import { Maybe } from '@equips/entities-schema';
import { OnboardingView } from '../../screens/onboarding/OnboardingScreen';
import { AuthorizationData } from '../auth/AuthContext';

const isInitialized = (userMetadata: Maybe<AuthorizationData>): { initialized: boolean; needed: OnboardingView | null } => {
  if (!userMetadata || !userMetadata.fullName) {
    return { initialized: false, needed: OnboardingView.Name };
  }

  /** If user doesn't belong to any organization, make user set up an org */
  if (!userMetadata?.organization?.metadata?.organizationName) {
    return { initialized: false, needed: OnboardingView.Organization };
  }

  if (!userMetadata?.locationId) {
    return { initialized: false, needed: OnboardingView.Location };
  }

  return { initialized: true, needed: null };
};

export default isInitialized;
