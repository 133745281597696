import React, { useContext } from 'react';

export enum AlertTypes {
  undo = 'undo',
  error = 'error',
  success = 'success',
  warning = 'warning',
  dismiss = 'dismiss',
}

export type UseAlertArgs = {
  type?: AlertTypes;
  content?: string;
  action?: () => Promise<void>;
  autoClose?: boolean;
};
export const AlertContext = React.createContext<(args: UseAlertArgs) => void>(() => ({}));

export const useAlert = () => {
  const showAlert = useContext(AlertContext);

  return (args: UseAlertArgs) => showAlert(args);
};

export const AlertProvider = AlertContext.Provider;
