import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  queryOrganizations,
  EntityLabelEnum,
  postEquipment,
  postNote,
  EquipmentStatusType,
  CoverageStatusType,
  postCoverage,
} from '@equips/entities-schema';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Prompt } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import UnderlinedButton from '../../common/components/Buttons/UnderlinedButton';
import Button from '../../common/components/Buttons/Button';
import { useAuth } from '../../common/auth/AuthContext';
import { clientValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import { getOrCreateSpecIdByModelManufacturerCategory } from '../../graphql/queries/specQueries';
import Modal from '../../common/components/Modal/Modal';
import useHead from '../../common/hooks/useHead';
import { useLocations } from '../../common/hooks/useLocations';
import Urls from '../../routes/Urls';
import useRouter from '../../common/hooks/useRouter';
import { useAlert } from '../../common/components/Alerts/AlertContext';
import InAppContainer from '../../common/components/InAppContainer';
import LocationForm from '../locations/LocationForm';
import Header from '../../common/components/Heading/Header';
import { toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST } from '../../common/functions/expensiveDateFunctions';
import BreadCrumbs from '../../common/components/BreadCrumbs';
import OrganizationSelectDialog from '../../common/components/Selects/OrganizationSelectDialog';
import { admins } from '../../common/auth/roles';
import { buildEquipmentDescription } from './buildEquipmentDescription';
import { EditEquipmentType, wantsCoverage } from './components/AddEquipmentFormEquipmentModal';
import { EquipmentAddScreenProvider, useEquipmentScreen, LocationNameAndIdType } from './components/useAddEquipmentScreen';
import DisplayEquipmentAtALocation from './components/DisplayEquipmentAtLocation';

function SelectOrganization() {
  const { setOrganizationId, organizationName, setOrganizationName, resetState } = useEquipmentScreen();
  const [modalShowing, setModalShowing] = useState(false);
  const closeModal = () => setModalShowing(false);
  return (
    <>
      <div className="-mt-4 pb-2 text-gray-800" data-testid="equipmentCreateForm">
        <div className="text-center text-sm">
          You are adding equipment to <strong>{organizationName}</strong>{' '}
          <UnderlinedButton
            data-testid="equipmentAddChangeOrganization"
            textColor="text-gray-800 text-2xs hover:text-blue-500"
            type="button"
            onClick={() => setModalShowing(true)}
          >
            Change organization
          </UnderlinedButton>
        </div>
      </div>
      <OrganizationSelectDialog
        open={modalShowing}
        handleClose={closeModal}
        typeOfOrganizationToFind={clientValueForFilter}
        handleSelect={async ({ organizationId, organizationName }) => {
          setOrganizationId(organizationId);
          setOrganizationName(organizationName);
          resetState();
        }}
      />
    </>
  );
}

function LocationFormModal({ modalShowing, setModalShowing, fetchLocations, locationId }) {
  const { organizationId } = useEquipmentScreen();
  const closeModal = () => setModalShowing(false);

  const title = locationId ? 'Update default location' : 'Add location';
  const subtitle = locationId
    ? 'This location was created by default during onboarding.  Please update this location information.'
    : 'Add a location to this organization.';

  return (
    <Modal
      handleClose={closeModal}
      isOpen={modalShowing}
      title={title}
      color="bg-gray-200"
      isLarge
      subtitle={subtitle}
      CloseIcon={() => <Close />}
    >
      <div className="p-4">
        <LocationForm
          locationId={locationId}
          assignableOrganizationId={organizationId}
          onSuccess={async () => {
            await fetchLocations();
            closeModal();
          }}
        />
      </div>
    </Modal>
  );
}
LocationFormModal.propTypes = {
  modalShowing: PropTypes.bool.isRequired,
  setModalShowing: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
};

function AddNewLocationComponent() {
  const { userCan, internalUsers } = useAuth();
  const { organizationId, addNewLocation, locationNameAndIds } = useEquipmentScreen();
  const [showing, setShowing] = useState(false);
  const [modalShowing, setModalShowing] = useState(false);
  const [locationId, setLocationId] = useState<string>();
  const { locations: unfilteredLocations, fetch } = useLocations(organizationId);
  const unavailableLocationIds = useMemo(() => locationNameAndIds.map((location) => location.locationId), [locationNameAndIds]);
  const locations = unfilteredLocations.filter((location) => !unavailableLocationIds.includes(location?.metadata?.locationId || ''));

  if (!showing && locationNameAndIds.length !== 0) {
    return (
      <div className="mx-auto max-w-lg rounded-lg bg-blue-500 hover:bg-blue-600">
        <button
          data-testid="addAnotherLocation"
          className="w-full py-2 text-center text-xs font-semibold text-white"
          type="button"
          onClick={() => setShowing(true)}
        >
          {locationNameAndIds.length > 0 ? 'Add Another Location' : 'Select A Location'}
        </button>
      </div>
    );
  }

  return (
    <>
      <footer className="py-2">
        <div className="mx-auto my-4 rounded-lg border border-gray-200 bg-white px-4 pb-2 pt-4 shadow">
          <h3 className="pb-6 pt-2 text-center font-semibold text-gray-700">
            {locationNameAndIds.length === 0 ? 'Where would you like to add equipment?' : 'Add a location'}
          </h3>
          {userCan(internalUsers) && <SelectOrganization />}
          <div className="-mx-2 flex flex-wrap">
            {locations.map((location) => (
              <div key={location?.metadata?.locationId || ''} className="w-1/2 p-2 md:w-1/4">
                <button
                  data-testid="equipmentAddLocationSelect"
                  data-locationid={location?.metadata?.locationId || ''}
                  className="block w-full border border-gray-400 bg-gray-50 py-2 text-sm text-gray-800 hover:bg-gray-300 hover:shadow-sm"
                  onClick={() => {
                    if (location?.metadata?.autogenerated) {
                      setLocationId(location?.metadata?.locationId || undefined);
                      setModalShowing(true);
                    } else {
                      addNewLocation({
                        locationId: location?.metadata?.locationId || '',
                        locationName: location?.metadata?.locationDisplayName || '',
                      });
                      setShowing(false);
                    }
                  }}
                  type="button"
                >
                  {location?.metadata?.locationDisplayName || '(unnamed location)'}
                </button>
              </div>
            ))}
          </div>
          <div className="mt-2 w-full text-right text-xs text-gray-700">
            {locationNameAndIds.length !== 0 && (
              <button className="mr-2 hover:text-blue-500 hover:underline" type="button" onClick={() => setShowing(false)}>
                Cancel
              </button>
            )}
            <button
              data-testid="equipmentAddMissingLocation"
              className="hover:text-blue-500 hover:underline"
              type="button"
              onClick={() => setModalShowing(true)}
            >
              Add missing location
            </button>
          </div>
        </div>
      </footer>
      <LocationFormModal modalShowing={modalShowing} setModalShowing={setModalShowing} fetchLocations={fetch} locationId={locationId} />
    </>
  );
}

function FormHeader() {
  const { saving, saveAllEquipment, haveEquipment } = useEquipmentScreen();

  return (
    <>
      <BreadCrumbs
        items={[
          { text: 'Dashboard', to: Urls.HOME },
          { text: 'Equipment', to: Urls.EQUIPMENT },
        ]}
        className="pb-4"
      />
      <Header
        className="pb-6"
        title="Add Equipment"
        subtitle="Easily add equipment to your locations. Select a location to get started."
        ActionButtons={() => {
          return haveEquipment ? (
            <div>
              <span>
                <Button data-testid="saveAllEquipment" className="px-8" onClick={() => saveAllEquipment()} loading={saving} type="button">
                  Save all
                </Button>
              </span>
            </div>
          ) : null;
        }}
      />
    </>
  );
}

export async function saveOneEquipment(equipment: EditEquipmentType, saveEquipment, organizationId, locationId, userCan) {
  const {
    subclasses,
    categoryId,
    categoryName,
    model,
    manufacturer,
    externalId,
    equipmentName,
    serialNumber,
    providerId,
    wantCoverage,
    onDate,
    note,
    purchaseDate,
    acquisitionDate,
    installationDate,
    options = {},
    clientTags,
  } = equipment;

  const possibleProviderIds = providerId ? [providerId] : undefined;

  try {
    const defaultEquipmentName = buildEquipmentDescription({
      model,
      manufacturer,
      serialNumber,
      categoryName,
    });

    const specId = await getOrCreateSpecIdByModelManufacturerCategory({ model, manufacturer, categoryId });

    const equipmentPostResponse = await saveEquipment({
      metadata: {
        subclasses,
        locationId,
        organizationId,
        equipmentName: equipmentName || defaultEquipmentName,
        externalId,
        serialNumber,
        specId,
        providerId,
        possibleProviderIds,
        purchaseDate,
        acquisitionDate,
        installationDate,
        clientTags,
        equipmentStatus: userCan(admins) ? EquipmentStatusType.Active : EquipmentStatusType.Proposed,
      },
      options: { ...options },
    });
    const equipmentId = equipmentPostResponse?.data?.post?.metadata?.equipmentId;

    if (note && equipmentId) {
      await postNote({ metadata: { parentId: equipmentId, parentLabel: EntityLabelEnum.Equipment, message: note } });
    }

    if (wantCoverage && wantCoverage === wantsCoverage && equipmentId) {
      await postCoverage({
        metadata: {
          onDate: toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST(onDate),
          equipmentId,
          organizationId,
          locationId,
          coverageStatus: CoverageStatusType.Requested,
        },
      });
    }
    return equipmentId;
  } catch (error) {
    console.error(error);
  }
}

export default function EquipmentAddScreen() {
  const { auth, userCan } = useAuth();
  useHead('Add Equipment');
  const [organizationId, setOrganizationId] = useState(auth?.organizationId || '');
  const [organizationName, setOrganizationName] = useState(auth?.organization?.metadata?.organizationName);

  const [locationNameAndIds, setLocations] = useState<LocationNameAndIdType[]>([]);
  const [equipment, setEquipment] = useState<{ [x: string]: EditEquipmentType[] | null | undefined }>({});
  const [saving, setSaving] = useState(false);

  const { data, isFetching: organizationIndustryLoading } = useQuery(
    ['EquipmentAddScreen', organizationId],
    () => queryOrganizations({ organizationId }),
    {
      enabled: !!organizationId,
      select: (data) => data?.data,
    },
  );

  const industry = data?.organizations?.data?.[0]?.metadata?.industry;

  if (!organizationIndustryLoading && !industry) {
    throw new Error('Your organization does not currently have an industry');
  }

  const resetState = () => setLocations([]);
  const haveEquipment = useMemo(() => !!Object.keys(equipment).find((locationId) => (equipment[locationId]?.length || 0) > 0), [equipment]);

  const addNewLocation = ({ locationId, locationName }) => setLocations(() => [...locationNameAndIds, { locationId, locationName }]);

  const removeALocation = ({ locationId: locationIdToRemove }) =>
    setLocations((state) => state.filter(({ locationId }) => locationId !== locationIdToRemove));

  const { mutateAsync: saveEquipment } = useMutation(postEquipment);

  const { history } = useRouter();
  const showAlert = useAlert();

  const saveAllEquipment = async () => {
    setSaving(true);

    for (let i = 0; i < locationNameAndIds.length; i++) {
      const { locationId } = locationNameAndIds[+i];
      const equipmentAtLocation = equipment[locationId] || [];

      for (let k = 0; k < equipmentAtLocation.length; k++) {
        await saveOneEquipment(equipmentAtLocation[+k], saveEquipment, organizationId, locationId, userCan);
      }
    }

    setEquipment({});
    showAlert({ content: 'Successfully added equipment' });
    history.push(Urls.EQUIPMENT);
  };

  return (
    <EquipmentAddScreenProvider
      value={{
        haveEquipment,
        industry,
        saveAllEquipment,
        saving,
        organizationId,
        setOrganizationId,
        organizationName,
        setOrganizationName,
        locationNameAndIds,
        addNewLocation,
        removeALocation,
        resetState,
      }}
    >
      <InAppContainer className="min-h-screen py-4">
        <Prompt when={haveEquipment && !saving} message="You have unsaved changes.  Are you sure you want to navigate away?" />
        <FormHeader />
        <section className="mx-auto">
          {locationNameAndIds.length > 0 && (
            <section>
              {locationNameAndIds.map(({ locationId, locationName }) => (
                <DisplayEquipmentAtALocation
                  key={locationId}
                  locationId={locationId}
                  locationName={locationName}
                  equipment={equipment[locationId.toString()] || []}
                  setEquipment={(equipment) => {
                    setEquipment((state) => ({ ...state, [locationId]: equipment }));
                  }}
                />
              ))}
            </section>
          )}
          <AddNewLocationComponent />
        </section>
      </InAppContainer>
    </EquipmentAddScreenProvider>
  );
}
