import React, { useState, useContext, useReducer } from 'react';
import { useAuth } from '../../common/auth/AuthContext';
import { AlertContext, AlertTypes } from '../../common/components/Alerts/AlertContext';
import useHead from '../../common/hooks/useHead';
import FormFields, { fieldTypes } from '../../common/components/Form/FormFields';
import isInitialized from '../../common/functions/isInitialized';
import MainForm from '../../common/components/Form/MainForm';
import { postUserForOnboarding } from '../../graphql/mutations/UserMutations';
import waitSynchronously from '../../common/functions/waitSynchronously';
import { supportEmailAddress } from '../../common/constants/contact';
import Urls from '../../routes/Urls';
import useLocalStorage from '../../common/hooks/useLocalStorage';
import OnboardingLocations from './OnboardingLocations';
import OnboardingOrganization from './OnboardingOrganization';

export enum OnboardingView {
  Name,
  Organization,
  Location,
}

function OnboardingScreen() {
  useHead('Onboarding');
  useLocalStorage('onboarding', true);

  const { auth, userCan, roles } = useAuth();
  const showAlert = useContext(AlertContext);

  const { needed: view } = isInitialized(auth);
  const [loading, setLoading] = useState(false);
  const [{ fullName, phoneNumberWithCountryCode, phoneNumberExtension }, dispatch] = useReducer(
    (state, { id, value }) => ({ ...state, [id]: value }),
    {
      fullName: '',
      phoneNumberWithCountryCode: '',
      phoneNumberExtension: '',
    },
  );

  const updateUser = async () => {
    try {
      if (!fullName) {
        return showAlert({ type: AlertTypes.error, content: 'Please complete the name field.' });
      }

      if (!phoneNumberWithCountryCode) {
        return showAlert({ type: AlertTypes.error, content: 'Please add a phone number.' });
      }

      setLoading(true);

      const { data, errors } = await postUserForOnboarding(auth?.userId, { fullName, phoneNumberWithCountryCode, phoneNumberExtension });

      if (errors?.[0]?.name === 'UserValidationError') {
        setLoading(false);

        return showAlert({ type: AlertTypes.error, content: `${errors[0].message}. Please contact ${supportEmailAddress}` });
      }

      if (data && 'post' in data && data?.post === null) {
        throw new Error('There was an error updating your name.');
      }

      showAlert({ content: 'You have successfully updated your account.' });
      await waitSynchronously(1000);

      window.location.reload();
    } catch (error) {
      console.error(error);
      showAlert({
        type: AlertTypes.error,
        content: `There was an error registering your user. Please contact ${supportEmailAddress}.`,
      });
      throw error;
    }
    setLoading(false);
  };

  switch (view) {
    case OnboardingView.Location:
      return (
        <MainForm data-testid="onboarding-location-form" title="One last step" subtitle="We just need you to select or setup a location.">
          <OnboardingLocations
            handleSubmit={() => {
              window.location.href = Urls.HOME;
            }}
          />
        </MainForm>
      );

    case OnboardingView.Organization:
      if (userCan([roles.superGlobalAdmin, roles.globalAdmin, roles.customerOrganizationAdmin, roles.providerOrganizationAdmin])) {
        return (
          <div data-testid="onboarding-organization-screen">
            <MainForm
              title="Let's set up your organization"
              subtitle="Looks like your organization is not registered with Equips yet. Let's set it up."
            >
              <OnboardingOrganization
                organizationId={auth?.organizationId}
                handleSuccess={async () => {
                  /** update auth context */
                  await waitSynchronously(1000);
                  window.location.reload();
                }}
              />
            </MainForm>
          </div>
        );
      } else {
        return (
          <div data-testid="onboarding-organization-screen-no-role">
            <MainForm title="Uh-oh" subtitle="Looks like your organization has not been set up yet.">
              Please contact <a href={`mailto:${supportEmailAddress}`}>{supportEmailAddress}</a> or your organization admin to setup your
              organization.
            </MainForm>
          </div>
        );
      }

    case OnboardingView.Name:
      return (
        <div data-testid="onboarding-screen">
          <MainForm title="Welcome to Equips!" subtitle="We just need your name to get started.">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                updateUser();
              }}
              data-testid="onboardingScreen-postUser"
            >
              <FormFields
                loading={loading}
                handleChange={({ target: { id, value } }) => {
                  dispatch({ id, value });
                }}
                primaryButton="Continue"
                fields={[
                  {
                    heading: `Let's get your account setup`,
                    fields: [
                      { label: 'Your full name', id: 'fullName', required: true, value: fullName },
                      {
                        label: 'Phone number',
                        id: 'phoneNumberWithCountryCode',
                        type: fieldTypes.PHONE,
                        required: true,
                        isHalf: true,
                        value: phoneNumberWithCountryCode,
                      },
                      {
                        label: 'Phone extension',
                        placeholder: 'ext: 325',
                        isHalf: true,
                        id: 'phoneNumberExtension',
                        value: phoneNumberExtension,
                      },
                    ],
                  },
                ]}
              />
            </form>
          </MainForm>
        </div>
      );
    default:
      // FIXME this is not the right way to do this
      window.location.href = Urls.HOME;
      return null;
  }
}

export default OnboardingScreen;
