import { lazy } from 'react';
import Urls from '../../routes/Urls';

const { SETTINGS, SETTINGS_NOTIFICATIONS, SETTINGS_ORGANIZATION, SETTINGS_HOMEPAGE, SETTINGS_AUDIT_LOG } = Urls;
const SettingsHomepage = lazy(() => import('./homepage/SettingsHomepage'));
const SettingsNotifications = lazy(() => import('./notifications/SettingsNotifications'));
const SettingsOrganization = lazy(() => import('./organization/SettingsOrganization'));
const SettingsProfile = lazy(() => import('./user/SettingsProfile'));
const SettingsAuditLog = lazy(() => import('./user/SettingsAuditLog'));

export const settingsRoutes = [
  {
    path: SETTINGS,
    component: SettingsProfile,
  },
  {
    path: SETTINGS_HOMEPAGE,
    component: SettingsHomepage,
  },
  {
    path: SETTINGS_ORGANIZATION,
    component: SettingsOrganization,
  },
  {
    path: SETTINGS_NOTIFICATIONS,
    component: SettingsNotifications,
  },
  {
    path: SETTINGS_AUDIT_LOG,
    component: SettingsAuditLog,
  },
];
