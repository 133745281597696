import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { coverageStatuses } from '../../../graphql/enums';
import useMutation from '../../../common/hooks/useMutation';
import SimpleModal, { simpleModalTypes } from '../../../common/components/Modal/SimpleModal';
import UnderlinedButton from '../../../common/components/Buttons/UnderlinedButton';
import Button from '../../../common/components/Buttons/Button';
import { postCoverage } from '../../../graphql/mutations/coveragesMutation';
import Textarea from '../../../common/components/Form/Textarea';
import FormGroup from '../../../common/components/Form/FormGroup';
import Label from '../../../common/components/Form/Label';

export default function RequestCoverageRemovalToDeactivateEquipment({ onClose, onSuccess, organizationId, locationId, equipmentId }) {
  const { t } = useTranslation();

  const [conditions, setConditions] = useState('Please remove this equipment from coverage and deactivate it, effective immediately.');
  const [requestCoverage, { saving }] = useMutation(postCoverage, {
    variables: {
      equipmentId,
      organizationId,
      locationId,
      coverageStatus: coverageStatuses.requested.value,
      conditions,
      onDate: Date.now(),
      offDate: Date.now(),
    },
    successMessage: t('success'),
    onCompleted: () => {
      if (onSuccess) onSuccess();
    },
  });

  return (
    <SimpleModal
      modalType={simpleModalTypes.info}
      isOpen
      handleClose={onClose}
      title={t('unableToDeactivateEquipment')}
      subtitle={t('coverageMustBeRemovedToDeactivateEquipment')}
    >
      <form
        className="pb-4 pt-6"
        data-testid="coverageRemoveBeforeDeactivateModal"
        onSubmit={(event) => {
          event.preventDefault();
          requestCoverage();
        }}
      >
        <FormGroup fullWidth>
          <Label label={t('note')} id="conditions" />
          <Textarea required value={conditions} onChange={(event) => setConditions(event.target.value)} id="conditions" />
        </FormGroup>
        <div className="flex items-end justify-end py-2">
          <UnderlinedButton type="button" onClick={onClose}>
            {t('cancel')}
          </UnderlinedButton>
          <Button type="submit" loading={saving} blue className="ml-4">
            {t('save')}
          </Button>
        </div>
      </form>
    </SimpleModal>
  );
}
RequestCoverageRemovalToDeactivateEquipment.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  equipmentId: PropTypes.string.isRequired,
};
