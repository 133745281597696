import React from 'react';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type ModalProps = {
  testId?: string;
  isDismissible?: boolean;
  isLarge?: boolean;
  isOpen?: boolean;
  color?: string;
  handleClose?: (...args: any[]) => any;
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
  ActionButtons?: React.ReactNode;
  CloseIcon?: React.ReactNode;
  HeaderIcon?: React.ReactNode;
  styles?: Record<string, string> | null;
};

export default function Modal({
  testId = 'modal',
  isDismissible = true,
  isLarge = false,
  handleClose = () => null,
  isOpen = true,
  color = 'bg-white',
  title = '',
  subtitle = '',
  children,
  className = '',
  ActionButtons = null,
  CloseIcon = <CancelOutlined />,
  HeaderIcon = null,
  styles = null,
}: ModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      className={className}
      fullWidth
      maxWidth={isLarge ? 'md' : 'sm'}
      open={isOpen}
      onClose={(event, reason) => isDismissible && handleClose(event, reason)}
      fullScreen={fullScreen}
    >
      <div data-testid={testId} className="flex flex-wrap">
        {title && (
          <div className={`w-full px-4 py-4 ${color} ${styles?.border ? styles.border : ''}`}>
            <header className={`flex items-center justify-between`}>
              <div className="flex flex-row items-center gap-2">
                {HeaderIcon}
                <h3 className={`${styles?.title ? styles.title : 'font-bold capitalize text-gray-800'}`}>{title}</h3>
              </div>

              <div className="flex flex-row items-center gap-2">
                {ActionButtons}
                {isDismissible && (
                  <button
                    className="text-gray-600 opacity-75 transition duration-100 ease-in-out hover:opacity-100"
                    type="button"
                    onClick={handleClose}
                  >
                    {CloseIcon}
                  </button>
                )}
              </div>
            </header>
            {subtitle && <p className="mt-2 text-sm text-gray-600">{subtitle}</p>}
          </div>
        )}

        <div className="w-full text-gray-600">{children}</div>
      </div>
    </Dialog>
  );
}
