import React, { lazy } from 'react';
import { StringParam, NumberParam, JsonParam, BooleanParam } from 'use-query-params';
import FormGroup from '../Form/FormGroup';
import StateSelector from '../Selects/StateSelector';
import StyledSelect from '../Selects/StyledSelect';
import Input from '../Form/Input';
import CurrencyRangeFilter from '../Form/CurrencyRangeFilter';
import ContractSelect from '../Selects/ContractSelect';
import TagInput from '../Form/TagInput';
import LocationsDialogSelect from '../Selects/LocationsDialogSelect';
import CurrencyInput from '../Form/CurrencyInput';
import Label from '../Form/Label';
import { EquipmentSearchableSelection } from '../ServiceRequest/EquipmentSearchableSelection';
import { clientValueForFilter, providerValueForFilter } from '../../../graphql/queries/organizationGraphQLQueries';
import MultiSelect from '../Selects/MultiSelect';
import CategorySelect from '../Selects/CategorySelect';

const DateRangeFilter = lazy(() => import('../Date/DateRangeFilter'));
const SelectDateRangeFromAgreements = lazy(() => import('../Date/SelectDateRangeFromAgreements'));
const OrganizationsSelect = lazy(() => import('../Selects/OrganizationsSelect'));
const OrganizationMultiSelect = lazy(() => import('../Selects/OrganizationMultiSelect'));

export enum TableFilterTypes {
  clientTagsMultiSelect = 'clientTagsMultiSelect',
  currencyInput = 'currencyInput',
  currencyRange = 'currencyRange',
  dateRange = 'dateRange',
  dateRangeOrContractSelect = 'dateRangeOrContractSelect',
  organizationSelect = 'organizationSelect',
  organizationMultiSelect = 'organizationMultiSelect',
  locationSelect = 'locationSelect',
  equipmentSearchable = 'equipmentSearchable',
  categorySelect = 'categorySelect',
  agreementSelect = 'agreementSelect',
  stateSelect = 'stateSelect',
  multiSelect = 'multiSelect',
  select = 'select',
  tags = 'tags',
  text = 'text',
  /** Exposes the filter via the query string but does not display a filter input on the interface*/
  hidden = 'hidden',
}
export type FilterOptionType = {
  type: TableFilterTypes;
  prependedOptions?: { label: string; value: string }[];
  emptySelectionText?: string;
  key: string;
  /** Used for select */
  options?: { label: string; value: string }[];
  hidden?: boolean;
  /** The default value for the given filter. Defaults to: "" */
  defaultValue?: any;
  filterType?: typeof StringParam | typeof BooleanParam | typeof NumberParam | typeof JsonParam;
  label?: string;
  labelHelper?: string;
  showFilterOptionWhen?: (filters: any) => boolean;
  typeOfOrganizationToFind?: 'ANY' | typeof clientValueForFilter | typeof providerValueForFilter | string;
  organizationIdLocation?: string;
  locationIdLocation?: string;
  equipmentClass?: string;
  includeEmptySelectOption?: boolean;
  buildOptionsFromActiveFilters?: (currentyActiveFilters: { [key: string]: any }) => { value: string; label: string }[];
  isSmall?: boolean;
  isSidebar?: boolean;
};

export function DisplayTableOptionByType({
  filters,
  setFilters,
  type,
  prependedOptions,
  emptySelectionText,
  filterKey: key,
  options,
  label,
  organizationIdLocation = 'organizationId',
  locationIdLocation = 'locationId',
  buildOptionsFromActiveFilters,
  isSidebar,
  ...restOfOptions
}: FilterOptionType & {
  filterKey: any;
  filters: any;
  setFilters: (state: { [key: string]: string | null | undefined }) => void;
}) {
  let availableOptions = options || [];

  if (buildOptionsFromActiveFilters) {
    availableOptions = buildOptionsFromActiveFilters(filters);
  }

  if (type === TableFilterTypes.currencyInput) {
    return (
      <CurrencyInput
        {...restOfOptions}
        key={key}
        id={`${key}Filters`}
        cents={filters?.[key]}
        handleCents={(cents) => setFilters({ [key]: cents || undefined })}
        label={label}
        fullWidth
      />
    );
  }

  if (type === TableFilterTypes.currencyRange) {
    return (
      <CurrencyRangeFilter
        id={key}
        key={key}
        label={label}
        value={filters?.[key]}
        setValue={(value) => setFilters({ [key]: value || undefined })}
      />
    );
  }

  if (type === TableFilterTypes.categorySelect) {
    return (
      <CategorySelect
        {...restOfOptions}
        fullWidth
        key={key}
        emptySelectionText={emptySelectionText || 'Any'}
        label={label}
        onChange={(value) => setFilters({ [key]: value || undefined })}
        selectedCategoryIds={filters?.[key]}
      />
    );
  }

  if (type === TableFilterTypes.agreementSelect) {
    return (
      <ContractSelect
        {...restOfOptions}
        prependedOptions={prependedOptions}
        fullWidth
        key={key}
        emptySelectionText={emptySelectionText || 'Any'}
        label={label}
        organizationId={filters?.[organizationIdLocation || '']}
        setContractId={(value) => setFilters({ [key]: value || undefined })}
        contractId={filters?.[key]}
      />
    );
  }

  if (type === TableFilterTypes.locationSelect) {
    return (
      <LocationsDialogSelect
        {...restOfOptions}
        fullWidth
        key={key}
        id={`${key}Filters`}
        emptySelectionText={emptySelectionText || 'Any'}
        label={label}
        organizationId={filters?.[organizationIdLocation || '']}
        changeLocation={(value) => setFilters({ [key]: value || undefined })}
        selectedLocation={filters?.[key]}
      />
    );
  }

  if (type === TableFilterTypes.organizationSelect) {
    return (
      <OrganizationsSelect
        {...restOfOptions}
        typeOfOrganizationToFind={restOfOptions?.typeOfOrganizationToFind}
        fullWidth
        isClearable
        label={label}
        key={key}
        id={`${key}Filters`}
        organizationId={filters?.[key]}
        changeOrganization={(selection) => {
          setFilters({ [key]: selection?.value || undefined });
        }}
      />
    );
  }

  if (type === TableFilterTypes.organizationMultiSelect) {
    return (
      <OrganizationMultiSelect
        {...restOfOptions}
        typeOfOrganizationToFind={restOfOptions?.typeOfOrganizationToFind}
        fullWidth
        isClearable
        label={label}
        key={key}
        id={`${key}Filters`}
        value={filters?.[key]}
        setValue={(selections) => {
          // @ts-ignore Unknown reason. Please investigate me.
          setFilters({ [key]: selections?.length > 0 ? selections : undefined });
        }}
      />
    );
  }

  if (type === TableFilterTypes.multiSelect) {
    return (
      <MultiSelect
        id={`${key}Filters`}
        testId={`${key}MultiSelect`}
        options={availableOptions}
        fullWidth
        label={label}
        isClearable
        handleChange={(selections) => {
          setFilters({ [key]: (selections?.length || 0) > 0 ? selections : undefined });
        }}
        selectedValues={filters?.[key]}
      />
    );
  }

  if (type === TableFilterTypes.equipmentSearchable) {
    return (
      <EquipmentSearchableSelection
        includeAnyText={restOfOptions.includeEmptySelectOption ? 'Any' : ''}
        equipmentId={filters?.[key]}
        organizationId={filters?.[organizationIdLocation]}
        locationId={filters?.[locationIdLocation]}
        key={key}
        id={`${key}Filters`}
        handleSelect={(equipmentId) => setFilters({ [key]: equipmentId || undefined })}
      />
    );
  }

  if (type === TableFilterTypes.dateRange) {
    return (
      <DateRangeFilter
        {...restOfOptions}
        key={key}
        id={key}
        label={label || ''}
        value={filters?.[key]}
        setValue={(value) => setFilters({ [key]: value || undefined })}
      />
    );
  }

  if (type === TableFilterTypes.dateRangeOrContractSelect) {
    return (
      <SelectDateRangeFromAgreements
        {...restOfOptions}
        key={key}
        id={key}
        label={label || ''}
        value={filters?.[key]}
        setValue={(value) => setFilters({ [key]: value || undefined })}
        organizationId={filters?.[organizationIdLocation || '']}
        isSidebar={isSidebar}
      />
    );
  }

  if (type === TableFilterTypes.stateSelect) {
    return (
      <StateSelector
        {...restOfOptions}
        key={`${key}Filters`}
        id={`${key}stateFilter`}
        label={label || ''}
        fullWidth
        value={filters?.[key]}
        onChange={(event) => setFilters({ [key]: event.target.value })}
      />
    );
  }

  if (type === TableFilterTypes.select) {
    return (
      <FormGroup fullWidth key={key}>
        <Label id={key} label={label} helper={restOfOptions?.labelHelper} />
        <StyledSelect
          {...restOfOptions}
          key={`${key}Filters`}
          id={key}
          value={filters?.[key]}
          onChange={(event) => setFilters({ [key]: event.target.value })}
        >
          {options?.map(({ label, value }) => (
            <option value={value} key={label}>
              {label}
            </option>
          ))}
        </StyledSelect>
      </FormGroup>
    );
  }

  if (type === TableFilterTypes.tags) {
    return (
      <TagInput
        key={key}
        selectedTags={filters?.[key] ? filters?.[key].split(',') : undefined}
        onChange={(value) => setFilters({ [key]: value.join(',') })}
        label={label || 'Filter by tag'}
        emptyMessage="Filter by another tag"
      />
    );
  }

  if (type === TableFilterTypes.text) {
    return (
      <FormGroup fullWidth key={key}>
        <Label id={key} label={label} helper={restOfOptions?.labelHelper} />
        <Input
          id={key}
          value={filters?.[key]}
          placeholder={filters?.placeholder || label}
          onChange={(event) => setFilters({ [key]: event.target.value })}
        />
      </FormGroup>
    );
  }

  if (type === TableFilterTypes.hidden) return null;

  console.error('form type ' + type + ' has no form element set to render for it');

  return null;
}
