import React from 'react';
import EmptyComponent from '../EmptyComponent';
import Skeleton from '../Loaders/Skeleton';
import Label from '../Form/Label';
import UnderlinedButton from '../Buttons/UnderlinedButton';
import StyledSelect from '../Selects/StyledSelect';

interface EquipmentClassSelectionProps {
  equipmentClass: string | undefined;
  loading: boolean;
  setEquipmentClass: (value: string) => void;
  equipmentClassesAtTheLocation: string[];
  label?: string;
}

export default function EquipmentClassSimpleSelect({
  equipmentClass = '',
  loading,
  setEquipmentClass,
  equipmentClassesAtTheLocation,
  label = 'Equipment Category',
}: EquipmentClassSelectionProps) {
  if (loading) return <Skeleton />;

  return (
    <>
      {equipmentClassesAtTheLocation.length === 0 && <EmptyComponent small text="No equipment found at this location" />}
      {equipmentClassesAtTheLocation.length > 0 && <Label id="equipmentClassSelect" label={label} />}
      <StyledSelect
        key="interactiveServiceRequest-equipmentClass"
        id="interactiveServiceRequest-equipmentClass"
        value={equipmentClass}
        onChange={(event) => setEquipmentClass(event.target.value)}
      >
        <option value="" key="anyOption">
          Filter by category
        </option>
        {equipmentClassesAtTheLocation.map((equipmentClass) => (
          <option value={equipmentClass} key={equipmentClass}>
            {equipmentClass}
          </option>
        ))}
      </StyledSelect>
      {equipmentClass && (
        <UnderlinedButton className="block ml-auto" type="button" tiny onClick={() => setEquipmentClass('')}>
          Clear Selection
        </UnderlinedButton>
      )}
    </>
  );
}
