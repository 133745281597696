import React, { useMemo, useState } from 'react';
import { Maybe, Note } from '@equips/entities-schema';
import EmptyComponent from '../EmptyComponent';
import Spinner from '../Loaders/Spinner';
import { NoteDisplay } from './NoteDisplay';

export interface NoteListProps {
  loading?: boolean;
  readOnly?: boolean;
  displayEmptyMessage?: boolean;
  notes: Maybe<Note>[];
  emptyTitle?: string;
  unknownAuthorName?: string;
  highlightId?: string;
}

export default function NoteList({
  displayEmptyMessage = true,
  notes = [],
  emptyTitle = '',
  unknownAuthorName = '',
  readOnly,
  loading,
  highlightId,
}: NoteListProps) {
  const [highlightSeen, setHighlightSeen] = useState(false);

  const sortedNotes = useMemo(() => {
    if (!loading)
      return notes
        .filter((note): note is Note => note != null)
        .sort((a, b) => {
          const aUnixMillisecondTimestamp = a?.metadata?.createdAt || 0;
          const bUnixMillisecondTimestamp = b?.metadata?.createdAt || 0;

          return +bUnixMillisecondTimestamp - +aUnixMillisecondTimestamp;
        });
  }, [notes, loading]);

  if (loading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <ul data-testid="activityFeed">
      {(!sortedNotes || sortedNotes.length === 0) && displayEmptyMessage && <EmptyComponent text={emptyTitle} />}
      {sortedNotes?.map((note) => {
        const isHighlighted = highlightId && highlightId === note?.metadata?.noteId && !highlightSeen;

        return (
          <li
            {...(isHighlighted && { onMouseOver: () => setHighlightSeen(true) })}
            className={`border-b border-gray-300 py-4 ${isHighlighted && 'bg-orange-100'}`}
            key={note.metadata?.noteId}
          >
            <NoteDisplay readOnly={readOnly} note={note} unknownAuthorName={unknownAuthorName} />
          </li>
        );
      })}
    </ul>
  );
}
