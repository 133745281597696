export const cleanObject = (incomingObject) => {
  const objectToClean = {
    ...incomingObject,
  };

  const recursivelyCleanObject = (obj) => {
    const newObject = {};

    Object.keys(obj).forEach((key) => {
      const val = obj[key];

      if (val === '' || Number.isNaN(val)) {
        newObject[key] = null;
      } else if (val !== null && typeof val === 'object' && !Array.isArray(val)) {
        newObject[key] = recursivelyCleanObject(val);
      } else if (val || val === false || val === 0) {
        newObject[key] = val;
      }
    });

    return newObject;
  };

  const returnedObject = recursivelyCleanObject(objectToClean);

  return returnedObject;
};
