import React, { useState } from 'react';
import CloseTwoTone from '@mui/icons-material/CloseTwoTone';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Maybe, Invoice, InvoiceMetadata } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import DraggableDialog from '../Modal/DraggableDialog';
import Input from '../Form/Input';
import Button from '../Buttons/Button';
import LoaderCards from '../Loaders/LoaderCards';
import EmptyComponent from '../EmptyComponent';
import { invoiceSelectSearch } from '../../../graphql/queries/invoiceGraphQLQueries';

function DisplaySelectableInvoice({ invoice, handleSelect }) {
  const invoiceId = invoice?.metadata?.invoiceId;
  const shortId = invoice?.metadata?.shortId;

  return (
    <button
      data-testid="InvoiceSelectDialogButton"
      data-invoiceid={invoiceId}
      className="block w-full border-b border-gray-200 px-4 py-4 text-left text-lg text-gray-800 hover:bg-blue-100"
      type="button"
      onClick={() => handleSelect({ invoice, invoiceId })}
    >
      <div className="item-start flex flex-col justify-between sm:flex-row sm:items-center">
        <div>
          <span className="pr-2 text-gray-600">
            <AccountCircle color="inherit" />
          </span>{' '}
          {shortId}
        </div>
      </div>
    </button>
  );
}

type InvoiceSelectDialogProps = {
  handleSelect: any;
  open: boolean;
  handleClose: any;
  existingSearch?: string;
  showUnassigned?: boolean;
};

const blankInvoiceToAdd = {
  metadata: {
    invoiceId: null,
    shortId: 'Unassigned',
  },
};

export default function InvoiceSelectDialog({ handleSelect, open, handleClose, existingSearch, showUnassigned }: InvoiceSelectDialogProps) {
  const [q, setQ] = useState(existingSearch);
  const [invoices, setInvoices] = useState<Maybe<Invoice>[]>([]);

  const { isLoading } = useQuery(['invoiceSelectDialgo', q], () => invoiceSelectSearch(q), {
    select: (data) => data?.data,
    onSuccess: (data) => {
      const allInvoices = data?.invoices?.data || [];
      setInvoices(showUnassigned ? [blankInvoiceToAdd, ...allInvoices] : allInvoices);
    },
  });

  const selectInvoice = (data: {
    invoice: { metadata: InvoiceMetadata | null | undefined };
    invoiceId: Maybe<string>;
    shortId: Maybe<string>;
  }) => {
    handleSelect(data);
    handleClose();
  };

  return (
    <DraggableDialog onClose={() => handleClose()} aria-labelledby="locationSelect" open={open} fullWidth maxWidth="xs">
      <header
        id="draggable-dialog"
        style={{ cursor: 'move' }}
        className={`flex flex-wrap items-center justify-between border-b border-gray-300 bg-white p-4`}
      >
        <div className="space-between flex w-2/3 flex-auto pr-2">
          <Input
            autoFocus
            className="no-drag-zone"
            id="searchQueryForInvoices"
            value={q || ''}
            onChange={(event: { target: { value: React.SetStateAction<string | undefined> } }) => setQ(event.target.value)}
            placeholder="Search invoices"
          />
          &nbsp;
          {q && (
            <Button tabIndex={-1} gray tiny className="ml-2 text-sm text-gray-700" type="button" onClick={() => setQ('')}>
              Clear
            </Button>
          )}
        </div>
        <div>
          <button tabIndex={-1} onClick={() => handleClose()} type="button" className="text-gray-500">
            <CloseTwoTone fontSize="small" />
          </button>
        </div>
      </header>
      <section>
        <div className="w-full overflow-y-scroll pb-10" style={{ height: '350px' }}>
          {!isLoading && invoices.length === 0 && <EmptyComponent text="No invoices found" />}
          {!isLoading &&
            invoices.map((invoice) => (
              <DisplaySelectableInvoice invoice={invoice} key={invoice?.metadata?.invoiceId || ''} handleSelect={selectInvoice} />
            ))}
          {isLoading && <LoaderCards />}
        </div>
      </section>
    </DraggableDialog>
  );
}
