import React from 'react';
import PropTypes from 'prop-types';

export default function Pill({
  children,
  danger = false,
  outlined = false,
  className = '',
  fontSize = 'text-sm',
  blue = false,
  green = false,
  color = '',
  ...restOfProps
}) {
  let background = 'bg-gray-200 border border-gray-400 text-gray-700';

  if (color) {
    background = color;
  } else if (danger) {
    background = 'bg-white text-red-500 border border-red-500';
  } else if (outlined) {
    background = 'bg-white border border-gray-800 text-gray-800';
  } else if (blue) {
    background = 'border border-blue-700 bg-blue-500';
  } else if (green) {
    background = 'border border-green-700 bg-green-500';
  }

  const makeItEasierToSelectTextInsideThePill = ' ';

  return (
    <>
      <span
        {...restOfProps}
        className={`inline-block whitespace-nowrap ${fontSize} mx-1 rounded-2xl px-2 py-0 text-center ${background} ${className}`}
      >
        {children}
      </span>
      {makeItEasierToSelectTextInsideThePill}
    </>
  );
}

Pill.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
  green: PropTypes.bool,
  blue: PropTypes.bool,
  danger: PropTypes.bool,
  outlined: PropTypes.bool,
};
