import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import './radioStyles.css';

export default function Radio({ onChange, options, value: radioGroupValue, required, label, labelClassName, isHalf, id }) {
  return (
    <fieldset>
      <legend className={labelClassName}>{label}</legend>
      <div className="flex flex-wrap">
        {options.map(({ value, label }) => (
          <Label
            className={`${isHalf ? 'w-1/2' : 'w-full'} py-1`}
            key={value}
            id={value}
            label={
              <>
                <input
                  required={required}
                  type="radio"
                  className="radio-input"
                  onChange={onChange}
                  id={value}
                  name={id}
                  value={value}
                  checked={radioGroupValue === value}
                />
                <span className={`ml-2`}>{label}</span>
              </>
            }
          />
        ))}
      </div>
    </fieldset>
  );
}

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  isHalf: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })).isRequired,
};

Radio.defaultProps = {
  required: false,
  isHalf: false,
  labelClassName: '',
};
