import React from 'react';

type ContainerType = { children: any; className?: string; width?: 'tight' };

export default function InAppContainer({ children, className = 'pb-12', width, ...restOfProps }: ContainerType) {
  let xPadding = 'px-4 md:px-12';

  if (width === 'tight') {
    xPadding = 'px-4 md:px-48';
  }

  return (
    <div className={`${xPadding} m-auto ${className}`} {...restOfProps}>
      {children}
    </div>
  );
}
