import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Maybe, Coverage, CoverageStatusType, Equipment } from '@equips/entities-schema';
import { CellProps } from 'react-table';
import { isEffectiveCoverageNow } from '../../coverages/businessLogic/isEffectiveCoverage';
import { toESTDateStringFromUnixMillisecondTimestamp } from '../../../common/functions/expensiveDateFunctions';
import { coverageStatuses, getEnumDisplayName } from '../../../graphql/enums';
import Urls from '../../../routes/Urls';
import StackedColumn from '../../../common/components/Table/StackedColumn';
import { standardEntityOptionsSerializerAndDeserializer } from '../../../common/functions/standardSerializersAndDeserializers';
import { getUnixMillisecondTimestampXDaysAgo } from '../../../common/functions/dateFunctions';
import RequestCoverage from './RequestCoverage';

const typesOfCoverage = {
  effectiveCurrentCoverage: 1,
  effectiveFutureCoverage: 2,
  requestedCoverage: 3,
  proposedFutureCoverage: 4,
  effectiveExpiredRecentlyCoverage: 5,
};

export const equipmentCoverageText = ({ data }) => {
  const coverages = data.row?.original?.coverages || [];

  const matchingCoverageTypes = new Map<number, undefined | Maybe<Coverage>>([]);
  coverages
    .filter((coverage) => !coverage?.metadata?.deactivatedAt)
    .forEach((coverage) => {
      const onDate = coverage?.metadata?.onDate || Infinity;
      const offDate = coverage?.metadata?.offDate || 0;
      const coverageStatus = coverage?.metadata?.coverageStatus;

      if (isEffectiveCoverageNow(coverage)) {
        matchingCoverageTypes.set(typesOfCoverage.effectiveCurrentCoverage, coverage);
      } else if (
        coverageStatus === CoverageStatusType.Effective &&
        offDate <= Date.now() &&
        offDate > getUnixMillisecondTimestampXDaysAgo(14)
      ) {
        matchingCoverageTypes.set(typesOfCoverage.effectiveExpiredRecentlyCoverage, coverage);
      } else if (coverageStatus === CoverageStatusType.Effective && onDate > Date.now()) {
        matchingCoverageTypes.set(typesOfCoverage.effectiveFutureCoverage, coverage);
      } else if (coverageStatus === CoverageStatusType.Requested) {
        matchingCoverageTypes.set(typesOfCoverage.requestedCoverage, coverage);
      } else if (coverageStatus === CoverageStatusType.Proposed) {
        matchingCoverageTypes.set(typesOfCoverage.proposedFutureCoverage, coverage);
      }
    });

  const coverage =
    matchingCoverageTypes.get(typesOfCoverage.effectiveCurrentCoverage) ||
    matchingCoverageTypes.get(typesOfCoverage.effectiveFutureCoverage) ||
    matchingCoverageTypes.get(typesOfCoverage.effectiveExpiredRecentlyCoverage) ||
    matchingCoverageTypes.get(typesOfCoverage.requestedCoverage) ||
    matchingCoverageTypes.get(typesOfCoverage.proposedFutureCoverage);

  const status = coverage?.metadata?.coverageStatus;
  const onDate = coverage?.metadata?.onDate || Infinity;
  const offDate = coverage?.metadata?.offDate || 0;
  const coverageConditions = coverage?.metadata?.conditions || '';
  const options = coverage?.options || {};
  let coverageConditionsDescription = '';

  if (coverageConditions) {
    coverageConditionsDescription = ` Coverage conditions: ${coverageConditions}`;
  }

  if (!coverage) return { text: '', line1: '' };

  let line1 = getEnumDisplayName(status, coverageStatuses);

  if (status === CoverageStatusType.Requested) {
    line1 = `Requested on ${toESTDateStringFromUnixMillisecondTimestamp(coverage?.metadata?.createdAt)}`;
  } else if (status === CoverageStatusType.Proposed) {
    line1 = `Proposed coverage`;
  } else if (Date.now() < onDate) {
    line1 = `Effective on ${toESTDateStringFromUnixMillisecondTimestamp(onDate)}`;
  } else if (Date.now() < offDate && Date.now() > onDate) {
    line1 = `Covered until ${toESTDateStringFromUnixMillisecondTimestamp(offDate)}`;
  } else if (Date.now() > offDate) {
    line1 = `Expired on ${toESTDateStringFromUnixMillisecondTimestamp(offDate)}`;
  }

  const text = `${line1}${coverageConditionsDescription} ${standardEntityOptionsSerializerAndDeserializer.serializer(options)}`;

  return { text, line1, coverage, coverageConditions };
};

export default function EquipmentCoverageCell(props: React.PropsWithChildren<CellProps<Equipment, any>>) {
  const equipmentId = props.row.original.metadata?.equipmentId || '';

  const { coverageConditions, line1, coverage } = useMemo(() => equipmentCoverageText({ data: props }), [props]);

  const linkToCoverage = `${Urls.EQUIPMENT}/${equipmentId}#3`;

  if (!coverage) {
    return <RequestCoverage equipmentId={equipmentId} getData={props.refetchData} />;
  }

  return (
    <StackedColumn
      testId="equipmentCoverageCell"
      line1={
        <Link className="secondary-active" to={linkToCoverage}>
          {line1}
        </Link>
      }
      line2={coverageConditions}
    />
  );
}
