import React from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

type FormFieldErrorMessageProps = {
  rule: React.ReactNode;
  type?: 'error' | 'warning';
  className?: string;
};

export default function FormFieldErrorMessage({ rule, type = 'error', className = '' }: FormFieldErrorMessageProps) {
  return (
    <div
      className={`flex items-center pt-1 text-xs leading-none ${type === 'error' ? 'text-red-500' : 'text-yellow-700'} ${className}`}
      aria-label="aria-errormessage"
    >
      <div className="pr-1 text-sm">
        <InfoOutlined fontSize="inherit" />
      </div>
      {rule}
    </div>
  );
}
