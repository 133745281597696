import React, { useState } from 'react';
import dompurify from 'dompurify';
import HelperPopper from '../Popper';
import { stripHtml } from '../../functions/stripHtml';

function TableCellExpandDangerouslySetInnerHTML(props: { html: string }) {
  const [anchorEl, setAnchorEl] = useState<React.ReactNode>(null);

  return (
    <>
      <button type="button" className="block cursor-pointer truncate text-left" onClick={(event) => setAnchorEl(event.currentTarget)}>
        {stripHtml(props.html)}
      </button>

      <HelperPopper handleClose={() => setAnchorEl(false)} open={Boolean(anchorEl)} placement="bottom" anchorEl={anchorEl || <div />}>
        <div className="prose max-w-md break-all text-sm" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(props.html) }}></div>
      </HelperPopper>
    </>
  );
}

export { TableCellExpandDangerouslySetInnerHTML };
