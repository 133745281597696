function stripHtml(value: string): string {
  if (!value) {
    return '';
  }

  return value
    .replace(/&nbsp;/g, '')
    .replace(/(<([^>]+)>)/g, ' ')
    .replace(/[\s]+/g, ' ')
    .trim();
}

export { stripHtml };
