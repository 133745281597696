import { deleteView, patchView, postView, View, ViewMetadataPatchInput, ViewMetadataPostInput, ViewScope } from '@equips/entities-schema';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TableInstance, TableState } from 'react-table';
import { getSearchFactory } from '../../../graphql/factories/SearchFactory';
import { getViewsByTableId } from '../../../graphql/queries/viewGraphQLQueries';
import { useAuth } from '../../auth/AuthContext';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';
import { isAasOrganizationId } from '../../functions/aasHelpers';
import { internalUsersPlusCOA } from '../../auth/roles';
import { useTableViewColumns } from './useTableViewColumns';

type ViewState = Pick<TableState, 'filters' | 'globalFilter' | 'sortBy' | 'hiddenColumns' | 'columnOrder' | 'pageSize'>;
export type ViewPatchInput = Pick<ViewMetadataPatchInput, 'viewId' | 'name' | 'description' | 'scope' | 'visibleColumns'>;
export type ViewPostInput = Pick<ViewMetadataPostInput, 'viewId' | 'name' | 'description' | 'scope' | 'visibleColumns'>;

/** Manage saved views for users and organizations */
export function useTableViews(tableId: string, options?: any) {
  const { defaultScope = ViewScope.Creator } = options || {};

  const { auth, permissions, userCan, internalUsers } = useAuth();
  const showAlert = useAlert();

  const [viewChanged, setViewChanged] = useState(false);
  const [currentView, setCurrentView] = useState<View | null | undefined>(null);
  const [currentViewId, _setCurrentViewId] = useState<null | string>(null);
  const [tableInstance, setTableInstance] = useState<TableInstance | null>(null);
  const [scope, setScope] = useState<ViewScope>(defaultScope);

  const validColumnIds = useTableViewColumns(tableId);

  function onError(action: 'load' | 'save' | 'update' | 'delete', message = '') {
    showAlert({ content: `Failed to ${action} view. ${message}`, type: AlertTypes.error });
  }

  const {
    data: views,
    refetch,
    isFetching,
  } = useQuery(
    ['useTableViews', tableId],
    () =>
      getViewsByTableId({
        tableId,
        userId: auth?.userId,
        organizationId: auth?.organizationId,
      }),
    {
      enabled: !!tableId && !!scope && !!auth,
      select: (response) => response?.data?.views?.data?.filter((v): v is View => !!v && v.metadata?.scope === scope) ?? [],
      onSuccess: (data) => data,
      onError: () => onError('load'),
    },
  );

  /** To be called when relevant table state changes */
  function viewChangedHook() {
    if (currentViewId) setViewChanged(true);
  }

  useEffect(() => {
    if (views && currentViewId) {
      setViewChanged(true);
      setCurrentView(views.find((v) => v.viewId === currentViewId) || null);
    } else {
      setViewChanged(false);
      setCurrentView(null);
    }
  }, [views, currentViewId]);

  /** Sets current view and resets the change counter */
  function setCurrentViewId(...args: Parameters<typeof _setCurrentViewId>) {
    _setCurrentViewId(...args);
    // Dumb hack to make sure we update *after* the table state does
    setTimeout(() => setViewChanged(false), 1);
  }

  /** Sets table to provided view state */
  function setViewState(view: View) {
    if (!tableInstance) throw new Error('Table not yet initialized');
    if (!view.viewId || !view.metadata?.tableState) throw new Error('Missing view data');

    setCurrentViewId(view.viewId);
  }

  function sanitizeStoredFilterOrSortInput(filterOrSortObject, validOptions: string[]) {
    return filterOrSortObject.filter((filterOrSort) =>
      filterOrSort.id ? validOptions.includes(filterOrSort.id) : validOptions.includes(filterOrSort),
    );
  }

  useEffect(() => {
    if (currentView) {
      if (!tableInstance) throw new Error('Table not yet initialized');
      if (!currentView.viewId || !currentView.metadata?.tableState) throw new Error('Missing view data');
      if (!currentView.metadata?.visibleColumns) throw new Error('Missing visible columns in view data');

      if (!validColumnIds)
        throw new Error(
          `Unable to retrieve valid columns for table with id of ${currentView.metadata?.tableId} view id of ${currentView.viewId}`,
        );

      const defaultTableColumnIds = [
        'metadata.createdAt',
        'metadata.modifiedAt',
        'metadata.deactivatedAt',
        'metadata.createdByUserId',
        'metadata.modifiedByUserId',
      ];

      const columnIds = [...validColumnIds, ...defaultTableColumnIds];
      const tableState: Partial<TableState> = JSON.parse(currentView.metadata.tableState);
      const visibleColumns = JSON.parse(currentView.metadata.visibleColumns);
      const hiddenColumns = tableInstance.allColumns.map((column) => column.id).filter((column) => !visibleColumns.includes(column));

      tableInstance.setAllFilters(sanitizeStoredFilterOrSortInput(tableState.filters ?? [], columnIds));
      tableInstance.setGlobalFilter(tableState.globalFilter ?? '');
      tableInstance.setSortBy(sanitizeStoredFilterOrSortInput(tableState.sortBy ?? [], columnIds));
      tableInstance.setHiddenColumns(sanitizeStoredFilterOrSortInput(hiddenColumns ?? tableState.hiddenColumns ?? [], columnIds));
      tableInstance.setColumnOrder(sanitizeStoredFilterOrSortInput(tableState.columnOrder ?? [], columnIds));
      tableInstance.setPageSize(tableState?.pageSize ?? 15);
    }
  }, [currentView, validColumnIds, tableInstance]);

  /** Returns the table's current view state */
  function getViewState(): ViewState {
    if (!tableInstance) throw new Error('Table not yet initialized');

    const { filters, globalFilter, sortBy, hiddenColumns, columnOrder, pageSize } = tableInstance.state;
    return { filters, globalFilter, sortBy, hiddenColumns, columnOrder, pageSize };
  }

  function clearActiveView() {
    setCurrentView(null);
    setCurrentViewId(null);
    setViewChanged(false);
  }

  const saveViewMutation = useMutation(
    async (metadata: ViewPostInput) => {
      const tableState = getViewState();
      const serializedSearch = JSON.stringify(getSearchFactory(tableId)(tableState));
      const serializedTableState = JSON.stringify(tableState);
      const visibleColumns = JSON.stringify(tableInstance?.visibleColumns?.map((col) => col.id) || []);

      const { data } = await postView(
        { metadata: { ...metadata, tableId, tableState: serializedTableState, search: serializedSearch, visibleColumns } },
        { query: `viewId` },
      );
      if (!data?.post?.viewId) throw new Error('No id after post');
      return data?.post?.viewId;
    },
    {
      onSuccess: (id) => {
        setCurrentViewId(id);
        showAlert({ type: AlertTypes.success, content: 'View saved successfully.' });
        refetch();
      },
      onError: () => onError('save'),
    },
  );

  const onMutateMiddleware = (viewId: string, views: View[] = []) => {
    const context = { viewId };

    const hasPermission = () => {
      const view = views.find((view) => view.viewId === viewId);
      if (!view || view.metadata?.createdByUserId !== auth?.userId) throw new Error('Permission denied');
    };

    if (isAasOrganizationId(auth?.organizationId) && !permissions.isAASAdmin) hasPermission();
    if (!userCan(internalUsersPlusCOA)) hasPermission();

    return context;
  };

  const updateViewMutation = useMutation(
    async (args: { metadata: ViewPatchInput; updateTableState: boolean }) => {
      const tableState = getViewState();
      const serializedSearch = JSON.stringify(getSearchFactory(tableId)(tableState));
      const serializedTableState = JSON.stringify(tableState);
      const visibleColumns = JSON.stringify(tableInstance?.visibleColumns?.map((col) => col.id) || []);

      const metadata = {
        ...args.metadata,
        ...(args.updateTableState ? { tableState: serializedTableState, search: serializedSearch, visibleColumns } : {}),
      };
      await patchView({ viewId: args.metadata.viewId, metadata });
      return args.metadata.viewId;
    },
    {
      onMutate: ({ metadata }) => onMutateMiddleware(metadata.viewId, views),
      onSuccess: (id) => {
        if (currentViewId === id) setViewChanged(false);
        showAlert({ type: AlertTypes.success, content: 'View saved successfully.' });
        refetch();
      },
      onError: (error: Error) => {
        onError('update', error.message);
      },
    },
  );

  const deleteViewMutation = useMutation(
    async (id: string) => {
      await deleteView({ entityId: id });
      return id;
    },
    {
      onMutate: (id) => onMutateMiddleware(id, views),
      onSuccess: (id) => {
        if (currentViewId === id) {
          setViewChanged(false);
          setCurrentView(null);
          setCurrentViewId(null);
        }
        showAlert({ type: AlertTypes.success, content: 'View deleted successfully.' });
        refetch();
      },
      onError: (error: Error) => onError('delete', error.message),
    },
  );

  return {
    views,
    viewChangedHook,
    setViewState,
    clearActiveView,
    viewChanged,
    currentViewId,
    currentView,
    tableInstance,
    setTableInstance,
    scope,
    setScope,
    isFetching,
    saveViewMutation,
    updateViewMutation,
    deleteViewMutation,
  };
}
