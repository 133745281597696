import React from 'react';
import { parseContact } from '../../functions/parseContacts';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../functions/phoneFunctions';

export function formatContact(fullName: string, phone?: string, email?: string) {
  return [fullName, toDisplayPhoneFromPhoneWithCountryCode(phone), email].filter((x) => !!x).join(' - ');
}

export default function ContactsDisplay({ contacts }) {
  const parsedContacts = parseContact(contacts) || [];
  const contactsDisplay = parsedContacts.map((x, i) => <div key={i}>{formatContact(x.fullName, x.phone, x.email)}</div>);

  return <>{contactsDisplay}</>;
}
