import { getCurrentDateAndMonth } from '../../../../common/functions/dateFunctions';
import rock1 from './1.png';
import rock2 from './2.png';
import rock3 from './3.png';
import rock4 from './4.png';
import rock5 from './5.png';
import rock6 from './6.png';
import rock7 from './7.png';
import rock8 from './8.png';
import rock9 from './9.png';
import rock10 from './10.png';

const STORAGE_KEY = 'aprilFoolsMode';

export const isAprilFools = () => {
  const aprilFoolsMode = !!localStorage.getItem(STORAGE_KEY) || false;
  if (aprilFoolsMode) return true;

  const { month, date } = getCurrentDateAndMonth();
  return month === 4 && date === 1;
};

export const toggleAprilFoolsMode = () => {
  if (localStorage.getItem(STORAGE_KEY)) {
    localStorage.removeItem(STORAGE_KEY);
  } else {
    localStorage.setItem(STORAGE_KEY, 'true');
  }
  window.location.reload();
};

export const rockMode = () => {
  const rockPics = [rock1, rock2, rock3, rock4, rock5, rock6, rock7, rock8, rock9, rock10];
  return rockPics[Math.floor(Math.random() * rockPics?.length)];
};
