export const defaultSelectStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    boxShadow: isFocused ? '0 0 0 3px rgba(66, 153, 225, 0.5)' : 'none',
    borderColor: isFocused ? '#4299e1' : '#e2e8f0',
    backgroundColor: isFocused ? 'white' : '#f7fafc',
    borderRadius: '2.1675px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#A0AEC0',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#CBD5E0',
    borderRadius: '12px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: '0 10px 10px 0',
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected ? '#4299E1' : isFocused ? '#EBF8FF' : 'white',
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#A0AEC0',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    width: '35px',
    color: '#4a5568',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
  }),
};
