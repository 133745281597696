import React, { ReactElement, useState } from 'react';
import Close from '@mui/icons-material/Close';
import useLocalStorage from '../../hooks/useLocalStorage';
import IconButton from '../Buttons/IconButton';

interface BaseBannerProps {
  bg?: string;
  textColor?: string;
  textClassName?: string;
  bannerClassName?: string;
  text?: string | ReactElement;
  children?: ReactElement | ReactElement[];
  showOnThisPathOnly?: string;
}

interface BannerPropsWithClose extends BaseBannerProps {
  withClose: true;
  id: string;
}

interface BannerPropsWithoutClose extends BaseBannerProps {
  withClose?: false;
  id?: never;
}

export const bannerContentClass = 'py-1 px-2 text-sm text-center max-h-xs';

const componentStates = {
  open: 1,
  closed: 0,
};

const Banner = ({
  bg = 'bg-equipsLightBlue',
  textColor = 'text-gray-100',
  textClassName = '',
  bannerClassName = '',
  text = '',
  children,
  withClose,
  showOnThisPathOnly = '',
  id,
}: BannerPropsWithClose | BannerPropsWithoutClose) => {
  const [state, setState] = useState(componentStates.open);
  const [hasSeen, setHasSeen] = useLocalStorage(id || 'banner', false);

  const isPath = window.location.pathname === showOnThisPathOnly;

  if (withClose && hasSeen) return null;

  if (showOnThisPathOnly && !isPath) return null;

  return <>
    {state === componentStates.open && (
      <mark className={`${bannerClassName} ${bg} flex w-full items-center`}>
        <div className="w-11/12">
          {children ? children : <p className={`${textColor} ${textClassName} ${bannerContentClass}`}>{text}</p>}
        </div>

        {withClose && (
          <div className={`${textColor} mr-2 w-1/12 text-right`}>
            <IconButton
              Icon={Close}
              text="Close Banner"
              onClick={() => {
                setState(componentStates.closed);
                setHasSeen(true);
              }}
              size="large" />
          </div>
        )}
      </mark>
    )}
  </>;
};

export default Banner;
