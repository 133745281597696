import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';
import Urls from '../../../routes/Urls';

const srMentionPattern = /SR\[(.*?)\]\((.*?)\)/g;
const equipmentMentionPattern = /EQ\[(.*?)\]\((.*?)\)/g;
const invoiceMentionPattern = /IN\[(.*?)\]\((.*?)\)/g;
const userMentionPattern = /@\[(.*?)\]\((.*?)\)/g;

type NoteMessageProps = {
  message?: string | null;
};

export default function NoteMessage({ message = '' }: NoteMessageProps) {
  const formatted = useMemo(() => {
    return DOMPurify.sanitize(message ?? '')
      .replaceAll(srMentionPattern, (substring, ...groups) => {
        const [display, id] = groups;
        return `<a href="${Urls.SERVICE_REQUESTS}/${id}">${display}</a>`;
      })
      .replaceAll(equipmentMentionPattern, (substring, ...groups) => {
        const [display, id] = groups;
        return `<a href="${Urls.EQUIPMENT}/${id}">${display}</a>`;
      })
      .replaceAll(invoiceMentionPattern, (substring, ...groups) => {
        const [display, id] = groups;
        return `<a href="${Urls.INVOICES}/${id}">${display}</a>`;
      })
      .replaceAll(userMentionPattern, (substring, ...groups) => {
        const [display, id] = groups;
        return `<strong class="font-semibold" data-id="${id}">${display}</strong>`;
      });
  }, [message]);

  return (
    <p
      data-testid="noteItemMessage"
      className="whitespace-pre-wrap p-1 text-sm text-gray-700"
      dangerouslySetInnerHTML={{ __html: formatted }}
    />
  );
}
