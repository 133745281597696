import React, { Dispatch, useState, SetStateAction } from 'react';
import {
  patchServiceRequest,
  EntityLabelEnum,
  postNote,
  ServiceRequestStatusType,
  NoteAuthorizationLevel,
  NotesTagsEnum,
  Equipment,
  Location,
  Maybe,
  EquipmentStatusType,
  deactivateServiceRequest,
  ServiceRequest,
  ClientOrganizationEnumType,
  OrganizationTagsEnum,
  RecurrenceOptionsType,
} from '@equips/entities-schema';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { AlertTypes, useAlert } from '../../../../common/components/Alerts/AlertContext';
import UserSelectDialog, { UserSelectDialogContext } from '../../../../common/components/Selects/UserSelectDialog';
import SimpleModal, { simpleModalTypes } from '../../../../common/components/Modal/SimpleModal';
import Button from '../../../../common/components/Buttons/Button';
import Radio from '../../../../common/components/Form/Radio';
import Label from '../../../../common/components/Form/Label';
import Textarea from '../../../../common/components/Form/Textarea';
import { escalatedSeverityNumber } from '../../../../graphql/enums';
import FormGroup from '../../../../common/components/Form/FormGroup';
import Checkbox from '../../../../common/components/Form/Checkbox';
import { useAuth } from '../../../../common/auth/AuthContext';
import Urls from '../../../../routes/Urls';
import NoteForm from '../../../../common/components/ViewPage/NoteForm';
import RadioCard from '../../../../common/components/Form/RadioCards';
import { descriptionMap } from '../../../../common/components/ServiceRequest/RequestFormRecurrenceOption';
import { getClosingStatus } from '../ServiceRequestStatusTracker/statuses';
import RedispatchModal from './RedispatchModal';
import PatchEquipmentModal from './PatchEquipmentModal';
import ProposedSRWithProposedEquipmentModal from './ProposedSRWithProposedEquipmentModal';
import PurchaseOrderEmail from './PurchaseOrderEmail';
import { PartRequestForm } from './PartRequest';
import DispatchModal from './DispatchModal';
import AssignPartsModal from './AssignPartsModal';

const confirmationModalText = {
  assignParts: {
    title: 'Assign Parts',
    subtitle: 'Select parts from inventory to assign to this service request.',
    confirmationButtonText: 'Submit',
  },
  cancel: {
    title: 'Request cancellation',
    subtitle: 'Submit a request to cancel the service request.',
    confirmationButtonText: 'Cancel the request',
  },
  canceled: {
    title: 'Request cancellation',
    subtitle: 'Submit a request to cancel the service request.',
    confirmationButtonText: 'Cancel the request',
  },
  closeRequest: {
    title: 'Close the Service Request',
    subtitle: 'This will put the service request in the closed status.',
    confirmationButtonText: 'Close the request',
  },
  dispatchExternally: {
    title: 'Dispatch service request externally',
    subtitle: 'You are about to dispatch your service request to your provider.',
    confirmationButtonText: 'Dispatch request externally',
  },
  dispatchToEquips: {
    title: 'Dispatch service request to Equips',
    subtitle: 'You are about to dispatch your service request to Equips.',
    confirmationButtonText: 'Dispatch request to Equips',
  },
  dispatchInternally: {
    title: 'Internal dispatch has not been set up yet',
    subtitle: 'Current dispatch preference for this organization is set to forward all service request to Equips',
    confirmationButtonText: 'Change dispatch preference',
  },
  partRequest: {
    title: 'Request Approval',
    subtitle: 'Request approval for expensive repair',
    equipsCoverageSubtitle: 'Request approval from Equips for expensive repair',
    confirmationButtonText: 'Close',
  },
  denyProposedSR: {
    title: 'Deny Service Request',
    subtitle: 'This will deactivate the service request',
    confirmationButtonText: 'Deny',
  },
  followUp: {
    title: 'Follow up needed',
    subtitle: 'Complete the form by adding follow up notes below',
    confirmationButtonText: 'Close',
  },
  purchaseOrderEmail: {
    title: 'Send PO',
    confirmationButtonText: 'Send Email',
  },
};

export enum modalStateOptions {
  assignParts = 'assignParts',
  cancel = 'cancel',
  canceled = 'canceled',
  escalate = 'escalate',
  deescalate = 'deescalate',
  patch = 'patch',
  closed = 'closed',
  dispatchToEquips = 'dispatchToEquips',
  dispatchExternally = 'dispatchExternally',
  closeRequest = 'closeRequest',
  dispatchInternally = 'dispatchInternally',
  partRequest = 'partRequest',
  denyProposedSR = 'denyProposedSR',
  redispatch = 'redispatch',
  providerMarkedComplete = 'providerMarkedComplete',
  changePmRecurrence = 'changePmRecurrence',
  changeSrRecurrence = 'changeSrRecurrence',
  assignInternalDispatchToInternalUser = 'assignInternalDispatchToInternalUser',
  followUp = 'followUp',
  purchaseOrderEmail = 'purchaseOrderEmail',
}

type ModifyTheServiceRequestProps = {
  fetch: () => any;
  setModalState: Dispatch<SetStateAction<modalStateOptions>>;
  serviceRequestId: string;
  incidentIds?: string[];
  organizationId?: string;
  organizationTags?: Maybe<OrganizationTagsEnum>[];
  modalState: modalStateOptions;
  equipment: Maybe<Equipment>;
  location?: Maybe<Location>;
  canDispatchInternally: boolean;
  serviceRequestData: Maybe<ServiceRequest>;
};

export default function ModifyTheServiceRequest({
  fetch,
  modalState,
  setModalState,
  incidentIds = [],
  serviceRequestId,
  organizationId,
  organizationTags = [],
  equipment,
  location,
  canDispatchInternally,
  serviceRequestData,
}: ModifyTheServiceRequestProps) {
  const [escalationReason, setEscalationReason] = useState('');
  const [escalationNotes, setEscalationNotes] = useState('');
  const [escalationResolutionReason, setEscalationResolutionReason] = useState('');
  const [escalationResolutionNotes, setEscalationResolutionNotes] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [cancellationOtherReason, setCancellationOtherReason] = useState('');
  const [cancellationNotes, setCancellationNotes] = useState('');
  const [savingEscalation, setSavingEscalation] = useState(false);
  const [closeRequest, setCloseRequest] = useState(false);
  const [denialReason, setDenialReason] = useState('');
  const [recurrenceInterval, setRecurrenceInterval] = useState(serviceRequestData?.metadata?.recurrenceInterval);
  const [recurrenceOption, setRecurrenceOption] = useState(serviceRequestData?.metadata?.recurrenceOption || RecurrenceOptionsType.Once);
  const [followUpNotes, setFollowUpNotes] = useState<string>('');

  const providerOrganization = equipment?.metadata?.provider;

  const assignedInternalUser = serviceRequestData?.metadata?.assignedInternalUser?.metadata?.fullName ?? '';
  const { auth, userCan, internalUsers, isAasUser } = useAuth();
  const clientOrganizationType = serviceRequestData?.metadata?.organization?.metadata?.clientOrganizationType;
  const showAlert = useAlert();
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleClose = () => setModalState(modalStateOptions.closed);

  const closingStatus = getClosingStatus(clientOrganizationType);

  const { title, subtitle, equipsCoverageSubtitle, confirmationButtonText } = confirmationModalText[modalState] || {};

  const { mutate: wrappedPatchServiceRequest, isLoading: loadingSRPatch } = useMutation(patchServiceRequest, {
    onSuccess: async () => {
      showAlert({ type: AlertTypes.success, content: 'Successfully updated service request' });
      await fetch();
      queryClient.invalidateQueries(['NoteListWithForm', serviceRequestId]);
      handleClose();
    },
  });

  const { mutate: escalateSeverity } = useMutation(patchServiceRequest, {
    onSuccess: async () => {
      showAlert({ type: AlertTypes.success, content: "We've escalated your request and notified all appropriate parties" });
    },
  });

  const { mutate: resolveEscalationSeverity } = useMutation(patchServiceRequest, {
    onSuccess: async () => {
      showAlert({ type: AlertTypes.success, content: "We've resolved your escalation request and notified all appropriate parties" });
    },
  });

  const { mutate: assignSrInternaly } = useMutation(patchServiceRequest, {
    onSuccess: async () => {
      showAlert({ type: AlertTypes.success, content: "We've assigned that SR to the user you specified" });
      await fetch();
    },
  });

  const { mutate: denyServiceRequest } = useMutation(
    async () => {
      await deactivateServiceRequest({ entityId: serviceRequestId });
    },
    {
      onSuccess: async () => {
        showAlert({ type: AlertTypes.success, content: 'Request has been denied and deactivated' });
        await fetch();
        handleClose();
      },
    },
  );

  const equipmentIsProposed = equipment?.metadata?.equipmentStatus === EquipmentStatusType.Proposed;

  if (modalState === modalStateOptions.patch) {
    return (
      <PatchEquipmentModal
        equipment={equipment}
        location={location}
        incidentIds={incidentIds}
        organizationId={organizationId ?? ''}
        patchEquipmentModalOpen={modalState === modalStateOptions.patch}
        handleClose={() => {
          handleClose();
          fetch();
        }}
      />
    );
  }

  if (modalState === modalStateOptions.providerMarkedComplete) {
    return (
      <SimpleModal
        isOpen={modalState === modalStateOptions.providerMarkedComplete}
        handleClose={() => setModalState(modalStateOptions.closed)}
        testId="completedModal"
      >
        <div className="p-2">
          <h2 className="mb-3 text-2xl font-semibold text-blue-900">Work Completed</h2>
          Thank you! Please add any closing notes below.
          <h2 className="my-3 text-xl font-semibold text-blue-900">Closing Notes</h2>
          <NoteForm
            parentLabel={EntityLabelEnum.ServiceRequest}
            entityId={serviceRequestId}
            defaultCheckedVisibleTo={NoteAuthorizationLevel.ClientAndEquips}
            onCreated={() => {
              wrappedPatchServiceRequest({
                serviceRequestId,
                metadata: {
                  requestStatus: closingStatus,
                },
              });
              setModalState(modalStateOptions.closed);
            }}
            tags={[NotesTagsEnum.ClosingNotes]}
          />
          <div className="flex justify-end py-2">
            <Button data-testid="skipClosingNotes" tiny outlined blue onClick={() => setModalState(modalStateOptions.closed)}>
              Skip
            </Button>
          </div>
        </div>
      </SimpleModal>
    );
  }

  if (modalState === modalStateOptions.changePmRecurrence) {
    return (
      <SimpleModal
        modalType={simpleModalTypes.info}
        title="Change Preventive Maintenance Recurrence"
        subtitle="This will affect all the future PMs. Providers can take up to 30-60 days after dispatch date to perform the maintenance"
        isOpen={modalState === modalStateOptions.changePmRecurrence}
        handleClose={handleClose}
        size="md"
      >
        <form
          className="mt-4"
          onSubmit={() => {
            wrappedPatchServiceRequest({
              serviceRequestId,
              metadata: { recurrenceInterval },
            });
            setModalState(modalStateOptions.closed);
          }}
        >
          <RadioCard
            id="recurringPMFrequency"
            label="Set Recurring PM Frequency"
            cardSize="flex-grow"
            options={[
              { label: 'Do Not Repeat', value: 0 },
              { label: 'Every 3 Months', value: 90 },
              { label: 'Every 6 Months', value: 180 },
              { label: 'Every 12 Months', value: 360 },
            ]}
            onChange={(value: number) => {
              setRecurrenceInterval(value);
            }}
            value={recurrenceInterval ?? 0}
          />
          <Button className="ml-auto mt-4 block" type="submit">
            Change Recurrence
          </Button>
        </form>
      </SimpleModal>
    );
  }

  if (modalState === modalStateOptions.changeSrRecurrence) {
    const recurrenceHelperDescription = descriptionMap[recurrenceOption] || (() => '');
    return (
      <SimpleModal
        modalType={simpleModalTypes.info}
        title="Change Service Request Recurrence"
        subtitle="This will affect all the future recurring service requests"
        isOpen={modalState === modalStateOptions.changeSrRecurrence}
        handleClose={handleClose}
        size="md"
      >
        <form
          className="mt-4"
          onSubmit={() => {
            wrappedPatchServiceRequest({
              serviceRequestId,
              metadata: { recurrenceOption, recurrenceInterval },
            });
            setModalState(modalStateOptions.closed);
          }}
        >
          <RadioCard
            id="recurringSrOptions"
            label="Set Recurring Service Request Option"
            labelSubtitle={recurrenceHelperDescription({ recurrenceInterval: recurrenceInterval || 1 })}
            cardSize="flex-grow"
            options={[
              { label: 'One Time', value: RecurrenceOptionsType.Once },
              { label: 'Monthly', value: RecurrenceOptionsType.Monthly },
              { label: 'Custom', value: RecurrenceOptionsType.Custom },
            ]}
            onChange={(value: RecurrenceOptionsType) => setRecurrenceOption(value)}
            value={recurrenceOption}
          />
          {recurrenceOption === RecurrenceOptionsType.Custom && (
            <div className="mt-4 flex items-center gap-3">
              Every
              <TextField
                value={recurrenceInterval || 1}
                onChange={(e) => setRecurrenceInterval(parseInt(e.target.value))}
                id="customRecurrenceOption"
                InputProps={{
                  inputProps: { type: 'number', min: 0 },
                }}
                variant="outlined"
                size="small"
                required={recurrenceOption === RecurrenceOptionsType.Custom}
              />
              Days
            </div>
          )}
          <Button className="ml-auto mt-4 block" type="submit">
            Change Recurrence
          </Button>
        </form>
      </SimpleModal>
    );
  }

  if (modalState === modalStateOptions.redispatch) {
    return (
      <SimpleModal
        modalType={simpleModalTypes.danger}
        title={isAasUser ? 'Change Service Provider' : 'Redispatch this request to a new provider'}
        subtitle={
          isAasUser
            ? 'This will update the Service Provider on the claim'
            : 'This will assign the new request to a provider and cancel the old request'
        }
        isOpen={modalState === modalStateOptions.redispatch}
        handleClose={handleClose}
      >
        <RedispatchModal
          locationId={location?.metadata?.locationId}
          serviceRequest={serviceRequestData}
          equipment={equipment}
          clientOrganizationType={clientOrganizationType}
          organizationTags={organizationTags}
          patchServiceRequest={wrappedPatchServiceRequest}
          loadingSRPatch={loadingSRPatch}
        />
      </SimpleModal>
    );
  }

  if (modalState === modalStateOptions.assignInternalDispatchToInternalUser) {
    return (
      <UserSelectDialog
        organizationId={auth?.organizationId || ''}
        handleSelect={async ({ userId }) => {
          await assignSrInternaly({ serviceRequestId, metadata: { assignedInternalUserId: userId } });
        }}
        showUnassigned={true}
        open={modalStateOptions.assignInternalDispatchToInternalUser ? true : false}
        handleClose={() => handleClose()}
        existingSearch={assignedInternalUser}
        context={UserSelectDialogContext.AssignInternalUsers}
      />
    );
  }

  if (modalState === modalStateOptions.deescalate) {
    return (
      <SimpleModal
        modalType={simpleModalTypes.info}
        title="Resolve escalated request"
        subtitle="We're glad your escalation request has been resolved."
        isOpen={modalState === modalStateOptions.deescalate}
        handleClose={handleClose}
      >
        <div className="border-b border-gray-300 pt-4" />
        <form
          data-testid="deescalateServiceRequestForm"
          className="pt-4"
          onSubmit={async (event) => {
            event.preventDefault();
            setSavingEscalation(true);
            await resolveEscalationSeverity({ serviceRequestId, metadata: { severity: 3 } });
            await postNote({
              metadata: {
                parentId: serviceRequestId,
                parentLabel: EntityLabelEnum.ServiceRequest,
                authorizationLevel: NoteAuthorizationLevel.All,
                message: `Escalation has been resolved. Notes: ${escalationResolutionNotes}`,
                tags: [NotesTagsEnum.Escalated],
              },
            });
            fetch();
            handleClose();
            setSavingEscalation(false);
          }}
        >
          <div className="p-2">
            <Radio
              id="deescalationReason"
              label="What is your reason for resolving your request?"
              labelClassName="text-sm pb-2 text-gray-700"
              onChange={({ target: { value } }) => setEscalationResolutionReason(value)}
              isHalf={true}
              value={escalationResolutionReason}
              options={[]}
            />
          </div>
          <div className="p-2">
            <Textarea
              data-testid="escalationNotes"
              aria-label="Add a note"
              id="escalationNotes"
              onChange={({ target: { value } }) => setEscalationResolutionNotes(value)}
              value={escalationResolutionNotes}
              placeholder="Add a note"
            />
          </div>
          <div className="mt-2">
            <Button loading={savingEscalation} blue className="block w-full" type="submit">
              Resolve escalation request
            </Button>
          </div>
        </form>
      </SimpleModal>
    );
  }

  if (modalState === modalStateOptions.escalate) {
    return (
      <SimpleModal
        modalType={simpleModalTypes.info}
        title="Escalate request"
        subtitle="We're sorry to hear you need to escalate this request."
        isOpen={modalState === modalStateOptions.escalate}
        handleClose={handleClose}
      >
        <div className="border-b border-gray-300 pt-4" />
        <form
          data-testid="escalateServiceRequestForm"
          className="pt-4"
          onSubmit={async (event) => {
            event.preventDefault();
            setSavingEscalation(true);
            await escalateSeverity({ serviceRequestId, metadata: { severity: escalatedSeverityNumber } });
            await postNote({
              metadata: {
                parentId: serviceRequestId,
                parentLabel: EntityLabelEnum.ServiceRequest,
                authorizationLevel: NoteAuthorizationLevel.All,
                message: `Please escalate the request. Reason: ${escalationReason}, Notes: ${escalationNotes}`,
                tags: [NotesTagsEnum.Escalated],
              },
            });
            fetch();
            handleClose();
            setSavingEscalation(false);
          }}
        >
          <div className="p-2">
            <Radio
              id="escalationReason"
              label="What is your reason for escalation?"
              labelClassName="text-sm pb-2 text-gray-700"
              onChange={({ target: { value } }) => setEscalationReason(value)}
              required
              isHalf={true}
              value={escalationReason}
              options={[
                { value: 'Service tech has not arrived.', label: 'Service tech has not arrived.' },
                { value: 'Better ETA is needed.', label: 'Better ETA is needed.' },
                { value: 'Issue is very urgent.', label: 'Issue is very urgent.' },
                { value: 'Issue has returned.', label: 'Issue has returned.' },
                { value: 'Issue is not completely resolved.', label: 'Issue is not completely resolved.' },
                { value: 'Other', label: 'Other' },
              ]}
            />
          </div>
          <div className="p-2">
            <Textarea
              data-testid="escalationNotes"
              aria-label="Add a note"
              id="escalationNotes"
              onChange={({ target: { value } }) => setEscalationNotes(value)}
              value={escalationNotes}
              placeholder="Add a note"
            />
          </div>
          <div className="mt-2">
            <Button loading={savingEscalation} blue className="block w-full" type="submit">
              Escalate request
            </Button>
          </div>
        </form>
      </SimpleModal>
    );
  }

  if (!canDispatchInternally && modalState === modalStateOptions.dispatchInternally) {
    return (
      <SimpleModal
        title={title}
        subtitle={subtitle}
        modalType={simpleModalTypes.info}
        isOpen={!canDispatchInternally && modalState === modalStateOptions.dispatchInternally}
        handleClose={handleClose}
      >
        <form
          className="pt-4"
          data-testid="statusChangeServiceRequestForm"
          onSubmit={async (event) => {
            event.preventDefault();
            history.push(userCan(internalUsers) ? `${Urls.ORGANIZATIONS}/${auth?.organizationId}#2` : `${Urls.SETTINGS_ORGANIZATION}#1`);
          }}
        >
          <div>
            <Button blue loading={loadingSRPatch} className="block w-full" type="submit">
              {confirmationButtonText}
            </Button>
          </div>
        </form>
      </SimpleModal>
    );
  }

  if (equipmentIsProposed && (modalState === modalStateOptions.dispatchToEquips || modalState === modalStateOptions.dispatchInternally)) {
    return (
      <ProposedSRWithProposedEquipmentModal
        isOpen={
          equipmentIsProposed && (modalState === modalStateOptions.dispatchToEquips || modalState === modalStateOptions.dispatchInternally)
        }
        handleClose={handleClose}
        equipment={equipment}
        dispatchToEquips={modalState === modalStateOptions.dispatchToEquips}
        serviceRequestId={serviceRequestId}
        canDispatchInternally={canDispatchInternally}
      />
    );
  }

  if (modalState === modalStateOptions.denyProposedSR) {
    return (
      <SimpleModal
        title={title}
        subtitle={subtitle}
        modalType={simpleModalTypes.info}
        isOpen={modalState === modalStateOptions.denyProposedSR}
        handleClose={handleClose}
      >
        <form
          className="pt-4"
          data-testid="statusChangeServiceRequestForm"
          onSubmit={async (event) => {
            event.preventDefault();
            await postNote({
              metadata: {
                parentId: serviceRequestId,
                parentLabel: EntityLabelEnum.ServiceRequest,
                authorizationLevel: NoteAuthorizationLevel.All,
                message: `Request denied. Reason: ${denialReason}`,
              },
            });
            denyServiceRequest();
          }}
        >
          <FormGroup fullWidth>
            <Label id="reasonNote" label={`What is your reason for denial?`} />
            <Textarea
              data-testid="denialReason"
              aria-label="What is your reason for denial?"
              id="denialReason"
              onChange={({ target: { value } }) => setDenialReason(value)}
              value={denialReason}
              placeholder="Reason"
            />
          </FormGroup>
          <div className="">
            <Button blue loading={loadingSRPatch} className="block w-full" type="submit">
              {confirmationButtonText}
            </Button>
          </div>
        </form>
      </SimpleModal>
    );
  }

  if (modalState === modalStateOptions.partRequest) {
    return (
      <SimpleModal
        title={title}
        subtitle={clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage ? equipsCoverageSubtitle : subtitle}
        modalType={simpleModalTypes.info}
        isOpen={modalState === modalStateOptions.partRequest}
        handleClose={handleClose}
      >
        <div className="pt-8">
          <PartRequestForm serviceRequestId={serviceRequestId} onCompleted={handleClose} />
        </div>
      </SimpleModal>
    );
  }

  if (modalState === modalStateOptions.followUp) {
    return (
      <SimpleModal
        title={title}
        subtitle={subtitle}
        modalType={simpleModalTypes.info}
        isOpen={modalState === modalStateOptions.followUp}
        handleClose={handleClose}
      >
        <div className="pt-4">
          <NoteForm
            parentLabel={EntityLabelEnum.ServiceRequest}
            entityId={serviceRequestId}
            defaultCheckedVisibleTo={NoteAuthorizationLevel.All}
            onInputChange={({ target: { value } }) => setFollowUpNotes(value.trim())}
            onCreated={() => {
              wrappedPatchServiceRequest({
                serviceRequestId,
                metadata: { requestStatus: ServiceRequestStatusType.FollowUp },
              });
              setModalState(modalStateOptions.closed);
            }}
            tags={[NotesTagsEnum.FollowUp]}
          >
            {!followUpNotes ? <FormHelperText className="text-red-500">Please add a reason for a return visit</FormHelperText> : null}
          </NoteForm>
        </div>
      </SimpleModal>
    );
  }

  if (modalState === modalStateOptions.dispatchExternally) {
    return (
      <DispatchModal
        locationId={location?.metadata?.locationId}
        serviceRequestId={serviceRequestId}
        clientOrganizationType={clientOrganizationType}
        equipment={equipment}
        provider={providerOrganization}
        organizationTags={organizationTags}
        patchServiceRequest={wrappedPatchServiceRequest}
        loadingSRPatch={loadingSRPatch}
        modalProps={{
          title,
          subtitle,
          modalType: simpleModalTypes.info,
          isOpen: [modalStateOptions.dispatchExternally].includes(modalState),
          handleClose,
          confirmationButtonText,
        }}
      />
    );
  }

  if (modalState === modalStateOptions.purchaseOrderEmail) {
    return (
      <PurchaseOrderEmail
        serviceRequest={serviceRequestData ?? {}}
        modalProps={{
          title,
          subtitle,
          modalType: simpleModalTypes.info,
          isOpen: modalState === modalStateOptions.purchaseOrderEmail,
          handleClose,
          size: 'md',
        }}
      />
    );
  }

  if (modalState === modalStateOptions.assignParts) {
    return (
      <AssignPartsModal
        serviceRequest={serviceRequestData ?? {}}
        modalProps={{
          title,
          subtitle,
          modalType: simpleModalTypes.info,
          isOpen: modalState === modalStateOptions.assignParts,
          handleClose,
          size: 'md',
        }}
      />
    );
  }

  return (
    <SimpleModal
      title={title}
      subtitle={subtitle}
      modalType={simpleModalTypes.info}
      isDismissible={!(modalState === modalStateOptions.canceled)}
      isOpen={[
        modalStateOptions.cancel,
        modalStateOptions.canceled,
        modalStateOptions.closeRequest,
        modalStateOptions.dispatchToEquips,
        modalStateOptions.dispatchExternally,
      ].includes(modalState)}
      handleClose={handleClose}
    >
      <form
        className="pt-4"
        data-testid="statusChangeServiceRequestForm"
        onSubmit={async (event) => {
          event.preventDefault();

          if (modalState === modalStateOptions.cancel || modalState === modalStateOptions.canceled) {
            await postNote({
              metadata: {
                parentId: serviceRequestId,
                parentLabel: EntityLabelEnum.ServiceRequest,
                authorizationLevel: NoteAuthorizationLevel.All,
                message: `Please cancel the request. Reason: ${cancellationReason}${
                  cancellationOtherReason ? `,\n${cancellationOtherReason}\n` : ', '
                }Notes: ${cancellationNotes}`,
                tags: [NotesTagsEnum.Cancelled],
              },
            });
            wrappedPatchServiceRequest({
              serviceRequestId,
              metadata: {
                ...(closeRequest ? { requestStatus: closingStatus } : { requestStatus: ServiceRequestStatusType.Canceled }),
                cancelationRequested: true,
              },
            });
          } else if (modalState === modalStateOptions.closeRequest) {
            wrappedPatchServiceRequest({
              serviceRequestId,
              metadata: { requestStatus: closingStatus },
            });
          } else if (modalState === modalStateOptions.dispatchToEquips) {
            wrappedPatchServiceRequest({
              serviceRequestId,
              metadata: { requestStatus: ServiceRequestStatusType.EquipsDispatch },
            });
          }
        }}
      >
        {(modalState === modalStateOptions.cancel || modalState === modalStateOptions.canceled) && (
          <FormGroup fullWidth>
            {userCan(internalUsers) && (
              <Checkbox
                label="Set service request status to closed"
                id="cancelRequest"
                checked={closeRequest}
                onChange={(event) => setCloseRequest(event?.target?.checked)}
              />
            )}
            <div className="p-2">
              <Radio
                id="cancelationReason"
                label="What is your reason for cancellation?"
                labelClassName="text-sm pb-2 text-gray-700"
                onChange={({ target: { value } }) => setCancellationReason(value)}
                required
                isHalf={true}
                value={cancellationReason}
                options={[
                  { value: 'Issue resolved itself', label: 'Issue resolved itself' },
                  { value: 'Internal team resolved issue', label: 'Internal team resolved issue' },
                  { value: 'Wrong service provider selected', label: 'Wrong service provider selected' },
                  { value: 'Wrong equipment selected ', label: 'Wrong equipment selected' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
            </div>
            {cancellationReason === 'Other' && (
              <div className="p-2 pb-4">
                <Textarea
                  data-testid="cancellationOtherReason"
                  aria-label="What is your reason for cancellation?"
                  id="cancellationOtherReason"
                  onChange={({ target: { value } }) => setCancellationOtherReason(value)}
                  value={cancellationOtherReason}
                  placeholder="Other Reason"
                  required
                />
              </div>
            )}
            <div className="p-2">
              <Textarea
                data-testid="cancellationNotes"
                aria-label="Add a note"
                id="cancellationNotes"
                onChange={({ target: { value } }) => setCancellationNotes(value)}
                value={cancellationNotes}
                placeholder="Add a note"
              />
            </div>
          </FormGroup>
        )}

        <div className="">
          <Button blue loading={loadingSRPatch} className="block w-full" type="submit">
            {confirmationButtonText}
          </Button>
        </div>
      </form>
    </SimpleModal>
  );
}
