import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from '../../common/auth/AuthContext';
import { AuthenticatedRoute } from '../../routes/MainRouter';

export const TASKS_URLS = {
  ROOT: '/tasks',
  TASK_ADD_FORM: '/add',
  TASK_EDIT_FORM: '/edit/:taskId',
  TASK_ITEM_DETAILS: '/details/:entity/:id',
  TASK_ENTITY_TABLE: '/table/:entity',
};

export const tasksRoutes = [
  {
    path: TASKS_URLS.ROOT,
    component: lazy(() => import('./TasksApp')),
    routes: [
      {
        path: `${TASKS_URLS.ROOT}${TASKS_URLS.TASK_ADD_FORM}`,
        component: lazy(() => import('./components/TaskForm')),
      },
      {
        path: `${TASKS_URLS.ROOT}${TASKS_URLS.TASK_EDIT_FORM}`,
        component: lazy(() => import('./components/TaskForm')),
      },
      {
        path: `${TASKS_URLS.ROOT}${TASKS_URLS.TASK_ITEM_DETAILS}`,
        component: lazy(() => import('./screens/view-task/ViewTask')),
      },
      // {
      //   path: `${TASKS_URLS.ROOT}${TASKS_URLS.TASK_ENTITY_TABLE}`,
      //   component: lazy(() => import('./screens/ViewEntityTable')),
      // },
    ],
  },
];

function TasksRouter({ routes }) {
  const { auth } = useAuth();

  return (
    <div className="min-h-full bg-gray-200">
      <Switch>
        {routes.map((route) => (
          <AuthenticatedRoute key={route.path} {...route} auth={auth} />
        ))}
      </Switch>
    </div>
  );
}

export default TasksRouter;
