import React, { useState } from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import { EntityLabelEnum, NotificationMethodEnum, postNotification, ServiceRequest } from '@equips/entities-schema';
import MailOutlined from '@mui/icons-material/MailOutlined';
import Button from '../../common/components/Buttons/Button';
import IconButton from '../../common/components/Buttons/IconButton';
import FormFields, { fieldTypes } from '../../common/components/Form/FormFields';
import Label from '../../common/components/Form/Label';
import SimpleModal from '../../common/components/Modal/SimpleModal';
import { RichTextEditor } from '../../common/components/RichTextEditor';
import Urls from '../../routes/Urls';
import useMutation from '../../common/hooks/useMutation';

const OnHoldFollowUpEmail = (props: ServiceRequest) => {
  const serviceRequestShortId = props.metadata?.shortId;
  const serviceRequestExternalId = props.metadata?.providerRefId;
  const serviceRequestTitle = props.metadata?.title;
  const providerOrganizationName = props.providerMetadata?.organizationName;
  const assignedProviderEmail = props.metadata?.provider?.provider?.dispatchEmail;
  const assignedProviderPhone = props.metadata?.provider?.provider?.dispatchPhone;
  const locationName = props.locationMetadata?.locationDisplayName;
  const locationAddressAsOneLine = formatAddressAsOneLine(props.metadata?.location?.address);
  const equipmentShortId = props.equipmentMetadata?.shortId;
  const equipmentName = props.equipmentMetadata?.equipmentName;
  const categoryName = props.metadata?.equipment?.specMetadata?.category?.metadata?.name || '';
  const model = props.metadata?.equipment?.specMetadata?.model;
  const manufacturer = props.metadata?.equipment?.specMetadata?.manufacturer;
  const serialNumber = props.equipmentMetadata?.serialNumber;
  const externalId = props.equipmentMetadata?.externalId;

  // stolen from outbound serviceRequestBody.hbs
  const defaultHtml = `
  Hello, ${props.organizationMetadata?.organizationName} would like an update about the service request below. 
  Please provide a technician or parts ETA.<br>
  <p>If this request has been completed, please send the technician's closing notes. Thank you. </p><br>
  <table style="border-collapse: collapse; font-family:sans-serif; color: #3b3b3b;">
  <tbody>
      <tr>
          <td style="padding: 5px 0px; border-top: #cdcdcd 1px solid; ">
              <table style="width: 90%;" border="0">
                  <tbody>
                      <tr style="">
                          <td style="">
                              <span style="font-weight: 600;color:#6f7782; font-size: 13px">Service request:</span>
                          </td>
                      </tr>
                      <tr style="">
                          <td style="">
                              <span style="font-size: 15px">
                              <a style="color:#3E88C5; font-weight:600" href="${`${Urls.SERVICE_REQUESTS}/${props.metadata?.serviceRequestId}`}">${serviceRequestShortId}</a>
                              ${serviceRequestExternalId ? `/ ${serviceRequestExternalId}` : ''}<br>
                              ${serviceRequestTitle}
                              </span>
                          </td>
                      </tr>
                      <tr style="">
                          <td style="font-size: 13px">
                              Provider: ${providerOrganizationName}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </td>
      </tr>
      <tr>
          <td style="padding: 5px 0px;">
              <table style="width: 90%" border="0">
                  <tbody>
                      <tr style="">
                          <td style=" ">
                              <span style="font-weight: 600;color:#6f7782;font-size: 13px">Location:</span>
                          </td>
                      </tr>
                      <tr style="">
                          <td style="">
                              <span style="font-size: 15px">${locationName}</span>
                          </td>
                      </tr>
                      <tr style="">
                          <td style="font-size: 13px">
                              ${locationAddressAsOneLine}
                          </td>
                      </tr>
                  </tbody>
              </table>
          </td>
      </tr>
      <tr>
          <td style="padding: 5px 0px;">
              <table style="width: 90%" border="0">
                  <tbody>
                      <tr style="">
                          <td style="">
                              <span style="font-weight: 600;color:#6f7782;font-size: 13px">Equipment:</span>
                          </td>
                      </tr>
                      <tr style="">
                          <td style="">
                              <span style="font-size: 15px">
                                  ${equipmentShortId}
                                  ${equipmentName ? `: ${equipmentName}` : ''}
                              </span>
                          </td>
                      </tr>
                      <tr style="">
                          <td style="font-size: 13px">
                              ${categoryName ? `Category: ${categoryName}` : ''} 
                              ${model ? `, Model: ${model}` : ''} 
                              ${manufacturer ? `, Manufacturer: ${manufacturer}` : ''} 
                              ${serialNumber ? `, Serial Number: ${serialNumber}` : ''} 
                              ${externalId ? `, External Id: ${externalId}` : ''} 
                          </td>
                      </tr>
                  </tbody>
              </table>
          </td>
      </tr>
  </tbody>
  </table>`;

  const [openEmailModal, setOpenEmailModal] = useState(false);

  const [{ to, subject }, setState] = useState({
    to: assignedProviderEmail,
    subject: `Equips Update Needed for ${props.organizationMetadata?.organizationName} | ID: ${serviceRequestShortId}`,
  });
  const [htmlMessage, setHtmlMessage] = useState(defaultHtml);

  const [postOnHoldsFollowUpEmailNotification, { saving }] = useMutation(
    () =>
      postNotification({
        metadata: {
          meetsPreferences: true, // need to set to true to bypass pref
          templateName: 'onHoldsFollowUpEmail',
          data: JSON.stringify({ to, subject, htmlMessage }),
          entityId: props.metadata?.serviceRequestId,
          entityLabel: EntityLabelEnum.ServiceRequest,
          notificationMethod: NotificationMethodEnum.Email,
          requestedEmailAddress: to,
        },
      }),
    {
      onCompleted: () => {
        setOpenEmailModal(false);
      },
      successMessage: 'Follow up message sent',
    },
  );

  return <>
    <IconButton
        Icon={MailOutlined}
        text="Send Follow-Up Email"
        onClick={() => setOpenEmailModal(true)}
        size="large" />
    {openEmailModal && (
      <SimpleModal size={'md'} handleClose={() => setOpenEmailModal(false)} title="Send Follow Up Email">
        {to ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              postOnHoldsFollowUpEmailNotification();
            }}
          >
            <FormFields
              handleChange={({ target: { id, value } }) => setState((state) => ({ ...state, [id]: value }))}
              fields={[
                {
                  fields: [
                    {
                      label: 'To',
                      helper: 'to change the email recipient, please change provider dispatch email.',
                      id: 'onHoldEmailRecipient',
                      type: fieldTypes.text,
                      value: to,
                      readOnly: true,
                    },
                    {
                      label: 'Subject',
                      id: 'onHoldEmailSubject',
                      type: fieldTypes.text,
                      required: true,
                      value: subject,
                    },
                  ],
                },
              ]}
            />
            <Label id="onHoldEmailMessage" label="Message *" />
            <RichTextEditor defaultValue={htmlMessage} onChange={setHtmlMessage} />
            <Button loading={saving} className="my-4 ml-auto block">
              Send Email
            </Button>
          </form>
        ) : (
          <div className="mt-4">Email address not found for this provider. Add email address or call : {assignedProviderPhone}</div>
        )}
      </SimpleModal>
    )}
  </>;
};

export default OnHoldFollowUpEmail;
