import { IndustryType } from '@equips/entities-schema';
import React, { useState } from 'react';
import { useAuth } from '../../common/auth/AuthContext';
import Button from '../../common/components/Buttons/Button';
import FormGroup from '../../common/components/Form/FormGroup';
import Input from '../../common/components/Form/Input';
import Label from '../../common/components/Form/Label';
import RadioCard from '../../common/components/Form/RadioCards';
import StyledSelect from '../../common/components/Selects/StyledSelect';
import { industries } from '../../common/constants/industries';
import useMutation from '../../common/hooks/useMutation';
import { createLocation } from '../../graphql/mutations/LocationMutations';
import { updateOrganization } from '../../graphql/mutations/OrganizationMutations';
import { patchUser } from '../../graphql/mutations/UserMutations';

const OnboardingOrganization = ({ organizationId, handleSuccess }) => {
  const { auth } = useAuth();
  const [organizationName, setOrganizationName] = useState('');
  const [industry, setIndustry] = useState<string | null>(null);
  const [isExternalProvider, setIsExternalProvider] = useState(false);

  const [postOrganization, { saving: organizationSaving }] = useMutation(updateOrganization, {
    variables: {
      organizationId,
      metadata: {
        organizationName,
        industry: (industry && IndustryType[industry]) ?? IndustryType.General,
      },
      provider: {
        isExternalProvider,
      },
    },
  });

  const [postLocation, { saving: locationSaving }] = useMutation(createLocation, {
    variables: {
      organizationId,
      shortId: '1',
      locationName: organizationName,
      autogenerated: true,
    },
  });

  const [changeLocationId, { saving: userSaving }] = useMutation(async (locationId: string) => {
    return patchUser({ userId: auth?.userId || '', patchFields: { locationId, locationIds: [locationId] } });
  });

  const saving = organizationSaving || locationSaving || userSaving;

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await postOrganization();
      if (isExternalProvider) {
        const response = await postLocation();
        if (response) {
          const { data } = response;
          const locationId = data?.post?.metadata?.locationId;
          await changeLocationId(locationId);
        }
      }
      if (handleSuccess) handleSuccess();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit} data-testid="updateOrganization">
      <FormGroup fullWidth>
        <Label required id="organizationName" label="Organization name" />
        <Input
          required
          placeholder="Organization name"
          id="organizationName"
          name="organizationName"
          value={organizationName}
          onChange={(event) => setOrganizationName(event.target.value)}
        />
      </FormGroup>
      <FormGroup fullWidth>
        <Label id="industry" label="Which industry best describes you?" required />
        <StyledSelect data-testid="industry" id="industry" required value={industry || ''} onChange={(e) => setIndustry(e.target.value)}>
          <option value="">Select option</option>
          {industries.map((industry) => (
            <option data-testid={`industry-${industry}`} key={industry} value={industry}>
              {industry}
            </option>
          ))}
        </StyledSelect>
      </FormGroup>
      <FormGroup className="mt-2" fullWidth>
        <RadioCard
          id="organizationType"
          label="Will you be submitting service requests?"
          labelHelper="Service requests are work orders for maintaining equipment"
          options={[
            { label: 'Yes', value: false }, //client, so isExternalProvider = false
            { label: 'No', value: true }, //provider, isExternalProvider = true
          ]}
          onChange={(value) => {
            setIsExternalProvider(value);
          }}
          value={isExternalProvider}
        />
      </FormGroup>
      <div className="flex justify-end mt-2">
        <Button loading={saving}>Continue</Button>
      </div>
    </form>
  );
};

export default OnboardingOrganization;
