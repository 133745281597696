import { formatPhoneNumber } from 'react-phone-number-input';

export const toDisplayPhoneFromPhoneWithCountryCode = (phoneNumberWithCountryCode) => {
  if (!phoneNumberWithCountryCode) return '';

  return formatPhoneNumber(phoneNumberWithCountryCode);
};

export const formatPhoneWithoutCountryCode = (phone?: string | null) => {
  if (!phone) return '';

  const digitsOnly = phone.replace(/\D/g, '');

  if (digitsOnly.length > 10) {
    return digitsOnly.substring(1);
  }

  return digitsOnly;
};
