import React, { useState } from 'react';
import { EntityLabelEnum, Maybe, Note, NoteMetadata, NotesTagsEnum } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Button from '../Buttons/Button';
import NoteList, { NoteListProps } from './NoteList';
import NoteForm, { NoteFormProps } from './NoteForm';

export enum NoteType {
  Internal,
  Public,
}

interface NoteListWithFormProps {
  entityId: string;
  parentLabel: EntityLabelEnum;
  formInitiallyOpen?: boolean;
  queryFunction(args: { entityId: string }): Promise<Maybe<Note>[]>;
  list?: Omit<NoteListProps, 'notes'>;
  form?: Omit<NoteFormProps, 'entityId' | 'parentLabel'>;
  emptyTitle?: string;
  noteType?: NoteType;
  highlightId?: string;
  tags?: NotesTagsEnum[];
}

export default function NoteListWithForm(props: NoteListWithFormProps) {
  const { t } = useTranslation();

  const {
    entityId,
    parentLabel,
    queryFunction,
    formInitiallyOpen,
    emptyTitle = t('noDataFoundWithName', { name: t('notes') }),
    noteType = NoteType.Public,
    highlightId,
    tags,
  } = props;

  const [showForm, setShowForm] = useState(formInitiallyOpen);
  const [notes, setNotes] = useState<Note[]>([]);

  const { isFetching } = useQuery(['NoteListWithForm', entityId], () => queryFunction({ entityId }), {
    enabled: !!entityId,
    onSuccess: (data) => setNotes(data?.filter((n): n is Note => !!n) ?? []),
  });

  const onNoteCreated = (metadata: Maybe<NoteMetadata>) => {
    if (metadata) setNotes([{ metadata }, ...notes]);
    setShowForm(false);
    if (props.form?.onCreated) props.form.onCreated(metadata);
  };

  return (
    <>
      {!showForm && (
        <div className="mb-3 flex w-full items-center justify-between text-sm text-gray-700">
          <div className="text-sm text-gray-500">{!isFetching && `${notes.length} ${t('notes')}`}</div>
          <Button data-testid="addNoteButton" blue type="button" onClick={() => setShowForm(!showForm)}>
            {`${t('create')} ${noteType === NoteType.Internal ? t('internal') : ''} ${t('note')}`}
          </Button>
        </div>
      )}

      {showForm && (
        <div className="mt-3">
          <NoteForm
            parentLabel={parentLabel}
            entityId={entityId}
            onCreated={onNoteCreated}
            onClose={() => setShowForm(false)}
            noteType={noteType}
            tags={tags}
            {...props.form}
          ></NoteForm>
          <div className="mb-3 mt-5 border" />
        </div>
      )}

      {showForm && <div className="text-sm text-gray-500">{!isFetching && `${notes.length} ${t('notes')}`}</div>}

      <NoteList
        loading={isFetching}
        notes={notes}
        emptyTitle={emptyTitle}
        {...props.list}
        unknownAuthorName={t('system')}
        highlightId={highlightId}
      />
    </>
  );
}
