import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function BreadCrumbs({ items, className }) {
  const lastItemIndex = items.length - 1;

  return (
    <nav className={`flex items-center text-sm leading-5 font-medium ${className}`}>
      {items.map(({ text, to }, i) => (
        <div key={text} className="flex">
          <Link
            to={to}
            className="text-gray-500 hover:text-gray-800 focus:outline-none focus:underline transition duration-150 ease-in-out"
          >
            {text}
          </Link>
          {i !== lastItemIndex && (
            <svg className="shrink-0 mx-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </div>
      ))}
    </nav>
  );
}
BreadCrumbs.propTypes = { items: PropTypes.array.isRequired, className: PropTypes.string };
BreadCrumbs.defaultProps = { className: '' };
