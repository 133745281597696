import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import StyledSelect from './StyledSelect';

type RecurrenceIntervalSelectProps = {
  value: string | number;
  id: string;
  label?: string;
  helper?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>;
};

const RecurrenceIntervalSelect = ({ value, onChange, id, label, helper }: RecurrenceIntervalSelectProps) => {
  const { t } = useTranslation();
  const [recurrenceInterval, setRecurrenceInterval] = useState([undefined, 'all', 0, 90, 180, 360].includes(value) ? value : 'Custom');

  const options = [
    { label: t('any'), value: '' },
    { label: t('recurring'), value: 'all' },
    { label: t('once'), value: 0 },
    { label: t('everyNDays', { count: 90 }), value: 90 },
    { label: t('everyNDays', { count: 180 }), value: 180 },
    { label: t('everyNDays', { count: 360 }), value: 360 },
    { label: t('custom'), value: 'Custom' },
  ];

  return (
    <FormGroup fullWidth key={id}>
      <Label id={id} label={label ?? t('recurrenceInterval')} helper={helper} />
      <StyledSelect
        key={id}
        id={id}
        data-testid={id}
        value={recurrenceInterval}
        onChange={(event) => {
          setRecurrenceInterval(event.target.value);
          if (event.target.value !== 'Custom') {
            onChange(event);
          }
        }}
      >
        {options?.map(({ label, value }) => (
          <option value={value} key={label}>
            {label}
          </option>
        ))}
      </StyledSelect>
      {recurrenceInterval === 'Custom' && (
        <div className="mb-1 flex items-center gap-2">
          {t('every')}
          <TextField
            value={value}
            onChange={onChange}
            id="customRecurrenceValue"
            InputProps={{
              inputProps: { type: 'number', min: 0 },
            }}
            variant="outlined"
            size="small"
            margin="dense"
          />
          {t('days')}
        </div>
      )}
    </FormGroup>
  );
};

export default RecurrenceIntervalSelect;
