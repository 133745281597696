import { currentTimestamp, dateIsAfter, msToTime, toUTCUnixMillisecondFromCstString } from '../../../common/functions/dateFunctions';
import { finalizedRequestStatuses } from '../../../graphql/enums';

export const determineHandleTimeForServiceRequest = ({
  createdAt,
  dispatchedInternallyAt,
  dispatchedToEquipsAt,
  dispatchedToProviderAt,
}): number | null => {
  // SRs older than 6/20/2021 were not tracking these dispatch timestamps
  // If it's after this date and still empty, it's not yet been populated
  const oldRequest = toUTCUnixMillisecondFromCstString('06/20/2021');

  if (createdAt && dispatchedInternallyAt && dispatchedToEquipsAt && dispatchedToProviderAt) {
    return dispatchedInternallyAt - createdAt + (dispatchedToProviderAt - dispatchedToEquipsAt);
  } else if (createdAt && dispatchedToEquipsAt && dispatchedToProviderAt) {
    return dispatchedToEquipsAt - createdAt + (dispatchedToProviderAt - dispatchedToEquipsAt);
  } else if (createdAt && dispatchedInternallyAt && dispatchedToProviderAt) {
    return dispatchedToProviderAt - createdAt;
  } else if (createdAt && dispatchedToProviderAt) {
    return dispatchedToProviderAt - createdAt;
  } else if (createdAt && dispatchedInternallyAt) {
    return dispatchedInternallyAt - createdAt;
  } else if (createdAt && dateIsAfter(createdAt, oldRequest)) {
    return currentTimestamp() - createdAt;
  } else {
    return null;
  }
};

export const getHandleTimeLabel = ({ handleTime, requestStatus }): string => {
  if (handleTime == null) return 'Unknown';
  if (handleTime <= 0) return 'Autodispatched';

  return (requestStatus && !finalizedRequestStatuses.includes(requestStatus) ? 'In progress / ' : '') + msToTime(handleTime);
};
