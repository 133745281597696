import { stringify } from 'query-string';

export function buildQueryString<T>(url: string, args: T): string {
  const formattedArgs = Object.entries(args).reduce((acc, [key, value]) => {
    if (typeof value === 'string' || typeof value === 'number' || value === null) {
      acc[key] = value;
    } else if (Array.isArray(value) || typeof value === 'object') {
      acc[key] = JSON.stringify(value);
    }

    return acc;
  }, {});

  return `${url}?${stringify(formattedArgs)}`;
}
