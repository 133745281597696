import { lazy } from 'react';
import Urls from './routes/Urls';
import { reportingRoutes } from './screens/reporting/reportingRoutes';
import { settingsRoutes } from './screens/settings/settingsRoutes';
import { tasksRoutes } from './screens/tasks/TasksRouter';
import { captureRoutes } from './screens/qr-codes/libs/captureRoutes';

export const aasRoutes = [
  {
    path: Urls.AAS_CLAIMS,
    component: lazy(() => import('./screens/aas/claims/AasClaimsScreen')),
  },
  {
    path: `${Urls.AAS_CLAIMS}/:serviceRequestId`,
    component: lazy(() => import('./screens/aas/claims/ViewAasClaim')),
  },
  {
    path: Urls.AAS_CONTRACTS,
    component: lazy(() => import('./screens/aas/contracts/AasContractsScreen')),
  },
  {
    path: Urls.AAS_CONTRACTS_DATA,
    component: lazy(() => import('./screens/aas/contracts-data/AasContractsDataScreen')),
  },
  {
    path: `${Urls.AAS_CONTRACTS}/:aasContractLocationId`,
    component: lazy(() => import('./screens/aas/contracts/ViewAasContract')),
  },
  {
    path: `${Urls.AAS_CONTRACTS_DATA}/:aasContractId`,
    component: lazy(() => import('./screens/aas/contracts-data/ViewAasContractData')),
  },
  {
    path: Urls.AAS_FORM,
    component: lazy(() => import('./screens/aas/form/AasClaimFormScreen')),
  },
  {
    path: Urls.AAS_INVOICES,
    component: lazy(() => import('./screens/aas/invoices/AasInvoicesScreen')),
  },
  {
    path: `${Urls.AAS_INVOICES}/:invoiceId`,
    component: lazy(() => import('./screens/aas/invoices/ViewAasInvoice')),
  },
  {
    path: Urls.AAS_ORGANIZATIONS,
    component: lazy(() => import('./screens/aas/organizations/AasOrganizationsScreen')),
  },
  {
    path: `${Urls.AAS_ORGANIZATIONS}/:organizationId`,
    component: lazy(() => import('./screens/aas/organizations/ViewAasOrganization')),
  },
  {
    path: Urls.AAS_PAYABLES,
    component: lazy(() => import('./screens/payables/PayablesScreen')),
  },
  {
    path: Urls.AAS_PAYABLES_CREATE,
    component: lazy(() => import('./screens/payables/CreatePayable')),
  },
  {
    path: `${Urls.AAS_PAYABLES}/:payableId`,
    component: lazy(() => import('./screens/aas/payables/ViewAasPayable')),
  },
  {
    path: `${Urls.AAS_CHARGES}/:chargeId`,
    component: lazy(() => import('./screens/aas/components/ViewAasCharge')),
  },
];

export const homeWarrantyRoutes = [
  {
    path: Urls.CLAIMS,
    component: lazy(() => import('./screens/home-warranty/claims/ClaimsScreen')),
  },
  {
    path: `${Urls.CLAIMS}/:serviceRequestId`,
    component: lazy(() => import('./screens/home-warranty/claims/ViewClaim')),
  },
  {
    path: Urls.HOME_WARRANTY_INVOICES,
    component: lazy(() => import('./screens/aas/invoices/AasInvoicesScreen')),
  },
  {
    path: `${Urls.HOME_WARRANTY_INVOICES}/:invoiceId`,
    component: lazy(() => import('./screens/aas/invoices/ViewAasInvoice')),
  },
  {
    path: Urls.HOME_WARRANTY_ORGANIZATIONS,
    component: lazy(() => import('./screens/aas/organizations/AasOrganizationsScreen')),
  },
  {
    path: `${Urls.HOME_WARRANTY_ORGANIZATIONS}/:organizationId`,
    component: lazy(() => import('./screens/aas/organizations/ViewAasOrganization')),
  },
  {
    path: Urls.HOME_WARRANTY_PAYABLES,
    component: lazy(() => import('./screens/payables/PayablesScreen')),
  },
  {
    path: Urls.HOME_WARRANTY_PAYABLES_CREATE,
    component: lazy(() => import('./screens/payables/CreatePayable')),
  },
  {
    path: `${Urls.HOME_WARRANTY_PAYABLES}/:payableId`,
    component: lazy(() => import('./screens/aas/payables/ViewAasPayable')),
  },
  {
    path: `${Urls.HOME_WARRANTY_CHARGES}/:chargeId`,
    component: lazy(() => import('./screens/aas/components/ViewAasCharge')),
  },
];

export const authenticatedRoutes = [
  ...captureRoutes,
  ...tasksRoutes,
  ...reportingRoutes,
  ...settingsRoutes,
  ...aasRoutes,
  ...homeWarrantyRoutes,
  {
    path: Urls.HOME,
    component: lazy(() => import('./screens/home/HomeScreen')),
  },
  {
    path: `${Urls.AGREEMENTS_BULK_EDIT}/:contractId`,
    component: lazy(() => import('./screens/contracts/ViewAndEditCoverages')),
  },
  {
    path: `${Urls.SUBSCRIPTIONS}`,
    component: lazy(() => import('./screens/subscriptions/SubscriptionsScreen')),
  },
  {
    path: `${Urls.SUBSCRIPTIONS}/:subscriptionId`,
    component: lazy(() => import('./screens/subscriptions/ViewSubscription')),
  },
  {
    path: Urls.INCIDENTS,
    component: lazy(() => import('./screens/incidents/IncidentsScreen')),
  },
  {
    path: `${Urls.INCIDENTS}/:incidentId`,
    component: lazy(() => import('./screens/incidents/ViewIncident')),
  },
  {
    path: Urls.NOTIFICATIONS,
    component: lazy(() => import('./screens/notifications/NotificationsScreen')),
  },
  {
    path: `${Urls.NOTIFICATIONS}/:notificationId`,
    component: lazy(() => import('./screens/notifications/ViewNotification')),
  },
  {
    path: Urls.MANAGED_SERVICES,
    component: lazy(() => import('./screens/managed-services/ManagedServicesAutomation')),
  },
  {
    path: Urls.ENTITY_CHANGES,
    component: lazy(() => import('./screens/entity-changes/EntityChangesScreen')),
  },
  {
    path: Urls.QUERY_ELASTICSEARCH_AND_ACT_ON_DATA,
    component: lazy(() => import('./screens/query-elasticsearch/MigrationTool')),
  },
  {
    path: Urls.QUERY_GRAPHQL,
    component: lazy(() => import('./screens/query-graphql/QueryGraphQL')),
  },
  {
    path: Urls.DEV_INCIDENTS,
    component: lazy(() => import('./screens/incidents/PostDevIncident')),
  },
  {
    path: Urls.PROVIDER_INVOICES,
    component: lazy(() => import('./screens/payments/LegacyPaymentsScreen')),
  },
  {
    path: Urls.DOCUMENTS,
    component: lazy(() => import('./screens/documents/DocumentsScreen')),
  },
  {
    path: Urls.SERVICE_REQUEST_DISPATCH,
    component: lazy(() => import('./screens/dispatch/DispatchScreen')),
  },
  {
    path: Urls.CHARGES,
    component: lazy(() => import('./screens/charges/ChargesScreen')),
  },
  {
    path: `${Urls.CHARGES}/:chargeId`,
    component: lazy(() => import('./screens/charges/ViewCharge')),
  },
  {
    path: Urls.CATEGORIES,
    component: lazy(() => import('./screens/categories/CategoriesScreen')),
  },
  {
    path: `${Urls.CATEGORIES}/:categoryId`,
    component: lazy(() => import('./screens/categories/ViewCategory')),
  },
  {
    path: Urls.CATEGORIES_DEFAULT,
    component: lazy(() => import('./screens/categories/DefaultCategoriesScreen')),
  },
  {
    path: Urls.COVERAGE,
    component: lazy(() => import('./screens/coverages/CoveragesScreen')),
  },
  {
    path: Urls.COVERAGE_PRICING_TOOL,
    component: lazy(() => import('./screens/coverages/PricingTool')),
  },
  {
    path: Urls.COVERAGE_UPLOAD,
    component: lazy(() => import('./screens/coverages/ImportCoverage')),
  },
  {
    path: Urls.COVERAGE_UPLOAD_PREVIEW,
    component: lazy(() => import('./screens/coverages/importCoveragePreviewTable/ImportCoveragePreviewTable')),
  },
  {
    path: `${Urls.COVERAGE}/:coverageId`,
    component: lazy(() => import('./screens/coverages/ViewCoverage')),
  },
  {
    path: `${Urls.AGREEMENTS}/:contractId`,
    component: lazy(() => import('./screens/contracts/ViewContract')),
  },
  {
    path: Urls.AGREEMENTS,
    component: lazy(() => import('./screens/contracts/ContractsScreen')),
  },
  {
    path: `${Urls.SERVICE_CONTRACTS_CREATE}`,
    component: lazy(() => import('./screens/service-contracts/CreateServiceContract')),
  },
  {
    path: `${Urls.SERVICE_CONTRACTS}/:serviceContractId`,
    component: lazy(() => import('./screens/service-contracts/ViewServiceContract')),
  },
  {
    path: `${Urls.SERVICE_CONTRACTS}`,
    component: lazy(() => import('./screens/service-contracts/ServiceContractsScreen')),
  },
  {
    path: `${Urls.SERVICE_REQUESTS}/:serviceRequestId`,
    component: lazy(() => import('./screens/service-requests/ViewServiceRequest')),
  },
  {
    path: `${Urls.SERVICE_REQUESTS}`,
    component: lazy(() => import('./screens/service-requests/ServiceRequestsScreen')),
  },
  {
    path: `${Urls.SQUADS}`,
    component: lazy(() => import('./screens/squads/SquadsScreen')),
  },
  {
    path: `${Urls.EQUIPMENT_CREATE_LEGACY}`,
    component: lazy(() => import('./screens/equipment/EquipmentAddScreenLegacy')),
  },
  {
    path: `${Urls.EQUIPMENT_CREATE}`,
    component: lazy(() => import('./screens/equipment/create/EquipmentAddScreen')),
  },
  {
    path: Urls.EQUIPMENT_REQUEST_NEW_PURCHASE,
    component: lazy(() => import('./screens/equipment/RequestNewEquipmentPurchaseScreen')),
  },
  {
    path: `${Urls.EQUIPMENT}/:equipmentId`,
    component: lazy(() => import('./screens/equipment/ViewEquipment')),
  },
  {
    path: `${Urls.EQUIPMENT}/:equipmentId/coverage`,
    component: lazy(() => import('./screens/equipment/ViewEquipment')),
  },
  {
    path: Urls.EQUIPMENT,
    component: lazy(() => import('./screens/equipment/Equipment List/EquipmentScreen')),
  },
  {
    path: Urls.EQUIPMENT_PM,
    component: lazy(() => import('./screens/equipment/PmEquipmentScreen')),
  },
  {
    path: `${Urls.ORGANIZATIONS}/:organizationId`,
    component: lazy(() => import('./screens/organizations/ViewOrganization')),
  },
  {
    path: `${Urls.ORGANIZATIONS}`,
    component: lazy(() => import('./screens/organizations/OrganizationsScreen')),
  },
  {
    path: Urls.USERS,
    component: lazy(() => import('./screens/users/UsersScreen')),
  },
  {
    path: Urls.UNCONFIRMED_USERS,
    component: lazy(() => import('./screens/users/UnconfirmedUsersScreen')),
  },
  {
    path: Urls.USERS_PROPOSED,
    component: lazy(() => import('./screens/users/ProposedUsersScreen')),
  },
  {
    path: `${Urls.USERS}/invite`,
    component: lazy(() => import('./screens/users/UserInvite')),
  },
  {
    path: `${Urls.USERS}/:userId`,
    component: lazy(() => import('./screens/users/ViewUser')),
  },
  {
    path: Urls.SPECS,
    component: lazy(() => import('./screens/specs/SpecsScreen')),
  },
  {
    path: `${Urls.SPECS}/:specId`,
    component: lazy(() => import('./screens/specs/ViewSpec')),
  },
  {
    path: `${Urls.LOCATIONS}/create`,
    component: lazy(() => import('./screens/locations/CreateLocation')),
  },
  {
    path: `${Urls.LOCATIONS}/:locationId`,
    component: lazy(() => import('./screens/locations/ViewLocation')),
  },
  {
    path: `${Urls.LOCATIONS}`,
    component: lazy(() => import('./screens/locations/LocationsScreen')),
  },
  {
    path: Urls.CUSTOMER_INVOICES,
    component: lazy(() => import('./screens/payments/PaymentsScreen')),
  },
  {
    path: Urls.SETTINGS,
    component: lazy(() => import('./screens/settings/SettingsScreen')),
  },
  {
    path: `${Urls.INVOICES_UPLOAD}`,
    component: lazy(() => import('./screens/invoices/BulkUploadInvoice')),
  },
  {
    path: `${Urls.IHT_INVOICES}/:invoiceId`,
    component: lazy(() => import('./screens/invoices/CreateEditIhtInvoice')),
  },
  {
    path: Urls.IHT_INVOICES,
    component: lazy(() => import('./screens/invoices/CreateEditIhtInvoice')),
  },
  {
    path: `${Urls.INVOICES}/:invoiceId`,
    component: lazy(() => import('./screens/invoices/ViewInvoice')),
  },
  {
    path: Urls.INVOICES,
    component: lazy(() => import('./screens/invoices/InvoicesScreen')),
  },
  {
    path: Urls.SCHEDULE,
    component: lazy(() => import('./screens/schedule/Schedule')),
  },
  {
    path: Urls.FEEDBACK,
    component: lazy(() => import('./screens/feedback/FeedbackScreen')),
  },
  {
    path: Urls.FEEDBACK_ADD,
    component: lazy(() => import('./screens/feedback/FeedbackForm')),
  },
  {
    path: [Urls.IMPORT, Urls.AAS_IMPORT],
    component: lazy(() => import('./screens/import/ImportScreen')),
  },
  {
    path: Urls.IMPORT_RESULTS,
    component: lazy(() => import('./screens/import/ImportResultsScreen')),
  },
  {
    path: Urls.EQUIPMENT_QR,
    component: lazy(() => import('./screens/equipment/EquipmentQrCodeScreen')),
  },
  {
    path: Urls.LOCATION_QR,
    component: lazy(() => import('./screens/locations/LocationQrCodeScreen')),
  },
  {
    path: Urls.ALERTS,
    component: lazy(() => import('./screens/alerts/AlertsScreen')),
  },
  {
    path: Urls.TESTS,
    component: lazy(() => import('./screens/testing/TestsScreen')),
  },
  {
    path: Urls.UNIQUE_DEVICE_IDENTIFICATION,
    component: lazy(() => import('./screens/unique-device-identification/UDIScreen')),
  },
  {
    path: `${Urls.UNIQUE_DEVICE_IDENTIFICATION}/:deviceId`,
    asdfasdf: 'asdf',
    component: lazy(() => import('./screens/unique-device-identification/ViewUDI')),
  },
  {
    path: `${Urls.INVENTORY}`,
    component: lazy(() => import('./screens/inventory/InventoryScreen')),
  },
  {
    path: `${Urls.PARTS}/:partId`,
    component: lazy(() => import('./screens/inventory/ViewPart')),
  },
];

export const uninitializedUserRoutes = [
  {
    path: Urls.ONBOARDING,
    component: lazy(() => import('./screens/onboarding/OnboardingScreen')),
  },
];

export const unauthenticatedRoutes = [
  {
    path: Urls.SIGNUP,
    component: lazy(() => import('./screens/auth/SignupScreen')),
  },
  {
    path: `${Urls.CONFIRM_SIGNUP}/:userEmail/:confirmationCode`,
    component: lazy(() => import('./screens/auth/ConfirmSignup')),
  },
  {
    path: Urls.PASSWORD_RESET,
    component: lazy(() => import('./screens/auth/ForgotScreen')),
  },
  {
    path: Urls.LOGIN,
    component: lazy(() => import('./screens/auth/LoginScreen')),
  },
  {
    path: `${Urls.LOGIN}/:referringOrganizationId`,
    component: lazy(() => import('./screens/auth/LoginScreen')),
  },
  {
    path: `${Urls.EMAIL_AUTH_REQUEST}/:organizationId`,
    component: lazy(() => import('./screens/auth/EmailAuthRequestScreen')),
  },
  {
    path: `${Urls.EMAIL_AUTH_CONFIRM}`,
    component: lazy(() => import('./screens/auth/EmailAuthConfirmScreen')),
  },
  {
    path: `${Urls.INSIGHTS}`,
    component: lazy(() => import('./screens/insights/InsightsScreen')),
  },
];

export const unauthenticatedOrAuthenticatedRoutes = [
  {
    path: [
      Urls.AAS_ARMIS,
      Urls.AAS_ARMIS_CLAIM_FORM,
      Urls.AAS_ARMIS_PORTAL,
      Urls.AAS_ARMIS_STATUS,
      Urls.PIZZA,
      Urls.PIZZA_STATUS,
      Urls.TRACKER,
      Urls.TRACKER_STATUS,
    ],
    component: lazy(() => import('./screens/aas/armis/AasArmisApp')),
  },
  {
    path: [`/:domain${Urls.CLAIM_FORM}`, `/:domain${Urls.CLAIM_STATUS}`, `/:domain${Urls.CLAIM_EMAIL_VERIFICATION_FORM}`],
    component: lazy(() => import('./screens/home-warranty/mobile-app/MobileApp')),
  },
  {
    path: [`${Urls.SERVICE_REQUESTS}/:serviceRequestIdOrShortId/checkin`, `/sr/:serviceRequestIdOrShortId/checkin`],
    component: lazy(() => import('./screens/service-requests/CheckinServiceRequest')),
  },
  {
    path: `${Urls.EQUIPMENT}/:qrCodeId/preview`,
    component: lazy(() => import('./screens/equipment/qrScreen/EquipmentQrPreview')),
  },
  {
    path: Urls.SERVICE_REQUEST_FORM,
    component: lazy(() => import('./screens/service-requests/ServiceRequestFormScreen')),
  },
  {
    path: `${Urls.NOTE_ATTACHMENT}/:parentLabel/:noteId`,
    component: lazy(() => import('./screens/notes/NoteAttachmentViewer')),
  },
  {
    path: `${Urls.SERVICE_PROVIDER_VIEW}`,
    component: lazy(() => import('./screens/service-provider-view/ServiceProviderViewScreen')),
  },
];

export const devRoutes = [
  {
    path: Urls.PLAYGROUND,
    component: lazy(() => import('./screens/playground/PlaygroundScreen')),
  },
];

export const defaultRoutes = [
  {
    path: Urls.LOGOUT,
    component: lazy(() => import('./screens/auth/LogoutScreen')),
  },
  {
    path: Urls.INACTIVE,
    component: lazy(() => import('./screens/inactive/InactiveScreen')),
  },
  {
    path: '*',
    component: lazy(() => import('./screens/error-screen/ErrorScreen')),
  },
];
