import React from 'react';
import InputLoader from '../Form/InputLoader';
import './selectStyles.css';

interface StyledSelectProp extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children: any;
  isSmall?: boolean;
  isWhite?: boolean;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  id: string;
  isLight?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => any;
}

export default function StyledSelect({
  children,
  isSmall = false,
  isWhite = false,
  required = false,
  disabled = false,
  loading = false,
  isLight = false,
  id,
  onChange,
  ...restOfProps
}: StyledSelectProp) {
  if (loading) return <InputLoader />;

  const size = isSmall ? 'text-sm py-1 px-1' : 'py-2 px-2';
  const backgroundColor = isWhite ? 'bg-white' : 'bg-gray-100';
  const color = isLight ? 'text-gray-500' : 'text-gray-800';

  return (
    <div className="relative">
      <select
        onChange={onChange}
        required={required}
        className={`
        block overflow-hidden 
        appearance-none w-full 
        ${backgroundColor}
        ${color}
        border border-gray-300
        hover:bg-white hover:border-gray-400
        ${size} pr-8 rounded leading-tight 
        outline-none
        focus:bg-blue-100 focus:border-blue-500 focus:ring
        ${disabled ? 'bg-gray-200 cursor-not-allowed' : ''}
        `}
        disabled={disabled}
        id={id}
        data-testid={id}
        {...restOfProps}
      >
        {children}
      </select>
      <div
        style={{ top: '5px' }}
        className={`pointer-events-none absolute ${isSmall ? '' : 'inset-y-0'} right-0 flex items-center px-2 text-gray-700`}
      >
        <svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
}
