import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { FloatSearchFilterInputType, Maybe } from '@equips/entities-schema';
import { useTranslation } from 'react-i18next';
import { startOfTodayUnixTimestampInMilliseconds } from '../../../common/functions/dateFunctions';
import StyledSelect from '../Selects/StyledSelect';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import Input from '../Form/Input';
import { NOT_NULL_OPTION_VALUE, NULL_OPTION_VALUE } from '../../../graphql/enums';
import RelativeDateSelect, { toRelativeFilterArgs, getDatesFromRelative, parseRelativeFilterArgs } from './RelativeDateSelect';

const optionDelim = '#';
const valueDelim = '::';

export const dateRangeOptions = {
  next7: { label: 'Next 7 days', value: '+7' },
  tomorrow: { label: 'Tomorrow', value: 'tomorrow' },
  today: { label: 'Today', value: 'today' },
  yesterday: { label: 'Yesterday', value: 'yesterday' },
  last7: { label: 'Last 7 days', value: '7' },
  last30: { label: 'Last 30 days', value: '30' },
  last90: { label: 'Last 90 days', value: '90' },
  none: { label: 'None', value: NULL_OPTION_VALUE },
  notEmpty: { label: 'Is not empty', value: NOT_NULL_OPTION_VALUE },
  custom: { label: 'Custom...', value: 'custom' },
  relative: { label: 'Relative dates...', value: 'relative' },
};

const defaultOptions = [
  dateRangeOptions.today,
  dateRangeOptions.yesterday,
  dateRangeOptions.last7,
  dateRangeOptions.last30,
  dateRangeOptions.last90,
  dateRangeOptions.custom,
  dateRangeOptions.relative,
  dateRangeOptions.none,
];

const biDirectionalDefaultOptions = [
  dateRangeOptions.next7,
  dateRangeOptions.tomorrow,
  dateRangeOptions.today,
  dateRangeOptions.yesterday,
  dateRangeOptions.last7,
  dateRangeOptions.custom,
  dateRangeOptions.relative,
];

function parseFilter(value: string) {
  const [optionValue = '', customParts] = value.split(optionDelim);
  const args = (customParts || '').split(valueDelim);

  return { optionValue, args };
}

function encodeFilter(optionValue: string, args: string[]) {
  const customTimeString = (args || []).join(valueDelim);
  return `${optionValue}${optionDelim}${customTimeString}`;
}

export function convertStartAndEndDateToEncodedFilterString(
  startDateTimestamp: null | undefined | number,
  endDateTimestamp: null | undefined | number,
): string {
  const from = startDateTimestamp ? dayjs(startDateTimestamp).format('YYYY-MM-DD') : '';
  const to = endDateTimestamp ? dayjs(endDateTimestamp).format('YYYY-MM-DD') : '';

  return encodeFilter(dateRangeOptions.custom.value, [from, to]);
}

export function extractCustomDateFromString(value: string) {
  const { optionValue, args } = parseFilter(value);

  if (optionValue === dateRangeOptions.relative.value) {
    const relative = parseRelativeFilterArgs(args);
    const { from, to } = getDatesFromRelative(relative);

    return { optionValue, from, to };
  }

  const [from = '', to = ''] = args;
  return { optionValue, from, to };
}

function getGraphQLFilter({ optionValue, from, to, adjustToEndOfDay = true }): { gte?: number; lte?: number } | null | undefined {
  if (!optionValue) {
    return;
  } else if (optionValue === dateRangeOptions.none.value || optionValue === dateRangeOptions.notEmpty.value) {
    return null;
  } else if (optionValue === dateRangeOptions.next7.value) {
    return {
      gte: adjustToEndOfDay ? dayjs().endOf('day').valueOf() : dayjs().startOf('day').valueOf(),
      lte: dayjs().add(7, 'day').startOf('day').valueOf(),
    };
  } else if (optionValue === dateRangeOptions.tomorrow.value) {
    return {
      gte: dayjs().add(1, 'day').startOf('day').valueOf(),
      lte: adjustToEndOfDay ? dayjs().add(1, 'day').endOf('day').valueOf() : dayjs().add(1, 'day').startOf('day').valueOf(),
    };
  } else if (optionValue === dateRangeOptions.today.value) {
    return {
      gte: startOfTodayUnixTimestampInMilliseconds(),
      lte: adjustToEndOfDay ? dayjs().endOf('day').valueOf() : dayjs().startOf('day').valueOf(),
    };
  } else if (optionValue === dateRangeOptions.yesterday.value) {
    return {
      gte: dayjs().subtract(1, 'day').startOf('day').valueOf(),
      lte: adjustToEndOfDay ? dayjs().subtract(1, 'day').endOf('day').valueOf() : dayjs().subtract(1, 'day').startOf('day').valueOf(),
    };
  } else if (optionValue === dateRangeOptions.last7.value) {
    return {
      gte: dayjs().subtract(7, 'day').startOf('day').valueOf(),
      lte: adjustToEndOfDay ? dayjs().endOf('day').valueOf() : dayjs().startOf('day').valueOf(),
    };
  } else if (optionValue === dateRangeOptions.last30.value) {
    return {
      gte: dayjs().subtract(30, 'day').startOf('day').valueOf(),
      lte: adjustToEndOfDay ? dayjs().endOf('day').valueOf() : dayjs().startOf('day').valueOf(),
    };
  } else if (optionValue === dateRangeOptions.last90.value) {
    return {
      gte: dayjs().subtract(90, 'day').startOf('day').valueOf(),
      lte: adjustToEndOfDay ? dayjs().endOf('day').valueOf() : dayjs().startOf('day').valueOf(),
    };
  } else if (from || to) {
    return {
      ...(from ? { gte: dayjs(from).startOf('day').valueOf() } : {}),
      ...(to
        ? {
            lte: adjustToEndOfDay
              ? dayjs(to === 'today' ? Date.now() : to)
                  .endOf('day')
                  .valueOf()
              : dayjs(to === 'today' ? Date.now() : to)
                  .startOf('day')
                  .valueOf(),
          }
        : {}),
    };
  }
}

export const dateRangeStringToRange = (value = ''): { gte?: number; lte?: number } | null | undefined => {
  const { optionValue, from, to } = extractCustomDateFromString(value);
  return getGraphQLFilter({ optionValue, from, to, adjustToEndOfDay: false });
};

type TransformerOptions = { customDatestringToTimestampFunction?: Function; filters?: FloatSearchFilterInputType[] };

export const transformDateRangeSelectAndCustomDateIntoGraphQLQuery = (
  value = '',
  { customDatestringToTimestampFunction, filters = [] }: TransformerOptions = {},
): Maybe<FloatSearchFilterInputType[]> | undefined => {
  const { optionValue, from, to } = extractCustomDateFromString(value);

  const range = getGraphQLFilter({ optionValue, from, to });
  if (range) {
    if (customDatestringToTimestampFunction && optionValue === dateRangeOptions.custom.value) {
      return [
        {
          range: {
            gte: range.gte ? customDatestringToTimestampFunction(range.gte) : undefined,
            lte: range.lte ? customDatestringToTimestampFunction(range.lte) : undefined,
          },
          ...filters,
        },
      ];
    }

    return [{ range }, ...filters];
  } else if (optionValue === dateRangeOptions.none.value) {
    return [{ notExists: true }];
  } else if (optionValue === dateRangeOptions.notEmpty.value) {
    return [{ exists: true }];
  }
};

type DateRangeFilterType = {
  id: string;
  label: string | React.ReactNode;
  labelHelper?: string;
  value?: string;
  setValue: (value: string) => void;
  className?: string;
  emptySelectionText?: string;
  options?: { label: string; value: string }[];
  biDirectionalMode?: boolean;
};

export default function DateRangeFilter({
  className = '',
  emptySelectionText,
  id,
  label,
  value,
  setValue,
  labelHelper = '',
  options,
  biDirectionalMode = false,
}: DateRangeFilterType) {
  const { t } = useTranslation();
  const { optionValue, args } = useMemo(() => parseFilter(value || ''), [value]);
  const [from = '', to = ''] = optionValue === dateRangeOptions.custom.value ? args : [];

  const availableOptions = options?.length ? options : biDirectionalMode ? biDirectionalDefaultOptions : defaultOptions;

  return (
    <div className={className}>
      <FormGroup fullWidth className="pb-2">
        <Label id={id} label={label} helper={labelHelper} />
        <StyledSelect
          data-testid={`${id}Select`}
          id={id}
          value={optionValue}
          onChange={(event) => {
            if (event.target.value === dateRangeOptions.custom.value) {
              // Provide default range, or convert previously selected value into custom range
              const difference = optionValue === 'today' ? 0 : optionValue === 'yesterday' ? 1 : optionValue ? parseInt(optionValue) : 7;
              const from = dayjs().subtract(difference, 'day').format('YYYY-MM-DD');
              const to = dayjs().format('YYYY-MM-DD');
              setValue(encodeFilter(event.target.value, [from, to]));
            } else if (event.target.value === dateRangeOptions.relative.value) {
              setValue(encodeFilter(dateRangeOptions.relative.value, ['0', '1', 'week', 'true']));
            } else {
              setValue(event.target.value);
            }
          }}
        >
          {availableOptions[0].value !== '' && <option value="">{emptySelectionText || t('any')}</option>}
          {availableOptions.map((opt, i) => {
            return (
              <option key={`${opt.value}__${i}`} value={opt.value}>
                {opt.label}
              </option>
            );
          })}
        </StyledSelect>
      </FormGroup>
      {optionValue === dateRangeOptions.custom.value && (
        <div className="flex space-x-1">
          <div className="w-1/2">
            <Input
              data-testid={`${id}From`}
              tiny
              type="date"
              id={`${id}FromDateRangePicker`}
              value={from}
              onChange={({ target: { value } }) => {
                setValue(encodeFilter(dateRangeOptions.custom.value, [value, to]));
              }}
            />
          </div>
          <div className="w-1/2">
            <Input
              tiny
              data-testid={`${id}To`}
              type="date"
              id={`${id}ToDateRangePicker`}
              value={to && to === 'today' ? dayjs().format('YYYY-MM-DD') : to}
              onChange={({ target: { value } }) => {
                setValue(encodeFilter(dateRangeOptions.custom.value, [from, value]));
              }}
            />
          </div>
        </div>
      )}
      {optionValue === dateRangeOptions.relative.value && (
        <RelativeDateSelect
          value={args?.length ? parseRelativeFilterArgs(args) : undefined}
          onChange={(value) => {
            setValue(encodeFilter(dateRangeOptions.relative.value, toRelativeFilterArgs(value)));
          }}
        />
      )}
    </div>
  );
}
