import React, { useState } from 'react';
import Close from '@mui/icons-material/Close';
import { ClientOrganizationEnumType, UserAuthorizationRoleEnum } from '@equips/entities-schema';
import Switch from '@mui/material/Switch';
import { useAlert } from '../components/Alerts/AlertContext';
import { SelectOptionsForEnum, clientOrganizationTypes } from '../../graphql/enums';
import Label from '../components/Form/Label';
import FormGroup from '../components/Form/FormGroup';

interface AuthorizationMutatorProps {
  isTrial: boolean;
  isActive: boolean;
  eLinkMode: boolean;
  setELinkMode: any;
  authorizationRole?: UserAuthorizationRoleEnum | null;
  clientOrganizationType: ClientOrganizationEnumType | null;
  userId?: string | null;
  organizationId?: string | null;
  roles: UserAuthorizationRoleEnum[];
  updateClientOrganizationType: (clientOrganizationType: ClientOrganizationEnumType) => any;
  updateUserRole: (authorizationRole: UserAuthorizationRoleEnum) => any;
  updateOrganizationMetadata: (field: string) => any;
  isAasUser: boolean;
  setIsAasUser: any;
}

export default function AuthorizationMutator({
  updateOrganizationMetadata,
  updateUserRole,
  updateClientOrganizationType,
  setELinkMode,
  eLinkMode,
  authorizationRole,
  clientOrganizationType,
  roles,
  isTrial,
  isActive,
  userId,
  organizationId,
  isAasUser,
  setIsAasUser,
}: AuthorizationMutatorProps) {
  const [showing, setShowing] = useState(false);
  const showAlert = useAlert();

  function copyValueWithToast(value) {
    window.navigator.clipboard.writeText(value);
    showAlert({ content: 'Successfully copied.' });
    setShowing(false);
  }

  return (
    <aside className="fixed bottom-0 left-0 z-20 block print:hidden">
      {showing ? (
        <div className="rounded bg-gray-800 p-2 shadow">
          <div className="relative">
            <button
              className="absolute right-0 top-0 h-6 w-6 rounded-full bg-white text-sm"
              type="button"
              onClick={() => setShowing(false)}
            >
              <Close />
            </button>
          </div>

          <div className="w-full">
            <p className="mb-2 px-1 text-white">Internal Dev Tool.</p>
            <button className="mb-4 block rounded bg-white px-1" type="button" onClick={() => setELinkMode((state) => !state)}>
              {eLinkMode ? 'Back to normal' : 'E-LINK Mode'}
            </button>
            <button className="mb-4 block rounded bg-white px-1" type="button" onClick={() => updateOrganizationMetadata('isTrial')}>
              {isTrial ? 'Escape trial mode' : 'View as trial'}
            </button>

            <button className="mb-4 block rounded bg-white px-1" type="button" onClick={() => updateOrganizationMetadata('deactivatedAt')}>
              {isActive ? 'Make inactive' : 'View as active'}
            </button>

            <button className="mb-4 block rounded bg-white px-1" type="button" onClick={() => copyValueWithToast(userId)}>
              Copy userId
            </button>
            <button className="mb-4 block rounded bg-white px-1" type="button" onClick={() => copyValueWithToast(organizationId)}>
              Copy organizationId
            </button>

            <div>
              <select
                className="m-auto mb-4"
                value={clientOrganizationType || undefined}
                onChange={(event) => {
                  updateClientOrganizationType(event.target.value as ClientOrganizationEnumType);
                }}
              >
                <SelectOptionsForEnum objectOfOptions={clientOrganizationTypes} />
              </select>
            </div>

            <select
              className="m-auto mb-4"
              value={authorizationRole || undefined}
              onChange={(event) => {
                updateUserRole(event.target.value as UserAuthorizationRoleEnum);
              }}
            >
              {roles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>

            <FormGroup fullWidth className="flex justify-between rounded-xl bg-gray-700 px-2 align-middle">
              <Label id="isAasUser" label="AAS User" labelClassName="p-2 text-white" />
              <Switch id="isAasUser" checked={isAasUser} onChange={(event) => setIsAasUser(event.target.checked)} />
            </FormGroup>
          </div>
        </div>
      ) : (
        <button className="rounded rounded-b-none bg-gray-500 px-4 text-sm text-white" type="button" onClick={() => setShowing(true)}>
          Dev Tools
        </button>
      )}
    </aside>
  );
}
