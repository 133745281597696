import React, { useState, useMemo, useEffect } from 'react';
import {
  Maybe,
  patchServiceRequest,
  ServiceRequest,
  ServiceRequestStatusType,
  patchEquipment,
  EquipmentStatusType,
  Equipment,
  IncidentsMutationPatchArgs,
  patchIncident,
  postNote,
  EntityLabelEnum,
  NoteAuthorizationLevel,
} from '@equips/entities-schema';
import { useMutation, useQuery } from '@tanstack/react-query';
import CallSplit from '@mui/icons-material/CallSplit';
import Forward from '@mui/icons-material/Forward';
import Alert from '@mui/material/Alert';
import ServiceRequestCard from '../../common/components/Cards/ServiceRequestCard';
import SimpleModal, { simpleModalTypes } from '../../common/components/Modal/SimpleModal';
import { getServiceRequestForProposedEquipmentByEquipmentId } from '../../graphql/queries/serviceRequestGraphQLQueries';
import { AlertTypes, useAlert } from '../../common/components/Alerts/AlertContext';
import { checkForDuplicateActiveEquipment } from '../../graphql/queries/equipmentGraphQLQueries';
import { EquipmentWrapper } from '../service-requests/components/ModifyServiceRequest/ProposedSRWithProposedEquipmentModal';
import DisplayEquipment from '../../common/components/ServiceRequest/DisplayEquipment';
import Button from '../../common/components/Buttons/Button';
import Label from '../../common/components/Form/Label';
import StyledSelect from '../../common/components/Selects/StyledSelect';
import FormGroup from '../../common/components/Form/FormGroup';
import Textarea from '../../common/components/Form/Textarea';
import { modalStateOptions } from './components/EquipmentRowActions';

export const ProposedEquipmentServiceRequestsModalContent = ({
  serviceRequests,
  loading,
  equipmentDeactivated,
  equipmentActivated,
  duplicateEquipment,
}: {
  serviceRequests: Maybe<ServiceRequest>[];
  loading: boolean;
  equipmentDeactivated?: boolean;
  equipmentActivated?: boolean;
  duplicateEquipment?: Maybe<Equipment>[];
}) => {
  const [serviceRequestsToDispatchToEquips, setServiceRequestsToDispatchToEquips] = useState<string[]>([]);
  const [serviceRequestsToDispatchInternally, setServiceRequestsToDispatchInternally] = useState<string[]>([]);
  const [serviceRequestBeingProcessed, setServiceRequestBeingProcessed] = useState<string>('');
  const [saving, setSaving] = useState(false);
  const [reassignedEquipmentId, setReassignedEquipmentId] = useState('');
  const showAlert = useAlert();

  const { mutate: wrappedPatchIncident, isLoading: incidentSaving } = useMutation(
    async (args: IncidentsMutationPatchArgs) => {
      await patchIncident(args);
    },
    {
      onSuccess: () => showAlert({ type: AlertTypes.success, content: 'Service Request has been reassigned' }),
    },
  );

  const duplicateEquipmentAsOptions = useMemo(() => {
    const optionsToReturn = [] as any;

    (duplicateEquipment || []).forEach((equipment) => {
      const { equipmentName, serialNumber, equipmentId } = equipment?.metadata || {};
      const { model, manufacturer } = equipment?.specMetadata || {};

      const singleRecord = {};

      let labelRecord = equipmentName ? `Name: ${equipmentName}` : '';

      if (model) labelRecord += ` Model: ${model}`;

      if (manufacturer) labelRecord += ` Manufacturer: ${manufacturer}`;

      if (model) labelRecord += ` Model: ${model}`;

      if (serialNumber) labelRecord += ` S/N: ${serialNumber}`;

      singleRecord['label'] = labelRecord;
      singleRecord['value'] = equipmentId;

      optionsToReturn.push(singleRecord);
    });

    return optionsToReturn;
  }, [duplicateEquipment]);

  const isInternalDispatchTurnedOn = !serviceRequests?.[0]?.organizationMetadata?.autoForwardAllDispatchRequests;

  const dispatch = async (serviceRequestId: string, to: ServiceRequestStatusType) => {
    setServiceRequestBeingProcessed(serviceRequestId);
    setSaving(true);
    await patchServiceRequest({ serviceRequestId, metadata: { requestStatus: to } });

    if (to === ServiceRequestStatusType.EquipsDispatch) {
      setServiceRequestsToDispatchToEquips([...serviceRequestsToDispatchToEquips, serviceRequestId]);
    } else {
      setServiceRequestsToDispatchInternally([...serviceRequestsToDispatchInternally, serviceRequestId]);
    }

    setSaving(false);
    showAlert({ content: `Service Request has been updated.`, type: AlertTypes.success });
  };

  return (
    <>
      {serviceRequests.length > 0 && (
        <>
          {equipmentDeactivated && (
            <>
              <p className="py-3 text-sm">Equipment deactivated would you like to reassign the proposed SRs?</p>
              <Label id="organizationName" label={'Equipment to reassign SR to'} />
              <StyledSelect
                id="organizationName"
                onChange={(event) => {
                  setReassignedEquipmentId(event.target.value);
                }}
                value={reassignedEquipmentId}
              >
                {duplicateEquipmentAsOptions?.map(({ label, value }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </StyledSelect>
            </>
          )}

          <div className="max-h-lg overflow-y-auto pt-3">
            <p className="py-3 text-sm">
              Service Requests that were created when this equipment was in proposed status were set to Proposed status.
            </p>
            {serviceRequests?.map((request, index) => {
              const serviceRequestId = request?.metadata?.serviceRequestId || '';
              const incident = request?.incidents?.[0];
              const incidentId = incident?.incidentId || '';
              const buttonActions = equipmentActivated
                ? [
                    {
                      onClick: () => {
                        dispatch(serviceRequestId, ServiceRequestStatusType.EquipsDispatch);
                      },
                      text: 'Dispatch to Provider',
                      Icon: Forward,
                      saving: saving && serviceRequestBeingProcessed === serviceRequestId,
                    } /** Allow dispatch internally if internal dispatch is turned on for this organization*/,
                    isInternalDispatchTurnedOn && equipmentActivated
                      ? {
                          onClick: () => {
                            dispatch(serviceRequestId, ServiceRequestStatusType.InternalDispatch);
                          },
                          text: 'Dispatch Internally',
                          Icon: Forward,
                          saving: saving && serviceRequestBeingProcessed === serviceRequestId,
                        }
                      : {},
                  ]
                : [
                    {
                      onClick: () => {
                        wrappedPatchIncident({
                          incidentId,
                          metadata: { equipmentId: reassignedEquipmentId || duplicateEquipmentAsOptions?.[0]?.value },
                        });
                        // Remove from SR list locally
                        serviceRequests.splice(index, 1);
                      },
                      text: 'Reassign SR',
                      Icon: CallSplit,
                      saving: incidentSaving,
                    },
                  ];

              return (
                <ServiceRequestCard
                  key={incidentId || serviceRequestId}
                  incident={incident}
                  serviceRequest={request}
                  loading={loading}
                  actions={
                    serviceRequestsToDispatchToEquips.find((request) => request === serviceRequestId)
                      ? [{ text: 'Service Request is set to dispatch to Provider', color: 'text-equipsGreen' }]
                      : serviceRequestsToDispatchInternally.find((request) => request === serviceRequestId)
                      ? [{ text: 'Service Request is set to dispatch internally', color: 'text-equipsGreen' }]
                      : buttonActions
                  }
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const ProposedEquipmentServiceRequestsModal = ({
  isOpen,
  handleClose,
  modalState,
  equipmentId,
  categoryId,
  manufacturer,
  model,
  equipmentName,
  shortId,
  serialNumber,
  equipmentStatus,
  locationId,
}: {
  isOpen: boolean;
  handleClose: (...args: any[]) => any;
  modalState: modalStateOptions;
  equipmentId: Maybe<string>;
  categoryId: Maybe<string>;
  manufacturer: Maybe<string>;
  model: Maybe<string>;
  equipmentName: Maybe<string>;
  shortId: Maybe<string>;
  serialNumber: Maybe<string>;
  equipmentStatus: Maybe<string>;
  locationId: Maybe<string>;
}) => {
  const showAlert = useAlert();

  const [equipmentActivated, setEquipmentActivated] = useState(false);
  const [equipmentDeactivated, setEquipmentDeactivated] = useState(false);
  const [denialReason, setDenialReason] = useState('');

  const { data: serviceRequestData, isFetching: loading } = useQuery(
    ['getSRForProposedEquipment', equipmentId],
    async () => {
      return await getServiceRequestForProposedEquipmentByEquipmentId({ equipmentId: equipmentId || '' });
    },
    {
      enabled: !!equipmentId && isOpen,
    },
  );

  const { data: equipmentData } = useQuery(
    ['checkForDuplicateActiveEquipment', equipmentId],
    async () => {
      return await checkForDuplicateActiveEquipment({
        categoryId,
        manufacturer,
        model,
        locationId,
      });
    },
    { enabled: !!categoryId && !!manufacturer && !!model && !!locationId && isOpen },
  );

  const serviceRequests = serviceRequestData?.data?.serviceRequests?.data || [];
  const duplicateEquipment = equipmentData?.data?.equipment?.data ?? [];

  const { mutate: setEquipmentToActive, isLoading: setToActiveSaving } = useMutation(
    async () => {
      await patchEquipment({ equipmentId: equipmentId || '', metadata: { equipmentStatus: EquipmentStatusType.Active } });
    },
    {
      onSuccess: () => {
        showAlert({ type: AlertTypes.success, content: 'Successfully set to active' });
        setEquipmentActivated(true);
      },
    },
  );

  const { mutate: deactivateEquipment, isLoading: deactivateEquipmentSaving } = useMutation(
    async () => {
      await patchEquipment({ equipmentId: equipmentId || '', metadata: { deactivatedAt: Date.now() } });
    },
    {
      onSuccess: () => {
        showAlert({ type: AlertTypes.success, content: 'Successfully deactivated' });
        setEquipmentDeactivated(true);
      },
    },
  );

  useEffect(() => {
    if (!equipmentActivated && equipmentStatus === EquipmentStatusType.Active) {
      setEquipmentActivated(true);
    }
  }, [equipmentStatus]);

  /** Once the equipment has been activated or deactivated and there are no more SRS to resolve close the Modal */
  if (!loading && (equipmentActivated || equipmentDeactivated) && serviceRequests.length === 0) {
    handleClose();
  }

  /** If equipment has a duplicate, prompt user to activate/deactivate */
  if (duplicateEquipment.length && !(equipmentActivated || equipmentDeactivated)) {
    return (
      <SimpleModal
        {...{
          isOpen,
          handleClose,
          modalType: simpleModalTypes.info,
          size: serviceRequests.length > 0 ? 'md' : undefined,
          title: 'Resolve Proposed Equipment',
        }}
      >
        <p className="pt-3 text-xs leading-none text-gray-700">Equipment that is being proposed</p>
        <EquipmentWrapper {...{ equipmentName, equipmentId, shortId, model, manufacturer, serialNumber, equipmentStatus }} />

        {duplicateEquipment.length > 0 && (
          <Alert severity="error" className="mb-4">
            {duplicateEquipment.length} PIECES OF DUPLICATE EQUIPMENT FOUND
          </Alert>
        )}

        <div className="scrolling-touch scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray max-h-md w-full overflow-y-auto pt-1">
          {duplicateEquipment.map((equipment, index) => (
            <DisplayEquipment key={index} item={equipment} clickable={true} showLabel={false} />
          ))}
        </div>

        <div className="py-2">
          <Button
            type="button"
            blue
            className="mr-2 px-6"
            data-testid="activateEquipment"
            onClick={() => setEquipmentToActive()}
            disabled={setToActiveSaving}
          >
            Activate equipment
          </Button>

          <Button
            type="button"
            danger
            className="px-6"
            data-testid="deactivateEquipment"
            onClick={() => deactivateEquipment()}
            disabled={deactivateEquipmentSaving}
          >
            Deactivate equipment
          </Button>
        </div>
      </SimpleModal>
    );
  }

  /** If equipment is approved, set status to active */
  if (
    modalState === modalStateOptions.approveProposedEquipment &&
    equipmentStatus === EquipmentStatusType.Proposed &&
    !(equipmentActivated || equipmentDeactivated)
  ) {
    return (
      <SimpleModal
        size="xs"
        title={'Approve Proposed Equipment'}
        subtitle={'This will set the status to active'}
        modalType={simpleModalTypes.info}
        isOpen={modalState === modalStateOptions.approveProposedEquipment}
        handleClose={handleClose}
      >
        <Button
          type="button"
          blue
          className="ml-14 mt-4"
          data-testid="activateEquipment"
          onClick={() => setEquipmentToActive()}
          disabled={setToActiveSaving}
        >
          Activate equipment
        </Button>
      </SimpleModal>
    );
  }

  /** If equipment is denied, ask for reason */
  if (modalState === modalStateOptions.denyProposedEquipment && !(equipmentActivated || equipmentDeactivated)) {
    return (
      <SimpleModal
        title={'Deny Proposed Equipment'}
        subtitle={'This will deactivate the equipment'}
        modalType={simpleModalTypes.info}
        isOpen={modalState === modalStateOptions.denyProposedEquipment}
        handleClose={handleClose}
      >
        <form
          className="pt-4"
          data-testid="denyEquipment"
          onSubmit={async (event) => {
            event.preventDefault();
            await postNote({
              metadata: {
                parentId: equipmentId || '',
                parentLabel: EntityLabelEnum.Equipment,
                authorizationLevel: NoteAuthorizationLevel.All,
                message: `Equipment denied. Reason: ${denialReason}`,
              },
            });
            deactivateEquipment();
          }}
        >
          <FormGroup fullWidth>
            <Label id="reasonNote" label={`What is your reason for denial?`} />
            <Textarea
              data-testid="denialReason"
              aria-label="What is your reason for denial?"
              id="denialReason"
              onChange={({ target: { value } }) => setDenialReason(value)}
              value={denialReason}
              placeholder="Reason"
            />
          </FormGroup>
          <div className="">
            <Button blue loading={deactivateEquipmentSaving} className="block w-full" type="submit">
              Deny
            </Button>
          </div>
        </form>
      </SimpleModal>
    );
  }

  /** Show service requests that need to be resolved after equipment activation/deactivation */
  return (
    <SimpleModal
      {...{
        isOpen,
        handleClose,
        modalType: simpleModalTypes.success,
        size: serviceRequests.length > 0 ? 'md' : undefined,
        title: `Equipment has been ${equipmentActivated ? 'activated' : 'deactivated'}`,
      }}
    >
      <ProposedEquipmentServiceRequestsModalContent
        {...{
          serviceRequests,
          loading,
          equipmentDeactivated,
          equipmentActivated,
          duplicateEquipment,
        }}
      />
    </SimpleModal>
  );
};

export default ProposedEquipmentServiceRequestsModal;
