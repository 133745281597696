import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-spinners/PulseLoader';
import './Switch.css';

export default function Switch(props) {
  const { id, loading, label, onChange, checked, tiny, large, className, ...restOfProps } = props;

  return (
    <div className={className} {...restOfProps}>
      {loading ? (
        <Loader size={8} color="#718096" />
      ) : (
        <>
          <input
            data-testid={id}
            type="checkbox"
            className={`switch ${tiny ? 'switch-sm' : ''} ${large ? 'switch-lg' : ''}`}
            id={id}
            onChange={onChange}
            checked={checked}
          />
          <label data-testid={`${id}Label`} className="text-gray-700 text-sm" htmlFor={id}>
            {label}
          </label>
        </>
      )}
    </div>
  );
}

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  tiny: PropTypes.bool,
  large: PropTypes.bool,
};

Switch.defaultProps = {
  label: '',
  className: '',
  checked: false,
  loading: false,
  tiny: false,
  large: false,
};
