import React from 'react';
import Star from '@mui/icons-material/Star';
import { Tooltip } from '@mui/material';

export default function VipIndicator() {
  return (
    <Tooltip title="VIP">
      <span className="text-yellow-600">
        <Star />
      </span>
    </Tooltip>
  );
}
