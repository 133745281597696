import React from 'react';
import { AasFailCode, Maybe } from '@equips/entities-schema';
import Category from '@mui/icons-material/Category';
import Description from '@mui/icons-material/Description';
import { Tooltip } from '@mui/material';
import { AasFailGroupToTextMap } from './AasFailCodesSelect';

export default function TableCellAasFailCodes({ failCodes }: { failCodes?: Maybe<AasFailCode>[] }) {
  if (!failCodes?.length) {
    return <>N/A</>;
  }

  return (
    <div className="flex gap-1">
      {failCodes.map((failCode) => (
        <span className="rounded-xl bg-gray-300 p-1 text-xs" key={failCode?.aasFailCodeId}>
          <Tooltip
            title={
              <>
                <div className="flex items-center gap-1">
                  <Category fontSize="inherit" />{' '}
                  <span>{failCode?.metadata?.group && AasFailGroupToTextMap[failCode?.metadata?.group]}</span>
                </div>
                <div className="flex items-center gap-1">
                  <Description fontSize="inherit" />
                  <span>{failCode?.metadata?.description}</span>
                </div>
              </>
            }
          >
            <div>{failCode?.metadata?.code}</div>
          </Tooltip>
        </span>
      ))}
    </div>
  );
}
