import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { patchUser } from '../../../graphql/mutations/UserMutations';
import { getUserById } from '../../../graphql/queries/userGraphQLQueries';
import Button from '../../../common/components/Buttons/Button';
import useMutation from '../../../common/hooks/useMutation';
import { useAuth } from '../../../common/auth/AuthContext';
import LocationsDialogSelect from '../../../common/components/Selects/LocationsDialogSelect';

const submitButtonText = 'Save';

interface SelectDefaultLocationProps {
  organizationId: string;
  userId: string;
  currentLocationId?: string;
  handleSuccess?: () => any;
  onlyAllowUsersPreselectedLocationIds?: boolean;
}

export default function SelectDefaultLocation({
  organizationId,
  currentLocationId,
  userId,
  handleSuccess,
  onlyAllowUsersPreselectedLocationIds = false,
}: SelectDefaultLocationProps) {
  const { userCan, admins } = useAuth();
  const [defaultLocation, setDefaultLocation] = useState(currentLocationId || '');

  const { data } = useQuery(['getUserById', userId], () => getUserById(userId), {
    enabled: !!userId && onlyAllowUsersPreselectedLocationIds,
    select: (data) => data?.data,
  });

  const allowToSelectAllLocations = null;
  const defaultAvailableLocationIds = onlyAllowUsersPreselectedLocationIds && !userCan(admins) ? [] : allowToSelectAllLocations;
  const locationIds = data?.users?.data?.[0]?.metadata?.locationIds;
  const availableLocationIds = locationIds || defaultAvailableLocationIds;
  const [changeLocationId, { saving }] = useMutation(patchUser, {
    variables: {
      userId,
      patchFields: {
        locationId: defaultLocation,
        ...(!onlyAllowUsersPreselectedLocationIds ? { locationIds: [defaultLocation] } : {}),
      },
    },
    onCompleted: () => handleSuccess && handleSuccess(),
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        changeLocationId();
      }}
      data-testid="selectDefaultLocations"
    >
      <LocationsDialogSelect
        id="selectDefaultLocationLocationSelect"
        includeLocationsNotOnUser
        availableLocationIds={availableLocationIds as string[]}
        required
        fullWidth
        organizationId={organizationId}
        label="Your default location"
        changeLocation={(value) => setDefaultLocation(value)}
        selectedLocation={defaultLocation}
        emptySelectionText="Select a location"
      />
      <div className="flex justify-end py-2">
        <Button loading={saving}>{submitButtonText}</Button>
      </div>
    </form>
  );
}
