import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Close from '@mui/icons-material/Close';
import OpenWith from '@mui/icons-material/OpenWith';
import Search from '@mui/icons-material/Search';
import { useQuery } from '@tanstack/react-query';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useDebouncePrimitive } from '../../hooks/useDebounce';
import EmptyComponent from '../EmptyComponent';
import LoaderCards from '../Loaders/LoaderCards';
import DraggableDialog from '../Modal/DraggableDialog';
import useWindowSize from '../../hooks/useWindowSize';
import { searchCharacterLimit } from '../../constants/numberLimits';

export interface SearchableDialogShellProps {
  entityName: string;
  Filters?: JSX.Element | null;
  graphQLQuery: (...args: any[]) => any;
  graphQLVariables?: (...args: any[]) => any;
  handleClose: () => any;
  handleSelect?: any;
  hideSearchBox?: boolean;
  inputTestId?: string;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  open: boolean;
  saving?: boolean;
  SelectDisplay: any;
  testId?: string;
  selected?: string | null;
  searchQuery?: string | null;
}

interface ExtendedSearchableDialogProps extends Omit<SearchableDialogShellProps, 'handleSelect'> {
  classes: ClassNameMap<string>;
  selectAction: (...args: any) => any;
}

function MobileSearchableDialog(props: ExtendedSearchableDialogProps) {
  const {
    entityName,
    Filters = null,
    graphQLQuery,
    graphQLVariables = (o) => o,
    handleClose,
    hideSearchBox = false,
    inputTestId = 'searchableDialogInput',
    maxWidth = 'md',
    open,
    saving = false,
    SelectDisplay,
    testId = 'searchableDialog',
    selected = null,
    searchQuery = '',
    classes,
    selectAction,
  } = props;

  const [q, setQ] = useState(searchQuery);
  const debouncedQ = useDebouncePrimitive(q, 300);
  const variables = { q: debouncedQ, ...graphQLVariables(debouncedQ) };
  const [fireSearch, setFireSearch] = useState(false);
  const [filterOpen, setFilterOpen] = useState(!!Filters);

  const setSearchableOptionsOverrides = (overrides) => {
    Filters?.props.setEntities({
      ...Filters?.props.entitiesToIgnore,
      ...overrides,
    });
  };

  useEffect(() => {
    switch (q?.toLowerCase().trim().slice(0, 2)) {
      case 'sr':
        setSearchableOptionsOverrides({ serviceRequests: false });
        break;
      case 'eq':
        setSearchableOptionsOverrides({ equipment: false });
        break;
      case 'in':
        setSearchableOptionsOverrides({ invoices: false });
        break;
      default:
        setSearchableOptionsOverrides({
          serviceRequests: true,
          equipment: true,
          invoices: true,
        });
    }
  }, [q]);

  const { data, isFetching } = useQuery(['SearchableDialogShell', variables], () => graphQLQuery(variables), {
    select: (data) => data?.data,
    enabled: !!fireSearch,
    onSettled: () => setFireSearch(false),
  });

  const entities = data?.[entityName]?.data || [];

  return (
    <DraggableDialog onClose={() => handleClose()} aria-labelledby="locationSelect" open={open} fullWidth maxWidth={maxWidth}>
      <div className={classes.root} data-testid={testId}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.title}>
              Search
            </Typography>
            <OpenWith className="mr-2" style={{ cursor: 'move' }} id="draggable-dialog" />
            <div className={clsx(classes.search, `${hideSearchBox ? 'hidden' : ''}`)}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                autoFocus
                className={`no-drag-zone ${hideSearchBox ? 'invisible' : ''}`}
                id="searchQuery"
                data-testid="searchQuery"
                value={q}
                onChange={(event) => setQ(event.target.value)}
                onFocus={() => {
                  setFilterOpen(true);
                }}
                placeholder="Search..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{
                  'aria-label': 'search',
                  'data-testid': inputTestId,
                  maxLength: searchCharacterLimit,
                }}
              />
            </div>
            {q && (
              <Button
                color="inherit"
                onClick={() => {
                  setQ('');
                  setFilterOpen(true);
                }}
              >
                Clear
              </Button>
            )}
            <IconButton color="inherit" aria-label="close dialog" edge="end" onClick={handleClose}>
              <Close />
            </IconButton>
          </Toolbar>
          {Filters && !filterOpen && (
            <div className="flex items-center gap-2 bg-white px-3 py-1 text-left text-gray-700">
              <Button
                color="primary"
                onClick={() => {
                  setFilterOpen(true);
                }}
              >
                Filters <ExpandMore />
              </Button>
            </div>
          )}
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className="flex-1 overflow-x-hidden overflow-y-scroll" style={{ height: '425px' }}>
            {filterOpen ? (
              <>
                <div className="overflow-x-hidden overflow-y-scroll" style={{ height: '360px' }}>
                  {Filters}
                </div>
                <Divider className="my-3" />
                <Button
                  variant="contained"
                  className={`mx-auto block ${classes.resultsButton}`}
                  onClick={() => {
                    setFireSearch(true);
                    setFilterOpen(false);
                  }}
                >
                  See Results
                </Button>
              </>
            ) : (
              <div className="mt-6">
                {saving && <LoaderCards />}
                {isFetching ? (
                  <LoaderCards />
                ) : entities?.length ? (
                  entities.map((entity, i) => <SelectDisplay key={i} selected={selected} entity={entity} handleSelect={selectAction} />)
                ) : (
                  <EmptyComponent text="None found" />
                )}
              </div>
            )}
          </div>
        </main>
      </div>
    </DraggableDialog>
  );
}

function SearchableDialog(props: ExtendedSearchableDialogProps) {
  const {
    entityName,
    Filters = null,
    graphQLQuery,
    graphQLVariables = (o) => o,
    handleClose,
    hideSearchBox = false,
    inputTestId = 'searchableDialogInput',
    maxWidth = 'md',
    open,
    saving = false,
    SelectDisplay,
    testId = 'searchableDialog',
    selected = null,
    searchQuery = '',
    classes,
    selectAction,
  } = props;
  const [q, setQ] = useState(searchQuery);
  const debouncedQ = useDebouncePrimitive(q, 300);
  const variables = { q: debouncedQ, ...graphQLVariables(debouncedQ) };
  const [exactMatchUrl, setExactMatchUrl] = useState<string | null>(null);
  const history = useHistory();

  const setSearchableOptionsOverrides = (overrides) => {
    Filters?.props.setEntities({
      ...Filters?.props.entitiesToIgnore,
      ...overrides,
    });
  };

  useEffect(() => {
    switch (q?.toLowerCase().trim().slice(0, 2)) {
      case 'sr':
        setSearchableOptionsOverrides({ serviceRequests: false });
        break;
      case 'eq':
        setSearchableOptionsOverrides({ equipment: false });
        break;
      case 'in':
        setSearchableOptionsOverrides({ invoices: false });
        break;
      default:
        setSearchableOptionsOverrides({
          serviceRequests: true,
          equipment: true,
          invoices: true,
        });
    }
  }, [q]);

  const { data: entities, isFetching } = useQuery(['SearchableDialogShell', variables], () => graphQLQuery(variables), {
    enabled: !!debouncedQ || hideSearchBox,
    select: (data) => data?.data?.[entityName]?.data || [],
    onSuccess: (data) => setExactMatchUrl(data.length === 1 ? data[0].url : null),
  });

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {Filters && <div className="p-4">{Filters}</div>}
    </div>
  );

  const drawerContainer = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);

  const navigateToExactMatchUrl = (exactMatchUrl: string | null) => {
    if (exactMatchUrl) {
      history.push(exactMatchUrl);
      handleClose();
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        navigateToExactMatchUrl(exactMatchUrl);
        break;
      default:
        return;
    }
  };

  return (
    <DraggableDialog onClose={() => handleClose()} aria-labelledby="locationSelect" open={open} fullWidth maxWidth={maxWidth}>
      <div className={classes.root} data-testid={testId}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.title}>
              Search
            </Typography>
            <OpenWith className="mr-2" style={{ cursor: 'move' }} id="draggable-dialog" />
            <div className={clsx(classes.search, `${hideSearchBox ? 'hidden' : ''}`)}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                autoFocus
                className={`no-drag-zone ${hideSearchBox ? 'invisible' : ''}`}
                id="searchQuery"
                data-testid="searchQuery"
                value={q}
                onChange={(event) => setQ(event.target.value)}
                placeholder="Search..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{
                  'aria-label': 'search',
                  'data-testid': inputTestId,
                  maxLength: searchCharacterLimit,
                  onKeyDown: handleOnKeyDown,
                }}
              />
            </div>
            {q && (
              <Button color="inherit" onClick={() => setQ('')}>
                Clear
              </Button>
            )}
            <IconButton color="inherit" aria-label="close dialog" edge="end" onClick={handleClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        {Filters && (
          <div className={classes.drawer} aria-label="search filters" ref={drawerContainer}>
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </div>
        )}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className="flex-1 overflow-x-hidden overflow-y-scroll" style={{ height: '425px' }}>
            {saving && <LoaderCards />}
            {isFetching ? (
              <LoaderCards />
            ) : entities?.length ? (
              entities.map((entity, i) => (
                <SelectDisplay
                  key={i}
                  selected={selected}
                  entity={entity}
                  handleSelect={selectAction}
                  ref={exactMatchUrl && entity.url === exactMatchUrl ? selectedRef : null}
                />
              ))
            ) : !debouncedQ && !hideSearchBox ? (
              <EmptyComponent text="Enter search term above" Icon={Search} />
            ) : (
              <EmptyComponent text="None found" />
            )}
          </div>
        </main>
      </div>
    </DraggableDialog>
  );
}

export default function SearchableDialogShell(props: SearchableDialogShellProps) {
  const windowSize = useWindowSize();
  const isMobile = !!(windowSize.width && windowSize.width <= 768);

  const { selected, handleSelect, handleClose } = props;

  const selectAction = async (data) => {
    if (selected) {
      await handleSelect({ ...data, selected });
    } else {
      await handleSelect(data);
      handleClose();
    }
  };

  const drawerWidth = props.Filters ? 400 : 0;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appBar: {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
      title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        marginRight: theme.spacing(1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      resultsButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '20ch',
          },
        },
      },
      // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
    }),
  );

  const classes = useStyles();

  const propsToBePassed = { ...props, classes, selectAction };

  if (isMobile) {
    return <MobileSearchableDialog {...propsToBePassed} />;
  } else {
    return <SearchableDialog {...propsToBePassed} />;
  }
}
