export default function clearCachedServiceWorker() {
  try {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
      });
    }
  } catch (error) {
    console.error(error);
  }
}
