import { ClientOrganizationEnumType, ServiceRequestStatusType } from '@equips/entities-schema';

const { Canceled, ServiceComplete, Closed, InternalDispatch, ProviderDispatch, EquipsDispatch } = ServiceRequestStatusType || {};
const terminal = [ServiceComplete, Canceled, Closed];
const dispatch = [InternalDispatch, ProviderDispatch, EquipsDispatch];
const close = [ServiceComplete, Closed];

export const getClosingStatus = (clientOrganizationType: ClientOrganizationEnumType | null = ClientOrganizationEnumType.EquipsBasic) =>
  clientOrganizationType &&
  [ClientOrganizationEnumType.EquipsCoverage, ClientOrganizationEnumType.EquipsFieldDispatch].includes(clientOrganizationType)
    ? ServiceRequestStatusType.ServiceComplete
    : ServiceRequestStatusType.Closed;

export default { terminal, dispatch, close };
