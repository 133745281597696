import React from 'react';
import Chip from '@mui/material/Chip';
import { tagStyleToSx } from '../Tags/ClientTag';

type MultipleSelectChipProps = {
  chipSetType: 'choice' | 'filter';
  selected: string | object;
  setSelected: (value: string | object) => any;
  chipTestId: string;
  options: {
    label: string;
    value: string;
    style?: string;
  }[];
};

export default function MultipleSelectChip({ chipSetType, options, selected, setSelected, chipTestId = '' }: MultipleSelectChipProps) {
  const toggleSelected = (key) => {
    if (chipSetType === 'filter') {
      setSelected({
        ...(selected as object),
        [key]: !selected[key],
      });
    } else {
      setSelected(key);
    }
  };

  return (
    <div className="flex flex-wrap justify-start gap-2">
      {options.map(({ value, label, style }) => (
        <Chip
          key={value}
          data-testid={chipTestId}
          variant={selected[value] ? undefined : 'outlined'}
          color={style ? undefined : 'primary'}
          onClick={() => toggleSelected(value)}
          label={label}
          sx={tagStyleToSx(style)}
        />
      ))}
    </div>
  );
}
