export const industries = [
  'Financial',
  'Healthcare',
  'Hospitality',
  'Restaurant',
  'Education',
  'Government',
  'Retail',
  'Automotive',
  'Construction',
  'Communications',
  'Utilities',
  'Manufacturing',
  'Transportation',
  'Real Estate',
  'Business Services',
  'Other',
].sort();
