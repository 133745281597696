export default [
  'America/Anchorage',
  'America/Boise',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/Edmonton',
  'America/Fort_Wayne',
  'America/Indianapolis',
  'America/Juneau',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Monterrey',
  'America/Montreal',
  'America/Nassau',
  'America/New_York',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Phoenix',
  'America/Puerto_Rico',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
];
