/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import copy from 'copy-to-clipboard';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';

interface TableCellCopyDataProps {
  data: string;
  children?: JSX.Element | JSX.Element[];
}

export default function TableCellCopyData({ children, data }: TableCellCopyDataProps) {
  const showAlert = useAlert();
  function copyDataAndSendNotification(data: string) {
    copy(data);
    showAlert({ content: 'Copied to clipboard!', type: AlertTypes.success });
  }

  return (
    <>
      <div aria-hidden="true" className="cursor-pointer" onClick={() => copyDataAndSendNotification(data)}>
        {children || data}
      </div>
    </>
  );
}
