import { Coverage, CoverageMetadata, CoverageStatusType, Maybe } from '@equips/entities-schema';

export const isEffectiveCoverageAtDate = (date?: number | null) => (coverage: Maybe<Coverage>) =>
  coverage?.metadata?.coverageStatus === CoverageStatusType.Effective &&
  (coverage?.metadata?.onDate || Infinity) <= (date || 0) &&
  (coverage?.metadata?.offDate || 0) >= (date || 0);

export const isEffectiveCoverageNow = (coverage: Maybe<Coverage>) => isEffectiveCoverageAtDate(Date.now())(coverage);

type ContractDatesType = { effectiveDate?: number | null; expirationDate?: number | null };

export const hasCoveragesEffectiveOnContract = (
  coverageMetadatas: Maybe<CoverageMetadata>[] | null | undefined,
  { effectiveDate, expirationDate }: ContractDatesType,
) => {
  if (!coverageMetadatas) return false;

  const effectiveDateWithDefault = effectiveDate || Infinity;
  const expirationDateWithDefault = expirationDate || 0;

  return coverageMetadatas?.some(
    (coverageMetadata) =>
      coverageMetadata?.coverageStatus === CoverageStatusType.Effective &&
      (coverageMetadata?.onDate || Infinity) <= expirationDateWithDefault &&
      (coverageMetadata?.offDate || 0) >= effectiveDateWithDefault,
  );
};
