import { ClientOrganizationEnumType, Maybe, OptInWorkOrderStatusEnumType, ServiceRequestStatusType } from '@equips/entities-schema';
import { AuthContextValue } from '../../../common/auth/AuthContext';
import { clientOrganizationTypes, EnumObjectType, getEnumDisplayName, serviceRequestDispatchStatuses } from '../../../graphql/enums';

export const womStatusToAllStatusMap: { [status: string]: { value: string; womValue: ServiceRequestStatusType; name: string } } = {
  // Opt in statuses
  onHold: { value: 'onHold', womValue: ServiceRequestStatusType.OnHold, name: 'On hold' },
  proposed: { value: 'proposed', womValue: ServiceRequestStatusType.Proposed, name: 'Proposed' },
  canceled: { value: 'canceled', womValue: ServiceRequestStatusType.Canceled, name: 'Canceled' },

  // Default WOM statuses
  open: { value: 'internalDispatch', womValue: ServiceRequestStatusType.InternalDispatch, name: 'Open' },
  inProgress: { value: 'inProgress', womValue: ServiceRequestStatusType.InProgress, name: 'In progress' },
  closed: { value: 'closed', womValue: ServiceRequestStatusType.Closed, name: 'Closed' },

  // Open
  internalDispatch: { value: 'internalDispatch', womValue: ServiceRequestStatusType.InternalDispatch, name: 'In-house' },
  equipsDispatch: { value: 'equipsDispatch', womValue: ServiceRequestStatusType.InternalDispatch, name: 'Equips processing' },
  providerDispatch: { value: 'providerDispatch', womValue: ServiceRequestStatusType.InternalDispatch, name: 'Dispatched' },

  // In Progress
  followUp: { value: 'followUp', womValue: ServiceRequestStatusType.InProgress, name: 'Needs follow up' },
  awaitingPayment: { name: 'Awaiting Payment', womValue: ServiceRequestStatusType.InProgress, value: 'awaitingPayment' },

  // Closed
  serviceComplete: { value: 'serviceComplete', womValue: ServiceRequestStatusType.Closed, name: 'Completed' },
  /** @deprecated Now tracked in billingStatus */
  invoiced: { value: 'invoiced', womValue: ServiceRequestStatusType.Closed, name: 'Invoiced' },
};

export const getStatusDisplayNameForWOM = (
  value: undefined | string | null,
  enumObject: EnumObjectType,
  clientOrganizationType: ClientOrganizationEnumType | undefined | null,
) => {
  let status;

  if (
    clientOrganizationType &&
    [ClientOrganizationEnumType.EquipsCoverage, ClientOrganizationEnumType.EquipsBasic].includes(clientOrganizationType)
  ) {
    status = value;
  } else {
    status = womStatusToAllStatusMap[value ?? '']?.womValue;
    if (status === ServiceRequestStatusType.InternalDispatch) {
      status = 'open';
    }
  }

  return getEnumDisplayName(status, womStatusToAllStatusMap);
};

interface OptionsProps {
  requestStatus?: Maybe<ServiceRequestStatusType>;
  hasCOAAuthorization?: boolean;
  clientOrganizationType?: ClientOrganizationEnumType | null;
}

const { open, newClaim, inProgress, closed, serviceComplete, awaitingPayment, onHold, ...baseRequestOptions } =
  serviceRequestDispatchStatuses;
const { internalDispatch, equipsDispatch, providerDispatch, proposed } = baseRequestOptions;

const workOrderStatuses = { open, inProgress, closed };

const optionsByClientOrganizationType = {
  equipsBasic: { ...baseRequestOptions, serviceComplete, awaitingPayment, onHold },
  equipsCoverage: { ...baseRequestOptions, serviceComplete, awaitingPayment, onHold },
  equipsFieldDispatch: { ...workOrderStatuses },
  equipsWorkOrderManagement: { ...workOrderStatuses },
  equipsHomeWarranty: { newClaim, providerDispatch, inProgress, serviceComplete, closed },
};

export const getStatusDisplayNameForHW = (value: undefined | string | null) => {
  let status = value;

  if (value === ServiceRequestStatusType.InternalDispatch) {
    status = 'newClaim';
  }

  if (status && optionsByClientOrganizationType.equipsHomeWarranty[status])
    return getEnumDisplayName(status, optionsByClientOrganizationType.equipsHomeWarranty);

  return '';
};

const optionsWithCOAAuthorization = {
  proposed: { internalDispatch, equipsDispatch, proposed },
  internalDispatch: { closed, serviceComplete, equipsDispatch },
};

export function withCOAAuthorization(opts: Pick<OptionsProps, 'hasCOAAuthorization' & 'requestStatus'> & Partial<OptionsProps>) {
  const { hasCOAAuthorization, requestStatus } = opts || {};

  return (serviceRequestOptions: EnumObjectType) => {
    if (!requestStatus || !hasCOAAuthorization) return serviceRequestOptions;
    serviceRequestOptions = optionsWithCOAAuthorization[requestStatus] ?? serviceRequestOptions;

    return serviceRequestOptions;
  };
}

export type getServiceRequestStatusOptionsTypes = {
  checks?: ((serviceRequestOptions: EnumObjectType) => EnumObjectType)[];
  auth: AuthContextValue['auth'];
  unauth?: {
    clientOrganizationType: ClientOrganizationEnumType;
    optInWorkOrderStatuses: Maybe<OptInWorkOrderStatusEnumType>[];
  };
};

export const getServiceRequestStatusOptions = ({ checks, auth, unauth }: getServiceRequestStatusOptionsTypes) => {
  const clientOrganizationType = auth?.organization?.metadata?.clientOrganizationType ?? unauth?.clientOrganizationType;
  const optInWorkOrderStatuses = auth?.organization?.metadata?.optInWorkOrderStatuses ?? unauth?.optInWorkOrderStatuses;

  let serviceRequestOptions: EnumObjectType =
    (clientOrganizationType && optionsByClientOrganizationType[clientOrganizationType]) ||
    optionsByClientOrganizationType[clientOrganizationTypes.equipsBasic.value];

  if (checks && checks.length > 0)
    checks.forEach(
      (check: (serviceRequestOptions: EnumObjectType) => EnumObjectType) => (serviceRequestOptions = check(serviceRequestOptions)),
    );

  if (clientOrganizationType === ClientOrganizationEnumType.EquipsWorkOrderManagement) {
    optInWorkOrderStatuses?.forEach((status) => {
      if (status) {
        const temp = serviceRequestDispatchStatuses[status];
        if (temp) {
          serviceRequestOptions[status] = { name: temp.name, value: temp.value };
        }
      }
    });
  }

  return serviceRequestOptions;
};
