import React from 'react';
import { Link } from 'react-router-dom';
import { Contract } from '@equips/entities-schema';
import { JsonParam } from 'use-query-params';
import { Translation } from 'react-i18next';
import { toESTDateStringFromUnixMillisecondTimestamp } from '../../common/functions/expensiveDateFunctions';
import Urls from '../../routes/Urls';
import DropDown from '../../common/components/DropDown';
import { TableFilterTypes } from '../../common/components/Table/Filters';
import { clientValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import {
  contractStatusOptions,
  enumObjectToSelectOptions,
  getEnumDisplayName,
  contractTypes,
  organizationIndustryTypes,
} from '../../graphql/enums';
import { AuthContextValue } from '../../common/auth/AuthContext';
import { internalUsers } from '../../common/auth/roles';
import { defaultTableWidths } from '../../common/components/Table/tableDefaults';
import { TableColumn } from '../../common/components/DataTable';
import { buildQueryString } from '../../common/functions/buildQueryString';

export default function ContractsColumns(userCan: AuthContextValue['userCan'], equipsUsers: any[]): TableColumn<Contract>[] {
  return [
    {
      id: 'metadata.shortId',
      Header: <Translation>{(t) => <>{t('number')}</>}</Translation>,
      accessor: (data) => data.metadata?.shortId,
      Cell: (data) => (
        <Link data-testid="contractNumberValue" className="active" to={`${Urls.AGREEMENTS}/${data?.row?.original?.metadata?.contractId}`}>
          {data.value}
        </Link>
      ),
    },
    {
      id: 'metadata.organizationId',
      Header: <Translation>{(t) => <>{t('client')}</>}</Translation>,
      accessor: (data) => data.organizationMetadata?.organizationName,
      filterOptions: {
        type: TableFilterTypes.organizationMultiSelect,
        defaultValue: [],
        typeOfOrganizationToFind: clientValueForFilter,
        filterType: JsonParam,
      },
    },
    {
      id: 'metadata.accountManagerId',
      Header: <Translation>{(t) => <>{t('accountManager')}</>}</Translation>,
      accessor: (data) => data?.metadata?.accountManager?.metadata?.fullName,
      disableSortBy: true,
      Cell: (data) => data.value || 'Unassigned',
      filterOptions: {
        hidden: !userCan(internalUsers),
        type: TableFilterTypes.select,
        options: equipsUsers,
      },
    },
    {
      id: 'organizationMetadata.industry',
      Header: <Translation>{(t) => <>{t('industry')}</>}</Translation>,
      accessor: (data) => data?.organizationMetadata?.industry,
      Cell: (d) => <div>{getEnumDisplayName(d?.value, organizationIndustryTypes)}</div>,
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(organizationIndustryTypes, { anyText: 'Any' }),
      },
    },
    {
      id: 'organizationMetadata.producers',
      Header: <Translation>{(t) => <>{t('producer')}</>}</Translation>,
      accessor: (data) => data?.organizationMetadata?.producers,
      disableSortBy: true,
      Cell: (data) => data?.value?.[0] || 'No producer',
      width: defaultTableWidths.normalText,
    },
    {
      id: 'organizationMetadata.subproducers',
      Header: <Translation>{(t) => <>{t('subproducers')}</>}</Translation>,
      accessor: (data) => data?.organizationMetadata?.producers,
      disableSortBy: true,
      Cell: (data) => (data?.value && data?.value.length > 0 ? data?.value.slice().join(', ') : ''),
      width: defaultTableWidths.normalText,
    },
    {
      id: 'organizationBillingAddress.stateUnabbreviated',
      Header: <Translation>{(t) => <>{t('state')}</>}</Translation>,
      accessor: (data) => data?.organizationBillingAddress?.stateUnabbreviated,
      disableSortBy: true,
      filterOptions: {
        hidden: !userCan(internalUsers),
        type: TableFilterTypes.stateSelect,
      },
    },
    ...(userCan(internalUsers)
      ? [
          {
            id: 'metadata.coverageProviderId',
            Header: <Translation>{(t) => <>{t('provider')}</>}</Translation>,
            accessor: (data) => data?.metadata?.coverageProvider?.metadata?.organizationName || '',
            Cell: (d) => d?.row?.original?.metadata?.coverageProvider?.metadata?.organizationName || '',
            filterOptions: {
              type: TableFilterTypes.organizationSelect,
            },
          },
          {
            id: 'metadata.underwriterId',
            Header: <Translation>{(t) => <>{t('underwriter')}</>}</Translation>,
            accessor: (data) => data?.metadata?.underwriter?.metadata?.fullName || '',
            disableSortBy: true,
            Cell: (data) => data.value || 'None',
            filterOptions: {
              hidden: !userCan(internalUsers),
              type: TableFilterTypes.select,
              options: equipsUsers,
            },
          },
        ]
      : []),
    {
      id: 'metadata.effectiveDate',
      Header: <Translation>{(t) => <>{t('effectiveDate')}</>}</Translation>,
      accessor: (data) => data?.metadata?.effectiveDate,
      Cell: (d) => toESTDateStringFromUnixMillisecondTimestamp(d.value, 'MM/DD/YYYY'),
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.expirationDate',
      Header: <Translation>{(t) => <>{t('expirationDate')}</>}</Translation>,
      accessor: (data) => data?.metadata?.expirationDate,
      Cell: (d) => toESTDateStringFromUnixMillisecondTimestamp(d.value, 'MM/DD/YYYY'),
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.expired',
      Header: <Translation>{(t) => <>{t('expired')}</>}</Translation>,
      accessor: (data) => data?.metadata?.expired,
      Cell: (d) => (d.value ? 'Yes' : 'No'),
    },
    ...(userCan(internalUsers)
      ? [
          {
            id: 'metadata.totalPmHours',
            Header: <Translation>{(t) => <>{t('pmHours')}</>}</Translation>,
            accessor: (data) => data?.metadata?.totalPmHours,
          },
          {
            id: 'metadata.contractType',
            Header: <Translation>{(t) => <>{t('contractType')}</>}</Translation>,
            accessor: (data) => data?.metadata?.contractType,
            Cell: (d) => <div data-testid="contractTypeDisplayValue">{getEnumDisplayName(d?.value, contractTypes)}</div>,
            filterOptions: {
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(contractTypes, { anyText: 'Any' }),
            },
          },
          {
            id: 'metadata.segmentCode',
            Header: <Translation>{(t) => <>{t('segmentCode')}</>}</Translation>,
            accessor: (data) => data?.metadata?.segmentCode,
          },
          {
            id: 'metadata.contractStatus',
            Header: <Translation>{(t) => <>{t('status')}</>}</Translation>,
            accessor: (data) => data?.metadata?.contractStatus,
            Cell: (d) => getEnumDisplayName(d?.value, contractStatusOptions),
            filterOptions: {
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(contractStatusOptions, { anyText: 'Any' }),
            },
          },
        ]
      : []),
    {
      id: 'metadata.contractId',
      Header: '',
      accessor: (data) => data?.metadata?.contractId,
      disableSortBy: true,
      Cell: (data) => {
        if (!userCan(internalUsers)) return null;

        return (
          <DropDown
            testId="contractOptions"
            actions={[
              { Component: 'View Agreement Overview', to: `${Urls.AGREEMENTS_BULK_EDIT}/${data.value}` },
              {
                Component: 'View Coverage',
                to: buildQueryString(Urls.COVERAGE, {
                  'metadata.contractId': data.value,
                  'metadata.expired': 'false',
                }),
              },
              { border: true },
              {
                Component: 'View Organization',
                to: `${Urls.ORGANIZATIONS}/${data?.row?.original?.organizationMetadata?.organizationId}`,
              },
            ]}
          />
        );
      },
      width: 100,
    },
  ];
}
