import React, { useState, useReducer, useMemo } from 'react';
import classNames from 'classnames';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Popper from '../Popper';
import createRandomString from '../../functions/createRandomString';

type LabelProps = {
  id: string;
  label: string | React.ReactNode;
  htmlId?: string;
  className?: string;
  required?: boolean;
  helper?: any;
  size?: string;
  labelClassName?: string;
  // All other props
  [x: string]: any;
};
export default function Label({
  id,
  label,
  required = false,
  className = '',
  htmlId = '',
  helper = '',
  size = '',
  labelClassName,
  ...restOfProps
}: LabelProps) {
  const [open, dispatch] = useReducer((state, newValue) => newValue, false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | undefined>();

  const buttonId = useMemo(() => createRandomString(8), []);
  const sizeOptions = size === 'small' ? { labelFontSize: 'text-xs' } : { labelFontSize: 'text-sm' };

  return (
    <div className={classNames([className, 'relative flex'])}>
      <label
        htmlFor={id}
        id={htmlId}
        className={`${classNames(`cursor-pointer font-semibold text-gray-700`, {
          relative: required,
        })} ${sizeOptions.labelFontSize} ${labelClassName}`}
        {...restOfProps}
      >
        {label}
      </label>
      {required && <span className="ml-1 h-2 text-gray-600">*</span>}
      {helper && (
        <>
          <button
            tabIndex={-1}
            className="text-xs leading-none text-gray-700"
            style={{ marginTop: '-3px', marginLeft: '2px' }}
            type="button"
            id={buttonId}
            onClick={(event) => {
              dispatch(true);
              setAnchorEl(event.currentTarget);
            }}
          >
            <HelpOutline style={{ fontSize: '12px' }} />
          </button>

          <Popper handleClose={() => dispatch(false)} open={open} placement="right" anchorEl={anchorEl || <div />}>
            <div className="max-w-sm text-sm">{helper}</div>
          </Popper>
        </>
      )}
    </div>
  );
}
