import React from 'react';
import Chip from '@mui/material/Chip';
import { safelyParseJson } from '../../functions/safelyParseJson';

export type ClientTagProps = {
  label?: React.ReactNode;
  value?: string | null;
  style?: string | null;
  onClick?: (value?: string | null) => any;
  [prop: string]: any;
};

// Adds styles for necessary UI states (hover, selected, etc.)
export function tagStyleToSx(style?: string | null) {
  const styles = safelyParseJson(style) || {};

  return {
    ...styles,
    '&.MuiChip-clickable:hover': {
      ...styles,
      filter: 'brightness(0.9)',
    },
    '&.MuiChip-outlinedDefault': {
      backgroundColor: styles?.color || undefined,
      color: styles?.backgroundColor || undefined,
      borderColor: styles?.backgroundColor || undefined,
    },
    '&.MuiChip-filledDefault': {
      ...styles,
    },
  };
}

export default function ClientTag({ label = '', value = '', style, onClick, ...restOfProps }: ClientTagProps) {
  return <Chip label={label} sx={tagStyleToSx(style)} onClick={onClick ? () => onClick(value) : undefined} {...restOfProps} />;
}
