import {
  postToEquipmentEndpoint,
  fullEquipmentMetadataGraphqlNode,
  queryEquipment,
  fullChangesGraphQLNode,
  EquipmentStatusType,
  Maybe,
  fullEquipmentOptionsGraphqlNode,
  SortByElasticSearchOptions,
} from '@equips/entities-schema';
import dayjs from 'dayjs';
import { NoteListFields, NoteListFragment } from '../../common/components/ViewPage/NoteListFragment';
import { gql } from '../graphqlHelperFunctions';
import { toTerms } from '../../common/functions/elasticSearchHelpers';
import { categoryGraphQlNode } from './categoryQueries';

export const Equipment = {
  fragments: {
    nameAndId: gql`
      fragment NameAndId on EquipmentMetadata {
        equipmentId
        equipmentName
      }
    `,
    metadata: gql`
      fragment EquipmentMetadata on EquipmentMetadata {
        equipmentId
        equipmentName
        shortId
        externalId
        locationId
        organizationId
        serialNumber
        specId
      }
    `,
  },
};

export const getEquipmentNotes = async ({ entityId }) => {
  const data = await queryEquipment(
    { equipmentId: entityId },
    {
      query: `notes { ${NoteListFields} }`,
    },
  );
  return data.data?.equipment?.data?.[0]?.notes ?? [];
};

export const getEquipmentDispatchNote = async ({ entityId }) =>
  postToEquipmentEndpoint({
    query: gql`
      query ($equipmentId: String!) {
        equipment(equipmentId: $equipmentId) {
          data {
            dispatchNote {
              ...NoteComponentData
            }
          }
        }
      }
      ${NoteListFragment.fragments.noteComponentData}
    `,
    variables: { equipmentId: entityId },
  });

export const getEquipmentClientToProviderDispatchNote = async ({ entityId }) =>
  postToEquipmentEndpoint({
    query: gql`
      query ($equipmentId: String!) {
        equipment(equipmentId: $equipmentId) {
          data {
            clientToProviderDispatchNote {
              ...NoteComponentData
            }
          }
        }
      }
      ${NoteListFragment.fragments.noteComponentData}
    `,
    variables: { equipmentId: entityId },
  });

export const getProvidersByEquipmentId = async ({ equipmentId }) =>
  postToEquipmentEndpoint({
    query: gql`
      query ($equipmentId: String) {
        equipment(equipmentId: $equipmentId) {
          data {
            metadata {
              provider {
                metadata {
                  organizationName
                  organizationId
                }
              }
              consumablesProvider {
                metadata {
                  organizationName
                  organizationId
                }
              }
            }
          }
        }
      }
    `,
    variables: { equipmentId },
  });

export const getEquipmentByIdForMainView = async ({ equipmentId }) =>
  queryEquipment(
    { equipmentId },
    {
      query: `
      ${fullEquipmentOptionsGraphqlNode}
      coverages {
        metadata {
          coverageId
          coverageStatus
          onDate
          offDate
        }
      }
      ${fullEquipmentMetadataGraphqlNode}
      metadata {
        assetPrice
        serviceTotal
        tid
        specId
        shortId
        serialNumber
        equipmentStatus
        mainContactUser {
          metadata {
            fullName
            email
          }
        }
        contacts {
          fullName
          email 
          phone
        }
        pinnedNote
        pmFrequency
        usefulLifeEndDate
        salvageValue
        dispatchTo
        externalId
        pinnedNote
        pinnedImageId
        equipsCoveragePmCadence
        monthsToPmEquipment
        qrCodeId
        routing {
          type
          categoryIds
          locationId
          equipmentId
          emails
          providerId
          schedule {
            days
            startHour
            endHour
          }
          routeId
        }
        installationDate
        warrantyOnDate
        warrantyOffDate
        warrantyInfo
        organization {
          metadata {
            organizationName
            autorequestId
            clientOrganizationType
            tier
            allowedClientTags {
              tagId
              entityLabel
              name
              organizationId
              style 
            }
          }
          provider {
            dispatchEmail
          }
        }
        createdByUser {
          metadata {
            fullName
          }
        }
        modifiedByUser {
          metadata {
            fullName
          }
        }
        flaggedChronicAt
        flaggedChronicUser {
          metadata {
            fullName
          }
        }
        location {
          locationId
          metadata {
            locationDisplayName
            sublocations{
              metadata{
                locationId
                locationDisplayName
              }
            }
            parentLocation{
              metadata{
                locationId
                locationDisplayName
              }
            }
          }
          address {
            line1
            line2
            city
            stateUnabbreviated
            zip
          }
        }
        possibleProviders {
          metadata {
            organizationName
            organizationId
          }
        }
        imageId
        pinnedImageId
        clientTags {
          tagId
          entityLabel
          name
          style
          organizationId
        }
      }
      specMetadata {
        ${categoryGraphQlNode}
        model
        manufacturer
        manufacturerEOL
        vendorLimitedSupportDate
      }
    `,
    },
  );

export const getEquipmentChanges = async (equipmentId) =>
  postToEquipmentEndpoint({
    query: gql`
      query($equipmentId: String!) {
        equipment(equipmentId: $equipmentId) {
          data {
            ${fullChangesGraphQLNode}
          }
        }
      }
    `,
    variables: {
      equipmentId,
    },
  });

export const getAllEquipmentByLocationId = async ({ locationId }) =>
  queryEquipment(
    { search: { filters: [{ metadata: { locationId: [{ keyword: [{ term: locationId }] }] } }] } },
    {
      query: `${fullEquipmentMetadataGraphqlNode} 
        specMetadata { 
          ${categoryGraphQlNode}
          model
          manufacturer
        }
        metadata {
          provider {
            metadata {
              organizationName
              organizationId
            }
          }
        }`,
    },
  );

export const getEquipmentLocationMetadata = async ({ equipmentId }) =>
  queryEquipment(
    {
      equipmentId,
    },
    {
      query: `
      metadata { 
        location {
          metadata {
            locationId 
            locationDisplayName
          }
        } 
      }
      specMetadata{
        ${categoryGraphQlNode}
      }`,
    },
  );

export const getEquipmentPMFrequencyByEquipmentId = async ({ equipmentId }) =>
  queryEquipment(
    {
      equipmentId,
    },
    {
      query: `
    equipmentId
    metadata {
      shortId
      pmFrequency
      equipmentHealth
      equipmentStatus
      serialNumber
      serviceEndDate
    }
    `,
    },
  );

export const getProviderInformationByEquipmentId = async ({ equipmentId }) =>
  postToEquipmentEndpoint({
    query: gql`
      query getEquipmentAsUser($equipmentId: String) {
        equipment(equipmentId: $equipmentId) {
          data {
            metadata {
              providerId
              provider {
                metadata {
                  organizationId
                  organizationName
                }
                provider {
                  equipmentServiced
                  dispatchEmail
                  dispatchPhone
                  dispatchWebsite
                }
              }
              consumablesProviderId
              consumablesProvider {
                metadata {
                  organizationId
                  organizationName
                }
                provider {
                  equipmentServiced
                  dispatchEmail
                  dispatchPhone
                  dispatchWebsite
                }
              }
              possibleProviders {
                metadata {
                  organizationName
                  organizationId
                }
                billingAddress {
                  line1
                  line2
                  city
                  stateUnabbreviated
                  zip
                }
                provider {
                  isExternalProvider
                  dispatchEmail
                  dispatchPhone
                  dispatchWebsite
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      equipmentId,
    },
  });

export const getEquipmentClassById = async ({ equipmentId }) =>
  postToEquipmentEndpoint({
    query: gql`
      query ($equipmentId: String!) {
        equipment(equipmentId: $equipmentId) {
          data {
            specMetadata {
              ${categoryGraphQlNode}
            }
          }
        }
      }
    `,
    variables: { equipmentId },
  });

export const getProvidersAndClassForEquipment = async ({ equipmentId }) =>
  queryEquipment(
    { equipmentId },
    { query: `specMetadata {${categoryGraphQlNode}} metadata { possibleProviders {metadata {organizationId organizationName}} }` },
  );

export const getEquipmentDataForCoverageImport = async (equipmentId: string) => {
  const { data } = await queryEquipment(
    { equipmentId },
    {
      query: `
      locationMetadata{locationId locationName} 
      metadata{shortId organizationId}
      specMetadata{${categoryGraphQlNode}}
      `,
    },
  );

  const equipment = data?.equipment?.data?.[0];

  return {
    shortId: equipment?.metadata?.shortId || '',
    organizationId: equipment?.metadata?.organizationId || '',
    locationId: equipment?.locationMetadata?.locationId || '',
    locationName: equipment?.locationMetadata?.locationName || '',
    category: equipment?.specMetadata?.category?.metadata?.name || '',
  };
};

export const getEquipmentDataForCoverageImportByShortId = async (shortId: string) => {
  const { data } = await queryEquipment(
    {
      search: {
        filters: [{ metadata: { shortId: [{ keyword: [{ term: shortId }] }] } }],
      },
    },
    {
      query: `
      equipmentId
      locationMetadata{locationId locationName} 
      metadata{shortId organizationId}
      specMetadata{${categoryGraphQlNode}}
      `,
    },
  );

  const equipment = data?.equipment?.data?.[0];

  return {
    equipmentId: equipment?.equipmentId || '',
    organizationId: equipment?.metadata?.organizationId || '',
    locationId: equipment?.locationMetadata?.locationId || '',
    locationName: equipment?.locationMetadata?.locationName || '',
    category: equipment?.specMetadata?.category?.metadata?.name || '',
  };
};

export const getProposedEquipmentCount = async (createdByUserId: Maybe<string> = null) =>
  queryEquipment(
    {
      search: {
        includeTotalCount: true,
        filters: [
          {
            metadata: {
              equipmentStatus: [{ keyword: [{ term: EquipmentStatusType.Proposed }] }],
              ...(createdByUserId ? { createdByUserId: [{ keyword: [{ term: createdByUserId }] }] } : {}),
            },
          },
        ],
      },
    },
    { query: `metadata{ equipmentId }` },
  );

export const checkForDuplicateEquipment = async ({ categoryId, manufacturer, model, locationId, serialNumber }) =>
  queryEquipment({
    search: {
      size: 25,
      filters: serialNumber
        ? [
            {
              metadata: {
                serialNumber: [{ keyword: [{ term: serialNumber }] }],
              },
            },
          ]
        : [
            {
              specMetadata: {
                categoryId: [{ keyword: [{ term: categoryId }] }],
                manufacturer: [{ keyword: [{ term: manufacturer }] }],
                model: [{ keyword: [{ term: model }] }],
              },
              metadata: {
                locationId: [{ keyword: [{ term: locationId }] }],
                deactivatedAt: [{ exists: false }],
              },
            },
          ],
    },
  });

export const checkForDuplicateActiveEquipment = async ({ categoryId, manufacturer, model, locationId }) =>
  queryEquipment({
    search: {
      size: 25,
      filters: [
        {
          specMetadata: {
            categoryId: [{ keyword: [{ term: categoryId }] }],
            manufacturer: [{ keyword: [{ term: manufacturer }] }],
            model: [{ keyword: [{ term: model }] }],
          },
          metadata: {
            locationId: [{ keyword: [{ term: locationId }] }],
            equipmentStatus: [{ keyword: [{ term: EquipmentStatusType.Active }] }],
            deactivatedAt: [{ exists: false }],
          },
        },
      ],
    },
  });

export const checkForDuplicateActiveEquipmentCount = async ({ categoryId, manufacturer, model, locationId }) =>
  queryEquipment(
    {
      search: {
        size: 25,
        includeTotalCount: true,
        filters: [
          {
            specMetadata: {
              categoryId: [{ keyword: [{ term: categoryId }] }],
              manufacturer: [{ keyword: [{ term: manufacturer }] }],
              model: [{ keyword: [{ term: model }] }],
            },
            metadata: {
              locationId: [{ keyword: [{ term: locationId }] }],
              equipmentStatus: [{ keyword: [{ term: EquipmentStatusType.Active }] }],
              deactivatedAt: [{ exists: false }],
            },
          },
        ],
      },
    },
    {
      query: `metadata { equipmentId }`,
    },
  );

type getEquipmentForMonthlyPmPageType = {
  organizationId: string;
  categoryIds: Maybe<Maybe<string>[]>;
  clientTags: Maybe<Maybe<string>[]>;
  year: number;
  monthsToPmEquipmentOnly: boolean;
  pageSize?: number;
  pageIndex?: number;
  locationId?: string;
  providerId?: string;
};

export const getEquipmentForMonthlyPmPage = async ({
  organizationId,
  categoryIds = [],
  clientTags = [],
  year,
  monthsToPmEquipmentOnly,
  pageSize = 50,
  pageIndex = 0,
  locationId,
  providerId,
}: getEquipmentForMonthlyPmPageType) => {
  // Create a Day.js object for the beginning of the year
  const beginningOfYear = dayjs(`${year}-01-01`).startOf('year').valueOf();
  const endOfYear = dayjs(`${year}-01-01`).endOf('year').valueOf();

  return queryEquipment(
    {
      search: {
        size: pageSize,
        from: pageSize * pageIndex,
        includeTotalCount: true,
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
        filters: [
          {
            metadata: {
              ...(monthsToPmEquipmentOnly ? { monthsToPmEquipment: [{ keyword: [{ exists: true }] }] } : {}),
              ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
              ...(locationId ? { locationId: [{ keyword: [{ term: locationId }] }] } : {}),
              ...(providerId ? { providerId: [{ keyword: [{ term: providerId }] }] } : {}),
            },
            specMetadata: {
              ...(categoryIds && categoryIds.length
                ? { categoryId: toTerms<string>(categoryIds.filter((id): id is string => id !== null)) }
                : {}),
            },
            tagMetadata: {
              tagId:
                clientTags && clientTags?.length && !clientTags?.includes('none')
                  ? toTerms<string>(clientTags.filter((id): id is string => id !== null))
                  : [{ keyword: [{ exists: false }] }],
            },
          },
        ],
      },
    },
    {
      query: gql`
      metadata {
        pmServiceRequests(filter:{ createdAt: {gte: ${beginningOfYear}, lte: ${endOfYear}}}) {
          metadata {
            serviceRequestId
            shortId
            title
            description
            createdAt
            dueDate
            scheduledDate
            dispatchedToProviderAt
            dispatchedToEquipsAt
            completedAt
          }
        }
        equipmentId
        locationId
        shortId
        monthsToPmEquipment
        provider { 
          metadata {
            organizationName
          }
        }
      }
      specMetadata {
        ${categoryGraphQlNode}
        model
        manufacturer
      }
      locationMetadata {
        timezone
      }
      `,
    },
  );
};

export const queryEquipmentBySerialNumber = async ({ organizationId, serialNumber }: { organizationId: string; serialNumber: string }) =>
  queryEquipment(
    {
      search: {
        size: 1,
        filters: [
          {
            metadata: {
              serialNumber: [{ keyword: [{ term: serialNumber }] }],
              organizationId: [{ keyword: [{ term: organizationId }] }],
            },
          },
        ],
      },
    },
    {
      query: `metadata { equipmentId }`,
    },
  );
