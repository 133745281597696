import React from 'react';
import MultiSelectUserCreate from '../Selects/MultiSelectUserCreate';

export const arrayOfDefaultTags = [
  'Fire',
  'Escalation',
  'Part Approval',
  'Managed Item',
  'Customer Response Pending',
  'Vendor Response Pending',
  'New Customer',
  'Chronic',
  'Awaiting FSR',
  'Resolved (Monitoring)',
  'Part ETA',
  'PO Needed',
  'Vendor Needed',
  'Potentially Chronic',
];

export const defaultTags = arrayOfDefaultTags.map((tag) => ({ value: tag, label: tag }));

type TagInputProps = {
  fullWidth?: boolean;
  label?: string | React.ReactNode;
  loading?: boolean;
  selectedTags?: { value: string; label: string }[] | [] | string[];
  onChange: (...args: any[]) => any;
  emptyMessage?: string;
  id?: string;
  testId?: string;
  selectOverflow?: boolean;
};

export default function TagInput({
  fullWidth = true,
  label = 'Add and edit tags',
  selectedTags = [],
  emptyMessage = 'Create a tag',
  onChange,
  loading = false,
  id = 'tagInput',
  testId = 'tagInput',
  selectOverflow = false,
}: TagInputProps) {
  return (
    <MultiSelectUserCreate
      id={id}
      testId={testId}
      defaultOptions={defaultTags}
      fullWidth={fullWidth}
      label={label}
      loading={loading}
      isClearable
      onChange={onChange}
      selectedValues={selectedTags}
      emptyMessage={emptyMessage}
      selectOverflow={selectOverflow}
    />
  );
}
