import React, { forwardRef, ReactNode, Ref } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

interface UnderlinedButtonProps {
  tiny?: boolean;
  loading?: boolean;
  children: ReactNode;
  className?: string;
  textColor?: string;
  danger?: boolean;
  white?: boolean;
  onClick?: () => void;
  type: 'button' | 'submit';
  [propName: string]: any;
}

const UnderlinedButton = forwardRef(
  (
    {
      children,
      loading = false,
      type,
      tiny = false,
      textColor = 'text-gray-800 hover:text-gray-600',
      className = '',
      danger = false,
      white = false,
      ...restOfProps
    }: UnderlinedButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const size = tiny ? 'text-xs' : 'text-base';

    let color = textColor;

    if (danger) {
      color = 'text-red-600 hover:text-red-400';
    } else if (white) {
      color = 'text-white hover:text-gray-200';
    }
    const loaderColor = 'black';

    return (
      // eslint-disable-next-line
      <button type={type} className={`${color} underline ${size} ${className} disabled:cursor-not-allowed`} ref={ref} {...restOfProps}>
        {loading ? <BeatLoader size={8} color={loaderColor} /> : children}
      </button>
    );
  },
);

export default UnderlinedButton;
