import React, { useMemo } from 'react';
import { Maybe } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import createRandomString from '../../functions/createRandomString';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import { getAllContractsByOrganizationId } from '../../../graphql/queries/contractQueries';
import { toESTDateStringFromUnixMillisecondTimestamp } from '../../functions/expensiveDateFunctions';
import InputLoader from '../Form/InputLoader';
import { getEnumDisplayName, contractStatusOptions } from '../../../graphql/enums';
import StyledSelect from './StyledSelect';

interface ContractSelectProps {
  setContractId: (value: string, dates: { effectiveDate: Maybe<number>; expirationDate: Maybe<number> }) => void;
  label?: string | React.ReactNode;
  required?: boolean;
  loading?: boolean;
  emptySelectionText?: string;
  prependedOptions?: { label: string; value: string }[];
  organizationId?: Maybe<string>;
  contractId?: Maybe<string>;
  fullWidth?: boolean;
  noContractsFoundMessage?: string;
  labelHelper?: string;
  className?: string;
  testId?: string;
}

export default function ContractSelect({
  setContractId,
  label = 'Agreements',
  required = false,
  loading: isLoading = false,
  emptySelectionText = 'Any',
  prependedOptions,
  organizationId = '',
  contractId = '',
  fullWidth = false,
  noContractsFoundMessage = 'No agreements found',
  labelHelper = '',
  className = '',
  testId = 'contractsSelect',
}: ContractSelectProps) {
  const htmlId = useMemo(() => createRandomString(15), []);

  const { data, isFetching } = useQuery(
    ['queryContractsForSelect', organizationId],
    () => getAllContractsByOrganizationId({ organizationId, size: 30 }),
    {
      enabled: !!organizationId,
      select: (data) => data?.data,
    },
  );

  const contracts = data?.contracts?.data || [];

  return (
    <FormGroup fullWidth={fullWidth} className={className}>
      <Label label={label} id={htmlId} required={required} helper={labelHelper} />
      {isFetching || isLoading ? (
        <InputLoader fullWidth />
      ) : !contracts || contracts.length === 0 ? (
        <p data-testid="contractsSelectNoLocations" className="text-gray-700">
          {noContractsFoundMessage}
        </p>
      ) : (
        <StyledSelect
          required={required}
          id={htmlId}
          value={contractId || ''}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            const { value } = event.target;
            const contract = contracts.find((contract) => contract?.metadata?.contractId === value);
            setContractId(value, {
              effectiveDate: contract?.metadata?.effectiveDate,
              expirationDate: contract?.metadata?.expirationDate,
            });
          }}
          data-testid={testId}
        >
          <option value="">{emptySelectionText}</option>
          {prependedOptions &&
            prependedOptions.map((option) => (
              <option key={option.value} value={option.value} data-testid="contractSelectResult">
                {option.label}
              </option>
            ))}
          {contracts.map((item) => (
            <option key={item?.metadata?.contractId} value={item?.metadata?.contractId || ''} data-testid="contractSelectResult">
              {item?.metadata?.shortId}&nbsp;-&nbsp;({toESTDateStringFromUnixMillisecondTimestamp(item?.metadata?.effectiveDate)}
              &nbsp;to&nbsp;
              {toESTDateStringFromUnixMillisecondTimestamp(item?.metadata?.expirationDate)})&nbsp;-&nbsp;
              {getEnumDisplayName(item?.metadata?.contractStatus, contractStatusOptions)}
            </option>
          ))}
        </StyledSelect>
      )}
    </FormGroup>
  );
}
