import React, { useMemo } from 'react';
import Select from 'react-select';
import FormGroup from '../Form/FormGroup';
import createRandomString from '../../functions/createRandomString';
import Label from '../Form/Label';
import InputLoader from '../Form/InputLoader';
import Input from '../Form/Input';
import { defaultSelectStyles } from './defaultSelectStyles';
import ReactSelectRequiredInput from './ReactSelectRequiredInput';

type MultiSelectProps = {
  id: string;
  handleChange: (...args: any[]) => any;
  options: any;
  defaultOptions?: any;
  fullWidth?: boolean;
  testId?: string;
  isClearable?: boolean;
  label?: string | React.ReactNode;
  placeholder?: string;
  loading?: boolean;
  required?: boolean;
  readOnly?: boolean;
  selectedValues: string[];
  helper?: any;
  [x: string]: any;
};

export default function MultiSelect({
  id,
  handleChange,
  fullWidth = false,
  options,
  defaultOptions = [],
  isClearable = false,
  label = 'Add options',
  loading = false,
  required = false,
  selectedValues = [],
  testId = '',
  readOnly = false,
  placeholder = 'Search...',
  helper = null,
  ...restOfProps
}: MultiSelectProps) {
  const inputId = useMemo(() => id || `multiSelectIdSelect${createRandomString(5)}`, [id]);

  if (loading) return <InputLoader />;

  return (
    <FormGroup fullWidth={fullWidth} data-testid={testId}>
      <Label id={inputId} label={label} required={required} helper={helper} />
      <div className="relative">
        {readOnly ? (
          <Input readOnly id={inputId} value={(selectedValues && selectedValues.join(', ')) || ''} />
        ) : (
          <Select
            isClearable={isClearable}
            isMulti
            inputId={inputId}
            classNamePrefix="equips"
            placeholder={placeholder}
            value={
              (selectedValues &&
                selectedValues.map((value) => {
                  const optionsToUse = defaultOptions.length ? defaultOptions : options;
                  const label = optionsToUse?.find((option) => option.value === value)?.label;

                  return { label: label || value, value };
                })) ||
              []
            }
            options={options}
            onChange={(selectedValues) => handleChange(selectedValues ? selectedValues.map(({ value }) => value) : null, selectedValues)}
            menuPortalTarget={document.body}
            styles={defaultSelectStyles}
            {...restOfProps}
          />
        )}
        {required && <ReactSelectRequiredInput isValid={selectedValues.length > 0} />}
      </div>
    </FormGroup>
  );
}
