import LogRocket from 'logrocket';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAppStatus } from '../providers/AppStatusProvider';
import { AlertTypes, useAlert } from './Alerts/AlertContext';

const RETRY_LIMIT = 3;

export function ReactQueryProvider(props: { children: React.ReactNode }) {
  const showAlert = useAlert();
  const { setApiFailure } = useAppStatus();

  const queryClient = new QueryClient({
    // See https://react-query.tanstack.com/guides/important-defaults
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount) => {
          if (failureCount >= RETRY_LIMIT) {
            setApiFailure(true);
            return false;
          }
          return true;
        },
        onSuccess: () => setApiFailure(false),
        onError: (error) => {
          showAlert({ content: 'Error loading content.', type: AlertTypes.error });
          if (error instanceof Error) LogRocket.captureException(error);
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools
        toggleButtonProps={{ style: { bottom: 20, zIndex: 10 } }}
        closeButtonProps={{ style: { zIndex: 30 } }}
        panelProps={{ style: { zIndex: 30 } }}
      />
      {props.children}
    </QueryClientProvider>
  );
}
