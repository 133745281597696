import React, { useEffect, useMemo, useRef, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Folder from '@mui/icons-material/Folder';
import FolderOpen from '@mui/icons-material/FolderOpen';
import Search from '@mui/icons-material/Search';
import OpenInBrowser from '@mui/icons-material/OpenInBrowser';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import { useQuery } from '@tanstack/react-query';
import { ClientOrganizationEnumType, Equipment, Location, OrganizationFeatureFlagEnum } from '@equips/entities-schema';
import Skeleton from '@mui/material/Skeleton';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocations } from '../../hooks/useLocations';
import { useAuth } from '../../auth/AuthContext';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Buttons/Button';
import Urls from '../../../routes/Urls';
import Checkbox from '../Form/Checkbox';
import { internalUsers } from '../../auth/roles';
import { isOmniFamilyHealthOrganizationId } from '../../functions/womHelpers';
import { getEquipmentForLocation } from './SubmitRequestQueries';
import RecursiveLocationList from './SubmitRequestRecursiveLocationList';
import EquipmentLocationCard from './EquipmentLocationCard';

type SubmitRequestSelectEquipmentProps = {
  location?: Location | undefined;
  organizationId: string;
  addEquipment?: () => void;
  addLocation?: () => void;
  setAddEquipmentLocationId?: React.Dispatch<React.SetStateAction<string>>;
  onUseGeolocation?: () => void;
  onChange: (args: { location?: Location; equipment: Equipment[] }) => any;
};

export type Categories = { [category: string]: Equipment[] };

export function filterMatches(eq: Equipment, filter: string) {
  const searchable = [
    eq?.metadata?.equipmentName,
    eq?.metadata?.serialNumber,
    eq?.specMetadata?.manufacturer,
    eq?.specMetadata?.model,
    eq?.metadata?.tid,
  ].join(' ');
  return searchable.match(new RegExp(filter, 'gi'));
}

function Loader(props: { n: number }) {
  return (
    <div className="mt-5 px-5">
      {Array(props.n)
        .fill(1)
        .map((x, i) => (
          <div className="mb-5" key={i}>
            <Skeleton />
          </div>
        ))}
    </div>
  );
}

export function FilterInput(props: { filter: string; setFilter: React.Dispatch<React.SetStateAction<string | null>> }) {
  return (
    <TextField
      fullWidth
      value={props.filter}
      onChange={(e) => props.setFilter(e.target.value)}
      placeholder="Filter"
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment className="text-gray-700" position="start">
            <IconButton size="small" onClick={() => props.setFilter(null)}>
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

function Category({ category, nested, expand, setSelectedCategory, selected, children }) {
  return (
    <>
      <ListItem className={nested ? 'pl-10' : ''} button onClick={() => setSelectedCategory(selected ? '' : category)}>
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText primary={category} />
        {expand ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {expand && <Divider />}
      <Collapse in={expand} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
      {expand && <Divider />}
    </>
  );
}

function EquipmentList({ category, equipment, selectedEquipment, setSelectedEquipment, equipmentMode = false }) {
  const { t } = useTranslation();
  const { userCan, determineFeatureFlagVisibility } = useAuth();

  const hasBulkSubmissionModuleEnabled = determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalBulkSubmission);

  return (
    <List disablePadding>
      {equipment.map((eq) => {
        const name = eq.metadata?.equipmentName;
        const selected = selectedEquipment?.some((x) => x.equipmentId === eq.equipmentId);
        const serviceEndDate = eq.metadata?.serviceEndDate;
        const isPassedServiceEndDate = typeof serviceEndDate === 'number' && dayjs().isAfter(serviceEndDate);
        const serviceEndDateMessage = `${t('serviceEnded')} ${dayjs(serviceEndDate).format('MM/DD/YYYY')}`;

        const description = [
          eq.metadata?.shortId,
          eq?.specMetadata?.manufacturer,
          eq?.specMetadata?.model,
          eq.metadata?.serialNumber,
          eq.metadata?.tid,
        ]
          .filter((x) => !!x)
          .join(' / ');
        return (
          <ListItem
            className={equipmentMode || (!hasBulkSubmissionModuleEnabled && !userCan(internalUsers)) ? '' : 'pl-20'}
            key={eq.equipmentId}
            selected={selected}
            button
            onClick={() => setSelectedEquipment([eq])}
          >
            <ListItemIcon>
              {hasBulkSubmissionModuleEnabled && (
                <Checkbox
                  id="multiselect"
                  checked={selected}
                  onChange={(event) => {
                    if (!event?.currentTarget?.checked) {
                      setSelectedEquipment(selectedEquipment?.filter((value) => value !== eq));
                    } else {
                      setSelectedEquipment([...(selectedEquipment || []), eq]);
                    }
                  }}
                />
              )}
            </ListItemIcon>
            <div className="w-full flex-col">
              {equipmentMode ? (
                <ListItemText
                  primary={name}
                  secondary={
                    <>
                      <FolderOpen className="mb-1 mr-1 text-sm" />
                      {category} / {description}
                    </>
                  }
                />
              ) : (
                <ListItemText primary={name} secondary={description} />
              )}
              {userCan([...internalUsers]) && isPassedServiceEndDate && (
                <span className="text-xs font-light text-red-700">{serviceEndDateMessage}</span>
              )}
            </div>
            <ListItemSecondaryAction>
              <Tooltip title={t('openInNewTab')}>
                <a target="_blank" href={`${Urls.EQUIPMENT}/${eq.equipmentId}`} rel="noreferrer">
                  <OpenInBrowser />
                </a>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

export function LocationCategories(props: {
  categories: Categories;
  loading: boolean;
  nested?: boolean;
  equipmentFilter: string | null;
  equipmentCount: number;
  selectedCategory: string | undefined;
  selectedEquipment: Equipment[] | undefined;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedEquipment: React.Dispatch<React.SetStateAction<Equipment[] | undefined>>;
}) {
  const {
    categories,
    loading,
    nested,
    selectedCategory,
    selectedEquipment,
    equipmentCount = 0,
    equipmentFilter,
    setSelectedCategory,
    setSelectedEquipment,
  } = props;

  const { clientOrganizationType } = useAuth();
  const windowSize = useWindowSize();

  const isMobile = !!(windowSize.width && windowSize.width <= 768);

  const shouldShowSubmitSrHelperText =
    clientOrganizationType !== ClientOrganizationEnumType.EquipsCoverage &&
    clientOrganizationType !== ClientOrganizationEnumType.EquipsBasic;

  const [equipmentMode, setEquipmentMode] = useState(equipmentCount <= 5);

  useEffect(() => {
    setEquipmentMode(equipmentCount <= 5);
  }, [equipmentCount]);

  if (loading) return <Loader n={nested ? 3 : 9} />;

  if (!equipmentCount && !isMobile) {
    return (
      <div className="MuiTypography-colorTextSecondary my-auto flex items-center p-4 text-center md:h-96">
        <div className="space-y-4 md:space-y-20">
          <h3 className="md:px-14 md:text-2xl">Looks like there isn't any equipment at this location.</h3>
          <ol className="text-sm leading-loose md:text-base">
            <li className="inline-block align-middle">
              1. Add a piece of equipment by clicking <Add />{' '}
            </li>
            <div>OR</div>
            <li className="inline-block align-middle">
              2. Expand sublocation by clicking <AddBoxOutlined />
            </li>
            {shouldShowSubmitSrHelperText && (
              <>
                <div>OR</div>
                <li className="inline-block align-middle">3. Submit a SR for this location by clicking "Continue"</li>
              </>
            )}
          </ol>
        </div>
      </div>
    );
  }

  return (
    <>
      {Object.entries(categories)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([category, equipment]) => {
          const selected = category === selectedCategory;
          const expand = Boolean(equipmentFilter || category === selectedCategory);
          return (
            <div key={category}>
              {equipmentMode ? (
                <EquipmentList
                  category={category}
                  equipment={equipment}
                  selectedEquipment={selectedEquipment}
                  setSelectedEquipment={setSelectedEquipment}
                  equipmentMode={equipmentMode}
                />
              ) : (
                <Category category={category} nested={nested} expand={expand} setSelectedCategory={setSelectedCategory} selected={selected}>
                  <EquipmentList
                    category={category}
                    equipment={equipment}
                    selectedEquipment={selectedEquipment}
                    setSelectedEquipment={setSelectedEquipment}
                    equipmentMode={equipmentMode}
                  />
                </Category>
              )}
            </div>
          );
        })}
    </>
  );
}

export const SubmitRequestSelectEquipmentQueryKey = 'SubmitRequestSelectEquipment';

export function SubmitRequestSelectEquipment(props: SubmitRequestSelectEquipmentProps) {
  const { t } = useTranslation();
  const { auth, userCan, clientOrganizationType, determineFeatureFlagVisibility } = useAuth();
  const { loading: loadingLocations, locations, defaultLocation } = useLocations(props.organizationId, { excludeSublocations: true });
  const isInternalUser = userCan([...internalUsers]);

  const [locationFilter, setLocationFilter] = useState<string | null>(null);
  const [equipmentFilter, setEquipmentFilter] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(props.location ?? defaultLocation);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment[]>([]);
  const windowSize = useWindowSize();
  const isMobile = !!(windowSize.width && windowSize.width <= 768);

  const selectedLocationRef = useRef<HTMLDivElement>(null);

  const submit = () => {
    if (!selectedLocation?.locationId) return;
    props.onChange({ location: selectedLocation, equipment: selectedEquipment });
  };

  const { data: equipmentData, isLoading: loadingEquipment } = useQuery(
    [SubmitRequestSelectEquipmentQueryKey, props.organizationId, selectedLocation],
    () => getEquipmentForLocation(selectedLocation?.locationId ?? '', { isInternalUser, organizationId: props.organizationId }),
  );

  useEffect(() => {
    if (defaultLocation && !selectedLocation) {
      setSelectedLocation(defaultLocation);
    }

    if (selectedLocationRef.current) {
      selectedLocationRef.current.style.scrollMarginTop = '75px';
      selectedLocationRef.current?.scrollIntoView();
    }
  }, [defaultLocation, selectedLocation]);

  useEffect(() => {
    if (!selectedLocation) {
      const currentEquipment = selectedEquipment[selectedEquipment.length - 1];
      const location = locations.find((l) => l?.locationId === currentEquipment?.metadata?.locationId) ?? undefined;
      setSelectedLocation(location);
    }
  }, [selectedEquipment]);

  useEffect(() => {
    if (props.organizationId !== auth?.organizationId) {
      setSelectedLocation(undefined);
    }
  }, [props.organizationId, auth?.organizationId]);

  const equipment = equipmentData?.data?.equipment?.data || [];
  const equipmentCount = equipment.length;

  const shouldSelectEquipment = useMemo(
    () =>
      [ClientOrganizationEnumType.EquipsBasic, ClientOrganizationEnumType.EquipsCoverage].includes(
        clientOrganizationType as ClientOrganizationEnumType,
      ) ||
      (clientOrganizationType === ClientOrganizationEnumType.EquipsWorkOrderManagement &&
        isOmniFamilyHealthOrganizationId(auth?.organizationId)),
    [clientOrganizationType, auth],
  );

  const categories: Categories = {};
  for (const eq of equipment) {
    const category = eq?.specMetadata?.category?.metadata?.name;
    if (!eq || !category) {
      continue;
    } else if (equipmentFilter && !filterMatches(eq, equipmentFilter)) {
      continue;
    } else if (categories[category]) {
      categories[category].push(eq);
    } else {
      categories[category] = [eq];
    }
  }

  return (
    <div>
      <div className="flex h-full flex-col border-b sm:flex-row sm:items-stretch" style={{ maxHeight: isMobile ? undefined : '50vh' }}>
        <div className="w-full overflow-y-auto">
          <List>
            <ListSubheader className="bg-white">
              <div className="flex items-center justify-between">
                <h3 className="text-base">{t('location')}</h3>
                <div>
                  {props.addLocation && (
                    <IconButton onClick={props.addLocation} title="Add new location" size="large">
                      <Add />
                    </IconButton>
                  )}
                  <IconButton onClick={() => setLocationFilter(locationFilter ?? '')} title="Filter locations" size="large">
                    <Search />
                  </IconButton>
                </div>
              </div>
              {locationFilter !== null && <FilterInput filter={locationFilter} setFilter={setLocationFilter} />}
            </ListSubheader>
            {loadingLocations ? (
              <Loader n={9} />
            ) : (
              <RecursiveLocationList
                {...{
                  locations,
                  isMobile,
                  categories,
                  selectedLocation,
                  equipmentCount,
                  equipmentFilter,
                  locationFilter,
                  selectedCategory,
                  selectedEquipment,
                  setSelectedLocation,
                  setSelectedCategory,
                  setSelectedEquipment,
                  setEquipmentFilter,
                  loadingEquipment,
                  selectedLocationRef,
                }}
              />
            )}
          </List>
        </div>
        {!isMobile && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility) && (
          <div className="w-full overflow-y-auto border-x border-gray-400">
            <List>
              <ListSubheader className="bg-white">
                <div className="flex items-center justify-between">
                  <h3 className="text-base">{t('equipment')}</h3>
                  <div>
                    {props.addEquipment && (
                      <IconButton
                        onClick={() => {
                          props.addEquipment?.();
                          props.setAddEquipmentLocationId?.(selectedLocation?.locationId || '');
                        }}
                        title="Add new equipment"
                        size="large"
                      >
                        <Add />
                      </IconButton>
                    )}
                    <IconButton onClick={() => setEquipmentFilter(equipmentFilter ?? '')} title={t('filter')} size="large">
                      <Search />
                    </IconButton>
                  </div>
                </div>
                {equipmentFilter !== null && <FilterInput filter={equipmentFilter} setFilter={setEquipmentFilter} />}
              </ListSubheader>
              {!isMobile && (
                <LocationCategories
                  categories={categories}
                  loading={loadingEquipment}
                  equipmentCount={equipmentCount}
                  equipmentFilter={equipmentFilter}
                  selectedCategory={selectedCategory}
                  selectedEquipment={selectedEquipment}
                  setSelectedCategory={setSelectedCategory}
                  setSelectedEquipment={setSelectedEquipment}
                ></LocationCategories>
              )}
            </List>
          </div>
        )}
      </div>

      <div className="sticky bottom-0 z-50 w-full border-t bg-white p-5 md:relative md:border-0">
        {auth?.organization?.metadata?.allowSRGeolocation && props.onUseGeolocation && (
          <Button tiny blue className={`mb-4 block ${isMobile ? 'mx-auto' : ''}`} onClick={props.onUseGeolocation}>
            {t('useDeviceLocation')}
          </Button>
        )}
        <EquipmentLocationCard equipment={selectedEquipment} location={selectedLocation} setEquipment={setSelectedEquipment} />
        <div className="mt-4 flex flex-col items-center justify-center">
          <Button type="button" onClick={submit} disabled={!selectedLocation || (shouldSelectEquipment && !selectedEquipment.length)}>
            {t('continue')}
          </Button>
          {shouldSelectEquipment && !selectedEquipment.length && (
            <div className="mt-4 text-xs text-red-700">
              {t('equipment')} {t('required')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
