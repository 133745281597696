import React, { useState } from 'react';
import { Invoice, InvoiceCoverageStatus, InvoiceDisplayStatus } from '@equips/entities-schema';
import BeatLoader from 'react-spinners/BeatLoader';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { TableColumn } from '../../common/components/DataTable';
import { centsToMoney } from '../../common/functions/moneyFunctions';
import { getQuickbooksAttachmentAsPdf } from '../../graphql/queries/paymentsQueries';
import { downloadABlob } from '../../common/functions/downloads';
import { ContentTypes } from '../../common/constants/contentTypes';
import { useAlert, AlertTypes } from '../../common/components/Alerts/AlertContext';
import { AuthContextValue } from '../../common/auth/AuthContext';
import { internalUsers } from '../../common/auth/roles';
import Urls from '../../routes/Urls';
import { toDateStringFromUnixMillisecondTimestamp, toFormattedStringDirtyString } from '../../common/functions/dateFunctions';
import { chargeStatuses } from '../../graphql/enums';
import TableCellExpand from '../../common/components/DataTable/TableCellExpand';
import Label from '../../common/components/Form/Label';

export type QuickbooksInvoiceType = {
  DocNumber: string;
  Id: string;
  CustomerMemo: string;
  TxnDate: string;
  DueDate: string;
  PrintStatus: string;
  EmailStatus: string;
  Balance: number;
  TotalAmt: number;
};

const extractPdfFromResponse = (encodedPdfString) => {
  const base64EncodedBinaryPdfFile = encodedPdfString?.replace(/\s/g, '');

  if (!base64EncodedBinaryPdfFile) {
    throw new Error('There was no file returned');
  }

  return atob(base64EncodedBinaryPdfFile);
};

export const getInvoiceFromAttachmentOrQuickbooksAutogeneratedPdf = async ({ organizationId, quickbooksInvoiceId }) => {
  const { data } = await getQuickbooksAttachmentAsPdf({ organizationId, quickbooksInvoiceId });

  if (data?.quickbooksAttachmentAsPdf) {
    return data?.quickbooksAttachmentAsPdf;
  }

  const { data: quickbooksPdfData } = await getQuickbooksAttachmentAsPdf({ organizationId, quickbooksInvoiceId });

  if (quickbooksPdfData?.quickbooksInvoicePdf) {
    return quickbooksPdfData?.quickbooksInvoicePdf;
  }

  return '';
};

export const InvoiceDisplayStatusTag = ({ status, isSmall }: { status: InvoiceDisplayStatus; isSmall?: boolean }) => {
  const tagClassName =
    status === InvoiceDisplayStatus.UnderReview
      ? 'bg-gray-200'
      : status === InvoiceDisplayStatus.Paid
      ? 'bg-green-300 text-green-800 '
      : status === InvoiceDisplayStatus.PayProvider
      ? ''
      : status === InvoiceDisplayStatus.ProcessingPayment
      ? 'bg-blue-300 text-blue-800'
      : status === InvoiceDisplayStatus.Rejected
      ? 'bg-gray-800 text-gray-300'
      : status === InvoiceDisplayStatus.Overdue
      ? 'bg-red-300 text-red-800'
      : 'bg-yellow-300 text-yellow-800';

  return (
    <>
      <div className={`${tagClassName} text-center font-semibold ${isSmall ? 'py-1' : 'py-2'} rounded-xl text-xs capitalize`}>
        {status === InvoiceDisplayStatus.PayProvider ? 'Please pay to Provider' : status?.split(/(?=[A-Z])/)?.join(' ')}
      </div>
    </>
  );
};

export function DownloadInvoicePdfButton({ organizationId, Id, equipsInvoiceNumber }) {
  const [loading, setLoading] = useState(false);
  const showAlert = useAlert();

  if (loading) {
    return <BeatLoader size={8} color="black" />;
  }

  return (
    <Link
      className="active font-bold"
      to="#"
      data-testid="downloadInvoiceButton"
      onClick={async (event) => {
        event.preventDefault();

        setLoading(true);
        const encodedPdfString = await getInvoiceFromAttachmentOrQuickbooksAutogeneratedPdf({ organizationId, quickbooksInvoiceId: Id });
        const binary = extractPdfFromResponse(encodedPdfString);

        if (!binary) {
          showAlert({ type: AlertTypes.error, content: 'The file is not available at this time.' });
        } else {
          const len = binary.length;
          const buffer = new ArrayBuffer(len);
          let view = new Uint8Array(buffer);

          for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
          }
          downloadABlob(view, { fileName: `invoice-${Id}`, extension: '.pdf', contentType: ContentTypes.PDF });
        }
        setLoading(false);
      }}
    >
      {equipsInvoiceNumber}
    </Link>
  );
}

export default function PaymentColumns(userCan: AuthContextValue['userCan'], organizationId: string): TableColumn<Invoice>[] {
  return [
    ...(userCan(internalUsers)
      ? [
          {
            id: 'metadata.shortId',
            Header: <Translation>{(t) => <>{t('number')}</>}</Translation>,
            accessor: (data) => data.metadata?.shortId,
            Cell: (data) => {
              const invoiceId = data?.row?.original?.metadata?.invoiceId;

              return (
                <div className="flex justify-between">
                  <Link className="active font-bold" data-testid="toInvoiceFromTable" to={`${Urls.INVOICES}/${invoiceId}`}>
                    {data.value}
                  </Link>
                </div>
              );
            },
          },
        ]
      : []),
    {
      id: 'DocNumber',
      Header: <Translation>{(t) => <>{t('equipsInvoice')}</>}</Translation>,
      accessor: (data) => data.metadata?.quickbooksInvoice?.DocNumber,
      Cell: (data) =>
        data?.value ? (
          <DownloadInvoicePdfButton
            Id={data.row.original.metadata?.quickbooksInvoice?.Id}
            organizationId={organizationId}
            equipsInvoiceNumber={data?.value}
          />
        ) : (
          'Not Available'
        ),
      disableSortBy: true,
    },
    {
      id: 'externalInvoiceNumber',
      Header: <Translation>{(t) => <>{t('providerInvoiceNo')}</>}</Translation>,
      accessor: (data) => data.metadata?.externalInvoiceNumber,
      Cell: (data) => {
        const externalInvoiceNumber = data?.row?.original?.metadata?.externalInvoiceNumber || 'None';

        const denialInvoceFileGetUrl = data?.row?.original?.metadata?.denialInvoiceFile?.metadata?.presignedGetUrl ?? '#';

        if (denialInvoceFileGetUrl === '#') return <>{externalInvoiceNumber}</>;

        return (
          <a className="active" target="_blank" rel="noopener noreferrer" href={denialInvoceFileGetUrl}>
            {externalInvoiceNumber}
          </a>
        );
      },
      disableSortBy: true,
    },
    {
      id: 'metadata.externalServiceDate',
      Header: <Translation>{(t) => <>{t('serviceDate')}</>}</Translation>,
      accessor: (data) => toDateStringFromUnixMillisecondTimestamp(data.metadata?.externalServiceDate),
    },
    {
      id: 'serviceRequestNumber',
      Header: <Translation>{(t) => <>{t('serviceRequestNo')}</>}</Translation>,
      accessor: (data) => data.metadata?.serviceRequestNumber,
      Cell: (data) => {
        const serviceRequests = data?.row?.original?.metadata?.serviceRequests;
        if (serviceRequests && serviceRequests.length) {
          return serviceRequests.map((sr, i) => (
            <>
              <Link
                key={sr?.metadata?.shortId}
                data-testid="serviceRequestViewLink"
                className="active font-bold"
                to={`${Urls.SERVICE_REQUESTS}/${sr?.metadata?.serviceRequestId}`}
              >
                {sr?.metadata?.shortId}
              </Link>
              {i === serviceRequests.length - 1 ? '' : ','}
            </>
          ));
        } else {
          return <TableCellExpand>{data?.value}</TableCellExpand>;
        }
      },
    },
    {
      id: 'title',
      Header: <Translation>{(t) => <>{t('title')}</>}</Translation>,
      accessor: (data) => data.metadata?.serviceRequests?.[0]?.metadata?.title,
      Cell: (data) => <TableCellExpand>{data.value}</TableCellExpand>,
      width: 250,
    },
    {
      id: 'equipment.metadata.shortId',
      Header: <Translation>{(t) => <>{t('equipment')}</>}</Translation>,
      accessor: (data) => data.equipment?.[0]?.metadata?.shortId,
      Cell: (data) => (
        <Link
          target="_blank"
          className="active"
          data-testid="toEquipment"
          to={`${Urls.EQUIPMENT}/${data?.row?.original?.equipment?.[0]?.equipmentId}`}
        >
          {data?.row?.original?.equipment?.[0]?.metadata?.shortId}
        </Link>
      ),
      disableSortBy: true,
    },
    {
      id: 'closingNotes',
      Header: <Translation>{(t) => <>{t('closingNotes')}</>}</Translation>,
      accessor: (data) => data?.metadata?.closingNotes,
      Cell: (data) => {
        if (data?.value) {
          return data?.value?.map((note) => <TableCellExpand key={note?.metadata?.noteId}>{note?.metadata?.message}</TableCellExpand>);
        }
        return null;
      },
    },
    {
      id: 'locationId',
      Header: <Translation>{(t) => <>{t('location')}</>}</Translation>,
      accessor: (data) => data?.equipment?.[0]?.locationMetadata?.locationDisplayName,
      disableSortBy: true,
    },
    {
      id: 'metadata.quickbooksInvoice.MetaData.CreateTime',
      Header: (
        <Label
          labelClassName="text-gray-900"
          id="billingDate"
          label={<Translation>{(t) => <>{t('billingDate')}</>}</Translation>}
          helper={<Translation>{(t) => <>{t('billingDateDescription')}</>}</Translation>}
        />
      ),
      accessor: (data) => toFormattedStringDirtyString(data.metadata?.quickbooksInvoice?.MetaData?.CreateTime || ''),
      disableSortBy: true,
    },
    {
      id: 'Amount',
      Header: <Translation>{(t) => <>{t('amount')}</>}</Translation>,
      disableSortBy: true,
      accessor: (data) => data.metadata?.quickbooksInvoice?.TotalAmt,
      Cell: (d) => {
        const charges = d.row.original?.metadata?.charges;
        const displayStatus = d.row.original?.metadata?.invoiceDisplayStatus;
        const coverageStatus = d.row.original?.metadata?.coverageStatus;

        if (displayStatus === InvoiceDisplayStatus.UnderReview) return null;
        else if (displayStatus === InvoiceDisplayStatus.PayProvider)
          return charges
            ?.filter((charge) => charge?.metadata?.chargeStatus === chargeStatuses.clientInvoiceIssuedExternally.value)
            ?.reduce((acc, current) => centsToMoney(acc + current?.metadata?.amountInCents), 0);
        else if (coverageStatus === InvoiceCoverageStatus.Covered) {
          return (
            <div className="text-equipsGreen">
              We've Got You <br />
              Covered! ✅
            </div>
          );
        } else {
          return centsToMoney((d.value || 0) * 100);
        }
      },
    },
    {
      id: 'DueDate',
      Header: <Translation>{(t) => <>{t('dueDate')}</>}</Translation>,
      accessor: (data) => toFormattedStringDirtyString(data.metadata?.quickbooksInvoice?.DueDate || ''),
      disableSortBy: true,
    },
    {
      id: 'metadata.invoiceDisplayStatus',
      Header: <Translation>{(t) => <>{t('paymentStatus')}</>}</Translation>,
      accessor: (data) => data.metadata?.invoiceDisplayStatus,
      Cell: (data) => <InvoiceDisplayStatusTag status={data?.value} />,
      disableSortBy: true,
      width: 175,
    },
    {
      id: 'metadata.coverageStatus',
      Header: <Translation>{(t) => <>{t('coverageStatus')}</>}</Translation>,
      accessor: (data) => data.metadata?.coverageStatus,
      Cell: (data) => {
        return <p className="capitalize"> {data?.value?.split(/(?=[A-Z])/)?.join(' ')}</p>;
      },
      disableSortBy: true,
    },
    {
      id: 'metadata.clientOrganizationId',
    },
  ];
}
