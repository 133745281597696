import React, { lazy, Suspense } from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Error from '@mui/icons-material/Error';
import Info from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';

const DraggableDialog = lazy(() => import('./DraggableDialog'));

export const simpleModalTypes = { danger: 1, info: 2, success: 3 };

export type SimpleModalProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  testId?: string;
  isDismissible?: boolean;
  draggable?: boolean;
  modalType?: number;
  isOpen?: boolean;
  handleClose?: (x?: any) => any;
  title?: any;
  subtitle?: string;
  children?: React.ReactNode;
};

export default function SimpleModal({
  draggable = false,
  size = 'sm',
  testId = 'modal',
  modalType = simpleModalTypes.info,
  isDismissible = true,
  isOpen = true,
  handleClose,
  title,
  subtitle = '',
  children,
}: SimpleModalProps) {
  if (!isOpen) return null;

  let backgroundColor = 'bg-red-100';
  let color = 'text-red-600';

  if (modalType === simpleModalTypes.info) {
    backgroundColor = 'bg-blue-100';
    color = 'text-blue-600';
  }

  if (modalType === simpleModalTypes.success) {
    backgroundColor = 'bg-green-100';
    color = 'text-equipsGreen';
  }

  const DialogComponent = draggable ? DraggableDialog : Dialog;
  const dialogComponentHeaderAttributes = draggable
    ? { 'data-testid': 'draggable-dialog', id: 'draggable-dialog', style: { cursor: 'move' } }
    : { 'data-testid': 'non-draggable-dialog' };

  return (
    <Suspense fallback={<div />}>
      <DialogComponent
        fullWidth
        maxWidth={size}
        data-testid={testId}
        open={isOpen}
        onClose={() => (isDismissible && handleClose ? handleClose() : null)}
      >
        <div className="rounded-lg bg-white">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <header {...dialogComponentHeaderAttributes} className={`sm:flex ${subtitle ? 'sm:items-start' : 'sm:items-center'}`}>
              {modalType && (
                <div
                  className={`mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 ${backgroundColor} ${color}`}
                >
                  {modalType === simpleModalTypes.danger && <Error />}
                  {modalType === simpleModalTypes.info && <Info />}
                  {modalType === simpleModalTypes.success && <CheckCircle />}
                </div>
              )}
              <div className={`mt-3 text-center sm:mt-0 ${modalType && 'sm:ml-4'} sm:text-left`}>
                <h3 className="text-xl font-semibold leading-6 tracking-wide text-equipsNavyBlue">{title}</h3>
                {subtitle && (
                  <div className="mt-1">
                    <p className="text-sm leading-5 text-gray-500">{subtitle}</p>
                  </div>
                )}
              </div>
            </header>

            <div>{children}</div>
          </div>
        </div>
      </DialogComponent>
    </Suspense>
  );
}
