import React, { useCallback, useMemo } from 'react';
import Input from '../Form/Input';
import { dateStringFormattedForHtmlInput } from '../../functions/dateFunctions';

const parseMMslashDDslashYYYY = (oldFormat) => {
  const [mm, dd, yyyy] = oldFormat?.split('/') ?? [];

  return `${yyyy}-${mm}-${dd}`;
};

interface DateInputProps {
  onChange?: (any) => any;
  className?: string;
  value?: string;
  loading?: boolean;
  tiny?: boolean;
  isWhite?: boolean;
  helper?: any;
  id: string;
  required?: boolean;
  type?: 'date' | 'datetime-local';
  dateValidation?: 'future' | 'past' | 'none';
  [x: string]: any;
}

export default function DateInput({
  className = '',
  loading = false,
  value = '',
  onChange = () => null,
  tiny = false,
  helper = null,
  isWhite = false,
  required = false,
  id = '',
  type = 'date',
  dateValidation = 'none',
  ...restOfProps
}: DateInputProps) {
  const minMaxDates = useMemo(() => {
    if (dateValidation === 'future') {
      return {
        min: dateStringFormattedForHtmlInput(Date.now()),
        max: dateStringFormattedForHtmlInput(new Date(new Date().setFullYear(new Date().getFullYear() + 2))),
      };
    }
    if (dateValidation === 'past') {
      return {
        max: dateStringFormattedForHtmlInput(Date.now()),
      };
    }

    return {};
  }, [dateValidation]);

  const parseValue = useCallback(
    (value) => {
      const formatted = value?.includes('/') ? parseMMslashDDslashYYYY(value) : value;

      const { min, max } = minMaxDates;
      if (min && new Date(formatted) < new Date(min)) {
        return min;
      }
      if (max && new Date(formatted) > new Date(max)) {
        return max;
      }

      return formatted;
    },
    [minMaxDates],
  );

  const parsedValue = useMemo(() => parseValue(value), [value, parseValue]);

  // onChange will fire even when a date is outside of the min/max.
  // This handler clamps the value before passing on the event.
  const onChangeHandler = (event) => {
    event.target.value = parseValue(event.target.value);
    onChange?.(event);
  };

  return (
    <>
      <Input
        id={id}
        helper={helper}
        className={className}
        loading={loading}
        tiny={tiny}
        isWhite={isWhite}
        placeholder={restOfProps?.placeholder ? restOfProps.placeholder : 'mm/dd/yyyy'}
        value={parsedValue}
        type={type}
        onChange={onChangeHandler}
        required={required}
        data-testid={restOfProps['data-testid'] || restOfProps['id']}
        {...minMaxDates}
        {...restOfProps}
      />
    </>
  );
}
