import React from 'react';
import Star from '@mui/icons-material/Star';
import { Tooltip } from '@mui/material';
import { OrganizationTier } from '@equips/entities-schema';
import { useAuth } from '../../../common/auth/AuthContext';

type TierIndicatorProps = {
  tier?: OrganizationTier | null;
};

const tierSettings = {
  [OrganizationTier.Gold]: {
    color: 'text-yellow-600',
    Icon: Star,
  },
  [OrganizationTier.Silver]: {
    color: 'text-gray-500',
    Icon: Star,
  },
};

export default function TierIndicator(props: TierIndicatorProps) {
  const { userCan, internalUsers } = useAuth();

  if (!userCan(internalUsers) || !props.tier) {
    return <></>;
  }

  const { color, Icon } = tierSettings[props.tier];

  return (
    <Tooltip title={`${props.tier} tier account`}>
      <span className={`${color}`}>
        <Icon fontSize="inherit" />
      </span>
    </Tooltip>
  );
}
