import { OrganizationFeatureFlagEnum, UserAuthorizationRoleEnum } from '@equips/entities-schema';
import { AuthorizationData } from '../../../common/auth/AuthContext';

// Helper that determines if a specific user has access to do invoice related task in Equips
// Duplicated in webapp and API with slightly different implementation
export function invoicePreviewEnabled(user?: AuthorizationData | null): boolean {
  const organizationId = user?.organizationId;
  const authorizationRole = user?.authorizationRole;
  const featureFlags = user?.organization?.metadata?.featureFlags;

  const hasInvoiceVisibilityFeatureFlag = !!featureFlags?.includes(OrganizationFeatureFlagEnum.InvoiceVisibility);

  if (!authorizationRole || !organizationId) return false;

  if (authorizationRole === UserAuthorizationRoleEnum.CustomerOrganizationAdmin && hasInvoiceVisibilityFeatureFlag) {
    return true;
  }

  return false;
}
