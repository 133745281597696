import React, { useState } from 'react';
import { safelyParseJson } from '@equips/common-resources';
import Close from '@mui/icons-material/Close';
import Person from '@mui/icons-material/Person';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Label from '../Form/Label';
import Input from '../Form/Input';
import UserSelectDialog from '../Selects/UserSelectDialog';
import validateEmail from '../../functions/validateEmail';
import PhoneNumber from '../Form/PhoneNumber';
import IconButton from '../Buttons/IconButton';

type ContactType = {
  fullName: string;
  phone: string;
  email: string;
};

const emptyContact: ContactType = { fullName: '', email: '', phone: '' };

interface ContactEditorProps {
  value: ContactType;
  onChange: (value: ContactType) => any;
  organizationId: string;
  locationId?: string;
  disabled?: boolean;
}

export function ContactEditor({ value, onChange, organizationId, locationId, disabled = false }: ContactEditorProps) {
  const [userSelectShowing, setUserSelectShowing] = useState(false);

  const emailValid = value.email ? validateEmail(value.email) : true;

  const handleUserSelect = (user) => {
    if (user?.metadata?.fullName == 'Unassigned') {
      onChange(emptyContact);
    } else {
      onChange({
        fullName: user?.metadata?.fullName,
        email: user?.metadata?.email,
        phone: user?.metadata?.phoneNumberWithCountryCode,
      });
    }
  };

  return (
    <div>
      <UserSelectDialog
        organizationId={organizationId}
        locationId={locationId}
        handleSelect={({ user }) => handleUserSelect(user)}
        open={userSelectShowing}
        handleClose={() => setUserSelectShowing(false)}
      />
      <div className="p-1">
        <div className="flex justify-between">
          <Label id="contactFullName" label="Full Name" />
          {!disabled && (
            <IconButton text="Choose a user" type="button" Icon={Person} onClick={() => setUserSelectShowing(true)} size="large" />
          )}
        </div>
        <Input
          id="fullName"
          name="fullName"
          data-testid="fullName"
          value={value.fullName}
          placeholder="Full Name"
          onChange={({ target }) => onChange({ ...value, fullName: target.value })}
          disabled={disabled}
        />
      </div>
      <div className="p-1">
        <Label id="email" label="Email" />
        <Input
          type="email"
          id="email"
          name="email"
          data-testid="email"
          value={value.email}
          placeholder="Email"
          onChange={({ target }) => onChange({ ...value, email: target.value })}
        />
        {!emailValid && (
          <div data-testid="emailValid" className="w-1/2 text-xs text-red-500">
            Please enter a valid email address.
          </div>
        )}
      </div>
      <div className="p-1">
        <PhoneNumber fullWidth label="Phone" inputId="phone" value={value.phone} onChange={(phone) => onChange({ ...value, phone })} />
      </div>
    </div>
  );
}

interface AdditionalContactsContainerProps {
  organizationId: string;
  locationId?: string;
  contacts?: string;
  setContactString(contactsString: string): void;
  disablePrimaryEdit?: boolean;
  disabled?: boolean;
}

export default function AdditionalContactsContainer(props: AdditionalContactsContainerProps) {
  const [contacts, setContacts] = useState<ContactType[]>((safelyParseJson(props.contacts) as ContactType[]) || []);

  // First contact should be disabled if disablePrimaryEdit is enabled, unless it's empty.
  const primaryDisabled = !!contacts?.[0] && props.disablePrimaryEdit;

  const handleUpdate = (value, index) => {
    const updated = contacts.map((c, i) => (i === index ? value : c));
    setContacts(updated);
    props.setContactString(JSON.stringify(updated));
  };

  const handleRemove = async (index) => {
    const updated = contacts.filter((_c, i) => i !== index);
    setContacts(updated);
    props.setContactString(JSON.stringify(updated));
  };

  return (
    <div className="text-xs">
      <div>
        {!contacts.length && <div>None</div>}
        {contacts.map((contact, index) => (
          <div className={`flex justify-between p-2 ${index % 2 === 0 ? 'bg-gray-200' : ''}`} key={index}>
            <div className="w-full">
              <ContactEditor
                key={index}
                value={contact}
                onChange={(value) => handleUpdate(value, index)}
                organizationId={props.organizationId}
                locationId={props.locationId}
                disabled={index === 0 && primaryDisabled}
              />
            </div>
            <div className="pl-2">
              {(index !== 0 || !primaryDisabled) && (
                <IconButton
                  text="Remove Contact"
                  type="button"
                  Icon={Close}
                  onClick={() => handleRemove(index)}
                  disabled={props.disabled}
                  size="large"
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <IconButton
          type="button"
          text="Add Contact"
          Icon={PersonAdd}
          onClick={() => setContacts((contacts) => [...contacts, emptyContact])}
          size="large"
        />
      </div>
    </div>
  );
}
