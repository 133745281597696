import { Maybe, User } from '@equips/entities-schema';
import config from '../../config';
import { waitUntil } from './waitUntil';

/**
 * Returns a flat object of string values with dotted keys for nested objects.
 * For Heap: "Keys and values must be a number or string, with the value being 255 characters or fewer."
 * Adapted from https://gist.github.com/penguinboy/762197#gistcomment-3448642
 **/
export function flattenObject<T extends Record<string, any>>(object: T, path: string | null = null, separator = '.'): T {
  return Object.keys(object).reduce((acc: T, key: string): T => {
    const value = object[key];
    // Skip metadata keys for compactness
    const newPath = [path, key].filter((x) => x && x !== 'metadata').join(separator);
    const isObject = [
      typeof value === 'object',
      value !== null,
      !(value instanceof Date),
      !(value instanceof RegExp),
      !Array.isArray(value),
    ].every(Boolean);

    return isObject ? { ...acc, ...flattenObject(value, newPath, separator) } : { ...acc, [newPath]: String(value).slice(0, 255) };
  }, {} as T);
}

const configureHeap = () => {
  waitUntil(
    () => {
      if (typeof window?.loadHeap === 'function') {
        window.loadHeap(config.heap.apiKey);

        return true;
      }
    },
    { timeout: 10000, interval: 1000 },
  );
};
export default configureHeap;

export const addUserDataToHeap = (userId: Maybe<string>, user: Maybe<User>) => {
  try {
    if (!userId) return;
    waitUntil(
      () => {
        if (typeof window?.heap?.identify === 'function') {
          window.heap.identify(userId);

          if (user?.metadata) {
            const flatStringMetadataMap = flattenObject(user.metadata);
            window.heap.addUserProperties(flatStringMetadataMap);
          }

          return true;
        }
      },
      { timeout: 10000, interval: 1000 },
    );
  } catch (error) {
    console.error(error);
  }
};
