import LogRocket from 'logrocket';
import { useState } from 'react';
import { useAlert, AlertTypes } from '../components/Alerts/AlertContext';

type BaseMutationHookOptions<TData, TVariables> = {
  onCompleted?: (data?: TData | null) => unknown | Promise<unknown>;
  variables?: TVariables;
  onError?: (str: string) => Promise<any>;
  suppressAlerts?: boolean;
  successMessage?: string;
  undoAction?: () => Promise<any>;
  undoActionMessage?: string;
  errorMessage?: string;
  graphQLQuery?: string;
};
export type MutationFn<TData, TVariables> = (options?: TVariables) => Promise<undefined | { data?: TData | undefined; errors?: any }>;

/**
 * @deprecated The method should not be used instead use useMutation from React Query like so import { useMutation } from 'react-query';
 */
export default function useMutation<TVariables = any, TData = any>(
  mutationFunction: (args?: TVariables, options?: any) => Promise<{ data?: null | TData; errors?: any }>,
  {
    onCompleted,
    variables,
    onError,
    suppressAlerts = false,
    successMessage = 'Successfully updated.',
    undoAction,
    undoActionMessage = '',
    errorMessage = 'There was an error saving this data.',
    graphQLQuery,
  }: BaseMutationHookOptions<TData, TVariables> = {},
): [MutationFn<TData, TVariables>, { saving: boolean; data?: TData | null }] {
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState<TData | null | undefined>(null);
  const showAlert = useAlert();

  const executeFunction = async (optionalData?: TVariables): Promise<undefined | { data?: TData | undefined; errors?: any }> => {
    setSaving(true);

    try {
      const response = await mutationFunction(optionalData || variables, { query: graphQLQuery });
      // @ts-ignore Unknown reason. Please investigate me.
      const { errors, data } = response || {};
      if (errors) throw new Error((errors || []).map((err) => err?.message).join(','));
      setData(data);
      if (onCompleted) await onCompleted(data);

      if (undoAction) {
        showAlert({ type: AlertTypes.undo, action: undoAction, content: undoActionMessage, autoClose: false });
      } else if (!suppressAlerts) {
        // @ts-ignore Unknown reason. Please investigate me.
        showAlert({ content: successMessage });
      }
      setSaving(false);

      // @ts-ignore Unknown reason. Please investigate me.
      return response;
    } catch (error) {
      if (onError) onError(error?.message);

      if (!suppressAlerts) {
        LogRocket.captureException(error);
        showAlert({ type: AlertTypes.error, content: error?.message || errorMessage });
      }
      setSaving(false);
    }
  };

  return [executeFunction, { saving, data }];
}
