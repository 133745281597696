import React from 'react';
import { EntityLabelEnum, Payable, patchPayable, patchServiceRequest } from '@equips/entities-schema';
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { capitalize, upperFirst } from 'lodash';
import { JsonParam } from 'use-query-params';
import { TableColumn } from '../../common/components/DataTable';
import TableCellInlineEditable from '../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../common/components/ViewPage/DisplayDescriptionValue';
import { abbreviateName } from '../../common/functions/abbreviateName';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';
import { defaultColumnWidths } from '../../common/components/DataTable/columns';
import { toDateStringFromUnixMillisecondTimestamp } from '../../common/functions/dateFunctions';
import DisplayTime from '../../common/components/Date/DisplayTime';
import { centsToMoney } from '../../common/functions/moneyFunctions';
import Urls from '../../routes/Urls';
import { enumObjectToSelectOptions } from '../../graphql/enums';
import statesAsObject from '../../common/constants/statesAsObject';
import ClientTag from '../../common/components/Tags/ClientTag';
import { payableStatusEnum, payeeTypeEnum, paymentStatusEnum, paymentTypeEnum } from './PayableEnums';

export function getPayableColumns(): TableColumn<Payable>[] {
  return [
    {
      id: 'metadata.payableId',
      Header: 'Payable Id',
      accessor: (data) => data?.payableId,
      Cell: (data) => (
        <Link to={`${Urls.AAS_PAYABLES}/${data.row?.original?.payableId}`} target="_blank">
          {data.row?.original?.payableId}
        </Link>
      ),
    },
    {
      id: 'metadata.auditedBy',
      Header: 'Audited by',
      accessor: (data) => data?.metadata?.auditedByUser?.metadata?.fullName,
      toText: (data) => data?.value ?? 'Unassigned',
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            inputType={descriptionListInputTypes.inlineUserSelect}
            formatter={(_value) => {
              const fullName = data.row?.original?.metadata?.auditedByUser?.metadata?.fullName;
              const url = data.row?.original?.metadata?.auditedByUser?.metadata?.profilePictureUrl;
              if (fullName) {
                return (
                  <div className="flex items-center gap-2">
                    <Avatar src={url ?? ''} alt={fullName ?? 'Unknown User'} className="h-8 w-8 bg-blue-700">
                      {abbreviateName(fullName)}
                    </Avatar>
                    {fullName}
                  </div>
                );
              } else {
                return <p>Unassigned</p>;
              }
            }}
            onSave={async (_value) => {
              data.refetchData();
            }}
            entityLabel={EntityLabelEnum.Payable}
            entityId={data?.row?.original?.payableId}
            fieldToPatch="auditedBy"
          />
        );
      },
      filterOptions: {
        type: TableFilterTypes.userSelect,
        showOnMobile: true,
      },
    },
    {
      id: 'metadata.auditedAt',
      Header: 'Audited',
      accessor: (data) => data?.metadata?.auditedAt,
      width: defaultColumnWidths.smallText,
      toText: (data) => {
        const time = toDateStringFromUnixMillisecondTimestamp(data?.value, {
          customFormatString: 'M/D/YY h:mma',
        });
        return time;
      },
      Cell: (data) => {
        return <DisplayTime timestamp={data?.value} />;
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.authorizedByUserId',
      Header: 'Authorized By',
      accessor: (data) => data?.metadata?.authorizedByUser?.metadata?.fullName,
      Cell: (data) => data?.value ?? 'N/A',
      filterOptions: {
        type: TableFilterTypes.userSelect,
        showOnMobile: true,
      },
    },
    {
      id: 'metadata.authorizationDate',
      Header: 'Authorized At',
      accessor: (data) => data?.metadata?.authorizationDate,
      width: defaultColumnWidths.smallText,
      toText: (data) => {
        const time = toDateStringFromUnixMillisecondTimestamp(data?.value, {
          customFormatString: 'M/D/YY h:mma',
        });
        return time;
      },
      Cell: (data) => {
        return <DisplayTime timestamp={data?.value} />;
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.authorizationNumber',
      Header: 'Authorization Number',
      accessor: (data) => data?.metadata?.authorizationNumber,
    },
    {
      id: 'metadata.invoice.aasInvoicedAmount',
      Header: 'Invoiced Amount',
      accessor: (data) => data?.metadata?.payableInvoice?.metadata?.aasInvoicedAmount,
      Cell: (data) => <>{centsToMoney(data?.value || 0)}</>,
    },
    {
      id: 'metadata.paidAmount',
      Header: 'Claimed Amount',
      accessor: (data) => data?.metadata?.paidAmount,
      toText: (data) => centsToMoney(data.value || 0),
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          inputType={descriptionListInputTypes.currency}
          formatter={(value) => centsToMoney(value)}
          onSave={(value) =>
            patchPayable({
              payableId: data.row?.original?.payableId,
              metadata: { paidAmount: value },
            })
          }
        />
      ),
    },
    {
      id: 'metadata.paidAt',
      Header: 'Paid Date',
      accessor: (data) => data?.metadata?.paidAt,
      toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value),
      Cell: (data) => {
        return <>{toDateStringFromUnixMillisecondTimestamp(data?.value)}</>;
      },
    },
    {
      id: 'metadata.payableInvoiceId',
      Header: 'Invoice',
      accessor: (data) => data?.metadata?.payableInvoice?.metadata?.shortId,
      disableSortBy: true,
      toText: (data) => data?.value,
      Cell: (data) => {
        return (
          <div className="flex justify-between">
            <Link to={`${Urls.AAS_INVOICES}/${data?.row?.original?.metadata?.payableInvoiceId}`}>{data.value}</Link>
          </div>
        );
      },
      filterOptions: {
        label: 'Invoice',
        type: TableFilterTypes.hidden,
      },
    },
    {
      id: 'invoiceMetadata.fileId',
      Header: 'Invoice File',
      accessor: (data) => data?.metadata?.payableInvoice?.metadata?.fileId,
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Has invoice file', value: 'true' },
          { label: 'No invoice file', value: 'false' },
        ],
      },
      width: defaultColumnWidths.normalText,
      Cell: (data) => <>{data.value ? 'Yes' : 'No'}</>,
    },
    {
      id: 'metadata.payableServiceRequestId',
      Header: 'Claim',
      accessor: (data) => data?.metadata?.payableServiceRequest?.metadata?.shortId,
      disableSortBy: true,
      toText: (data) => data?.value,
      Cell: (data) => {
        return (
          <div className="flex justify-between">
            <Link to={`${Urls.AAS_CLAIMS}/${data?.row?.original?.metadata?.payableServiceRequestId}`}>{data.value}</Link>
          </div>
        );
      },
    },
    {
      id: 'metadata.payableStatus',
      Header: 'Payable Status',
      accessor: (data) => data?.metadata?.payableStatus,
      toText: (data) => capitalize(data.value),
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          formatter={(value) => capitalize(value)}
          inputType={descriptionListInputTypes.select}
          options={enumObjectToSelectOptions(payableStatusEnum)}
          onSave={(value) => patchPayable({ payableId: data.row?.original?.payableId, metadata: { payableStatus: value } })}
        />
      ),
      filterOptions: {
        label: 'Payable Status',
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(payableStatusEnum, { anyText: 'Any', allowNull: true }),
      },
    },
    {
      id: 'metadata.paybleAASContractId',
      Header: 'Contract',
      accessor: (data) => data?.metadata?.payableAASContract?.metadata?.homeContractNo,
      disableSortBy: true,
      toText: (data) => data?.value,
      Cell: (data) => {
        return (
          <div className="flex justify-between">
            <Link to={`${Urls.AAS_CONTRACTS}/${data?.row?.original?.metadata?.payableAASContract?.metadata?.location?.locationId}`}>
              {data.value}
            </Link>
          </div>
        );
      },
    },
    {
      id: 'metadata.payeeType',
      Header: 'Payee Type',
      accessor: (data) => data?.metadata?.payeeType,
      toText: (data) => upperFirst(data.value),
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          formatter={(value) => upperFirst(value)}
          inputType={descriptionListInputTypes.select}
          options={enumObjectToSelectOptions(payeeTypeEnum)}
          onSave={(value) => patchPayable({ payableId: data.row?.original?.payableId, metadata: { payeeType: value } })}
        />
      ),
      filterOptions: {
        label: 'Payee Type',
        type: TableFilterTypes.multiSelect,
        options: enumObjectToSelectOptions(payeeTypeEnum, { anyText: 'Any', allowNull: true }),
      },
    },
    {
      id: 'metadata.paymentAmount',
      Header: 'Payment Amount',
      accessor: (data) => data?.metadata?.paymentAmount,
      toText: (data) => centsToMoney(data.value || 0),
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          inputType={descriptionListInputTypes.currency}
          formatter={(value) => centsToMoney(value)}
          onSave={(value) =>
            patchPayable({
              payableId: data.row?.original?.payableId,
              metadata: { paymentAmount: value },
            })
          }
        />
      ),
    },
    {
      id: 'metadata.paymentAt',
      Header: 'Payment Date',
      accessor: (data) => data?.metadata?.paymentAt,
      toText: (data) => {
        const time = toDateStringFromUnixMillisecondTimestamp(data.value, {
          customFormatString: 'M/D/YY h:mma',
        });
        return data.value ? time : 'Payment not processed yet';
      },
      Cell: (data) => {
        const time = toDateStringFromUnixMillisecondTimestamp(data.value, {
          customFormatString: 'M/D/YY h:mma',
        });
        return <>{data.value ? time : 'Payment not processed yet'}</>;
      },
    },
    {
      id: 'metadata.paymentStatus',
      Header: 'Payment Status',
      accessor: (data) => data?.metadata?.paymentStatus,
      toText: (data) => capitalize(data.value),
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          formatter={(value) => capitalize(value)}
          inputType={descriptionListInputTypes.select}
          options={enumObjectToSelectOptions(paymentStatusEnum)}
          onSave={(value) => patchPayable({ payableId: data.row?.original?.payableId, metadata: { paymentStatus: value } })}
        />
      ),
      filterOptions: {
        label: 'Payment Status',
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(paymentStatusEnum, { anyText: 'Any', allowNull: true }),
      },
    },
    {
      id: 'metadata.paymentType',
      Header: 'Payment Type',
      accessor: (data) => data?.metadata?.paymentType,
      toText: (data) => capitalize(data.value),
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          formatter={(value) => capitalize(value)}
          inputType={descriptionListInputTypes.select}
          options={enumObjectToSelectOptions(paymentTypeEnum)}
          onSave={(value) => patchPayable({ payableId: data.row?.original?.payableId, metadata: { paymentType: value } })}
        />
      ),
      filterOptions: {
        label: 'Payment Type',
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(paymentTypeEnum, { anyText: 'Any', allowNull: true }),
      },
    },
    {
      id: 'metadata.paymentUniqueId',
      Header: 'Payment Unique Id',
      accessor: (data) => data?.metadata?.paymentUniqueId,
      toText: (data) => data.value,
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          inputType={descriptionListInputTypes.input}
          onSave={(value) =>
            patchPayable({
              payableId: data.row?.original?.payableId,
              metadata: { paymentUniqueId: value },
            })
          }
        />
      ),
      filterOptions: {
        label: 'Payment Unique Id',
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions({}, { anyText: 'Any', allowNull: true }),
      },
    },
    {
      id: 'metadata.serviceProviderPayeeId',
      Header: 'Service Provider',
      accessor: (data) => data?.metadata?.serviceProviderPayee?.metadata?.organizationName,
      disableSortBy: true,
      toText: (data) => data?.value || 'N/A',
      Cell: (data) => {
        return data?.value ? (
          <div className="flex justify-between">
            <Link to={`${Urls.AAS_ORGANIZATIONS}/${data?.row?.original?.metadata?.serviceProviderPayeeId}`}>{data.value}</Link>
          </div>
        ) : (
          <>N/A</>
        );
      },
    },
    {
      id: 'metadata.serviceProviderPayee.billingAddress.line1',
      Header: 'Service Provider Address',
      accessor: (data) => data?.metadata?.serviceProviderPayee?.billingAddress,
      Cell: (data) => {
        return data.row?.original?.metadata?.serviceProviderPayeeId ? (
          <>
            {data?.value?.line1} {data?.value?.line2 ?? ''}
          </>
        ) : (
          <>N/A</>
        );
      },
    },
    {
      id: 'metadata.serviceProviderPayee.billingAddress',
      Header: 'Service Provider City State Zip',
      accessor: (data) => data?.metadata?.serviceProviderPayee?.billingAddress,
      Cell: (data) => {
        const address = data?.value;
        return data.row?.original?.metadata?.serviceProviderPayeeId ? (
          <>{`${address?.city}, ${statesAsObject[address?.stateUnabbreviated] || address?.stateUnabbreviated || ''} ${address?.zip}`}</>
        ) : (
          <>N/A</>
        );
      },
    },
    {
      id: 'metadata.payableAASContract.metadata.location.metadata.contacts',
      Header: 'Customer Name',
      accessor: (data) => data?.metadata?.payableAASContract?.metadata?.location?.metadata?.contacts?.[0]?.fullName,
      Cell: (data) => <>{data?.value ?? 'N/A'}</>,
    },
    {
      id: 'metadata.payableAASContract.metadata.location.address.line1',
      Header: 'Customer Address',
      accessor: (data) => data?.metadata?.payableAASContract?.metadata?.location?.address,
      Cell: (data) => (
        <>
          {data?.value?.line1} {data?.value?.line2 ?? ''}
        </>
      ),
    },
    {
      id: 'metadata.payableAASContract.metadata.location.address',
      Header: 'Customer City State Zip',
      accessor: (data) => data?.metadata?.payableAASContract?.metadata?.location?.address,
      Cell: (data) => {
        const address = data?.value;
        return (
          <>
            {address?.city}, {statesAsObject[address?.stateUnabbreviated] || address?.stateUnabbreviated || ''} {address?.zip}
          </>
        );
      },
    },
    {
      id: 'metadata.payableAASContract.metadata.obligor',
      Header: 'Obligor',
      accessor: (data) => data?.metadata?.payableAASContract?.metadata?.obligor,
      Cell: (data) => <>{data?.value ?? 'N/A'}</>,
    },
    {
      id: 'metadata.credit',
      Header: 'Credit',
      toText: (data) => centsToMoney(data.value || 0),
      accessor: (data) => data?.metadata?.credit,
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          inputType={descriptionListInputTypes.currency}
          formatter={(value) => centsToMoney(value)}
          onSave={(value) =>
            patchPayable({
              payableId: data.row?.original?.payableId,
              metadata: { credit: value },
            })
          }
        />
      ),
    },
    {
      id: 'claimTagsMetadata.tagId',
      Header: 'Tags',
      accessor: (data) => data.metadata?.payableServiceRequest?.metadata?.clientTags,
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      filterOptions: {
        type: TableFilterTypes.clientTagsMultiSelect,
        prependedOptions: [{ label: 'None', value: 'none' }],
        defaultValue: [],
        filterType: JsonParam,
        entityLabel: EntityLabelEnum.ServiceRequest,
      },
      toText: (data) => data?.value?.map((t) => t.name)?.join(',') || '',
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value?.map((item) => ({ value: item?.tagId, label: item?.name, style: item?.style }))}
            formatter={(value) => {
              return (
                <div className="inline-block overflow-x-auto">
                  {value?.map(({ value, label, style }) => <ClientTag key={value} label={label} style={style} value={value} />) || ''}
                </div>
              );
            }}
            inputType={descriptionListInputTypes.clientTagsMultiSelect}
            entityLabel={EntityLabelEnum.ServiceRequest}
            label=""
            onSave={(selections) => {
              const clientTags = selections ? selections?.map((selection) => ({ tagId: selection?.value })) : [];

              patchServiceRequest({
                serviceRequestId: data.row?.original?.metadata?.payableServiceRequestId,
                metadata: { clientTags },
              });
            }}
          />
        );
      },
    },
    {
      id: 'claimTagsMetadata.excludeServicePowerTag',
      width: 0,
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.switch,
        label: 'Exclude Service Power Tag',
      },
    },
  ];
}
