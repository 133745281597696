import React from 'react';
import FormFields from '../Form/FormFields';

interface RequestFormConsumablesProps {
  partNumber?: string;
  partQuantity?: number;
  onChange: (args: { partNumber?: string; partQuantity?: number }) => void;
}

export const RequestFormConsumables = ({ partNumber, partQuantity, onChange }: RequestFormConsumablesProps) => {
  return (
    <div>
      <div className="-mb-8">
        <FormFields
          handleChange={(e) => onChange({ partNumber, partQuantity, [e.target.id]: e.target.value })}
          fields={[
            {
              fields: [
                {
                  label: 'Part number',
                  placeholder: 'Consumable description or part number (if available)',
                  id: 'partNumber',
                  value: partNumber,
                },
                {
                  label: 'Part quantity',
                  id: 'partQuantity',
                  value: partQuantity,
                  type: 'number',
                  isHalf: true,
                  required: true,
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};
