import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { supportEmailAddress } from '../../constants/contact';
import UnderlinedButton from '../Buttons/UnderlinedButton';
import { ReactComponent as ErrorSvg } from './error.svg';

export const ErrorBoundaryHtml = () => {
  return (
    <div className="my-12 flex flex-wrap justify-center text-center">
      <div className="w-8/12">
        <div>
          <h1 className="text-3xl font-bold">Something went wrong</h1>
          <p>
            Need Help? Please email us at <a href={`mailto:${supportEmailAddress}`}>{supportEmailAddress}</a>
          </p>
          <UnderlinedButton tiny type="button" onClick={() => window.location.reload()}>
            or click here to reload the page and try again.
          </UnderlinedButton>
          <ErrorSvg className="m-auto mt-6 h-64 w-full" />
        </div>
      </div>
    </div>
  );
};

class MainErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        hasError: false,
      });
    }
  }

  static getDerivedStateFromError(error) {
    console.error(error);

    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryHtml />;
    }

    return this.props.children;
  }
}
export default withRouter(MainErrorBoundary);
