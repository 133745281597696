import React, { useState } from 'react';
import { deleteLocation, reactivateLocation, deactivateLocation, Location, OrganizationFeatureFlagEnum } from '@equips/entities-schema';
import { CellProps } from 'react-table';
import Urls from '../../routes/Urls';
import DropDown from '../../common/components/DropDown';
import { buildQueryString } from '../../common/functions/buildQueryString';
import { useAuth } from '../../common/auth/AuthContext';

export function LocationRowActions(props: React.PropsWithChildren<CellProps<Location, any>>) {
  const entityId = props.row.original.metadata?.locationId ?? '';
  const { userCan, admins, internalUsers, roles, determineFeatureFlagVisibility } = useAuth();
  const [loading, setLoading] = useState(false);
  const deactivated = props.row.original.metadata?.deactivatedAt != null;

  return (
    <DropDown
      containerClasses="more-actions"
      actions={[
        ...(userCan([...admins, roles.customerLocationAdmin, roles.providerLocationAdmin])
          ? [{ Component: <>View location</>, to: `${Urls.LOCATIONS}/${entityId}` }, { border: true }]
          : []),
        ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility)
          ? [{ Component: <>View equipment</>, to: `${Urls.EQUIPMENT}?metadata.locationId=${entityId}` }]
          : []),
        ...(userCan(internalUsers)
          ? [
              {
                Component: <>View organization</>,
                to: `${Urls.ORGANIZATIONS}/${props.row.original?.organizationMetadata?.organizationId}`,
              },
            ]
          : []),
        { Component: <>View requests</>, to: buildQueryString(Urls.SERVICE_REQUESTS, { 'metadata.locationId': entityId || '' }) },
        ...(deactivated && userCan([...internalUsers, roles.customerOrganizationAdmin])
          ? [
              {
                Component: <>{loading ? 'Loading...' : 'Reactivate'}</>,
                action: async () => {
                  setLoading(true);
                  await reactivateLocation({ entityId });
                  setLoading(false);
                },
              },
              ...(userCan([roles.superGlobalAdmin])
                ? [
                    {
                      Component: <>{loading ? 'Loading...' : 'Delete'}</>,
                      action: async () => {
                        setLoading(true);
                        await deleteLocation({ entityId });
                        setLoading(false);
                      },
                    },
                  ]
                : []),
            ]
          : [
              ...(userCan([...internalUsers, roles.customerOrganizationAdmin])
                ? [
                    {
                      Component: <>{loading ? 'Loading...' : 'Deactivate'}</>,
                      action: async () => {
                        setLoading(true);
                        await deactivateLocation({ entityId });
                        // await onSideFormSuccess();
                        setLoading(false);
                      },
                    },
                  ]
                : []),
            ]),
      ]}
    />
  );
}
