import React from 'react';
import { Link } from 'react-router-dom';
import useRouter from '../../hooks/useRouter';
import Badge, { badgeTypes } from '../Badge';

const isActivePath = ({ value, pathname = '' }) => {
  const path = pathname.split('?')?.[0];
  const rootValue = value.split('?')?.[0];

  return rootValue === path;
};

export interface RouteType {
  label: string;
  value: string;
  badgeCount?: any;
}

export interface HeaderProps {
  className?: string;
  testId?: string;
  title?: string;
  subtitle?: string;
  routes?: RouteType[];
  ActionButtons?: null | (() => JSX.Element | null);
}

const Header: React.FC<HeaderProps> = ({ className = '', testId = '', routes = [], title = '', subtitle = '', ActionButtons = null }) => {
  const {
    location: { pathname },
  } = useRouter();

  return (
    <header data-testid={testId} className={`items-bottom flex flex-wrap justify-between  ${className}`}>
      {routes.length > 0 && (
        <div className="flex items-center leading-none">
          {routes.map(({ label, value, badgeCount }, i) => (
            <Link
              className={`relative border-b-2 px-4 py-2 text-xs font-semibold leading-none transition-all duration-150 md:text-base ${
                isActivePath({ value, pathname })
                  ? 'border-equipsLightBlue text-equipsLightBlue'
                  : 'border-gray-200 text-gray-600 hover:border-gray-300 hover:text-gray-700 '
              } ${i !== 0 ? 'ml-2' : ''}`}
              key={value}
              to={value}
            >
              {label}
              {badgeCount && <Badge badgeType={badgeTypes.alert}>{badgeCount}</Badge>}
            </Link>
          ))}
        </div>
      )}
      {routes.length === 0 && (
        <div className="">
          <h1 className="mb-1 text-3xl font-semibold tracking-wide text-equipsNavyBlue">{title}</h1>
          <p className="text-sm text-gray-700">{subtitle}</p>
        </div>
      )}
      {ActionButtons && ActionButtons()}
    </header>
  );
};
export default React.memo(Header);
