import React, { useMemo, useState } from 'react';
import { notEmpty } from '@equips/common-resources';
import { EntityLabelEnum, Maybe, TagMetadata } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { getTagsForSelect } from '../../../graphql/queries/tagGraphQLQueries';
import createRandomString from '../../functions/createRandomString';
import MultipleSelectChip from '../Chips/MultipleSelectChip';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import ClientTag from '../Tags/ClientTag';

export interface TagSelectOptionType {
  value: string;
  label: string;
  style?: string;
  entityLabel?: EntityLabelEnum;
}

function formatTagForReactSelect(tag): TagSelectOptionType {
  const { metadata } = tag;
  const { tagId, name, entityLabel, style } = metadata;

  if (tagId && name && entityLabel) {
    return { value: tagId, label: name, entityLabel, style };
  }

  return { value: '', label: '' };
}

function tagFormatter(tags: Maybe<Maybe<TagMetadata>[]>) {
  return tags?.map((tag) => <ClientTag className="m-1" key={tag?.tagId} label={tag?.name} style={tag?.style} value={tag?.tagId} />);
}

interface ClientTagsSelectProps {
  label?: string;
  entityLabel: EntityLabelEnum;
  organizationId: string;
  onChange: (...args: any[]) => any;
  fullWidth?: boolean;
  required?: boolean;
}
const ClientTagsSelect = ({
  label = 'Tags',
  entityLabel,
  onChange,
  organizationId,
  fullWidth = false,
  required = false,
}: ClientTagsSelectProps) => {
  const [state, setState] = useState<{ [x: string]: boolean }>({});

  const { data: defaultOptions, error } = useQuery(
    ['getTagsForSelect', organizationId],
    () =>
      getTagsForSelect({
        search: '',
        entityLabel,
        organizationId: organizationId,
      }),
    {
      enabled: !!organizationId,
      select: (data) => {
        const options = data?.data?.tag?.data || [];
        return options.map(formatTagForReactSelect).filter(notEmpty);
      },
    },
  );

  const clientTagsHtmlId = useMemo(() => `clientTags${createRandomString(5)}`, []);

  function handleSelected(values) {
    setState((s) => ({
      ...s,
      ...values,
    }));

    let selectedClientTags: { tagId: string }[] = [];

    for (const [key, value] of Object.entries(values)) {
      if (value) {
        selectedClientTags = [...selectedClientTags, { tagId: key }];
      }
    }

    onChange(selectedClientTags);
  }

  return (
    <>
      {defaultOptions && defaultOptions.length && !error ? (
        <FormGroup fullWidth={fullWidth}>
          <Label required={required} id={clientTagsHtmlId} label={label} className={'py-2'} />
          <MultipleSelectChip
            chipTestId="clientTagsSelectTestId"
            chipSetType="filter"
            setSelected={(values) => handleSelected(values)}
            selected={state}
            options={defaultOptions || []}
          />
        </FormGroup>
      ) : null}
    </>
  );
};

export { ClientTagsSelect, formatTagForReactSelect, tagFormatter };
