import axios from 'axios';
import { decryptData } from './crypto';
import waitSynchronously from './waitSynchronously';

export async function downloadAndDecyptData(presignedGetUrl: string, encryptionKey: string): Promise<string> {
  const result = await axios.get(presignedGetUrl);

  return decryptData({ encryptionKey, data: result.data });
}

async function doesPresignedGetUrlResolveFileOnS3(presignedGetUrl: string): Promise<boolean> {
  try {
    const result = await axios.get(presignedGetUrl);
    return result.status === 200;
  } catch (error) {
    return false;
  }
}

export async function checkS3FileExistence(presignedGetUrl: string, backOff = 500, iterations = 40): Promise<boolean> {
  let transfered = false;
  let counter = 0;

  while (!transfered) {
    await waitSynchronously(backOff);

    if (counter >= iterations) {
      console.error(`Could not find file with${presignedGetUrl} tried ${iterations} times`);

      return false;
    }

    transfered = await doesPresignedGetUrlResolveFileOnS3(presignedGetUrl);
    counter += 1;
  }

  return true;
}

export async function putFileToS3(presignedPutUrl: string | undefined | null, file: File) {
  if (!presignedPutUrl) throw new Error('putFileToS3 was called without a presignedPutUrl');

  let options = {
    headers: {
      'Content-Type': file.type,
    },
  };

  return axios.put(presignedPutUrl, file, options);
}

export async function deleteFileInS3(presignedDeleteUrl: string | undefined | null) {
  if (!presignedDeleteUrl) throw new Error('deleteFileInS3 was called without a presignedDeleteUrl');

  return axios.delete(presignedDeleteUrl);
}
