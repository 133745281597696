import { useCallback, useRef } from 'react';

/**
 * Hook to handle click and double-click events.
 * In React, a double-click will FIRST trigger 2 click events before a double-click event.
 * This hook delays the click events long enough to detect whether a double-click will occur.
 * If a double-click occurs, then the click event(s) are cancelled.
 */
function useSingleAndDoubleClicks({
  onClick,
  onDoubleClick,
}: {
  onClick: (data: unknown) => unknown;
  onDoubleClick: (data: unknown) => unknown;
}): { handleClick: (data: unknown) => void; handleDoubleClick: (data: unknown) => void } {
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  const cancelPendingClick = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [timer]);

  const handleClick = useCallback(
    (data) => {
      // We only cache the most recent click event, so cancel any pending clicks
      cancelPendingClick();
      timer.current = setTimeout(() => {
        timer.current = null;
        onClick(data);
      }, 300);
    },
    [timer, cancelPendingClick, onClick],
  );

  const handleDoubleClick = useCallback(
    (data) => {
      cancelPendingClick();
      onDoubleClick(data);
    },
    [cancelPendingClick, onDoubleClick],
  );

  return { handleClick, handleDoubleClick };
}

export default useSingleAndDoubleClicks;
