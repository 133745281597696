import { cleanObject } from '../../common/functions/cleanObject';
import { gql } from '../graphqlHelperFunctions';
import { postToLocationsEndpoint } from '../postToEndpoint';

const mutateLocation = async (
  query,
  {
    organizationId,
    locationId,
    locationName,
    shortId,
    legacyLocationId,
    line1,
    line2,
    city,
    stateUnabbreviated,
    timezone,
    countryUnabbreviated,
    zip,
    parentLocationId,
    clientTags,
    contacts,
    imageId,
    mailingAddress,
  },
) => {
  const variables = {
    metadata: {
      locationName,
      shortId,
      legacyLocationId,
      timezone,
      parentLocationId,
      clientTags,
      contacts,
      imageId,
    },
    address: {
      line1,
      line2,
      city,
      stateUnabbreviated,
      zip,
      countryUnabbreviated,
    },
    mailingAddress: {
      ...mailingAddress,
    },
  };

  if (locationId && locationId !== '') {
    variables.locationId = locationId;
  } else {
    variables.metadata.organizationId = organizationId;
  }

  return postToLocationsEndpoint({ query, variables: cleanObject(variables) });
};

const postQuery = gql`
  mutation PostLocationMutation($metadata: LocationMetadataPostInput!, $address: AddressPostInput!, $mailingAddress: AddressPostInput!) {
    post(metadata: $metadata, address: $address, mailingAddress: $mailingAddress) {
      metadata {
        locationId
        locationName
      }
    }
  }
`;
const patchQuery = gql`
  mutation PatchLocationMutation(
    $locationId: String!
    $metadata: LocationMetadataPatchInput!
    $address: AddressPatchInput!
    $mailingAddress: AddressPatchInput!
  ) {
    patch(locationId: $locationId, metadata: $metadata, address: $address, mailingAddress: $mailingAddress) {
      metadata {
        locationId
        locationName
      }
    }
  }
`;
export const createLocation = async (location) => mutateLocation(postQuery, location);
export const updateLocation = async (location) => mutateLocation(patchQuery, location);
