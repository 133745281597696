import React, { useEffect, useRef } from 'react';
import pell from 'pell';
import './RichTextEditor.css';
import { AlertTypes, useAlert } from './Alerts/AlertContext';
import { MAX_CONTENT_SIZE } from './ServiceRequest/DescriptionEditor';

const base64ImageRegex = /^data:image\/(jpeg|png|gif|bmp|svg\+xml);base64,/;

export type RichTextEditorProps = {
  defaultValue: string;
  onChange: (value: string) => void;
  disableImagePaste?: boolean;
};

export function RichTextEditor(props: RichTextEditorProps) {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const pellRef = useRef<HTMLElement | null>(null);

  const showAlert = useAlert();

  useEffect(() => {
    if (editorRef.current && props.disableImagePaste) {
      editorRef.current.onpaste = (e) => {
        const files = e.clipboardData?.files ?? [];
        for (let i = 0; i < files.length; i++) {
          if (files[i].type.match(/image/g)) {
            e.preventDefault();
            showAlert({
              content: 'Use attachment to include images',
              type: AlertTypes.warning,
            });
          }
        }
      };
    }
  }, [editorRef.current, props.disableImagePaste]);

  useEffect(() => {
    if (!pellRef.current) {
      const editor = pell.init({
        element: editorRef.current as HTMLDivElement,
        onChange: props.onChange,
        actions: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'heading1',
          'heading2',
          'paragraph',
          'quote',
          'olist',
          'ulist',
          'code',
          'line',
          'link',
          {
            name: 'image',
            result: () => {
              const input = prompt('Enter image URL') || '';

              if (input) {
                const isBase64Image = base64ImageRegex.test(input);

                if (isBase64Image) {
                  showAlert({
                    content: 'The image URL provided is invalid',
                    type: AlertTypes.warning,
                  });
                } else {
                  const image = `<img src="${input}" />`;
                  let current = editor.content.innerHTML;

                  current += image;

                  const contentSize = new Blob([current], {
                    type: 'text/html',
                  }).size;

                  if (contentSize < MAX_CONTENT_SIZE) {
                    editor.content.innerHTML += image;
                  } else {
                    showAlert({
                      content: 'Exceeded max size of description content',
                      type: AlertTypes.warning,
                    });
                  }
                  props.onChange(editor.content.innerHTML);
                }
              }
            },
          },
        ],
      });

      editor.content.innerHTML = `<div>${props.defaultValue}</div>`;
      pellRef.current = editor;
    }
  }, []);

  return <div className="pell h-full" ref={editorRef}></div>;
}
