import {
  queryOrganizations,
  ElasticsearchSearchTypesEnum,
  OrganizationsQueryOrganizationsArgs,
  fullOrganizationsMetadataGraphqlNode,
  addressFields,
  generateStringTermFilters,
  OrganizationsAnyRoleFiltersInput,
  IndustryType,
  Maybe,
  Organization,
  OrganizationsSearchInput,
  OrganizationTagsEnum,
  NotesSearchInput,
} from '@equips/entities-schema';
import { gql } from '../graphqlHelperFunctions';
import { postToOrganizationsEndpoint as untypedPostToOrganizationsEndpoint } from '../postToEndpoint';
import { NoteListFragment } from '../../common/components/ViewPage/NoteListFragment';
import { defaultSearchParamsForAsyncSelects } from '../defaultSearchParamsForAsyncSelects';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import { TableFetch } from '../../common/components/DataTable';
import Urls from '../../routes/Urls';
import { buildQueryString } from '../../common/functions/buildQueryString';
import { defaultColumns } from './../../common/components/Table/defaultColumns';

const postToOrganizationsEndpoint = async ({ query, variables }: { query: string; variables: OrganizationsQueryOrganizationsArgs }) => {
  return untypedPostToOrganizationsEndpoint({ query, variables });
};

export const getProviderInvoicesByOrganizationId = async ({ organizationId, startDateTimestamp, endDateTimestamp }) =>
  postToOrganizationsEndpoint({
    query: gql`
      query ($organizationId: String!, $search: FilesSearchInput) {
        organizations(organizationId: $organizationId) {
          data {
            outOfScopeInvoices(search: $search) {
              data {
                metadata {
                  metadata
                  fileId
                  notificationSentAt
                  presignedGetUrl
                  createdAt
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 10000,
        filters: [{ metadata: { createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }] } }],
      },
      organizationId,
    },
  });

export const getUnconfirmedUsers = async ({ organizationId }) =>
  postToOrganizationsEndpoint({
    query: gql`
      query ($organizationId: String!) {
        organizations(organizationId: $organizationId) {
          data {
            unverifiedCognitoUsers {
              Username
              UserCreateDate
              Attributes {
                Name
                Value
              }
            }
          }
        }
      }
    `,
    variables: { organizationId },
  });

export const getOrganizationFromQuickBooks = async ({ quickbooksId, organizationType }) =>
  postToOrganizationsEndpoint({
    query: gql`
      mutation ($quickbooksId: String!, $organizationType: QuickbooksOrganizationType!) {
        quickbooksIdCheck(quickbooksId: $quickbooksId, organizationType: $organizationType) {
          DisplayName
        }
      }
    `,
    // @ts-ignore Unknown reason. Please investigate me.
    variables: { quickbooksId, organizationType },
  });

export const getOrganizationNameAndDeactivatedAt = async ({ organizationId }) =>
  postToOrganizationsEndpoint({
    query: gql`
      query ($organizationId: String!) {
        organizations(organizationId: $organizationId) {
          data {
            provider {
              isExternalProvider
              providerSource
            }
            metadata {
              autorequestId
              shortId
              deactivatedAt
              organizationName
              clientOrganizationType
              createdAt
              modifiedAt
              createdByUser {
                metadata {
                  fullName
                }
              }
              modifiedAt
              modifiedByUser {
                metadata {
                  fullName
                }
              }
            }
          }
        }
      }
    `,
    variables: { organizationId },
  });

export const getOrganizationNotes = async ({ entityId, noteSearch }: { entityId: string; noteSearch?: NotesSearchInput }) => {
  const { data } = await postToOrganizationsEndpoint({
    query: gql`
      query ($organizationId: String!, $noteSearch: NotesSearchInput) {
        organizations(organizationId: $organizationId) {
          data {
            notes(search: $noteSearch) {
              ...NoteComponentData
            }
          }
        }
      }
      ${NoteListFragment.fragments.noteComponentData}
    `,
    // @ts-ignore Our schema generation code does not see notesSearch as a valid input but it is
    variables: { organizationId: entityId, noteSearch },
  });

  return data?.organizations?.data?.[0]?.notes ?? [];
};

export const getOrganizationsDispatchNote = async ({ entityId }) =>
  postToOrganizationsEndpoint({
    query: gql`
      query ($organizationId: String!) {
        organizations(organizationId: $organizationId) {
          data {
            dispatchNote {
              ...NoteComponentData
            }
          }
        }
      }
      ${NoteListFragment.fragments.noteComponentData}
    `,
    variables: { organizationId: entityId },
  });

export const clientValueForFilter = 'CLIENT';
export const providerValueForFilter = 'PROVIDER';
export const anyValueForFilter = 'ANY';

export async function getOrganizationsForSelect({
  search = '',
  typeOfOrganizationToFind = 'ANY',
  possibleProviderIds = [],
  organizationTags = [],
  byProvider,
}: {
  search?: string;
  typeOfOrganizationToFind?: 'ANY' | typeof clientValueForFilter | typeof providerValueForFilter | string;
  possibleProviderIds?: string[];
  organizationTags?: Maybe<OrganizationTagsEnum>[];
  byProvider?: boolean;
}) {
  let filters = [] as OrganizationsAnyRoleFiltersInput[];

  if (typeOfOrganizationToFind === clientValueForFilter) {
    filters = [{ provider: { isExternalProvider: [{ notTerm: true }] } }];
  } else if (typeOfOrganizationToFind === providerValueForFilter) {
    filters = [
      {
        provider: { isExternalProvider: [{ term: true }] },
        metadata: {
          quickbooksVendorId: [{ keyword: [{ exists: true }] }],
          organizationId: [{ keyword: [{ notTerms: possibleProviderIds }] }],
        },
      },
    ];
  }

  if (organizationTags.includes(OrganizationTagsEnum.Aas)) {
    filters = [
      {
        metadata: {
          tags: [
            {
              keyword: [{ terms: [OrganizationTagsEnum.Aas] }],
            },
          ],
        },
        provider: { isExternalProvider: [{ term: true }] },
      },
    ];
  } else if (organizationTags.includes(OrganizationTagsEnum.Uhw)) {
    filters = [
      {
        metadata: {
          tags: [
            {
              keyword: [{ terms: [OrganizationTagsEnum.Uhw] }],
            },
          ],
        },
        provider: { isExternalProvider: [{ term: true }] },
      },
    ];
  }

  return queryOrganizations(
    {
      byProvider,
      search: {
        ...defaultSearchParamsForAsyncSelects(search, false, { keyToSortBy: 'organizationName' }),
        ...(organizationTags.includes(OrganizationTagsEnum.Aas) ? { size: 25 } : {}),
        filters,
      },
    },
    {
      query: `
      metadata { 
        organizationId 
        organizationName
        industry
       }
       billingAddress {
        ${addressFields}
      }
      provider {
        equipmentServiced
        dispatchEmail
        dispatchPhone
        dispatchWebsite
        isExternalProvider
      }`,
    },
  );
}

export const getOrganizationByMetadataField = async ({ size = 1, value, field, showActivatedAndDeactivated = false }) =>
  postToOrganizationsEndpoint({
    query: gql`
      query ($search: OrganizationsSearchInput) {
        organizations(search: $search) {
          data {
            metadata {
              organizationName
              organizationId
              urlDomain
              modifiedByUserId
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size,
        from: 0,
        searchFields: { metadata: { [field]: true } },
        filters: [
          {
            metadata: {
              ...(showActivatedAndDeactivated ? { deactivatedAt: [{ all: true }] } : {}),
              [field]: { keyword: [{ term: value }] },
            },
          },
        ],
      },
    },
  });

interface OrganizationFilterType {
  searchQuery?: string;
  showDeactivated?: boolean;
  perPage?: number;
  page?: number;
  stateUnabbreviated?: string;
  equipmentServiced?: string;
  organizationType;
  industry?: IndustryType;
  isTrial?: string;
  goLiveDate?: string;
  sorted?: any[];
  quickbooksCompanyId?: string;
  quickbooksVendorId?: string;
  createdAt?: string;
  createdByUserId?: string;
  modifiedAt?: string;
  modifiedByUserId?: string;
  underwriterId?: string;
  acceptingDispatch?: string;
}

export function buildOrganizationsQueryString(args: OrganizationFilterType): string {
  return buildQueryString(Urls.ORGANIZATIONS, args);
}

export const getOrganizationsForMainTable: TableFetch<Maybe<Organization>> = async ({
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}) => {
  const query = `
    metadata {
      autoForwardAllDispatchRequests
      accountManager{
        metadata {
          fullName
        }
      }
      producers
      organizationName
      organizationId
      clientOrganizationType
      shortId
      urlDomain
      isTrial
      industry
      legacyServiceProviderId
      legacyProviderCode
      quickbooksCompanyId
      quickbooksVendorId
      EIN
      autorequestId
      goLiveDate
      tier
      ${defaultColumns.createdColumnQueryString}
      ${defaultColumns.modifiedColumnQueryString}
    }
    billingAddress {
      ${addressFields}
    }
    provider {
      equipmentServiced
      dispatchEmail
      dispatchPhone
      dispatchWebsite
      isExternalProvider
    }`;

  const search: OrganizationsSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: [
      {
        metadata: {
          ...(filterMap['metadata.isTrial'] === 'active'
            ? { isTrial: [{ notTerm: true }] }
            : filterMap['metadata.isTrial'] === 'trial'
            ? { isTrial: [{ term: true }] }
            : {}),
          ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
          ...(filterMap['metadata.underwriterId'] ? { underwriterId: [{ keyword: [{ term: filterMap['metadata.underwriterId'] }] }] } : {}),
          ...(filterMap['metadata.clientOrganizationType']
            ? { clientOrganizationType: [{ keyword: [{ term: filterMap['metadata.clientOrganizationType'] }] }] }
            : {}),
          ...(filterMap['metadata.autoForwardAllDispatchRequests'] === 'true'
            ? { autoForwardAllDispatchRequests: [{ notTerm: true }] }
            : filterMap['metadata.autoForwardAllDispatchRequests'] === 'false'
            ? { autoForwardAllDispatchRequests: [{ term: true }] }
            : {}),
          ...generateStringTermFilters({
            quickbooksCompanyId: filterMap['metadata.quickbooksCompanyId'],
            quickbooksVendorId: filterMap['metadata.quickbooksVendorId'],
            industry: filterMap['metadata.industry'],
          }),
          goLiveDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.goLiveDate']),
          createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
          modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
          ...(filterMap['metadata.createdByUserId']
            ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
            : {}),
          ...(filterMap['metadata.modifiedByUserId']
            ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
            : {}),
        },
        billingAddress: {
          ...(filterMap['billingAddress.stateUnabbreviated']
            ? { stateUnabbreviated: [{ keyword: [{ term: filterMap['billingAddress.stateUnabbreviated'] }] }] }
            : {}),
        },
        provider: {
          ...(filterMap['provider.equipmentServiced']
            ? { equipmentServiced: [{ keyword: [{ match: filterMap['provider.equipmentServiced'] }] }] }
            : {}),
          ...(filterMap['metadata.organizationType'] === providerValueForFilter ? { isExternalProvider: [{ term: true }] } : {}),
          ...(filterMap['metadata.autoForwardAllDispatchRequests'] === 'true' ? { isExternalProvider: [{ term: true }] } : {}),
          ...(filterMap['metadata.organizationType'] === clientValueForFilter ? { isExternalProvider: [{ notTerm: true }] } : {}),
        },
      },
    ],
  };

  const { data } = await queryOrganizations({ search }, { query });

  return { totalCount: data?.organizations?.totalCount, data: data?.organizations?.data ?? [] };
};

export const getEquipsAutoDispatchStatus = async () =>
  postToOrganizationsEndpoint({
    query: gql`
      query ($search: OrganizationsSearchInput) {
        organizations(search: $search) {
          data {
            metadata {
              autoForwardAllDispatchRequests
            }
          }
        }
      }
    `,
    variables: {
      search: {
        q: 'equips.com',
        size: 1,
        searchFields: { metadata: { urlDomain: true } },
      },
    },
  });

export const getOrganizationNameById = async ({ organizationId }) =>
  postToOrganizationsEndpoint({
    query: gql`
      query ($organizationId: String) {
        organizations(organizationId: $organizationId) {
          data {
            metadata {
              organizationName
              requireApprovalForSelfRegisteredUsers
              submissionSettings {
                access
                method
                requireApproval
                instructions
                triageLocationId
                requireTriageLocation
                requireContactInformation
              }
              invoiceSettings {
                deductibleAmount
              }
              allowSRGeolocation
              allowUnauthenticatedAttachments
            }
          }
        }
      }
    `,
    variables: { organizationId },
  });

export const getOrganizationDispatchDetailsById = async ({ organizationId }) => {
  return queryOrganizations(
    { organizationId },
    {
      query: gql`
      metadata {
        organizationId
        emailRouting
        offHoursEmailRouting
        autoForwardAllDispatchRequests
      }
      provider {
        dispatchEmail
      }
      routes{
        metadata {
          routeId
          type
          equipmentId
          equipment {
            metadata {
              equipmentId
              shortId
              equipmentName
              tid
              serialNumber
            }
            specMetadata{
              model
              manufacturer
            }
          }
          categoryIds
          categories {
            metadata {
              categoryId
              name
              parentCategoryId
            }
          }
          userIds
          users {
            metadata {
              userId
              fullName
            }
          }
          locationIds
          tagIds
          tags {
            metadata {
              tagId
              name
            }
          }
          emails
          providerId
          provider {
            metadata {
              organizationName
            }
          }
          schedule {
            days
            startHour
            endHour
          }
        }
      }
      `,
    },
  );
};

export const getOrganizationBillingSettingsById = async ({ organizationId }) => {
  return queryOrganizations(
    { organizationId },
    {
      query: `
      ${fullOrganizationsMetadataGraphqlNode}
      metadata {
        organizationId
        updatedBillingExperience
      }
      `,
    },
  );
};

export const getOrganizationById = async ({ organizationId }) => {
  return queryOrganizations(
    { organizationId },
    {
      query: `
      ${fullOrganizationsMetadataGraphqlNode}
      metadata {
        organizationId
        organizationName
        urlDomain
        additionalDomains
        accountManagerId
        haveCOAsApproveServiceRequests
        clientOrganizationType
        accountManager {
          metadata {
            fullName
          }
        }
        underwriter {
          metadata {
            fullName
          }
        }
        EIN
        isTrial
        industry
        organizationBillingMethod
        autoForwardAllDispatchRequests
        quickbooksCompanyId
        quickbooksVendorId
        shortId
        goLiveDate
        producers
        featureFlags
        tags
        tier
        referringOrganizationId
      }
      provider {
        dispatchEmail
        dispatchPhone
        dispatchWebsite
        equipmentServiced
        isExternalProvider
        legacyDispatchDeliverable
        soapDispatchEndpoint
        paymentPreference
        providerSource
        typeOfWork
      }
      billingAddress {
        ${addressFields}
      }
      shippingAddress {
       ${addressFields}
      }
      dispatchNote {
        metadata {
          message
        }
      }
      `,
    },
  );
};

export const getOrganizationFeatureFlags = async ({ organizationId }) => {
  return queryOrganizations(
    { organizationId },
    {
      query: gql`
      metadata {
        featureFlags
      }
      `,
    },
  );
};

export const getOrganizationLogo = async ({ organizationId }) => {
  return queryOrganizations(
    { organizationId },
    {
      query: gql`
      metadata {
        logo {
          presignedGetUrl
          presignedPutUrl
        }
      }
      `,
    },
  );
};

export const getOrganizationServiceCategories = async ({ organizationId }) => {
  return queryOrganizations(
    { organizationId },
    {
      query: gql`
      metadata {
        serviceCategories {
          serviceCategoryId
          metadata {
            name
            coverage
            parentServiceCategory {
               metadata {
                name
               }
            }
          }
        }
      }
      `,
    },
  );
};

export const getOrganizationForInvoiceGenerator = async ({ organizationId }) =>
  queryOrganizations(
    { organizationId },
    {
      query: `
        metadata {
          organizationId 
          organizationName
          industry
          logo {
            presignedGetUrl
          }
        }
        billingAddress {
          ${addressFields}
        }
      `,
    },
  );
