/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import { Equipment, Location, Maybe } from '@equips/entities-schema';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import ChevronRight from '@mui/icons-material/ChevronRight';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import Room from '@mui/icons-material/Room';
import Search from '@mui/icons-material/Search';
import { Categories, FilterInput, LocationCategories } from './SubmitRequestSelectEquipment';

type CommonProps = {
  isMobile: boolean;
  categories: Categories;
  equipmentCount: number;
  equipmentFilter: string | null;
  locationFilter: string | null;
  selectedCategory: string | undefined;
  selectedEquipment: Equipment[] | undefined;
  selectedLocation: Location | undefined;
  setSelectedLocation: React.Dispatch<React.SetStateAction<Location | undefined>>;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedEquipment: React.Dispatch<React.SetStateAction<Equipment[] | undefined>>;
  setEquipmentFilter: React.Dispatch<React.SetStateAction<string | null>>;
  loadingEquipment: boolean;
  selectedLocationRef: React.RefObject<HTMLDivElement>;
};

type LocationListItemProps = {
  location: Location;
  children?: JSX.Element[];
  level: number;
} & CommonProps;

type RecursiveLocationListProps = {
  locations: Maybe<Location>[];
} & CommonProps;

const indentClasses = ['', 'pl-8', 'pl-16', 'pl-24'];

function LocationListItem(props: LocationListItemProps) {
  const {
    location,
    isMobile,
    categories,
    selectedLocation,
    equipmentCount,
    equipmentFilter,
    locationFilter,
    selectedCategory,
    selectedEquipment,
    setSelectedLocation,
    setSelectedCategory,
    setSelectedEquipment,
    setEquipmentFilter,
    loadingEquipment,
    children,
    level,
    selectedLocationRef,
  } = props;

  const [isOpen, toggleItemOpen] = useState<boolean>(false);
  const selected = selectedLocation?.locationId === location?.locationId;

  return (
    <>
      <ListItem
        className={indentClasses[level]}
        selected={selected}
        button
        onClick={() => {
          setSelectedLocation(selected ? undefined : location);
        }}
        ref={selected ? selectedLocationRef : null}
      >
        <ListItemIcon>
          <Room />
        </ListItemIcon>
        <ListItemText
          primary={location?.metadata?.locationDisplayName ?? 'Unknown Location'}
          secondary={formatAddressAsOneLine(location.address)}
        />
        {children && children.length > 0 && (
          <IconButton
            title="Expand Sublocations"
            className=""
            onClick={(e) => {
              e.stopPropagation();
              toggleItemOpen(!isOpen);
            }}
            size="large"
          >
            {isOpen ? <IndeterminateCheckBoxOutlined /> : <AddBoxOutlined />}
          </IconButton>
        )}

        {!isMobile && selected && <ChevronRight />}
        {isMobile && selected && (
          <div className="relative z-50">
            <IconButton
              onClick={(e) => {
                setEquipmentFilter(equipmentFilter ?? '');
                e.stopPropagation();
              }}
              size="large"
            >
              <Search />
            </IconButton>
          </div>
        )}
      </ListItem>
      {(isOpen || locationFilter) && children}
      {isMobile && selected && (
        <>
          {equipmentFilter !== null && (
            <div className="my-5">
              <FilterInput filter={equipmentFilter} setFilter={setEquipmentFilter} />
            </div>
          )}
          <LocationCategories
            nested
            categories={categories}
            loading={loadingEquipment}
            equipmentCount={equipmentCount}
            equipmentFilter={equipmentFilter}
            selectedCategory={selectedCategory}
            selectedEquipment={selectedEquipment}
            setSelectedCategory={setSelectedCategory}
            setSelectedEquipment={setSelectedEquipment}
          />
        </>
      )}
    </>
  );
}

const locationMatchesFilter = (location: Location, locationFilter: string | null) => {
  if (!locationFilter) {
    return true;
  }
  const searchable = [location?.metadata?.locationDisplayName, location?.metadata?.shortId, formatAddressAsOneLine(location?.address)].join(
    ' ',
  );
  return searchable.match(new RegExp(locationFilter ?? '', 'gi'));
};

const RecursiveLocationList = (props: RecursiveLocationListProps) => {
  const { locations, ...locationListItemProps } = props;
  const createTree = (location: Location, level: number) => {
    let visible = false;
    if (locationMatchesFilter(location, locationListItemProps.locationFilter)) {
      visible = true;
    }
    const sublocations = location?.metadata?.sublocations;

    const locationListItem = (
      <LocationListItem
        {...{
          location,
          ...locationListItemProps,
          level,
        }}
      >
        {sublocations?.map((sublocation: Location) => {
          if (locationMatchesFilter(sublocation, locationListItemProps.locationFilter)) {
            visible = true;
          }
          return (
            <div className="border-y " key={sublocation?.locationId}>
              {createTree(sublocation, level + 1)}
            </div>
          );
        })}
      </LocationListItem>
    );

    return visible ? locationListItem : null;
  };

  const sortedLocations = locations.sort(
    (a, b) => a?.metadata?.locationDisplayName?.localeCompare(b?.metadata?.locationDisplayName ?? '') ?? 0,
  );

  return (
    <>
      {sortedLocations.map((location) => {
        if (!location) {
          return null;
        }
        return <div key={location.locationId}>{createTree(location, 0)}</div>;
      })}
    </>
  );
};

export default RecursiveLocationList;
