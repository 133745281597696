import React, { useMemo, useState } from 'react';
import { queryUsersWithAnyUserRole } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { abbreviateName } from '../../functions/abbreviateName';

type ContactCardProps = {
  userId?: string;
  title?: string;
  children?: React.ReactNode;
};

export default function ContactCard({ userId, title = '', children }: ContactCardProps) {
  const [showAvatarPopup, setShowAvatarPopup] = useState(false);

  const { data, isFetching } = useQuery(
    ['ContactCard', userId],
    () =>
      queryUsersWithAnyUserRole(
        { userId },
        {
          query: `metadata {
        userId
        fullName
        organizationId
        organizationName
        clientTags {
          tagId
          name
          style
        }
        defaultTagId
      }`,
        },
      ),
    {
      enabled: !!userId,
      select: (data) => data?.data,
    },
  );

  const user = data?.anyRoleUsers?.data?.[0] || {};
  const fullName = user?.metadata?.fullName || '';
  const profilePictureUrl = user?.metadata?.profilePictureUrl || undefined;
  const clientTags = user?.metadata?.clientTags;
  const defaultTagId = user?.metadata?.defaultTagId;

  const defaultTag = useMemo(() => {
    return clientTags?.find((tag) => tag?.tagId === defaultTagId);
  }, [clientTags, defaultTagId]);

  if (isFetching) {
    return (
      <div className="flex">
        <Skeleton variant="circular" width={48} height={48} />
        <Skeleton variant="rectangular" width={200} height={48} />
      </div>
    );
  }

  return (
    <div>
      <p className="mb-1 font-semibold">{title}</p>
      <div className="flex flex-wrap items-center">
        <div className="avatar relative mr-2 flex items-center">
          <Avatar
            onTouchStart={() => setShowAvatarPopup(true)}
            onContextMenu={(e) => e.preventDefault()}
            onTouchEnd={() => setShowAvatarPopup(false)}
            onBlur={() => console.log(false)}
            src={profilePictureUrl}
            alt={fullName}
            className="mr-2 h-10 w-10 bg-blue-500"
          >
            {abbreviateName(fullName)}
          </Avatar>
          {profilePictureUrl && (
            <div className={`avatar-hover white-box h-48 w-48 p-1 ${showAvatarPopup && 'avatar-show'}`}>
              <img src={profilePictureUrl} alt={fullName} />
            </div>
          )}
          {children}
        </div>
        <div></div>
        {defaultTag && <span className="ml-12 text-xs">{defaultTag?.name}</span>}
      </div>
    </div>
  );
}
