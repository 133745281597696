import React from 'react';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import EmptyComponent from '../EmptyComponent';
import Skeleton from '../Loaders/Skeleton';
import Label from './Label';

type RadioCardProps = {
  id: string;
  label: any;
  labelSubtitle?: string;
  labelHelper?: string;
  cardSize?: string;
  required?: boolean;
  onChange: (...args: any[]) => any;
  value: string | boolean | number;
  loading?: boolean;
  options: {
    icon?: any;
    label: string;
    value: string | boolean | number;
  }[];
  cardXPadding?: string;
  textSize?: string;
};
export default function RadioCard({
  options,
  onChange,
  value,
  required = false,
  label,
  labelSubtitle,
  labelHelper = '',
  id,
  cardSize = 'w-1/3',
  textSize = 'text-sm',
  cardXPadding = 'px-4',
  loading = false,
}: RadioCardProps) {
  return (
    <>
      <Label id={id} htmlId={id} label={label} required={required} helper={labelHelper} />
      {labelSubtitle && <p className="py-2 text-xs text-gray-600">{labelSubtitle}</p>}
      {loading ? (
        <div className="my-8">
          <Skeleton />
        </div>
      ) : (
        <div className="mt-2 flex flex-wrap items-center gap-3" role="radiogroup" aria-labelledby={id}>
          {options.length === 0 && <EmptyComponent text="No available options" />}
          {options.map((option) => {
            const isActive = option.value === value;
            const cardStyles = isActive ? 'border-equipsLightBlue text-gray-800' : 'border-gray-100 text-gray-700';

            return (
              <div
                key={String(option.value)}
                data-testid={`${label}${option.value}`}
                role="radio"
                aria-checked={isActive}
                tabIndex={0}
                onKeyPress={(event) => {
                  event.preventDefault();
                  onChange(option.value);
                }}
                onClick={(event) => {
                  event.preventDefault();
                  onChange(option.value);
                }}
                className={`flex cursor-pointer items-center rounded-lg border shadow-sm transition-all focus:outline-none ${cardSize} ${cardStyles} py-2 ${cardXPadding}`}
              >
                <input
                  onChange={() => ({})}
                  type="radio"
                  tabIndex={-1}
                  style={{ width: '1px', height: '1px' }}
                  className="absolute cursor-pointer opacity-0"
                  required={required}
                  name={id}
                  checked={isActive}
                />

                {isActive ? (
                  <span className="text-equipsLightBlue">
                    <RadioButtonChecked fontSize="small" />
                  </span>
                ) : (
                  <span className="text-gray-400">
                    <RadioButtonUnchecked fontSize="small" />
                  </span>
                )}

                <span className={`pl-3 ${textSize} font-semibold`}>{option.label}</span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
