import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

export default function HelperPopper({ handleClose, open, placement, anchorEl, children }) {
  return (
    <Popper keepMounted={false} open={open} anchorEl={anchorEl} placement={placement} transition style={{ zIndex: 10000 }}>
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={() => handleClose()}>
          <Fade {...TransitionProps} timeout={350}>
            <div className="p-3 bg-white border rounded-lg shadow">{children}</div>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
}

HelperPopper.propTypes = {
  placement: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
  children: PropTypes.any.isRequired,
  handleClose: PropTypes.func,
};
HelperPopper.defaultProps = { open: false, placement: 'bottom', anchorEl: <div />, handleClose: (o) => o };
