import { useState, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useDeepCompareEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [value, delay]);

  return debouncedValue;
}

export const useDebouncePrimitive = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [value, delay]);

  return debouncedValue;
};
