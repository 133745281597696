import { daysBetweenTwoTimestamps } from './dateFunctions';
import { roundTo } from './roundToDecimalPlaces';

const convertCentsToDollars = (cents: number, includeCents: boolean) => {
  const centsAsNumber = +cents;
  const dollarsAsNumber = centsAsNumber / 100;
  const dollarsToWithOrWithoutCents = includeCents ? dollarsAsNumber.toFixed(2) : dollarsAsNumber.toFixed(0);

  return dollarsToWithOrWithoutCents;
};

const insertThousandsSeparators = (amount: number) => {
  const thousandSeparator = ',';
  const decimalSeparator = '.';
  const currencySymbol = '$';
  const dollarsToWithOrWithoutCents = amount.toFixed(2);
  const amountParts = dollarsToWithOrWithoutCents.toString().split(/\.|-/);
  const negative = amountParts.length === 3 ? '-' : '';
  const dollarPortion = negative ? amountParts[1] : amountParts[0];
  const unformattedCentsPortion = negative ? amountParts[2] : amountParts[1];
  const centsPortion = unformattedCentsPortion && unformattedCentsPortion.substring(0, 2);
  const formatedCentsPortion = centsPortion ? `${decimalSeparator}${centsPortion}` : '';

  const reversedDollarPortion = dollarPortion.split('').reverse().join('');
  const reversedGroupedDollarPortion = reversedDollarPortion.match(/.{1,3}/g)?.join(thousandSeparator);
  const formattedDollarPortion = reversedGroupedDollarPortion?.split('').reverse().join('');

  return `${negative}${currencySymbol}${formattedDollarPortion}${formatedCentsPortion}`;
};

export const centsToMoney = (cents?: number | null, includeCents = true): string => {
  if (!cents) return '$0.00';
  const dollars = convertCentsToDollars(cents, includeCents);
  const formattedDollars = insertThousandsSeparators(+dollars);

  return formattedDollars;
};

export const moneyToCents = (dirtyMoneyString: string) => {
  if (!dirtyMoneyString) return false;
  const isNegative = dirtyMoneyString.charAt(0) === '-';
  const containsDecimals = dirtyMoneyString.includes('.') ? true : false;
  const noSymbols = dirtyMoneyString.replace(/[^0-9]/g, '');
  const moneyAsInt = parseInt(noSymbols, 10);
  const cents = containsDecimals ? moneyAsInt : moneyAsInt * 100;

  return isNegative ? -cents : cents;
};

export const convertExcelMoneyFieldToCents = (dirtyMoneyString: string) => {
  let [rest, decimal] = dirtyMoneyString.split('.');

  if (decimal && decimal.length === 1) {
    decimal = `${decimal}0`;
  }

  if (decimal?.length > 2) {
    decimal = decimal.slice(0, 2);
  }

  return moneyToCents(`${rest}${decimal && '.' + decimal}`);
};

export const convertDailyPriceToAnnualized = (dailyPrice) => {
  if (!dailyPrice) return '$0';
  const roundedAnnualizedPrice = roundTo(dailyPrice * 365, 2);

  return insertThousandsSeparators(roundedAnnualizedPrice);
};

export const toAnnualizedInCentsFromDailyPriceInDollars = (dailyPrice?: null | number): number => {
  if (!dailyPrice) return 0;

  return dailyPrice * 365 * 100;
};

export const toProrataInCentsFromDailyPriceInDollars = (
  dailyPrice?: null | number,
  onDate?: null | number,
  offDate?: null | number,
): number => {
  onDate = onDate || 0;
  offDate = offDate || 0;
  if (!dailyPrice) return 0;
  const daysBetween = daysBetweenTwoTimestamps(onDate, offDate);

  return dailyPrice * daysBetween * 100;
};

export const covertAnnualizedPriceToDailyPriceRegardlessOfLeapYear = (annualizedPriceInCents) => {
  const annualizedPriceInDollar = annualizedPriceInCents / 100;
  const dailyPrice = annualizedPriceInDollar / 365;

  return roundTo(dailyPrice, 10);
};

export const covertExcelValueToAnnualizedPriceInCents = (dirtyMoneyString?: string): number => {
  const annualizedPriceInCents = convertExcelMoneyFieldToCents(dirtyMoneyString || '');
  if (!annualizedPriceInCents) return 0;

  return roundTo(annualizedPriceInCents, 10);
};
