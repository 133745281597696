import React, { useState } from 'react';
import { Contact } from '@equips/entities-schema';
import { Divider } from '@mui/material';
import { formatPhoneNumber } from 'react-phone-number-input';
import Modal from '../Modal/Modal';
import Button from '../Buttons/Button';

export default function TableCellAdditionalContacts({ contacts = [], buttonTitle }: { contacts: Contact[]; buttonTitle?: string }) {
  const [modal, setModal] = useState(false);

  if (!contacts.length) return <></>;

  const defaultButtonTitle = `View contacts (${contacts.length})`;

  return (
    <>
      {modal && (
        <Modal isOpen title="Contacts" handleClose={() => setModal(false)}>
          <div className="px-4 pb-2">
            {contacts.map((contact, index) => {
              const fullName = contact?.fullName || 'N/A';
              const email = contact?.email || 'N/A';
              const phone = (contact?.phone && formatPhoneNumber(contact.phone)) || 'N/A';

              return (
                <>
                  <div key={`${fullName}__${index}`} className="my-4">
                    <p>Name: {fullName}</p>
                    <p>Email: {email}</p>
                    <p>Phone: {phone}</p>
                  </div>
                  {index !== contacts.length - 1 && <Divider />}
                </>
              );
            })}
          </div>
        </Modal>
      )}

      <div className="relative flex items-center justify-between">
        <div>
          <Button className="-my-1 ml-1" tiny gray type="button" onClick={() => setModal(true)}>
            {buttonTitle || defaultButtonTitle}
          </Button>
        </div>
      </div>
    </>
  );
}
