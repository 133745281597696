import React, { useRef, useState } from 'react';
import {
  arrayToGrammaticalStringOmittingEmptyValues,
  deduplicateServiceRequestSymptoms,
  equipsAddressLine1,
  equipsPhoneNumber,
  formatAddressAsOneLine,
} from '@equips/common-resources';
import {
  EntityLabelEnum,
  NoteAuthorizationLevel,
  NotificationMethodEnum,
  postNote,
  postNotification,
  ServiceRequest,
} from '@equips/entities-schema';
import { useReactToPrint } from 'react-to-print';
import dompurify from 'dompurify';
import Logo from '../../../../images/logo-with-text.png';
import Button from '../../../../common/components/Buttons/Button';
import FormFields, { fieldTypes } from '../../../../common/components/Form/FormFields';
import Label from '../../../../common/components/Form/Label';
import { RichTextEditor } from '../../../../common/components/RichTextEditor';
import useMutation from '../../../../common/hooks/useMutation';
import { centsToMoney } from '../../../../common/functions/moneyFunctions';
import SimpleModal, { SimpleModalProps } from '../../../../common/components/Modal/SimpleModal';
import { equipsCityStateZip, equipsCompanyName, operationEmailAddress } from '../../../../common/constants/contact';
import Urls from '../../../../routes/Urls';
import config from '../../../../config';
import validateEmail from '../../../../common/functions/validateEmail';

const PurchaseOrderEmail = (props: { serviceRequest: ServiceRequest; modalProps: SimpleModalProps }) => {
  const { serviceRequest, modalProps } = props;

  const organizationName = serviceRequest.metadata?.organization?.metadata?.organizationName;
  const serviceRequestShortId = serviceRequest.metadata?.shortId;
  const poAmount = serviceRequest.metadata?.poAmount;

  const locationName = serviceRequest.metadata?.location?.metadata?.locationDisplayName;
  const locationAddressAsOneLine = formatAddressAsOneLine(serviceRequest.metadata?.location?.address);
  const parentAddressOnOneLine = serviceRequest?.metadata?.location?.metadata?.parentLocation?.address;

  const contactName = serviceRequest.metadata?.mainContactUser?.metadata?.fullName;
  const contactPhone = serviceRequest.metadata?.mainContactUser?.metadata?.phoneNumberWithCountryCode;
  const additionalContacts = undefined;

  const locationMessage = serviceRequest.metadata?.location?.clientToProviderDispatchNote?.metadata?.message;
  const equipmentMessage = serviceRequest.metadata?.equipment?.clientToProviderDispatchNote?.metadata?.message;

  const equipment = serviceRequest?.incidents?.[0]?.metadata?.equipment;
  const category = equipment?.specMetadata?.category?.metadata?.name || '';

  const model = equipment?.specMetadata?.model;
  const manufacturer = equipment?.specMetadata?.manufacturer;
  const serialNumber = equipment?.metadata?.serialNumber;

  const serviceRequestTitle = serviceRequest.metadata?.title;
  const serviceRequestDescription = serviceRequest.metadata?.description;
  const incidents = serviceRequest?.incidents;

  const assignedProviderEmail = serviceRequest.metadata?.provider?.provider?.dispatchEmail;
  const assignedProviderPhone = serviceRequest.metadata?.provider?.provider?.dispatchPhone;
  const serviceRequestId = serviceRequest?.metadata?.serviceRequestId;

  const emailData = [
    `PO#: ${serviceRequestShortId}`,
    `PO Amount: ${centsToMoney(poAmount)}`,
    `Location Name: ${locationName}`,
    `Location Address: ${locationAddressAsOneLine}`,
    `Parent Address: ${parentAddressOnOneLine}`,
    `Contact: ${contactName}`,
    `Contact Phone: ${contactPhone}`,
    `Additional contacts: ${additionalContacts}`,
    `Notes regarding location: ${locationMessage}`,
    `Notes regarding equipment to be serviced: ${equipmentMessage}`,
    `Equipment Class: ${category}`,
    `Make: ${manufacturer}`,
    `Model: ${model}`,
    `Serial Number / Identification Number: ${serialNumber}`,
    `Title: ${serviceRequestTitle}`,
    `Description of Problem: ${serviceRequestDescription}`,
    `Initial Problem Description: ${arrayToGrammaticalStringOmittingEmptyValues(deduplicateServiceRequestSymptoms(incidents))}`,
  ];

  // from outbound dispatchEmailToProvider.hbs
  const defaultHtml = `
  <div style="font-size: 14px;">
  <p style="margin-bottom: 15px">Hello, we need to place a service request for  ${organizationName}</p>
 ${emailData.filter((x) => !x.includes('undefined') && !x.includes('null')).join('<br>')}
  <p style="margin-top: 15px;">For faster payment please email all invoices to: ${operationEmailAddress}</p>
  <p style="margin-top: 15px;">Bill to: </p>
  ${organizationName} <br>
  C/O ${equipsCompanyName} <br>
  ${equipsAddressLine1} <br>
  ${equipsCityStateZip}
  <p style="margin-top: 15px;">Thank you!</p>
  ${equipsCompanyName} Team
  </div>
  `;

  const [{ to, subject }, setState] = useState({
    to: assignedProviderEmail ?? `Email address not found for this provider. Add email address or call : ${assignedProviderPhone}`,
    subject: `PO for Equips Service Request ${serviceRequestShortId}`,
  });
  const [htmlMessage, setHtmlMessage] = useState(defaultHtml);
  const { url } = config.host;
  const [postPurchaseOrderEmailNotification, { saving }] = useMutation(
    () =>
      postNotification({
        metadata: {
          meetsPreferences: true, // need to set to true to bypass pref
          templateName: 'purchaseOrderEmail',
          data: JSON.stringify({ to, subject, htmlMessage }),
          entityId: serviceRequestId,
          entityLabel: EntityLabelEnum.ServiceRequest,
          notificationMethod: NotificationMethodEnum.Email,
          requestedEmailAddress: to,
        },
      }),
    {
      onCompleted: (data) => {
        modalProps?.handleClose?.();
        const notificationId = data?.post?.notificationId ?? '';
        postNote({
          metadata: {
            authorizationLevel: NoteAuthorizationLevel.EquipsOnly,
            parentId: serviceRequestId ?? '',
            parentLabel: EntityLabelEnum.ServiceRequest,
            message: `Purchase order email sent for this service request. Notification: ${url}${Urls.NOTIFICATIONS}/${notificationId}`,
          },
        });
      },
      successMessage: 'PO email sent',
    },
  );

  const pdfRef = useRef(null);
  const printToPdf = useReactToPrint({
    pageStyle: '@page { size: 8.5in 11in; margin: .6in; } * { print-color-adjust: exact; }',
    copyStyles: true,
    content: () => pdfRef.current,
  });

  const PDF = (
    <div ref={pdfRef}>
      <div className="flex justify-between" style={{ fontFamily: 'Calibri, sans-serif' }}>
        <div className="text-sm leading-tight">
          <b>
            {equipsCompanyName} <br />
          </b>
          {equipsAddressLine1} <br />
          {equipsCityStateZip} <br />
          {equipsPhoneNumber}
        </div>
        <img alt="Equips Logo" className="ml-auto object-cover" src={Logo} style={{ maxHeight: 50 }} />
      </div>
      <div className="my-4 border-t border-black" />
      <div className="" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(htmlMessage) }}></div>
    </div>
  );

  return (
    <SimpleModal {...modalProps}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          postPurchaseOrderEmailNotification();
        }}
      >
        <div className="-mb-4 mt-4 px-1"></div>
        <FormFields
          handleChange={({ target: { id, value } }) => setState((state) => ({ ...state, [id]: value }))}
          fields={[
            {
              fields: [
                {
                  label: 'To',
                  id: 'to',
                  required: true,
                  type: fieldTypes.text,
                  value: to,
                  valid: validateEmail(to),
                },
                {
                  label: 'Subject',
                  id: 'subject',
                  type: fieldTypes.text,
                  required: true,
                  value: subject,
                },
              ],
            },
          ]}
        />
        <Label id="purchaseOrderEmailMessage" label="Message *" />
        <RichTextEditor defaultValue={htmlMessage} onChange={setHtmlMessage} />
        <div hidden>{PDF}</div>
        <div className="my-4 flex justify-between">
          <Button blue onClick={printToPdf} type="button">
            Download as PDF
          </Button>
          <Button loading={saving} type="submit" disabled={!validateEmail(to) || !subject}>
            Send Email
          </Button>
        </div>
      </form>
    </SimpleModal>
  );
};

export default PurchaseOrderEmail;
