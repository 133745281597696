import React from 'react';
import { Maybe, Equipment } from '@equips/entities-schema';
import Label from '../Form/Label';
import { entityIcons } from '../../../graphql/entityIcons';
import Urls from '../../../routes/Urls';

const Icon = entityIcons.equipment;

type DisplayEquipmentType = {
  item: Maybe<Equipment>;
  label?: string;
  labelStyles?: string;
  clickable?: boolean;
  showLabel?: boolean;
};

function InnerContent({ item, label, labelStyles, showLabel }) {
  const { equipmentName, model, manufacturer, serialNumber, equipmentStatus } = item?.metadata || {};

  return (
    <>
      {showLabel && <Label label={label} id="SelectedEquipmentLabel" labelClassName={labelStyles} />}
      <div
        data-testid="serviceRequestEquipmentSelect"
        className="flex items-center w-full px-4 py-2 my-1 space-x-2 text-left text-gray-800 border border-gray-300 rounded-lg bg-gray-50 hover:bg-blue-100"
      >
        <div className="pr-2 text-gray-600">
          <Icon color="inherit" />
        </div>
        <div className="flex flex-wrap w-full">
          <div className="w-full">
            <span>{equipmentName}</span>
          </div>
          <div className="w-full text-gray-600 text-2xs">
            {model && (
              <span className="pr-2">
                <span className="font-semibold">Model:</span> {model}
              </span>
            )}
            {manufacturer && (
              <span className="pr-2">
                <span className="font-semibold">Manufacturer:</span> {manufacturer}
              </span>
            )}
            {serialNumber && (
              <span className="pr-2">
                <span className="font-semibold">Serial number:</span> {serialNumber}
              </span>
            )}
            {equipmentStatus && (
              <span className="pr-2">
                <span className="font-semibold">Status:</span> {equipmentStatus}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default function DisplayEquipment({
  item,
  label = 'Selected Equipment',
  labelStyles,
  clickable = false,
  showLabel = true,
}: DisplayEquipmentType) {
  if (clickable) {
    return (
      <a href={`${Urls.EQUIPMENT}/${item?.metadata?.equipmentId}`} target="_blank" rel="noopener noreferrer">
        <InnerContent item={item} label={label} labelStyles={labelStyles} showLabel={showLabel} />
      </a>
    );
  }
  return <InnerContent item={item} label={label} labelStyles={labelStyles} showLabel={showLabel} />;
}
