import React, { forwardRef } from 'react';
import classNames from 'classnames';
import InputLoader from './InputLoader';
import InputHelper from './InputHelper';

export const inputClassName = ({ isWhite }) => `
text-gray-900
placeholder-gray-500
${isWhite ? 'bg-white' : 'bg-gray-100'}
leading-none
border border-gray-300 
w-full
rounded
outline-none
hover:bg-white hover:border-gray-400
focus:bg-white focus:ring
`;

type InputProps = {
  type?: string;
  id: string;
  helper?: string;
  className?: string;
  value: number | string | undefined;
  loading?: boolean;
  tiny?: boolean;
  isWhite?: boolean;
  [x: string]: any;
};

const Input = forwardRef(
  (
    {
      value = undefined,
      className = '',
      loading = false,
      tiny = false,
      type = 'text',
      id,
      helper = '',
      isWhite = false,
      ...restOfProps
    }: InputProps,
    ref: React.Ref<any>,
  ) => {
    if (loading) return <InputLoader />;

    const size = tiny ? 'py-1' : 'px-2 py-2';

    return (
      <>
        <input
          id={id}
          type={type}
          value={value}
          style={{ ...(tiny ? { paddingLeft: '4px' } : {}) }}
          className={classNames([inputClassName({ isWhite }), size, className])}
          {...restOfProps}
          ref={ref}
        />
        {helper && <InputHelper>{helper}</InputHelper>}
      </>
    );
  },
);

export default Input;
