import { gql } from './graphqlHelperFunctions';

export const deactivateMutation = gql`
  mutation Deactivate($entityId: String!) {
    deactivate(entityId: $entityId)
  }
`;
export const reactivateMutation = gql`
  mutation Reactivate($entityId: String!) {
    reactivate(entityId: $entityId)
  }
`;
export const deleteMutation = gql`
  mutation DeleteMutation($entityId: String!) {
    delete(entityId: $entityId)
  }
`;
