import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { inputClassName } from './Input';
import FormGroup from './FormGroup';
import Label from './Label';
import InputLoader from './InputLoader';
import FormFieldErrorMessage from './FormFieldErrorMessage';

type PhoneNumberProps = {
  discardFormGroup?: boolean;
  inputId?: string;
  fullWidth?: boolean;
  isWhite?: boolean;
  required?: boolean;
  loading?: boolean;
  value?: string;
  placeholder?: string;
  label?: string;
  labelHelper?: string;
  highlight?: boolean;
  onChange: (...args: any[]) => any;
  disabled?: boolean;
};

export default function PhoneNumber({
  discardFormGroup = false,
  isWhite = false,
  loading = false,
  value = '',
  onChange,
  required = false,
  fullWidth = false,
  placeholder = '(800)422-3212',
  label,
  labelHelper = '',
  highlight = false,
  inputId = 'phone',
  disabled = false,
}: PhoneNumberProps) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const validate = () => {
    setError(!isPossiblePhoneNumber(value));
  };

  function InputInners() {
    return (
      <>
        <Label id={inputId} label={label || t('phone')} helper={labelHelper} required={required} />
        {loading ? (
          <InputLoader />
        ) : (
          <>
            <PhoneInput
              id={inputId}
              country="US"
              required={required}
              placeholder={placeholder}
              className={`${highlight && 'border-2 border-red-700'} ${inputClassName({ isWhite })} px-2 py-2`}
              value={value}
              onChange={onChange}
              onBlur={value ? validate : null}
              data-testid={inputId}
              disabled={disabled}
            />
            {error && <FormFieldErrorMessage rule={t('validationWithField', { field: t('phone') })} />}
          </>
        )}
      </>
    );
  }

  if (discardFormGroup) return InputInners();

  return <FormGroup fullWidth={fullWidth}>{InputInners()}</FormGroup>;
}
