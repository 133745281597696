import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebouncePrimitive } from '../../hooks/useDebounce';
import SimpleModal from '../Modal/SimpleModal';
import Button from '../Buttons/Button';

export const duplicateCheckerStates = { emptyValue: 'emptyValue', valid: 'valid', duplicateFound: 'duplicateFound' };

interface DuplicateEntityCheckerProps {
  value?: any;
  // eslint-disable-next-line
  duplicateCheckerFunction: Function;
}

export default function DuplicateEntityChecker({ value = '', duplicateCheckerFunction }: DuplicateEntityCheckerProps) {
  const [state, setState] = useState(duplicateCheckerStates.emptyValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicates, setDuplicates] = useState([]);

  const debouncedValue = useDebouncePrimitive(value, 1000);
  useEffect(() => {
    const getData = async () => {
      const duplicates = await duplicateCheckerFunction({ value: debouncedValue });
      setDuplicates(duplicates);
      setState(duplicates?.length > 0 ? duplicateCheckerStates.duplicateFound : duplicateCheckerStates.valid);
    };

    if (debouncedValue) {
      getData();
    }
  }, [debouncedValue]); //eslint-disable-line

  if (state === duplicateCheckerStates.emptyValue || state === duplicateCheckerStates.valid) return <div />;

  return (
    <div className="text-sm text-gray-700">
      {duplicates.length <= 5 ? (
        duplicates.map((url) => (
          <Link className="block" key={url} target="_blank" to={url}>
            View duplicate
          </Link>
        ))
      ) : (
        <Button tiny blue type="button" onClick={() => setIsModalOpen(true)}>
          Show all duplicates
        </Button>
      )}
      <SimpleModal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} title="Duplicate Providers">
        <div className="p-2 text-sm text-gray-700">
          {duplicates.map((url) => (
            <Link className="block" key={url} target="_blank" to={url}>
              View duplicate
            </Link>
          ))}
        </div>
      </SimpleModal>
    </div>
  );
}
