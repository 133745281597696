import React from 'react';
import { Equipment } from '@equips/entities-schema';
import Extension from '@mui/icons-material/Extension';
import capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { internalUsers } from '../../auth/roles';
import { useAuth } from '../../auth/AuthContext';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../functions/phoneFunctions';
import ContactCard from '../DisplayGuid/ContactCard';

type EquipmentCardProps = { equipment: Equipment | null; placeholder?: string };

export function EquipmentCard(props: EquipmentCardProps) {
  const { t } = useTranslation();
  const { userCan } = useAuth();
  const { pathname } = useLocation();

  const eq = props.equipment;

  const serviceEndDate = eq?.metadata?.serviceEndDate;
  const isPassedServiceEndDate = typeof serviceEndDate === 'number' && dayjs().isAfter(serviceEndDate);
  const serviceEndDateMessage = `${t('serviceEnded')} ${dayjs(serviceEndDate).format('MM/DD/YYYY')}`;

  return (
    <div className="flex items-center gap-3">
      <div className="pr-2">
        <Extension color="inherit" />
      </div>
      {eq === null ? (
        <div>{props.placeholder}</div>
      ) : (
        <div>
          <div className="flex">
            <div className="flex w-full flex-col flex-wrap justify-start sm:flex-row">
              <div className="mb-1 w-full font-semibold ">
                {eq?.metadata?.equipmentName}
                {eq?.metadata?.shortId ? ` (${eq?.metadata?.shortId})` : ''}
              </div>
              <div className="flex w-full flex-col flex-wrap text-sm sm:flex-row">
                {eq?.metadata?.tid && (
                  <span className="pr-3">
                    <span className="font-semibold text-gray-700">{t('tid')}:</span> {eq?.metadata?.tid}
                  </span>
                )}
                {eq?.specMetadata?.model && (
                  <span className="pr-3">
                    <span className="font-semibold text-gray-700">{t('model')}:</span> {eq?.specMetadata?.model}
                  </span>
                )}
                {eq?.specMetadata?.manufacturer && (
                  <span className="pr-3">
                    <span className="font-semibold text-gray-700">{t('manufacturer')}:</span> {eq?.specMetadata?.manufacturer}
                  </span>
                )}
                {eq?.metadata?.serialNumber && (
                  <span className="pr-3">
                    <span className="font-semibold text-gray-700">{t('serialNumber')}:</span> {eq?.metadata?.serialNumber}
                  </span>
                )}

                <span className="pr-3">
                  <span className="font-semibold text-gray-700">{t('status')}:</span>{' '}
                  {capitalize(eq?.metadata?.equipmentStatus ?? t('unknown'))}
                </span>
              </div>
              <div className="text-sm text-gray-700">
                <span className=" font-semibold ">{t('dispatchTo')}: </span>
                {eq?.metadata?.dispatchTo}
              </div>
            </div>
            {userCan([...internalUsers]) && isPassedServiceEndDate && (
              <div className="pt-2 text-xs font-light text-red-700">{serviceEndDateMessage}</div>
            )}
            {pathname === '/' && !!eq?.metadata?.mainContactUser?.metadata && (
              <>
                <div className="mr-2 flex w-full text-sm">
                  <ContactCard userId={eq?.metadata?.mainContactUserId || ''} title={t('contact')}>
                    <div className="flex flex-col">
                      <p>
                        <span className="font-bold">{eq?.metadata?.mainContactUser?.metadata?.fullName} </span>
                        {toDisplayPhoneFromPhoneWithCountryCode(eq?.metadata?.mainContactUser?.metadata?.phoneNumberWithCountryCode)}
                      </p>
                      <p>{eq?.metadata?.mainContactUser?.metadata?.email}</p>
                    </div>
                  </ContactCard>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
