import React, { useState } from 'react';
import HelperPopper from '../Popper';

export default function TableCellExpand(props: { children: React.ReactNode }) {
  const [anchorEl, setAnchorEl] = useState<React.ReactNode>(null);

  return (
    <>
      <button type="button" className="block text-left truncate cursor-pointer" onClick={(event) => setAnchorEl(event.currentTarget)}>
        {props.children}
      </button>

      <HelperPopper handleClose={() => setAnchorEl(false)} open={Boolean(anchorEl)} placement="bottom" anchorEl={anchorEl || <div />}>
        <div className="max-w-md text-sm break-all">{props.children}</div>
      </HelperPopper>
    </>
  );
}
