import React from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import { Link } from 'react-router-dom';
import { Contact, EntityLabelEnum, Location, patchLocation } from '@equips/entities-schema';
import { JsonParam } from 'use-query-params';
import { Translation } from 'react-i18next';
import TableCellCopyData from '../../common/components/DataTable/TableCellCopyData';
import AddressColumn from '../../common/components/Address/AddressColumn';
import Urls from '../../routes/Urls';
import { AuthContextValue, AuthorizationData } from '../../common/auth/AuthContext';
import { clientValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import { TableColumn } from '../../common/components/DataTable';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';
import { customers, internalUsers } from '../../common/auth/roles';
import { defaultColumnWidths } from '../../common/components/DataTable/columns';
import TableCellInlineEditable from '../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../common/components/ViewPage/DisplayDescriptionValue';
import TableCellAdditionalContacts from '../../common/components/DataTable/TableCellAdditionalContacts';
import ClientTag from '../../common/components/Tags/ClientTag';
import { LocationRowActions } from './LocationRowActions';

export function getLocationColumns(
  userCan: AuthContextValue['userCan'],
  auth: AuthorizationData | null | undefined,
): TableColumn<Location>[] {
  return [
    {
      id: 'metadata.shortId',
      Header: <Translation>{(t) => <>{t('number')}</>}</Translation>,
      accessor: (data) => data.metadata?.shortId,
      toText: (data) => data.value,
      Cell: (data) => (
        <div className="flex justify-between">
          <Link
            className="active font-bold"
            data-testid="locationEditButton"
            to={`${Urls.LOCATIONS}/${data.row.original.metadata?.locationId}`}
          >
            {data.value}
          </Link>
          <LocationRowActions {...data}></LocationRowActions>
        </div>
      ),
      width: 110,
    },
    {
      id: 'metadata.locationName',
      Header: <Translation>{(t) => <>{t('name')}</>}</Translation>,
      width: defaultColumnWidths.largeText,
      accessor: (data) => data.metadata?.locationName,
      toText: (data) => data?.value,
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          onSave={(value) =>
            patchLocation({ locationId: data.row.original?.metadata?.locationId, metadata: { locationName: value }, address: {} })
          }
        />
      ),
    },
    {
      id: 'address.line1',
      Header: <Translation>{(t) => <>{t('address')}</>}</Translation>,
      accessor: (data) => data.address?.line1,
      width: 220,
      Cell: (d) => <AddressColumn addressObject={d.row.original?.address} />,
      toText: (d) => formatAddressAsOneLine(d.row.original?.address),
    },
    {
      id: 'address.city',
      Header: <Translation>{(t) => <>{t('city')}</>}</Translation>,
      accessor: (data) => data.address?.city,
    },
    {
      id: 'address.state',
      Header: <Translation>{(t) => <>{t('state')}</>}</Translation>,
      accessor: (data) => data.address?.stateUnabbreviated,
      filterOptions: {
        type: TableFilterTypes.stateSelect,
      },
    },
    {
      id: 'address.zip',
      Header: <Translation>{(t) => <>{t('zip')}</>}</Translation>,
      accessor: (data) => data.address?.zip,
      disableSortBy: true,
    },
    {
      id: 'mailingAddress',
      Header: <Translation>{(t) => <>{t('mailingAddress')}</>}</Translation>,
      accessor: (data) => data.mailingAddress,
      width: 220,
      Cell: (d) => <AddressColumn addressObject={d.value} />,
      toText: (d) => formatAddressAsOneLine(d.value),
      disableSortBy: true,
    },
    {
      id: 'parentLocation',
      Header: <Translation>{(t) => <>{t('parentLocation')}</>}</Translation>,
      disableSortBy: true,
      accessor: (data) => data?.metadata?.parentLocation,
      Cell: (d) => {
        return (
          <Link className="active" data-testid="parentLocation" target="_blank" to={`${Urls.LOCATIONS}/${d.value?.metadata?.locationId}`}>
            {d?.value?.metadata?.locationDisplayName}
          </Link>
        );
      },
      filterOptions: {
        type: TableFilterTypes.locationSelect,
      },
    },
    {
      id: 'sublocations',
      Header: <Translation>{(t) => <>{t('sublocations')}</>}</Translation>,
      disableSortBy: true,
      accessor: (data) => data?.metadata?.sublocations,
      Cell: (d) => {
        return (
          <>
            {d?.value?.map((sublocation) => (
              <Link
                key={sublocation?.metadata?.locationId}
                className="active block"
                data-testid="parentLocation"
                target="_blank"
                to={`${Urls.LOCATIONS}/${sublocation?.metadata?.locationId}`}
              >
                {sublocation?.metadata?.locationDisplayName}
              </Link>
            ))}
          </>
        );
      },
    },
    {
      id: 'isSublocation',
      filterOptions: {
        label: <Translation>{(t) => <>{t('showSublocationsOnly')}</>}</Translation>,
        type: TableFilterTypes.select,
        options: [
          { value: '', label: 'No' },
          { value: 'true', label: 'Yes' },
        ],
      },
    },
    {
      id: 'contacts',
      Header: <Translation>{(t) => <>{t('contacts')}</>}</Translation>,
      disableSortBy: true,
      accessor: (data) => {
        return data?.metadata?.contacts;
      },
      Cell: (d) => {
        const contacts = (d?.value || []) as Contact[];
        if (!contacts.length) return <></>;
        return <TableCellAdditionalContacts contacts={contacts} />;
      },
    },
    {
      id: 'tagMetadata.tagId',
      Header: <Translation>{(t) => <>{t(userCan(internalUsers) ? 'clientTags' : 'tags')}</>}</Translation>,
      accessor: (data) => data.metadata?.clientTags,
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      filterOptions: {
        type: TableFilterTypes.clientTagsMultiSelect,
        prependedOptions: [{ label: 'None', value: 'none' }],
        defaultValue: [],
        filterType: JsonParam,
        entityLabel: EntityLabelEnum.Location,
      },
      toText: (data) => data?.value?.map((t) => t.name)?.join(',') || '',
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value?.map((item) => ({ value: item?.tagId, label: item?.name, style: item?.style }))}
            formatter={(value) => {
              return (
                <div className="inline-block overflow-x-auto">
                  {value?.map(({ value, label, style }) => <ClientTag key={value} label={label} style={style} value={value} />) || ''}
                </div>
              );
            }}
            inputType={descriptionListInputTypes.clientTagsMultiSelect}
            entityLabel={EntityLabelEnum.Location}
            label=""
            onSave={async (selections) => {
              const clientTags = selections ? selections?.map((selection) => ({ tagId: selection?.value })) : [];
              await patchLocation({
                locationId: data.row?.original?.metadata?.locationId,
                metadata: { clientTags },
                address: {},
              });
              data.refetchData();
            }}
          />
        );
      },
    },
    ...(!userCan(customers)
      ? [
          {
            id: 'referredByOrg',
            filterOptions: {
              label: <Translation>{(t) => <>{t('referredByMyOrganization')}</>}</Translation>,
              type: TableFilterTypes.select,
              options: [
                { label: 'Any', value: '' },
                { label: 'Yes', value: 'true' + auth?.organizationId },
                { label: 'No', value: 'false' + auth?.organizationId },
              ],
            },
          },
        ]
      : []),
    ...(userCan(internalUsers)
      ? [
          {
            id: 'metadata.organizationId',
            Header: <Translation>{(t) => <>{t('organization')}</>}</Translation>,
            accessor: (data) => data.organizationMetadata?.organizationName,
            filterOptions: {
              type: TableFilterTypes.organizationMultiSelect,
              typeOfOrganizationToFind: clientValueForFilter,
              defaultValue: [],
              filterType: JsonParam,
            },
          },
        ]
      : []),
    {
      id: 'locationId',
      Header: <Translation>{(t) => <>{t('locationId')}</>}</Translation>,
      accessor: (data) => data.metadata?.locationId,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (data) => data.value || '',
      Cell: (data) => <TableCellCopyData data={data.value}>{data.value}</TableCellCopyData>,
    },
  ];
}
