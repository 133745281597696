import { gql } from '../graphqlHelperFunctions';
import { postToNotesEndpoint } from '../postToEndpoint';
import { deactivateMutation } from '../commonMutations';
import { allowedNoteParentLabels } from '../enums';
import { cleanObject } from '../../common/functions/cleanObject';

export const deactivateNote = async ({ entityId }) => postToNotesEndpoint({ query: deactivateMutation, variables: { entityId } });

export const createNote = async (args) => {
  const { parentId, parentLabel, note, message, tags, authorizationLevel, createdAt, modifiedAt } = args;
  const arrayOfAllowedNoteParentLabels = Object.keys(allowedNoteParentLabels);

  if (!arrayOfAllowedNoteParentLabels.includes(parentLabel)) {
    throw new Error('The parentLabel field needs to be one of these: ' + JSON.stringify(allowedNoteParentLabels));
  }

  return postToNotesEndpoint({
    query: gql`
      mutation ($metadata: NoteMetadataPostInput!) {
        post(metadata: $metadata) {
          metadata {
            noteId
          }
        }
      }
    `,
    variables: cleanObject({
      metadata: {
        parentId,
        parentLabel,
        tags,
        message: message || note,
        authorizationLevel,
        ...(createdAt ? { createdAt } : {}),
        ...(modifiedAt ? { modifiedAt } : {}),
      },
    }),
  });
};

export const patchNotesMessage = async ({ noteId, message }) => {
  return postToNotesEndpoint({
    query: gql`
      mutation ($noteId: String!, $metadata: NoteMetadataPatchInput!) {
        patch(noteId: $noteId, metadata: $metadata) {
          metadata {
            noteId
          }
        }
      }
    `,
    variables: { noteId, metadata: { noteId, message: message ? message : null } },
  });
};
