export async function waitUntil<T>(
  callback: (() => T) | (() => Promise<T>),
  { logErrors = false, timeout = 30000, interval = 1000 } = {},
): Promise<T> {
  if (typeof callback !== 'function') {
    throw new TypeError('Received `callback` arg must be a function');
  }

  return new Promise((resolve) => {
    let intervalId,
      promiseStatus = 'idle';

    const overallTimeoutTimer = setTimeout(handleTimeout, timeout); // eslint-disable-line
    intervalId = setInterval(async () => await checkCallback(), interval); // eslint-disable-line

    function onDone(error, result) {
      clearTimeout(overallTimeoutTimer);
      clearInterval(intervalId);

      if (error) {
        resolve(error);
      } else {
        resolve(result);
      }
    }

    async function checkCallback() {
      if (promiseStatus === 'pending') return;

      try {
        promiseStatus = 'pending';
        const result = await callback();

        if (!result) {
          throw new Error('Missing result');
        } else {
          onDone(null, result);
        }
      } catch (error) {
        if (logErrors) {
          console.error(error);
        }
        promiseStatus = 'idle';
      }
    }

    function handleTimeout() {
      onDone(new Error('Timed out in waitUntil.'), null);
    }
  });
}
