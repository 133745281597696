import toTitleCase from 'lodash/startCase';
import { Note, Maybe, Organization, integerRepresentingUnlimitedForOptions } from '@equips/entities-schema';
import { formatAddressAsOneLine } from '@equips/common-resources';
import { toDateStringFromUnixMillisecondTimestamp, toUnixMillisecondsFromString } from './dateFunctions';
import {
  toESTDateStringFromUnixMillisecondTimestamp,
  toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST,
} from './expensiveDateFunctions';
import { centsToMoney, convertExcelMoneyFieldToCents } from './moneyFunctions';
import { arrayToGrammaticalList } from './arrayToGrammaticalList';

export const standardDateSerializerAndDeserializer = {
  serializer: (unixTimestamp: number) => toDateStringFromUnixMillisecondTimestamp(unixTimestamp),
  deserializer: (stringValue: string) => toUnixMillisecondsFromString(stringValue),
};
export const standardESTDateSerializerAndDeserializer = {
  serializer: (unixTimestamp: number) => toESTDateStringFromUnixMillisecondTimestamp(unixTimestamp),
  deserializer: (stringValue: string) => toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST(stringValue),
};
export const standardMoneySerializerAndDeserializer = {
  serializer: (amountInCents: number) => (amountInCents && centsToMoney(amountInCents)) || null,
  deserializer: (stringValue: string) => convertExcelMoneyFieldToCents(stringValue),
};
export const standardArraySerializerAndDeserializer = {
  serializer: (arr: Maybe<string>[]) => arrayToGrammaticalList(arr),
};
export const standardNoteSerializerAndDeserializer = {
  serializer: (notes: Maybe<Note>[]) => {
    return (notes || [])
      .map((note) => {
        const author = note?.metadata?.createdByUser?.metadata?.fullName || 'Unknown Author';
        const message = note?.metadata?.message || '';
        const timestamp = note?.metadata?.createdAt;

        return `${author} - ${toDateStringFromUnixMillisecondTimestamp(timestamp)} \n${message}`;
      })
      .join('\n\n');
  },
};

const displayEquipmentOptionValue = (value: any): string => {
  if (typeof value === 'boolean') {
    return value === true ? 'Yes' : 'No';
  }

  if (!value) return '';

  if (value === integerRepresentingUnlimitedForOptions) return 'Unlimited allowed';

  return String(value);
};

export const standardEntityOptionsSerializerAndDeserializer = {
  serializer: (options = {}): string => {
    if (!options) return '';

    return Object.entries(options).reduce((acc, [key, value]) => {
      const stringValue = displayEquipmentOptionValue(value);
      if (!stringValue) return acc;

      return (acc ? `${acc}; ` : '') + `${toTitleCase(key)}: ${stringValue}`;
    }, '');
  },
};
export const standardOrganizationSerializerAndDeserializer = {
  serializer: (organization?: Maybe<Organization>): string => {
    const organizationDescription = [organization?.metadata?.organizationName, organization?.metadata?.shortId].filter((o) => o).join(' ');
    const organizationBillingAddress = organization?.billingAddress ? `\n\n${formatAddressAsOneLine(organization?.billingAddress)}` : '';

    return `${organizationDescription}${organizationBillingAddress}`;
  },
};
