import React, { useState, useEffect, lazy } from 'react';
import pRetry from 'p-retry';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { UserAuthorizationRoleEnum } from '@equips/entities-schema';
import Modal from '../components/Modal/Modal';
import isInitialized from '../functions/isInitialized';
import { getUserForAuthorization } from '../../graphql/queries/userGraphQLQueries';
import Urls from '../../routes/Urls';
import Button from '../components/Buttons/Button';
import { patchUser } from '../../graphql/mutations/UserMutations';
import { AuthorizationData, useAuth } from '../auth/AuthContext';
import { addUserDataToHeap } from '../functions/configureHeap';
import configureAmplify from '../functions/configureAmplify';
import { getHMACHash } from '../functions/intercomHelpers';
import { hasAasTagOrOrganizationId } from '../functions/aasHelpers';
import useRouter from './useRouter';
import useMutation from './useMutation';
import { useInterval } from './useInterval';

const PhoneNumber = lazy(() => import('../components/Form/PhoneNumber'));
const checkIfUserIsOnline = () => window.navigator.onLine;

const waitThirtySecondsWhileLoggedIn = 30000;
const waitFiveSecondsWhileLoggedOut = 5000;

const userDoesNotExistMessage = 'User does not exist. They should enter onboarding';
const serverErrorMessage = 'Server returned an error.';
const apiGatewayTimeoutMessage = 'Request failed with status code 502';

function PhoneNumberModal() {
  const { auth } = useAuth();
  const [phoneNumberWithCountryCode, setPhoneNumberWithCountryCode] = useState('');

  const [updateUser, { saving }] = useMutation(patchUser, {
    variables: { userId: auth?.userId, patchFields: { phoneNumberWithCountryCode } },
    onCompleted: () => window.location.reload(),
  });

  return (
    <Modal
      testId="phoneNumberModal"
      isDismissible={false}
      color="bg-gray-200"
      title="We're missing your phone number"
      subtitle="Please enter the best phone number for you to continue."
    >
      <form
        className="p-4"
        onSubmit={(event) => {
          event.preventDefault();

          if (phoneNumberWithCountryCode && phoneNumberWithCountryCode.length > 4) {
            updateUser();
          }
        }}
      >
        <PhoneNumber
          inputId="phoneRequiredModal"
          required
          value={phoneNumberWithCountryCode}
          onChange={(value) => setPhoneNumberWithCountryCode(value)}
          fullWidth
          label="Your phone number"
        />
        <div className="mt-4 flex justify-end">
          <Button blue type="submit" loading={saving}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}

/** Log user in on pageload by verifying session with Cognito & querying user data from the API. */
export default function useLogin(fakeAuth?: AuthorizationData) {
  const [isOnline, setIsOnline] = useState(checkIfUserIsOnline());
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authorizationData, setAuthorizationData] = useState<AuthorizationData | null>(null);
  const [userNoLongerIsLoggedIn, setUserNoLongerIsLoggedIn] = useState(false);
  const [HMACHash, setHMACHash] = useState('');

  const { history, location } = useRouter();
  const redirectToOnboarding = () => history.push(Urls.ONBOARDING);
  const redirectToAasClaims = () => history.push(Urls.AAS_CLAIMS);

  useInterval(
    () => {
      (async () => {
        configureAmplify();
        if (authorizationData) {
          let userNoLongerIsLoggedIn: boolean;

          try {
            const session = await Auth.currentSession();
            const userSub = session.getIdToken().payload.sub;
            const userLoggedInAsADifferentUserInADifferentTab = userSub && userSub !== authorizationData.userId;

            if (userLoggedInAsADifferentUserInADifferentTab) {
              window.location.reload();
            }

            userNoLongerIsLoggedIn = false;
          } catch (e) {
            userNoLongerIsLoggedIn = true;
          }
          setUserNoLongerIsLoggedIn(userNoLongerIsLoggedIn);
        }
      })();
      setIsOnline(checkIfUserIsOnline());
    },
    userNoLongerIsLoggedIn ? waitFiveSecondsWhileLoggedOut : waitThirtySecondsWhileLoggedIn,
  );

  useEffect(() => {
    const fetchHash = async (email: string) => {
      const currentHash = await getHMACHash(email);
      setHMACHash(currentHash);
    };

    if (authorizationData?.email) {
      fetchHash(authorizationData?.email);
    }
  }, [authorizationData]);

  useEffect(() => {
    if (authorizationData && HMACHash) {
      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: 'p5p8f365',
          name: authorizationData?.fullName || '',
          email: authorizationData?.email || '',
          organization_name: authorizationData?.organization?.metadata?.organizationName,
          client_organization_type: authorizationData?.organization?.metadata?.clientOrganizationType,
          user_hash: HMACHash,
        });
      }
    }
  }, [authorizationData, HMACHash]);

  useEffect(() => {
    (async () => {
      let authentication: CognitoUserSession | null = null;
      try {
        authentication = await Auth.currentSession();
      } catch (e) {
        console.error(e);
        // start up intercom for logged out users
        if (window.Intercom) {
          window.Intercom('shutdown');
          window.Intercom('boot', {
            app_id: 'p5p8f365',
          });
        }
      }

      let authorization: AuthorizationData | null = null;

      if (fakeAuth) {
        const { initialized } = isInitialized(fakeAuth);
        authorization = { ...fakeAuth, initialized };
      } else if (authentication) {
        try {
          const sub = authentication.getIdToken()?.payload?.sub;

          if (!sub) {
            await Auth.signOut();
            throw new Error('User does not have a sub (userId) returned from cognito. Avoiding an api call with null/undefined');
          }

          const { errors: graphQLErrors, data } = await pRetry(async () => await getUserForAuthorization({ sub }), { retries: 1 });
          const graphQLErrorName = graphQLErrors && graphQLErrors[0] && graphQLErrors[0].name;

          if (graphQLErrorName === 'NotFoundError' && graphQLErrors[0].message?.includes('User')) {
            throw new Error(userDoesNotExistMessage);
          } else if (graphQLErrorName === 'Internal server error:') {
            throw new Error(serverErrorMessage);
          }

          const user = data?.users?.data[0];
          addUserDataToHeap(sub, user);
          const { initialized } = isInitialized(user?.metadata);

          authorization = { ...user.metadata, initialized, userId: sub };
        } catch (error) {
          console.error(error);
          const userId = authentication.getAccessToken().payload.username;

          if (error.message === apiGatewayTimeoutMessage) {
            authorization = { serverError: error.message };
          } else if (error.message === userDoesNotExistMessage) {
            authorization = { userId, initialized: false };
          } else if (error.message === serverErrorMessage) {
            throw new Error('This is most likely due to an incorrect graphql query.');
          }
        }
      }

      if (authorization && !authorization.initialized) redirectToOnboarding();

      setIsOnline(checkIfUserIsOnline());
      setAuthorizationData(authorization);
      setIsAuthenticating(false);

      const isAasUser = hasAasTagOrOrganizationId(authorization);
      const isRedirectingToDashboard = location.pathname === Urls.HOME;
      const isSGA = authorization?.authorizationRole === UserAuthorizationRoleEnum.SuperGlobalAdmin;
      const shouldRedirectToAasClaims = !isSGA && isRedirectingToDashboard && isAasUser;

      if (shouldRedirectToAasClaims) redirectToAasClaims();
    })();
  }, []);

  const AuthWarningModals = () => {
    return (
      <>
        {userNoLongerIsLoggedIn && (
          <Modal
            color="bg-gray-200"
            isDismissible={false}
            isOpen={true}
            title="Not logged in"
            subtitle="It looks like you are no longer logged in."
          >
            <p className="p-4">
              Please{' '}
              <a target="_blank" rel="noopener noreferrer" href={`${Urls.HOME}`}>
                login
              </a>{' '}
              to resume using the app.
            </p>
          </Modal>
        )}
        {!isOnline && (
          <Modal
            color="bg-gray-200"
            isDismissible={false}
            isOpen={true}
            title="No internet"
            subtitle="It looks like you are not connected to the internet"
          >
            <p className="p-4">Please reconnect to the internet to use the app.</p>
          </Modal>
        )}

        {!isAuthenticating &&
          authorizationData &&
          authorizationData.initialized &&
          authorizationData.authorizationRole !== UserAuthorizationRoleEnum.EmailOnlyAuth &&
          !authorizationData.phoneNumberWithCountryCode && <PhoneNumberModal />}
      </>
    );
  };

  return { AuthWarningModals, authorizationData, isAuthenticating };
}
