import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L, { LatLngLiteral, Marker as LeafletMarker } from 'leaflet';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import { GeolocationType } from '@equips/entities-schema';
import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const center = {
  lat: 39.5,
  lng: -98.35,
};

const nominatimAPI = 'https://nominatim.openstreetmap.org/';

function MyComponent({ initialPosition, position, setPosition, setInitialPosition }) {
  const markerRef = useRef<LeafletMarker>(null);
  const map = useMap();

  map.locate({ setView: true });

  map.on('click', () => {
    setPosition(initialPosition);
  });

  map.on('locationfound', (e) => {
    setInitialPosition(e.latlng);
    setPosition(e.latlng);
  });

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    [],
  );

  return position === null ? null : (
    <Marker position={position} draggable={true} eventHandlers={eventHandlers} ref={markerRef} autoPan={true} />
  );
}

export type LeafletMapData = {
  initialPosition: LatLngLiteral | null;
  selectedPosition: LatLngLiteral | null;
};

type LeafletMapProps = {
  onChange: (value: GeolocationType) => unknown;
};

const LeafletMap = (props: LeafletMapProps) => {
  const [initialPosition, setInitialPosition] = useState<LatLngLiteral | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<LatLngLiteral | null>(null);

  const { data: reverseGeocodeAddress } = useQuery(
    ['reverseGeocode', selectedPosition],
    () => axios.get(`${nominatimAPI}/reverse?format=jsonv2&lat=${selectedPosition?.lat}&lon=${selectedPosition?.lng}`),
    {
      enabled: !!selectedPosition,
      select: (data) => data?.data?.display_name,
    },
  );

  useEffect(() => {
    if (selectedPosition && reverseGeocodeAddress) {
      props.onChange({ lat: selectedPosition?.lat, lng: selectedPosition?.lng, displayName: reverseGeocodeAddress });
    }
  }, [selectedPosition, reverseGeocodeAddress]);

  return (
    <>
      <div className="h-56 max-w-5xl lg:h-96">
        <MapContainer
          attributionControl={false}
          center={center}
          zoom={50}
          fullscreenControl={true}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MyComponent
            initialPosition={initialPosition}
            setInitialPosition={setInitialPosition}
            position={selectedPosition}
            setPosition={setSelectedPosition}
          />
        </MapContainer>
      </div>
      {!reverseGeocodeAddress ? (
        <div className="py-4">
          {[0, 0, 0].map((_, i) => (
            <Skeleton key={i} />
          ))}
        </div>
      ) : (
        <div className="flex items-start gap-4 py-4 text-sm text-gray-800">
          <div>
            {reverseGeocodeAddress}
            <p className="text-equipsLightBlue">
              Lat: {selectedPosition?.lat}, Long: {selectedPosition?.lng}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default LeafletMap;
