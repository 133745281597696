import React, { useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from '@tanstack/react-query';
import createRandomString from '../../functions/createRandomString';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import MultipleSelectChip from '../Chips/MultipleSelectChip';
import { getCategory } from '../../../graphql/queries/categoryQueries';

type SymptomSelectProps = {
  label?: string;
  categoryId?: string;
  changeSymptoms: (...args: any[]) => any;
  selectedSymptoms?: { [x: string]: boolean };
  fullWidth?: boolean;
  required?: boolean;
  symptoms?: string[];
};

export default function SymptomsSelect({
  selectedSymptoms = {},
  categoryId = '',
  changeSymptoms,
  fullWidth = false,
  label = 'Type of Issue',
  required = false,
  symptoms: propsSymptoms,
}: SymptomSelectProps) {
  const symptomHtmlId = useMemo(() => `symptom${createRandomString(5)}`, []);

  const { data, isFetching } = useQuery(['getCategory', categoryId], () => getCategory({ categoryId }), {
    enabled: !!categoryId && !propsSymptoms?.length,
    select: (data) => data?.data,
  });

  const symptoms = propsSymptoms ?? data?.categories?.data?.[0]?.metadata?.symptoms ?? [];

  if (!propsSymptoms?.length && isFetching) {
    return <Skeleton />;
  }

  return (
    <FormGroup fullWidth={fullWidth}>
      <Label required={required} id={symptomHtmlId} label={label} />
      <MultipleSelectChip
        chipTestId="symptomsSelectTestId"
        chipSetType="filter"
        setSelected={(objectOfSelectedPills) => changeSymptoms(objectOfSelectedPills)}
        selected={selectedSymptoms}
        options={symptoms.map((symptom) => ({ value: symptom, label: symptom }))}
      />
    </FormGroup>
  );
}
