export default {
  stage: 'dev',
  isProd: false,
  apiGateway: {
    name: `equips-app`,
    region: 'us-east-1',
    endpoint: `https://1d40xqh2jb.execute-api.us-east-1.amazonaws.com/dev`,
  },
  cognito: {
    mandatorySignIn: true,
    region: 'us-east-1',
    userPoolId: 'us-east-1_upt87tVa2',
    identityPoolId: 'us-east-1:28db12d0-b299-4206-aa8e-60452206c467',
    userPoolWebClientId: '4s0ebo3hs5u69gah17djfh1l5t', // App client ID
  },
  heap: {
    apiKey: '3358892284',
  },
  users: {
    systemImportUserGuid: 'b2c02780-ef99-4292-8ec0-f1f5bef296bd',
  },
  host: {
    url: 'https://dev.app.equips.com',
  },
  googleAnalytics: {
    trackingID: 'UA-214493757-1',
  },
  flipt: {
    url: 'https://flipt.equips.com',
  },
  defaultSRDetailsForSoftwareIssues: {
    organizationId: 'b7883211c3f24929830b56568aab0ee5',
    locationId: '16314ce60137430393969e298cb95e63',
    equipmentId: 'f67fa0666fd84b57bcd6bf7b30821bdd',
  },
};
