import React, { useEffect, useState } from 'react';
import { Location } from '@equips/entities-schema';
import { formatAddressAsOneLine } from '@equips/common-resources';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import Store from '@mui/icons-material/Store';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';
import { ActionButtonProps, actionButtonTextColors } from '../Buttons/ActionButton';
import Checkbox from '../Form/Checkbox';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../functions/phoneFunctions';
import ContactCard from '../DisplayGuid/ContactCard';

export type LocationCardProps = {
  onClick?: (value: string | null) => any;
  onChange?: (selected: boolean) => any;
  location?: Location | null;
  emptyText?: string;
  className?: string;
  selected?: boolean;
  expanded?: boolean;
  children?: JSX.Element[];
  actions?: ActionButtonProps[];
};

export default function LocationCard({
  onClick,
  onChange,
  location,
  emptyText,
  className = '',
  selected = false,
  expanded = false,
  children,
  actions,
  ...restOfProps
}: LocationCardProps) {
  const value = location?.metadata?.locationId ?? null;
  const [isOpen, toggleItemOpen] = useState<boolean>(false);

  useEffect(() => {
    toggleItemOpen(expanded);
  }, [expanded]);

  const { pathname } = useLocation();

  const card = (
    <>
      {onChange ? (
        <Checkbox
          id="multiselect"
          checked={selected}
          onChange={(e) => {
            e.stopPropagation();
            onChange?.(e.currentTarget.checked || false);
          }}
        />
      ) : (
        <Store className="mr-3 text-gray-700" />
      )}
      <div className="w-full flex-1" style={{ minWidth: 0 }}>
        <div>{location?.metadata?.locationDisplayName || emptyText || 'Unnamed Location'}</div>
        <div className="text-xs">{formatAddressAsOneLine(location?.address)}</div>
      </div>
      {pathname === '/' && !!location?.metadata?.mainContactUser?.metadata?.fullName && (
        <>
          <div className="flex w-1/2 flex-col text-sm">
            <div className="flex">
              <ContactCard userId={location?.metadata?.mainContactUserId || ''} title="Location Contact">
                <div className="flex flex-col">
                  <p>
                    <span className="font-bold">{location?.metadata?.mainContactUser?.metadata?.fullName} </span>
                    {toDisplayPhoneFromPhoneWithCountryCode(location?.metadata?.mainContactUser?.metadata?.phoneNumberWithCountryCode)}
                  </p>
                  <p>{location?.metadata?.mainContactUser?.metadata?.email}</p>
                </div>
              </ContactCard>
            </div>
          </div>
        </>
      )}
      {(actions || []).map(({ text, Icon, color, onClick }) => (
        <IconButton
          key={`locationCardAction-${text}`}
          title={text}
          className={`${color ? actionButtonTextColors[color] : actionButtonTextColors['blue']}`}
          onClick={(e) => {
            e.stopPropagation();
            onClick?.(e);
          }}
          size="large"
        >
          <Icon />
        </IconButton>
      ))}
      {onClick && children && children.length > 0 && (
        <IconButton
          title={isOpen ? 'Collapse sublocations' : 'Expand sublocations'}
          className=""
          onClick={(e) => {
            e.stopPropagation();
            toggleItemOpen(!isOpen);
          }}
          size="large"
        >
          {isOpen ? <IndeterminateCheckBoxOutlined /> : <AddBoxOutlined />}
        </IconButton>
      )}
    </>
  );

  return (
    <>
      {onClick ? (
        <button
          title="Select this location"
          data-testid="locationDialogButton"
          data-locationid={value}
          type="button"
          onClick={() => onClick?.(value)}
          className={`flex w-full items-center rounded-lg border border-gray-400 px-4 py-2 text-left hover:bg-gray-200 ${className}`}
          {...restOfProps}
        >
          {card}
        </button>
      ) : (
        <div
          data-testid="locationDialogButton"
          data-locationid={value}
          className={`flex w-full items-center text-left text-gray-700 ${className}`}
          {...restOfProps}
        >
          {card}
        </div>
      )}
      {isOpen && children}
    </>
  );
}
