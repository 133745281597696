import React from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import { Link } from 'react-router-dom';
import { deactivateOrganization, reactivateOrganization, deleteOrganization, Organization } from '@equips/entities-schema';
import Urls from '../../routes/Urls';
import AddressColumn from '../../common/components/Address/AddressColumn';
import statesAsObject from '../../common/constants/statesAsObject';
import DropDown from '../../common/components/DropDown';
import { clientOrganizationTypes, getEnumDisplayName, organizationIndustryTypes } from '../../graphql/enums';
import StackedColumn from '../../common/components/Table/StackedColumn';
import { TableFilterTypes } from '../../common/components/Table/Filters';
import useMutation from '../../common/hooks/useMutation';
import waitSynchronously from '../../common/functions/waitSynchronously';
import { clientValueForFilter, providerValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import DisplayTime from '../../common/components/Date/DisplayTime';
import { toDateStringFromUnixMillisecondTimestamp } from '../../common/functions/dateFunctions';
import { defaultTableWidths } from '../../common/components/Table/tableDefaults';
import { buildQueryString } from '../../common/functions/buildQueryString';
import { TableColumn } from '../../common/components/DataTable';
import { AuthContextValue } from '../../common/auth/AuthContext';
import { getSubproducers } from './businessLogic/getSubproducers';
import TierIndicator from './components/TierIndicator';

export default function OrganizationsColumns(
  userCan: AuthContextValue['userCan'],
  internalUsers,
  equipsUsers,
  roles,
  onSideFormSuccess,
): TableColumn<Organization>[] {
  function OrganizationActions({ entityId, isExternalProvider, deactivatedAt }) {
    const [deleteMutation, { saving: deleteSaving }] = useMutation(deleteOrganization, {
      variables: { entityId },
      successMessage: 'Successfully deleted.',
      onCompleted: async () => {
        await waitSynchronously(750);
        await onSideFormSuccess();
      },
    });

    const [reactivate, { saving: reactivateSaving }] = useMutation(reactivateOrganization, {
      variables: { entityId },
      successMessage: 'Successfully reactivated.',
      onCompleted: async () => await onSideFormSuccess(),
    });

    const [deactivate, { saving }] = useMutation(deactivateOrganization, {
      variables: { entityId },
      undoAction: async () => reactivate(),
      onCompleted: async () => onSideFormSuccess(),
    });

    const deactivated = deactivatedAt ? true : false;

    return (
      <DropDown
        containerClasses="more-actions"
        actions={[
          {
            Component: <>Locations</>,
            to: buildQueryString(Urls.LOCATIONS, { 'metadata.organizationIds': [entityId] }),
            'data-testid': 'toLocations',
          },
          ...(isExternalProvider
            ? [{ Component: 'Managed Equipment', to: `${Urls.EQUIPMENT}?metadata.providerId=${entityId}`, 'data-organizationid': entityId }]
            : [
                { Component: <>Agreements</>, to: buildQueryString(Urls.AGREEMENTS, { 'metadata.organizationId': [entityId] }) },
                {
                  Component: <>Coverage</>,
                  to: buildQueryString(Urls.COVERAGE, {
                    'metadata.clientOrganizationId': entityId,
                  }),
                },
                { Component: <>Charges</>, to: `${Urls.CHARGES}?clientOrganizationId=${entityId}` },
                { Component: <>Equipment</>, to: `${Urls.EQUIPMENT}?metadata.organizationId=${entityId}` },
                {
                  Component: <>Service Requests</>,
                  to: buildQueryString(Urls.SERVICE_REQUESTS, { 'metadata.organizationId': [entityId] }),
                },
                { Component: <>Users</>, to: buildQueryString(Urls.USERS, { 'metadata.organizationId': entityId }) },
              ]),
          { border: true },
          ...(userCan(internalUsers)
            ? [{ Component: <>Copy OrganizationId</>, action: () => window.navigator.clipboard.writeText(entityId) }]
            : []),
          { border: true },
          ...(deactivated && userCan(internalUsers)
            ? [
                {
                  Component: <>{reactivateSaving ? 'Loading...' : 'Reactivate'}</>,
                  action: async () => reactivate(),
                },
                ...(userCan([roles.superGlobalAdmin])
                  ? [
                      {
                        Component: <>{deleteSaving ? 'Loading...' : 'Delete'}</>,
                        action: async () => deleteMutation(),
                      },
                    ]
                  : []),
              ]
            : [
                { Component: <>Edit</>, to: `${Urls.ORGANIZATIONS}/${entityId}` },
                ...(userCan(internalUsers)
                  ? [
                      {
                        Component: <>{saving ? 'Loading...' : 'Deactivate'}</>,
                        action: async () => deactivate(),
                      },
                    ]
                  : []),
              ]),
        ]}
      />
    );
  }

  return [
    ...(userCan(internalUsers)
      ? [
          {
            id: 'metadata.underwriterId',
            Header: 'Underwriter',
            accessor: (data) => data?.metadata?.underwriter?.metadata?.fullName,
            disableSortBy: true,
            Cell: (data) => data.value || 'None',
            filterOptions: {
              hidden: !userCan(internalUsers),
              type: TableFilterTypes.select,
              options: equipsUsers,
            },
          },
        ]
      : []),
    {
      id: 'metadata.organizationId',
      Header: 'Organization Id',
      accessor: (data) => data?.metadata?.organizationId,
    },
    {
      id: 'metadata.shortId',
      Header: 'Number',
      accessor: (data) => data?.metadata?.shortId,
      Cell: (data) => (
        <Link className="active" data-testid="organizationId" to={`${Urls.ORGANIZATIONS}/${data?.row?.original?.metadata?.organizationId}`}>
          {data?.value}
        </Link>
      ),
    },
    {
      id: 'metadata.organizationName',
      Header: 'Name',
      width: defaultTableWidths.largeText,
      accessor: (data) => data?.metadata?.organizationName,
      Cell: (data) => (
        <StackedColumn
          line1={
            <>
              {data?.value} <TierIndicator tier={data?.row?.original?.metadata?.tier} />
            </>
          }
          line2={data?.row?.original?.metadata?.urlDomain}
        />
      ),
    },
    {
      id: 'billingAddress.line1',
      Header: 'Address',
      accessor: (data) => data?.billingAddress?.line1,
      width: defaultTableWidths.largeText,
      toText: (data) => formatAddressAsOneLine(data?.row?.original?.billingAddress),
      Cell: (data) => <AddressColumn addressObject={data?.row?.original?.billingAddress} />,
    },
    {
      id: 'billingAddress.stateUnabbreviated',
      Header: 'State',
      accessor: (data) => data?.billingAddress?.stateUnabbreviated,
      filterOptions: {
        type: TableFilterTypes.stateSelect,
      },
      Cell: (data) => (data?.value && statesAsObject[data?.value]) || 'NA',
    },
    {
      id: 'billingAddress.city',
      Header: 'City',
      accessor: (data) => data?.billingAddress?.city,
    },
    {
      id: 'billingAddress.zip',
      Header: 'Zip',
      accessor: (data) => data?.billingAddress?.zip,
    },
    {
      id: 'metadata.equipmentServiced',
      Header: 'Equipment Serviced',
      accessor: (data) => data?.provider?.equipmentServiced,
    },
    {
      id: 'metadata.industry',
      Header: 'Industry',
      accessor: (data) => data?.metadata?.industry,
      Cell: (data) => getEnumDisplayName(data.value, organizationIndustryTypes),
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          ...Object.entries(organizationIndustryTypes).map(([, { value, name }]) => ({ value, label: name })),
        ],
      },
    },
    {
      id: 'metadata.organizationType',
      Header: 'Type',
      accessor: (data) => data?.provider?.isExternalProvider,
      Cell: (data) => (data.value ? 'Provider' : 'Client'),
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Client', value: clientValueForFilter },
          { label: 'Provider', value: providerValueForFilter },
        ],
        label: 'Organization type',
      },
    },
    {
      id: 'metadata.clientOrganizationType',
      Header: 'Client Organization Type',
      accessor: (data) => data?.metadata?.clientOrganizationType,
      Cell: (data) => (data?.value ? clientOrganizationTypes[data.value].name ?? 'None' : 'None'),
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          ...Object.entries(clientOrganizationTypes).map(([, { value, name }]) => ({ value, label: name })),
        ],
      },
    },
    {
      id: 'metadata.autoForwardAllDispatchRequests',
      Header: 'Accepting Dispatch',
      accessor: (data) => data?.metadata?.autoForwardAllDispatchRequests,
      Cell: (data) =>
        !data?.row?.original?.provider?.isExternalProvider
          ? 'N/A'
          : data?.row?.original?.metadata?.autoForwardAllDispatchRequests
          ? 'No'
          : 'Yes',
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
        label: 'Accepting Dispatch',
      },
    },
    {
      id: 'metadata.accountManager.metadata.fullName',
      Header: 'Account Manager',
      accessor: (data) => data?.metadata?.accountManager?.metadata?.fullName,
      disableSortBy: true,
      Cell: (data) => data.value || 'Unassigned',
    },
    {
      id: 'metadata.producers',
      Header: 'Producer',
      accessor: (data) => data?.metadata?.producers,
      disableSortBy: true,
      Cell: (data) => data.value?.[0] || 'No producer',
      width: defaultTableWidths.normalText,
    },
    {
      id: 'metadata.subproducers',
      Header: 'Subproducers',
      accessor: (data) => data?.metadata?.producers,
      disableSortBy: true,
      Cell: (data) => getSubproducers(data.value || []),
      width: defaultTableWidths.normalText,
    },
    {
      id: 'metadata.isTrial',
      Header: 'Account live',
      accessor: (data) => data?.metadata?.isTrial,
      disableSortBy: true,
      Cell: (data) => (data.value ? 'Trial' : 'Active'),
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Active', value: 'active' },
          { label: 'Trial', value: 'trial' },
        ],
      },
    },
    {
      id: 'metadata.EIN',
      Header: 'EIN',
      accessor: (data) => data?.metadata?.EIN,
    },
    {
      id: 'metadata.urlDomain',
      Header: 'Domain',
      accessor: (data) => data?.metadata?.urlDomain,
      width: defaultTableWidths.largeText,
    },
    {
      id: 'metadata.autorequestId',
      Header: 'Auto request ID',
      accessor: (data) => data?.metadata?.autorequestId,
    },
    {
      id: 'metadata.goLiveDate',
      Header: 'Go live Date',
      accessor: (data) => data?.metadata?.goLiveDate,
      width: defaultTableWidths.normalText,
      toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value),
      Cell: (data) => <DisplayTime timestamp={data?.value} />,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.quickbooksCompanyId',
      Header: 'Quickbooks Company ID',
      width: defaultTableWidths.normalText,
      accessor: (data) => data?.metadata?.quickbooksCompanyId,
      filterOptions: {
        type: TableFilterTypes.text,
      },
    },
    {
      id: 'metadata.quickbooksVendorId',
      Header: 'Quickbooks Vendor ID',
      width: defaultTableWidths.normalText,
      accessor: (data) => data?.metadata?.quickbooksVendorId,
      filterOptions: {
        type: TableFilterTypes.text,
      },
    },
    {
      id: 'metadata',
      disableSortBy: true,
      Cell: (data) => (
        <OrganizationActions
          isExternalProvider={data?.row?.original?.provider?.isExternalProvider}
          entityId={data?.row?.original?.metadata?.organizationId}
          deactivatedAt={data?.row?.original.metadata?.deactivatedAt}
        />
      ),
      width: 100,
    },
  ];
}
