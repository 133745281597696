import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import useWindowSize from '../../hooks/useWindowSize';
import { TrialMessage } from '../TrialMessage';

export function TrialBanner() {
  const [modalOpen, setModalOpen] = useState(false);
  const size = useWindowSize();
  const smallBreakpoint = size?.width && size.width < 768;
  return (
    <>
      <button onClick={() => setModalOpen(true)} type="button" className="w-full bg-blue-600 py-2 text-center text-white">
        Your account is in trial mode. Click here for more info.
      </button>
      <Modal open={modalOpen}>
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="md"
          fullWidth
          fullScreen={!!smallBreakpoint}
          classes={{ scrollPaper: 'items-start', paperScrollBody: 'align-top' }}
        >
          <TrialMessage onClose={() => setModalOpen(false)} />
        </Dialog>
      </Modal>
    </>
  );
}
