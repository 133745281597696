import React from 'react';
import BrandedSpinner from './BrandedSpinner';

export default function MainLoader() {
  return (
    <div className="flex h-screen align-middle">
      <BrandedSpinner />
    </div>
  );
}
