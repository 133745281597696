import React from 'react';
import { integerRepresentingUnlimitedForOptions } from '@equips/entities-schema';
import FormGroup from '../../common/components/Form/FormGroup';
import Checkbox from '../../common/components/Form/Checkbox';
import Label from '../../common/components/Form/Label';
import Input from '../../common/components/Form/Input';
import Button from '../../common/components/Buttons/Button';

type OptionsFormBuilderProps = {
  options?: { [key: string]: string | boolean | number };
  setOptions: (any) => void;
  optionsArray: any[];
};

export default function OptionsFormBuilder({ options = {}, setOptions, optionsArray }: OptionsFormBuilderProps) {
  return (
    <>
      {optionsArray.map(([optionName, optionConfigValue]) => {
        const type = optionConfigValue?.type?.name;
        const labelAndPlaceholder = optionConfigValue?.label;
        const value = options[optionName] || optionConfigValue?.default || '';
        const handleChange = (value: any) => setOptions({ ...options, [optionName]: value });

        return (
          <FormGroup key={optionName} fullWidth className="pt-0 pb-0">
            {type === 'Boolean' && (
              <div className="my-1 -mx-3">
                <Checkbox
                  label={labelAndPlaceholder}
                  id={optionName}
                  checked={!!value}
                  onChange={({ target: { checked } }) => handleChange(checked)}
                />
              </div>
            )}
            {type === 'Number' && (
              <>
                <div>
                  <Label label={labelAndPlaceholder} id={optionName} />
                  {integerRepresentingUnlimitedForOptions === value ? (
                    <Input readOnly id={optionName} value="Unlimited allowed" />
                  ) : (
                    <Input
                      placeholder={labelAndPlaceholder}
                      type="number"
                      id={optionName}
                      value={value}
                      onChange={({ target: { value } }) => handleChange(parseInt(value, 10))}
                    />
                  )}
                  <div className="flex w-full justify-end pt-2">
                    <Button
                      tiny
                      gray
                      type="button"
                      onClick={() =>
                        handleChange(integerRepresentingUnlimitedForOptions === value ? 0 : integerRepresentingUnlimitedForOptions)
                      }
                    >
                      {integerRepresentingUnlimitedForOptions === value ? 'Define specific number' : 'Set to unlimited'}
                    </Button>
                  </div>
                </div>
              </>
            )}
            {type === 'String' && (
              <div className="py-2">
                <Label label={labelAndPlaceholder} id={optionName} />
                <Input
                  placeholder={labelAndPlaceholder}
                  type={type === 'Number' ? 'number' : 'string'}
                  id={optionName}
                  value={value}
                  onChange={({ target: { value } }) => handleChange(type === 'Number' ? parseInt(value, 10) : value)}
                />
              </div>
            )}
          </FormGroup>
        );
      })}
    </>
  );
}
