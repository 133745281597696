import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function DraggableComponent(props) {
  return (
    <Draggable handle="#draggable-dialog" cancel={'.no-drag-zone'}>
      <Paper {...props} />
    </Draggable>
  );
}

export const DefaultDraggableDialogHeader: React.FC = React.memo(({ children }) => {
  return (
    <header
      id="draggable-dialog"
      style={{ cursor: 'move' }}
      className={`flex flex-wrap items-center justify-between border-b border-gray-300 bg-white p-4`}
    >
      <div className="flex w-2/3 pr-2">{children}</div>
    </header>
  );
});

const DraggableDialog: React.FC<DialogProps> = ({ children, ...restOfProps }) => {
  return (
    <Dialog PaperComponent={DraggableComponent} {...restOfProps}>
      {children}
    </Dialog>
  );
};
export default React.memo(DraggableDialog);
