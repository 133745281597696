import React from 'react';
import { Link } from 'react-router-dom';
import { Equipment, OrganizationFeatureFlagEnum } from '@equips/entities-schema';
import { Translation } from 'react-i18next';
import Urls from '../../../routes/Urls';
import { AuthContextValue, AuthorizationData } from '../../../common/auth/AuthContext';
import { rolesThatCanViewCoverageData } from '../../coverages/rolesThatCanViewCoverageData';
import { TableColumn } from '../../../common/components/DataTable';
import TableCellCopyData from '../../../common/components/DataTable/TableCellCopyData';
import { customers, internalUsers } from '../../../common/auth/roles';
import { defaultColumnWidths } from '../../../common/components/DataTable/columns';
import { TableFilterTypes } from '../../../common/components/DataTable/TableFilters';
import { agreementFilterTypeOptions } from '../equipmentScreenQuery';
import {
  coveragesMetadataAnnualizedCoveragePrice,
  coveragesMetadataConditions,
  coveragesMetadataCoverageStatus,
  coveragesMetadataDailyMaintenanceContractFee,
  coveragesMetadataExclusions,
  coveragesMetadataExpired,
  coveragesMetadataOffDate,
  coveragesMetadataOnDate,
  equipmentCoverages,
  equipmentNotes,
  equipmentOptions,
  locationAddressCity,
  locationAddressLine1,
  locationAddressState,
  locationAddressZip,
  locationMetadataShortId,
  metadataAssetPrice,
  metadataCostCenter,
  metadataDailyManagedServicesFee,
  metadataDailyServiceDispatchFee,
  metadataEquipmentHealth,
  metadataEquipmentName,
  metadataEquipmentStatus,
  metadataEquipsCoveragePmCadence,
  metadataExternalId,
  metadataHasChronicIssues,
  metadataInstallationDate,
  metadataLocationId,
  metadataMainContactEmail,
  metadataMainContactPhone,
  metadataMainContactUser,
  metadataMonthsToPmEquipment,
  metadataOpenServiceRequestCount,
  metadataOrganizationId,
  metadataPmCompliance,
  metadataProvider,
  metadataSerialNumber,
  metadataServiceEndDate,
  metadataServiceTotal,
  metadataShortId,
  metadataSubclasses,
  metadataTid,
  metadataUsefulLifeEndDate,
  metadataWarrantyInfo,
  metadataWarrantyOffDate,
  metadataWarrantyOnDate,
  organizationMetadataClientOrganizationType,
  organizationMetadataIndustry,
  parentLocation,
  specMetadataCategory,
  specMetadataManufacturer,
  specMetadataManufacturerEOL,
  specMetadataModel,
  specMetadataVendorLimitedSupportDate,
  tagMetadata,
} from './EquipmentColumnDefinitions';

export type EquipmentColumnsArgs = {
  userCan: AuthContextValue['userCan'];
  auth: AuthorizationData | null | undefined;
  determineFeatureFlagVisibility: AuthContextValue['determineFeatureFlagVisibility'];
};
export function getEquipmentColumns({ userCan, auth, determineFeatureFlagVisibility }: EquipmentColumnsArgs): TableColumn<Equipment>[] {
  return [
    metadataLocationId,
    locationAddressLine1,
    locationAddressCity,
    locationAddressState,
    locationAddressZip,
    metadataMainContactUser,
    metadataMainContactPhone,
    metadataMainContactEmail,
    metadataShortId,
    metadataEquipmentName,
    specMetadataCategory,
    metadataSubclasses,
    specMetadataManufacturer,
    metadataEquipmentStatus,
    metadataEquipmentHealth,
    metadataOpenServiceRequestCount,
    {
      ...tagMetadata,
      Header: <Translation>{(t) => <>{t(userCan(internalUsers) ? 'clientTags' : 'tags')}</>}</Translation>,
    },
    {
      ...specMetadataModel,
      Cell: (data) => (
        <div className="flex justify-between">
          {userCan(internalUsers) ? (
            <Link to={`${Urls.SPECS}/${data.row.original.metadata?.specId}`}>{data?.value}</Link>
          ) : (
            <>{data?.value}</>
          )}
        </div>
      ),
    },
    metadataSerialNumber,
    metadataExternalId,
    metadataServiceEndDate,
    metadataPmCompliance,
    metadataUsefulLifeEndDate,
    specMetadataManufacturerEOL,
    specMetadataVendorLimitedSupportDate,
    locationMetadataShortId,
    parentLocation,
    metadataInstallationDate,
    metadataWarrantyOnDate,
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentFieldsWarranty)
      ? [metadataWarrantyOffDate, metadataWarrantyInfo]
      : []),
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentFieldCostCenter) ? [metadataCostCenter] : []),
    metadataTid,
    metadataAssetPrice,
    metadataServiceTotal,
    equipmentOptions,
    ...(!userCan(customers)
      ? [
          {
            id: 'referredByOrg',
            filterOptions: {
              label: <Translation>{(t) => <>{t('referredByMyOrganization')}</>}</Translation>,
              type: TableFilterTypes.select,
              options: [
                { label: 'Any', value: '' },
                { label: 'Yes', value: 'true' + auth?.organizationId },
                { label: 'No', value: 'false' + auth?.organizationId },
              ],
            },
          },
        ]
      : []),
    ...(userCan(internalUsers)
      ? [
          metadataOrganizationId,
          organizationMetadataClientOrganizationType,
          organizationMetadataIndustry,
          {
            id: 'agreementFilter',
            disableSortBy: true,
            filterOptions: {
              type: TableFilterTypes.agreementSelect,
              label: <Translation>{(t) => <>{t('agreement')}</>}</Translation>,
              showFilterOptionWhen: (filterMap) => Boolean(filterMap['metadata.organizationId']),
              labelHelper:
                'Select an agreement to see equipment that were on that agreement. This overrides any organization that maybe selected to the organization on the agreement.',
            },
          },
          coveragesMetadataCoverageStatus,
          {
            id: 'coverageFilter',
            disableSortBy: true,
            filterOptions: {
              type: TableFilterTypes.select,
              label: 'Agreement Filter Type',
              showFilterOptionWhen: (filterMap) => Boolean(filterMap['metadata.contractId']),
              labelHelper:
                'Select if you want to see all active equipment at the time of the agreement, or equipment with coverage on the agreement',
              options: [
                { label: 'All Equipment On Agreement', value: agreementFilterTypeOptions.allEquipment },
                { label: 'Equipment With Coverage', value: agreementFilterTypeOptions.equipmentWithCoverage },
              ],
            },
          },
          coveragesMetadataOnDate,
          coveragesMetadataOffDate,
          coveragesMetadataAnnualizedCoveragePrice,
          coveragesMetadataDailyMaintenanceContractFee,
          metadataDailyManagedServicesFee,
          metadataDailyServiceDispatchFee,
          coveragesMetadataConditions,
          coveragesMetadataExclusions,
          metadataEquipsCoveragePmCadence,
          metadataMonthsToPmEquipment,
        ]
      : []),
    metadataHasChronicIssues,
    ...(userCan(rolesThatCanViewCoverageData) ? [metadataProvider, equipmentCoverages, coveragesMetadataExpired] : []),
    equipmentNotes,
    {
      id: 'sublocationIds',
    },
    {
      id: 'equipmentId',
      Header: <Translation>{(t) => <>{t('equipmentId')}</>}</Translation>,
      accessor: (data) => data.equipmentId,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (data) => data.value || '',
      Cell: (data) => <TableCellCopyData data={data.value}>{data.value}</TableCellCopyData>,
      filterOptions: {
        type: TableFilterTypes.text,
        hidden: !userCan(internalUsers),
      },
    },
  ];
}
