import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { currentYear } from './dateFunctions';

const DEFAULT_TIME_ZONE = 'America/New_York';

dayjs.extend(utc);
dayjs.extend(timezone);

export const toESTDateStringFromUnixMillisecondTimestamp = (unixTimestamp?: void | null | number, customFormatString = ''): string => {
  if (!unixTimestamp) return '';

  const formatString = !customFormatString ? `MM/DD/YYYY` : customFormatString;

  return dayjs.tz(unixTimestamp, DEFAULT_TIME_ZONE).format(formatString);
};

export const getStringRepresentationOfTimeRangeInEst = (effectiveDate, expirationDate) => {
  const effectiveDateString = effectiveDate ? toESTDateStringFromUnixMillisecondTimestamp(effectiveDate) : 'No Date';
  const expirationDateString = expirationDate ? toESTDateStringFromUnixMillisecondTimestamp(expirationDate) : 'No Date';

  return `${effectiveDateString} to ${expirationDateString}`;
};

export const toESTJavascriptDateFromUnixMillisecondTimestamp = (unixTimestamp?: null | number) => {
  if (!unixTimestamp) return '';

  return dayjs.tz(unixTimestamp, DEFAULT_TIME_ZONE).toDate();
};

export const toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST = (string: string | null | undefined): null | number => {
  if (!string) return null;
  const estUTCOffset = dayjs.tz(string, DEFAULT_TIME_ZONE);
  const usersUTCOffset = dayjs.tz(`${string}`, dayjs.tz.guess());

  return dayjs(string).startOf('day').add(estUTCOffset.diff(usersUTCOffset, 'minutes'), 'minutes').valueOf();
};

export const convertExcelDateStringToUnixMilliseconds = (string) => {
  if (!string) return null;
  const regex = new RegExp(/([0-9])-([A-Z])\w+/);

  if (regex.test(string)) return toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST(`${string}-${currentYear()}`);

  return toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST(string);
};

export const getDateInTimeZone = (date: string | number | dayjs.Dayjs | Date | null | undefined, timezone: string) => {
  return dayjs.tz(date, timezone || DEFAULT_TIME_ZONE);
};

export const getStartAndEndOfMonthInTimeZone = (month: string, timezone: string) => {
  const currentYear = dayjs().year();
  const startOfMonth = dayjs
    .tz(`${currentYear}-${month}-01`, timezone || DEFAULT_TIME_ZONE)
    .startOf('month')
    .valueOf();
  const endOfMonth = dayjs
    .tz(`${currentYear}-${month}-01`, timezone || DEFAULT_TIME_ZONE)
    .endOf('month')
    .valueOf();

  return { startOfMonth, endOfMonth };
};
