import React, { useContext } from 'react';
import { IndustryType } from '@equips/entities-schema';

export type LocationNameAndIdType = { locationName: string; locationId: string };
export type UseEquipmentScreenType = {
  haveEquipment: boolean;
  industry: IndustryType;
  saveAllEquipment: () => void;
  saving: boolean;
  organizationId: string;
  setOrganizationId: (organizationId: string) => void;
  organizationName: string;
  setOrganizationName: (organizationName: string) => void;
  locationNameAndIds: LocationNameAndIdType[];
  addNewLocation: ({ locationId, locationName }: LocationNameAndIdType) => void;
  removeALocation: ({ locationId }: { locationId: string }) => void;
  resetState: () => void;
};
const EquipmentAddScreenContext = React.createContext<any>({});
export const EquipmentAddScreenProvider = EquipmentAddScreenContext.Provider;
export const useEquipmentScreen = (): UseEquipmentScreenType => useContext(EquipmentAddScreenContext);
