import React from 'react';
import PropTypes from 'prop-types';

export default function StackedColumn({ testId, line1, line2 }) {
  if (!line2) return <span data-testid={testId}>{line1}</span>;

  return (
    <div data-testid={testId}>
      <div className="pb-1 text-sm leading-none">{line1}</div>
      <div className="text-xs leading-none text-gray-500">{line2}</div>
    </div>
  );
}
StackedColumn.propTypes = {
  testId: PropTypes.string.isRequired,
  line1: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  line2: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

StackedColumn.defaultProps = { testId: '', line2: null };
