import Dialog from '@mui/material/Dialog';
import React, { useState, useMemo } from 'react';
import useWindowSize from '../hooks/useWindowSize';

type ModalContextType = {
  showModal: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>;
  handleClose: () => void;
};

export const ModalContext = React.createContext<ModalContextType>({ showModal: () => null, handleClose: () => null });

const ModalProvider = ({ children }) => {
  const size = useWindowSize();
  const smallBreakpoint = size?.width && size.width < 768;

  const [modalContent, setModalContent] = useState<JSX.Element>();

  const value = useMemo(() => {
    return { showModal: setModalContent, handleClose: () => setModalContent(undefined) };
  }, []);

  return (
    <>
      <ModalContext.Provider value={value}>
        {children}
        {modalContent && (
          <Dialog
            open={!!modalContent}
            onClose={() => setModalContent(undefined)}
            maxWidth="md"
            fullScreen={!!smallBreakpoint}
            fullWidth
            classes={{ scrollPaper: 'items-start', paperScrollBody: 'align-top' }}
          >
            {modalContent}
          </Dialog>
        )}
      </ModalContext.Provider>
    </>
  );
};

export default ModalProvider;
