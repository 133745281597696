import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { toDateStringFromUnixMillisecondTimestamp } from '../../functions/dateFunctions';

export default function DisplayTime({ timestamp, emptyFallback = '', customFormatString = '' }) {
  return (
    <Tooltip
      title={toDateStringFromUnixMillisecondTimestamp(timestamp, {
        customFormatString: customFormatString ? customFormatString : 'MM/DD/YYYY hh:mma',
      })}
    >
      <span>
        {!timestamp
          ? emptyFallback
          : toDateStringFromUnixMillisecondTimestamp(timestamp, { customFormatString: customFormatString ? customFormatString : '' })}
      </span>
    </Tooltip>
  );
}
DisplayTime.propTypes = { timestamp: PropTypes.number };
DisplayTime.defaultProps = { timestamp: null };
