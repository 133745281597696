import React, { useMemo, useState } from 'react';
import Button from '../Buttons/Button';
import UnderlinedButton from '../Buttons/UnderlinedButton';
import SimpleModal from '../Modal/SimpleModal';
import Banner, { bannerContentClass } from './Banner';

interface HolidayBannerProps {
  start: string;
  end: string;
  holiday: 'Christmas' | 'New Years' | 'Other';
  showBanner: boolean;
  greeting?: string;
}

const whoPickedThisLight = ['sam', 'ray', 'luc', 'claudia', 'conrad', 'cariny', 'taylor', 'ed', 'derrick'];

const gradients = [
  `bg-gradient-to-r from-[#1b3e6f] via-[#3c88c6] to-[#f26a25]`, // sam
  `bg-gradient-to-r from-[#9037a4] to-[#59c1c5] `, // ray
  `bg-gradient-to-r from-[#303030] via-[#d0352b] to-[#6be55f]`, // luc
  `bg-gradient-to-r from-[#fdff54] via-[#e93323] to-[#6037f5]`, // claudia
  `bg-gradient-to-r from-[#33c6ff] via-[#9c33ff] to-[#ff33d7]`, // conrad
  `bg-gradient-to-r from-[#012169] via-[#009739] to-[#fedd00]`, // cariny
  `bg-gradient-to-r from-[#2D00F7] via-[#B100E8] to-[#f20089] `, // taylor
  `bg-gradient-to-r from-[#C0C0C0] via-[#1C2536] to-[#E3DAC9]`, // ed
  `bg-gradient-to-r from-[#10B981] via-[#7C3AED] to-[#FDE68A] `, // derrick
];

const HolidayBanner = ({ start, end, holiday, showBanner = true, greeting = '' }: HolidayBannerProps) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const holidayStart = new Date(start).toLocaleString('en-us', dateOptions);
  const holidayEnd = new Date(end).toLocaleString('en-us', dateOptions);

  const isHomePage = window.location.pathname === '/';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [tryAgain, setTryAgain] = useState(false);

  const randomIndex = useMemo(() => Math.floor(Math.random() * whoPickedThisLight.length), [tryAgain]);

  const resetGame = () => {
    setIsModalOpen(false);
    setSelectedOption('');
    setTryAgain(!tryAgain);
  };

  if (showBanner) {
    return (
      <>
        <Banner bg={`${gradients[randomIndex]}`} bannerClassName="text-gray-100">
          {holiday === 'Christmas' ? (
            <>
              <div
                className={`${bannerContentClass} ${isHomePage ? 'text-lg' : ''} font-semibold tracking-wider`}
                data-testid="holidayChristmas"
              >
                {/* {`Equips will close at noon on ${holidayStart} and will reopen on ${holidayEnd}. 
              We wish you a healthy and happy holiday!`} */}
                ❄️ Happy Holidays from Equips! ❄️
                {isHomePage && (
                  <UnderlinedButton onClick={() => setIsModalOpen(true)} white tiny type="button" className="mx-auto block">
                    Who picked these lights?
                  </UnderlinedButton>
                )}
              </div>

              {isHomePage && (
                <ul className={`lightrope ${whoPickedThisLight[randomIndex]}`}>
                  {Array.from(Array(25), (e, i) => {
                    return <li key={i} />;
                  })}
                </ul>
              )}
            </>
          ) : holiday === 'New Years' ? (
            <p className={`${bannerContentClass}`} data-testid="holidayNewYears">
              {`Equips will will be closed on ${holidayStart} and will reopen on ${holidayEnd}. 
             Happy New Year!`}
            </p>
          ) : (
            <p className={`${bannerContentClass}`} data-testid="holiday">
              {`Equips will will be closed on ${holidayStart} and will reopen on ${holidayEnd}. ${greeting}`}
            </p>
          )}
        </Banner>
        <SimpleModal title="Who Picked These Lights? 🤔 " isOpen={isModalOpen} handleClose={resetGame}>
          <div className="mt-5 capitalize">
            {whoPickedThisLight.map((person, i) => (
              <label key={person} className="block py-1">
                <input
                  type="radio"
                  value={person}
                  checked={selectedOption === person}
                  onChange={(e) => {
                    !selectedOption ? setSelectedOption(e.target.value) : null;
                  }}
                />
                <span className="ml-4">{person}</span>

                <span className="ml-4 text-red-500">
                  {selectedOption === person ? (
                    i === randomIndex ? (
                      <>
                        You Got It Right!{' '}
                        <span role="img" aria-label="party">
                          🎉
                        </span>{' '}
                      </>
                    ) : (
                      <>Wrong Answer! 🙁</>
                    )
                  ) : null}
                </span>
              </label>
            ))}
            <Button blue type="button" className="ml-auto block" onClick={resetGame}>
              Play Again
            </Button>
          </div>
        </SimpleModal>
      </>
    );
  }

  return null;
};

export default HolidayBanner;
