import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import RadioCard from '../Form/RadioCards';
import FormGroup from '../Form/FormGroup';

type RecurrenceIntervalOption = 0 | 90 | 180 | 360 | 'Custom';
type RequestFormRecurrenceProps = {
  recurrenceInterval?: number;
  onChange: (args: { recurrenceInterval: number }) => void;
};

const RequestFormRecurrence = (props: RequestFormRecurrenceProps) => {
  const { t } = useTranslation();
  const initialRecurrenceIntervalOption = [0, 90, 180, 360].includes(props.recurrenceInterval || 0)
    ? props.recurrenceInterval
    : typeof props.recurrenceInterval === 'number' && props.recurrenceInterval > 0
    ? 'Custom'
    : 0;

  const [recurrenceIntervalOption, setRecurrenceIntervalOption] = useState<RecurrenceIntervalOption | number>(
    initialRecurrenceIntervalOption as RecurrenceIntervalOption,
  );

  return (
    <FormGroup className="mt-4" fullWidth>
      <RadioCard
        required
        id="recurrenceInterval"
        label={t('recurrenceLabel')}
        cardSize="flex-grow"
        options={[
          { label: t('once'), value: 0 },
          { label: t('everyNDays', { count: 90 }), value: 90 },
          { label: t('everyNDays', { count: 180 }), value: 180 },
          { label: t('everyNDays', { count: 360 }), value: 360 },
          { label: t('custom'), value: 'Custom' },
        ]}
        onChange={(value) => {
          setRecurrenceIntervalOption(value);
          props.onChange({ recurrenceInterval: value === 'Custom' ? undefined : value });
        }}
        value={recurrenceIntervalOption ?? 0}
      />
      {recurrenceIntervalOption === 'Custom' && (
        <div className="mt-4 flex items-center gap-3">
          {t('every')}
          <TextField
            value={props.recurrenceInterval}
            onChange={(e) => props.onChange({ recurrenceInterval: parseInt(e.target.value) })}
            id="customRecurrenceValue"
            InputProps={{
              inputProps: { type: 'number', min: 0 },
            }}
            variant="outlined"
            size="small"
            required={recurrenceIntervalOption === 'Custom'}
          />
          {t('days')}
        </div>
      )}
    </FormGroup>
  );
};

export default RequestFormRecurrence;
