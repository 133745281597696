import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from './Skeleton';

export default function LoaderCards({ parentQuantity, parentClassName, childQuantity, childClassName }) {
  return (
    <div className={parentClassName}>
      {[...Array(parentQuantity)].map((_, i) => (
        <div key={i} className={childClassName}>
          {[...Array(childQuantity)].map((_, j) => (
            <div key={j} className="mb-1 text-2xl leading-tightest">
              <Skeleton />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
LoaderCards.propTypes = {
  parentClassName: PropTypes.string,
  childClassName: PropTypes.string,
  parentQuantity: PropTypes.number,
  childQuantity: PropTypes.number,
};
LoaderCards.defaultProps = {
  parentClassName: 'mb-16',
  childClassName: 'bg-white p-2 w-full mb-4 rounded shadow',
  parentQuantity: 3,
  childQuantity: 4,
};
