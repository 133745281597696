import { lazy } from 'react';

export const CAPTURE_URLS = {
  ROOT: '/capture/queue',
  CAPTURE_ITEM_DETAILS: '/details/:id',
  CAPTURE_PRINT: '/qr/print',
  CAPTURE_SCAN: '/qr/scan',
};

export const captureRoutes = [
  {
    path: CAPTURE_URLS.ROOT,
    component: lazy(() => import('../CaptureApp')),
    routes: [
      {
        path: `${CAPTURE_URLS.ROOT}${CAPTURE_URLS.CAPTURE_ITEM_DETAILS}`,
        component: lazy(() => import('../screens/ViewCapture')),
      },
    ],
  },
  {
    path: CAPTURE_URLS.CAPTURE_PRINT,
    component: lazy(() => import('../screens/PrintQRCodesScreen')),
  },
  {
    path: CAPTURE_URLS.CAPTURE_SCAN,
    component: lazy(() => import('../screens/CaptureScreen')),
  },
  {
    path: `${CAPTURE_URLS.CAPTURE_SCAN}/:captureId`,
    component: lazy(() => import('../screens/CaptureScreen')),
  },
];
