export const gql = String.raw;

/**
 * Generates a filter object based on the provided key and value for a single-select case.
 * NOTE: Make sure to only use this for single-select cases multi-selects will not work
 * @param {string} key - The key for the filter.
 * @param {string} value - The value used to generate the filter object.
 * @param {boolean} filterOutBlanks - Indicates whether the query should filter out blanks as well. Generally use this for strings but not for enums
 * @returns {Object} The filter object based on the specified key and value.
 */
export const handleFilterMapEmptyNotEmptySingleSelectCase = (key: string, value: string, filterOutBlanks = true) => {
  switch (value) {
    case '{none}':
      if (filterOutBlanks) {
        return { [key]: [{ keyword: [{ exists: false }] }, { keyword: [{ term: '' }] }] };
      } else {
        return { [key]: [{ keyword: [{ exists: false }] }] };
      }
    case '{notempty}':
      return { [key]: [{ keyword: [{ exists: true }] }] };
    default:
      return { [key]: [{ keyword: [{ term: value }] }] };
  }
};
