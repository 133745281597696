import React from 'react';
import { API, Auth } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ReactQueryProvider } from './common/components/ReactQueryProvider';
import configureHeap from './common/functions/configureHeap';
import configureGA from './common/functions/googleAnalytics';
import AppStatusProvider from './common/providers/AppStatusProvider';
import config from './config';
import * as serviceWorker from './serviceWorker';
import './styles/tailwind.css';
import './styles/transisions.css';
import './i18n';

API.configure({ endpoints: [config.apiGateway] });
Auth.configure(config.cognito);

// needed for invoke-entities
window.AmplifyAPI = API;

configureHeap();
configureGA();

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Router>
    <AppStatusProvider>
      <ReactQueryProvider>
        <App />
      </ReactQueryProvider>
    </AppStatusProvider>
  </Router>,
  rootElement,
);

serviceWorker.unregister();
