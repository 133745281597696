import AccountCircle from '@mui/icons-material/AccountCircle';
import Assignment from '@mui/icons-material/Assignment';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import Build from '@mui/icons-material/Build';
import Business from '@mui/icons-material/Business';
import Category from '@mui/icons-material/Category';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Handshake from '@mui/icons-material/Handshake';
import LocationOn from '@mui/icons-material/LocationOn';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import Receipt from '@mui/icons-material/Receipt';
import Work from '@mui/icons-material/Work';

export const entityIcons = {
  categories: Category,
  charges: MonetizationOn,
  contracts: Assignment,
  coverages: AssignmentTurnedIn,
  equipment: Work,
  invoices: Receipt,
  incidents: ErrorOutline,
  locations: LocationOn,
  organizations: Business,
  serviceContracts: Handshake,
  serviceRequests: Build,
  users: AccountCircle,
};
