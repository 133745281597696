import React, { useState } from 'react';
import { Equipment, GeolocationType, OrganizationFeatureFlagEnum } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Add from '@mui/icons-material/Add';
import Search from '@mui/icons-material/Search';
import { useAuth } from '../../auth/AuthContext';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Buttons/Button';
import { internalUsers } from '../../auth/roles';
import LeafletMap from '../LeafletMap';
import { Categories, FilterInput, filterMatches, LocationCategories } from './SubmitRequestSelectEquipment';
import { getEquipmentForLocation } from './SubmitRequestQueries';

type SubmitRequestGeolocationProps = {
  organizationId: string;
  addEquipment?: () => void;
  onSelectLocation: () => void;
  onChange: (args: { equipment: Equipment[]; geolocation: GeolocationType }) => any;
};

export function SubmitRequestGeolocation(props: SubmitRequestGeolocationProps) {
  const { userCan, determineFeatureFlagVisibility } = useAuth();

  const isInternalUser = userCan([...internalUsers]);

  const [equipmentFilter, setEquipmentFilter] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment[]>([]);
  const [geolocation, setGeolocation] = useState<GeolocationType>({ lat: 0, lng: 0, displayName: '' });

  const windowSize = useWindowSize();
  const isMobile = !!(windowSize.width && windowSize.width <= 768);

  const { data: equipmentData, isLoading: loadingEquipment } = useQuery(
    ['SubmitRequestSelectEquipmentGeolocation', props.organizationId],
    () => getEquipmentForLocation('', { isInternalUser, organizationId: props.organizationId }),
    {
      onSuccess: (data) => {
        const equipment = data?.data?.equipment?.data || [];
        if (equipment.length === 1 && equipment[0]) {
          setSelectedEquipment([equipment[0]]);
        }
      },
    },
  );

  const equipment = equipmentData?.data?.equipment?.data || [];
  const equipmentCount = equipment.length;

  const categories: Categories = {};
  for (const eq of equipment) {
    const category = eq?.specMetadata?.category?.metadata?.name;
    if (!eq || !category) {
      continue;
    } else if (equipmentFilter && !filterMatches(eq, equipmentFilter)) {
      continue;
    } else if (categories[category]) {
      categories[category].push(eq);
    } else {
      categories[category] = [eq];
    }
  }

  const submit = () => {
    if (!geolocation) return;
    props.onChange({ equipment: selectedEquipment, geolocation });
  };

  return (
    <div>
      <div className="flex h-full flex-col border-b sm:items-stretch lg:flex-row" style={{ maxHeight: isMobile ? undefined : '50vh' }}>
        <div className="lg:w-1/2">
          <LeafletMap
            onChange={(v) => {
              setGeolocation({ ...geolocation, ...v });
            }}
          />
        </div>

        {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility) && (
          <div className="overflow-y-auto border-x border-gray-400 lg:w-1/2">
            <List>
              <ListSubheader className="bg-white">
                <div className="flex items-center justify-between">
                  <h3 className="text-base">Equipment</h3>
                  <div>
                    {props.addEquipment && (
                      <IconButton onClick={props.addEquipment} title="Add new equipment" size="large">
                        <Add />
                      </IconButton>
                    )}
                    <IconButton onClick={() => setEquipmentFilter(equipmentFilter ?? '')} title="Filter equipment" size="large">
                      <Search />
                    </IconButton>
                  </div>
                </div>
                {equipmentFilter !== null && <FilterInput filter={equipmentFilter} setFilter={setEquipmentFilter} />}
              </ListSubheader>

              <LocationCategories
                categories={categories}
                loading={loadingEquipment}
                equipmentCount={equipmentCount}
                equipmentFilter={equipmentFilter}
                selectedCategory={selectedCategory}
                selectedEquipment={selectedEquipment}
                setSelectedCategory={setSelectedCategory}
                setSelectedEquipment={setSelectedEquipment}
              />
            </List>
          </div>
        )}
      </div>

      <div className="sticky bottom-0 z-50 w-full border-t bg-white p-5 md:relative md:border-0">
        <Button tiny blue className={`mb-4 block ${isMobile ? 'mx-auto' : ''}`} onClick={props.onSelectLocation}>
          Select from available locations
        </Button>

        <div className="mt-4 flex items-center justify-center">
          <Button type="button" onClick={submit} disabled={!geolocation}>
            {selectedEquipment?.length ? 'Continue' : 'Continue Without Equipment'}
          </Button>
        </div>
      </div>
    </div>
  );
}
