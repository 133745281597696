import { Maybe } from '@equips/entities-schema';

export const prodOmniFamilyHealthOrganizationId = '6d56192a90fb486e898c857827c8cb40';

export function isOmniFamilyHealthOrganizationId(organizationId: Maybe<string>) {
  if (organizationId) {
    return organizationId === prodOmniFamilyHealthOrganizationId;
  }
  return false;
}
