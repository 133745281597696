import { SentimentSatisfied } from '@mui/icons-material';
import { groupBy } from '@equips/common-resources';
import React, { useState, useRef } from 'react';
import EmojiPicker, { Categories, Emoji, EmojiClickData } from 'emoji-picker-react';
import { Maybe } from '@equips/entities-schema';
import HelperPopper from './Popper';

enum EmojiReactionState {
  Open = 'open',
  Closed = 'closed',
}

export const EmojiSelectorButton = ({ parentClassName = '', className = '', onSelect }) => {
  const [state, setState] = useState(EmojiReactionState.Closed);
  const helperRef = useRef(null);

  const handleClose = () => {
    setState(EmojiReactionState.Closed);
  };

  return (
    <div className={parentClassName}>
      <button className={`ml-2 ${className}`} ref={helperRef} type="button" onClick={() => setState(EmojiReactionState.Open)}>
        <SentimentSatisfied fontSize="small" />
      </button>
      {state === EmojiReactionState.Open && (
        <>
          <HelperPopper handleClose={() => handleClose()} open anchorEl={helperRef && helperRef.current}>
            <EmojiPicker
              height={300}
              width={400}
              searchDisabled
              onEmojiClick={(emojiData: EmojiClickData) => onSelect(emojiData.unified)}
              categories={[
                {
                  name: 'Smiles & Emotions',
                  category: Categories.SMILEYS_PEOPLE,
                },
              ]}
            ></EmojiPicker>
          </HelperPopper>
        </>
      )}
    </div>
  );
};

export interface EmojiType {
  noteId?: Maybe<string>;
  fullName?: string;
  userId?: string;
  message?: string;
}
interface EmojiReactionRowProps {
  emojis: EmojiType[];
  onSelect: any;
  loggedInUserId: Maybe<string>;
}

export const EmojiReactionRow = ({ emojis, onSelect, loggedInUserId }: EmojiReactionRowProps) => {
  const groupedEmoji = groupBy(emojis, (emoji) => emoji?.message);
  const [hoveredEmoji, setHoveredEmoji] = useState('');

  const filterUniqueByUserId = (emojis: EmojiType[]) => {
    const uniqueUserIds = new Set();
    const uniqueItems = emojis.filter((emoji: EmojiType) => {
      const { userId } = emoji;
      if (!uniqueUserIds.has(userId)) {
        uniqueUserIds.add(userId);
        return true;
      }
      return false;
    });
    return uniqueItems;
  };

  return (
    <div className="emoji-group flex w-full flex-wrap pb-4 pt-2">
      {Object.entries(groupedEmoji).map(([emoji, emojis]) => {
        const filteredEmojis = emojis.filter((currEmoji) => currEmoji?.message === emoji);
        const uniqueUsers = filterUniqueByUserId(filteredEmojis);

        let reactionMessage = '';

        if (uniqueUsers.length > 1) {
          const lastUser = uniqueUsers[uniqueUsers.length - 1];
          const otherUsers = uniqueUsers.slice(0, -1);
          const otherUsersString = otherUsers.map((emoji) => (emoji.userId === loggedInUserId ? 'you' : emoji.fullName)).join(', ');
          reactionMessage = `${otherUsersString}, and ${lastUser.userId === loggedInUserId ? 'you' : lastUser.fullName} reacted`;
        } else if (uniqueUsers.length === 1) {
          reactionMessage = `${uniqueUsers[0].userId === loggedInUserId ? 'You(click to remove)' : uniqueUsers[0].fullName} reacted`;
        }
        const reacted = uniqueUsers.some((user) => user.userId === loggedInUserId);

        return (
          <div className="relative" key={emoji}>
            <button
              className={`m-1 inline-flex rounded-full ${reacted && 'border border-blue-500'} bg-blue-50 px-3 py-1 hover:bg-blue-100`}
              type="button"
              onClick={() => onSelect(emoji)}
              onMouseOver={() => setHoveredEmoji(emoji)}
              onMouseOut={() => setHoveredEmoji('')}
              onFocus={() => setHoveredEmoji(emoji)}
              onBlur={() => setHoveredEmoji('')}
            >
              <Emoji unified={emoji} size={20} />
              <span className="ml-2 text-sm text-blue-600">{emojis.length}</span>
            </button>
            {hoveredEmoji === emoji && (
              <div className="absolute  bottom-full  z-10 rounded-md border bg-white p-2 shadow-md">
                <span>{reactionMessage}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
