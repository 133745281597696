import React, { useState } from 'react';
import { ElasticsearchSearchTypesEnum, generateStringTermFilters, queryEquipment } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import Input from '../Form/Input';
import EmptyComponent from '../EmptyComponent';
import { DisplaySelectableEquipment } from './EquipmentSelection';

export interface EquipmentSearchableProps {
  organizationId?: string;
  locationId?: string;
  handleSelect: (equipmentId: string) => void;
  id?: string;
  /** The selected equipmentId. */
  equipmentId?: string;
  /** Whether the component should output and "any" option. If empty, no option is shown. */
  includeAnyText?: string;
}

export function EquipmentSearchableSelection({
  equipmentId = '',
  locationId = '',
  organizationId = '',
  handleSelect,
  id,
  includeAnyText = '',
}: EquipmentSearchableProps) {
  const [q, setQ] = useState('');

  const { data } = useQuery(
    ['QueryEquipmentForEquipmentSelection', locationId, organizationId, q],
    () =>
      queryEquipment({
        search: {
          q,
          searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
          filters: [{ metadata: generateStringTermFilters({ locationId, organizationId }) }],
        },
      }),
    {
      enabled: !!organizationId,
      select: (data) => data?.data,
    },
  );

  const equipmentForSelection = data?.equipment?.data || [];

  return (
    <>
      <div className="py-2">
        <div className="flex w-2/3 p-2">
          <Input
            autoFocus
            className={`no-drag-zone`}
            id="searchQuery"
            value={q}
            onChange={(event) => setQ(event.target.value)}
            placeholder="Search"
          />
          {q && (
            <button tabIndex={-1} className="ml-2 text-sm text-gray-700" type="button" onClick={() => setQ('')}>
              Clear
            </button>
          )}
        </div>
        <div
          aria-labelledby={id || 'selectEquipmentForRequest'}
          className="scrolling-touch scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray max-h-md w-full overflow-y-auto pt-1"
        >
          {includeAnyText && <DisplaySelectableEquipment text={includeAnyText} handleSelect={handleSelect} />}
          {equipmentForSelection.length === 0 && <EmptyComponent small />}
          {equipmentForSelection.map((item) => (
            <DisplaySelectableEquipment key={item?.metadata?.equipmentId || ''} item={item || {}} handleSelect={handleSelect} />
          ))}
        </div>
      </div>
    </>
  );
}
