import {
  queryOrganizations,
  OrganizationTagsEnum,
  queryAasContracts,
  AasContractSearchInput,
  addressFields,
  SortByElasticSearchOptions,
  queryLocations,
} from '@equips/entities-schema';
import dayjs from 'dayjs';
import { getAasOrganizationId } from '../../../common/functions/aasHelpers';

export const getAllAASProviders = async ({ size = 5, field, value }) =>
  queryOrganizations(
    {
      search: {
        q: value,
        size,
        searchFields: {
          ...(field === 'organizationName'
            ? {
                metadata: { [field]: true },
              }
            : {}),
          ...(['dispatchPhone', 'dispatchEmail'].includes(field)
            ? {
                provider: { [field]: true },
              }
            : {}),
        },
        filters: [
          {
            metadata: {
              tags: [
                {
                  keyword: [{ terms: [OrganizationTagsEnum.Aas] }],
                },
              ],
            },
            provider: {
              isExternalProvider: [{ term: true }],
            },
          },
        ],
      },
    },
    {
      query: `
      metadata { 
        organizationId 
        organizationName
      }
      billingAddress {
        line1
        line2
        city
        stateUnabbreviated
        zip
      }
      provider {
        dispatchEmail
        dispatchPhone
        dispatchWebsite
        isExternalProvider  
      }`,
    },
  );

export const aasContractQuery = `
  metadata {
    aasContractId
    homeMainContractNo
    homeContractNo
    effectiveDate
    expirationDate
    initialSaleDate
    contractSaleDate
    planType
    locationId
    seqNo
    vip
    planDeductible
    location {
      metadata {
        locationId
        locationDisplayName
        contacts {
          fullName
          phone
          email
        }
        clientTags {
          tagId
          name
        }
      }
      address {
        ${addressFields}
      }
    }
    failCodes{
      aasFailCodeId
      code
      group
      description
    }
  }
`;
export const getAASContracts = async (search: AasContractSearchInput) => queryAasContracts({ search }, { query: aasContractQuery });

// Context: AAS Contracts are Locations under the hood. True AAS Contracts are referred as Contract Data in the app.
export const getAASMainContracts = async (q: string) => {
  return queryLocations(
    {
      search: {
        q,
        size: 25,
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
        // adding filters automatically takes out deactivated ones
        filters: [
          {
            metadata: {
              organizationId: [{ keyword: [{ term: getAasOrganizationId() }] }],
            },
          },
        ],
      },
    },
    {
      query: `
      metadata {
        clientTags {
          tagId
          entityLabel
          name
          style
          organizationId
        }
        homeMainContractNo
        locationId
        contacts {
          fullName
          phone
          email
        }
      }
      aasContract {
        metadata {
          aasContractId
          effectiveDate
          expirationDate
          initialSaleDate
          contractSaleDate
          planType
          seqNo
          vip
        }
      }
      address {
        ${addressFields}
      }`,
    },
  );
};

export const getAASContractFailCodes = async (aasContractId: string) => {
  return queryAasContracts(
    { aasContractId },
    {
      query: `
        metadata { 
          aasContractId
          failCodes {
            aasFailCodeId
            code
            group
            description
          }
        }
    `,
    },
  );
};

export const getBestMatchAASContractsByLocationIdAndFailureDate = async (homeMainContractNo: string, failureDate: string) => {
  const failDateTimeStamp = dayjs(failureDate).utc().startOf('day').valueOf();
  return queryAasContracts(
    {
      search: {
        size: 1,
        filters: [
          {
            metadata: {
              homeMainContractNo: [{ keyword: [{ term: homeMainContractNo }] }],
              contractSaleDate: [{ range: { lte: failDateTimeStamp } }],
              expirationDate: [{ range: { gte: failDateTimeStamp } }],
            },
          },
        ],
        // Get the earliest exp contract that covers fail date
        sortBy: [{ metadata: { expirationDate: SortByElasticSearchOptions.Asc } }],
      },
    },
    {
      query: `
      metadata {
        aasContractId
        locationId
        seqNo
        effectiveDate
        contractSaleDate
        expirationDate
        location {
          address {
            ${addressFields}
          }
        }
      }`,
    },
  );
};

export const createSRQuery = `
  metadata{
    aasContract {
      metadata {
        aasContractId
        homeContractNo
        homeMainContractNo
        effectiveDate
        expirationDate
        initialSaleDate
        planDeductible
        locationId
        vip
        location {
          metadata {
            locationDisplayName
            contacts {
              fullName
              phone
              email
            }
          }
          address {
            ${addressFields}
          }
        }
      }
    }
    requestedProvider{
      metadata {
        organizationId
        organizationName
      }
      billingAddress {
        line1
        line2
        city
        stateUnabbreviated
        zip
      }
      provider {
        dispatchEmail
        dispatchPhone
        dispatchWebsite
        isExternalProvider  
      }
    }
    serviceRequestId
    aasClaimStatus
    failureDate
    clientTags{
      tagId
    }
    shortId
  }
`;
