import { SortByElasticSearchOptions, queryEquipment } from '@equips/entities-schema';
import { categoryGraphQlNode } from '../../../graphql/queries/categoryQueries';

const equipmentQuery = `
equipmentId
metadata {
  equipmentId
  equipmentName
  equipmentStatus
  dispatchTo
  externalId
  locationId
  serviceEndDate
  organizationId
  pinnedNote
  pinnedImageId
  mainContactUserId
  mainContactUser {
    metadata {
      fullName
      phoneNumberWithCountryCode
      email
    }
}
  possibleProviders {
    metadata {
      organizationId
      organizationName
    }
  }
  serialNumber
  specId
  tid
  shortId
}
specMetadata {
  model
  manufacturer
  categoryId
  ${categoryGraphQlNode}
}
`;

export const getEquipmentForLocation = (locationId: string, opts?: { [index: string]: any }) => {
  const { isInternalUser, organizationId } = opts || {};

  return queryEquipment(
    {
      search: {
        sortBy: [{ metadata: { equipmentName: { keyword: SortByElasticSearchOptions.Asc } } }],
        filters: [
          {
            metadata: {
              serviceEndDate: !isInternalUser ? [{ range: { gte: Date.now() } }] : [],
              locationId: locationId ? [{ keyword: [{ term: locationId }] }] : [],
              organizationId: locationId ? [] : [{ keyword: [{ term: organizationId }] }],
            },
          },
          {
            metadata: {
              serviceEndDate: !isInternalUser ? [{ notExists: true }] : [],
              locationId: locationId ? [{ keyword: [{ term: locationId }] }] : [],
              organizationId: locationId ? [] : [{ keyword: [{ term: organizationId }] }],
            },
          },
        ],
        size: 1000,
      },
    },
    { query: equipmentQuery },
  );
};

export const getEquipment = (equipmentId: string) => queryEquipment({ equipmentId }, { query: equipmentQuery });
