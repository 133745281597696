const disallowedEmails = {
  /* Default domains included */
  'aol.com': true,
  'att.net': true,
  'comcast.net': true,
  'facebook.com': true,
  'gmail.com': true,
  'gmx.com': true,
  'googlemail.com': true,
  'google.com': true,
  'hotmail.com': true,
  'hotmail.co.uk': true,
  'mac.com': true,
  'me.com': true,
  'mail.com': true,
  'msn.com': true,
  'live.com': true,
  'sbcglobal.net': true,
  'verizon.net': true,
  'yahoo.com': true,
  'yahoo.co.uk': true,

  /* Other global domains */
  'email.com': true,
  'fastmail.fm': true,
  'games.com' /* AOL */: true,
  'gmx.net': true,
  'hush.com': true,
  'hushmail.com': true,
  'icloud.com': true,
  'iname.com': true,
  'inbox.com': true,
  'lavabit.com': true,
  'love.com' /* AOL */: true,
  'outlook.com': true,
  'pobox.com': true,
  'protonmail.com': true,
  'rocketmail.com' /* Yahoo */: true,
  'safe-mail.net': true,
  'wow.com' /* AOL */: true,
  'ygm.com' /* AOL */: true,
  'ymail.com' /* Yahoo */: true,
  'zoho.com': true,
  'yandex.com': true,

  /* United States ISP domains */
  'bellsouth.net': true,
  'charter.net': true,
  'cox.net': true,
  'earthlink.net': true,
  'juno.com': true,

  /* British ISP domains */
  'btinternet.com': true,
  'virginmedia.com': true,
  'blueyonder.co.uk': true,
  'freeserve.co.uk': true,
  'live.co.uk': true,
  'ntlworld.com': true,
  'o2.co.uk': true,
  'orange.net': true,
  'sky.com': true,
  'talktalk.co.uk': true,
  'tiscali.co.uk': true,
  'virgin.net': true,
  'wanadoo.co.uk': true,
  'bt.com': true,

  /* Domains used in Asia */
  'sina.com': true,
  'sina.cn': true,
  'qq.com': true,
  'naver.com': true,
  'hanmail.net': true,
  'daum.net': true,
  'nate.com': true,
  'yahoo.co.jp': true,
  'yahoo.co.kr': true,
  'yahoo.co.id': true,
  'yahoo.co.in': true,
  'yahoo.com.sg': true,
  'yahoo.com.ph': true,
  '163.com': true,
  '126.com': true,
  'aliyun.com': true,
  'foxmail.com': true,

  /* French ISP domains */
  'hotmail.fr': true,
  'live.fr': true,
  'laposte.net': true,
  'yahoo.fr': true,
  'wanadoo.fr': true,
  'orange.fr': true,
  'gmx.fr': true,
  'sfr.fr': true,
  'neuf.fr': true,
  'free.fr': true,

  /* German ISP domains */
  'gmx.de': true,
  'hotmail.de': true,
  'live.de': true,
  'online.de': true,
  't-online.de' /* T-Mobile */: true,
  'web.de': true,
  'yahoo.de': true,

  /* Italian ISP domains */
  'libero.it': true,
  'virgilio.it': true,
  'hotmail.it': true,
  'aol.it': true,
  'tiscali.it': true,
  'alice.it': true,
  'live.it': true,
  'yahoo.it': true,
  'email.it': true,
  'tin.it': true,
  'poste.it': true,
  'teletu.it': true,

  /* Russian ISP domains */
  'mail.ru': true,
  'rambler.ru': true,
  'yandex.ru': true,
  'ya.ru': true,
  'list.ru': true,

  /* Belgian ISP domains */
  'hotmail.be': true,
  'live.be': true,
  'skynet.be': true,
  'voo.be': true,
  'tvcablenet.be': true,
  'telenet.be': true,

  /* Argentinian ISP domains */
  'hotmail.com.ar': true,
  'live.com.ar': true,
  'yahoo.com.ar': true,
  'fibertel.com.ar': true,
  'speedy.com.ar': true,
  'arnet.com.ar': true,

  /* Domains used in Mexico */
  'yahoo.com.mx': true,
  'live.com.mx': true,
  'hotmail.es': true,
  'hotmail.com.mx': true,
  'prodigy.net.mx': true,

  /* Domains used in Brazil */
  'yahoo.com.br': true,
  'hotmail.com.br': true,
  'outlook.com.br': true,
  'uol.com.br': true,
  'bol.com.br': true,
  'terra.com.br': true,
  'ig.com.br': true,
  'itelefonica.com.br': true,
  'r7.com': true,
  'zipmail.com.br': true,
  'globo.com': true,
  'globomail.com': true,
  'oi.com.br': true,
};

export default disallowedEmails;
