import { queryCoverages, CoverageStatusType, Maybe, postNote, EntityLabelEnum, NoteAuthorizationLevel } from '@equips/entities-schema';
import { notEmpty } from '@equips/common-resources';
import { prompt } from '../../../common/components/Alerts/Prompt';
import {
  toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST,
  toESTDateStringFromUnixMillisecondTimestamp,
} from '../../../common/functions/expensiveDateFunctions';

export const equipmentIsInService = (serviceEndDate: number | null | undefined): boolean => {
  return !serviceEndDate || serviceEndDate >= Date.now();
};

export const equipmentIsInServiceOnContract = (
  serviceEndDate: number | null | undefined,
  { effectiveDate }: { effectiveDate?: number | null },
): boolean => {
  if (!serviceEndDate) return true;

  return serviceEndDate > (effectiveDate || Infinity);
};

export const serviceEndDateIsTodayOrLater = (requestedServiceEndDate: number): void | string => {
  if (requestedServiceEndDate === 0) return;

  return requestedServiceEndDate < Date.now() ? 'The service end date must be today or a future date.' : undefined;
};

export const findSoonestPossibleServiceEndDate = async (
  equipmentIds: Maybe<string>[],
): Promise<{ [equipmentId: string]: void | number }> => {
  const { data } = await queryCoverages(
    {
      search: {
        filters: [
          {
            metadata: {
              equipmentId: [{ keyword: [{ terms: equipmentIds.filter(notEmpty) }] }],
              coverageStatus: [{ keyword: [{ term: CoverageStatusType.Effective }] }],
            },
          },
        ],
      },
    },
    { query: 'metadata {equipmentId offDate}' },
  );

  return (data?.coverages?.data || []).reduce((acc, cur) => {
    const equipmentId = cur?.metadata?.equipmentId || '';
    const offDate = cur?.metadata?.offDate || 0;

    if (acc[equipmentId] && acc[equipmentId] < offDate) {
      acc[equipmentId] = offDate;
    } else if (cur?.metadata?.offDate) {
      acc[equipmentId] = offDate;
    }

    return acc;
  }, {} as { [id: string]: number });
};

export const saveServiceEndDate = async (
  value: string | undefined | null,
  { isInternalUser, equipmentId, soonestPossibleServiceEndDates, patchEquipmentMutation },
): Promise<string | void> => {
  const serviceEndDate = toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST(value);

  if (!serviceEndDate) {
    await patchEquipmentMutation({ equipmentId, metadata: { serviceEndDate: null } });

    return;
  }

  const serviceEndDateIsInPastErrorMessage = serviceEndDateIsTodayOrLater(serviceEndDate);

  if (serviceEndDateIsInPastErrorMessage && !isInternalUser) {
    return serviceEndDateIsInPastErrorMessage;
  } else if (serviceEndDateIsInPastErrorMessage) {
    const message = await prompt('Why are you backdating the service end date?', { label: 'Backdate reason' });

    if (!message || typeof message === 'boolean') {
      return serviceEndDateIsInPastErrorMessage;
    }

    await postNote({
      metadata: {
        parentId: equipmentId,
        parentLabel: EntityLabelEnum.Equipment,
        message,
        authorizationLevel: NoteAuthorizationLevel.EquipsOnly,
      },
    });
  }

  if ((soonestPossibleServiceEndDates?.[equipmentId] || 0) > serviceEndDate) {
    const coverageOffDateInFuture = soonestPossibleServiceEndDates?.[equipmentId];

    return `The date needs to be ${toESTDateStringFromUnixMillisecondTimestamp(coverageOffDateInFuture)} or later.`;
  }

  await patchEquipmentMutation({ equipmentId, metadata: { serviceEndDate: serviceEndDate === 0 ? null : serviceEndDate } });
};
