import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpansionPanelProps {
  expansionPanelClassName?: string;
  expansionPanelSummaryClassName?: string;
  expansionPanelDetailsClassName?: string;
  children: any;
  title: any;
  defaultExpanded?: boolean;
  expandMoreIcon?: any;
  summary?: React.ReactNode;
}

export default function ExpansionPanel({
  defaultExpanded = false,
  children,
  title,
  expansionPanelClassName = '',
  expansionPanelSummaryClassName = 'font-semibold',
  expansionPanelDetailsClassName = '',
  expandMoreIcon = <ExpandMoreIcon />,
  summary = null,
}: ExpansionPanelProps) {
  return (
    <Accordion defaultExpanded={defaultExpanded} className={expansionPanelClassName} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary className={expansionPanelSummaryClassName} expandIcon={expandMoreIcon}>
        {title} {summary}
      </AccordionSummary>
      <AccordionDetails className={`flex flex-wrap ${expansionPanelDetailsClassName}`}>{children}</AccordionDetails>
    </Accordion>
  );
}
