import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import Business from '@mui/icons-material/Business';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { Link } from 'react-router-dom';
import { generateStringTermFilters, queryEquipment } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import createRandomString from '../../../common/functions/createRandomString';
import DropDown from '../../../common/components/DropDown';
import Urls from '../../../routes/Urls';
import Pill from '../../../common/components/Pills/Pill';
import SimpleModal, { simpleModalTypes } from '../../../common/components/Modal/SimpleModal';
import AddEquipmentFormEquipmentModal, { EditEquipmentType } from './AddEquipmentFormEquipmentModal';
import { useEquipmentScreen } from './useAddEquipmentScreen';

function AddEquipmentButton({ startToAddPieceOfEquipment }) {
  return (
    <div className="my-2 w-full">
      <button
        data-testid="equipmentAddAddEquipmentButton"
        onClick={startToAddPieceOfEquipment}
        type="button"
        className="flex w-full items-center justify-center border border-dashed border-gray-400 bg-gray-100 py-2 transition duration-100 ease-out hover:bg-gray-200"
      >
        <span className="text-gray-600">
          <AddCircleOutline fontSize="small" />
        </span>
        <span className="pl-1 text-xs font-semibold text-gray-700">Add Equipment</span>
      </button>
    </div>
  );
}

function EquipmentRowCard({ removeEquipment, duplicateEquipment, editEquipment, ...equipment }) {
  const [duplicateSerialNumberShowing, setDuplicateSerialNumberShowing] = useState(false);
  const { id, categoryName, manufacturer, model, serialNumber, externalId } = equipment;
  const { data } = useQuery(
    ['EquipmentRowCard', serialNumber],
    () =>
      queryEquipment({
        search: {
          filters: [{ metadata: { deactivatedAt: [{ all: true }], ...generateStringTermFilters({ serialNumber: String(serialNumber) }) } }],
        },
      }),
    {
      enabled: !!serialNumber,
      select: (data) => data?.data,
    },
  );
  const equipmentWithSharedSerialNumber = data?.equipment?.data || [];

  return (
    <div className="shadow-xs border border-gray-300 bg-white px-5 hover:shadow">
      <SimpleModal
        title="Equipment sharing serial number"
        subtitle={`The following equipment also have serial number ${serialNumber}`}
        modalType={simpleModalTypes.info}
        isOpen={duplicateSerialNumberShowing}
        handleClose={() => setDuplicateSerialNumberShowing(false)}
      >
        <ul className="py-4">
          {equipmentWithSharedSerialNumber.map((item) => (
            <Link
              className="block rounded-lg border border-gray-200 bg-gray-50 px-2 py-4 text-blue-500 hover:bg-white"
              target="_blank"
              to={`${Urls.EQUIPMENT}/${item?.metadata?.equipmentId}`}
              key={item?.metadata?.equipmentId || ''}
            >
              <Business fontSize="small" />
              <span>{item?.metadata?.equipmentName}</span>
            </Link>
          ))}
        </ul>
      </SimpleModal>
      <div className="flex items-center justify-between gap-2">
        <button
          data-testid="equipmentRow"
          className="w-full py-5 text-left text-sm font-semibold text-gray-800 outline-none"
          type="button"
          onClick={() => {
            editEquipment(id);
          }}
        >
          <div className="flex w-full justify-between gap-2">
            <div>
              <div className="block text-2xs font-base text-gray-500">Category</div>
              <div>{categoryName}</div>
            </div>
            {manufacturer && (
              <div>
                <div className="block text-2xs font-base text-gray-500">Manufacturer</div>
                <div>{manufacturer}</div>
              </div>
            )}
            {model && (
              <div>
                <div className="block text-2xs font-base text-gray-500">Model</div>
                <div>{model}</div>
              </div>
            )}
            {serialNumber && (
              <div>
                <div className="block text-2xs font-base text-gray-500">Serial number</div>
                <div>{serialNumber}</div>
              </div>
            )}
            {externalId && (
              <div>
                <div className="block text-2xs font-base text-gray-500">Internal Id</div>
                <div>{externalId}</div>
              </div>
            )}
            {equipmentWithSharedSerialNumber.length > 0 && (
              <div>
                <Pill
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setDuplicateSerialNumberShowing(true);
                  }}
                  danger
                >
                  Duplicate serial number
                </Pill>
              </div>
            )}
          </div>
        </button>
        <div className="justify-end">
          <DropDown
            actions={[
              { Component: 'Edit', action: () => editEquipment(id) },
              { Component: 'Duplicate', action: () => duplicateEquipment(equipment) },
              { Component: 'Remove', action: () => removeEquipment(id) },
            ]}
            isSmall
            CustomButton={(props) => (
              <IconButton {...props} data-testid="equipmentRowMoreOptions" size="large">
                <MoreVert />
              </IconButton>
            )}
          />
        </div>
      </div>
    </div>
  );
}
EquipmentRowCard.propTypes = {
  removeEquipment: PropTypes.func.isRequired,
  duplicateEquipment: PropTypes.func.isRequired,
  editEquipment: PropTypes.func.isRequired,
};

const generateALocalId = () => createRandomString(15);
const defaultEmptyModalState = null;
const addEquipmentId = 'ADDING_EQUIPMENT';

type DisplayEquipmentAtALocationType = {
  locationId: string;
  locationName: string;
  setEquipment: (e: EditEquipmentType[]) => void;
  equipment?: EditEquipmentType[];
};

export default function DisplayEquipmentAtALocation({
  locationId,
  locationName,
  setEquipment,
  equipment = [],
}: DisplayEquipmentAtALocationType) {
  const numberOfEquipmentAtThisLocation = (equipment && Array.isArray(equipment) && equipment.length) || 0;
  const [localEquipmentIdToEditInModal, setEquipmentEditModal] = useState<string | null>(defaultEmptyModalState);
  const equipmentForModal = !localEquipmentIdToEditInModal ? false : equipment.filter(({ id }) => id === localEquipmentIdToEditInModal)[0];

  const { removeALocation } = useEquipmentScreen();

  const editEquipment = (id) => setEquipmentEditModal(id);

  const handleClose = () => {
    setEquipmentEditModal(defaultEmptyModalState);
  };

  const startToAddPieceOfEquipment = () => {
    setEquipmentEditModal(addEquipmentId);
  };

  const addPieceOfEquipment = (newEquipment: EditEquipmentType) => {
    const copyOfEquipment = [...equipment, { ...newEquipment, id: generateALocalId() }];
    setEquipment(copyOfEquipment);
  };

  const editPieceOfEquipment = (id: string, newEquipment: EditEquipmentType) => {
    const copyOfEquipment = [...equipment];
    const index = copyOfEquipment.map((item) => item?.id).indexOf(id);
    copyOfEquipment[index] = newEquipment;
    setEquipment(copyOfEquipment);
  };

  const removeEquipment = (id: string) => setEquipment(equipment.filter((s) => s.id !== id));

  // eslint-disable-next-line
  const duplicateEquipment = ({ externalId, serialNumber, ...restOfItem }) => {
    setEquipment([...equipment, { ...restOfItem, id: generateALocalId() }]);
  };

  return (
    <div className="relative mb-12 border border-gray-200 shadow">
      <header className="rounded-t-lg bg-gray-800 px-6 py-4">
        <div className="flex items-center justify-between">
          <h3 className="text-sm text-white">{locationName} Equipment</h3>
          {numberOfEquipmentAtThisLocation > 0 && (
            <h4 className="text-2xs text-gray-200">
              {numberOfEquipmentAtThisLocation === 1
                ? `Adding 1 piece of equipment`
                : `Adding ${numberOfEquipmentAtThisLocation} pieces of equipment`}
            </h4>
          )}
        </div>
      </header>
      <section className="bg-white px-6 py-2" data-testid="equipmentRowHolder">
        {equipment.map((item) => (
          <div key={item.id} className="my-2">
            <EquipmentRowCard
              {...item}
              editEquipment={editEquipment}
              removeEquipment={removeEquipment}
              duplicateEquipment={duplicateEquipment}
            />
          </div>
        ))}

        <AddEquipmentButton startToAddPieceOfEquipment={startToAddPieceOfEquipment} />
        {numberOfEquipmentAtThisLocation === 0 && (
          <div className="-mt-2 pt-2 text-center">
            <button
              className="text-2xs text-gray-600 hover:text-blue-500 hover:underline"
              onClick={() => removeALocation({ locationId })}
              type="button"
            >
              Remove location
            </button>
          </div>
        )}
      </section>
      {equipmentForModal && (
        <AddEquipmentFormEquipmentModal
          handleClose={handleClose}
          equipment={equipmentForModal}
          editPieceOfEquipment={editPieceOfEquipment}
        />
      )}
      {localEquipmentIdToEditInModal === addEquipmentId && (
        <AddEquipmentFormEquipmentModal handleClose={handleClose} addPieceOfEquipment={addPieceOfEquipment} />
      )}
    </div>
  );
}
