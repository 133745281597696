import React, { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import { ClientOrganizationEnumType, EntityLabelEnum, Equipment, Maybe, queryEquipment } from '@equips/entities-schema';
import Alert from '@mui/material/Alert';
import { useQuery } from '@tanstack/react-query';
import Checkbox from '../../../common/components/Form/Checkbox';
import Button from '../../../common/components/Buttons/Button';
import { providerValueForFilter } from '../../../graphql/queries/organizationGraphQLQueries';
import OrganizationsSelect from '../../../common/components/Selects/OrganizationsSelect';
import Input from '../../../common/components/Form/Input';
import FormGroup from '../../../common/components/Form/FormGroup';
import Label from '../../../common/components/Form/Label';
import { supportEmailAddress } from '../../../common/constants/contact';
import RadioCard from '../../../common/components/Form/RadioCards';
import DateInput from '../../../common/components/Date/DateInput';
import Textarea from '../../../common/components/Form/Textarea';
import OptionsFormBuilder from '../OptionsFormBuilder';
import {
  dateStringFormattedForHtmlInput,
  toDateStringFromUnixMillisecondTimestamp,
  toUnixMillisecondsFromString,
} from '../../../common/functions/dateFunctions';
import Modal from '../../../common/components/Modal/Modal';
import { useAuth } from '../../../common/auth/AuthContext';
import { useAlert, AlertTypes } from '../../../common/components/Alerts/AlertContext';
import DisplayEquipment from '../../../common/components/ServiceRequest/DisplayEquipment';
import { checkForDuplicateEquipment } from '../../../graphql/queries/equipmentGraphQLQueries';
import { ClientTagsSelect } from '../../../common/components/Selects/ClientTagsSelect';
import { legacyGetEquipmentOptionsConfig } from '../../../common/functions/legacyEquipmentClassHelpers';
import CategorySelect from '../../../common/components/Selects/CategorySelect';
import SubcategorySelect from '../../../common/components/Selects/SubcategorySelect';

export const wantsCoverage = 'wantsCoverage';
export const doesNotWantCoverage = 'noCoverage';

const todaysDateForHtmlInput = dateStringFormattedForHtmlInput(Date.now());

export type EditEquipmentType = {
  id?: string;
  categoryId?: string;
  categoryName?: string;
  subclasses?: string[];
  model?: string;
  manufacturer?: string;
  externalId?: string;
  equipmentName?: string;
  serialNumber?: string;
  providerId?: string;
  wantCoverage?: string;
  note?: string;
  onDate?: string;
  purchaseDate?: number;
  acquisitionDate?: number;
  installationDate?: number;
  options?: { [optionName: string]: any };
  clientTags?: { tagId: string }[];
};
export type EquipmentEditModalType = {
  equipment?: EditEquipmentType;
  handleClose: () => void;
  editPieceOfEquipment?: (id: string, item: EditEquipmentType) => void;
  addPieceOfEquipment?: (item: EditEquipmentType) => void;
};

export function AddEquipmentFormContent({ equipment, editPieceOfEquipment, addPieceOfEquipment, handleClose }: EquipmentEditModalType) {
  const showAlert = useAlert();
  const { userCan, internalUsers, admins, isTrial, auth, clientOrganizationType } = useAuth();
  const [equipmentBeingModified, setEquipmentBeingModified] = useState(equipment ? { ...equipment } : {});
  const [extraOptionsShowing, setExtraOptionsShowing] = useState(true);
  const [duplicateEquipmentWarningOverwrite, setDuplicateEquipmentWarningOverwrite] = useState(false);
  const [duplicateEquipment, setDuplicateEquipment] = useState<Maybe<Equipment>[]>([]);
  const handleChange = (id: string, value) => setEquipmentBeingModified((state) => ({ ...state, [id]: value }));

  const equipmentClassConfig = legacyGetEquipmentOptionsConfig(equipmentBeingModified.categoryId);
  const equipmentOptionsArray = Object.entries(equipmentClassConfig);

  async function checkForDuplicates({ categoryId, manufacturer, model, locationId, serialNumber }) {
    if (categoryId && manufacturer && model && locationId) {
      const { data } = await checkForDuplicateEquipment({ categoryId, manufacturer, model, locationId, serialNumber });
      if (data?.equipment?.data?.[0]) {
        setDuplicateEquipment(data?.equipment?.data ?? []);
        return true;
      } else {
        setDuplicateEquipment([]);
        return false;
      }
    }
  }
  const { data: trialLimitExceeded } = useQuery(
    ['AddEquipmentFormContentTrial'],
    async () => {
      const { data } = await queryEquipment({
        search: {
          includeTotalCount: true,
          filters: [{ metadata: { organizationId: [{ keyword: [{ term: auth?.organizationId }] }] } }],
        },
      });
      return (data?.equipment?.totalCount ?? 0) >= 10;
    },
    { enabled: isTrial },
  );

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();

        const locationId = auth?.locationId;
        const { categoryId, manufacturer, model, serialNumber } = equipmentBeingModified;
        const hasDuplicates = await checkForDuplicates({ categoryId, manufacturer, model, locationId, serialNumber });

        if (hasDuplicates && !duplicateEquipmentWarningOverwrite) {
          showAlert({
            type: AlertTypes.error,
            content: 'Please confirm that this equipment is not a duplicate',
          });
          return;
        }

        if (!equipmentBeingModified.categoryId) {
          showAlert({
            type: AlertTypes.error,
            content: 'Please select a category for the proposed equipment',
          });
          return;
        }

        if (addPieceOfEquipment) addPieceOfEquipment(equipmentBeingModified);
        if (editPieceOfEquipment) editPieceOfEquipment(equipment?.id || '', equipmentBeingModified);
        handleClose();
      }}
      className="w-full"
    >
      <fieldset disabled={trialLimitExceeded}>
        {trialLimitExceeded && (
          <p className="text-red-700">
            Trial accounts are limited to 10 pieces of equipment. Click the trial banner to learn more about the trial.
          </p>
        )}
        {!userCan(admins) && (
          <section className="mb-2 border-b pb-4 pt-3 text-sm">
            If the piece of equipment you need serviced isn't visible, you can propose it to be added. This will need to be approved by your
            Administrator before Service Requests will be dispatched. They will be notified and asked to approve this piece of equipment.
          </section>
        )}
        <section className="border-b border-gray-300 pb-4">
          <div>
            <FormGroup fullWidth>
              <CategorySelect
                key="categorySelect"
                label="Equipment category"
                emptySelectionText="None"
                onChange={(value, _values, labels) => {
                  handleChange('categoryId', value);
                  handleChange('categoryName', labels[0]);
                  handleChange('subclasses', []);
                  handleChange('options', {});
                }}
                selectedCategoryIds={equipmentBeingModified.categoryId}
                fullWidth
                required
              />
            </FormGroup>
            <FormGroup fullWidth>
              <SubcategorySelect
                key="subcategorySelect"
                label="Subcategories"
                values={equipmentBeingModified.subclasses}
                onChange={(values) => handleChange('subclasses', values)}
                fullWidth
                required
              />
            </FormGroup>
          </div>
          {duplicateEquipment.length > 0 && (
            <section className="mb-4 border-b border-gray-300 py-4">
              <Alert severity="error" className="mb-4">
                {duplicateEquipment.length} PIECES OF DUPLICATE EQUIPMENT FOUND
              </Alert>

              {duplicateEquipment.map((equipment, index) => (
                <DisplayEquipment key={index} item={equipment} clickable={true} showLabel={false} />
              ))}

              <Checkbox
                id="duplicateEquipmentWarning"
                label="Propose equipment anyway"
                checked={duplicateEquipmentWarningOverwrite}
                onChange={(event) => setDuplicateEquipmentWarningOverwrite(event.target.checked)}
              />
            </section>
          )}
          <div className="justify-between sm:flex sm:gap-3">
            <FormGroup>
              <Label id="manufacturer" label="Manufacturer" />
              <Input
                placeholder="Manufacturer"
                id="manufacturer"
                type="text"
                value={equipmentBeingModified.manufacturer}
                onChange={(event) => handleChange('manufacturer', event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label id="model" label="Model" />
              <Input
                placeholder="Model"
                id="model"
                type="text"
                value={equipmentBeingModified.model}
                onChange={(event) => handleChange('model', event.target.value)}
              />
            </FormGroup>
          </div>
        </section>
        {userCan(internalUsers) ||
          (clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage && (
            <section className="border-b border-gray-300 py-4">
              <h3 className="pb-1 text-lg font-bold text-gray-700">Coverage</h3>
              <FormGroup fullWidth>
                <RadioCard
                  cardSize="w-full py-1"
                  required
                  id="wantCoverage"
                  label="Would you like to request this piece of equipment to be added to your coverage agreement?"
                  options={[
                    { label: 'Yes', value: wantsCoverage },
                    { label: 'No', value: doesNotWantCoverage },
                  ]}
                  onChange={(value) => handleChange('wantCoverage', value)}
                  value={equipmentBeingModified.wantCoverage || ''}
                />
                {equipmentBeingModified.wantCoverage === wantsCoverage && (
                  <span className="text-xs">Equips will process this coverage and generate a charge.</span>
                )}
              </FormGroup>
              {equipmentBeingModified.wantCoverage === wantsCoverage && (
                <div>
                  <FormGroup fullWidth>
                    <Label label="When should the coverage start?" id="onDate" />
                    <DateInput
                      required
                      onChange={({ target: { value } }) => handleChange('onDate', value)}
                      id="onDate"
                      value={equipmentBeingModified.onDate}
                      min={todaysDateForHtmlInput}
                    />
                  </FormGroup>
                </div>
              )}
            </section>
          ))}
        <section className={`${extraOptionsShowing ? 'flex flex-wrap justify-between border-b border-gray-300 py-4' : 'py-4 text-right'}`}>
          <div>
            {extraOptionsShowing && (
              <>
                <h3 className="pb-1 text-lg font-bold text-gray-700">Details</h3>
                <div className="text-xs">This information is optional, but it helps us provide a better experience.</div>
              </>
            )}
          </div>
          <div>
            <button
              type="button"
              onClick={() => setExtraOptionsShowing((state) => !state)}
              className="text-sm text-equipsLightBlue"
              data-testid="moreOptionsButton"
            >
              {extraOptionsShowing ? 'Fewer options' : 'More options'}
            </button>
          </div>
          <Collapse className="w-full" in={extraOptionsShowing}>
            <div>
              <FormGroup fullWidth>
                <Label id="equipmentName" label="Equipment name" />
                <Input
                  placeholder="Equipment name"
                  id="equipmentName"
                  type="text"
                  value={equipmentBeingModified.equipmentName}
                  onChange={(event) => handleChange('equipmentName', event.target.value)}
                  data-testid="equipmentNameInput"
                />
              </FormGroup>
            </div>
            <div className="justify-between sm:flex sm:gap-3">
              <FormGroup>
                <Label id="serialNumber" label="Serial number" />
                <Input
                  placeholder="Serial number"
                  id="serialNumber"
                  type="text"
                  value={equipmentBeingModified.serialNumber}
                  onChange={(event) => handleChange('serialNumber', event.target.value)}
                  data-testid="serialNumberInput"
                />
              </FormGroup>
              <FormGroup>
                <Label id="externalId" label="Internal ID" />
                <Input
                  placeholder="An internal ID you use"
                  id="externalId"
                  type="text"
                  value={equipmentBeingModified.externalId}
                  onChange={(event) => handleChange('externalId', event.target.value)}
                  data-testid="externalIdInput"
                />
              </FormGroup>
            </div>
            <div>
              <OrganizationsSelect
                organizationId={equipmentBeingModified?.providerId || ''}
                labelHelper={`If you cannot find your provider, please email ${supportEmailAddress}. We will add them for you.`}
                typeOfOrganizationToFind={providerValueForFilter}
                fullWidth
                label="Provider"
                id="providerId"
                changeOrganization={(selection) => handleChange('providerId', selection?.value)}
                placeholder="Search providers"
              />
            </div>
            {equipmentOptionsArray.length > 0 && (
              <div className="my-4 flex flex-wrap justify-between border-b border-t border-gray-300 py-4">
                <div>
                  <h3 className="text-lg font-bold text-gray-700">Options</h3>
                </div>
                <OptionsFormBuilder
                  optionsArray={equipmentOptionsArray}
                  options={equipmentBeingModified.options}
                  setOptions={(newOptions) => handleChange('options', newOptions)}
                />
              </div>
            )}
            <div className="justify-between sm:flex sm:gap-3">
              <FormGroup>
                <Label label="Purchase date" id="purchaseDate" />
                <DateInput
                  onChange={({ target: { value } }) => handleChange('purchaseDate', toUnixMillisecondsFromString(value))}
                  id="purchaseDate"
                  value={toDateStringFromUnixMillisecondTimestamp(equipmentBeingModified.purchaseDate)}
                />
              </FormGroup>
              <FormGroup>
                <Label label="Acquisition date" id="acquisitionDate" />
                <DateInput
                  onChange={({ target: { value } }) => handleChange('acquisitionDate', toUnixMillisecondsFromString(value))}
                  id="acquisitionDate"
                  value={toDateStringFromUnixMillisecondTimestamp(equipmentBeingModified.acquisitionDate)}
                />
              </FormGroup>
              <FormGroup>
                <Label label="Installation date" id="installationDate" />
                <DateInput
                  onChange={({ target: { value } }) => handleChange('installationDate', toUnixMillisecondsFromString(value))}
                  id="installationDate"
                  value={toDateStringFromUnixMillisecondTimestamp(equipmentBeingModified.installationDate)}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup fullWidth>
                <ClientTagsSelect
                  label={userCan(internalUsers) ? 'Client Tags' : 'Tags'}
                  entityLabel={EntityLabelEnum.Equipment}
                  onChange={(clientTags) => handleChange('clientTags', clientTags)}
                  organizationId={auth?.organizationId ?? ''}
                  fullWidth
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup fullWidth>
                <Label label="Note" id="equipmentNote" />
                <Textarea
                  id="equipmentNote"
                  value={equipmentBeingModified.note}
                  onChange={(event) => handleChange('note', event.target.value)}
                />
              </FormGroup>
            </div>
          </Collapse>
        </section>
        {!userCan(admins) && (
          <section className="mb-2 border-b py-4 text-sm">
            This will create a piece of proposed equipment. Select this from the appropriate drop down and complete your service request.
            When proposed equipment is approved by an Administrator, service requests can be dispatched.
          </section>
        )}
        <section className="sticky bottom-0 flex items-center justify-end bg-white py-4">
          <Button type="submit" blue className="px-6" data-testid="submitEquipmentAddModal">
            {userCan(admins) ? 'Save' : 'Propose'} Equipment
          </Button>
        </section>
      </fieldset>
    </form>
  );
}

export default function AddEquipmentFormEquipmentModal({
  equipment = {},
  handleClose,
  editPieceOfEquipment,
  addPieceOfEquipment,
}: EquipmentEditModalType) {
  return (
    <Modal
      handleClose={handleClose}
      title={editPieceOfEquipment ? 'Edit Equipment' : 'Add Equipment'}
      color="bg-gray-200"
      isDismissible
      isLarge
    >
      <div className="p-4">
        <AddEquipmentFormContent {...{ equipment, handleClose, editPieceOfEquipment, addPieceOfEquipment }} />
      </div>
    </Modal>
  );
}
