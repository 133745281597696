import { ElasticsearchSearchTypesEnum, querySpecs, Maybe, Spec, SpecsSearchInput } from '@equips/entities-schema';
import { createOrUpdateSpec } from '../mutations/SpecMutations';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';
import { defaultColumns } from '../../common/components/Table/defaultColumns';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import { TableFetch } from '../../common/components/DataTable';
import { categoryGraphQlNode } from './categoryQueries';

export const getSpecByModelManufacturerCategory = async ({ model, manufacturer, categoryId }) =>
  querySpecs(
    {
      search: {
        filters: [
          {
            metadata: {
              model: model ? [{ keyword: [{ term: model }] }] : [{ keyword: [{ exists: false }] }],
              manufacturer: manufacturer ? [{ keyword: [{ term: manufacturer }] }] : [{ keyword: [{ exists: false }] }],
              categoryId: [{ keyword: [{ term: categoryId }] }],
            },
          },
        ],
      },
    },
    {
      query: `
    metadata {
      specId
      shortId
      model
      manufacturerEOL
      ${categoryGraphQlNode}
    }`,
    },
  );

export const getOrCreateSpecIdByModelManufacturerCategory = async ({ model, manufacturer, categoryId = '', manufacturerEOL = null }) => {
  const response = await getSpecByModelManufacturerCategory({
    model,
    manufacturer,
    categoryId,
  });
  const specs = response?.data?.specs?.data;

  if (!specs || !specs.length) {
    console.log('Spec is missing. Creating it...');

    const response = await createOrUpdateSpec({
      isCreate: true,
      manufacturer,
      model,
      manufacturerEOL,
      categoryId,
      verified: false,
    });

    return response?.data?.post?.specId;
  }

  return specs[0]?.metadata?.specId;
};

export const getSpecsBySearch = async (searchQuery) =>
  querySpecs(
    { search: { q: searchQuery } },
    {
      query: `
      metadata {
        specId
        shortId
        model
        ${categoryGraphQlNode}
      }`,
    },
  );

export const getSpecById = async ({ specId }) =>
  querySpecs(
    { specId },
    {
      query: `
    metadata {
      specId
      verified
      ${categoryGraphQlNode}
      manufacturer
      manufacturerEOL
      vendorLimitedSupportDate
      industry
      model
      imageId
      msrp
      introducedToMarketDate
      features 
      standardRate
      comparableMCRate
      equipsAverageRateCalculation
      mostRecentEffectiveCoverageCalculation {
        mostRecentEffectiveCoverage {
          dailyCoveragePrice
        }
      }
      equipsAverageInvoiceTotalCalculation
      equipsActiveEquipmentData {
        activeEquipmentCount,
        uniqueOrganizationCount,
      }
      equipsServiceRequestCount
    }`,
    },
  );

export const getSpecsForMainTable: TableFetch<Maybe<Spec>> = async ({
  sortBy,
  globalFilter,
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}) => {
  const query = `
  metadata {
    ${defaultColumns.createdColumnQueryString}
    ${defaultColumns.modifiedColumnQueryString}
    specId
    shortId
    manufacturer
    manufacturerEOL
    model
    ${categoryGraphQlNode}
    industry
    vendorLimitedSupportDate
    verified
  }`;

  const search: SpecsSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: filterMap
      ? [
          {
            metadata: {
              ...(filterMap['metadata.verified'] ? { verified: [{ term: filterMap['metadata.verified'] === 'verified' }] } : {}),
              ...(filterMap['metadata.industry'] ? { industry: [{ keyword: [{ term: filterMap['metadata.industry'] }] }] } : {}),
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              manufacturerEOL: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.manufacturerEOL']),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              ...(filterMap['metadata.createdByUserId']
                ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.modifiedByUserId']
                ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.showDeactivated'] ? { deactivatedAt: [{ exists: true }] } : {}),
              vendorLimitedSupportDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(
                filterMap['metadata.vendorLimitedSupportDate'],
              ),
            },
          },
        ]
      : [],
  };

  const { data } = await querySpecs({ search }, { query });

  return { totalCount: data?.specs?.totalCount, data: data?.specs?.data ?? [] };
};
