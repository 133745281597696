import { InvoiceStatusEnumType } from '@equips/entities-schema';

export const NEW = InvoiceStatusEnumType.New;
export const ADJUDICATED = InvoiceStatusEnumType.Adjudicated;
export const APPROVED = InvoiceStatusEnumType.Approved;
export const FINALIZED = InvoiceStatusEnumType.Finalized;
export const IMPORTED = InvoiceStatusEnumType.Imported;
export const REVIEW = InvoiceStatusEnumType.Review;
export const HOLD = InvoiceStatusEnumType.Hold;

export const invoiceStatuses = {
  new: { name: 'New', label: 'New', value: NEW },
  approved: { name: 'Approved', label: 'Approved', value: APPROVED },
  adjudicated: { name: 'Adjudicated', label: 'Adjudicated', value: ADJUDICATED },
  finalized: { name: 'Finalized', label: 'Finalized', value: FINALIZED },
  imported: { name: 'Imported', label: 'Imported', value: IMPORTED },
  review: { name: 'Review', label: 'Review', value: REVIEW },
  hold: { name: 'Hold', label: 'Hold', value: HOLD },
  externalDataEntry: { value: InvoiceStatusEnumType.ExternalDataEntry, name: 'Not submitted' },
  readyForAdjudication: { value: InvoiceStatusEnumType.ReadyForAdjudication, name: 'Ready for adjudication' },
  AAS_FINALIZED: { value: InvoiceStatusEnumType.AasFinalized, name: 'Finalized', label: 'Finalized' },
};

export const invoiceStatusesForAAS = {
  new: { name: 'New', label: 'New', value: NEW },
  approved: { name: 'Approved', label: 'Approved', value: APPROVED },
  AAS_FINALIZED: { value: InvoiceStatusEnumType.AasFinalized, name: 'Finalized', label: 'Finalized' },
  denied: { name: 'Denied', label: 'Denied', value: InvoiceStatusEnumType.Denied },
};
