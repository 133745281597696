import React from 'react';
import { AasContract, AasFeatures } from '@equips/entities-schema';
import { Link } from 'react-router-dom';
import Urls from '../../../routes/Urls';
import { TableColumn } from '../../../common/components/DataTable';
import { TableFilterTypes } from '../../../common/components/DataTable/TableFilters';
import { defaultColumnWidths } from '../../../common/components/DataTable/columns';
import { toDateStringFromUnixMillisecondTimestamp } from '../../../common/functions/dateFunctions';
import { arrayToGrammaticalList } from '../../../common/functions/arrayToGrammaticalList';

export default function AasContractsDataColumns(): TableColumn<AasContract>[] {
  return [
    {
      id: 'metadata.homeContractNo',
      Header: 'Home Contract No.',
      accessor: (data) => data?.metadata?.homeContractNo,
      Cell: (data) => {
        return (
          <>
            <div className="flex gap-4">
              <Link className="active font-bold" to={`${Urls.AAS_CONTRACTS_DATA}/${data.row.original?.metadata?.aasContractId}`}>
                {data.value}
              </Link>
            </div>
          </>
        );
      },
      toText: (data) => data.value,
    },
    {
      id: 'metadata.homeMainContractNo',
      Header: 'Home Main Contract No.',
      accessor: (data) => data?.metadata?.homeMainContractNo,
      Cell: (data) => (
        <Link className="active font-bold" to={`${Urls.AAS_CONTRACTS}/${data.row.original?.metadata?.locationId}`}>
          {data.value}
        </Link>
      ),
    },
    {
      id: 'metadata.seqNo',
      Header: 'Seq. No.',
      accessor: (data) => data?.metadata?.seqNo,
    },
    {
      id: 'metadata.locationId',
      Header: 'Location',
      accessor: (data) => data?.metadata?.location?.metadata?.locationDisplayName,
      width: defaultColumnWidths.mediumText,
      filterOptions: {
        type: TableFilterTypes.locationSelect,
      },
    },
    {
      id: 'metadata.effectiveDate',
      Header: 'Effective Date',
      accessor: (data) => data?.metadata?.effectiveDate,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { toUTC: true }) || 'N/A',
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.expirationDate',
      Header: 'Expiration Date',
      accessor: (data) => data?.metadata?.expirationDate,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { toUTC: true }) || 'N/A',
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.contractSaleDate',
      Header: 'Contract Sale Date',
      accessor: (data) => data?.metadata?.contractSaleDate,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { toUTC: true }) || 'N/A',
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.initialSaleDate',
      Header: 'Initial Sale Date',
      accessor: (data) => data?.metadata?.initialSaleDate,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { toUTC: true }) || 'N/A',
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.planType',
      Header: 'Plan Type',
      accessor: (data) => data?.metadata?.planType,
      disableSortBy: true,
    },
    {
      id: 'metadata.status',
      Header: 'Status',
      accessor: (data) => data?.metadata?.status,
      disableSortBy: true,
    },
    {
      id: 'metadata.statusSecondary',
      Header: 'Status Secondary',
      accessor: (data) => data?.metadata?.statusSecondary,
      disableSortBy: true,
    },
    {
      id: 'metadata.squareFootage',
      Header: 'Square Footage',
      accessor: (data) => data?.metadata?.squareFootage,
      disableSortBy: true,
    },
    {
      id: 'metadata.dealerName',
      Header: 'Dealer Name',
      accessor: (data) => data?.metadata?.dealerName,
    },
    {
      id: 'metadata.features',
      Header: 'Features',
      accessor: (data) => data?.metadata?.features,
      Cell: (data) => arrayToGrammaticalList(data?.value?.map((x) => (x ? AasFeatures[x] : ''))) || [],
      disableSortBy: true,
    },
    {
      id: 'metadata.planDeductible',
      Header: 'Plan Deductible',
      accessor: (data) => data?.metadata?.planDeductible,
    },
    {
      id: 'metadata.planMonths',
      Header: 'Plan Months',
      accessor: (data) => data?.metadata?.planMonths,
    },
    {
      id: 'metadata.obligor',
      Header: 'Obligor',
      accessor: (data) => data?.metadata?.obligor,
    },
  ];
}
