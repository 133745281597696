import {
  ElasticsearchSearchTypesEnum,
  UsersQueryUsersArgs,
  queryUsers,
  User,
  Maybe,
  UserAuthorizationRoleEnum,
} from '@equips/entities-schema';
import { TableFetch } from '../../common/components/DataTable';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import { UserSelectDialogContext } from '../../common/components/Selects/UserSelectDialog';
import { gql } from '../graphqlHelperFunctions';
import { postToUsersEndpoint as untypedPostToUsersEndpoint } from '../postToEndpoint';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';
import { toTerms } from '../../common/functions/elasticSearchHelpers';
import { isAasOrganizationId } from '../../common/functions/aasHelpers';
import { defaultColumns } from './../../common/components/Table/defaultColumns';

const postToUsersEndpoint = async ({ query, variables }: { query: string; variables: UsersQueryUsersArgs }) => {
  return untypedPostToUsersEndpoint({ query, variables });
};

export const getUsersByOrganizationIdForSelect = async ({ organizationId }) =>
  queryUsers(
    { search: { filters: [{ metadata: { organizationId: [{ keyword: [{ term: organizationId }] }] } }] } },
    {
      query: `metadata{fullName userId}`,
    },
  );

export const getUserForDispatch = async ({ userId }) =>
  postToUsersEndpoint({
    query: gql`
      query ($userId: String) {
        users(userId: $userId) {
          data {
            metadata {
              fullName
              phoneNumberWithCountryCode
              email
            }
          }
        }
      }
    `,
    variables: { userId },
  });

export const getUserById = async (userId) =>
  queryUsers(
    { userId },
    {
      query: `
        metadata {
          organizationId
          favoriteSavedViewId
          fullName
          locationId
          locationIds
          savedViewsIds {
            tableId
            savedViewId
          }
          authorizationRole
          receiveEmailNotifications
          phoneNumberWithCountryCode
          phoneNumberExtension
          email
          status
          tags
        }`,
    },
  );

export const getUsersBySearch = async (searchQuery) =>
  postToUsersEndpoint({
    query: gql`
      query ($search: UsersSearchInput) {
        users(search: $search) {
          data {
            metadata {
              fullName
              userId
            }
          }
        }
      }
    `,
    variables: { search: { q: searchQuery } },
  });

export const getAllUsersForThisLocationOrOrganizationWithSearch = async ({
  q,
  organizationId,
  locationId,
  context,
  excludedTags,
}: {
  q?: string;
  organizationId?: string;
  locationId?: string;
  context?: UserSelectDialogContext;
  excludedTags?: any;
}) => {
  // openServiceRequestCount is slow, so using where needed only
  const isAssignInternalUsersContext = context === UserSelectDialogContext.AssignInternalUsers;
  const openServiceRequestCount = !isAasOrganizationId(organizationId) && isAssignInternalUsersContext ? 'openServiceRequestCount' : '';

  return queryUsers(
    {
      search: {
        q,
        sortBy: transformTableSortToGraphQLSort([{ id: 'metadata.fullName', desc: false }]),
        searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
        filters: [
          ...(locationId || organizationId
            ? [
                {
                  metadata: {
                    ...(locationId ? { locationIds: [{ keyword: [{ terms: [locationId] }] }] } : {}),
                    ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
                    ...(excludedTags ? { tags: [{ keyword: [{ notTerms: excludedTags }] }] } : {}),
                  },
                },
              ]
            : []),
          {
            metadata: {
              authorizationRole: [
                {
                  keyword: [
                    {
                      terms: [
                        UserAuthorizationRoleEnum.CustomerOrganizationAdmin,
                        UserAuthorizationRoleEnum.GlobalAdmin,
                        UserAuthorizationRoleEnum.SuperGlobalAdmin,
                      ],
                    },
                  ],
                },
              ],
              ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
            },
          },
        ],
      },
    },
    {
      query: `
          metadata {
            fullName 
            userId 
            tags 
            phoneNumberWithCountryCode 
            email 
            ${openServiceRequestCount}
          }
      `,
    },
  );
};

export const getUsersForMainTable: TableFetch<Maybe<User>> = async ({
  filterMap,
  pageIndex,
  pageSize,
  sortBy,
  globalFilter,
  includeTotalCount = false,
}) => {
  let referredByOrgFilter;
  if (filterMap['referredByOrg']?.substring(0, 5) === 'false') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ notTerm: filterMap['referredByOrg'].substring(5) }] }] };
  } else if (filterMap['referredByOrg']?.substring(0, 4) === 'true') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ term: filterMap['referredByOrg'].substring(4) }] }] };
  }
  const { data } = await queryUsers(
    {
      search: {
        q: globalFilter,
        size: pageSize,
        from: pageSize * pageIndex,
        searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
        sortBy: transformTableSortToGraphQLSort(sortBy),
        includeTotalCount,
        filters: [
          {
            metadata: {
              ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
              ...(filterMap['metadata.authorizationRole']
                ? { authorizationRole: [{ keyword: [{ term: filterMap['metadata.authorizationRole'] }] }] }
                : {}),
              ...(filterMap['metadata.tags'] ? { tags: [{ keyword: [{ terms: [filterMap['metadata.tags']] }] }] } : {}),
              ...(filterMap['metadata.organizationId']
                ? { organizationId: [{ keyword: [{ term: filterMap['metadata.organizationId'] }] }] }
                : {}),
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              ...(filterMap['metadata.createdByUserId']
                ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
                : {}),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              ...(filterMap['metadata.modifiedByUserId']
                ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
                : {}),
            },
            organizationMetadata: {
              ...(filterMap['referredByOrg'] ? referredByOrgFilter : {}),
            },
            tagMetadata: {
              tagId: !filterMap['tagMetadata.tagId']?.includes('none')
                ? toTerms<string>(filterMap['tagMetadata.tagId'])
                : [{ keyword: [{ exists: false }] }],
            },
          },
        ],
      },
    },
    {
      query: `
            metadata {
              ${defaultColumns.createdColumnQueryString}
              ${defaultColumns.modifiedColumnQueryString}
              shortId
              fullName
              userId
              favoriteSavedViewId 
              defaultTagId
              authorizationRole
              email
              status
              tags
              clientTags {
                tagId
                name 
                style
              }
              locationId
              organizationId
              locationId
              deactivatedAt
              location {
                metadata {
                  locationDisplayName
                }
              }
              organization {
                metadata {
                  organizationName
                  organizationId
                }
              }
            }
    `,
    },
  );

  return { data: data?.users?.data ?? [], totalCount: data?.users?.totalCount };
};
export const getUserForAuthorization = async ({ sub }) =>
  postToUsersEndpoint({
    query: gql`
      query GetUserQuery($userId: String) {
        users(userId: $userId) {
          data {
            metadata {
              authorizationRole
              email
              featureFlags
              fullName
              locationId
              organizationId
              phoneNumberWithCountryCode
              tags
              userId
              favoriteSavedViewId
              favoriteSavedView {
                metadata {
                  tableState
                  tableId
                  visibleColumns
                }
              }
              orgHasActiveContract
              stikkyDataRedirectUrlToken
              profilePicture {
                presignedGetUrl
              }
              organization {
                provider {
                  isExternalProvider
                }
                metadata {
                  accountManager {
                    metadata {
                      fullName
                    }
                  }
                  accountManagerId
                  customHomeContent
                  customHomeEnabled
                  clientOrganizationType
                  optInWorkOrderStatuses
                  deactivatedAt
                  urlDomain
                  featureFlags
                  industry
                  isTrial
                  organizationBillingMethod
                  organizationName
                  producers
                  disallowProposedEquipment
                  disallowRequestNewEquipmentPurchase
                  allowSRGeolocation
                  referringOrganizationId
                  logo {
                    presignedGetUrl
                    presignedPutUrl
                  }
                  submissionSettings {
                    access
                    method
                    requireApproval
                    instructions
                    triageLocationId
                    requireTriageLocation
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: { userId: sub },
  });

export const getUserNotificationSettings = async (userId: Maybe<string>) =>
  queryUsers(
    { userId },
    {
      query: `
        metadata {
          requestedSmsNumber
          organizationId
          authorizationRole
          organization {
            metadata {
              industry
            }
          }
          locationIds
          notificationUnsubscribe
          notificationLocationIds
          notificationCategoryIds
          notificationProposedEquipmentLocationIds
          notificationSettings {
            unsubscribedTemplatesByMethod {
              value
              email
              sms
            }
          }
          organization{
            metadata{
              clientOrganizationType
              optInWorkOrderStatuses
            }
          }
        }
        `,
    },
  );

export const getProposedUsers: TableFetch<Maybe<User>> = async ({
  filterMap,
  pageIndex,
  pageSize,
  sortBy,
  globalFilter,
  includeTotalCount = false,
}) => {
  const { data } = await queryUsers(
    {
      search: {
        size: pageSize,
        from: pageSize * pageIndex,
        sortBy: transformTableSortToGraphQLSort(sortBy),
        includeTotalCount,
        filters: [
          {
            metadata: {
              authorizationRole: [
                {
                  keyword: [
                    {
                      terms: [UserAuthorizationRoleEnum.ProposedCustomer, UserAuthorizationRoleEnum.ProposedProvider],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      query: `
            metadata {
              ${defaultColumns.createdColumnQueryString}
              ${defaultColumns.modifiedColumnQueryString}
              shortId
              fullName
              userId
              authorizationRole
              email
              status
              tags
            }
    `,
    },
  );

  return { data: data?.users?.data ?? [], totalCount: data?.users?.totalCount };
};

export const getProposedUserCount = async () =>
  queryUsers(
    {
      search: {
        includeTotalCount: true,
        filters: [
          {
            metadata: {
              authorizationRole: [
                {
                  keyword: [
                    {
                      terms: [UserAuthorizationRoleEnum.ProposedCustomer, UserAuthorizationRoleEnum.ProposedProvider],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    { query: `metadata{ userId }` },
  );

export const getUserTimeTracking = async ({ userId, from, to }: { userId: string; from: number; to: number }) =>
  queryUsers(
    { userId },
    {
      query: `
      metadata {
        userId
        timeTracking(from: ${from}, to: ${to}) {
          serviceRequestId
          shortId
          title
          createdAt
          actions {
            metadata {
              actionId
              startedAt
              completedAt
              status
              type
              createdByUserId
              serviceRequestId
              modifiedAt
            }
          }
        }
      }`,
    },
  );

export const getUserActivities = async ({ userId, from, to }: { userId: string; from: number; to: number }) =>
  queryUsers(
    { userId },
    {
      query: `
      activity(from: ${from}, to: ${to}) {
        after
        before
        time
        idLabel
        user {
          metadata {
            fullName
          }
        }
      }`,
    },
  );
