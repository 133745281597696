import { cleanObject } from '../../common/functions/cleanObject';
import { deactivateMutation, reactivateMutation, deleteMutation } from '../commonMutations';
import { gql } from '../graphqlHelperFunctions';
import { postToOrganizationsEndpoint } from '../postToEndpoint';

export const deactivateOrganization = async ({ entityId }) =>
  postToOrganizationsEndpoint({ query: deactivateMutation, variables: { entityId } });

export const reactivateOrganization = async ({ entityId }) =>
  postToOrganizationsEndpoint({ query: reactivateMutation, variables: { entityId } });

export const deleteOrganization = async ({ entityId }) => postToOrganizationsEndpoint({ query: deleteMutation, variables: { entityId } });

export const bulkDeleteOrganizations = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { organizationId } = entity.metadata;

      await deleteOrganization({ entityId: organizationId });
    }),
  );
};

export const bulkDeactivateOrganizations = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { organizationId } = entity.metadata;

      return await deactivateOrganization({ entityId: organizationId });
    }),
  );
};

export const bulkReactivateOrganizations = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { organizationId } = entity.metadata;

      return await reactivateOrganization({ entityId: organizationId });
    }),
  );
};

export const patchOrganization = async ({ organizationId, patchFields }) => {
  const query = gql`
    mutation PatchesTheOrganization($organizationId: String!, $metadata: OrganizationMetadataPatchInput!) {
      patch(organizationId: $organizationId, metadata: $metadata) {
        metadata {
          organizationId
        }
      }
    }
  `;

  return postToOrganizationsEndpoint({
    query,
    variables: {
      organizationId,
      metadata: {
        ...patchFields,
      },
    },
  });
};

const mutateOrganization = async (query, organization) => {
  const variables = { ...organization };

  return await postToOrganizationsEndpoint({ query, variables: cleanObject(variables) });
};

const postQuery = gql`
  mutation OrganizationPostMutation(
    $metadata: OrganizationMetadataPostInput!
    $billingAddress: AddressPostInput
    $shippingAddress: AddressPostInput
    $provider: ProviderInput
  ) {
    post(metadata: $metadata, billingAddress: $billingAddress, shippingAddress: $shippingAddress, provider: $provider) {
      metadata {
        organizationId
      }
    }
  }
`;
const patchQuery = gql`
  mutation RootMutation(
    $organizationId: String!
    $metadata: OrganizationMetadataPatchInput!
    $billingAddress: AddressPatchInput
    $shippingAddress: AddressPatchInput
    $provider: ProviderInput
  ) {
    patch(
      organizationId: $organizationId
      metadata: $metadata
      billingAddress: $billingAddress
      shippingAddress: $shippingAddress
      provider: $provider
    ) {
      metadata {
        organizationId
      }
    }
  }
`;

export const createOrganization = async (organization) => mutateOrganization(postQuery, organization);
export const updateOrganization = async (organization) => mutateOrganization(patchQuery, organization);

export const bulkPatchOrganization = async ({ patchField, patchValue, arrayOfEntityObject }) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { organizationId } = entity.metadata;
      await patchOrganization({ organizationId, patchFields: { [patchField]: patchValue } });
    }),
  );
};

export const patchOrganizationProviderData = async ({ organizationId, patchFields, patchMetadataFields }) => {
  const query = gql`
    mutation PatchesTheOrganizationProviderData(
      $organizationId: String!
      $provider: ProviderInput!
      $metadata: OrganizationMetadataPatchInput
    ) {
      patch(organizationId: $organizationId, provider: $provider, metadata: $metadata) {
        provider {
          dispatchEmail
        }
      }
    }
  `;

  return postToOrganizationsEndpoint({
    query,
    variables: {
      organizationId,
      provider: { ...patchFields },
      metadata: { ...patchMetadataFields },
    },
  });
};

export const bulkPatchOrganizationProviderData = async ({ patchField, patchValue, arrayOfEntityObject }) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { organizationId } = entity.metadata;
      await patchOrganizationProviderData({ organizationId, patchFields: { [patchField]: patchValue } });
    }),
  );
};
