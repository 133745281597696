import React, { useEffect, useState } from 'react';
import { dateTimeStringFormattedForHtmlInput, toDateStringFromUnixMillisecondTimestamp } from '../../functions/dateFunctions';
import Input from '../Form/Input';

interface DateInputProps {
  onChange?: (any) => any;
  className?: string;
  value: number | string | null;
  loading?: boolean;
  tiny?: boolean;
  isWhite?: boolean;
  helper?: any;
  id: string;
  required?: boolean;
  [x: string]: any;
}

const DEFAULT_TIME = '00:00';

export default function DateTimeInput({
  className = '',
  loading = false,
  value,
  onChange = () => null,
  tiny = false,
  helper = null,
  isWhite = false,
  required = false,
  id = '',
  restOfProps,
}: DateInputProps) {
  const [state, setState] = useState<{
    date: string;
    time: string;
  }>(() => ({
    date: value && typeof value === 'number' ? toDateStringFromUnixMillisecondTimestamp(value, { customFormatString: 'YYYY-MM-DD' }) : '',
    time: value && typeof value === 'number' ? toDateStringFromUnixMillisecondTimestamp(value, { customFormatString: 'HH:mm:ss' }) : '',
  }));

  useEffect(() => {
    let dateTime: Date | null;

    if (state.date && state.time) {
      dateTime = new Date(`${state.date} ${state.time}`);
    } else if (state.date && !state.time) {
      dateTime = new Date(`${state.date} ${DEFAULT_TIME}`);
    } else {
      dateTime = null;
    }

    dateTime && dateTimeStringFormattedForHtmlInput(dateTime)
      ? onChange(dateTimeStringFormattedForHtmlInput(dateTime))
      : onChange(dateTime);
  }, [state]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setState((s) => ({
      ...s,
      [event.target.type]: event.target.value,
    }));
  }

  return (
    <div className="m-1 flex flex-col gap-2 border p-2">
      <Input
        id={id}
        className={className}
        loading={loading}
        tiny={tiny}
        isWhite={isWhite}
        placeholder="mm/dd/yyyy"
        value={state.date}
        type="date"
        onChange={handleChange}
        required={required}
      />
      <Input
        id={id}
        className={className}
        loading={loading}
        tiny={tiny}
        isWhite={isWhite}
        value={state.time}
        type="time"
        onChange={handleChange}
        required={required}
      />
      {helper && <p>{helper}</p>}
    </div>
  );
}
