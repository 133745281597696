import React, { EventHandler, useState } from 'react';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import MoreHoriz from '@mui/icons-material/MoreHoriz';

export type DropDownAction =
  | {
      Component: React.ReactNode;
      action?: (event: React.MouseEvent) => any;
      to?: string;
      href?: string;
      target?: any;
      [prop: string]: any;
    }
  | { border: boolean };

export type DropDownProps = {
  actions: DropDownAction[];
  toolTipTitle?: string;
  containerClasses?: string;
  isSmall?: boolean;
  children?: React.ReactElement;
  /** @deprecated Pass as child */
  CustomButton?: (args: { onClick: EventHandler }) => React.ReactElement;

  testId?: string;
  buttonTestId?: string;
  menuTestId?: string;
  [prop: string]: any;
};

export default function DropDown({
  actions = [],
  toolTipTitle = 'More actions',
  containerClasses = '',
  isSmall = false,
  children,
  CustomButton,

  testId = 'DropDown',
  buttonTestId = 'DropDownButton',
  menuTestId = '',
  ...restOfProps
}: DropDownProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const itemClassName = `rounded w-full block hover:bg-gray-100 hover:text-gray-900 text-left text-gray-700 text-sm px-4 ${
    isSmall ? 'py-1' : 'py-2'
  }`;

  const customButton = children ? React.cloneElement(children, { onClick: handleClick }) : CustomButton?.({ onClick: handleClick });

  return (
    <div data-testid={testId} className={`relative flex ${containerClasses}`} {...restOfProps}>
      {customButton ? (
        customButton
      ) : (
        <Tooltip title={toolTipTitle}>
          <button
            className={`${isSmall ? 'px-1' : 'px-2'} rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50`}
            type="button"
            data-testid={buttonTestId}
            onClick={handleClick}
          >
            <MoreHoriz />
          </button>
        </Tooltip>
      )}

      <Menu
        PaperProps={{ style: { minWidth: 175 } }}
        data-testid={menuTestId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((item, index) => {
          if ('border' in item) {
            return item.border ? (
              <div key={index} className="py-1">
                <div className="border-t border-gray-300" />
              </div>
            ) : null;
          }

          const { Component, to, href, target, action, ...restOfData } = item;

          if (to) {
            return (
              <Link to={to} target={target || ''} key={index} className={itemClassName} onClick={handleClose} {...restOfData}>
                {Component}
              </Link>
            );
          } else if (href) {
            return (
              <a href={href} target={target || ''} key={index} className={itemClassName} onClick={handleClose} {...restOfData}>
                {Component}
              </a>
            );
          } else {
            return (
              <button
                type="button"
                onClick={async (event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  await action?.(event);
                  handleClose();
                }}
                key={index}
                className={itemClassName}
                {...restOfData}
              >
                {Component}
              </button>
            );
          }
        })}

        {(!actions || actions.length === 0) && (
          <div className="block w-full px-1 py-2 text-left text-base text-gray-700 hover:bg-gray-200">No available actions</div>
        )}
      </Menu>
    </div>
  );
}
