import React, { useRef } from 'react';
import PropTypes from 'prop-types';

export const badgeTypes = { alert: 1 };

export default function Badge({ children, badgeType }) {
  const badgeRef = useRef();
  let color = '';

  if (badgeType === badgeTypes.alert) {
    color = 'border border-white bg-red-500 text-white';
  }

  return (
    <span
      ref={badgeRef}
      className={`${color} p-1 shadow-lg flex items-center justify-center rounded-full leading-none text-sm absolute top-0 right-0`}
      style={{ transform: `scale(1) translate(30%, -30%)`, height: `${badgeRef?.current?.offsetWidth}px` || '16px' }}
    >
      {children}
    </span>
  );
}
Badge.propTypes = {
  children: PropTypes.any.isRequired,
  badgeType: PropTypes.oneOf(Object.values(badgeTypes)).isRequired,
};
