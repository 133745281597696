import {
  ElasticsearchSearchTypesEnum,
  SortByElasticSearchOptions,
  queryContracts,
  Maybe,
  Contract,
  ContractsSearchInput,
} from '@equips/entities-schema';
import { gql } from '../graphqlHelperFunctions';
import { postToContractsEndpoint } from '../postToEndpoint';
import { NoteListFields } from '../../common/components/ViewPage/NoteListFragment';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';
import { toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST } from '../../common/functions/expensiveDateFunctions';
import { TableFetch } from '../../common/components/DataTable';
import { defaultColumns } from './../../common/components/Table/defaultColumns';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from './../../common/components/Date/DateRangeFilter';

export const getContractDates = async ({ contractId }) => queryContracts({ contractId });

export const getContractNameById = async ({ contractId }) => queryContracts({ contractId });

export const getAllContractsByOrganizationId = async ({ organizationId, size }) =>
  queryContracts({
    search: {
      size,
      filters: [
        {
          metadata: {
            organizationId: [{ keyword: [{ term: organizationId }] }],
          },
        },
      ],
      sortBy: [{ metadata: { expirationDate: SortByElasticSearchOptions.Desc } }],
    },
  });

export const getContractNotes = async ({ entityId }) => {
  const data = await queryContracts(
    { contractId: entityId },
    {
      query: `notes { ${NoteListFields} }`,
    },
  );
  return data.data?.contracts?.data?.[0]?.notes ?? [];
};
const customDateFunctionParams = {
  customDatestringToTimestampFunction: toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST,
};

export const getContractsForMainTable: TableFetch<Maybe<Contract>> = async ({
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}) => {
  const query = gql`
    organizationBillingAddress {
      stateUnabbreviated
    }
    metadata {
      accountManager{
        metadata {
          fullName
        }
      }
      underwriter {
        metadata {
          fullName
        }
      }
      shortId
      contractId
      expirationDate
      expired
      effectiveDate
      coverageProviderId
      contractType
      ${defaultColumns.createdColumnQueryString}
      ${defaultColumns.modifiedColumnQueryString}
      coverageProvider {
        metadata {
          organizationName
        }
      }
      totalPmHours
      segmentCode
      contractStatus
    }
    organizationMetadata {
      organizationName
      organizationId
      industry
      producers
    }
  `;

  const search: ContractsSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: filterMap
      ? [
          {
            metadata: {
              ...((filterMap['metadata.organizationId']?.length || 0) > 0
                ? { organizationId: [{ keyword: [{ terms: filterMap['metadata.organizationId'] }] }] }
                : {}),
              ...(filterMap['metadata.underwriterId']
                ? { underwriterId: [{ keyword: [{ term: filterMap['metadata.underwriterId'] }] }] }
                : {}),
              ...(filterMap['metadata.accountManagerId']
                ? { accountManagerId: [{ keyword: [{ term: filterMap['metadata.accountManagerId'] }] }] }
                : {}),
              ...(filterMap['metadata.coverageProviderId']
                ? { coverageProviderId: [{ keyword: [{ term: filterMap['metadata.coverageProviderId'] }] }] }
                : {}),
              ...(filterMap['metadata.contractStatus']
                ? { contractStatus: [{ keyword: [{ term: filterMap['metadata.contractStatus'] }] }] }
                : {}),
              ...(filterMap['metadata.contractType']
                ? { contractType: [{ keyword: [{ term: filterMap['metadata.contractType'] }] }] }
                : {}),
              effectiveDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(
                filterMap['metadata.effectiveDate'],
                customDateFunctionParams,
              ),
              expirationDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(
                filterMap['metadata.expirationDate'],
                customDateFunctionParams,
              ),
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              ...(filterMap['metadata.createdByUserId']
                ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.modifiedByUserId']
                ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.showDeactivated'] ? { deactivatedAt: [{ exists: true }] } : {}),
            },
            organizationMetadata: {
              ...(filterMap['organizationMetadata.industry']
                ? { industry: [{ keyword: [{ term: filterMap['organizationMetadata.industry'] }] }] }
                : {}),
            },
            organizationBillingAddress: {
              ...(filterMap['organizationBillingAddress.stateUnabbreviated']
                ? { stateUnabbreviated: [{ keyword: [{ term: filterMap['organizationBillingAddress.stateUnabbreviated'] }] }] }
                : {}),
            },
          },
        ]
      : [],
  };

  const { data } = await queryContracts({ search }, { query });

  return { totalCount: data?.contracts?.totalCount, data: data?.contracts?.data ?? [] };
};

export const getContractsByMetadataField = async ({ value, field, showActivatedAndDeactivated }) =>
  postToContractsEndpoint({
    query: gql`
      query ($search: ContractsSearchInput) {
        contracts(search: $search) {
          data {
            metadata {
              contractId
              shortId
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 200,
        from: 0,
        searchFields: { metadata: { [field]: true } },
        filters: [
          {
            metadata: {
              ...(showActivatedAndDeactivated ? { deactivatedAt: [{ all: true }] } : {}),
              [field]: { keyword: [{ term: value }] },
            },
          },
        ],
      },
    },
  });
