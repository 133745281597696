import React from 'react';
import { OrganizationSelectDialogWithInputButton } from '../Selects/OrganizationSelectDialog';
import { clientValueForFilter } from '../../../graphql/queries/organizationGraphQLQueries';
import { useAuth } from '../../auth/AuthContext';

export default function ChangeOrganization({
  label = 'Organization',
  typeOfOrganizationToFind = clientValueForFilter,
  organizationId,
  setOrganizationId,
}) {
  const { userCan, internalUsers } = useAuth();

  return (
    <>
      {userCan(internalUsers) && (
        <OrganizationSelectDialogWithInputButton
          fullWidth
          organizationId={organizationId}
          handleSelect={({ organizationId }) => setOrganizationId(organizationId)}
          label={label}
          typeOfOrganizationToFind={typeOfOrganizationToFind}
        />
      )}
    </>
  );
}
