import React from 'react';
import PropTypes from 'prop-types';
import './brandedSpinnerStyles.css';

export default function BrandedSpinner({ message }) {
  return (
    <div className="m-auto branded-spinner">
      <div className="logo" />
      <div className="loader" />
      <div className="pb-12 mt-6 text-sm text-gray-800 ">{message || 'Loading...'}</div>
    </div>
  );
}

BrandedSpinner.propTypes = {
  message: PropTypes.string,
};
