import React from 'react';
import PropTypes from 'prop-types';
import { formatAddress } from '@equips/common-resources';

function AddressColumn({ addressObject }) {
  const [partOne] = formatAddress(addressObject);

  return <>{partOne}</>;
}

AddressColumn.propTypes = { addressObject: PropTypes.object };
AddressColumn.defaultProps = { addressObject: {} };

export default AddressColumn;
