import React, { useState } from 'react';
import CloseTwoTone from '@mui/icons-material/CloseTwoTone';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Maybe, User } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AuthorizationData, useAuth } from '../../auth/AuthContext';
import DraggableDialog from '../Modal/DraggableDialog';
import Input from '../Form/Input';
import Button from '../Buttons/Button';
import LoaderCards from '../Loaders/LoaderCards';
import EmptyComponent from '../EmptyComponent';
import { getAllUsersForThisLocationOrOrganizationWithSearch } from '../../../graphql/queries/userGraphQLQueries';
import ChangeOrganization from '../ServiceRequest/ChangeOrganization';

export enum UserSelectDialogContext {
  Default = 'default',
  AssignInternalUsers = 'assignInternalUsers',
  FilterAssignedInternalUser = 'filterAssignedInternalUsers',
}

function DisplaySelectableUser({ user, handleSelect, context }) {
  const { t } = useTranslation();
  const userId = user?.metadata?.userId;
  const fullName = user?.metadata?.fullName;
  const openSRCount = user?.metadata?.openServiceRequestCount;
  const isAssignInternalUsersContext = context === UserSelectDialogContext.AssignInternalUsers;

  return (
    <button
      data-testid="userSelectDialogButton"
      data-userid={userId}
      className="block w-full border-b border-gray-200 px-4 py-4 text-left text-lg text-gray-800 hover:bg-blue-100"
      type="button"
      onClick={() => handleSelect({ user, userId, fullName })}
    >
      <div className="item-start flex flex-col justify-between sm:flex-row sm:items-center">
        <div>
          <span className="pr-2 text-gray-600">
            <AccountCircle color="inherit" />
          </span>{' '}
          {fullName}
        </div>
        {Boolean(openSRCount) && isAssignInternalUsersContext && (
          <div className="w-full text-center text-sm font-light text-gray-500 sm:w-24 sm:text-left">
            {t('openRequestsWithCount', { count: openSRCount })}
          </div>
        )}
      </div>
    </button>
  );
}

type UserSelectDialogProps = {
  organizationId: string;
  locationId?: string;
  handleSelect: any;
  open: boolean;
  handleClose: any;
  existingSearch?: string;
  showUnassigned?: boolean;
  context?: UserSelectDialogContext;
  showAllUsers?: boolean;
};

export default function UserSelectDialog({
  organizationId,
  locationId,
  handleSelect,
  open,
  handleClose,
  existingSearch,
  showUnassigned,
  context = UserSelectDialogContext.Default,
  showAllUsers = false,
}: UserSelectDialogProps) {
  const { t } = useTranslation();
  const [q, setQ] = useState(existingSearch);
  const [users, setUsers] = useState<Maybe<User>[]>([]);

  const { auth, clientOrganizationType, isAasUser } = useAuth();

  const [defaultOrganizationId, setDefaultOrganizationId] = useState<string>(organizationId ?? auth?.organizationId ?? '');

  const userId = auth?.userId;
  const fullName = auth?.fullName;

  const blankUserToAdd = {
    metadata: {
      userId: null,
      fullName: t('unassigned'),
    },
  };

  const currentUser = { user: { metadata: auth }, userId, fullName };

  const { isLoading } = useQuery(
    ['UserSelectDialog', q, defaultOrganizationId, locationId, context, showAllUsers],
    () =>
      getAllUsersForThisLocationOrOrganizationWithSearch({
        q,
        organizationId: defaultOrganizationId,
        locationId: !showAllUsers ? locationId : '',
        context,
      }),
    {
      select: (data) => data?.data,
      onSuccess: (data) => {
        const allUsers = data?.users?.data || [];
        setUsers(showUnassigned ? [blankUserToAdd, ...allUsers] : allUsers);
      },
    },
  );

  const selectUser = (data: {
    user: { metadata: AuthorizationData | null | undefined };
    userId: Maybe<string>;
    fullName: Maybe<string>;
  }) => {
    handleSelect(data);
    handleClose();
  };

  return (
    <DraggableDialog onClose={() => handleClose()} aria-labelledby="locationSelect" open={open} fullWidth maxWidth="xs">
      <header
        id="draggable-dialog"
        style={{ cursor: 'move' }}
        className={`flex flex-wrap items-center justify-between border-b border-gray-300 bg-white p-4`}
      >
        {!isAasUser && clientOrganizationType?.startsWith('equips') && (
          <ChangeOrganization organizationId={defaultOrganizationId} setOrganizationId={setDefaultOrganizationId} />
        )}
        <div className="space-between flex w-2/3 flex-auto pr-2">
          <Input
            autoFocus
            className="no-drag-zone"
            id="searchQueryForUsers"
            value={q || ''}
            onChange={(event: { target: { value: React.SetStateAction<string | undefined> } }) => setQ(event.target.value)}
            placeholder={t('search')}
          />
          &nbsp;
          {q && (
            <Button tabIndex={-1} gray tiny className="ml-2 text-sm text-gray-700" type="button" onClick={() => setQ('')}>
              {t('clear')}
            </Button>
          )}
          &nbsp;
          <Button className="w-32" tabIndex={0} blue tiny type="button" onClick={() => selectUser(currentUser)}>
            {t('assignToMe')}
          </Button>
        </div>
        <div>
          <button tabIndex={-1} onClick={() => handleClose()} type="button" className="text-gray-500">
            <CloseTwoTone fontSize="small" />
          </button>
        </div>
      </header>
      <section>
        <div className="w-full overflow-y-scroll pb-10" style={{ height: '350px' }}>
          {!isLoading && users.length === 0 && <EmptyComponent />}
          {!isLoading &&
            users.map((user) => (
              <DisplaySelectableUser user={user} key={user?.metadata?.userId || ''} context={context} handleSelect={selectUser} />
            ))}
          {isLoading && <LoaderCards />}
        </div>
      </section>
    </DraggableDialog>
  );
}
