import React, { useState } from 'react';
import Button from '../Buttons/Button';
import { RichTextEditor } from '../RichTextEditor';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';

export const MAX_CONTENT_SIZE = 300 * 1000; //300kb limit in bytes
interface DescriptionEditorProps {
  description: string;
  loading: boolean;
  onSave: any;
  refetching: boolean;
}

function DescriptionEditor({ description, loading, onSave, refetching }: DescriptionEditorProps) {
  const [html, setHtml] = useState(description);
  const [showEditor, setShowEditor] = useState(false);

  const showAlert = useAlert();

  return (
    <div className="p-3 text-sm">
      {!showEditor ? (
        <>
          <div className="prose max-w-xs break-words text-sm sm:max-w-xl" dangerouslySetInnerHTML={{ __html: description }}></div>
          {!loading && onSave && (
            <div className="my-3 flex justify-end">
              <Button
                blue
                disabled={refetching}
                onClick={() => {
                  setHtml(description);
                  setShowEditor(true);
                }}
              >
                Edit
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="prose text-sm">
            <RichTextEditor defaultValue={html} onChange={setHtml} disableImagePaste={true} />
          </div>
          <div className="my-3 flex justify-end gap-2">
            <Button disabled={loading || refetching} blue onClick={() => setShowEditor(false)}>
              Cancel
            </Button>
            <Button
              disabled={loading || refetching || !html}
              onClick={() => {
                if (html) {
                  const contentSize = new Blob([html], { type: 'text/html' }).size;

                  if (contentSize < MAX_CONTENT_SIZE) {
                    onSave(html);
                    setShowEditor(false);
                  } else {
                    showAlert({
                      content: 'Exceeded max size of description content',
                      type: AlertTypes.warning,
                    });
                  }
                }
              }}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export { DescriptionEditor };
