export const buildEquipmentDescription = ({
  manufacturer,
  model,
  serialNumber,
  categoryName,
}: {
  manufacturer?: string | null;
  model?: string | null;
  serialNumber?: string | null;
  categoryName?: string | null;
}) => {
  if (!manufacturer && !model && !serialNumber) return categoryName;

  return `${manufacturer ? `${manufacturer} ` : ''}${model ? `${model} ` : ''}${serialNumber ? `, Serial #: ${serialNumber}` : ''}`;
};
