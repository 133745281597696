import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  components: {
    MuiNativeSelect: {
      styleOverrides: {
        outlined: {
          padding: '8px 32px 8px 8px !important',
        },
      },
    },
  },
  palette: {
    primary: { main: '#2c5282' },
    secondary: { main: '#48bb78', contrastText: '#FFFFFF' },
  },
});
