import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

type SvgIconComponent = typeof SvgIcon;

export interface ActionButtonProps extends React.SelectHTMLAttributes<HTMLButtonElement> {
  text: string;
  Icon: SvgIconComponent;
  color?: 'green' | 'red' | 'blue' | 'gray' | 'orange';
}

const colors = {
  green: 'border-green-500 hover:border-green-600 hover:bg-green-100',
  red: 'border-red-500 hover:border-red-600 hover:bg-red-100',
  blue: 'border-blue-500 hover:border-blue-600 hover:bg-blue-100',
  gray: 'border-gray-500 hover:border-gray-600 hover:bg-gray-100',
  orange: 'border-orange-500 hover:border-orange-600 hover:bg-orange-100',
};

export const actionButtonTextColors = {
  green: 'text-equipsGreen',
  red: 'text-red-500',
  blue: 'text-equipsLightBlue',
  gray: 'text-gray-500',
  orange: 'text-equipsOrange',
};

export default function ActionButton({ text, Icon, color, ...restOfProps }: ActionButtonProps) {
  return (
    <button
      type="button"
      data-testid={`${text.toLowerCase()}ButtonAction`}
      key={text}
      className={`flex w-16 flex-wrap items-center justify-center px-1 focus:outline-none `}
      {...restOfProps}
    >
      <span
        style={{ padding: '0.3rem' }}
        className={`flex items-center justify-center rounded-full border-2 hover:shadow ${color ? colors[color] : colors['blue']}`}
      >
        <Icon fontSize="small" className={`${color ? actionButtonTextColors[color] : actionButtonTextColors['blue']}`} />
      </span>
      <span className={`block w-full pt-1 text-2xs ${color ? actionButtonTextColors[color] : actionButtonTextColors['blue']}`}>{text}</span>
    </button>
  );
}
