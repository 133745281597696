import { UserAuthorizationRoleEnum } from '@equips/entities-schema';
import { rolesWithAuthData, roleTypes } from '../../graphql/enums';

const { ADMIN } = roleTypes;
export const roles = {
  [UserAuthorizationRoleEnum.SuperGlobalAdmin]: UserAuthorizationRoleEnum.SuperGlobalAdmin,
  [UserAuthorizationRoleEnum.GlobalAdmin]: UserAuthorizationRoleEnum.GlobalAdmin,
  [UserAuthorizationRoleEnum.CustomerOrganizationAdmin]: UserAuthorizationRoleEnum.CustomerOrganizationAdmin,
  [UserAuthorizationRoleEnum.CustomerLocationAdmin]: UserAuthorizationRoleEnum.CustomerLocationAdmin,
  [UserAuthorizationRoleEnum.CustomerEmployee]: UserAuthorizationRoleEnum.CustomerEmployee,
  [UserAuthorizationRoleEnum.ProposedCustomer]: UserAuthorizationRoleEnum.ProposedCustomer,
  [UserAuthorizationRoleEnum.ProviderOrganizationAdmin]: UserAuthorizationRoleEnum.ProviderOrganizationAdmin,
  [UserAuthorizationRoleEnum.ProviderLocationAdmin]: UserAuthorizationRoleEnum.ProviderLocationAdmin,
  [UserAuthorizationRoleEnum.ProviderEmployee]: UserAuthorizationRoleEnum.ProviderEmployee,
  [UserAuthorizationRoleEnum.ProposedProvider]: UserAuthorizationRoleEnum.ProposedProvider,
  [UserAuthorizationRoleEnum.EmailOnlyAuth]: UserAuthorizationRoleEnum.EmailOnlyAuth,
};

export const rolesForSelect = (authorizationRole: UserAuthorizationRoleEnum) => {
  const currentRoleType = rolesWithAuthData[authorizationRole].type;
  const currentRolePriority = rolesWithAuthData[authorizationRole].priority;

  const arrayOfRoleNames = Object.keys(rolesWithAuthData);

  const d = arrayOfRoleNames.filter((roleName) => {
    const { type, priority } = rolesWithAuthData[roleName];

    if (currentRolePriority < priority) return false;
    if (currentRoleType === ADMIN) return true;
    if (currentRoleType === type) return true;

    return false;
  });

  return d;
};

export const allRoles = [
  UserAuthorizationRoleEnum.SuperGlobalAdmin,
  UserAuthorizationRoleEnum.GlobalAdmin,
  UserAuthorizationRoleEnum.CustomerOrganizationAdmin,
  UserAuthorizationRoleEnum.CustomerLocationAdmin,
  UserAuthorizationRoleEnum.CustomerEmployee,
  UserAuthorizationRoleEnum.ProviderOrganizationAdmin,
  UserAuthorizationRoleEnum.ProviderLocationAdmin,
  UserAuthorizationRoleEnum.ProviderEmployee,
];

export const internalUsers = [UserAuthorizationRoleEnum.SuperGlobalAdmin, UserAuthorizationRoleEnum.GlobalAdmin];

export const internalUsersPlusCOA = [...internalUsers, UserAuthorizationRoleEnum.CustomerOrganizationAdmin];

export const admins = [
  ...internalUsers,
  UserAuthorizationRoleEnum.CustomerOrganizationAdmin,
  UserAuthorizationRoleEnum.ProviderOrganizationAdmin,
];

export const organizationAdmins = [
  UserAuthorizationRoleEnum.CustomerOrganizationAdmin,
  UserAuthorizationRoleEnum.ProviderOrganizationAdmin,
];

export const customers = [
  UserAuthorizationRoleEnum.CustomerOrganizationAdmin,
  UserAuthorizationRoleEnum.CustomerLocationAdmin,
  UserAuthorizationRoleEnum.CustomerEmployee,
];

export const nonProviders = [
  ...internalUsers,
  UserAuthorizationRoleEnum.CustomerOrganizationAdmin,
  UserAuthorizationRoleEnum.CustomerLocationAdmin,
  UserAuthorizationRoleEnum.CustomerEmployee,
];

export const providers = [
  UserAuthorizationRoleEnum.ProviderEmployee,
  UserAuthorizationRoleEnum.ProviderLocationAdmin,
  UserAuthorizationRoleEnum.ProviderOrganizationAdmin,
];
