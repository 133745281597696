import React, { useState, forwardRef, Ref } from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import Business from '@mui/icons-material/Business';
import Email from '@mui/icons-material/Email';
import Language from '@mui/icons-material/Language';
import LocationOn from '@mui/icons-material/LocationOn';
import Phone from '@mui/icons-material/Phone';
import { Maybe, Organization, queryOrganizationsWithAnyUserRole } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationsForSelect } from '../../../graphql/queries/organizationGraphQLQueries';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import SearchableDialogShell, { SearchableDialogShellProps } from './SearchableDialogCore';
import { OrganizationSelectTypes } from './OrganizationsSelect';

interface Props {
  entity: Maybe<Organization>;
  handleSelect: (value: any) => void;
  selected?: string;
}

const SelectDisplay = forwardRef(function SelectDisplay({ entity, handleSelect, selected }: Props, ref: Ref<HTMLDivElement>) {
  const organizationId = entity?.metadata?.organizationId;
  const organizationName = entity?.metadata?.organizationName;
  const address = formatAddressAsOneLine(entity?.billingAddress);
  const isExternalProvider = entity?.provider?.isExternalProvider;
  const email = entity?.provider?.dispatchEmail;
  const phone = entity?.provider?.dispatchPhone;
  const website = entity?.provider?.dispatchWebsite;

  const isSelected = selected === organizationId;

  return (
    <div ref={isSelected ? ref : null} className={isSelected ? 'bg-gray-300' : ''}>
      <button
        data-testid="organizationSelectDialogButton"
        data-organizationid={organizationId}
        className={`flex w-full flex-wrap items-center border-b border-gray-200 px-4 py-4  md:px-8 ${
          !isSelected ? 'hover:bg-blue-100' : ''
        }`}
        type="button"
        onClick={() => handleSelect({ organizationId, organizationName })}
      >
        <div className="text-gray-600">
          <Business color="inherit" />
        </div>
        <div className="flex-1 space-y-1 pl-2 text-left md:pl-6">
          <div className="text-lg text-gray-800">{organizationName}</div>
          <div className="flex items-center gap-1 text-sm text-gray-700">
            <LocationOn fontSize="inherit" />
            {address.length ? address : 'N/A'}
          </div>
          {isExternalProvider && (
            <div className="space-y-1 text-sm text-gray-700">
              {email && (
                <div className="flex items-center gap-1">
                  <Email fontSize="inherit" /> {email}
                </div>
              )}
              {phone && (
                <div className="flex items-center gap-1">
                  <Phone fontSize="inherit" /> {phone}
                </div>
              )}
              {website && (
                <div className="flex items-center gap-1">
                  <Language fontSize="inherit" /> {website}
                </div>
              )}
            </div>
          )}
        </div>
      </button>
    </div>
  );
});

const modalOptions = { hidden: 0, organization: 2 };

type OrganizationSelectDialogProps = Pick<
  SearchableDialogShellProps,
  'saving' | 'handleClose' | 'handleSelect' | 'open' | 'selected' | 'searchQuery'
> &
  Pick<OrganizationSelectTypes, 'typeOfOrganizationToFind' | 'organizationTags'>;

export default function OrganizationSelectDialog({
  saving = false,
  handleSelect,
  open,
  handleClose,
  typeOfOrganizationToFind,
  organizationTags = [],
  selected,
  searchQuery,
}: OrganizationSelectDialogProps) {
  const getOrganizationsBySearch = async ({ q = searchQuery ?? '' } = {}) => {
    return getOrganizationsForSelect({ search: q, typeOfOrganizationToFind, organizationTags });
  };

  return (
    <SearchableDialogShell
      saving={saving}
      maxWidth="sm"
      SelectDisplay={SelectDisplay}
      graphQLQuery={getOrganizationsBySearch}
      entityName="organizations"
      handleSelect={handleSelect}
      open={open}
      handleClose={handleClose}
      selected={selected}
      searchQuery={searchQuery}
    />
  );
}

export const OrganizationSelectDialogWithInputButton = ({
  fullWidth = false,
  label,
  organizationId,
  handleSelect,
  typeOfOrganizationToFind,
  emptyText = '',
}) => {
  const [modalShowing, setModalShowing] = useState(modalOptions.hidden);

  const { data } = useQuery(
    ['OrganizationSelectDialogWithInputButton', organizationId],
    () => queryOrganizationsWithAnyUserRole({ organizationId }, { query: 'metadata { organizationName }' }),
    {
      enabled: !!organizationId,
      select: (data) => data?.data,
    },
  );

  const organizationName = data?.anyRoleQueryOrganizations?.data?.[0]?.metadata?.organizationName || emptyText;

  return (
    <>
      <FormGroup fullWidth={fullWidth}>
        <Label label={label} id="organizationLabel" />
        <button
          className="w-full rounded border border-gray-300 bg-white p-2 text-left text-gray-900 hover:border-gray-400"
          type="button"
          id="organizationLabel"
          data-testid="organizationLabel"
          onClick={() => setModalShowing(modalOptions.organization)}
        >
          {organizationName}
        </button>
      </FormGroup>

      <OrganizationSelectDialog
        open={modalShowing === modalOptions.organization}
        handleClose={() => setModalShowing(modalOptions.hidden)}
        typeOfOrganizationToFind={typeOfOrganizationToFind}
        handleSelect={handleSelect}
      />
    </>
  );
};
