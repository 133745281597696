import { deactivateMutation, reactivateMutation, deleteMutation } from '../commonMutations';
import { gql } from '../graphqlHelperFunctions';
import { postToSpecsEndpoint } from '../postToEndpoint';

export const deactivateSpec = async (entityId) => postToSpecsEndpoint({ query: deactivateMutation, variables: { entityId } });

export const reactivateSpec = async (entityId) => postToSpecsEndpoint({ query: reactivateMutation, variables: { entityId } });

export const deleteSpec = async (entityId) => postToSpecsEndpoint({ query: deleteMutation, variables: { entityId } });

export const bulkReactivateSpecs = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { specId } = entity.metadata;

      await reactivateSpec(specId);
    }),
  );
};

export const bulkDeactivateSpecs = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { specId } = entity.metadata;

      await deactivateSpec(specId);
    }),
  );
};

export const bulkDeleteSpecs = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { specId } = entity.metadata;

      await deleteSpec(specId);
    }),
  );
};

const createASpec = gql`
  mutation ($metadata: SpecMetadataPostInput!) {
    post(metadata: $metadata) {
      specId
      metadata {
        specId
        manufacturer
        model
        categoryId
        manufacturerEOL
        vendorLimitedSupportDate
      }
    }
  }
`;
const updateASpec = gql`
  mutation ($specId: String!, $metadata: SpecMetadataPatchInput!) {
    patch(specId: $specId, metadata: $metadata) {
      specId
      metadata {
        specId
        manufacturer
        model
        categoryId
        manufacturerEOL
        vendorLimitedSupportDate
      }
    }
  }
`;

export const createOrUpdateSpec = async ({
  specId = '',
  isCreate,
  industry = null,
  manufacturer,
  model,
  manufacturerEOL,
  categoryId,
  verified = true,
  vendorLimitedSupportDate,
  msrp = 0,
  introducedToMarketDate,
  features = '',
}) => {
  const query = isCreate ? createASpec : updateASpec;
  const variables = {
    specId: specId,
    metadata: {
      specId: !isCreate ? specId : undefined,
      categoryId,
      industry,
      manufacturer,
      model,
      manufacturerEOL,
      vendorLimitedSupportDate,
      verified,
      msrp,
      introducedToMarketDate,
      features,
    },
  };

  return postToSpecsEndpoint({ query, variables });
};
