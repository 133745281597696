import React, { useEffect, useState } from 'react';
import { msToTime } from '../../functions/dateFunctions';

interface DisplayElapsedTimeProps {
  value?: number;
  inProgress?: boolean;
}

export function DisplayElapsedTime({ value, inProgress }: DisplayElapsedTimeProps) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (inProgress) {
      const interval = setInterval(() => {
        setTime((prev) => prev + 1000);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [inProgress]);

  return <div>{msToTime((value || 0) + time)}</div>;
}
