import { useState, useEffect } from 'react';
import { safelyParseJson } from '@equips/common-resources';

let hasInitialized = false;

const getInitialValue = (key: string, { type }) => {
  const storage = window.localStorage.getItem(key);
  if (!storage) return '';

  if (storage && type === 'json') {
    return safelyParseJson(storage);
  }
};

export default function useLocalStorage(
  key: string,
  initialValueIfNoneFound: any = '',
  { type = 'json' } = {},
): [any, (...args: any[]) => void] {
  const [value, setValue] = useState(() => getInitialValue(key, { type }) || initialValueIfNoneFound);

  useEffect(() => {
    if (hasInitialized) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      hasInitialized = true;
    }
  }, [value, key]);

  return [value, setValue];
}
