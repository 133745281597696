import { EntityLabelEnum, NoteAuthorizationLevel, NotesTagsEnum } from '@equips/entities-schema';
import React, { useState } from 'react';
import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Build from '@mui/icons-material/Build';
import SimpleModal from '../../../../common/components/Modal/SimpleModal';
import { useAuth } from '../../../../common/auth/AuthContext';
import config from '../../../../config';
import { AlertTypes, useAlert } from '../../../../common/components/Alerts/AlertContext';
import ActionButton from '../../../../common/components/Buttons/ActionButton';
import NoteForm from '../../../../common/components/ViewPage/NoteForm';
import Input from '../../../../common/components/Form/Input';
import Label from '../../../../common/components/Form/Label';
import Button from '../../../../common/components/Buttons/Button';

interface PartRequestProps {
  serviceRequestId: string;
}

export function PartRequestForm({ serviceRequestId, onCompleted }: { serviceRequestId: string; onCompleted?: () => void }) {
  const { auth } = useAuth();
  const showAlert = useAlert();
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');

  const { endpoint } = config.apiGateway;
  const partRequestEndpoint = `${endpoint}/part-request`;

  const queryClient = useQueryClient();

  const { mutate: postPartRequestNote, isLoading } = useMutation(
    async () => {
      if (!auth) {
        await axios.post(
          partRequestEndpoint,
          { serviceRequestId, description, price },
          { headers: { 'Content-Type': 'application/json' } },
        );
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['NoteListWithForm', serviceRequestId]);
        showAlert({ type: AlertTypes.success, content: 'Thank you for submitting an approval request.' });
      },
      onError: () =>
        showAlert({ type: AlertTypes.error, content: 'An error occurred posting the approval request.  Please try again later.' }),
    },
  );

  return (
    <>
      <Label id="description" label="Description" required />
      <NoteForm
        parentLabel={EntityLabelEnum.ServiceRequest}
        entityId={serviceRequestId}
        tags={[NotesTagsEnum.PartRequest]}
        defaultCheckedVisibleTo={NoteAuthorizationLevel.All}
        onInputChange={({ target: { value } }) => setDescription(value.trim())}
        formatMessage={(message) => `An approval request has been submitted. Description: ${message}, Price: ${price}`}
        allowInvoices
        overrideSaveButton={({ submitForm, saving }) => (
          <Button
            data-testid="partRequestSubmitButton"
            disabled={saving || !description || !price}
            className="mx-1 mt-2 px-8"
            loading={saving || isLoading}
            type="submit"
            onClick={async () => {
              if (auth) {
                await submitForm();
              }
              postPartRequestNote();
              if (onCompleted) {
                onCompleted();
              }
            }}
          >
            Submit
          </Button>
        )}
      >
        <div className="py-4">
          <Label id="price" label="Price" required />
          <Input
            type="currency"
            id="price"
            data-testid="price"
            value={price}
            onChange={({ target: { value } }) => setPrice(value.trim())}
            required
          />
        </div>
      </NoteForm>
    </>
  );
}

export default function PartRequest({ serviceRequestId }: PartRequestProps) {
  const [formOpen, setFormOpen] = useState(false);

  return (
    <>
      <ActionButton data-testid="requestPartButton" onClick={() => setFormOpen(true)} text="Request Approval" Icon={Build} color="green" />
      <SimpleModal
        title="Request Approval"
        subtitle="Request approval for expensive repair"
        isOpen={formOpen}
        handleClose={() => setFormOpen(false)}
        testId="formModal"
      >
        <div className="pt-8">
          <PartRequestForm serviceRequestId={serviceRequestId} onCompleted={() => setFormOpen(false)} />
        </div>
      </SimpleModal>
    </>
  );
}
