export const equipsUrlDomain = 'equips.com';
export const supportEmailAddress = 'service@equips.com';
export const dispatchEmailAddress = 'service@equips.com';
export const operationEmailAddress = 'operations@equips.com';
export const feedbackEmailAddress = 'feedback@equips.com';
export const formattedPhoneNumber = '(866) 324-4508';
export const phoneNumberWithOnlyNumbers = '8663244508';
export const equipsCompanyName = 'Equips';
export const equipsAddressLine1 = 'PO Box 427';
export const equipsCity = 'Menomonee Falls';
export const equipsState = 'WI';
export const equipsZip = '53052';
export const equipsCityStateZip = `${equipsCity}, ${equipsState} ${equipsZip}`;
