import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Input from '../Form/Input';
import { getLocationByLocationId } from '../../../graphql/queries/locationGraphQLQueries';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../functions/phoneFunctions';
import UserSelectDialog from './UserSelectDialog';

type MainContactSelectProps = {
  locationId: string;
  organizationId: string;
  handleSave: (userId: string) => any;
};

export const MainContactLocationSelect = ({ locationId, organizationId, handleSave }: MainContactSelectProps) => {
  const [userSelectShowing, setUserSelectShowing] = useState(false);

  const { data } = useQuery(['getLocationByLocationId', locationId], () => getLocationByLocationId({ locationId }), {
    enabled: !!locationId,
  });

  const location = data?.data?.locations?.data?.[0] || {};
  const mainContactFullName = location?.metadata?.mainContactUser?.metadata?.fullName;
  const mainContactEmail = location?.metadata?.mainContactUser?.metadata?.email;
  const mainContactPhone =
    toDisplayPhoneFromPhoneWithCountryCode(location?.metadata?.mainContactUser?.metadata?.phoneNumberWithCountryCode) || '';
  const [mainContactData, setMainContactData] = useState({
    fullName: mainContactFullName,
    phoneNumber: mainContactPhone,
    email: mainContactEmail,
  });

  return (
    <>
      <Input
        data-testid="mainContactSelect"
        id="mainContactSelect"
        className="mb-4 cursor-pointer md:max-w-sm"
        onClick={() => setUserSelectShowing(true)}
        value={[mainContactData.fullName, mainContactData.phoneNumber, mainContactData.email].join(' ')}
        readOnly
      />
      <UserSelectDialog
        organizationId={organizationId}
        handleSelect={({ user }) => {
          handleSave(user?.metadata?.userId);
          setMainContactData({
            fullName: user?.metadata?.fullName,
            phoneNumber: user?.metadata?.phoneNumberWithCountryCode,
            email: user?.metadata?.email,
          });
        }}
        handleClose={() => setUserSelectShowing(false)}
        open={userSelectShowing}
        showUnassigned
      />
    </>
  );
};
