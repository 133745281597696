import React, { useState } from 'react';
import { waitSynchronously } from '@equips/common-resources';
import {
  ServiceTypeEnum,
  deleteEquipment,
  reactivateEquipment,
  deactivateEquipment,
  Equipment,
  EquipmentStatusType,
  EquipmentMutationPatchArgs,
  patchEquipment,
} from '@equips/entities-schema';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import useMutation from '../../../common/hooks/useMutation';
import DropDown from '../../../common/components/DropDown';
import Urls from '../../../routes/Urls';
import { equipmentIsInService } from '../businessLogic/serviceEndDate';
import { useAuth } from '../../../common/auth/AuthContext';
import { buildQueryString } from '../../../common/functions/buildQueryString';
import ProposedEquipmentServiceRequestsModal from '../ProposedEquipmentServiceRequestsModal';
import { getServiceRequestForProposedEquipmentCount } from '../../../graphql/queries/serviceRequestGraphQLQueries';
import { checkForDuplicateActiveEquipmentCount } from '../../../graphql/queries/equipmentGraphQLQueries';
import DispatchEquipmentModal from './DispatchEquipmentModal';
import RequestCoverageRemovalToDeactivateEquipment from './RequestCoverageRemovalToDeactivateEquipment';

export enum modalStateOptions {
  closed = 'closed',
  dispatchShowing = 'dispatchShowing',
  deactivateEquipmentWithCoverage = 'deactivateEquipmentWithCoverage',
  approveProposedEquipment = 'approveProposedEquipment',
  denyProposedEquipment = 'denyProposedEquipment',
}

export const EquipmentRowActions = (props: React.PropsWithChildren<CellProps<Equipment, any>>) => {
  const { t } = useTranslation();
  const { userCan, admins, roles, internalUsers } = useAuth();
  const [modalServiceType, setModalServiceType] = useState<ServiceTypeEnum>(ServiceTypeEnum.Corrective);
  const [resolveAction, setResolveAction] = useState<'' | 'approve' | 'deny'>('');

  const entityId = props.row.original.metadata?.equipmentId;
  const [modalState, setModalState] = useState<modalStateOptions>(modalStateOptions.closed);

  const [deleteEquipmentMutation, { saving: deleteSaving }] = useMutation(deleteEquipment, {
    variables: { entityId },
    successMessage: t('success'),
    onCompleted: async () => {
      await waitSynchronously(750);
      await props.refetchData();
    },
  });

  const [reactivate, { saving: reactivateSaving }] = useMutation(reactivateEquipment, {
    variables: { entityId },
    successMessage: t('success'),
    onCompleted: async () => props.refetchData(),
  });

  const [activate, { saving: activateSaving }] = useMutation<EquipmentMutationPatchArgs>(patchEquipment, {
    variables: { equipmentId: entityId || '', metadata: { equipmentStatus: EquipmentStatusType.Active } },
    successMessage: t('success'),
  });

  const [deactivate, { saving }] = useMutation(deactivateEquipment, {
    variables: { entityId },
    undoAction: async () => reactivate(),
    suppressAlerts: !userCan(internalUsers),
    onError: async () => setModalState(modalStateOptions.deactivateEquipmentWithCoverage),
    errorMessage: 'Please deactivate all coverage before deactivating equipment.',
    onCompleted: async () => props.refetchData(),
  });

  const deactivated = props.row.original.metadata?.deactivatedAt != null;

  const categoryId = props.row.original.specMetadata?.categoryId || '';
  const manufacturer = props.row.original.specMetadata?.manufacturer || '';
  const model = props.row.original.specMetadata?.model || '';
  const locationId = props.row.original.locationMetadata?.locationId || '';

  const getSRForProposedEquipment = async () => {
    const { data } = await getServiceRequestForProposedEquipmentCount({ equipmentId: entityId || '' });
    return data?.serviceRequests?.totalCount;
  };

  const getDuplicateEquipment = async () => {
    const { data } = await checkForDuplicateActiveEquipmentCount({
      categoryId,
      manufacturer,
      model,
      locationId,
    });

    return data?.equipment?.totalCount;
  };

  return (
    <>
      <DropDown
        isSmall
        containerClasses="more-actions"
        actions={[
          {
            Component: t('viewWithNoun', { noun: t('serviceRequests') }),
            to: buildQueryString(Urls.SERVICE_REQUESTS, { 'metadata.equipmentId': props?.row.original.metadata?.equipmentId || '' }),
          },
          ...(userCan([...admins, roles.providerLocationAdmin, roles.customerLocationAdmin])
            ? [
                {
                  Component: t('viewWithNoun', { noun: t('location') }),
                  to: `${Urls.LOCATIONS}/${props.row.original?.metadata?.locationId}`,
                },
              ]
            : []),
          ...(userCan(internalUsers)
            ? [
                {
                  Component: t('viewWithNoun', { noun: t('organization') }),
                  to: `${Urls.ORGANIZATIONS}/${props.row.original?.metadata?.organizationId}`,
                },
              ]
            : []),
          { border: true },
          ...(equipmentIsInService(props.row.original?.metadata?.serviceEndDate)
            ? [
                {
                  Component: t('startServiceRequest'),
                  action: () => {
                    setModalState(modalStateOptions.dispatchShowing);
                    setModalServiceType(ServiceTypeEnum.Corrective);
                  },
                },
              ]
            : []),
          ...(equipmentIsInService(props.row.original?.metadata?.serviceEndDate) &&
          userCan([...internalUsers, roles.customerOrganizationAdmin])
            ? [
                {
                  Component: t('schedulePreventiveMaintenance'),
                  action: () => {
                    setModalState(modalStateOptions.dispatchShowing);
                    setModalServiceType(ServiceTypeEnum.Preventive);
                  },
                  'data-testid': 'schedulePM',
                },
              ]
            : []),
          ...(props.row.original?.metadata?.equipmentStatus === EquipmentStatusType.Proposed && userCan(admins)
            ? [
                { border: true },
                {
                  Component: <>{activateSaving ? t('saving') : t('approve')}</>,
                  action: async () => {
                    const serviceRequest = await getSRForProposedEquipment();
                    const duplicateEquipment = await getDuplicateEquipment();

                    /** If duplicate exist, show modal immediately */
                    if (duplicateEquipment) {
                      setModalState(modalStateOptions.approveProposedEquipment);
                    } else {
                      /** If not, activate */
                      await activate();
                      /** If service request exist, show modal to resolve */
                      if (serviceRequest) {
                        setResolveAction('approve');
                        setModalState(modalStateOptions.approveProposedEquipment);
                      } else {
                        props.refetchData();
                      }
                    }
                  },
                },
                {
                  Component: <>{t('deny')}</>,
                  action: async () => {
                    setModalState(modalStateOptions.denyProposedEquipment);
                  },
                },
              ]
            : []),
          ...(deactivated && userCan(admins)
            ? [
                { border: true },
                {
                  Component: <>{reactivateSaving ? t('saving') : t('reactivate')}</>,
                  action: async () => reactivate(),
                },
              ]
            : []),
          ...(deactivated && userCan([roles.superGlobalAdmin])
            ? [
                {
                  Component: <>{deleteSaving ? t('saving') : t('delete')}</>,
                  action: async () => deleteEquipmentMutation(),
                },
              ]
            : [
                ...(userCan(admins)
                  ? [
                      {
                        Component: <>{saving ? t('saving') : t('deactivate')}</>,
                        action: async () => deactivate(),
                      },
                    ]
                  : []),
              ]),
        ]}
      />
      {modalState === modalStateOptions.deactivateEquipmentWithCoverage && (
        <RequestCoverageRemovalToDeactivateEquipment
          organizationId={props.row.original?.metadata?.organizationId || ''}
          locationId={props.row.original?.metadata?.locationId || ''}
          equipmentId={entityId ?? ''}
          onClose={() => setModalState(modalStateOptions.closed)}
          onSuccess={() => setModalState(modalStateOptions.closed)}
        />
      )}
      {modalState === modalStateOptions.dispatchShowing && (
        <DispatchEquipmentModal
          organizationId={props.row.original?.metadata?.organizationId || ''}
          locationId={props.row.original?.metadata?.locationId || ''}
          equipmentId={entityId || ''}
          onClose={() => setModalState(modalStateOptions.closed)}
          onSuccess={() => setModalState(modalStateOptions.closed)}
          serviceType={modalServiceType}
        />
      )}
      {(modalState === modalStateOptions.approveProposedEquipment || modalState === modalStateOptions.denyProposedEquipment) && (
        <ProposedEquipmentServiceRequestsModal
          equipmentId={entityId || ''}
          isOpen={true}
          handleClose={async () => {
            setModalState(modalStateOptions.closed);
            await props.refetchData();
          }}
          modalState={modalState}
          categoryId={props.row.original.specMetadata?.categoryId || ''}
          manufacturer={props.row.original.specMetadata?.manufacturer || ''}
          model={props.row.original.specMetadata?.model || ''}
          equipmentName={props.row.original.metadata?.equipmentName}
          shortId={props.row.original.metadata?.shortId}
          serialNumber={props.row.original.metadata?.serialNumber}
          equipmentStatus={resolveAction === 'approve' ? EquipmentStatusType.Active : props.row.original.metadata?.equipmentStatus}
          locationId={props.row.original.locationMetadata?.locationId || ''}
        />
      )}
    </>
  );
};
