import { gql } from '../graphqlHelperFunctions';
import { postToPaymentEndpoint } from '../postToEndpoint';

export const getQuickbooksCustomerByOrganizationId = async ({ organizationId }) =>
  postToPaymentEndpoint({
    query: gql`
      query ($organizationId: String!) {
        quickbooksCustomer(organizationId: $organizationId) {
          DisplayName
          Balance
          Active
          Id
          BillAddr {
            City
            Line1
            PostalCode
            Lat
            Long
            CountrySubDivisionCode
            Id
          }
          MetaData {
            CreateTime
            LastUpdatedTime
          }
        }
      }
    `,
    variables: { organizationId },
  });

export const getQuickbooksInvoicesByOrganizationId = async ({ organizationId, startDateISOFormat, endDateISOFormat, size, from }) =>
  postToPaymentEndpoint({
    query: gql`
      query ($organizationId: String!, $startDateISOFormat: String, $endDateISOFormat: String, $size: Int, $from: Int) {
        quickbooksInvoices(
          organizationId: $organizationId
          startDateISOFormat: $startDateISOFormat
          endDateISOFormat: $endDateISOFormat
          size: $size
          from: $from
        ) {
          totalCount
          invoices {
            DocNumber
            Id
            CustomerMemo {
              value
            }
            Line {
              DetailType
              Description
              SalesItemLineDetail {
                Qty
                UnitPrice
                ItemRef {
                  name
                  value
                }
              }
              LineNum
              Amount
              Id
            }
            TxnDate
            DueDate
            PrintStatus
            EmailStatus
            Balance
            TotalAmt
            MetaData {
              CreateTime
              LastUpdatedTime
            }
          }
        }
      }
    `,
    variables: { organizationId, startDateISOFormat, endDateISOFormat, size, from },
  });

export const getQuickbooksInvoicePdf = async ({ organizationId, quickbooksInvoiceId }) =>
  postToPaymentEndpoint({
    query: gql`
      query ($organizationId: String!, $quickbooksInvoiceId: String!) {
        quickbooksInvoicePdf(organizationId: $organizationId, quickbooksInvoiceId: $quickbooksInvoiceId)
      }
    `,
    variables: { organizationId, quickbooksInvoiceId },
  });

export const getQuickbooksAttachmentAsPdf = async ({ organizationId, quickbooksInvoiceId }) =>
  postToPaymentEndpoint({
    query: gql`
      query ($organizationId: String!, $quickbooksInvoiceId: String!) {
        quickbooksAttachmentAsPdf(organizationId: $organizationId, quickbooksInvoiceId: $quickbooksInvoiceId)
      }
    `,
    variables: { organizationId, quickbooksInvoiceId },
  });

export const getQuickbooksAttachment = async ({ organizationId, quickbooksInvoiceId }) =>
  postToPaymentEndpoint({
    query: gql`
      query ($organizationId: String!, $quickbooksInvoiceId: String!) {
        quickbooksAttachmentPdf(organizationId: $organizationId, quickbooksInvoiceId: $quickbooksInvoiceId)
      }
    `,
    variables: { organizationId, quickbooksInvoiceId },
  });
