import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CoveragesMutationPostArgs, CoverageStatusType, queryEquipment, postCoverage } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../common/components/Buttons/Button';
import Modal, { simpleModalTypes } from '../../../common/components/Modal/SimpleModal';
import useMutation from '../../../common/hooks/useMutation';
import waitSynchronously from '../../../common/functions/waitSynchronously';
import FormGroup from '../../../common/components/Form/FormGroup';
import Label from '../../../common/components/Form/Label';
import DateInput from '../../../common/components/Date/DateInput';
import { useAlert } from '../../../common/components/Alerts/AlertContext';
import { toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST } from '../../../common/functions/expensiveDateFunctions';
import LoaderCards from '../../../common/components/Loaders/LoaderCards';
import { dateStringFormattedForHtmlInput } from '../../../common/functions/dateFunctions';

const todaysDateForHtmlInput = dateStringFormattedForHtmlInput(Date.now());

function RequestCoverageModal({ equipmentId, closeModal }) {
  const [onDate, setOnDate] = useState<string | undefined>();
  const showAlert = useAlert();

  const { data, isFetching } = useQuery(['queryEquipment', equipmentId], () => queryEquipment({ equipmentId }), {
    enabled: !!equipmentId,
    select: (data) => data?.data,
  });

  const { organizationId, locationId } = data?.equipment?.data?.[0]?.metadata || {};

  const [requestCoverage, { saving }] = useMutation<CoveragesMutationPostArgs>(postCoverage, {
    successMessage: 'Successfully requested coverage.',
    onCompleted: () => closeModal(),
  });

  if (isFetching) return <LoaderCards />;

  return (
    <form
      data-testid="requestCoverageForm"
      onSubmit={(event) => {
        event.preventDefault();

        if (!locationId || !organizationId) {
          console.error('No locationId or organizationId');

          return showAlert({ content: 'There was an error requesting coverage' });
        }
        requestCoverage({
          metadata: {
            onDate: toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST(onDate),
            equipmentId,
            organizationId,
            locationId,
            coverageStatus: CoverageStatusType.Requested,
          },
        });
      }}
    >
      <FormGroup fullWidth>
        <Label label="When should the coverage start?" id="onDate" required />
        <DateInput
          data-testid="requestCoverageOnDate"
          required
          onChange={({ target: { value } }) => setOnDate(value)}
          id="onDate"
          value={onDate}
          min={todaysDateForHtmlInput}
        />
      </FormGroup>

      <div className="flex justify-end py-2">
        <Button blue type="submit" loading={saving}>
          Request Coverage
        </Button>
      </div>
    </form>
  );
}
RequestCoverageModal.propTypes = { equipmentId: PropTypes.string.isRequired, closeModal: PropTypes.func.isRequired };

export default function RequestCoverage({ equipmentId, getData }) {
  const [modalShowing, setModalShowing] = useState(false);

  return (
    <div>
      <Button data-testid="requestCoverageFromTable" tiny white type="button" onClick={() => setModalShowing(true)}>
        Request Coverage
      </Button>
      <span className="ml-2 inline-block text-2xs text-gray-700">Service dispatch only</span>
      {modalShowing && (
        <Modal
          modalType={simpleModalTypes.info}
          title="Request coverage"
          subtitle="After you request coverage, we will reach out to you to finalize details"
          isOpen={modalShowing}
          handleClose={() => setModalShowing(false)}
        >
          <div className="p-4 text-center">
            <RequestCoverageModal
              equipmentId={equipmentId}
              closeModal={async () => {
                await waitSynchronously(2000);
                getData();
                setModalShowing(false);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

RequestCoverage.propTypes = {
  getData: PropTypes.func.isRequired,
  equipmentId: PropTypes.string.isRequired,
};
