import {
  UserFeatureFlagEnum,
  UserAuthorizationRoleEnum,
  UserMetadata,
  UserTagsType,
  ClientOrganizationEnumType,
  OrganizationFeatureFlagEnum,
  OrganizationBillingMethodType,
} from '@equips/entities-schema';
import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { organizationIndustryTypes } from '../../graphql/enums';
import config from '../../config';
import { isAasOrganizationId } from '../functions/aasHelpers';
import {
  internalUsers,
  internalUsersPlusCOA,
  admins,
  nonProviders,
  organizationAdmins,
  providers,
  customers,
  roles,
  allRoles,
} from './roles';
import AuthorizationMutator from './AuthorizationMutator';
import { features, FeatureVisiblity } from './features';

export interface AuthorizationData extends UserMetadata {
  /** Indicates whether the user has entered their fullName and location or not. */
  initialized?: boolean;
  serverError?: string;
}

export type AuthContextValue = {
  industry: string;
  permissions: {
    userCanViewInHouseTechForm: boolean;
    isAASAdmin: boolean;
    isAASInternalUser: boolean;
    isWOMInternalUser: boolean;
    isCoverageInternalUser: boolean;
  };
  auth?: AuthorizationData | null;
  userCan: (roles: UserAuthorizationRoleEnum[], feature?: UserFeatureFlagEnum) => boolean;
  determineFeatureFlagVisibility: (featureFlag: OrganizationFeatureFlagEnum) => boolean;
  isTrial: boolean;
  isActive: boolean;
  clientOrganizationType: ClientOrganizationEnumType | null;
  organizationBillingMethod: OrganizationBillingMethodType | null;
  roles: typeof roles;
  eLinkMode: boolean;
  admins: UserAuthorizationRoleEnum[];
  organizationAdmins: UserAuthorizationRoleEnum[];
  internalUsers: UserAuthorizationRoleEnum[];
  internalUsersPlusCOA: UserAuthorizationRoleEnum[];
  nonProviders: UserAuthorizationRoleEnum[];
  providers: UserAuthorizationRoleEnum[];
  customers: UserAuthorizationRoleEnum[];
  isSystemImportUser: boolean;
  isAasUser: boolean;
  stikkyDataRedirectUrlToken: string;
};
export const AuthContext = React.createContext<AuthContextValue>({} as AuthContextValue);

/** Returns the AuthContext value */
export const useAuth = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: React.ReactNode;
  auth: AuthorizationData | null;
}

/** Provides user auth data via React Context. Access via the useAuth hook as a shortcut. */
export function AuthProvider({ auth, children }: AuthProviderProps) {
  const [userAuth, changeAuth] = useState(auth);
  const [eLinkMode, setELinkMode] = useState(false);
  const [clientOrganizationType, setClientOrganizationType] = useState(auth?.organization?.metadata?.clientOrganizationType || null);
  const [isAasUser, setIsAasUser] = useState(isAasOrganizationId(auth?.organizationId) || !!auth?.tags?.includes(UserTagsType.Aas));

  useEffect(() => changeAuth(auth), [auth]);

  const userCan = useCallback(
    (roleStringOrArray: string | string[], feature?: keyof typeof features) => {
      let featureFlagCheck = true;
      const userCheck =
        userAuth?.authorizationRole && Array.isArray(roleStringOrArray)
          ? roleStringOrArray.includes(userAuth.authorizationRole)
          : roleStringOrArray === userAuth?.authorizationRole;

      if (!userCheck) {
        return false;
      }

      if (
        feature &&
        features[feature].visibility === FeatureVisiblity.Beta &&
        !(userAuth?.featureFlags || []).includes(features[feature].name as UserFeatureFlagEnum) &&
        !internalUsers.includes(userAuth?.authorizationRole as UserAuthorizationRoleEnum)
      ) {
        featureFlagCheck = false;
      }

      return userCheck && featureFlagCheck;
    },
    [userAuth],
  );

  const determineFeatureFlagVisibility = useCallback(
    (featureFlag: OrganizationFeatureFlagEnum) => {
      const featureFlagsForEquipFieldDispatchToInclude = [OrganizationFeatureFlagEnum.SrModalScheduledDate];
      const featureFlagsInvert = [OrganizationFeatureFlagEnum.Symptoms];

      // If internal user return true
      if (internalUsers.includes(userAuth?.authorizationRole as UserAuthorizationRoleEnum)) {
        if (featureFlagsInvert.includes(featureFlag)) return false;
        return true;
      }

      // If not WOM, Basic, Home Warranty, or Coverage return true for now
      if (
        clientOrganizationType !== ClientOrganizationEnumType.EquipsWorkOrderManagement &&
        clientOrganizationType !== ClientOrganizationEnumType.EquipsBasic &&
        clientOrganizationType !== ClientOrganizationEnumType.EquipsCoverage &&
        clientOrganizationType !== ClientOrganizationEnumType.EquipsHomeWarranty
      ) {
        return !featureFlagsForEquipFieldDispatchToInclude.includes(featureFlag);
      }

      const orgsFeatureFlags = userAuth?.organization?.metadata?.featureFlags ?? [];

      if (orgsFeatureFlags.includes(featureFlag)) {
        return true;
      } else {
        return false;
      }
    },
    [userAuth, clientOrganizationType],
  );

  const updateOrganizationMetadata = (field: string) =>
    changeAuth({
      ...userAuth,
      organization: {
        metadata: {
          ...userAuth?.organization?.metadata,
          [field]: !userAuth?.organization?.metadata?.[field],
        },
      },
    });

  const updateUserRole = (authorizationRole: UserAuthorizationRoleEnum) =>
    changeAuth({
      ...userAuth,
      authorizationRole,
    });

  const updateClientOrganizationType = (clientOrganizationType: ClientOrganizationEnumType) =>
    setClientOrganizationType(clientOrganizationType);

  const industry = userCan(internalUsers) ? organizationIndustryTypes.general.value : auth?.organization?.metadata?.industry ?? '';
  const isTrial = userAuth?.organization?.metadata?.isTrial || false;
  const deactivatedAt = userAuth?.organization?.metadata?.deactivatedAt;
  const isActive = !deactivatedAt;
  const organizationBillingMethod = userAuth?.organization?.metadata?.organizationBillingMethod ?? null;
  const isSystemImportUser = userAuth?.userId === config?.users?.systemImportUserGuid;

  const stikkyDataRedirectUrlToken = userAuth?.stikkyDataRedirectUrlToken || '';
  const permissions = useMemo(() => {
    const tags = userAuth?.tags || [];
    const userCanViewInHouseTechForm = tags.includes(UserTagsType.InHouseTech);

    // assume allow all when userTags = [] or when userTags doesnt have any of them
    const allowAll = tags?.length ? !(tags.includes(UserTagsType.Aas) || tags.includes(UserTagsType.WorkOrderManagement)) : true;

    const isAASAdmin = tags.includes(UserTagsType.AasAdmin);
    const isAASInternalUser = allowAll || tags.includes(UserTagsType.Aas);
    const isWOMInternalUser = allowAll || tags.includes(UserTagsType.WorkOrderManagement);
    const isCoverageInternalUser = allowAll || tags.includes(UserTagsType.Coverage);

    return { userCanViewInHouseTechForm, isAASAdmin, isAASInternalUser, isWOMInternalUser, isCoverageInternalUser };
  }, [userAuth]);

  return (
    <AuthContext.Provider
      value={{
        permissions,
        industry,
        auth: userAuth,
        userCan,
        determineFeatureFlagVisibility,
        isTrial,
        isActive,
        clientOrganizationType,
        organizationBillingMethod,
        eLinkMode,
        roles,
        admins,
        organizationAdmins,
        providers,
        customers,
        nonProviders,
        internalUsers,
        internalUsersPlusCOA,
        isSystemImportUser,
        isAasUser,
        stikkyDataRedirectUrlToken,
      }}
    >
      {children}
      {[UserAuthorizationRoleEnum.SuperGlobalAdmin, UserAuthorizationRoleEnum.GlobalAdmin].includes(
        auth?.authorizationRole as UserAuthorizationRoleEnum,
      ) && (
        <AuthorizationMutator
          eLinkMode={eLinkMode}
          setELinkMode={setELinkMode}
          userId={userAuth?.userId}
          organizationId={userAuth?.organizationId}
          isTrial={isTrial}
          roles={allRoles}
          isActive={isActive}
          authorizationRole={userAuth?.authorizationRole}
          updateUserRole={updateUserRole}
          clientOrganizationType={clientOrganizationType}
          updateClientOrganizationType={updateClientOrganizationType}
          updateOrganizationMetadata={updateOrganizationMetadata}
          isAasUser={isAasUser}
          setIsAasUser={setIsAasUser}
        />
      )}
    </AuthContext.Provider>
  );
}
