import { useQuery } from '@tanstack/react-query';
import { getCategories } from '../../graphql/queries/categoryQueries';
import { useAuth } from '../auth/AuthContext';

export const useCategories = (
  organizationId?: string | null,
  options?: { showUsedCategoriesOnly?: boolean; showUsedSubcategoriesOnly?: boolean },
) => {
  const { auth, userCan, internalUsers } = useAuth();
  const { showUsedCategoriesOnly = true, showUsedSubcategoriesOnly = true } = options ?? {};

  // internalUsers only query the default list (null) or all categories (undefined).
  const organizationIdForQuery =
    userCan(internalUsers) && organizationId === auth?.organizationId ? (organizationId === undefined ? undefined : null) : organizationId;

  const queryKey = String(organizationIdForQuery);

  const minuteInMs = 60 * 1000;

  const {
    data,
    isLoading: loading,
    refetch: fetch,
  } = useQuery(
    ['getCategories', queryKey],
    () => getCategories({ organizationId: organizationIdForQuery, showUsedCategoriesOnly, showUsedSubcategoriesOnly }),
    {
      staleTime: 30 * minuteInMs,
      cacheTime: 35 * minuteInMs,
    },
  );

  const categories = data?.data?.categories?.data || [];

  return { categories, loading, fetch };
};
