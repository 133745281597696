import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { Link, LinkProps } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

interface ButtonProps extends Pick<Partial<LinkProps>, 'to'> {
  children: any;
  type?: 'button' | 'submit';
  blue?: boolean;
  lightBlue?: boolean;
  disabled?: boolean;
  className?: string;
  danger?: boolean;
  fullWidth?: boolean;
  gray?: boolean;
  darkGray?: boolean;
  large?: boolean;
  loading?: boolean;
  orange?: boolean;
  green?: boolean;
  rounded?: 'rounded' | 'rounded-none' | 'rounded-sm' | 'rounded-md' | 'rounded-lg' | 'rounded-xl';
  outlined?: boolean;
  tiny?: boolean;
  href?: string | null;
  target?: '_blank';
  rel?: 'noopener noreferrer';
  white?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tabIndex?: number;
}

const Button = React.forwardRef(
  (
    {
      blue = false,
      lightBlue = false,
      children,
      className = '',
      danger = false,
      fullWidth = false,
      gray = false,
      darkGray = false,
      large = false,
      loading = false,
      orange = false,
      green = false,
      rounded = 'rounded',
      tiny = false,
      to,
      href = null,
      white = false,
      onClick,
      outlined = false,
      ...restOfProps
    }: ButtonProps,
    ref: React.Ref<any>,
  ) => {
    const { eLinkMode } = useAuth();
    let color = outlined
      ? 'border border-green-500 hover:bg-green-100 text-green-500 outline-none'
      : 'bg-green-500 text-white hover:bg-green-500-hover border border-green-600';

    if (blue) {
      color = outlined
        ? 'border border-equipsNavyBlue hover:bg-blue-100 text-equipsNavyBlue outline-none'
        : 'bg-equipsNavyBlue hover:bg-blue-800 text-white border border-equipsNavyBlue';
    } else if (lightBlue) {
      color = 'bg-equipsLightBlue hover:bg-blue-500 text-white border border-equipsLightBlue';
    } else if (orange) {
      color = 'bg-equipsOrange hover:bg-orange-700 text-white';
    } else if (white) {
      color = 'border border-gray-300 bg-white text-gray-800 hover:text-gray-700 active:bg-gray-500 active:text-gray-900';
    } else if (gray) {
      color = 'bg-gray-200 hover:bg-gray-300 text-gray-700 border border-gray-400';
    } else if (darkGray) {
      color = 'bg-gray-800 hover:bg-gray-700 text-white border border-black';
    } else if (danger) {
      color = 'bg-red-600 hover:bg-red-500 text-white';
    } else if (green) {
      color = 'bg-equipsGreen hover:bg-green-700 text-white';
    }

    if (eLinkMode) {
      color = 'rounded-none elink-btn';
    }

    let size = 'px-5 py-2';

    if (tiny) {
      size = 'px-3 py-1 text-xs';
    } else if (large) {
      size = ' px-6 py-3';
    }

    const loaderColor = white ? 'black' : 'white';
    const cssClassNames = `antialiased leading-tight outline-none ${rounded} ${size} ${color} ${className} hover:shadow disabled:opacity-50 disabled:cursor-not-allowed transition ease-in-out duration-150 ${
      fullWidth ? 'block w-full' : ''
    }`;

    if (href) {
      return (
        <a className={cssClassNames} {...restOfProps} href={href} ref={ref}>
          {!loading ? children : <BeatLoader size={8} color={loaderColor} />}
        </a>
      );
    }

    if (to) {
      return (
        <Link className={cssClassNames} {...restOfProps} to={to} ref={ref}>
          {!loading ? children : <BeatLoader size={8} color={loaderColor} />}
        </Link>
      );
    }

    return (
      // eslint-disable-next-line
      <button disabled={loading} className={cssClassNames} onClick={onClick} {...restOfProps} ref={ref}>
        {!loading ? children : <BeatLoader size={8} color={loaderColor} />}
      </button>
    );
  },
);

export default Button;
