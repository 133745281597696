import {
  gql,
  fullUsersMetadataGraphqlNode,
  deactivateMutation,
  reactivateMutation,
  deleteMutation,
  GraphQLDeactivateOutputType,
  GraphQLFindOptions,
  GraphQLFindOptionsResponse,
  GraphQLQueryOptions,
  GraphQLQueryOutputType,
  GraphQLPostOutputType,
  GraphQLPatchOutputType,
  GraphQLReactivateOutputType,
  GraphQLDeleteOutputType,
  getSearchVariablesWithDefaults,
  User,
  UsersQuery,
  UserMetadata,
  UsersQueryUsersArgs,
  UsersMutationPostArgs,
  UsersMutationPatchArgs,
  UsersMutationDeactivateArgs,
  UsersMutationReactivateArgs,
  UsersMutationDeleteArgs,
} from '@equips/entities-schema';
import { postToUsersEndpoint } from '../postToEndpoint';

export type UsersQueryGraphQLOutputType = GraphQLQueryOutputType<UsersQuery>;

export const queryUsers = async (
  requestedVariables: UsersQueryUsersArgs,
  { query }: GraphQLQueryOptions = {},
): Promise<UsersQueryGraphQLOutputType> => {
  const variables = getSearchVariablesWithDefaults(requestedVariables);

  return postToUsersEndpoint({
    query: gql`
        query($userId: String, $search: UsersSearchInput) {
          users(userId: $userId, search: $search) {
            data {
              ${query || fullUsersMetadataGraphqlNode}
            }
          }
        }
      `,
    variables,
  });
};

export const findOneUser = async (
  variables: UsersQueryUsersArgs,
  { query, throwIfMultiple = false }: GraphQLFindOptions = {},
): Promise<GraphQLFindOptionsResponse<User, UsersQueryGraphQLOutputType>> => {
  const { data, errors } = await queryUsers(variables, { query });

  const entities = data?.users?.data || [];

  if (throwIfMultiple && entities.length > 0) {
    throw new Error('There was more than one entity found');
  }

  return { entity: entities[0], raw: { data, errors } };
};

export const postUser = async (
  variables: UsersMutationPostArgs,
  { query }: GraphQLQueryOptions = {},
): Promise<GraphQLPostOutputType<UserMetadata>> => {
  return postToUsersEndpoint({
    query: gql`
        mutation($metadata: UserMetadataPostInput!) {
          post(metadata: $metadata) {
            ${query || fullUsersMetadataGraphqlNode}
          }
        }
      `,
    variables,
  });
};

export const patchUser = async (
  variables: UsersMutationPatchArgs,
  { query }: GraphQLQueryOptions = {},
): Promise<GraphQLPatchOutputType<User>> => {
  return postToUsersEndpoint({
    query: gql`
        mutation($userId: String!, $metadata: UserMetadataPatchInput!) {
          patch(userId: $userId, metadata: $metadata) {
            ${query || fullUsersMetadataGraphqlNode}
          }
        }
      `,
    variables,
  });
};

export const deactivateUser = async (variables: UsersMutationDeactivateArgs): Promise<GraphQLDeactivateOutputType> => {
  return postToUsersEndpoint({ query: deactivateMutation, variables });
};

export const reactivateUser = async (variables: UsersMutationReactivateArgs): Promise<GraphQLReactivateOutputType> => {
  return postToUsersEndpoint({ query: reactivateMutation, variables });
};

export const deleteUser = async (variables: UsersMutationDeleteArgs): Promise<GraphQLDeleteOutputType> => {
  return postToUsersEndpoint({ query: deleteMutation, variables });
};
