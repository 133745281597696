import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Note,
  NotesTagsEnum,
  postNote,
  patchNote,
  NoteAuthorizationLevel,
  EntityLabelEnum,
  OrganizationFeatureFlagEnum,
} from '@equips/entities-schema';
import Avatar from '@mui/material/Avatar';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { Collapse } from '@mui/material';
import { AutoFixHigh } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../auth/AuthContext';
import { getEnumDisplayName, noteAuthorizationLevels } from '../../../graphql/enums';
import { toDateStringFromUnixMillisecondTimestamp } from '../../functions/dateFunctions';
import { toRelativeTimeIfTodayFromUnixMillisecondTimestamp } from '../../functions/dateFunctionsRelativeTime';
import Pill from '../Pills/Pill';
import Urls from '../../../routes/Urls';
import useMutation from '../../hooks/useMutation';
import { deactivateNote } from '../../../graphql/mutations/NoteMutations';
import DropDown from '../DropDown';
import { abbreviateName } from '../../functions/abbreviateName';
import './NoteDisplay.css';
import SimpleModal from '../Modal/SimpleModal';
import Label from '../Form/Label';
import Textarea from '../Form/Textarea';
import Button from '../Buttons/Button';
import Lightbox from '../Lightbox/Lightbox';
import { downloadAttachment } from '../../functions/downloads';
import { EmojiReactionRow, EmojiSelectorButton, EmojiType } from '../EmojiReaction';
import { getTagsForSelect } from '../../../graphql/queries/tagGraphQLQueries';
import UnderlinedButton from '../Buttons/UnderlinedButton';
import { getOrganizationFeatureFlags } from '../../../graphql/queries/organizationGraphQLQueries';
import { getAasOrganizationId } from '../../functions/aasHelpers';
import NoteAttachment from './NoteAttachment';
import NoteForm from './NoteForm';
import NoteMessage from './NoteMessage';

interface NoteDisplayProps {
  note: Note;
  readOnly?: boolean;
  unknownAuthorName?: string;
}

type Tag = { color: string; text: string };

export const automatedTags = [
  NotesTagsEnum.OnsiteCheckin,
  NotesTagsEnum.OnsiteCheckout,
  NotesTagsEnum.Autorequest,
  NotesTagsEnum.ArrivalWindow,
  NotesTagsEnum.Burroughs,
  NotesTagsEnum.InternalTicketNote,
  NotesTagsEnum.Resolution,
  NotesTagsEnum.DispatchReason,
  NotesTagsEnum.FollowUp,
  NotesTagsEnum.EquipmentHealth,
];

const displayTags: { [name: string]: Tag } = {
  [NotesTagsEnum.Invoice]: { color: 'bg-blue-200 text-blue-800 border-blue-400', text: 'Invoice' },
  [NotesTagsEnum.Cancelled]: { color: 'bg-orange-200 text-orange-800 border-orange-400', text: 'Cancellation' },
  [NotesTagsEnum.Denial]: { color: 'bg-red-200 text-red-800 border-red-400', text: 'Denied' },
  [NotesTagsEnum.Escalated]: { color: 'bg-red-200 text-red-800 border-red-400', text: 'Escalation' },
  [NotesTagsEnum.ClosingNotes]: { color: 'bg-green-200 text-green-900 border-green-400 border', text: 'Closing Notes' },
  [NotesTagsEnum.PartRequest]: { color: 'bg-blue-200 text-blue-800 border-blue-400', text: 'Requesting Approval' },
  [NotesTagsEnum.PartRequestDenied]: { color: 'bg-red-200 text-red-800 border-red-400', text: 'Denied' },
  [NotesTagsEnum.PartRequestApproved]: { color: 'bg-green-200 text-green-900 border-green-400 border', text: 'Approved' },
  [NotesTagsEnum.FollowUp]: { color: 'bg-yellow-200 text-yellow-800 border-yellow-400', text: 'Follow Up Needed' },
  [NotesTagsEnum.OnHold]: { color: 'bg-yellow-200 text-yellow-800 border-yellow-400', text: 'On Hold' },
  [NotesTagsEnum.EquipmentHealth]: { color: 'bg-blue-200 text-blue-800 border-blue-400', text: 'Updated Equipment Health' },
};

export function NoteDisplay({ note, readOnly, unknownAuthorName }: NoteDisplayProps) {
  const { t } = useTranslation();
  const { auth, userCan, internalUsers, internalUsersPlusCOA, admins, determineFeatureFlagVisibility, isAasUser } = useAuth();
  const [metadata, setMetadata] = useState(note?.metadata);
  const [isEditing, setIsEditing] = useState(false);
  const [showAvatarPopup, setShowAvatarPopup] = useState(false);
  const [isApproval, setIsApproval] = useState<boolean>();
  const [reasonNote, setReasonNote] = useState('');
  const [showAbsoluteTime, setShowAbsoluteTime] = useState(false);
  const [emojis, setEmojis] = useState([] as EmojiType[]);
  const emojisRef = useRef(emojis);

  const noteId = metadata?.noteId;
  const queryClient = useQueryClient();

  const reloadNotes = async () => {
    await queryClient.refetchQueries(['NoteListWithForm'], { active: true });
  };

  const history = useHistory();
  const [removeNote, { saving: removing }] = useMutation(deactivateNote, { onCompleted: () => reloadNotes() });

  const author = metadata?.createdByUser;
  const name = author?.metadata?.fullName || unknownAuthorName || t('unknown');
  const editorName = metadata?.modifiedByUser?.metadata?.fullName || unknownAuthorName || t('unknown');
  const authorizationLevel = getEnumDisplayName(note?.metadata?.authorizationLevel, noteAuthorizationLevels);
  const ownComment = metadata?.createdByUser?.metadata?.userId === auth?.userId;
  const profilePicture = ownComment ? auth?.profilePicture?.presignedGetUrl : author?.metadata?.profilePictureUrl;
  const automatedMessage = metadata?.tags?.some((tag) => tag && automatedTags.includes(tag));
  const tags = metadata?.tags?.filter((tag) => tag != null && displayTags[tag]).map((tag) => tag && displayTags[tag]);
  const { organizationId, organizationName } = metadata?.createdByUser?.metadata || {};
  const userId = auth?.userId || '';
  const defaultTagId = author?.metadata?.defaultTagId;

  const organizationIdForQuery = isAasUser ? getAasOrganizationId() : organizationId;

  const { data: organizationFeatureFlags } = useQuery(
    ['getOrganizationFeatureFlags', organizationIdForQuery],
    () => getOrganizationFeatureFlags({ organizationId: organizationIdForQuery }),
    {
      enabled: !!organizationIdForQuery,
      select: (data) => data?.data,
    },
  );
  const featureFlags = organizationFeatureFlags?.organizations?.data?.[0]?.metadata?.featureFlags || [];
  const showEmojis = featureFlags.includes(OrganizationFeatureFlagEnum.Emojis);

  const { data: allowedClientTags } = useQuery(
    ['getTagsForSelect', organizationId],
    () =>
      getTagsForSelect({
        search: '',
        entityLabel: EntityLabelEnum.User,
        organizationId: organizationId ?? '',
      }),
    {
      enabled: !!organizationId,
      select: (data) => {
        const options = data?.data?.tag?.data || [];
        return options;
      },
    },
  );

  const userClientTag = allowedClientTags?.find((tag) => tag?.tagId === defaultTagId)?.metadata;

  useEffect(() => {
    emojisRef.current = emojis;
  }, [emojis]);

  const [resolveRequest, { saving: resolving }] = useMutation(
    async () => {
      const resolutionTag = isApproval ? NotesTagsEnum.PartRequestApproved : NotesTagsEnum.PartRequestDenied;
      if (noteId && note.metadata) {
        await patchNote({
          noteId,
          metadata: { noteId, tags: [...(note?.metadata?.tags ?? []), resolutionTag] },
        });

        if (reasonNote) {
          const metadata = {
            parentId: note?.metadata?.parentId || noteId,
            parentLabel: note?.metadata?.parentLabel || EntityLabelEnum.Note,
            authorizationLevel: NoteAuthorizationLevel.All,
            message: `The approval request has been ${isApproval ? 'approved' : 'denied'}. Reason: ${reasonNote}`,
            tags: [NotesTagsEnum.PartRequestReason],
          };
          await postNote({ metadata });
        }
      }
      setIsApproval(undefined);

      return Promise.resolve({ data: null, errors: null });
    },
    { onCompleted: () => reloadNotes() },
  );

  useEffect(() => {
    const data = note?.metadata?.reactions;
    setEmojis(
      (data || []).map((note: Note) => ({
        noteId: note?.metadata?.noteId || '',
        fullName: note?.metadata?.createdByUser?.metadata?.fullName || '',
        userId: note?.metadata?.createdByUser?.metadata?.userId || '',
        message: note?.metadata?.message || '',
      })),
    );
  }, []);

  const addOrRedactEmoji = async (emojiId: string) => {
    const emojiToRemove = emojisRef.current.find(
      ({ message: emojiMessage, userId: emojiUserId }) => emojiMessage === emojiId && emojiUserId === userId,
    );
    if (emojiToRemove) {
      await deactivateNote({ entityId: emojiToRemove.noteId });
      setEmojis((prevState) => prevState.filter((emoji) => emoji.noteId !== emojiToRemove.noteId));
    } else {
      const { data } = await postNote({
        metadata: {
          authorizationLevel: NoteAuthorizationLevel.All,
          parentLabel: EntityLabelEnum.Note,
          parentId: noteId || '',
          message: emojiId,
          tags: [NotesTagsEnum.Reaction],
        },
      });
      const newNoteId = data?.post?.noteId || '';
      const newEmoji = {
        noteId: newNoteId,
        fullName: name,
        userId,
        message: emojiId,
      };
      setEmojis((prevState) => [...prevState, newEmoji]);
    }
  };

  const id = `comment-${noteId}`;

  const isRequest = metadata?.tags?.includes(NotesTagsEnum.PartRequest);
  const awaitingResponse =
    isRequest && !metadata?.tags?.includes(NotesTagsEnum.PartRequestApproved) && !metadata?.tags?.includes(NotesTagsEnum.PartRequestDenied);

  const [currentImageIndex, setCurrentImageIndex] = useState(-1);
  const images = (note?.metadata?.attachments ?? []).map((item) => ({
    src: item?.metadata?.presignedGetUrl ?? '',
    alt: item?.metadata?.name ?? '',
  }));

  const aiSummary = note?.metadata?.aiGeneratedSummary;
  const [showOriginalText, setShowOriginalText] = useState(!aiSummary);

  if (!noteId || !note.metadata) return <></>;

  return (
    <div id={id} className="relative flex flex-wrap justify-between">
      {!readOnly && (
        <div className="absolute right-0 top-0 flex">
          {!readOnly && (
            <>
              <DropDown
                actions={[
                  ...(note?.metadata?.attachments
                    ? [
                        {
                          Component: t('viewWithNoun', { noun: t('attachments') }),
                          action: () => history.push(`${Urls.NOTE_ATTACHMENT}/${note?.metadata?.parentLabel}/${noteId}`),
                        },
                      ]
                    : []),
                  ...(userCan(internalUsersPlusCOA)
                    ? [
                        { Component: t('copyLink'), action: () => window.navigator.clipboard.writeText(`${window.location.href}#${id}`) },
                        ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.NoteEditCoa) && !aiSummary
                          ? [{ Component: `${isEditing ? t('cancel') : t('edit')} ${t('note')}`, action: () => setIsEditing(!isEditing) }]
                          : []),
                        { border: true },
                        ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.NoteDeactivateCoa)
                          ? [{ Component: t('delete'), action: () => removeNote({ entityId: noteId }) }]
                          : []),
                      ]
                    : []),
                ]}
              />
            </>
          )}
        </div>
      )}
      <header className="w-full items-center pb-4">
        <div className="flex flex-wrap items-center">
          <div className="avatar relative mr-2">
            <Avatar
              onTouchStart={() => setShowAvatarPopup(true)}
              onContextMenu={(e) => e.preventDefault()}
              onTouchEnd={() => setShowAvatarPopup(false)}
              onBlur={() => console.log(false)}
              src={profilePicture ?? undefined}
              alt={name ?? t('unknown')}
              className={`h-10 w-10  ${ownComment ? 'bg-green-500' : 'bg-blue-500'}`}
            >
              {abbreviateName(name)}
            </Avatar>
            {profilePicture && (
              <div className={`avatar-hover white-box h-48 w-48 p-1 ${showAvatarPopup && 'avatar-show'}`}>
                <img src={profilePicture} alt={name ?? t('unknown')} />
              </div>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div>
                <strong>{name} - </strong>
                <span
                  role="presentation"
                  title={toDateStringFromUnixMillisecondTimestamp(metadata.createdAt, { customFormatString: 'MM/DD/YYYY hh:mm:ss A' })}
                  className="ml-1 cursor-pointer text-xs font-base text-gray-600"
                  onClick={() => setShowAbsoluteTime(!showAbsoluteTime)}
                >
                  {showAbsoluteTime
                    ? toDateStringFromUnixMillisecondTimestamp(metadata.createdAt, { customFormatString: 'M/DD/YYYY h:mm A' })
                    : toRelativeTimeIfTodayFromUnixMillisecondTimestamp(metadata.createdAt, { customFormatString: 'M/DD/YYYY h:mm A' })}
                </span>
                <div>
                  {userClientTag && <span className="text-black-400 text-sm">{userClientTag?.name} @ </span>}
                  <a href={`${Urls.ORGANIZATIONS}/${organizationId}`} className="text-xs font-base">
                    {organizationName}
                  </a>
                </div>
              </div>

              {userCan(internalUsers) && authorizationLevel && (
                <div className="flex items-center">
                  <Pill blue fontSize="text-2xs" className="border-1 text-black-200 rounded-full border-blue-500 bg-gray-200">
                    {authorizationLevel}
                  </Pill>
                </div>
              )}

              {userCan(internalUsers) && metadata.modifiedAt && (
                <span
                  title={`edited ${toDateStringFromUnixMillisecondTimestamp(metadata.modifiedAt, {
                    customFormatString: 'MM/DD/YYYY hh:mm:ss A',
                  })} by ${editorName}`}
                  className="ml-1 text-xs font-base text-gray-600"
                >
                  (edited{' '}
                  {toRelativeTimeIfTodayFromUnixMillisecondTimestamp(metadata.modifiedAt, { customFormatString: 'M/DD/YYYY h:mm A' })} by{' '}
                  {editorName})
                </span>
              )}
              {removing && <span className="ml-5 text-sm text-blue-500">{t('loading')}</span>}
            </div>
            {automatedMessage && (
              <div className="-ml-1 inline-block">
                <Pill fontSize="text-2xs">{t('automatedMessage')}</Pill>
              </div>
            )}
            {tags?.map(
              (tag) =>
                tag && (
                  <div key={tag.text} className="-ml-1 inline-block">
                    <Pill color={tag.color + ' border'} fontSize="text-2xs">
                      {tag.text}
                    </Pill>
                  </div>
                ),
            )}
          </div>
        </div>
      </header>
      <div className="w-full">
        {aiSummary && (
          <section className="mt-4">
            <div className="absolute -mt-3 ml-2 flex items-center gap-1 rounded-xl bg-purple-200 px-3 py-1 text-xs text-purple-800">
              <AutoFixHigh fontSize="small" />
              <p>{t('aiSummary')}</p>
            </div>
            <div className=" rounded-2xl border-2 border-purple-200">
              <div className="mt-2 p-4 text-sm text-purple-800">
                <p className="">{aiSummary}</p>
              </div>
            </div>
            <UnderlinedButton
              type="button"
              tiny
              className={`mb-4 ml-auto mt-2 block`}
              onClick={() => {
                setShowOriginalText(!showOriginalText);
              }}
            >
              {showOriginalText ? t('hideOriginalText') : t('showOriginalText')}
            </UnderlinedButton>
          </section>
        )}
        {isEditing ? (
          <>
            <NoteForm
              note={note}
              parentLabel={metadata.parentLabel as EntityLabelEnum}
              entityId={metadata.parentId || undefined}
              onCreated={(newMetadata) => {
                setMetadata(newMetadata);
                setIsEditing(false);
                reloadNotes();
              }}
              onClose={() => setIsEditing(false)}
            ></NoteForm>
          </>
        ) : (
          <Collapse in={showOriginalText || !aiSummary}>
            <NoteMessage message={note?.metadata?.message} />
            <ul className="my-2 flex flex-wrap gap-2">
              {note.metadata?.attachments?.map((attachment, index) => (
                <NoteAttachment
                  createdAt={Number(attachment?.metadata?.createdAt)}
                  parentId={attachment?.metadata?.parentId}
                  parentLabel={attachment?.metadata?.parentLabel}
                  key={attachment?.metadata?.attachmentId}
                  location={attachment?.metadata?.location ?? ''}
                  name={attachment?.metadata?.name ?? ''}
                  type={attachment?.metadata?.contentType ?? ''}
                  url={attachment?.metadata?.presignedGetUrl ?? ''}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </ul>
          </Collapse>
        )}
        <div className="inline-flex items-center">
          {userCan(internalUsersPlusCOA) && showEmojis && emojis && (
            <EmojiReactionRow emojis={emojis} onSelect={addOrRedactEmoji} loggedInUserId={auth?.userId} />
          )}
          {userCan(internalUsersPlusCOA) && showEmojis && <EmojiSelectorButton onSelect={addOrRedactEmoji} />}
        </div>
        {userCan(admins) && awaitingResponse && (
          <div className="flex">
            <Button data-testid="approveRequestButton" type="button" className="m-1" onClick={() => setIsApproval(true)} tiny>
              <Check /> {t('approve')}
            </Button>
            <Button data-testid="denyRequestButton" type="button" className="m-1" onClick={() => setIsApproval(false)} tiny danger>
              <Close /> {t('deny')}
            </Button>
            <SimpleModal
              title={isApproval ? t('approve') : t('deny')}
              isOpen={isApproval !== undefined}
              handleClose={() => setIsApproval(undefined)}
            >
              <Label id="reasonNote" label={t('reason')} />
              <Textarea
                id="reasonNote"
                data-testid="reasonNote"
                loading={resolving}
                value={reasonNote}
                onChange={({ target: { value } }) => setReasonNote(value)}
              />
              <Button data-testid="resolveRequestButton" loading={resolving} onClick={() => resolveRequest()}>
                {isApproval ? t('approve') : t('deny')}
              </Button>
            </SimpleModal>
          </div>
        )}
      </div>
      <Lightbox
        images={images}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
        onClickDownload={() => {
          const attachment = note?.metadata?.attachments?.[currentImageIndex]?.metadata;
          if (attachment) {
            downloadAttachment(attachment);
          }
        }}
      />
    </div>
  );
}
