import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { toDateStringFromUnixMillisecondTimestamp } from './dateFunctions';

export const toRelativeTimeIfTodayFromUnixMillisecondTimestamp = (
  unixTimestamp: string | number | null | undefined,
  { customFormatString = '' } = {},
): string => {
  if (!unixTimestamp) return '';

  const timestampAsInt = typeof unixTimestamp === 'string' ? parseInt(unixTimestamp, 10) : unixTimestamp;
  const timestampAsDayJsObject = dayjs(timestampAsInt);
  const diff = Math.abs(timestampAsDayJsObject.diff(dayjs(), 'hour'));

  if (diff > 12) {
    return toDateStringFromUnixMillisecondTimestamp(timestampAsInt, { customFormatString });
  }

  dayjs.extend(relativeTime);

  return timestampAsDayJsObject.fromNow();
};
