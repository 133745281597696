import React, { useState } from 'react';
import FileCopy from '@mui/icons-material/FileCopy';
import Edit from '@mui/icons-material/Edit';
import camelCase from 'lodash/camelCase';
import { parseContact } from '../../../common/functions/parseContacts';
import Skeleton from '../Loaders/Skeleton';
import IconButton from '../Buttons/IconButton';
import { useAlert } from '../Alerts/AlertContext';
import { formatContact } from '../ServiceRequest/ContactsDisplay';
import EditDescriptionValue from './EditDescriptionValue';
import './descriptionList.css';

export enum descriptionListInputTypes {
  inlineUserSelect = 'inlineUserSelect',
  mainContactSelect = 'mainContactSelect',
  userSelect = 'userSelect',
  checkbox = 'checkbox',
  date = 'date',
  datetime = 'datetime',
  number = 'number',
  input = 'text',
  currency = 'currency',
  select = 'select',
  chipMultiSelect = 'chipMultiSelect',
  multiSelectWithOptions = 'multiSelectWithOptions',
  warning = 'warning',
  contactSelect = 'contactSelect',
  clientTagsMultiSelect = 'clientTagsMultiSelect',
  inlineUserTagsMultiSelect = 'inlineUserTagsMultiSelect',
  textarea = 'textarea',
  invoiceSelect = 'invoiceSelect',
}
export const displayValueStates = { display: 1, edit: 2, loading: 3 };

type FormatterOptionsType = { inputType?: descriptionListInputTypes; formatter?: any };

export const formatBasedOnInputType = (value, { inputType, formatter }: FormatterOptionsType = {}) => {
  if (inputType === descriptionListInputTypes.multiSelectWithOptions) {
    return value.join(', ');
  }

  if (inputType === descriptionListInputTypes.checkbox) {
    return value === true ? 'Yes' : 'No';
  }

  if (inputType === descriptionListInputTypes.contactSelect) {
    const contacts = parseContact(value) || [];

    return contacts.map((x) => formatContact(x.fullName, x.phone, x.email)).join('\n');
  }

  if (formatter) {
    return formatter(value);
  }

  return value;
};

export default function DisplayDescriptionValue({
  loading = false,
  label,
  onSave = null,
  value = '',
  inputType,
  onClick = null,
  disabled = false,
  formatter,
  required,
  ...restOfProps
}) {
  const [state, setState] = useState(displayValueStates.display);
  const showAlert = useAlert();

  const copyValueByRef = (event) => {
    let copyableValue = formatBasedOnInputType(value, { inputType });

    if (typeof copyableValue === 'object') {
      copyableValue = event.target.closest('.description-value')?.querySelector('span')?.firstChild.textContent;
    }

    if (copyableValue) {
      window.navigator.clipboard.writeText(copyableValue);
      showAlert({ content: 'Copied to clipboard' });
    } else {
      showAlert({ content: 'Sorry, this cannot be copied at this time.' });
    }
  };

  if (loading || state === displayValueStates.loading) {
    return (
      <div className="w-full">
        <Skeleton count={1} />
      </div>
    );
  }

  if (state === displayValueStates.edit) {
    return (
      <EditDescriptionValue
        value={React.isValidElement(value) ? value?.props?.children : value}
        inputType={inputType}
        onSave={onSave}
        setState={setState}
        label={label}
        displayValueStates={displayValueStates}
        data-testid={`${camelCase(label)}InputTestId`}
        required={required}
        {...restOfProps}
      />
    );
  }

  if (!disabled && (onSave || onClick)) {
    return (
      <div className="flex w-full items-center justify-between gap-2">
        <span className={`block w-full whitespace-pre-line text-left ${!value || value?.length === 0 ? 'h-full' : ''}`}>
          {formatBasedOnInputType(value, { inputType, formatter })}
        </span>
        <span className="additional-actions invisible flex text-gray-700">
          <IconButton
            data-testid={`${camelCase(label)}TestId`}
            size="xs"
            Icon={Edit}
            text="Edit"
            onClick={onClick ? onClick : () => setState(displayValueStates.edit)}
          />
          <span style={{ padding: '0 2px' }} />
          <IconButton size="xs" Icon={FileCopy} text="Copy" onClick={copyValueByRef} />
        </span>
      </div>
    );
  }

  return (
    <div className="relative flex w-full items-center justify-between gap-2">
      <span className="block w-full">{formatBasedOnInputType(value, { inputType, formatter })}</span>
      <span className="additional-actions invisible text-gray-700">
        <IconButton size="xs" Icon={FileCopy} text="Copy" onClick={copyValueByRef} />
      </span>
    </div>
  );
}
