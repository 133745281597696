import { IconButton } from '@mui/material';
import { Close, GetApp, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import ReactSpringLightbox from 'react-spring-lightbox';

const mod = (n, m) => ((n % m) + m) % m;

type LightboxProps = Pick<React.ComponentProps<typeof ReactSpringLightbox>, 'images'> & {
  currentImageIndex: number;
  setCurrentImageIndex: (index: number) => any;
  onClickDownload?: (...args: any[]) => void;
};

const Lightbox = ({ images = [], currentImageIndex, setCurrentImageIndex, onClickDownload }: LightboxProps) => {
  const gotoPrevious = () => {
    const prevImageIndex = mod(currentImageIndex - 1, images?.length);
    setCurrentImageIndex(prevImageIndex);
  };
  const gotoNext = () => {
    const nextImageIndex = mod(currentImageIndex + 1, images?.length);
    setCurrentImageIndex(nextImageIndex);
  };

  const renderHeader = () => (
    <div className="flex items-center justify-end gap-2 bg-white bg-opacity-10 px-4 py-2 text-white ">
      {onClickDownload && (
        <IconButton className="text-white" onClick={onClickDownload} size="large">
          <GetApp />
        </IconButton>
      )}
      <IconButton className="text-white" onClick={() => setCurrentImageIndex(-1)} size="large">
        <Close />
      </IconButton>
    </div>
  );

  const renderFooter = () => (
    <div className="mx-auto space-y-4 p-8 text-center text-white">
      <p>
        {currentImageIndex + 1} / {images.length}
      </p>
      <p>{images?.[currentImageIndex]?.alt}</p>
    </div>
  );

  const prevButton = () => (
    // it needs the z-index
    <div className="hover:pointer z-50 bg-white bg-opacity-10 px-4 text-white">
      <IconButton className="text-white" onClick={gotoPrevious} size="large">
        <KeyboardArrowLeft />
      </IconButton>
    </div>
  );

  const nextButton = () => (
    <div className="hover:pointer bg-white bg-opacity-10 px-4 text-white">
      <IconButton className="text-white" onClick={gotoNext} size="large">
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );

  if (!images || !images?.length) return <></>;

  return (
    <ReactSpringLightbox
      isOpen={currentImageIndex >= 0}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      images={images}
      currentIndex={currentImageIndex}
      renderHeader={renderHeader}
      renderFooter={renderFooter}
      renderPrevButton={prevButton}
      renderNextButton={nextButton}
      className="bg-black"
      onClose={() => setCurrentImageIndex(-1)}
      style={{ zIndex: 1400 }}
    />
  );
};

export default Lightbox;
