import React, { useState } from 'react';
import { bulkPatchIncident, Equipment, Location, Maybe } from '@equips/entities-schema';
import Warning from '@mui/icons-material/Warning';
import SimpleModal, { simpleModalTypes } from '../../../../common/components/Modal/SimpleModal';
import useMutation from '../../../../common/hooks/useMutation';
import { confirm } from '../../../../common/components/Alerts/Prompt';
import { SubmitRequestSelectEquipment } from '../../../../common/components/ServiceRequest/SubmitRequestSelectEquipment';
import Button from '../../../../common/components/Buttons/Button';
import EquipmentLocationCard from '../../../../common/components/ServiceRequest/EquipmentLocationCard';

type PatchEquipmentModalProps = {
  equipment?: Maybe<Equipment>;
  location?: Maybe<Location>;
  incidentIds?: string[];
  organizationId: string;
  patchEquipmentModalOpen: boolean;
  handleClose: any;
};

export default function PatchEquipmentModal({
  equipment,
  location,
  incidentIds = [],
  organizationId,
  patchEquipmentModalOpen,
  handleClose,
}: PatchEquipmentModalProps) {
  const [saving, setSaving] = useState(false);
  const [patchIncidents] = useMutation(bulkPatchIncident, {
    successMessage: 'Successfully updated service request',
    graphQLQuery: 'incidentId',
  });

  const removeEquipment = async () => {
    setSaving(true);
    const isConfirmed = await confirm('Are you sure you want to remove the equipment from this request?');
    if (isConfirmed) {
      await patchIncidents({ entityIds: incidentIds, variables: { metadata: { equipmentId: null } } });
      handleClose();
    }
    setSaving(false);
  };

  return (
    <SimpleModal
      title="Change the Location/Equipment"
      subtitle="Change what is associated with the service request."
      isOpen={patchEquipmentModalOpen}
      handleClose={handleClose}
      modalType={simpleModalTypes.info}
      size={false}
    >
      <div className="py-4">
        <EquipmentLocationCard equipment={[equipment]} location={location} titleText="Current" />
      </div>

      <span data-testid="patchEquipmentFromServiceRequestModal">
        <SubmitRequestSelectEquipment
          organizationId={organizationId}
          onChange={async ({ location, equipment }) => {
            const locationId = location?.locationId;
            const equipmentId = equipment[0]?.equipmentId;
            await patchIncidents({ entityIds: incidentIds, variables: { metadata: { locationId, equipmentId } } });
            handleClose();
          }}
        />
        <div className="border-t border-gray-300 p-4">
          <Button type="button" loading={saving} danger className="block w-full" onClick={removeEquipment}>
            <Warning className="pr-2" />
            Remove Equipment from Request
          </Button>
        </div>
      </span>
    </SimpleModal>
  );
}
