import React, { lazy, useEffect, useState } from 'react';
import Urls from '../../routes/Urls';
import ProgramFeeReport from './programFeeReports/programFeeReport';

const GridReportContainer = lazy(() => import('./reportLayouts/DeepReportingContainer'));
const ReportingContainer = lazy(() => import('./reportLayouts/ReportingContainer'));
const RequestsCancelsAndSeverity = lazy(() => import('./simpleTableReports/RequestsCancelsAndSeverity'));
const RequestByAccount = lazy(() => import('./simpleTableReports/RequestsByAccount'));
const RequestByProvider = lazy(() => import('./simpleTableReports/RequestsByProvider'));
const ItemLossRatio = lazy(() => import('./lossRatioReports/ItemsLossRatio'));
const ClaimsLossRatio = lazy(() => import('./lossRatioReports/ClaimsLossRatio'));
const OnsiteCheckin = lazy(() => import('./dashboards/OnsiteCheckinDashboard'));
const HandleTime = lazy(() => import('./dashboards/HandleTimeDashboard'));
const ProcessingTime = lazy(() => import('./dashboards/InvoiceProcessingTimeDashboard'));
const Chronic = lazy(() => import('./dashboards/ChronicDashboard'));

function AsyncReportingComponent({ Component, includeDatePicker = true }) {
  return (
    <ReportingContainer includeDatePicker={includeDatePicker}>
      <Component />
    </ReportingContainer>
  );
}

function AsyncDeepReportingComponent({ asyncReport }) {
  const [report, setReport] = useState<any>();

  useEffect(() => {
    async function getModule() {
      const module = await asyncReport;
      setReport(module?.default);
    }
    getModule();
  });

  if (!report) return null;

  return <GridReportContainer report={report} />;
}

export const reportingRoutes = [
  {
    path: Urls.REPORTING,
    component: lazy(() => import('./ReportsScreen')),
  },
  {
    path: Urls.REPORTING_ALLOCATION_REPORT,
    component: () => {
      const report = import('./reportConfigurations/allocationReport');

      return <AsyncDeepReportingComponent asyncReport={report} />;
    },
  },
  {
    path: Urls.REPORTING_COVERAGE_AND_SUBSCRIPTION_REPORT,
    component: () => {
      const report = import('./reportConfigurations/coverageAndSubscriptionsReport');

      return <AsyncDeepReportingComponent asyncReport={report} />;
    },
  },
  {
    path: Urls.REPORTING_AGREEMENT_CHANGE_REPORT,
    component: () => {
      const report = import('./reportConfigurations/agreementChangeReport');

      return <AsyncDeepReportingComponent asyncReport={report} />;
    },
  },
  {
    path: Urls.REPORTING_SERVICE_REQUEST_TO_INVOICE,
    component: () => {
      const report = import('./reportConfigurations/serviceRequestToInvoiceReport');

      return <AsyncDeepReportingComponent asyncReport={report} />;
    },
  },
  {
    path: Urls.REPORTING_SERVICE_REQUEST_ORIGINATION,
    component: () => {
      const report = import('./reportConfigurations/serviceRequestOriginationReport');

      return <AsyncDeepReportingComponent asyncReport={report} />;
    },
  },
  {
    path: Urls.REPORTING_DEDUCTIBLE_REPORT,
    component: () => {
      const report = import('./reportConfigurations/deductibleAccountReport');

      return <AsyncDeepReportingComponent asyncReport={report} />;
    },
  },
  {
    path: Urls.REPORTING_REQUESTS_BY_ACCOUNT,
    component: () => <AsyncReportingComponent Component={RequestByAccount} />,
  },
  {
    path: Urls.REPORTING_BY_PROVIDER,
    component: () => <AsyncReportingComponent Component={RequestByProvider} />,
  },
  {
    path: Urls.REPORTING_CANCEL_SEVERITY,
    component: () => <AsyncReportingComponent Component={RequestsCancelsAndSeverity} />,
  },
  {
    path: Urls.REPORTING_LOSS_RATIO_ITEMS,
    component: () => <AsyncReportingComponent Component={ItemLossRatio} includeDatePicker={false} />,
  },
  {
    path: Urls.REPORTING_LOSS_RATIO_CLAIMS,
    component: () => <AsyncReportingComponent Component={ClaimsLossRatio} />,
  },
  {
    path: Urls.REPORTING_ONSITE_CHECKIN,
    component: () => <AsyncReportingComponent includeDatePicker={false} Component={OnsiteCheckin} />,
  },
  {
    path: Urls.REPORTING_HANDLE_TIME,
    component: () => <AsyncReportingComponent includeDatePicker={false} Component={HandleTime} />,
  },
  {
    path: Urls.REPORTING_PROCESSING_TIME,
    component: () => <AsyncReportingComponent includeDatePicker={false} Component={ProcessingTime} />,
  },
  {
    path: Urls.REPORTING_CHRONIC,
    component: () => <AsyncReportingComponent includeDatePicker={false} Component={Chronic} />,
  },
  {
    path: Urls.REPORTING_PROGRAM_FEES,
    component: () => <AsyncReportingComponent includeDatePicker={false} Component={ProgramFeeReport} />,
  },
];
