import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Equipment, Maybe } from '@equips/entities-schema';
import CloseSharp from '@mui/icons-material/CloseSharp';
import Button from '../Buttons/Button';
import { EquipmentCard } from './EquipmentCard';

type EquipmentMultiCardProps = {
  equipment?: Maybe<Equipment>[];
  setSelectedEquipment?: Dispatch<SetStateAction<Equipment[] | undefined>>;
  placeholder?: string;
};

export function EquipmentMultiCard({ equipment, setSelectedEquipment, placeholder }: EquipmentMultiCardProps) {
  const [selected, setSelected] = useState(equipment?.[0]);

  useEffect(() => (selected !== equipment?.[0] ? setSelected(equipment?.[0]) : () => {}), [equipment]);

  return (
    <div>
      {equipment && equipment.length > 1 && (
        <div className="mb-2 rounded-lg border bg-gray-100 text-xs text-gray-700">
          <div className="m-2 flex gap-2 overflow-x-auto whitespace-nowrap">
            {equipment.map((e) => (
              <Button key={e?.equipmentId} blue outlined={selected !== e} tiny onClick={() => setSelected(e)}>
                <div className="flex items-center">
                  {e?.metadata?.shortId}
                  {setSelectedEquipment && (
                    <CloseSharp
                      className="ml-3"
                      onClick={() => setSelectedEquipment(equipment.filter((eq): eq is Equipment => eq?.equipmentId !== e?.equipmentId))}
                    />
                  )}
                </div>
              </Button>
            ))}
          </div>
        </div>
      )}
      <EquipmentCard equipment={selected || {}} placeholder={placeholder} />
    </div>
  );
}
