import { useContext } from 'react';
import querystring from 'query-string';
import { __RouterContext, RouteComponentProps } from 'react-router';

export interface UseRoute extends RouteComponentProps {
  match: {
    params: any;
    url: any;
    isExact: any;
    path: any;
  };
  location: {
    pathname: any;
    search: any;
    state: any;
    hash: any;
    query: any;
  };
}

export default function useRouter(): UseRoute {
  const routerContext = useContext(__RouterContext);

  const query = querystring.parse(routerContext?.location?.search);

  return { ...routerContext, location: { ...routerContext.location, query } };
}
