import React from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import {
  AasChargeStatusType,
  EntityLabelEnum,
  Invoice,
  patchServiceRequest,
  patchInvoice,
  OrganizationTagsEnum,
} from '@equips/entities-schema';
import { Link } from 'react-router-dom';
import { JsonParam } from 'serialize-query-params';
import { Avatar } from '@mui/material';
import dayjs from 'dayjs';
import { AuthContextValue } from '../../../common/auth/AuthContext';
import { internalUsers } from '../../../common/auth/roles';
import AddressColumn from '../../../common/components/Address/AddressColumn';
import { TableColumn } from '../../../common/components/DataTable';
import { TableFilterTypes } from '../../../common/components/DataTable/TableFilters';
import { defaultColumnWidths } from '../../../common/components/DataTable/columns';
import DisplayTime from '../../../common/components/Date/DisplayTime';
import DropDown from '../../../common/components/DropDown';
import Pill from '../../../common/components/Pills/Pill';
import { toDateStringFromUnixMillisecondTimestamp, toUnixMillisecondsFromString } from '../../../common/functions/dateFunctions';
import { centsToMoney } from '../../../common/functions/moneyFunctions';
import { aasClaimStatuses, aasProcessingStatuses, enumObjectToSelectOptions, getEnumDisplayName } from '../../../graphql/enums';
import { providerValueForFilter } from '../../../graphql/queries/organizationGraphQLQueries';
import Urls from '../../../routes/Urls';
import { AasFailGroupToTextMap } from '../components/AasFailCodesSelect';
import { invoiceStatusesForAAS } from '../../invoices/invoiceStatuses';
import TableCellInlineEditable from '../../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../../common/components/ViewPage/DisplayDescriptionValue';
import { abbreviateName } from '../../../common/functions/abbreviateName';
import ClientTag from '../../../common/components/Tags/ClientTag';
import VipIndicator from '../components/VipIndicator';

interface GetAasInvoicesColumnsProps {
  userCan: AuthContextValue['userCan'];
  userCanViewDetails: boolean;
  isAasUser?: boolean;
  isHomeWarrantyUser?: boolean;
}

export function getAasInvoicesColumns({
  userCan,
  userCanViewDetails,
  isAasUser,
  isHomeWarrantyUser,
}: GetAasInvoicesColumnsProps): TableColumn<Invoice>[] {
  const isAasOrHomeWarrantyUser = isAasUser || isHomeWarrantyUser;

  return [
    {
      id: 'metadata.shortId',
      Header: 'Number',
      accessor: (data) => data.metadata?.shortId,
      Cell: (data) => {
        const invoiceId = data?.row?.original?.metadata?.invoiceId;
        const vip = data?.row?.original?.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.vip || false;

        return userCanViewDetails ? (
          <div className="flex justify-between">
            <Link className="active font-bold" data-testid="toAasInvoiceFromTable" to={`${Urls.AAS_INVOICES}/${invoiceId}`}>
              {data.value}
            </Link>
            {vip && <VipIndicator />}
            <DropDown
              isSmall
              containerClasses="more-actions"
              actions={[
                {
                  'data-invoiceid': invoiceId,
                  'data-testid': 'viewAasChargesLink',
                  Component: <>View charges</>,
                  to: `${Urls.CHARGES}/?invoiceId=${invoiceId}`,
                },
              ]}
            />
          </div>
        ) : (
          <>{data.value}</>
        );
      },
    },
    {
      id: 'metadata.title',
      Header: 'Title',
      accessor: (data) => data.metadata?.title,
      Cell: (data) => <>{data.value}</>,
    },
    {
      id: 'metadata.serviceRequestNumber',
      Header: isAasOrHomeWarrantyUser ? 'Claim #' : 'Service Request #',
      accessor: (data) => data.metadata?.serviceRequestNumber,
      Cell: (data) => {
        const id = data?.row?.original?.metadata?.serviceRequest?.serviceRequestId;
        const claimNumber = data?.row?.original?.metadata?.serviceRequestNumber;

        return (
          <>
            {!claimNumber ? (
              <>None</>
            ) : (
              <a
                className="active"
                href={`${isAasUser ? Urls.AAS_CLAIMS : isHomeWarrantyUser ? Urls.CLAIMS : Urls.SERVICE_REQUESTS}/${id}`}
              >
                {claimNumber}
              </a>
            )}
          </>
        );
      },
    },
    {
      id: 'claimTagsMetadata.tagId',
      Header: isAasOrHomeWarrantyUser ? 'Claim Tags' : 'Service Request Tags',
      accessor: (data) => data.metadata?.serviceRequest?.metadata?.clientTags,
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      filterOptions: {
        type: TableFilterTypes.clientTagsMultiSelect,
        prependedOptions: [{ label: 'None', value: 'none' }],
        defaultValue: [],
        filterType: JsonParam,
        entityLabel: EntityLabelEnum.ServiceRequest,
      },
      toText: (data) => data?.value?.map((t) => t.name)?.join(',') || '',
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value?.map((item) => ({ value: item?.tagId, label: item?.name, style: item?.style }))}
            formatter={(value) => {
              return (
                <div className="inline-block overflow-x-auto">
                  {value?.map(({ value, label, style }) => <ClientTag key={value} label={label} style={style} value={value} />) || ''}
                </div>
              );
            }}
            inputType={descriptionListInputTypes.clientTagsMultiSelect}
            entityLabel={EntityLabelEnum.ServiceRequest}
            label=""
            onSave={(selections) => {
              const clientTags = selections ? selections?.map((selection) => ({ tagId: selection?.value })) : [];

              patchServiceRequest({
                serviceRequestId: data.row?.original?.metadata?.invoiceServiceRequestId,
                metadata: { clientTags },
              });
            }}
          />
        );
      },
    },
    {
      id: 'claimMetadata.claimStatus',
      Header: isAasUser ? 'Claim Status' : 'Service Request Status',
      accessor: (data) => getEnumDisplayName(data.metadata?.serviceRequest?.metadata?.aasClaimStatus, aasClaimStatuses),
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        options: enumObjectToSelectOptions(aasClaimStatuses),
        defaultValue: [],
        filterType: JsonParam,
        showOnMobile: true,
      },
      width: defaultColumnWidths.normalText,
    },
    ...(isAasUser
      ? [
          {
            id: 'claimMetadata.processingStatus',
            Header: 'Claim Processing Status',
            accessor: (data) => getEnumDisplayName(data.metadata?.serviceRequest?.metadata?.aasProcessingStatus, aasProcessingStatuses),
            filterOptions: {
              type: TableFilterTypes.multiSelect,
              options: [{ value: 'none', label: 'None' }, ...enumObjectToSelectOptions(aasProcessingStatuses)],
              defaultValue: [],
              filterType: JsonParam,
              showOnMobile: true,
            },
            width: defaultColumnWidths.normalText,
          },
        ]
      : []),
    {
      id: 'metadata.invoiceStatus',
      Header: 'Invoice Status',
      accessor: (data) => data.metadata?.invoiceStatus,
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        options: enumObjectToSelectOptions(invoiceStatusesForAAS),
        defaultValue: [],
        filterType: JsonParam,
      },
      width: defaultColumnWidths.mediumText,
      Cell: (data) => getEnumDisplayName(data.value, invoiceStatusesForAAS),
    },
    {
      id: 'locationContacts.fullName',
      Header: 'Customer name',
      accessor: (data) => data.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.location?.metadata?.contacts?.[0]?.fullName,
      disableSortBy: true,
    },
    {
      id: 'locationAddress.line1',
      Header: 'Address',
      accessor: (data) => data.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.location?.address?.line1,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (data) =>
        formatAddressAsOneLine(data?.row?.original?.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.location?.address),
      Cell: (data) => (
        <AddressColumn addressObject={data?.row?.original?.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.location?.address} />
      ),
    },
    {
      id: 'locationAddress.city',
      Header: 'City',
      accessor: (data) => data.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.location?.address?.city,
      disableSortBy: true,
    },
    {
      id: 'locationAddress.stateUnabbreviated',
      Header: 'State',
      accessor: (data) => data.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.location?.address?.stateUnabbreviated,
      disableSortBy: true,
      Cell: (data) =>
        data?.row?.original?.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.location?.address?.stateUnabbreviated || '',
    },
    {
      id: 'locationAddress.zip',
      Header: 'Zip',
      accessor: (data) => data.metadata?.serviceRequest?.metadata?.aasContract?.metadata?.location?.address?.zip,
      disableSortBy: true,
    },
    ...(isAasUser
      ? [
          {
            Header: 'Failure groups',
            id: 'serviceRequest.aasFailCategories',
            accessor: (data) => data.metadata?.serviceRequest?.metadata?.aasFailCategories,
            width: defaultColumnWidths.mediumText,
            disableSortBy: true,
            Cell: (data) => {
              const aasFailCategories = data?.row?.original?.metadata?.serviceRequest?.metadata?.aasFailCategories;

              return (
                <div className="overflow-auto">
                  {aasFailCategories &&
                    aasFailCategories.map((cat) => (
                      <Pill key={cat} className="text-xs">
                        {AasFailGroupToTextMap[cat]}
                      </Pill>
                    ))}
                </div>
              );
            },
          },
        ]
      : []),

    ...(userCan(internalUsers)
      ? [
          {
            id: 'claimMetadata.requestedProviderId',
            Header: 'Provider',
            accessor: (data) => data.metadata?.serviceRequest?.metadata?.requestedProvider?.metadata?.organizationName,
            filterOptions: {
              hidden: !userCan(internalUsers),
              type: TableFilterTypes.organizationSelect,
              label: 'Provider',
              typeOfOrganizationToFind: providerValueForFilter,
              allowNullNotNull: true,
              organizationTags: [OrganizationTagsEnum.Aas],
            },
            disableSortBy: true,
            Cell: (data) => (
              <Link
                className="secondary-active"
                to={`${Urls.AAS_ORGANIZATIONS}/${data?.row?.original?.metadata.serviceRequest?.metadata?.requestedProvider?.metadata?.organizationId}`}
              >
                {data.value}
              </Link>
            ),
          },
        ]
      : []),
    {
      id: 'metadata.externalInvoiceNumber',
      Header: 'Provider Invoice #',
      accessor: (data) => data.metadata?.externalInvoiceNumber,
      Cell: (data) => {
        const externalInvoiceNumber = data?.row?.original?.metadata?.externalInvoiceNumber || 'None';
        const fileGetUrl = data?.row?.original?.metadata?.file?.metadata?.presignedGetUrl ?? '#';

        return (
          <a className="active" href={fileGetUrl}>
            {externalInvoiceNumber}
          </a>
        );
      },
    },
    {
      Header: 'Approved on',
      id: 'metadata.approvedOn',
      accessor: (data) => data.metadata?.approvedOn,
      width: 150,
      toText: (data) =>
        `${
          toDateStringFromUnixMillisecondTimestamp(data.value, {
            customFormatString: 'MM/DD/YYYY hh:mm a',
          }) || 'Not approved'
        }`,
      Cell: (data) => <DisplayTime timestamp={data.value} />,
      filterOptions: {
        type: TableFilterTypes.dateRange,
        label: 'Approved on',
      },
    },
    {
      id: 'metadata.approvedByUserId',
      Header: 'Approved by',
      accessor: (data) => data.metadata?.approvedByUser,
      Cell: (data) => {
        return <Link to={`${Urls.USERS}/${data?.value?.metadata?.userId}`}>{data?.value?.metadata?.fullName ?? ''}</Link>;
      },
      filterOptions: {
        type: TableFilterTypes.userSelect,
      },
      disableSortBy: true,
    },
    {
      id: 'metadata.finalizedOn',
      Header: 'Finalized on',
      accessor: (data) => data.metadata?.finalizedOn,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data?.row?.original?.metadata?.finalizedOn) || 'Not finalized',
    },
    {
      id: 'metadata.finalizedByUserId',
      Header: 'Finalized by',
      accessor: (data) => data.metadata?.finalizedByUser,
      Cell: (data) => {
        return <Link to={`${Urls.USERS}/${data?.value?.metadata?.userId}`}>{data?.value?.metadata?.fullName ?? ''}</Link>;
      },
      filterOptions: {
        type: TableFilterTypes.userSelect,
      },
      disableSortBy: true,
    },
    {
      id: 'metadata.charges',
      Header: 'Approved total',
      accessor: (data) => data?.metadata?.charges,
      disableSortBy: true,
      Cell: (data) => {
        const approvedCharges =
          data?.row?.original?.metadata?.charges?.filter((charge) => charge?.metadata?.aasChargeStatus === AasChargeStatusType.Approved) ||
          [];
        const approvedTotal = approvedCharges.reduce((acc, curr) => acc + curr?.metadata?.amountInCents, 0);

        return centsToMoney(approvedTotal);
      },
    },
    {
      id: 'metadata.quoteFileId',
      Header: 'Estimate File',
      accessor: (data) => data.metadata?.quoteFileId,
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Has estimate file', value: 'true' },
          { label: 'No estimate file', value: 'false' },
        ],
      },
      width: defaultColumnWidths.normalText,
      Cell: (data) => (data.value ? 'Yes' : 'No'),
    },
    {
      id: 'metadata.fileId',
      Header: 'Invoice File',
      accessor: (data) => data.metadata?.fileId,
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Has invoice file', value: 'true' },
          { label: 'No invoice file', value: 'false' },
        ],
      },
      width: defaultColumnWidths.normalText,
      Cell: (data) => (data.value ? 'Yes' : 'No'),
    },
    ...(userCan(internalUsers)
      ? [
          {
            id: 'metadata.invoiceTotal',
            Header: 'Invoice total',
            accessor: (data) => data.metadata?.invoiceTotal,
            Cell: (data) => centsToMoney(data?.row?.original?.metadata?.invoiceTotal),
          },
          {
            id: 'metadata.assigneeUserId',
            Header: 'Assignee',
            accessor: (data) => data.metadata?.assigneeUserId,
            width: defaultColumnWidths.mediumText,
            filterOptions: {
              hidden: !userCan(internalUsers),
              type: TableFilterTypes.userSelect,
            },
            Cell: (data) => {
              return (
                <TableCellInlineEditable
                  {...data}
                  fieldToPatch="assigneeUserId"
                  inputType={descriptionListInputTypes.inlineUserSelect}
                  formatter={(_value) => {
                    const fullName = data.row?.original?.metadata?.assignee?.metadata?.fullName;
                    const url = data.row?.original?.metadata?.assignee?.metadata?.profilePictureUrl;
                    if (fullName) {
                      return (
                        <div className="flex items-center gap-2">
                          <Avatar src={url ?? ''} alt={fullName ?? 'Unknown User'} className="h-8 w-8 bg-blue-700">
                            {abbreviateName(fullName)}
                          </Avatar>
                          {fullName}
                        </div>
                      );
                    } else {
                      return <p>Unassigned</p>;
                    }
                  }}
                  onSave={async (_value) => {
                    data.refetchData();
                  }}
                  entityLabel={EntityLabelEnum.Invoice}
                  entityId={data?.row?.original?.metadata?.invoiceId}
                />
              );
            },
          },
          {
            id: 'metadata.followUpDate',
            Header: 'Follow-up date',
            accessor: (data) => data.metadata?.followUpDate,
            width: defaultColumnWidths.mediumText,
            toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }),
            Cell: (data) => {
              return (
                <TableCellInlineEditable
                  {...data}
                  value={data?.value}
                  formatter={(value) => {
                    const dateTimeString =
                      toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }) || 'None';

                    return <>{value ? (value !== dateTimeString ? dayjs(value)?.format('MM/DD/YYYY hh:mma') : dateTimeString) : ''}</>;
                  }}
                  inputType={descriptionListInputTypes.datetime}
                  onSave={async (value) => {
                    const timestamp = toUnixMillisecondsFromString(value);

                    await patchInvoice({
                      invoiceId: data.row?.original?.metadata?.invoiceId,
                      metadata: { followUpDate: timestamp },
                    });

                    data.refetchData();
                  }}
                />
              );
            },
            filterOptions: {
              type: TableFilterTypes.dateRange,
            },
          },
        ]
      : []),
  ];
}
