import React from 'react';
import PropTypes from 'prop-types';

export default function ReactSelectRequiredInput({ isValid }) {
  return (
    <input
      data-testid="requiredInput"
      autoComplete="off"
      className="absolute inset-0 w-full"
      tabIndex="-1"
      style={{ zIndex: '-1' }}
      onChange={() => ({})}
      value={isValid ? 'valid' : ''}
      required
    />
  );
}

ReactSelectRequiredInput.propTypes = { isValid: PropTypes.any };
ReactSelectRequiredInput.defaultProps = { isValid: false };
