import { queryAasFailCodes, SortByElasticSearchOptions } from '@equips/entities-schema';

export const getAasFailCodes = async () => {
  const { data } = await queryAasFailCodes(
    {
      search: {
        sortBy: [{ metadata: { code: { keyword: SortByElasticSearchOptions.Asc } } }],
        filters: [],
      },
    },
    {
      query: `
        aasFailCodeId
        metadata {
          aasFailCodeId
          code
          description
          group
        }
    `,
    },
  );

  return { totalCount: data?.aasFailCodes?.totalCount, data: data?.aasFailCodes?.data ?? [] };
};
