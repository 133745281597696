import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Equipment,
  EquipmentStatusType,
  Maybe,
  patchEquipment,
  patchServiceRequest,
  ServiceRequestStatusType,
} from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from '../../../../common/components/Alerts/AlertContext';
import Button from '../../../../common/components/Buttons/Button';
import SimpleModal, { simpleModalTypes } from '../../../../common/components/Modal/SimpleModal';
import useMutation from '../../../../common/hooks/useMutation';
import { entityIcons } from '../../../../graphql/entityIcons';
import { getServiceRequestForProposedEquipmentByEquipmentId } from '../../../../graphql/queries/serviceRequestGraphQLQueries';
import Urls from '../../../../routes/Urls';
import { ProposedEquipmentServiceRequestsModalContent } from '../../../equipment/ProposedEquipmentServiceRequestsModal';

const Icon = entityIcons.equipment;

type ProposedSRWithProposedEquipmentModalProps = {
  isOpen: boolean;
  handleClose: () => any;
  equipment: Maybe<Equipment>;
  dispatchToEquips: boolean;
  serviceRequestId: string;
  canDispatchInternally: boolean;
};

enum formStage {
  START = 0,
  EQUIPMENT_APPROVED = 1,
  MORE_SERVICE_REQUEST = 2,
}

export const EquipmentWrapper = ({
  equipmentName,
  equipmentId,
  shortId,
  model,
  manufacturer,
  serialNumber,
  equipmentStatus,
}: {
  [field: string]: Maybe<string>;
}) => (
  <div className={`my-6 flex w-full items-center space-x-2 rounded-lg border border-gray-300 px-4 py-2 text-left text-gray-800 `}>
    <div className="pr-2 text-gray-600">
      <Icon color="inherit" />
    </div>
    <div className="flex w-full flex-wrap">
      <>
        <div className="w-full ">
          <Link className="text-lg font-semibold text-gray-700 underline hover:text-gray-500" to={`${Urls.EQUIPMENT}/${equipmentId}`}>
            {equipmentName}
            {shortId ? `- ${shortId}` : ''}
          </Link>
        </div>
        <div className="mt-1 w-full text-sm text-gray-600">
          {model && (
            <span className="pr-2">
              <span className="font-semibold">Model:</span> {model}
            </span>
          )}
          {manufacturer && (
            <span className="pr-2">
              <span className="font-semibold">Manufacturer:</span> {manufacturer}
            </span>
          )}
          {serialNumber && (
            <span className="pr-2">
              <span className="font-semibold">Serial number:</span> {serialNumber}
            </span>
          )}

          <div className="pr-2">
            <span className="font-semibold">Status:</span>{' '}
            <span className={`${equipmentStatus === EquipmentStatusType.Active ? `text-equipsGreen` : ''}`}>{equipmentStatus}</span>
          </div>
        </div>
      </>
    </div>
  </div>
);

const ProposedSRWithProposedEquipmentModal = ({
  isOpen,
  handleClose,
  equipment,
  dispatchToEquips,
  serviceRequestId,
  canDispatchInternally,
}: ProposedSRWithProposedEquipmentModalProps) => {
  const [stage, setStage] = useState(formStage.START);
  const showAlert = useAlert();

  const equipmentId = equipment?.metadata?.equipmentId || '';
  const equipmentName = equipment?.metadata?.equipmentName;
  const shortId = equipment?.metadata?.shortId;
  const model = equipment?.specMetadata?.model;
  const manufacturer = equipment?.specMetadata?.manufacturer;
  const serialNumber = equipment?.metadata?.serialNumber;
  const equipmentStatus = equipment?.metadata?.equipmentStatus;

  const { data: serviceRequestData, isFetching } = useQuery(
    ['getServiceRequestForProposedEquipmentByEquipmentId'],
    () => getServiceRequestForProposedEquipmentByEquipmentId({ equipmentId, excludedSRId: serviceRequestId }),
    {
      enabled: isOpen && !!equipmentId,
      select: (data) => data?.data,
    },
  );

  const serviceRequests = serviceRequestData?.serviceRequests?.data || [];

  const [approveServiceRequest, { saving: savingServiceRequest }] = useMutation(patchServiceRequest, {
    successMessage: 'Service Request Status has been updated',
    onCompleted: () => {
      serviceRequests.length > 0 ? setStage(formStage.MORE_SERVICE_REQUEST) : handleClose();
    },
  });

  const [approveEquipment, { saving: savingEquipment }] = useMutation(patchEquipment, {
    onCompleted: () => setStage(formStage.EQUIPMENT_APPROVED),
  });

  if (stage === formStage.EQUIPMENT_APPROVED) {
    return (
      <SimpleModal
        {...{
          isOpen,
          handleClose,
          modalType: simpleModalTypes.success,
          title: 'Equipment approved.',
          subtitle: 'You can now dispatch this Service Request.',
        }}
      >
        <EquipmentWrapper
          {...{ equipmentName, equipmentId, shortId, model, manufacturer, serialNumber, equipmentStatus: EquipmentStatusType.Active }}
        />
        <div className="ml-auto mt-4 flex items-center justify-end gap-3">
          {canDispatchInternally && (
            <Button
              className="mr-auto"
              outlined
              fullWidth
              loading={savingServiceRequest}
              onClick={() =>
                approveServiceRequest({ serviceRequestId, metadata: { requestStatus: ServiceRequestStatusType.InternalDispatch } })
              }
            >
              {dispatchToEquips ? 'Dispatch Internally' : 'Continue to Dispatch Internally'}
            </Button>
          )}

          <Button
            fullWidth
            loading={savingServiceRequest}
            onClick={() =>
              approveServiceRequest({ serviceRequestId, metadata: { requestStatus: ServiceRequestStatusType.EquipsDispatch } })
            }
          >
            {dispatchToEquips ? 'Continue to Dispatch to Provider' : 'Let Equips handle it'}
          </Button>
        </div>
      </SimpleModal>
    );
  }

  if (stage === formStage.MORE_SERVICE_REQUEST) {
    return (
      <SimpleModal
        {...{
          isOpen,
          handleClose,
          size: 'md',
          title: 'More Proposed Service Requests for This Equipment',
          subtitle: 'We found more Service Requests that are created when this Equipment is in proposed status',
        }}
      >
        <ProposedEquipmentServiceRequestsModalContent
          {...{
            serviceRequests,
            loading: isFetching,
          }}
        />
        <Button className="ml-auto block" blue onClick={handleClose}>
          Handle Later
        </Button>
      </SimpleModal>
    );
  }

  return (
    <SimpleModal
      {...{
        isOpen,
        handleClose,
        modalType: simpleModalTypes.info,
        title: 'Equipment for this request is in proposed status',
        subtitle: 'You need to approve this Equipment before dispatching this Service Request',
      }}
    >
      <EquipmentWrapper {...{ equipmentName, equipmentId, shortId, model, manufacturer, serialNumber, equipmentStatus }} />
      <Button
        className="ml-auto block"
        loading={savingEquipment}
        onClick={() => {
          try {
            approveEquipment({ equipmentId, metadata: { equipmentStatus: EquipmentStatusType.Active } });
          } catch (error) {
            showAlert({ content: 'Error' });
          }
        }}
      >
        Activate Equipment
      </Button>
    </SimpleModal>
  );
};

export default ProposedSRWithProposedEquipmentModal;
