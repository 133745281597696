export const abbreviateName = (nameString) => {
  if (!nameString) return '';
  const [firstString, secondString] = nameString.trim().split(' ');

  return [firstString, secondString ? secondString : firstString[1]]
    .filter((n) => n && n[0])
    .map((n) => n[0])
    .join('')
    .toUpperCase();
};
