import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import { CellProps, HeaderProps, Renderer } from 'react-table';
import { Translation } from 'react-i18next';
import { toDateStringFromUnixMillisecondTimestamp } from '../../functions/dateFunctions';
import DisplayTime from '../Date/DisplayTime';
import { TableColumn } from './types';
import { TableFilterTypes } from './TableFilters';

export const createdColumnQuery = `createdAt createdByUser { metadata { fullName } }`;
export const modifiedColumnQuery = `modifiedAt modifiedByUser { metadata { fullName } }`;

export const defaultColumnWidths = {
  extraSmall: 100,
  smallText: 120,
  normalText: 160,
  mediumText: 220,
  largeText: 360,
};

const useCheckboxStyles = makeStyles({ root: { padding: '0' } });

const CheckboxHeader: Renderer<HeaderProps<any>> = ({ getToggleAllRowsSelectedProps }) => {
  const checkboxClasses = useCheckboxStyles();

  return (
    <div>
      <Checkbox classes={checkboxClasses} size="small" {...getToggleAllRowsSelectedProps()} />
    </div>
  );
};

const CheckboxCell: Renderer<CellProps<any>> = ({ row }) => {
  const checkboxClasses = useCheckboxStyles();

  return (
    <div>
      <Checkbox data-testid="tableRowCheckbox" classes={checkboxClasses} size="small" {...row.getToggleRowSelectedProps()} />
    </div>
  );
};

export const deactivatedColumn: TableColumn<any> = {
  id: 'metadata.deactivatedAt',
  accessor: (data) => data.metadata.deactivatedAt,
  Header: <Translation>{(t) => <>{t('deactivatedAt')}</>}</Translation>,
  filterOptions: {
    type: TableFilterTypes.hidden,
  },
};

export const createdByColumn: TableColumn<any> = {
  Header: <Translation>{(t) => <>{t('createdBy')}</>}</Translation>,
  id: 'metadata.createdByUserId',
  accessor: (data) => data.metadata.createdByUserId,
  width: defaultColumnWidths.normalText,
  toText: (data) => data.row.original?.metadata?.createdByUser?.metadata?.fullName || '',
  Cell: (data) => data.row.original?.metadata?.createdByUser?.metadata?.fullName || '',
};

export const modifiedByColumn: TableColumn<any> = {
  Header: <Translation>{(t) => <>{t('modifiedBy')}</>}</Translation>,
  id: 'metadata.modifiedByUserId',
  accessor: (data) => data.metadata.modifiedByUserId,
  width: defaultColumnWidths.normalText,
  toText: (data) => data.row.original?.metadata?.modifiedByUser?.metadata?.fullName || '',
  Cell: (data) => data.row.original?.metadata?.modifiedByUser?.metadata?.fullName || '',
};

export const selectionColumn: TableColumn<any> = {
  id: 'selection',
  width: 0,
  maxWidth: 64,
  Header: CheckboxHeader,
  Cell: CheckboxCell,
  disableSortBy: true,
};

export const createdColumn: TableColumn<any> = {
  Header: <Translation>{(t) => <>{t('createdAt')}</>}</Translation>,
  id: 'metadata.createdAt',
  accessor: (data) => data.metadata?.createdAt,
  width: 120,
  toText: (data) =>
    toDateStringFromUnixMillisecondTimestamp(data.row.original?.metadata?.createdAt, { customFormatString: 'MM/DD/YYYY hh:mm a' }),
  Cell: (data) => <DisplayTime timestamp={data.value} />,
};

export const modifiedColumn: TableColumn<any> = {
  Header: <Translation>{(t) => <>{t('modifiedAt')}</>}</Translation>,
  id: 'metadata.modifiedAt',
  accessor: (data) => data.metadata?.modifiedAt,
  width: 120,
  toText: (data) =>
    toDateStringFromUnixMillisecondTimestamp(data.row.original?.metadata?.modifiedAt, { customFormatString: 'MM/DD/YYYY hh:mm a' }),
  Cell: (data) => <DisplayTime timestamp={data.value} />,
};
