import { API } from 'aws-amplify';

export const getHMACHash = async (email: string) => {
  try {
    const { data } = await API.post('equips-app', `/generate-hmac`, {
      response: true,
      body: {
        email,
      },
    });
    return data.hash;
  } catch (error) {
    console.log(error);
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 'p5p8f365',
      });
    }
  }
};
