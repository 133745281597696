import React, { useState } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Incident, Maybe, ServiceRequest } from '@equips/entities-schema';
import Skeleton from '@mui/material/Skeleton';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { findEnumNameByValue, serviceRequestDispatchStatuses } from '../../../graphql/enums';
import Urls from '../../../routes/Urls';
import { useAuth } from '../../auth/AuthContext';
import { centsToMoney } from '../../functions/moneyFunctions';
import { internalUsers } from '../../auth/roles';
import NoteList from '../ViewPage/NoteList';
import DisplayTime from '../Date/DisplayTime';
import { ButtonActionType } from '../ViewPage/DescriptionList';
import Pill from '../Pills/Pill';

interface ServiceRequestCardProps {
  incident: Maybe<Incident>;
  serviceRequest: Maybe<ServiceRequest>;
  loading?: boolean;
  actions?: (Partial<ButtonActionType> & { color?: string; saving?: boolean })[];
}

const ServiceRequestCard = ({ incident, serviceRequest, actions, loading }: ServiceRequestCardProps) => {
  const { t } = useTranslation();
  const { userCan } = useAuth();
  const [showNotes, setShowNotes] = useState(false);
  const [showCharges, setShowCharges] = useState(false);
  const Separator = () => <div className="my-2 w-full border-t border-gray-300" />;

  if (!incident || !serviceRequest) return null;

  return (
    <div className="mb-5 w-full rounded-lg border border-gray-300 bg-white px-8 py-4 shadow">
      <div className="flex flex-wrap items-center justify-between gap-2">
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              serviceRequest?.metadata?.serviceRequestId
                ? `${Urls.SERVICE_REQUESTS}/${serviceRequest?.metadata?.serviceRequestId}`
                : `${Urls.INCIDENTS}/${incident.metadata?.incidentId}`
            }
          >
            <h3 className="font-bold text-gray-700 hover:text-blue-600">
              {serviceRequest?.metadata?.serviceRequestDisplayName || 'Detached incident'}
            </h3>
          </a>
        </div>
        <div className="text-xs text-gray-500">
          {t('createdAt')} <DisplayTime timestamp={Number(serviceRequest.metadata?.createdAt)}></DisplayTime>
        </div>
      </div>
      <Separator />

      <div className="mt-3 text-sm">
        {serviceRequest?.metadata?.potentiallyChronic && <p className="font-semibold">{t('potentiallyChronic')}</p>}
        {serviceRequest?.metadata?.requestStatus && (
          <div className="mb-2 inline-block">
            <span className="text-gray-600">{t('status')}: </span>
            <Pill color={'bg-blue-200 text-blue-800 border-blue-400 border'} fontSize="text-2xs">
              {findEnumNameByValue(serviceRequest.metadata.requestStatus, serviceRequestDispatchStatuses)}
            </Pill>
          </div>
        )}
        {serviceRequest?.metadata?.serviceType && (
          <p>
            <span className="text-gray-600">{t('serviceType')}: </span>
            {serviceRequest.metadata.serviceType}
          </p>
        )}

        <div className="mb-2 flex items-baseline gap-2">
          <span className="text-gray-600">{t('description')}: </span>
          <span
            data-testid="srDescription"
            className="text-sm text-black"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(serviceRequest.metadata?.description ?? '') }}
          />
        </div>

        <span className="text-gray-600">{t('symptoms')}: </span>
        {Array.isArray(incident.metadata?.symptoms) ? incident.metadata?.symptoms.join() : ''}
        {incident.metadata?.createdByUser?.metadata?.fullName && (
          <p>
            <span className="text-gray-600">{t('createdBy')}: </span>
            {incident.metadata?.createdByUser?.metadata?.fullName}
          </p>
        )}
        {userCan(internalUsers) && incident.charges && incident.charges.length > 0 && (
          <div className="mt-3">
            <Separator />
            <button type="button" onClick={() => setShowCharges(!showCharges)} className="focus:none text-gray-600 hover:text-blue-600">
              {t('charges')} ({centsToMoney(incident.charges.reduce((prev, cur) => prev + (cur?.metadata?.amountInCents || 0), 0))})
              <ExpandMore fontSize="small" />
            </button>
          </div>
        )}
        {showCharges && incident.charges && incident.charges.length > 0 && (
          <>
            {incident.charges.map((charge, index) => {
              const invoiceId = charge?.metadata?.invoiceId;
              const amountInCents = charge?.metadata?.amountInCents;
              const description = charge?.metadata?.description;

              return (
                <p key={index}>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={invoiceId ? `${Urls.INVOICES}/${invoiceId}` : ''}
                  >
                    {amountInCents && centsToMoney(amountInCents)}
                  </a>
                  {description && ` - ${description}`}
                </p>
              );
            })}
          </>
        )}
        {!loading && serviceRequest?.notes && serviceRequest?.notes?.length > 0 && (
          <div className="mt-3">
            <button type="button" onClick={() => setShowNotes(!showNotes)} className="focus:none text-gray-600 hover:text-blue-600">
              {t('notes')} ({serviceRequest?.notes.length})
              <ExpandMore fontSize="small" />
            </button>
          </div>
        )}
        {serviceRequest?.notes && showNotes && (
          <NoteList
            readOnly
            loading={loading}
            notes={serviceRequest?.notes}
            emptyTitle={t('noDataFoundWithName', { name: t('notes') })}
            unknownAuthorName={t('system')}
          />
        )}
      </div>
      {actions && (
        <>
          <Separator />
          <div className="flex justify-start gap-3">
            {(actions || []).map(({ text, Icon, onClick, color, saving }) =>
              !saving ? (
                <button
                  data-testid={`${text?.toLowerCase()}ButtonAction`}
                  onClick={onClick}
                  type="button"
                  key={text}
                  className="outline-none"
                >
                  {Icon && <Icon fontSize="small" className={`${color ? color : 'text-equipsLightBlue'}`} style={{ fontSize: 'medium' }} />}
                  <span className={`w-full ${color ? color : 'text-equipsLightBlue'} ml-1 text-xs`}>{text}</span>
                </button>
              ) : (
                <Skeleton key={text} width="20%" />
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceRequestCard;
