/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import config from './config';

const languages = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    debug: !config?.isProd,
    fallbackLng: location.hostname !== 'localhost' ? 'en' : undefined,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['translation', 'enum'],
    defaultNS: 'translation',
  });

export { i18n as default, languages };
