import React, { useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';

type FlatAccordionProps = { defaultExpanded?: boolean; children: any; title: string; [key: string]: any; rightToggleIcon?: boolean };

export default function FlatAccordion({
  defaultExpanded,
  children,
  title,
  rightToggleIcon = false,
  buttonClassName,
  ...restOfProps
}: FlatAccordionProps) {
  const [expanded, setExpanded] = useState(restOfProps.expanded ?? defaultExpanded);

  useEffect(() => {
    if (restOfProps.expanded == undefined) return;
    setExpanded(restOfProps.expanded);
  }, [restOfProps.expanded]);

  return (
    <div {...restOfProps}>
      <button
        type="button"
        className={`flex w-full justify-between pt-3 pb-1 text-left text-sm font-semibold text-gray-700 hover:text-gray-800 ${buttonClassName}`}
        onClick={() => setExpanded((state) => !state)}
      >
        {rightToggleIcon && <span className="font-semibold">{title}</span>}
        <div>
          <ExpandMore className={`rotate mr-1 transform transition-transform ${expanded && 'rotate-180'}`} />
          {!rightToggleIcon && <span className={`font-semibold ${restOfProps.titleClassName}`}>{title}</span>}
        </div>
      </button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
}
