import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import { EntityLabelEnum } from '@equips/entities-schema';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';
import IconButton from '../Buttons/IconButton';
import Skeleton from '../Loaders/Skeleton';
import InputType from '../ViewPage/InputType';
import { descriptionListInputTypes, displayValueStates } from '../ViewPage/DisplayDescriptionValue';

type CellState = 'display' | 'edit' | 'loading';

type TableCellInlineEditable = {
  inputType: descriptionListInputTypes;
  onSave: (...args: any[]) => any;
  formatter?: (value: any) => any;
  options?: { label: string; value: string | number }[];
  entityLabel?: EntityLabelEnum;
  entityId?: string;
  fieldToPatch?: string;
  [x: string]: any;
  userId?: string;
};

const TableCellInlineEditable = ({
  value: initialValue,
  column: { id },
  onSave,
  inputType,
  options,
  entityLabel,
  entityId,
  fieldToPatch,
  userId,
  ...props
}: TableCellInlineEditable) => {
  const showAlert = useAlert();
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState<CellState>('display');
  const [value, setValue] = useState(initialValue);
  const [showEdit, setShowEdit] = useState(false);
  const [defaultTagValue, setDefaultTagValue] = useState(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleEdit = (event) => {
    const element = event.currentTarget?.parentElement?.parentElement?.parentElement || null;
    setAnchorEl(element);
    setState('edit');
  };

  const handleCancel = () => {
    setValue(initialValue);
    setAnchorEl(null);
    setState('display');
  };

  const handleSave = async () => {
    setState('loading');

    const response = await onSave(value, defaultTagValue);
    // If user only has 1 tag, assign it as the main tag
    if (value?.length === 1 && !defaultTagValue) {
      setDefaultTagValue(value?.[0]?.tagId);
      await onSave(value, value?.[0]?.tagId);
    }

    if (response?.errors) {
      setValue(initialValue);
      showAlert({ content: 'Error editing cell', type: AlertTypes.error });
    } else {
      showAlert({ content: 'Successfully updated' });
    }

    setAnchorEl(null);
    setState('display');
  };

  if (state === 'loading') {
    return (
      <div className="w-full">
        <Skeleton count={1} />
      </div>
    );
  }

  const userClientTags = !!userId;

  return (
    <div
      className={`flex w-full items-center justify-between gap-2 ${!value || value?.length === 0 ? 'h-6' : ''}`}
      onMouseOver={() => {
        setShowEdit(true);
      }}
      onMouseLeave={() => setShowEdit(false)}
      onFocus={() => void 0}
    >
      {props?.formatter ? props?.formatter(value) : value}
      <span className="text-gray-700">
        {showEdit && <IconButton data-testid={`${id}TestId`} size="xs" Icon={Edit} text="Edit" onClick={handleEdit} />}
      </span>
      <Popover
        open={state === 'edit'}
        onClose={() => setState('display')}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className={`flex w-96 gap-2 p-4 ${userClientTags ? 'items-start' : 'items-center'}`}>
          <div className="w-5/6">
            <InputType
              inputType={inputType}
              inputValue={value}
              setInputValue={setValue}
              setState={setState}
              handleSave={handleSave}
              label={props?.label ?? id}
              required={undefined}
              displayValueStates={displayValueStates}
              options={options ?? []}
              entityLabel={entityLabel}
              entityId={entityId}
              fieldToPatch={fieldToPatch}
              dateValidation={props?.dateValidation}
              userId={userId}
              setTagValue={setDefaultTagValue}
            />
          </div>
          <IconButton text="Cancel" Icon={Close} size="xs" onClick={handleCancel} />
          <IconButton data-testid={`${id}Submit`} type="button" text="Save" Icon={Check} size="xs" onClick={() => handleSave()} />
        </form>
      </Popover>
    </div>
  );
};

export default TableCellInlineEditable;
