import isValidDomainCheck from 'is-valid-domain';
import disallowedEmails from '../constants/disallowedEmails';
import normalizeEmail from './normalizeEmail';

export const getDomainFromEmailAddress = (email) => {
  const emailAddressPieces = normalizeEmail(email).split('@');
  const domain = emailAddressPieces && emailAddressPieces[emailAddressPieces.length - 1];

  if (!domain) return false;

  return domain;
};
export const isValidDomain = (domain) => isValidDomainCheck(domain, { subdomain: false });

export const isPublicDomain = (domain) => {
  if (!domain) return false;

  return disallowedEmails[domain.trim()] ? true : false;
};

export default function isPublicEmail(email) {
  if (!email) return false;

  const domain = getDomainFromEmailAddress(email);
  if (!domain) return false;

  return disallowedEmails[domain] ? true : false;
}
