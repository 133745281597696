import React from 'react';
import Header, { HeaderProps } from '../Heading/Header';

interface MainFormProps {
  children?: React.ReactNode;
}

export default function MainForm({ children, title, subtitle, routes, className, ...restOfProps }: MainFormProps & HeaderProps) {
  return (
    <div className={`m-auto max-w-3xl ${className}`}>
      <Header routes={routes} className="mx-auto py-8" title={title} subtitle={subtitle} {...restOfProps} />

      <section className="m-auto mb-12 rounded border-t-2 border-equipsLightBlue bg-white p-8 shadow md:mb-24">{children}</section>
    </div>
  );
}
