import { UsersMutationPatchArgs, View } from '@equips/entities-schema';
import React, { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { findOneUser, patchUser } from '../../graphql/queries/userQueries';
import useMutation from '../hooks/useMutation';

export enum TableIdEnum {
  Equipment = 'equipmentTable',
  Location = 'locationsTable',
  ServiceRequest = 'serviceRequestTable',
  Claim = 'claimTable',
  AasInvoice = 'aasInvoiceTable',
}

export interface NavDataType {
  favoriteSavedView: View | null;
  isFavoriteSavedViewMutationSaving: boolean;
  updateFavoriteSavedView: (args: UsersMutationPatchArgs) => void;
  setFavoriteSavedView: (view: View) => void;
  redirectToSavedView: (view?: View) => void;
  quickViews: boolean;
  isQuickViewsMutationSaving: boolean;
  updateQuickViews: (args: UsersMutationPatchArgs) => void;
  isFetchingUserData: boolean;
  isLoadingUserData: boolean;
  refetchUserData: () => void;
  userId?: string;
}

export enum HistoryStateInitiator {
  QuickSavedView = 'quickSavedView',
  CoverageDashboard = 'coverageDashboard',
}

export type HistoryStateType = { view: View; initiator: HistoryStateInitiator };

export const NavDataContext = React.createContext({} as NavDataType);

export const useNavData = () => useContext(NavDataContext);

export const redirectByTableId = {
  [TableIdEnum.Claim]: '/aas/claims',
  [TableIdEnum.AasInvoice]: '/u/invoices', // invoice migration
  [TableIdEnum.ServiceRequest]: '/service-requests',
  [TableIdEnum.Equipment]: '/equipment',
};

export default function NavDataProvider({ children, userId }) {
  const history = useHistory();

  const [favoriteSavedView, setFavoriteSavedView] = useState<View | null>(null);
  const [quickViews, setQuickViews] = useState(false);

  const redirectToSavedView = (selectedFavoriteSavedView: View) => {
    if (!selectedFavoriteSavedView) return;

    let state = {} as HistoryStateType;

    const tableId = selectedFavoriteSavedView?.metadata?.tableId;
    const defaultPathname = redirectByTableId[TableIdEnum.ServiceRequest];
    const pathname = (tableId && redirectByTableId[tableId]) || defaultPathname;

    state.view = selectedFavoriteSavedView;
    state.initiator = HistoryStateInitiator.QuickSavedView;

    history.push({ pathname, state });
  };

  const {
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
    refetch: refetchUserData,
  } = useQuery(
    ['getUserFavoriteSavedViewAndQuickViewsData'],
    async () =>
      await findOneUser(
        { userId },
        {
          query: `
        metadata {
          quickViews
          favoriteSavedView {
            viewId
            metadata {
              description
              name
              scope
              tableId
              tableState
              search
              count
              visibleColumns
            }
          }
        }
      `,
        },
      ),
    {
      enabled: !!userId,
      select: (data) => data?.entity,
      onSuccess: (data) => {
        const currentFavoriteSavedView = data?.metadata?.favoriteSavedView;
        const currentQuickViewsSetting = data?.metadata?.quickViews;

        currentFavoriteSavedView && setFavoriteSavedView(currentFavoriteSavedView);
        typeof currentQuickViewsSetting === 'boolean' && setQuickViews(currentQuickViewsSetting);
      },
    },
  );

  const [updateFavoriteSavedView, { saving: isFavoriteSavedViewMutationSaving }] = useMutation(patchUser, {
    graphQLQuery: `
      metadata { 
        favoriteSavedViewId
        favoriteSavedView {
          viewId
          metadata {
            description
            name
            scope
            tableId
            tableState
            search
            count
            visibleColumns
          }
        }
      }`,
    onCompleted: (data) => {
      const updatedFavoriteSavedView = data?.patch?.metadata?.favoriteSavedView;
      setFavoriteSavedView(updatedFavoriteSavedView || null);
    },
    successMessage: 'Successfully updated favorite saved view.',
  });

  const [updateQuickViews, { saving: isQuickViewsMutationSaving }] = useMutation(patchUser, {
    graphQLQuery: `metadata { quickViews }`,
    onCompleted: (data) => {
      const quickViewSetting = data?.patch?.metadata?.quickViews;
      typeof quickViewSetting === 'boolean' && quickViewSetting !== null && setQuickViews(quickViewSetting);
    },
    successMessage: `Successfully ${quickViews ? 'disabled' : 'enabled'} quick views.`,
  });

  return (
    <NavDataContext.Provider
      value={{
        favoriteSavedView,
        isFavoriteSavedViewMutationSaving,
        updateFavoriteSavedView,
        setFavoriteSavedView,
        redirectToSavedView,
        quickViews,
        isQuickViewsMutationSaving,
        updateQuickViews,
        userId,
        isFetchingUserData,
        isLoadingUserData,
        refetchUserData,
      }}
    >
      {children}
    </NavDataContext.Provider>
  );
}
