import LogRocket from 'logrocket';

export const safelyParseJson = (probablyJson) => {
  if (!probablyJson) return null;

  try {
    return JSON.parse(probablyJson);
  } catch (error) {
    LogRocket.captureException(error);
  }

  return null;
};
