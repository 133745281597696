import { useEffect } from 'react';
import { queryUsers } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../auth/AuthContext';
import config from '../../config';
import clearCachedServiceWorker from '../functions/clearCachedServiceWorker';

const oneDayInMS = 86400000;

export default function CachedAppDetector() {
  const { auth } = useAuth();
  const userId = auth?.userId;

  const { data, isFetching } = useQuery(
    ['GetCloudfrontCacheTimestamp', userId],
    () => queryUsers({ userId }, { query: 'cloudfrontMostRecentCacheInvalidationTimeStampInMS' }),
    {
      enabled: !!userId,
      select: (data) => data?.data,
    },
  );

  const builtAtTimestamp = config?.builtAtTimestamp;
  const cloudfrontMostRecentCacheInvalidationTimeStampInMS =
    data?.users?.data?.[0]?.cloudfrontMostRecentCacheInvalidationTimeStampInMS || 0;
  const canAnalysisBuildTime = !!(!isFetching && data?.users?.data?.[0] && cloudfrontMostRecentCacheInvalidationTimeStampInMS);

  useEffect(() => {
    if (!canAnalysisBuildTime) {
      return;
    }

    if (builtAtTimestamp + oneDayInMS < cloudfrontMostRecentCacheInvalidationTimeStampInMS) {
      console.log('Clearing service worker');

      clearCachedServiceWorker();
    }
  }, [builtAtTimestamp, canAnalysisBuildTime, cloudfrontMostRecentCacheInvalidationTimeStampInMS]);

  return null;
}
