import React from 'react';
import Dialog from '@mui/material/Dialog';
import { ServiceTypeEnum, queryLocations, ServiceRequest } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import SubmitRequest from '../../../common/components/ServiceRequest/SubmitRequest';
import { getEquipment } from '../../../common/components/ServiceRequest/SubmitRequestQueries';
import Spinner from '../../../common/components/Loaders/Spinner';

type DispatchEquipmentModalProps = {
  serviceType: ServiceTypeEnum;
  onClose?: () => unknown;
  onSuccess?: (requests?: ServiceRequest[]) => unknown;
  organizationId?: string;
  locationId?: string;
  equipmentId?: string;
  incidentId?: string;
  scheduledDate?: number;
  dueDate?: number;

  /** Request is made from PM schedule page */
  pmView?: boolean;
};

export default function DispatchEquipmentModal(props: DispatchEquipmentModalProps) {
  const { data: equipment, isFetching: isLoadingEquipment } = useQuery(
    ['DispatchEquipmentModalEquipment', props.equipmentId],
    () => {
      return getEquipment(props.equipmentId ?? '');
    },
    { enabled: !!props.equipmentId, select: (data) => data.data?.equipment?.data?.[0] },
  );

  const { data: location, isFetching: isLoadingLocation } = useQuery(
    ['DispatchEquipmentModalLocation', props.locationId],
    () => {
      return queryLocations({ locationId: props.locationId });
    },
    { enabled: !!props.locationId, select: (data) => data.data?.locations?.data?.[0] },
  );

  return (
    <Dialog onClose={props.onClose} open fullWidth maxWidth="md">
      {isLoadingEquipment || isLoadingLocation ? (
        <Spinner />
      ) : (
        <SubmitRequest
          serviceType={props.serviceType}
          organizationId={props.organizationId}
          equipment={[equipment || {}] ?? undefined}
          location={location ?? undefined}
          scheduledDate={props?.scheduledDate}
          dueDate={props?.dueDate}
          incidentId={props.incidentId}
          onSuccess={props.onSuccess}
          onClose={props.onClose}
          pmView={props?.pmView}
        />
      )}
    </Dialog>
  );
}
