import React from 'react';
import CheckboxMUI from '@mui/material/Checkbox';
import Label from './Label';

interface CheckboxProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
  onChange: (value) => any;
  helper?: any;
  id: string;
  checked?: boolean;
  required?: boolean;
  label?: string | React.ReactNode;
  labelClassName?: string;
  checkboxesAriaLabel?: string;
  inputTestId?: string;
  name?: string;
  disabled?: boolean;
}

export default function Checkbox({
  disabled = false,
  size = 'medium',
  helper = null,
  className = '',
  label = '',
  labelClassName = '',
  required = false,
  checkboxesAriaLabel = '',
  checked,
  onChange,
  id,
  inputTestId = '',
  name = '',
}: CheckboxProps) {
  return (
    <div className={`flex items-center ${className}`}>
      <CheckboxMUI
        disabled={disabled}
        style={{ width: 'auto', padding: size === 'small' ? '0' : '' }}
        className={className}
        size={size}
        checked={checked}
        aria-label={checkboxesAriaLabel}
        required={required}
        id={id}
        onChange={onChange}
        color="secondary"
        inputProps={{ 'data-testid': inputTestId || id }}
        name={name}
      />
      {label && <Label size={size} className={`ml-1 inline-block ${labelClassName}`} id={id} label={label} helper={helper} />}
    </div>
  );
}
