import { ServiceTypeEnum } from '@equips/entities-schema';
import { chargeAdjudicationResultTypes } from '../../../graphql/enums';

const { rejected, approved, denied } = chargeAdjudicationResultTypes;

const approvalReasons: ReasonsObject = {
  commonReasons: ['First line coverage', 'Second line coverage', 'Ex Gratis'],
  [ServiceTypeEnum.Consumables]: ['Consumable coverage'],
  [ServiceTypeEnum.Preventive]: ['Preventative services covered'],
  [ServiceTypeEnum.Corrective]: ['Corrective services covered'],
  [ServiceTypeEnum.Other]: [],
};
const denialReasons: ReasonsObject = {
  commonReasons: [
    'NA',
    'Consumables Excluded',
    'Date of Loss outside Coverage Effective Date',
    'Deductible Applied',
    'Excluded Cause of Loss',
    'Invalid Service Provider',
    'Item Not on Agreement',
    'Location Not on Agreement',
    'Property Insurance Applies',
    'Parts Replacement Excluded',
    'Software Excluded',
    'Upgrade Excluded',
    'Service Provider Cancelled Charges',
    'Maintenance Contract Excluded',
    'Programming Excluded',
    'First Line Maintenance Excluded',
    'Keys/Key Cutting Excluded',
    'Network Excluded',
    'Alarm Monitoring Excluded',
    'Combination Changes/Resets Excluded',
    'New Purchase/Installation Excluded',
    'Forcing Open/Drilling Excluded',
    'PM Kit(s) Excluded',
    'Sunviewable Monitor Excluded',
    'Tax Exempt',
    'Second Line Maintenance Excluded',
    'Phone Lines Excluded',
    'Color Monitor Excluded',
    'Codes/Users Excluded',
    'Duplicate Invoice',
    'Cash Handling Services Excluded',
    'Moves Excluded',
    'Applied Service Provider Credit',
    'No Problem Found Excluded',
    'Item Not Coverable',
    'Reported After 90 Days',
    'Weather/Water Damage Excluded',
    'CPM/BNA Excluded',
    'MAS Lock(s) Excluded',
    'Service Dispatch Only Item',
    'Pre-Existing Condition Excluded',
    'SDM/IDM Excluded',
    'Limit of Liability Reached',
    'Mileage/Shipping Excluded',
    'OEM Consumables Excluded',
    'Admin Only',
    'Non-Mechanical Loss Excluded',
    'Maintenance Contract Excluded DO NOT PAY',
    'Prepaid by Credit Card',
    'Deductible Item Covered',
    'Overtime Rates Excluded',
    'Deactivated/Not on Agreement',
  ],
  [ServiceTypeEnum.Consumables]: ['Consumables Not Supplied by Equips'],
  [ServiceTypeEnum.Preventive]: ['Exceeds Agreement PM Hours'],
  [ServiceTypeEnum.Corrective]: [],
  [ServiceTypeEnum.Other]: [],
};

const rejectionReasons: ReasonsObject = {
  commonReasons: [
    'Under M/C or Warranty',
    'Voided by Equips',
    'Partial Payment',
    'Paid with Credit card',
    'Service Provider Cancelled Charges',
  ],
  [ServiceTypeEnum.Consumables]: [],
  [ServiceTypeEnum.Preventive]: [],
  [ServiceTypeEnum.Corrective]: [],
  [ServiceTypeEnum.Other]: [],
};

type ReasonsObject = {
  [key in ServiceTypeEnum]: string[];
} & { commonReasons: string[] };

export function getAdjudicationReasonOptions(
  adjudicationResult: undefined | null | typeof rejected.value | typeof approved.value | typeof denied.value,
  serviceType = ServiceTypeEnum.Corrective,
): string[] {
  if (adjudicationResult === approved.value) {
    return approvalReasons[serviceType].concat(approvalReasons.commonReasons);
  } else if (adjudicationResult === denied.value) {
    return denialReasons[serviceType].concat(denialReasons.commonReasons);
  } else if (adjudicationResult === rejected.value) {
    return rejectionReasons[serviceType].concat(rejectionReasons.commonReasons);
  }

  return [];
}

export function getAdjudicationReasonsForSelect() {
  const allReasons = [
    ...approvalReasons.commonReasons,
    ...approvalReasons[ServiceTypeEnum.Consumables],
    ...approvalReasons[ServiceTypeEnum.Preventive],
    ...approvalReasons[ServiceTypeEnum.Corrective],
    ...approvalReasons[ServiceTypeEnum.Other],
    ...denialReasons.commonReasons,
    ...denialReasons[ServiceTypeEnum.Consumables],
    ...denialReasons[ServiceTypeEnum.Preventive],
    ...denialReasons[ServiceTypeEnum.Corrective],
    ...denialReasons[ServiceTypeEnum.Other],
    ...rejectionReasons.commonReasons,
    ...rejectionReasons[ServiceTypeEnum.Consumables],
    ...rejectionReasons[ServiceTypeEnum.Preventive],
    ...rejectionReasons[ServiceTypeEnum.Corrective],
    ...rejectionReasons[ServiceTypeEnum.Other],
  ];

  return [...new Set(allReasons)].sort().map((reason) => {
    return { label: reason, value: reason };
  });
}
