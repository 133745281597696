import React, { useState, useCallback } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import SimpleModal, { simpleModalTypes } from '../Modal/SimpleModal';
import Button from '../Buttons/Button';
import Input from '../Form/Input';
import Label from '../Form/Label';

enum PromptType {
  Prompt = 'prompt',
  Confirm = 'confirm',
}
type AlertProps = {
  onSubmit: (value: boolean | string) => void;
  title?: string;
  subtitle?: string;
  promptType: PromptType;
  label?: string;
  submitButtonText?: string;
};

const parentIdMount = 'promptRoot';
let parent = document.getElementById(parentIdMount) as HTMLElement;

if (!parent) {
  parent = document.createElement('div');
  parent.id = parentIdMount;
}

function CustomAlert({ onSubmit, title, subtitle, promptType, label, submitButtonText }: AlertProps) {
  const [promptMessage, setPromptMessage] = useState('');

  const close = useCallback(() => {
    unmountComponentAtNode(parent);
  }, []);

  const handleSubmit = useCallback(
    (accepted: boolean) => {
      if (promptType === PromptType.Prompt) {
        if (accepted) {
          onSubmit(promptMessage);
        } else {
          onSubmit(false);
        }
      } else {
        onSubmit(accepted);
      }

      return close();
    },
    [promptType, promptMessage, onSubmit, close],
  );

  return (
    <SimpleModal handleClose={() => handleSubmit(false)} modalType={simpleModalTypes.info} title={title} subtitle={subtitle}>
      <form
        data-testid="promptForm"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(true);
        }}
      >
        <div className="">
          {promptType === PromptType.Prompt && (
            <div className="py-4">
              {label && <Label id="" label={label} />}
              <Input
                data-testid="promptInput"
                id="promptInput"
                placeholder={label || ''}
                value={promptMessage}
                onChange={(event) => setPromptMessage(event.target.value)}
                type="text"
              />
            </div>
          )}
          <div className="flex justify-end py-4">
            <Button type="button" data-testid="promptCancel" gray onClick={() => handleSubmit(false)}>
              Cancel
            </Button>
            <div className="w-4" />
            <Button type="submit" blue>
              {submitButtonText || 'OK'}
            </Button>
          </div>
        </div>
      </form>
    </SimpleModal>
  );
}

type Options = { subtitle?: string; label?: string; submitButtonText?: string };

async function alertHelper(promptType: PromptType, title: string, options: undefined | Options) {
  return new Promise<string | boolean>((resolve) => {
    render(<CustomAlert promptType={promptType} onSubmit={(e) => resolve(e)} title={title} {...options} />, parent);
  });
}

export async function confirm(title: string, options?: Options) {
  return await alertHelper(PromptType.Confirm, title, options);
}

export async function prompt(title: string, options?: Options) {
  return await alertHelper(PromptType.Prompt, title, options);
}
