import React, { Dispatch, SetStateAction, useState } from 'react';
import Add from '@mui/icons-material/Add';
import Button from '../Buttons/Button';

type RepeaterFieldItem = { [x: string]: any };
type RepeaterFieldProps = {
  createDefaultItem: (args: { [x: string]: string }) => any[];
  singularName: string;
  pluralName: string;
  setItems: Dispatch<SetStateAction<RepeaterFieldItem[]>>;
  entityIdName: string;
  onAddCharge?: (...args: any[]) => any;
};

export default function useRepeaterField(
  items: RepeaterFieldItem[],
  { createDefaultItem, singularName = 'item', pluralName = 'items', setItems, entityIdName, onAddCharge = (o) => o }: RepeaterFieldProps,
) {
  const [activeItem, setActiveItem] = useState(items.length - 1);
  const [removedItems, setRemovedItems] = useState<any>([]);

  const changeField = ({ value, entityId, field, ...restOfData }) => {
    setItems((state) =>
      state.map((currentItem) => {
        if (currentItem[entityIdName] === entityId) {
          return { ...currentItem, [field]: value, ...restOfData };
        }

        return { ...currentItem };
      }),
    );
  };

  const removeById = (entityId, item) => {
    setRemovedItems((state) => [...state, item]);
    setItems((state) => state.filter((currentItem) => currentItem[entityIdName] !== entityId));
  };

  const clearItemFromRemoveList = (entityId) => {
    setRemovedItems((state) => state.filter((currentItem) => currentItem[entityIdName] !== entityId));
  };

  const RemoveButton = ({ item, ...restOfProps }: { item: object }) => {
    return (
      <button
        {...restOfProps}
        className="text-xs text-gray-500 hover:underline"
        type="button"
        onClick={(event) => {
          event.preventDefault();
          removeById(item[entityIdName], item);
        }}
      >
        Remove
      </button>
    );
  };

  const addButtonAction = (createDefaultItemArgs) =>
    setItems((state) => {
      const newItems = [...state, ...createDefaultItem(createDefaultItemArgs)];
      setActiveItem(newItems.length - 1);
      onAddCharge(newItems);

      return newItems;
    });

  const AddButton = ({ createDefaultItemArgs, ...restOfProps }: { createDefaultItemArgs: object }) => {
    return (
      <div className="m-auto my-2 text-center">
        {items.length === 0 ? <h3 className="mb-1 text-sm">There are currently no {pluralName}</h3> : null}
        <Button
          {...restOfProps}
          tiny
          gray
          type="button"
          className="m-auto"
          onClick={(event) => {
            event.preventDefault();
            addButtonAction(createDefaultItemArgs);
          }}
        >
          {items.length === 0 ? `Add your first ${singularName}` : <Add fontSize="small" />}
        </Button>
      </div>
    );
  };

  return { items, activeItem, removedItems, changeField, removeById, clearItemFromRemoveList, RemoveButton, AddButton, addButtonAction };
}
