import { postUser, queryUsers, patchUser as standardPatchUser } from '@equips/entities-schema';
import { deactivateMutation, reactivateMutation, deleteMutation } from '../commonMutations';
import { gql } from '../graphqlHelperFunctions';
import { postToUsersEndpoint } from '../postToEndpoint';
import { cleanObject } from '../../common/functions/cleanObject';

export const deactivateUser = async ({ entityId }) => postToUsersEndpoint({ query: deactivateMutation, variables: { entityId } });

export const reactivateUser = async ({ entityId }) => postToUsersEndpoint({ query: reactivateMutation, variables: { entityId } });

export const deleteUser = async ({ entityId }) => postToUsersEndpoint({ query: deleteMutation, variables: { entityId } });

export const bulkReactivateUsers = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { userId } = entity.metadata;

      return await reactivateUser({ entityId: userId });
    }),
  );
};

export const bulkDeactivateUsers = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { userId } = entity.metadata;

      return await deactivateUser({ entityId: userId });
    }),
  );
};

export const bulkDeleteUsers = async (arrayOfEntityObject) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { userId } = entity.metadata;

      await deleteUser({ entityId: userId });
    }),
  );
};

export const inviteUser = async ({ fullName, email, authorizationRole, phoneNumberWithCountryCode, organizationId, locationId }) => {
  return await postToUsersEndpoint({
    query: gql`
      mutation postNewUser($metadata: InviteUserMetadataPostInput!) {
        inviteUser(metadata: $metadata) {
          metadata {
            fullName
          }
        }
      }
    `,
    variables: {
      metadata: {
        fullName,
        email,
        phoneNumberWithCountryCode,
        authorizationRole,
        organizationId,
        locationId,
        locationIds: [locationId],
      },
    },
  });
};

export const removeUnconfirmedUserFromCognito = async ({ userId }) =>
  await postToUsersEndpoint({
    query: gql`
      mutation ($userId: String!) {
        removeUnregisteredAccountFromCognito(userId: $userId)
      }
    `,
    variables: { userId },
  });

export const getTemporaryPassword = async ({ userId }) =>
  await postToUsersEndpoint({
    query: gql`
      mutation ($userId: String!) {
        getTemporaryPassword(userId: $userId)
      }
    `,
    variables: { userId },
  });

export const postUserForOnboarding = async (userId, { fullName, phoneNumberWithCountryCode, phoneNumberExtension }) => {
  const { data } = await queryUsers({ userId });
  const metadata = cleanObject({ fullName, phoneNumberWithCountryCode, phoneNumberExtension });

  if (data?.users?.data?.[0]?.metadata) {
    return await standardPatchUser({ userId, metadata });
  } else {
    return await postUser({ metadata });
  }
};

export const patchUser = async ({ userId, patchFields }) =>
  await postToUsersEndpoint({
    query: gql`
      mutation ($userId: String!, $metadata: UserMetadataPatchInput!) {
        patch(userId: $userId, metadata: $metadata) {
          metadata {
            userId
          }
        }
      }
    `,
    variables: {
      userId,
      metadata: {
        ...patchFields,
      },
    },
  });

export const bulkPatchUsers = async ({ patchField, patchValue, arrayOfEntityObject }) => {
  return Promise.all(
    arrayOfEntityObject.map(async (entity) => {
      const { userId } = entity.metadata;
      await patchUser({ userId, patchFields: { [patchField]: patchValue } });
    }),
  );
};
