import React from 'react';
import { useAuth } from '../../common/auth/AuthContext';
import LocationsForm from '../locations/LocationForm';
import SelectDefaultLocation from '../settings/user/SelectDefaultLocation';
import MainLoader from '../../common/components/Loaders/Main';
import { supportEmailAddress } from '../../common/constants/contact';
import waitSynchronously from '../../common/functions/waitSynchronously';
import { useLocations } from '../../common/hooks/useLocations';

type OnboardingLocationsProps = {
  handleSubmit: () => any;
};

function OnboardingLocations({ handleSubmit }: OnboardingLocationsProps) {
  const { auth, userCan, roles } = useAuth();
  const { locations, loading } = useLocations(auth?.organizationId as string, { includeLocationsNotOnUser: true });

  if (loading) return <MainLoader />;

  if (locations.length > 0) {
    return <SelectDefaultLocation userId={auth?.userId || ''} organizationId={auth?.organizationId || ''} handleSuccess={handleSubmit} />;
  }

  if (userCan([roles.superGlobalAdmin, roles.globalAdmin, roles.customerOrganizationAdmin])) {
    return (
      <LocationsForm
        onSuccess={async () => {
          // waiting for elastic
          await waitSynchronously(2000);
          window.location.reload();
        }}
      />
    );
  }

  return (
    <p data-testid="onboardingLocations-noAvailableLocations">
      Please ask your administrator to create a location, or contact {supportEmailAddress}.
    </p>
  );
}

export default OnboardingLocations;
