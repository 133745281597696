import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { Attachment, AttachmentMetadata, Maybe } from '@equips/entities-schema';

type FileData = {
  fileName: string;
  extension: string;
  contentType: string;
};

export const downloadAUrl = async (fileUrl: string, fileInfo: FileData) => {
  const response = await fetch(fileUrl, { method: 'GET' });
  const data = await response.blob();

  saveAs(data, fileInfo?.fileName);
};

export const downloadABlob = (fileContent: Blob | Uint8Array, fileInfo: FileData): void => {
  const blob = new Blob([fileContent], { type: fileInfo.contentType });
  const fileName = fileInfo?.fileName + (fileInfo?.extension ?? '');

  saveAs(blob, fileName);
};

export const downloadAttachment = async (attachment: AttachmentMetadata) => {
  const { presignedGetUrl, name } = attachment ?? {};

  if (presignedGetUrl) {
    try {
      const res = await fetch(presignedGetUrl, { method: 'GET', cache: 'no-cache' });
      const blob = await res.blob();
      saveAs(blob, name);
    } catch (e) {
      console.error(`Error downloading ${name}`, e);
    }
  } else {
    throw new Error('Invalid url');
  }
};

export const downloadAllAttachments = (attachments: Maybe<Attachment>[]) => {
  try {
    attachments.map(async (item) => {
      if (item?.metadata) {
        await downloadAttachment(item?.metadata);
      }
    });
  } catch (e) {
    console.error('Error downloading all attachment. Please try again or download attachments individually.', e);
  }
};

export const downloadAllAttachmentsAsZip = async (folderName: string, attachments: Maybe<Attachment>[]) => {
  const zip = new JSZip();
  const zipFolder = zip.folder(folderName);

  // get all attachments and put it in zip folder
  const promises = attachments?.map(async (item, i) => {
    const src = item?.metadata?.presignedGetUrl;
    if (src) {
      const name = item?.metadata?.name ?? `attachment_${i}`;
      const res = await fetch(src, { method: 'GET', cache: 'no-cache' });

      zipFolder?.file(name, res.blob());
    }
  });

  if (promises) {
    await Promise.all(promises);
  }

  zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, `${folderName}.zip`));
};
