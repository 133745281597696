import Warning from '@mui/icons-material/Warning';
import React from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import Button from '../Buttons/Button';

export default function UnsupportedBrowser() {
  // @ts-ignore Yep this only exists on IE
  const isIE = Boolean(window.document.documentMode);
  const [dismissed, setDismissed] = useLocalStorage('dismissedBrowserSupportWarning');

  if (dismissed || !isIE) return null;

  return (
    <div className="flex justify-center bg-gray-200 py-2 text-sm text-gray-800 shadow">
      <div className="flex w-11/12 items-center justify-between">
        <div>
          <p>
            <Warning className="mr-2 inline-block" />
            <span className="font-semibold">Your browser is unsupported. </span>
            <span>Some features may not work properly in Internet Explorer.</span>
          </p>
          {!dismissed && (
            <p className="ml-8">
              To use Equips, you'll need to upgrade to a newer, more secure browser like{' '}
              <a className="text-blue-900 underline" href="https://www.mozilla.org/en-US/firefox/new/">
                Mozilla Firefox
              </a>
              ,{' '}
              <a className="text-blue-900 underline" href="https://www.google.com/chrome/">
                Google Chrome
              </a>
              , or{' '}
              <a className="text-blue-900 underline" href="https://www.microsoft.com/en-us/edge">
                Microsoft Edge
              </a>
              .
            </p>
          )}
        </div>
        <Button blue tiny={dismissed} onClick={() => setDismissed(!dismissed)}>
          {dismissed ? 'Read More' : 'Dismiss'}
        </Button>
      </div>
    </div>
  );
}
