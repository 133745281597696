import React, { useContext, useState } from 'react';

export interface AppStatusContextType {
  apiFailure: boolean;
  setApiFailure: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppStatusContext = React.createContext({} as AppStatusContextType);

export const useAppStatus = () => useContext(AppStatusContext);

export default function AppStatusProvider({ children }) {
  const [apiFailure, setApiFailure] = useState(false);

  return (
    <AppStatusContext.Provider
      value={{
        apiFailure,
        setApiFailure,
      }}
    >
      {children}
    </AppStatusContext.Provider>
  );
}
