import axios from 'axios';
import { Attachment, AttachmentMetadata, deleteAttachment, postAttachment } from '@equips/entities-schema';
import pRetry from 'p-retry';
import config from '../../config';
import { putFileToS3, checkS3FileExistence } from './s3HelperFunctions';

type AttachmentMetadataPost = Required<Pick<AttachmentMetadata, 'type' | 'parentId' | 'parentLabel' | 'organizationId'>>;

const unauthenticatedPath = `${config.apiGateway.endpoint}/unauth/attachments`;

export async function createAttachment(file: File, metadata: AttachmentMetadataPost, auth = true): Promise<AttachmentMetadata> {
  const { data } = auth
    ? await postAttachment(
        { metadata: { ...metadata, contentType: file.type, name: file.name } },
        {
          query: `
            metadata {
              attachmentId
              name
              contentType
              presignedGetUrl
              presignedPutUrl
            }
          `,
        },
      )
    : await axios.post<{ post: Attachment }>(
        unauthenticatedPath,
        {
          ...metadata,
          contentType: file.type,
          name: file.name,
        },
        { headers: { 'Content-Type': 'application/json' } },
      );

  const attachmentMetadata = data?.post?.metadata as AttachmentMetadata;

  const { presignedGetUrl, presignedPutUrl } = attachmentMetadata;
  if (!attachmentMetadata || !presignedPutUrl || !presignedGetUrl) throw new Error('File creation failed');

  try {
    await pRetry(async () => putFileToS3(presignedPutUrl, file), {
      onFailedAttempt: (error) => {
        console.log(`Attempt ${error.attemptNumber} to upload file failed. There are ${error.retriesLeft} retries left.`);
      },
      retries: 3,
    });
  } catch (error) {
    await deleteAttachment({ entityId: attachmentMetadata?.attachmentId });
    console.error(error);
    throw new Error('Upload to s3 failed. Attachment entity deleted');
  }

  // Wait for attachment to be available in S3
  await checkS3FileExistence(presignedGetUrl);

  return attachmentMetadata;
}
