import { AasFailGroupEnumType } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import FormGroup from '../../../common/components/Form/FormGroup';
import { defaultSelectStyles } from '../../../common/components/Selects/defaultSelectStyles';
import { OrganizationSelectOption } from '../../../common/components/Selects/OrganizationsSelect';
import { useDebouncePrimitive } from '../../../common/hooks/useDebounce';

const MINUTE = 1000 * 60;
const EXPIRE_TIME = 15 * MINUTE;

function useAasProvidersByDistance({
  inputValue = '',
  locationId,
  selectedFailGroups,
}: {
  inputValue: string;
  locationId?: string | null | undefined;
  selectedFailGroups?: AasFailGroupEnumType[];
}) {
  // avoid passing object as reference in useCallback dependency
  const selectedFailGroupsDependency = selectedFailGroups ? selectedFailGroups.join() : '';

  const select = React.useCallback(
    (data) =>
      data?.data?.map((provider) => {
        const address = provider?.billingAddress ?? {
          line1: provider?.line1,
          zip: provider?.zip,
          city: provider?.city,
          stateUnabbreviated: provider?.stateUnabbreviated,
        };

        const typeOfWork = provider?.typeOfWork || [];

        return {
          value: provider?.organizationId,
          label: provider?.organizationName,
          address,
          provider: {
            isExternalProvider: true,
            distance: provider?.distance_in_miles,
            dispatchEmail: provider?.dispatchEmail,
            dispatchPhone: provider?.dispatchPhone,
            dispatchWebsite: provider?.dispatchWebsite,
            providerSource: provider?.providerSource,
            completedServiceRequestCount: provider?.completedServiceRequestCount,
            noteCount: provider?.noteCount,
            tradeMatch: typeOfWork.some((t) => Boolean(selectedFailGroupsDependency?.split(',').includes(t))),
          },
        };
      }),
    [selectedFailGroupsDependency],
  );

  const queryKey = inputValue ? inputValue.toLowerCase().trim() : locationId;

  return useQuery(
    [`AmericanAutoShieldProviders`, queryKey],
    () => {
      return API.get('equips-app', `/aas-providers`, {
        queryStringParameters: {
          locationId,
          ...(inputValue ? { search: inputValue.toLowerCase().trim() } : {}),
        },
        response: true,
      });
    },
    {
      staleTime: EXPIRE_TIME,
      cacheTime: EXPIRE_TIME,
      enabled: !!locationId,
      select,
    },
  );
}

type AasProvidersByDistanceSelectProps = {
  locationId?: string | null;
  onChange?: (value) => any;
  fullWidth?: boolean;
  testId?: string;
  message?: string;
  className?: string;
  selectedFailGroups?: AasFailGroupEnumType[];
  defaultStyles?: boolean;
};

const AasProvidersByDistanceSelect = ({
  locationId,
  onChange,
  fullWidth = false,
  testId = 'test',
  message,
  className = '',
  selectedFailGroups,
  defaultStyles = true,
}: AasProvidersByDistanceSelectProps) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebouncePrimitive(inputValue, 500);

  const { data: defaultOptions, isFetching } = useAasProvidersByDistance({
    inputValue: debouncedValue,
    locationId,
    selectedFailGroups,
  });

  return (
    <FormGroup fullWidth={fullWidth} data-testid={testId}>
      <ReactSelect
        className={className}
        key={locationId}
        components={{
          Option: OrganizationSelectOption,
          SingleValue: OrganizationSelectOption,
        }}
        styles={defaultStyles ? { ...defaultSelectStyles } : {}}
        isMulti={false}
        onChange={onChange}
        onInputChange={setInputValue}
        placeholder="Select a provider"
        isClearable
        noOptionsMessage={() => message}
        options={defaultOptions}
        menuPortalTarget={document.body}
        isLoading={!defaultOptions && isFetching}
        loadingMessage={() => 'Loading providers ...'}
      />
    </FormGroup>
  );
};

export default AasProvidersByDistanceSelect;
