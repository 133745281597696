import React, { useContext, useState } from 'react';
import ReportOutlined from '@mui/icons-material/ReportOutlined';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Info from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { queryIncidents } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Urls from '../../../routes/Urls';
import { getUnixMillisecondTimestampXDaysAgo } from '../../../common/functions/dateFunctions';
import SimpleModal, { simpleModalTypes } from '../../../common/components/Modal/SimpleModal';
import { ModalContext } from '../../../common/providers/ModalProvider';
import Button from '../../../common/components/Buttons/Button';

interface DuplicateRequestModalType {
  equipmentId: string | undefined;
  setParentState?: (...args: any[]) => any;
}

export enum DuplicateRequestModalStates {
  Unopened,
  Open,
  Closed,
}

export const DuplicateRequestModalContent = ({ serviceRequest, onClickContinue }) => {
  const { t } = useTranslation();
  const { handleClose } = useContext(ModalContext);

  return (
    <div className="p-4" data-testid="duplicateRequestModalContent">
      <header className="sm:flex sm:items-center">
        <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-blue-100 text-blue-600 sm:mx-0 sm:h-10 sm:w-10">
          <Info />
        </div>
        <div className="mt-3 text-center sm:ml-3 sm:mt-0 sm:text-left">
          <h3 className="text-xl font-medium leading-6 text-gray-900">{t('possibleDuplicateServiceRequest')}</h3>
        </div>
      </header>
      <p className="mt-2">{t('duplicateRequestDescription')}</p>
      <div className="p-4">
        <Link to={`${Urls.SERVICE_REQUESTS}/${serviceRequest?.metadata?.serviceRequestId}`}>
          <p className="pb-2 font-semibold text-gray-800 underline">{serviceRequest?.metadata?.serviceRequestDisplayName}</p>
        </Link>
        <div className="pb-3">
          <Link to={`${Urls.SERVICE_REQUESTS}/${serviceRequest?.metadata?.serviceRequestId}#addnote`} onClick={handleClose}>
            <NoteAdd /> {t('createWithNoun', { noun: t('note') })}
          </Link>
        </div>
        <div>
          <Link to={`${Urls.SERVICE_REQUESTS}/${serviceRequest?.metadata?.serviceRequestId}#escalate`} onClick={handleClose}>
            <ReportOutlined /> {t('escalate')}
          </Link>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <p>Or continue creating a new service request for this equipment?</p>
        <Button data-testid="continueCreatingServiceRequestButton" type="button" onClick={onClickContinue}>
          {t('continue')}
        </Button>
      </div>
    </div>
  );
};

export default function DuplicateRequestModal({ equipmentId, setParentState }: DuplicateRequestModalType) {
  const { t } = useTranslation();
  const [state, setState] = useState(DuplicateRequestModalStates.Unopened);

  const { data } = useQuery(
    ['IncidentByEquipmentId', equipmentId],
    () =>
      queryIncidents(
        {
          search: {
            size: 1,
            filters: [
              {
                metadata: {
                  createdAt: [{ range: { gt: getUnixMillisecondTimestampXDaysAgo(1) } }],
                  equipmentId: [{ keyword: [{ term: equipmentId }] }],
                },
              },
            ],
          },
        },
        {
          query: `
      metadata {
        serviceRequest {
          metadata {
            serviceRequestId
            serviceRequestDisplayName
          }
        }
      }
      `,
        },
      ),
    {
      enabled: !!equipmentId,
      select: (data) => data?.data,
    },
  );

  const serviceRequest = data?.incidents?.data?.[0]?.metadata?.serviceRequest;

  if (serviceRequest && state === DuplicateRequestModalStates.Unopened) {
    setState(DuplicateRequestModalStates.Open);
    setParentState && setParentState();
  }

  return (
    <SimpleModal
      modalType={simpleModalTypes.info}
      title={t('possibleDuplicateServiceRequest')}
      isOpen={state === DuplicateRequestModalStates.Open}
      handleClose={() => setState(DuplicateRequestModalStates.Closed)}
    >
      <DuplicateRequestModalContent serviceRequest={serviceRequest} onClickContinue={() => setState(DuplicateRequestModalStates.Closed)} />
    </SimpleModal>
  );
}
