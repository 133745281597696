import React, { useState } from 'react';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicator from '@mui/icons-material/DragIndicator';

type SortableListProps<T> = {
  values: T[];
  formatter?: (item: T, index: number) => React.ReactElement;
  onChange: (values: T[]) => any;
};

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.index + 1 });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="flex" ref={setNodeRef} style={style}>
      <div className="items-center p-2">
        <button type="button" {...listeners} {...attributes}>
          <DragIndicator />
        </button>
      </div>
      {props.formatter ? props.formatter(props.item, props.index) : props.item}
    </div>
  );
}

export default function SortableList<T>({ values, formatter, onChange }: SortableListProps<T>) {
  const [activeId, setActiveId] = useState<number | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const sorted = arrayMove(values, active.id - 1, over.id - 1);
      onChange?.(sorted);
    }

    setActiveId(null);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={(event) => setActiveId(event.active.id as number)}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={Array.from({ length: values.length }, (_v, i) => i + 1)} strategy={verticalListSortingStrategy}>
        {values.map((item, index) => (
          <SortableItem key={index} item={item} index={index} formatter={formatter} />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? <div className="opacity-75">{formatter ? formatter(values[activeId - 1], 0) : values[activeId - 1]}</div> : null}
      </DragOverlay>
    </DndContext>
  );
}
