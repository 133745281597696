import React from 'react';
import './spinnerStyles.css';

export default function Spinner() {
  return (
    <div className="m-auto">
      <div className="spinner"></div>
    </div>
  );
}
