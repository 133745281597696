import React, { useState } from 'react';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import camelCase from 'lodash/camelCase';
import IconButton from '../Buttons/IconButton';
import { descriptionListInputTypes } from './DisplayDescriptionValue';
import InputType from './InputType';

export default function EditDescriptionValue({ value, inputType, onSave, setState, label, displayValueStates, required, ...restOfProps }) {
  const [inputValue, setInputValue] = useState(value ? value : inputType === descriptionListInputTypes.checkbox ? false : '');

  const handleCancel = () => {
    setInputValue(value);
    setState(displayValueStates.display);
  };

  const handleSave = async (overrideValue = undefined) => {
    setState(displayValueStates.loading);
    await onSave((overrideValue === undefined ? inputValue : overrideValue) || null);
    setState(displayValueStates.display);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setState(displayValueStates.display);
      }}
    >
      <form className="flex w-full items-center justify-between">
        <div className="w-full">
          <InputType
            inputType={inputType}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setState={setState}
            label={label}
            handleSave={handleSave}
            displayValueStates={displayValueStates}
            required={required}
            {...restOfProps}
          />
        </div>
        <IconButton text="Cancel" Icon={Close} size="xs" onClick={handleCancel} />
        <span style={{ padding: '0 2px' }} />
        <IconButton
          data-testid={`${camelCase(label)}Submit`}
          type="button"
          text="Save"
          Icon={Check}
          size="xs"
          onClick={() => handleSave()}
        />
      </form>
    </ClickAwayListener>
  );
}
