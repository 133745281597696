import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '../Loaders/Skeleton';

export default function Textarea({ className = '', loading = false, rows = '4', ...restOfProps }) {
  if (loading) {
    return (
      <div className="w-full text-lg rounded">
        <Skeleton count={5} />
      </div>
    );
  }

  return (
    <textarea
      rows={rows}
      className={`
    text-gray-900
    placeholder-gray-400
    bg-gray-100
    leading-tight
    border
    border-gray-300 
    w-full py-2 pt-3 px-3 
    rounded
    outline-none
    hover:bg-white hover:border-gray-400
    focus:bg-blue-100 focus:border-blue-500 focus:ring 
    ${className}`}
      {...restOfProps}
    />
  );
}

Textarea.propTypes = { className: PropTypes.string, loading: PropTypes.bool, rows: PropTypes.string };
