import React, { useState } from 'react';
import { PlaylistAdd, PlaylistAddCheckOutlined } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AasChargeStatusType,
  AcceptedFileExtensions,
  ChargeAdjudicationResultType,
  ChargeDescriptionEnum,
  ChargeTypeType,
  ClientOrganizationEnumType,
  EntityLabelEnum,
  FileMetadataPostInput,
  patchInvoice,
  PayeeType,
  postCharge,
  postFile,
  postInvoice,
  queryServiceRequests,
} from '@equips/entities-schema';
import IconButton from '../Buttons/IconButton';
import SimpleModal, { simpleModalTypes } from '../Modal/SimpleModal';
import Button from '../Buttons/Button';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';
import { useAuth } from '../../auth/AuthContext';
import { NEW } from '../../../screens/invoices/invoiceStatuses';
interface CreateInvoiceModalProps {
  location?: string;
  parentId?: string;
  parentLabel?: string;
  serviceRequestId?: string;
  name?: string;
  createdAt?: number;
}

export const CreateInvoiceModal = ({ location, parentId, parentLabel, serviceRequestId, name, createdAt }: CreateInvoiceModalProps) => {
  const { auth, isAasUser, clientOrganizationType, internalUsers } = useAuth();
  const showAlert = useAlert();
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const isCoverageInternalUser =
    clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage &&
    auth?.authorizationRole &&
    internalUsers.includes(auth?.authorizationRole);

  const { data, refetch } = useQuery(
    ['getServiceRequestsForInvoiceModal', serviceRequestId],
    () =>
      queryServiceRequests(
        {
          serviceRequestId,
        },
        {
          query: `
        organizationMetadata {
          invoiceSettings {
            deductibleAmount
          }
        }
        invoices {
          deactivatedAt
          fileId
          file {
            metadata {
               fileId
               presignedGetUrl
            }
          }
        }
        metadata {
          shortId
          requestedProviderId
          organization {
            metadata {
              organizationId
            }
          }
          aasContract{
            metadata{
              planDeductible
            }
          }
        }
        incidents {
          metadata {
            incidentId
            equipment {
              metadata {
                serialNumber
              }
            }
          }
        }`,
        },
      ),
    { enabled: !!serviceRequestId },
  );

  const serviceRequest = data?.data?.serviceRequests?.data?.[0] || {};

  const activeInvoices = serviceRequest.invoices?.filter((invoice) => !invoice?.deactivatedAt) || [];

  const shortId = serviceRequest?.metadata?.shortId || '';
  const incidentId = serviceRequest?.incidents?.[0]?.metadata?.incidentId;
  const organization = serviceRequest?.metadata?.organization;
  const organizationId = organization?.metadata?.organizationId;
  const planDeductibleInCents = (serviceRequest?.metadata?.aasContract?.metadata?.planDeductible ?? 0) * -100;
  const deductibleAmount =
    (isAasUser ? planDeductibleInCents : serviceRequest?.organizationMetadata?.invoiceSettings?.deductibleAmount) || 0;
  const providerOrganizationId = serviceRequest?.metadata?.requestedProviderId || '';
  const serialNumber = serviceRequest?.incidents?.[0]?.metadata?.equipment?.metadata?.serialNumber;
  const fileExtension = name?.split('.')?.[1] || '';
  const invoices = data?.data?.serviceRequests?.data?.[0]?.invoices;
  const foundDuplicateInvoice = Boolean(
    invoices?.find((invoice) => invoice?.file?.metadata?.presignedGetUrl?.split('.com/')[1].split('?')[0] === location)?.fileId,
  );

  const { mutate: createNewInvoice, isLoading: isSaving } = useMutation(
    async () => {
      const newInvoice = await postInvoice({
        metadata: {
          invoiceStatus: NEW,
          ...(isAasUser && { invoiceServiceRequestId: serviceRequestId }),
          serviceRequestNumber: shortId,
          clientOrganizationId: organizationId,
          ...(providerOrganizationId && { providerOrganizationId }),
          ...(isCoverageInternalUser && { serialNumber: serialNumber, receivedAt: createdAt }),
        },
      });

      const invoiceId = newInvoice?.data?.post?.invoiceId ?? '';
      // Only apply this for AAS and on the first invoice.
      if (activeInvoices.length === 0 && isAasUser) {
        await postCharge({
          metadata: {
            invoiceId: invoiceId,
            incidentId: incidentId,
            clientOrganizationId: organizationId,
            description: ChargeDescriptionEnum.DeductibleCredit,
            aasChargeStatus: AasChargeStatusType.Approved,
            chargeType: ChargeTypeType.ServiceCharge,
            amountInCents: deductibleAmount,
            payeeType: PayeeType.Customer,
            adjudicationResult: ChargeAdjudicationResultType.Approved,
          },
        });
      }
      const fileMetadata: FileMetadataPostInput = {
        fileExtension: fileExtension as AcceptedFileExtensions,
        parentId: parentId,
        parentLabel: parentLabel as EntityLabelEnum,
        fileStorageLocation: location,
        fileName: name,
      };
      const file = await postFile({ metadata: fileMetadata });
      const fileId = file?.data?.post?.fileId;

      await patchInvoice({ invoiceId, metadata: { ['fileId']: fileId } });
    },
    {
      onSuccess: () => {
        showAlert({ type: AlertTypes.success, content: 'Successfully created a new invoice' });
        setModalOpen(false);
        refetch();
        isAasUser && queryClient.invalidateQueries(['getAasClaimForView', serviceRequestId]);
        isCoverageInternalUser && queryClient.invalidateQueries(['ViewServiceRequest', serviceRequestId]);
      },
      onError: () => showAlert({ type: AlertTypes.error, content: 'An error occurred while saving the new invoice.  Please try again.' }),
    },
  );

  return (
    <>
      {!foundDuplicateInvoice ? (
        <IconButton
          iconClassName={'text-blue-600'}
          Icon={PlaylistAdd}
          size="small"
          text="Create new invoice"
          onClick={() => setModalOpen(true)}
        />
      ) : (
        <IconButton
          iconClassName={'text-green-600'}
          Icon={PlaylistAddCheckOutlined}
          size="small"
          text="Invoice already created from this file"
          onClick={() => setModalOpen(true)}
        />
      )}
      {modalOpen && !foundDuplicateInvoice && (
        <SimpleModal
          size="xs"
          modalType={simpleModalTypes.info}
          isOpen={modalOpen}
          handleClose={() => setModalOpen(false)}
          title="Are you sure you want to create a new invoice with this file ? "
        >
          <div className="ml-10 mt-4 flex justify-start gap-5">
            <Button className="ml-4" green rounded="rounded-xl" onClick={() => createNewInvoice()} loading={isSaving}>
              Save New Invoice
            </Button>
            <Button danger rounded="rounded-xl" onClick={() => setModalOpen(false)}>
              No
            </Button>
          </div>
        </SimpleModal>
      )}
    </>
  );
};
