import { gql } from '../graphqlHelperFunctions';
import { deleteMutation } from '../commonMutations';
import { postToCoveragesEndpoint } from '../postToEndpoint';

export const deleteCoverage = async ({ entityId }) => postToCoveragesEndpoint({ query: deleteMutation, variables: { entityId } });

export const postCoverage = async ({
  onDate,
  offDate,
  dailyCoveragePrice,
  dailyMaintenanceContractFee,
  equipmentId,
  legacyItemId,
  organizationId,
  locationId,
  coverageStatus,
  contractId,
  conditions,
}) =>
  postToCoveragesEndpoint({
    query: gql`
      mutation($metadata: CoverageMetadataPostInput!) {
        post(metadata: $metadata) {
          metadata {
            coverageId
          }
        }
      }
    `,
    variables: {
      metadata: {
        onDate,
        offDate,
        dailyCoveragePrice,
        dailyMaintenanceContractFee,
        equipmentId,
        legacyItemId,
        organizationId,
        locationId,
        coverageStatus,
        contractId,
        conditions,
      },
    },
  });

export const patchCoverage = async ({ coverageId, patchFields }) =>
  postToCoveragesEndpoint({
    query: gql`
      mutation($coverageId: String!, $metadata: CoverageMetadataPatchInput!) {
        patch(coverageId: $coverageId, metadata: $metadata) {
          metadata {
            coverageId
          }
        }
      }
    `,
    variables: {
      coverageId,
      metadata: { ...patchFields },
    },
  });
