import { UserFeatureFlagEnum } from '@equips/entities-schema';

export enum FeatureVisiblity {
  Beta = 'beta',
  General = 'general',
}

export type Feature = {
  name: string;
  visibility: FeatureVisiblity.Beta | FeatureVisiblity.General;
};

export type Features = {
  [id: string]: Feature;
};

export const features: Features = {
  [UserFeatureFlagEnum.IncidentsPage]: {
    name: UserFeatureFlagEnum.IncidentsPage,
    visibility: FeatureVisiblity.General,
  },
  [UserFeatureFlagEnum.CustomerInvoiceSubmission]: {
    name: UserFeatureFlagEnum.CustomerInvoiceSubmission,
    visibility: FeatureVisiblity.General,
  },
  [UserFeatureFlagEnum.PreventativeMaintenance]: {
    name: UserFeatureFlagEnum.PreventativeMaintenance,
    visibility: FeatureVisiblity.Beta,
  },
};
