import React from 'react';
import { AasFailCodeMetadata } from '@equips/entities-schema';
import { Category, Description } from '@mui/icons-material';
import { AasFailGroupToTextMap } from '../../../common/functions/aasHelpers';

interface AasFailCodeSelectOptionType {
  value: string;
  label: string;
}

const formatAasFailCodeForReactSelect = (failCode: AasFailCodeMetadata): AasFailCodeSelectOptionType => {
  if (failCode.aasFailCodeId && failCode.code) {
    return {
      value: failCode.aasFailCodeId,
      label: failCode.code,
      ...failCode,
    };
  }

  return { value: '', label: '' };
};

const formatAasFailCategoryForReactSelect = (failCode: AasFailCodeMetadata): AasFailCodeSelectOptionType => {
  if (failCode.group) {
    return {
      value: failCode.group,
      label: AasFailGroupToTextMap[failCode.group],
      ...failCode,
    };
  }

  return { value: '', label: '' };
};

const AasFailCategorySelectOption = ({
  innerProps = {},
  innerRef = undefined,
  isDisabled = false,
  data,
  isSelected = false,
  hoverStyle = true,
}) => {
  const { group, aasFailCodeId } = data;

  if (isDisabled) return null;

  return (
    <div
      key={aasFailCodeId}
      ref={innerRef}
      {...innerProps}
      className={`px-4 py-2 text-left ${
        isSelected ? 'bg-blue-600 text-white' : `border-b border-t border-gray-200 text-gray-700 ${hoverStyle ? ' hover:bg-blue-100' : ''}`
      }`}
    >
      <div className={`text-lg ${isSelected ? 'text-white' : 'text-gray-800'}`}>{AasFailGroupToTextMap[group]}</div>
    </div>
  );
};

const AasFailCodeSelectOption = ({
  innerProps = {},
  innerRef = undefined,
  isDisabled = false,
  data,
  isSelected = false,
  hoverStyle = true,
}) => {
  const { aasFailCodeId, group, code, description } = data;

  if (isDisabled) return null;

  return (
    <div
      key={aasFailCodeId}
      ref={innerRef}
      {...innerProps}
      className={`px-4 py-2 text-left ${
        isSelected ? 'bg-blue-600 text-white' : `border-b border-t border-gray-200 text-gray-700 ${hoverStyle ? ' hover:bg-blue-100' : ''}`
      }`}
    >
      <div className={`text-lg ${isSelected ? 'text-white' : 'text-gray-800'}`}>{code}</div>
      <div className="flex items-center gap-1">
        <Category fontSize="inherit" />{' '}
        <span className={`text-xs ${isSelected ? 'text-white' : 'text-gray-800'}`}>{AasFailGroupToTextMap[group]}</span>
      </div>
      <div className="flex items-center gap-1">
        <Description fontSize="inherit" />
        <span className={`text-xs ${isSelected ? 'text-white' : 'text-gray-800'}`}>{description}</span>
      </div>
    </div>
  );
};

export {
  AasFailCodeSelectOption,
  AasFailCategorySelectOption,
  AasFailGroupToTextMap,
  formatAasFailCodeForReactSelect,
  formatAasFailCategoryForReactSelect,
};
