import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Maybe, queryLocations, EntityLabelEnum, Contact } from '@equips/entities-schema';
import { useTranslation } from 'react-i18next';
import FormFields, { fieldTypes } from '../../common/components/Form/FormFields';
import { AlertTypes, useAlert } from '../../common/components/Alerts/AlertContext';
import { getLocationByLocationId } from '../../graphql/queries/locationGraphQLQueries';
import { createLocation, updateLocation } from '../../graphql/mutations/LocationMutations';
import { useAuth } from '../../common/auth/AuthContext';
import OrganizationsSelect from '../../common/components/Selects/OrganizationsSelect';
import { countries, nonStateBasedCountries } from '../../common/constants/countries';
import ClientTagsMultiSelect from '../../common/components/Selects/ClientTagsMultiSelect';
import AdditionalContactsContainer from '../../common/components/ServiceRequest/AdditionalContactsContainer';
import FlatAccordion from '../../common/components/Accordion/FlatAccordion';
import { getAasOrganizationId } from '../../common/functions/aasHelpers';
import SublocationsDisplay from './components/SublocationsDisplay';

type LocationFormState = {
  saving: boolean;
  organizationId: Maybe<string>;
  locationName: Maybe<string>;
  shortId: Maybe<string>;
  line1: Maybe<string>;
  line2: Maybe<string>;
  city: Maybe<string>;
  stateUnabbreviated: Maybe<string>;
  zip: Maybe<string>;
  countryUnabbreviated: Maybe<string>;
  timezone: Maybe<string>;
  clientTags: Maybe<Maybe<string>[]>;
  contacts: Maybe<Maybe<Contact>[]>;
  isCreate: boolean;
};

const shouldShowStateSelector = (selection: string) => !nonStateBasedCountries.includes(selection);

export default function LocationForm(props: {
  assignableOrganizationId?: string;
  locationId?: string;
  onSuccess?: (...args: any[]) => any;
}) {
  const { t } = useTranslation();
  const { assignableOrganizationId, locationId, onSuccess } = props;
  const { auth, admins, roles, userCan, internalUsers, isTrial } = useAuth();

  const showAlert = useAlert();

  const { data: trialLimitExceeded } = useQuery(
    ['LocationFormTrialCount'],
    async () => {
      const { data } = await queryLocations({
        search: {
          includeTotalCount: true,
          filters: [{ metadata: { organizationId: [{ keyword: [{ term: auth?.organizationId }] }] } }],
        },
      });
      return (data?.locations?.totalCount ?? 0) >= 1;
    },
    { enabled: isTrial },
  );

  const [
    {
      organizationId,
      saving,
      locationName,
      shortId,
      line1,
      line2,
      city,
      stateUnabbreviated,
      zip,
      countryUnabbreviated,
      timezone,
      clientTags,
      contacts,
      isCreate,
    },
    setState,
  ] = useState<LocationFormState>({
    saving: false,
    organizationId: assignableOrganizationId || auth?.organizationId,
    locationName: '',
    shortId: '',
    line1: '',
    line2: '',
    city: '',
    stateUnabbreviated: '',
    zip: '',
    countryUnabbreviated: '',
    timezone: '',
    clientTags: [],
    contacts: [],
    isCreate: locationId ? false : true,
  });

  const {
    isFetching,
    data: locationData,
    refetch: refetchLocationData,
  } = useQuery(['getLocationByLocationId', locationId], () => getLocationByLocationId({ locationId }), {
    enabled: !!locationId,
    select: (data) => data?.data?.locations?.data?.[0],
    onSuccess: (location) => {
      const countryUnabbreviated = location?.address?.countryUnabbreviated || countries.unitedStates.value;

      setState((state) => ({
        ...state,
        organizationId: location?.metadata?.organizationId,
        locationName: location?.metadata?.locationName,
        shortId: location?.metadata?.shortId,
        line1: location?.address?.line1,
        line2: location?.address?.line2,
        city: location?.address?.city,
        stateUnabbreviated: location?.address?.stateUnabbreviated,
        zip: location?.address?.zip,
        countryUnabbreviated: countryUnabbreviated,
        timezone: location?.metadata?.timezone,
        clientTags: location?.metadata?.clientTags?.map((x) => x?.tagId),
        contacts: location?.metadata?.contacts,
      }));
    },
  });

  const changeData = ({ target: { id, value } }) => setState((state) => ({ ...state, [id]: value }));

  const isState = shouldShowStateSelector(countryUnabbreviated || '');

  const isAasLocation = organizationId === getAasOrganizationId();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState((state) => ({ ...state, saving: true }));

    try {
      if (isCreate) {
        const response = await createLocation({
          organizationId,
          locationId,
          locationName,
          shortId,
          line1,
          line2,
          city,
          stateUnabbreviated,
          zip,
          countryUnabbreviated,
          timezone,
          clientTags,
          contacts,
        });

        showAlert({ content: t('success') });

        if (onSuccess) {
          onSuccess(response.data?.post?.metadata?.locationId);
        }
      } else {
        await updateLocation({
          organizationId,
          locationId,
          locationName,
          shortId,
          line1,
          line2,
          city,
          stateUnabbreviated,
          zip,
          countryUnabbreviated,
          timezone,
          clientTags,
          contacts,
          autogenerated: false,
        });

        showAlert({ content: t('success') });
        setState((state) => ({ ...state, saving: false }));
      }
    } catch (error) {
      console.error(error);
      showAlert({ type: AlertTypes.error, content: t('errorMessage') });
      setState((state) => ({ ...state, saving: false }));
      throw error;
    }
  };

  if (!userCan([...admins, roles.customerLocationAdmin, roles.providerLocationAdmin])) {
    return <div data-testid="noAuthLocationsCreateForm" />;
  }

  return (
    <div data-testid="locationsCreateForm">
      <div className="mb-8">
        {trialLimitExceeded && <p className="text-red-700">{t('trialLimitedLocationMessage')}</p>}
        <form onSubmit={handleSubmit}>
          <fieldset disabled={trialLimitExceeded}>
            <FormFields
              loading={isFetching}
              saving={saving}
              handleChange={changeData}
              primaryButton={t('save')}
              submitButtonHelperText={isCreate ? t('saveToAddParentAndSublocations') : ''}
              fields={[
                {
                  heading: t('locationInformation'),
                  fields: [
                    ...(userCan(internalUsers) && isCreate && !assignableOrganizationId
                      ? [
                          {
                            id: 'organizationId',
                            as: (
                              <OrganizationsSelect
                                id="organizationId"
                                typeOfOrganizationToFind="ANY"
                                fullWidth
                                organizationId={organizationId || ''}
                                changeOrganization={({ value }) => setState((state) => ({ ...state, organizationId: value }))}
                                required
                              />
                            ),
                          },
                        ]
                      : []),
                    { label: t('name'), id: 'locationName', required: true, value: locationName },
                    { label: t('number'), id: 'shortId', required: false, value: shortId },
                    {
                      id: 'clientTags',
                      as: (
                        <ClientTagsMultiSelect
                          id="clientTags"
                          label={t('tags')}
                          organizationId={organizationId || ''}
                          entityLabel={EntityLabelEnum.Location}
                          value={clientTags as string[]}
                          setValue={(selections) => setState((state) => ({ ...state, clientTags: selections?.map((x) => x.value) || [] }))}
                          fullWidth
                          isClearable
                        />
                      ),
                    },
                    {
                      id: 'contacts',
                      as: (
                        <FlatAccordion title={t('contacts')}>
                          <AdditionalContactsContainer
                            locationId={locationId}
                            organizationId={organizationId || ''}
                            contacts={!contacts?.length ? JSON.stringify(locationData?.metadata?.contacts ?? []) : JSON.stringify(contacts)}
                            setContactString={(contacts: string) => setState((state) => ({ ...state, contacts: JSON.parse(contacts) }))}
                            disablePrimaryEdit={isAasLocation && !userCan(internalUsers)}
                          />
                        </FlatAccordion>
                      ),
                    },
                  ],
                },
                {
                  heading: t('address'),
                  fields: [
                    { label: t('line1'), id: 'line1', value: line1, placeholder: ' ' },
                    { label: t('line2'), id: 'line2', value: line2, placeholder: ' ' },
                    { label: t('city'), id: 'city', value: city, placeholder: ' ' },
                    {
                      id: 'countryUnabbreviated',
                      label: t('country'),
                      value: countryUnabbreviated || '',
                      type: fieldTypes.COUNTRY_SELECT,
                    },
                    {
                      label: isState ? t('state') : t('provinceRegion'),
                      id: 'stateUnabbreviated',
                      value: stateUnabbreviated || '',
                      type: isState ? fieldTypes.STATE_SELECT : fieldTypes.text,
                      isHalf: true,
                    },
                    { id: 'zip', label: t('zip'), value: zip || '', placeholder: ' ', isHalf: true },
                    {
                      label: t('timezone'),
                      id: 'timezone',
                      value: timezone || '',
                      type: fieldTypes.TIMEZONE_SELECT,
                      placeholder: ' ',
                    },
                  ],
                },
              ]}
            />
          </fieldset>
        </form>
      </div>
      {!isCreate && <SublocationsDisplay organizationId={organizationId} location={locationData} refetchLocation={refetchLocationData} />}
    </div>
  );
}
