import { RecurrenceOptionsType } from '@equips/entities-schema';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import { enumToOptions } from '../../../graphql/enums';
import StyledSelect from './StyledSelect';

type RecurrenceOptionsSelectProps = {
  value: string | number;
  id: string;
  label?: string;
  helper?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>;
};

const RecurrenceOptionSelect = ({ value, onChange, id, label = 'Recurrence Option', helper }: RecurrenceOptionsSelectProps) => {
  const { t } = useTranslation();
  const [recurrenceOption, setRecurrenceOption] = useState(value);

  const options = enumToOptions('RecurrenceOptionsType', RecurrenceOptionsType, t, { anyTextKey: 'any' });

  return (
    <FormGroup fullWidth key={id}>
      <Label id={id} label={label} helper={helper} />
      <StyledSelect
        key={id}
        id={id}
        data-testid={id}
        value={recurrenceOption}
        onChange={(event) => {
          setRecurrenceOption(event.target.value);
          onChange(event);
        }}
      >
        {options?.map(({ label, value }) => (
          <option value={value} key={label}>
            {label}
          </option>
        ))}
      </StyledSelect>
    </FormGroup>
  );
};

export default RecurrenceOptionSelect;
