import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import MaterialIconButton from '@mui/material/IconButton';

type IconButtonProps = {
  type?: 'submit' | 'button';
  size?: 'xs' | 'small' | 'medium' | 'large';
  Icon: any;
  text: string;
  onClick(...args: any[]): any;
  disabled?: boolean;
  [prop: string]: any;
};

/* eslint-disable react/button-has-type */
const IconButton = React.forwardRef((props: IconButtonProps, ref: React.Ref<any>) => {
  const { type = 'button', Icon, text, onClick, size = 'small', disabled, className, iconClassName, ...restOfProps } = props;
  return (
    <Tooltip title={text} disableHoverListener={disabled}>
      {size === 'xs' ? (
        <div>
          <button
            ref={ref}
            className={`rounded-full bg-gray-50 p-1 text-base leading-none hover:bg-gray-200 ${className}`}
            type={type}
            onClick={onClick}
            {...restOfProps}
          >
            <Icon fontSize="inherit" color="inherit" />
          </button>
        </div>
      ) : (
        <MaterialIconButton ref={ref} color="inherit" type={type} size={size} onClick={onClick} {...restOfProps}>
          <Icon className={iconClassName} />
        </MaterialIconButton>
      )}
    </Tooltip>
  );
});

export default IconButton;
