import React, { CSSProperties } from 'react';

type SkeletonProps = {
  count?: number;
  duration?: number;
  width?: number | null;
  height?: number | null;
  circle?: boolean;
  direction?: 'flex-col' | 'flex-row';
  gap?: string;
};

export default function Skeleton({
  count = 1,
  duration = 1.2,
  width = null,
  height = null,
  circle = false,
  direction = 'flex-row',
  gap = '',
}: SkeletonProps) {
  const elements: JSX.Element[] = [];

  for (let i = 0; i < count; i++) {
    let style: CSSProperties = {
      animation: `skeleton ${String(duration)}s ease-in-out infinite`,
    };

    if (width !== null) {
      style.width = width;
    }

    if (height !== null) {
      style.height = height;
    }

    if (width !== null && height !== null && circle) {
      style.borderRadius = '50%';
    }

    elements.push(
      <span key={i} className={`skeletonStyles`} style={style}>
        &zwnj;
      </span>,
    );
  }

  return <span className={`flex ${direction} ${gap}`}>{elements}</span>;
}
