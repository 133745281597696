import React from 'react';
import { Link } from 'react-router-dom';
import { Charge, ChargeStatusType } from '@equips/entities-schema';
import { JsonParam } from 'use-query-params';
import { centsToMoney } from '../../common/functions/moneyFunctions';
import Urls from '../../routes/Urls';
import {
  chargeStatuses,
  chargeLifecycles,
  chargeTypes,
  getEnumDisplayName,
  enumObjectToSelectOptions,
  serviceRequestServiceTypes,
  chargeDescriptionOptions,
  chargeAdjudicationResultTypes,
} from '../../graphql/enums';
import { toDateStringFromUnixMillisecondTimestamp } from '../../common/functions/dateFunctions';
import DropDown from '../../common/components/DropDown';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';
import { clientValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import { defaultTableWidths } from '../../common/components/Table/tableDefaults';
import { TableColumn } from '../../common/components/DataTable';
import DisplayTime from '../../common/components/Date/DisplayTime';
import { getAdjudicationReasonsForSelect } from './businessLogic/adjudicationReasons';

export const getChargeStatusName = (currentValue, isPositiveCharge) => {
  let status = getEnumDisplayName(currentValue, chargeStatuses);
  if (isPositiveCharge) return status;

  if (chargeStatuses.readyToInvoiceClient.value === currentValue) {
    return chargeStatuses.readyToCreditMemoClient.name;
  } else if (chargeStatuses.clientInvoiceCreated.value === currentValue) {
    return chargeStatuses.clientCreditMemoCreated.name;
  } else if (chargeStatuses.clientInvoiceIssued.value === currentValue) {
    return chargeStatuses.clientCreditMemoIssued.name;
  }

  return status;
};

const ChargesRowAction = (data) => {
  return (
    <DropDown
      containerClasses="more-actions"
      actions={[
        { Component: 'View charge', to: `${Urls.CHARGES}/${data?.row?.original?.metadata?.chargeId}` },
        { border: true },
        ...(data?.row?.original?.metadata?.coverageId
          ? [{ Component: 'View coverage', to: `${Urls.COVERAGE}/${data?.row?.original?.metadata?.coverageId}` }]
          : []),
        {
          Component: 'View client',
          target: '_blanks',
          to: `${Urls.ORGANIZATIONS}/${data?.row?.original?.metadata?.clientOrganizationId}`,
        },
        {
          Component: 'View provider',
          target: '_blanks',
          to: `${Urls.ORGANIZATIONS}/${data?.row?.original?.metadata?.providerOrganizationId}`,
        },
      ]}
    />
  );
};

export default function ChargeColumns(): TableColumn<Charge>[] {
  return [
    {
      id: 'metadata.chargeType',
      Header: 'Type',
      accessor: (data) => data.metadata?.chargeType,
      Cell: (data) => {
        return (
          <div className="flex gap-4">
            <Link
              className="active font-bold"
              data-testid="toChargeFromTable"
              to={`${Urls.CHARGES}/${data?.row?.original?.metadata?.chargeId}`}
            >
              {getEnumDisplayName(data?.row?.original?.metadata?.chargeType, chargeTypes)}
            </Link>
            <ChargesRowAction {...data} />
          </div>
        );
      },
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(chargeTypes, { anyText: 'Any' }),
      },
      width: 200,
    },
    {
      id: 'metadata.chargeStatus',
      Header: 'Status',
      accessor: (data) => data.metadata?.chargeStatus,
      Cell: (data) => getChargeStatusName(data.value, (data?.row?.original?.metadata?.amountInCents || 0) >= 0) || 'No status',
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(chargeStatuses, { anyText: 'Any' }),
      },
    },
    {
      id: 'metadata.tags',
      Header: 'Tags',
      accessor: (data) => data.metadata?.tags,
      Cell: (data) => data?.value?.join(',') || '',
      disableSortBy: true,
    },
    {
      id: 'metadata.incident.metadata.createdAt',
      Header: 'Invoice date / Date of Loss',
      accessor: (data) => data?.metadata?.incident?.metadata?.createdAt,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data.value),
      disableSortBy: true,
      width: 100,
    },
    {
      id: 'metadata.equipment.metadata.shortId',
      Header: 'Equipment #',
      accessor: (data) => data?.equipmentMetadata?.shortId,
      width: 100,
      disableSortBy: true,
      Cell: (data) => (
        <Link
          target="_blank"
          className="active font-bold"
          data-testid="toEquipment"
          to={`${Urls.EQUIPMENT}/${data?.row?.original?.metadata?.incident?.metadata?.equipment?.metadata?.equipmentId}`}
        >
          {data?.row?.original?.metadata?.incident?.metadata?.equipment?.metadata?.shortId}
        </Link>
      ),
    },
    {
      id: 'metadata.incident.metadata.equipment.metadata.equipmentName',
      Header: 'Equipment name',
      accessor: (data) => data?.metadata?.incident?.metadata?.equipment?.metadata?.equipmentName,
      disableSortBy: true,
    },
    {
      id: 'metadata.incident.metadata.equipment.specMetadata.category.metadata.name',
      Header: 'Equipment category',
      accessor: (data) => data?.metadata?.incident?.metadata?.equipment?.specMetadata?.category?.metadata?.name,
      disableSortBy: true,
    },
    {
      id: 'metadata.providerOrganization.metadata.organizationName',
      Header: 'Provider',
      accessor: (data) => data?.metadata?.providerOrganization?.metadata?.organizationName,
      disableSortBy: true,
    },
    {
      id: 'metadata.chargeLifecycle',
      Header: 'Charge lifecycle',
      accessor: (data) => data?.metadata?.chargeLifecycle,
      Cell: (data) => {
        if (!data.value) return '';

        return getEnumDisplayName(data.value, chargeLifecycles);
      },
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(chargeLifecycles, { anyText: 'Any' }),
      },
    },
    {
      id: 'metadata.hours',
      Header: 'Hours',
      accessor: (data) => data?.metadata?.hours,
      width: 100,
    },
    {
      id: 'metadata.serviceType',
      Header: 'Service type',
      accessor: (data) => data?.metadata?.serviceType,
      Cell: (data) => {
        if (!data.value) return '';

        return getEnumDisplayName(data.value, serviceRequestServiceTypes);
      },
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(serviceRequestServiceTypes, { anyText: 'Any' }),
      },
    },
    {
      id: 'metadata.clientOrganizationId',
      Header: 'Client',
      accessor: (data) => data?.metadata?.clientOrganizationId,
      width: defaultTableWidths.normalText,
      Cell: (data) => (
        <Link
          data-testid="toOrganization"
          className="secondary-active"
          to={`${Urls.ORGANIZATIONS}/${data?.row?.original?.metadata?.clientOrganization?.metadata?.organizationId}`}
        >
          {data.row?.original?.metadata?.clientOrganization?.metadata?.organizationName}
        </Link>
      ),
      filterOptions: {
        type: TableFilterTypes.organizationSelect,
        label: 'Client',
        typeOfOrganizationToFind: clientValueForFilter,
      },
    },
    {
      id: 'metadata.amountInCents',
      Header: 'Amount',
      accessor: (data) => data?.metadata?.amountInCents,
      Cell: (data) => {
        const isNegativeCharge = data?.row?.original?.metadata?.chargeStatus === ChargeStatusType.Rejected;
        const amount = (data.value || 0) * (isNegativeCharge ? -1 : 1);

        return <div>{centsToMoney(amount)}</div>;
      },
      filterOptions: {
        type: TableFilterTypes.currencyRange,
        label: 'Amount',
      },
    },
    {
      id: 'metadata.description',
      Header: 'Description',
      accessor: (data) => data.metadata?.description,
      Cell: (data) => {
        return getEnumDisplayName(data.value, chargeDescriptionOptions) || '';
      },
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(chargeDescriptionOptions, { anyText: 'Any' }),
      },
    },
    {
      id: 'metadata.adjudicationResult',
      Header: 'Adjudication',
      accessor: (data) => data.metadata?.adjudicationResult,
      width: defaultTableWidths.normalText,

      Cell: (data) => {
        return getEnumDisplayName(data.value, chargeAdjudicationResultTypes) || '';
      },
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(chargeAdjudicationResultTypes, { anyText: 'Any' }),
      },
    },
    {
      id: 'metadata.primaryAdjudicationReason',
      Header: 'Adjudication reason',
      accessor: (data) => data.metadata?.primaryAdjudicationReason,
      width: defaultTableWidths.normalText,
      Cell: (data) => data?.value || '',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        options: getAdjudicationReasonsForSelect(),
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    {
      Header: 'Adjudicated on',
      id: 'metadata.adjustedAt',
      accessor: (data) => data.metadata?.adjustedAt,
      width: 150,
      toText: (data) =>
        `${
          toDateStringFromUnixMillisecondTimestamp(data.row.original?.metadata?.adjustedAt, {
            customFormatString: 'MM/DD/YYYY hh:mm a',
          }) || 'Not adjudicated'
        }`,
      Cell: (data) => <DisplayTime timestamp={data.value} />,
      filterOptions: {
        type: TableFilterTypes.dateRange,
        label: 'Adjudicated on',
      },
    },
    {
      Header: 'Adjudicated by',
      id: 'metadata.adjustedByUserName',
      accessor: (data) => data.metadata?.adjustedByUserName,
    },
    {
      id: 'metadata.adjustedByUserId',
      Header: 'Adjudicated by User Id',
      accessor: (data) => data.metadata?.adjustedByUserId,
      filterOptions: {
        type: TableFilterTypes.userSelect,
        label: 'Adjudicated by',
      },
    },
    {
      id: 'metadata.invoiceId',
      Header: 'Invoice',
      accessor: (data) => data?.metadata?.invoiceId,
      filterOptions: {
        type: TableFilterTypes.hidden,
      },
      Cell: (data) => (
        <Link className="secondary-active font-bold" to={`${Urls.INVOICES}/${data.value}`}>
          {data?.row?.original?.metadata?.invoice?.metadata?.shortId}
        </Link>
      ),
    },
    {
      id: 'metadata.quickbooksBillPaidDate',
      Header: 'Bill paid date',
      accessor: (data) => data?.metadata?.quickbooksBillPaidDate,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data.value),
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.quickbooksInvoicePaidDate',
      Header: 'Invoice paid date',
      accessor: (data) => data.metadata?.quickbooksInvoicePaidDate,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data.value),
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
  ];
}
