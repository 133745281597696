import {
  assignParts,
  PartMetadata,
  queryParts,
  queryPartsOnServiceRequests,
  ServiceRequest,
  SortByElasticSearchOptions,
} from '@equips/entities-schema';
import React, { useCallback, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Cancel from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import SimpleModal, { SimpleModalProps } from '../../../../common/components/Modal/SimpleModal';
import { defaultSelectStyles } from '../../../../common/components/Selects/defaultSelectStyles';
import useRepeaterField from '../../../../common/components/Form/useRepeaterField';
import Input from '../../../../common/components/Form/Input';
import IconButton from '../../../../common/components/Buttons/IconButton';
import Urls from '../../../../routes/Urls';
import Button from '../../../../common/components/Buttons/Button';
import { useAlert } from '../../../../common/components/Alerts/AlertContext';
import Skeleton from '../../../../common/components/Loaders/Skeleton';

type PartSelectOptionType = {
  label: string;
  value: string;
  part: PartMetadata;
};

type PartState = PartSelectOptionType & { quantity: string };

const PartSelectOption = ({ innerProps = {}, innerRef = undefined, isDisabled = false, data, isSelected = false, hoverStyle = true }) => {
  if (isDisabled) return null;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`flex justify-between px-4 py-2 ${
        isSelected ? 'bg-blue-600 text-white' : `border-b border-t border-gray-200 text-gray-700 ${hoverStyle ? ' hover:bg-blue-100' : ''}`
      }`}
    >
      <span>{data?.label}</span>
      {data?.part?.quantity ? (
        <span className=" text-green-700">In Stock: {data?.part?.quantity}</span>
      ) : (
        <span className="text-red-700">Out Of Stock</span>
      )}
    </div>
  );
};
const AssignPartsModal = (props: { serviceRequest: ServiceRequest; modalProps: SimpleModalProps }) => {
  const showAlert = useAlert();
  const { serviceRequest, modalProps } = props;
  const serviceRequestId = serviceRequest?.metadata?.serviceRequestId;

  const loadPartOptions = useCallback(async (search) => {
    const data = (
      await queryParts({
        search: {
          q: search,
          size: 15,
          sortBy: [{ metadata: { modifiedAt: SortByElasticSearchOptions.Desc } }], //sort by recently used
        },
      })
    )?.data?.parts?.data;

    if (!data) return;

    const parts: PartSelectOptionType[] = data?.map((p) => {
      return {
        label: p?.metadata?.name ?? '',
        value: p?.partId ?? '',
        part: p?.metadata ?? {},
      };
    });

    return parts;
  }, []);

  const [parts, setParts] = useState<PartState[]>([]);

  const { isFetching, refetch } = useQuery(
    ['getPartsOnServiceRequest', serviceRequestId],
    () =>
      queryPartsOnServiceRequests(
        {
          search: {
            filters: [
              {
                metadata: {
                  serviceRequestId: [{ keyword: [{ term: serviceRequestId }] }],
                },
              },
            ],
          },
        },
        {
          query: `
          metadata {
            partId
            quantityUsed
          }
          part {
            metadata {
              name
            }
          }
      `,
        },
      ),
    {
      enabled: !!serviceRequestId,
      onSuccess: (data) => {
        const parts = data?.data?.partsOnServiceRequests?.data;
        const formattedParts = parts?.map((p) => {
          return {
            label: p?.part?.metadata?.name ?? '',
            value: p?.metadata?.partId ?? '',
            part: p?.part ?? {},
            quantity: (p?.metadata?.quantityUsed ?? 0).toString(),
          };
        });

        if (!formattedParts) return;

        setParts(formattedParts);
      },
    },
  );

  const { changeField, removeById, addButtonAction } = useRepeaterField(parts, {
    createDefaultItem: (part) => [
      {
        quantity: 1,
        ...part,
      },
    ],
    entityIdName: 'value',
    pluralName: 'parts',
    setItems: setParts,
    singularName: 'part',
  });

  const { mutate: assignPartsMutation } = useMutation(
    () =>
      assignParts({
        serviceRequestId,
        parts: parts?.map((p) => ({ partId: p?.value, quantityUsed: parseInt(p?.quantity) })),
      }),
    {
      onSuccess: async () => {
        showAlert({ content: 'Successfully assigned parts' });
        await refetch();
        modalProps.handleClose?.();
      },
      onError: () => {
        showAlert({ content: 'Failed to assign parts. Please try again.' });
      },
    },
  );

  return (
    <SimpleModal {...modalProps}>
      <Link to={Urls.INVENTORY} className="mt-4 block text-right" target="_blank">
        Go to Inventory
      </Link>
      <div className="mt-4">
        <div className="mb-8 ">
          <AsyncSelect
            key={serviceRequestId}
            components={{
              Option: PartSelectOption,
            }}
            styles={defaultSelectStyles}
            cacheOptions
            isMulti={false}
            value={null}
            onChange={(part: PartSelectOptionType) => {
              const exist = parts.some((p) => p.value === part.value) && part?.part?.quantity;
              if (!exist) addButtonAction(part);
            }}
            defaultOptions
            loadOptions={loadPartOptions}
            placeholder="Select a part"
            isClearable
            noOptionsMessage={() => 'No available parts'}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="mb-8">
          <div className="space-y-4">
            {isFetching ? (
              <>
                <Skeleton count={1} height={50} direction="flex-col" gap="gap-2" />
              </>
            ) : (
              parts.map((part) => (
                <div key={part?.value} className="flex items-center justify-between rounded-lg border border-gray-200 bg-gray-50 px-4 py-2">
                  {part?.label}{' '}
                  <div className=" flex w-32 items-center justify-between gap-4">
                    <Input
                      min={1}
                      max={part?.part?.quantity}
                      id={part?.value}
                      value={part?.quantity}
                      type="number"
                      onChange={(e) => {
                        changeField({ entityId: part?.value, field: 'quantity', value: e.target.value });
                      }}
                    />
                    <IconButton
                      Icon={Cancel}
                      text="Remove Part"
                      onClick={() => {
                        removeById(part?.value, part);
                      }}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <Button
          className="mb-4 ml-auto block"
          onClick={async () => {
            await assignPartsMutation();
          }}
        >
          Submit
        </Button>
      </div>
    </SimpleModal>
  );
};

export default AssignPartsModal;
