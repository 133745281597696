import { EntityLabelEnum, UserTagsType } from '@equips/entities-schema';
import { useEffect, useState } from 'react';
import getContractColumns from '../../../screens/contracts/ContractsColumns';
import { getEquipmentColumns } from '../../../screens/equipment/Equipment List/EquipmentColumns';
import { getLocationColumns } from '../../../screens/locations/LocationsColumns';
import OrganizationsColumns from '../../../screens/organizations/OrganizationsColumns';
import LegacyPaymentsScreenColumns from '../../../screens/payments/LegacyPaymentsScreenColumns';
import { getServiceRequestColumns } from '../../../screens/service-requests/ServiceRequestsColumns';
import { getServiceRequestStatusOptions } from '../../../screens/service-requests/businessLogic/serviceRequestStatusOptions';
import { useAuth } from '../../auth/AuthContext';
import { getPayableColumns } from '../../../screens/payables/PayablesColumns';
import AasContractsDataColumns from '../../../screens/aas/contracts-data/AasContractsDataColumns';
import { getAasInvoicesColumns } from '../../../screens/aas/invoices/AasInvoicesColumns';
import { internalUsers } from '../../auth/roles';
import { invoicePreviewEnabled } from '../../../screens/invoices/businessLogic/invoicesPreview';
import { getAasClaimsColumns } from '../../../screens/aas/claims/AasClaimsColumns';
import { getInvoiceColumns } from '../../../screens/invoices/InvoicesColumns';
import ChargeColumns from '../../../screens/charges/ChargeColumns';

const enabledViewsTableIdToEntityMap = {
  [`${EntityLabelEnum.Contract}sTable`]: EntityLabelEnum.Contract,
  [`${EntityLabelEnum.Equipment}Table`]: EntityLabelEnum.Equipment,
  [`${EntityLabelEnum.Location}sTable`]: EntityLabelEnum.Location,
  [`${EntityLabelEnum.Organization}Table`]: EntityLabelEnum.Organization,
  [`${EntityLabelEnum.ServiceRequest}Table`]: EntityLabelEnum.ServiceRequest,
  [`${EntityLabelEnum.Payable}Table`]: EntityLabelEnum.Payable,
  [`${EntityLabelEnum.AasContract}sTable`]: EntityLabelEnum.AasContract,
  [`${EntityLabelEnum.Invoice}Table`]: EntityLabelEnum.Invoice,
  [`${EntityLabelEnum.Charge}sTable`]: EntityLabelEnum.Charge,
  aasInvoiceTable: 'aasInvoiceTable',
  claimTable: 'claimTable',
  clientFacingNonSSBPayments: 'clientFacingNonSSBPayments',
};

const localCache: { [entity: string]: string[] } = {};

export function useTableViewColumns(tableId = '') {
  if (!tableId) throw new Error(`Missing tableId`);

  const { auth, userCan, isAasUser, determineFeatureFlagVisibility, clientOrganizationType } = useAuth();
  const [validIds, setValidIds] = useState<string[]>([]);

  const isInHouseTech = auth?.tags && auth.tags.includes(UserTagsType.InHouseTech);

  useEffect(() => {
    function getIdsFromColumnDefinition(entity: string) {
      switch (entity) {
        case EntityLabelEnum.Contract: {
          return getContractColumns(userCan, []);
        }

        case EntityLabelEnum.Equipment: {
          return getEquipmentColumns({ userCan, auth, determineFeatureFlagVisibility });
        }

        case EntityLabelEnum.Location: {
          return getLocationColumns(userCan, auth);
        }

        case EntityLabelEnum.Organization: {
          return OrganizationsColumns(userCan, [], [], [], []);
        }

        // Special case for LegacyPaymentsScreen
        case 'clientFacingNonSSBPayments': {
          return LegacyPaymentsScreenColumns(userCan);
        }

        case EntityLabelEnum.ServiceRequest: {
          const serviceRequestSelectEnum = getServiceRequestStatusOptions({ auth });

          return getServiceRequestColumns({
            serviceRequestSelectEnum,
            userCan,
            isAasUser,
            auth,
            clientOrganizationType,
            determineFeatureFlagVisibility,
          });
        }

        case EntityLabelEnum.Payable: {
          return getPayableColumns();
        }

        case EntityLabelEnum.Invoice: {
          return getInvoiceColumns(userCan, isInHouseTech || userCan(internalUsers) || invoicePreviewEnabled(auth));
        }

        case EntityLabelEnum.Charge: {
          return ChargeColumns();
        }

        case EntityLabelEnum.AasContract: {
          return AasContractsDataColumns();
        }

        case 'aasInvoiceTable': {
          // invoice migration
          return getAasInvoicesColumns({ userCan, userCanViewDetails: userCan(internalUsers) || invoicePreviewEnabled(auth), isAasUser });
        }

        case 'claimTable': {
          const serviceRequestSelectEnum = getServiceRequestStatusOptions({ auth });
          return getAasClaimsColumns(serviceRequestSelectEnum);
        }

        default:
          return [];
      }
    }

    if (tableId) {
      const entity = enabledViewsTableIdToEntityMap[tableId];

      if (!localCache[entity]) {
        const columns = getIdsFromColumnDefinition(entity);
        localCache[entity] = columns.map((column) => column.id) || [];

        setValidIds((currentValidIds) => [...currentValidIds, ...localCache[entity]]);
      } else {
        setValidIds(localCache[entity]);
      }
    }
  }, [tableId, userCan, auth, clientOrganizationType, determineFeatureFlagVisibility]);

  return validIds;
}
