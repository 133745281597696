import React, { Dispatch, SetStateAction } from 'react';
import camelCase from 'lodash/camelCase';
import { EntityLabelEnum, patchInvoice, patchPayable, patchServiceRequest } from '@equips/entities-schema';
import { useParams } from 'react-router-dom';
import { getAasOrganizationId } from '../../functions/aasHelpers';
import MultipleSelectChip from '../Chips/MultipleSelectChip';
import { onEscapeKeyDown } from '../../functions/onEscapeKeyDown';
import Input from '../Form/Input';
import MultiSelect from '../Selects/MultiSelect';
import Checkbox from '../Form/Checkbox';
import CurrencyInput from '../Form/CurrencyInput';
import InvoiceSelectDialog from '../Selects/InvoiceSelectDialog';
import UserSelectDialog from '../Selects/UserSelectDialog';
import StyledSelect from '../Selects/StyledSelect';
import DateInput from '../Date/DateInput';
import AdditionalContactsContainer from '../ServiceRequest/AdditionalContactsContainer';
import ClientTagsMultiSelect from '../Selects/ClientTagsMultiSelect';
import { useAuth } from '../../auth/AuthContext';
import Textarea from '../Form/Textarea';
import DateTimeInput from '../Date/DateTimeInput';
import UserTagsSelect from '../Selects/UserTagsSelect';
import { MainContactLocationSelect } from '../Selects/MainContactLocationSelect';
import { descriptionListInputTypes } from './DisplayDescriptionValue';

type InputTypeProps = {
  organizationId?: string;
  inputValue: any;
  setInputValue: (...args: any[]) => any;
  setTagValue: Dispatch<SetStateAction<string | null>>;
  handleSave: (...args: any[]) => any;
  setState: (...args: any[]) => any;
  label: string;
  inputType: descriptionListInputTypes;
  entityLabel?: EntityLabelEnum;
  entityId?: string;
  fieldToPatch?: string;
  [x: string]: any;
  userId?: string;
};

export default function InputType({
  inputType = descriptionListInputTypes.input,
  inputValue,
  setInputValue,
  setTagValue,
  setState,
  label,
  handleSave,
  organizationId = '',
  options = [],
  required,
  displayValueStates,
  entityLabel,
  entityId,
  fieldToPatch,
  userId,
  ...restOfProps
}: InputTypeProps) {
  const { userCan, internalUsers } = useAuth();
  const params = useParams<{ locationId: string }>();
  const isAasLocation = organizationId === getAasOrganizationId();

  if (descriptionListInputTypes.currency === inputType) {
    return (
      <CurrencyInput
        tiny
        discardFormGroup
        discardLabel
        id="descriptionListCurrency"
        cents={inputValue}
        handleCents={(value) => setInputValue(value)}
        required={required}
        autoFocus
      />
    );
  }

  if (descriptionListInputTypes.chipMultiSelect === inputType) {
    let selectedValues = {};

    if (Array.isArray(inputValue)) {
      inputValue.forEach((fix) => {
        if (fix) selectedValues[fix] = true;
      });
    }

    return (
      <MultipleSelectChip
        chipTestId={`${camelCase(label)}Option`}
        chipSetType="filter"
        setSelected={(values) => {
          if (!values) {
            setInputValue(null);
            return;
          }

          let valuesArr = [] as any[];

          for (const [key, value] of Object.entries(values)) {
            if (value) {
              valuesArr.push(key);
            }
          }
          setInputValue(valuesArr.length > 0 ? valuesArr : null);
        }}
        selected={selectedValues}
        options={options}
      />
    );
  }

  if (descriptionListInputTypes.date === inputType) {
    return (
      <DateInput
        id="descriptionListDate"
        value={inputValue}
        required={required}
        onChange={(event) => setInputValue(event.target.value)}
        autoFocus
        {...restOfProps}
      />
    );
  }

  if (descriptionListInputTypes.datetime === inputType) {
    return (
      <DateTimeInput
        id="descriptionListDateTime"
        value={inputValue}
        required={required}
        onChange={(value) => setInputValue(value)}
        autoFocus
        {...restOfProps}
      />
    );
  }

  if (descriptionListInputTypes.checkbox === inputType) {
    return (
      <Checkbox id="descriptionListCheckbox" size="small" checked={inputValue} onChange={(event) => setInputValue(event.target.checked)} />
    );
  }

  if (descriptionListInputTypes.userSelect === inputType) {
    return (
      <UserSelectDialog
        organizationId={organizationId}
        handleSelect={({ userId }) => handleSave(userId)}
        open
        handleClose={() => setState(displayValueStates.display)}
        context={restOfProps?.context}
        showUnassigned
      />
    );
  }
  if (descriptionListInputTypes.mainContactSelect === inputType) {
    return <MainContactLocationSelect locationId={params?.locationId} organizationId={organizationId} handleSave={handleSave} />;
  }

  if (descriptionListInputTypes.inlineUserSelect === inputType) {
    return (
      <UserSelectDialog
        organizationId={organizationId}
        handleSelect={async ({ userId }) => {
          if (entityId) {
            if (entityLabel === EntityLabelEnum.Payable) {
              await patchPayable({
                payableId: entityId,
                //@ts-expect-error dynamic field name patching
                metadata: { [fieldToPatch]: userId },
              });
            } else if (entityLabel === EntityLabelEnum.Invoice) {
              await patchInvoice({
                invoiceId: entityId,
                //@ts-expect-error dynamic field name patching
                metadata: { [fieldToPatch]: userId },
              });
            } else {
              await patchServiceRequest({
                serviceRequestId: entityId,
                //@ts-expect-error dynamic field name patching
                metadata: { [fieldToPatch]: userId },
              });
            }
            handleSave();
          } else {
            console.error('Attepted inline user patch without entityid');
          }
        }}
        open
        handleClose={() => setState(displayValueStates.display)}
        context={restOfProps?.context}
        showUnassigned
      />
    );
  }

  if (descriptionListInputTypes.inlineUserTagsMultiSelect === inputType) {
    return (
      <UserTagsSelect
        organizationId={organizationId}
        userId={userId}
        inlineEdit={true}
        onChange={(selections) => {
          setInputValue(selections && selections.length > 0 ? selections : undefined);
        }}
        onDefaultTagChange={(tagId) => {
          setTagValue(tagId);
        }}
      />
    );
  }

  if (descriptionListInputTypes.multiSelectWithOptions === inputType) {
    return (
      <MultiSelect
        label=""
        id={label}
        options={options}
        fullWidth
        selectedValues={inputValue}
        handleChange={(values) => setInputValue(values ? values : null)}
        {...restOfProps}
      />
    );
  }

  if (descriptionListInputTypes.select === inputType) {
    return (
      <StyledSelect
        id={`${label}EditableInput`}
        onChange={({ target: { value } }) => setInputValue(value)}
        value={inputValue ?? undefined}
        {...restOfProps}
      >
        {options.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </StyledSelect>
    );
  }

  if (descriptionListInputTypes.warning === inputType) {
    return <p>{restOfProps?.warningText || 'Are you sure?'}</p>;
  }

  if (descriptionListInputTypes.contactSelect === inputType) {
    return (
      <AdditionalContactsContainer
        organizationId={organizationId}
        contacts={inputValue}
        setContactString={(value) => setInputValue(value)}
        disablePrimaryEdit={isAasLocation && !userCan(internalUsers)}
        {...restOfProps}
      />
    );
  }

  if (descriptionListInputTypes.clientTagsMultiSelect === inputType) {
    return (
      <ClientTagsMultiSelect
        {...restOfProps}
        fullWidth
        isClearable
        label={label}
        key={label}
        id={label}
        data-testid={`inputType-${label}`}
        organizationId={organizationId}
        entityLabel={entityLabel as EntityLabelEnum}
        value={inputValue}
        setValue={(selections) => {
          setInputValue(selections && selections.length > 0 ? selections : undefined);
        }}
      />
    );
  }

  if (descriptionListInputTypes.invoiceSelect === inputType) {
    return (
      <InvoiceSelectDialog
        handleSelect={({ invoiceId }) => handleSave(invoiceId)}
        open
        handleClose={() => setState(displayValueStates.display)}
        showUnassigned
      />
    );
  }

  if (descriptionListInputTypes.textarea === inputType) {
    return (
      <Textarea value={inputValue} id={`${label}EditableInput`} onChange={(event) => setInputValue(event.target.value)} {...restOfProps} />
    );
  }

  return (
    <Input
      autoFocus
      tiny
      value={inputValue}
      id={`${label}EditableInput`}
      onChange={(event) => setInputValue(event.target.value)}
      {...onEscapeKeyDown(() => setState(displayValueStates.display))}
      type={inputType}
      required={required}
      {...restOfProps}
    />
  );
}
