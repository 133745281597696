import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const FormContainer = ({ children }) => {
  return <div className="flex flex-wrap justify-between w-full my-1">{children}</div>;
};
FormContainer.propTypes = { children: PropTypes.node.isRequired };

export default function FormGroup({ fullWidth = false, oneThird = false, children, className = '', ...restOfProps }) {
  return (
    <fieldset
      className={classNames({
        'w-full py-2': true,
        [`md:w-1/${oneThird ? '3' : '2'} px-1 -mx-1`]: !fullWidth,
        [className]: true,
      })}
      {...restOfProps}
    >
      {children}
    </fieldset>
  );
}
FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  oneThird: PropTypes.bool,
  className: PropTypes.string,
};
