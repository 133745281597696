import { RecurrenceOptionsType } from '@equips/entities-schema';
import { msToTime } from '../../../common/functions/dateFunctions';

type DowntimeArgs = {
  closedAt?: number | null;
  completedAt?: number | null;
  createdAt?: number | null;
  recurrenceOption?: RecurrenceOptionsType | null;
  ignoreUntil?: number | null;
};

export const getDowntime = ({ closedAt, completedAt, createdAt, recurrenceOption, ignoreUntil }: DowntimeArgs): number => {
  const isReccuringSr = recurrenceOption === RecurrenceOptionsType.Monthly || recurrenceOption === RecurrenceOptionsType.Custom;

  if (!createdAt) {
    return 0;
  }

  if (isReccuringSr && !ignoreUntil) {
    return 0;
  }

  const endAt = closedAt && completedAt ? (closedAt > completedAt ? closedAt : completedAt) : closedAt || completedAt;

  if (isReccuringSr) {
    //@ts-expect-error conditional above should protect against this
    return (endAt || Date.now()) - ignoreUntil;
  } else {
    return (endAt || Date.now()) - createdAt;
  }
};

export const isDowntimeInProgress = ({ closedAt, completedAt }: DowntimeArgs): boolean => {
  return !closedAt && !completedAt;
};

export const getDowntimeString = (args: DowntimeArgs): string => {
  const value = getDowntime(args);

  return value ? msToTime(value) : '';
};
