import React from 'react';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Close from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { ClientOrganizationEnumType, OrganizationFeatureFlagEnum } from '@equips/entities-schema';
import Thumbnail from '../Thumbnail/Thumbnail';
import { useAuth } from '../../auth/AuthContext';
import { CreateInvoiceModal } from './CreateInvoiceModal';

interface NoteAttachmentProps {
  location?: string;
  name: string;
  type: string;
  parentId?: string;
  parentLabel?: string;
  createdAt?: number;
  onRemove?(): any;
  onClick?(): any;
  url?: string;
}

export default function NoteAttachment({
  location = '',
  name,
  type: fullType,
  parentId,
  parentLabel,
  createdAt,
  onRemove,
  onClick,
  url,
}: NoteAttachmentProps) {
  const type = fullType.split('/').pop() || name.split('.').pop() || 'file';
  const { serviceRequestId } = useParams<{ serviceRequestId: string }>();
  const { auth, isAasUser, clientOrganizationType, internalUsers, determineFeatureFlagVisibility } = useAuth();
  const isCoverageInternalUser =
    (clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage &&
      auth?.authorizationRole &&
      internalUsers.includes(auth?.authorizationRole)) ||
    false;

  return (
    <li className="white-box truncate text-sm hover:border-gray-400" title={name} style={{ width: '32%', maxWidth: 300 }}>
      <div className="flex items-center">
        <button
          type="button"
          className="flex w-full items-center truncate px-4 py-2 text-left text-gray-800"
          onClick={() => {
            ['file', 'pdf'].includes(type.toLowerCase()) ? window.open(url, '_blank') : onClick?.();
          }}
        >
          {['file', 'pdf'].includes(type.toLowerCase()) ? (
            <InsertDriveFile className="text-blue-700"></InsertDriveFile>
          ) : (
            <Thumbnail location={location} name={name} type={type} />
          )}
          <div className="ml-3 w-full truncate font-medium">
            <div className="truncate">{name}</div>
            <div className="-mt-1 text-xs text-gray-600">
              <span className="uppercase">{type.split('/').pop() ?? 'file'}</span>
            </div>
          </div>
          {onRemove && (
            <button type="button" className="right-10 transform cursor-pointer p-1 hover:text-gray-500" onClick={onRemove}>
              <Close></Close>
            </button>
          )}
        </button>
        {/* Show create a new invoice button if there is a note attachment and it's on sr/claim page */}
        {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceFromNoteAttachment) &&
          location &&
          serviceRequestId &&
          (isAasUser || isCoverageInternalUser) && (
            <CreateInvoiceModal
              createdAt={createdAt}
              location={location}
              parentId={parentId}
              parentLabel={parentLabel}
              name={name}
              serviceRequestId={serviceRequestId}
            />
          )}
      </div>
    </li>
  );
}
