import React, { useEffect, useState } from 'react';
import {
  currentTimestamp,
  dateIsAfter,
  dateIsSameOrBefore,
  isValidDateOrTimeStamp,
  relativeTimeToTimestamp,
} from '../../functions/dateFunctions';

enum TimestampStatusType {
  None = 'None',
  Upcoming = 'Upcoming',
  Overdue = 'Overdue',
}

interface TableCellRelativeTimeProps {
  timestamp: number;
}

function TableCellRelativeTime({ timestamp }: TableCellRelativeTimeProps) {
  const [status, setStatus] = useState(TimestampStatusType.None);
  const [currentTime, setCurrentTime] = useState(currentTimestamp());

  const isValid = isValidDateOrTimeStamp(timestamp);

  useEffect(() => {
    if (!isValid) return;

    const timer = setTimeout(() => setCurrentTime(currentTimestamp()), 1000 * 60);

    if (dateIsAfter(currentTime, timestamp)) {
      setStatus(TimestampStatusType.Overdue);
    } else if (dateIsSameOrBefore(currentTime, timestamp)) {
      setStatus(TimestampStatusType.Upcoming);
    } else {
      setStatus(TimestampStatusType.None);
    }

    return () => clearTimeout(timer);
  }, [currentTime]);

  return (
    <>
      {isValid ? (
        <div className={status === TimestampStatusType.Overdue ? 'text-red-600' : ''}>{`${status} (${relativeTimeToTimestamp(
          timestamp,
        )})`}</div>
      ) : null}
    </>
  );
}

export { TableCellRelativeTime };
