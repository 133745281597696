import React from 'react';
import { queryAttachments } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import placeholder from '../../../images/placeholder.png';

interface ImageDisplayProps extends React.SelectHTMLAttributes<HTMLImageElement> {
  attachmentId?: string;
  url?: string;
  altText?: string;
  showPlaceholder?: boolean;
}

export default function ImageDisplay({ attachmentId, url, altText, showPlaceholder, ...restOfProps }: ImageDisplayProps) {
  const { data, isFetching } = useQuery(['ImageDisplay', attachmentId], () => queryAttachments({ attachmentId }), {
    enabled: !!attachmentId,
  });

  const attachment = data?.data?.attachments?.data?.[0]?.metadata;

  if (isFetching) {
    return <></>;
  }

  return attachment?.name?.endsWith('.pdf') ? (
    <object
      data={attachment?.presignedGetUrl || url}
      type="application/pdf"
      width={restOfProps?.style?.width || '100%'}
      height={restOfProps?.style?.height || '500px'}
    >
      PDF
    </object>
  ) : (
    <img
      src={attachment?.presignedGetUrl || url || (showPlaceholder ? placeholder : '')}
      alt={altText}
      className="rounded-lg"
      {...restOfProps}
    />
  );
}
