import { PayableStatus, PayeeType, PaymentStatus, PaymentType } from '@equips/entities-schema';

export const payableStatusPriority = [
  PayableStatus.Inactive,
  PayableStatus.Cancelled,
  PayableStatus.Denied,
  PayableStatus.Authorized,
  PayableStatus.Paid,
];

export const paymentStatusPriority = [
  PaymentStatus.Expired,
  PaymentStatus.Void,
  PaymentStatus.Failed,
  PaymentStatus.Exception,
  PaymentStatus.Approved,
  PaymentStatus.Paid,
];

export const payableStatusEnum = {
  [PayableStatus.Authorized]: { value: PayableStatus.Authorized, name: 'Authorized' },
  [PayableStatus.Cancelled]: { value: PayableStatus.Cancelled, name: 'Cancelled' },
  [PayableStatus.Denied]: { value: PayableStatus.Denied, name: 'Denied' },
  [PayableStatus.Inactive]: { value: PayableStatus.Inactive, name: 'Inactive' },
  [PayableStatus.Paid]: { value: PayableStatus.Paid, name: 'Paid' },
};

export const paymentStatusEnum = {
  [PaymentStatus.Approved]: { value: PaymentStatus.Approved, name: 'Approved' },
  [PaymentStatus.Exception]: { value: PaymentStatus.Exception, name: 'Exception' },
  [PaymentStatus.Expired]: { value: PaymentStatus.Expired, name: 'Expired' },
  [PaymentStatus.Failed]: { value: PaymentStatus.Failed, name: 'Failed' },
  [PaymentStatus.Paid]: { value: PaymentStatus.Paid, name: 'Paid' },
  [PaymentStatus.Void]: { value: PaymentStatus.Void, name: 'Void' },
};

export const paymentTypeEnum = {
  [PaymentType.Check]: { value: PaymentType.Check, name: 'Check' },
  [PaymentType.Wex]: { value: PaymentType.Wex, name: 'Wex' },
  [PaymentType.DirectDeposit]: { value: PaymentType.DirectDeposit, name: 'Direct Deposit' },
};

export const payeeTypeEnum = {
  [PayeeType.Customer]: { value: PayeeType.Customer, name: 'Customer' },
  [PayeeType.ServiceProvider]: { value: PayeeType.ServiceProvider, name: 'Service Provider' },
  [PayeeType.Supplier]: { value: PayeeType.Supplier, name: 'Supplier' },
  [PayeeType.Seller]: { value: PayeeType.Seller, name: 'Seller' },
};
