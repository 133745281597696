import React, { useState } from 'react';
import { Maybe, File, queryFiles, SortByElasticSearchOptions, FileTagsEnum } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import useHead from '../../../common/hooks/useHead';
import LoaderCards from '../../../common/components/Loaders/LoaderCards';
import { toDateStringFromUnixMillisecondTimestamp } from '../../../common/functions/dateFunctions';

const size = 10;

export default function ProgramFeeReport() {
  useHead('Program fee report');

  const [page, setPage] = useState(0);
  const [files, setFiles] = useState<Maybe<File>[]>([]);

  const { data, isLoading } = useQuery(
    ['ItemLossRatio'],
    () =>
      queryFiles({
        search: {
          size,
          from: page * size,
          sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
          filters: [{ metadata: { tags: [{ keyword: [{ terms: [FileTagsEnum.ProgramFeeReport] }] }] } }],
        },
      }),
    {
      onSuccess: (d) => setFiles((state) => [...state, ...(d?.data?.files?.data || [])]),
    },
  );

  const totalCount = data?.data?.files?.totalCount || 0;

  const loadMore = () => setPage((state) => state + 1);

  return (
    <div data-testid="programFeeReport">
      <div>
        <h2 className="text-2xl font-semibold text-gray-700">Recent files</h2>
        {isLoading && <LoaderCards />}
        {!isLoading && files.length === 0 && <div>No files found</div>}
        {files.map((file) => (
          <div key={file?.metadata?.fileId || ''}>
            <p>
              <a href={`${file?.metadata?.presignedGetUrl || ''}`} target="_blank" rel="noopener noreferrer">
                {file?.metadata?.fileName || `Items file created on ${toDateStringFromUnixMillisecondTimestamp(file?.metadata?.createdAt)}`}
              </a>
            </p>
          </div>
        ))}
        {page * size < totalCount && (
          <button className="text-sm font-semibold text-blue-500 hover:text-blue-600" onClick={() => loadMore()} type="button">
            Load more
          </button>
        )}
      </div>
    </div>
  );
}
