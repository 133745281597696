import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Invoice, InvoiceStatusEnumType, patchInvoice } from '@equips/entities-schema';
// import { getEquipmentClassNameById } from '@equips/equipment-classes';
import { TableColumn } from '../../common/components/DataTable';
import { toDateStringFromUnixMillisecondTimestamp, toUnixMillisecondsFromString } from '../../common/functions/dateFunctions';
import { TableFilterTypes } from '../../common/components/Table/Filters';
import { clientValueForFilter, providerValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import Urls from '../../routes/Urls';
import DropDown from '../../common/components/DropDown';
import statesAsObject from '../../common/constants/statesAsObject';
import TableCellInlineEditable from '../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../common/components/ViewPage/DisplayDescriptionValue';
import { defaultColumnWidths } from '../../common/components/DataTable/columns';
import { AuthContextValue } from '../../common/auth/AuthContext';
import { internalUsers } from '../../common/auth/roles';
import { InvoiceDisplayStatusTag } from './PaymentColumns';

export default function LegacyPaymentsScreenColumns(userCan: AuthContextValue['userCan']): TableColumn<Invoice>[] {
  return [
    {
      id: 'metadata.shortId',
      Header: 'Number',
      accessor: (data) => data.metadata?.shortId,
      Cell: (data) => {
        const invoiceId = data?.row?.original?.metadata?.invoiceId;

        return userCan(internalUsers) ? (
          <div className="flex justify-between">
            <Link
              className="active font-bold"
              data-testid="toInvoiceFromTable"
              target="_blank"
              rel="noopener noreferrer"
              to={`${Urls.INVOICES}/${invoiceId}`}
            >
              {data.value}
            </Link>
            <DropDown
              isSmall
              containerClasses="more-actions"
              actions={[
                {
                  'data-invoiceid': invoiceId,
                  'data-testid': 'viewChargesLink',
                  Component: <>View charges</>,
                  to: `${Urls.CHARGES}/?invoiceId=${invoiceId}`,
                },
              ]}
            />
          </div>
        ) : (
          <>{data.value}</>
        );
      },
    },
    {
      id: 'metadata.denialInvoiceFileId',
      Header: 'Out of scope?',
      accessor: (data) => data?.metadata?.denialInvoiceFileId,
      Cell: (data) => (data?.value ? 'Yes' : 'No'),
      filterOptions: {
        label: 'Out of scope?',
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
    },
    {
      id: 'metadata.denialInvoiceFile',
      Header: 'Out of scope invoice',
      accessor: (data) => data?.metadata?.denialInvoiceFile?.metadata?.presignedGetUrl,
      Cell: (data) => {
        if (data.value) {
          return (
            <a className="active" href={data.value} target="_blank" rel="noopener noreferrer">
              View invoice
            </a>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      id: 'metadata.denialInvoiceFile.metadata.explanation',
      Header: 'Explanation',
      accessor: (data) => data?.metadata?.denialInvoiceFile?.metadata?.metadata,
      Cell: (data) => {
        // Yes we need this apparently
        if (data.value === 'null') {
          return 'N/A';
        }
        if (!data.value) {
          return 'N/A';
        }
        const metadata = JSON.parse(data.value || '');
        return metadata.explanation ? metadata.explanation : 'N/A';
      },
    },
    {
      id: 'metadata.denialInvoiceFile.metadata.outstandingBalance',
      Header: 'Outstanding balance',
      accessor: (data) => data?.metadata?.denialInvoiceFile?.metadata?.metadata,
      Cell: (data) => {
        if (data.value === 'null' || data.row?.original?.metadata?.invoiceStatus !== InvoiceStatusEnumType.Finalized) return 'Under Review';
        if (!data.value) {
          return "We've got you covered";
        }
        const metadata = JSON.parse(data.value);
        return metadata.outstandingBalance ? metadata.outstandingBalance : "We've got you covered";
      },
    },
    {
      id: 'metadata.denialInvoicePaid',
      Header: 'Paid?',
      accessor: (data) => data.metadata?.denialInvoicePaidAt,
      Cell: (data) => {
        if (data.row?.original?.metadata?.invoiceStatus !== InvoiceStatusEnumType.Finalized) return 'Under Review';
        else {
          return data.value ? 'Yes' : data?.row?.original?.metadata?.denialInvoiceFileId ? 'No' : "We've got you covered";
        }
      },
      filterOptions: {
        label: 'Out of scope paid?',
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
    },
    {
      id: 'metadata.denialInvoicePaidAt',
      Header: 'Paid At',
      accessor: (data) => data.metadata?.denialInvoicePaidAt,
      width: defaultColumnWidths.mediumText,
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={toDateStringFromUnixMillisecondTimestamp(data?.value) ?? 'None'}
            formatter={(value) => {
              if (data.row?.original?.metadata?.invoiceStatus !== InvoiceStatusEnumType.Finalized) return 'Under Review';
              return value
                ? dayjs(value).format('MM/DD/YYYY')
                : data?.row?.original?.metadata?.denialInvoiceFileId
                ? 'Not paid'
                : "We've got you covered";
            }}
            inputType={descriptionListInputTypes.date}
            onSave={(value) =>
              patchInvoice({
                invoiceId: data.row?.original?.metadata?.invoiceId,
                metadata: { denialInvoicePaidAt: toUnixMillisecondsFromString(value) },
              })
            }
          />
        );
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    ...(userCan(internalUsers)
      ? [
          {
            id: 'metadata.clientId',
            Header: 'Client',
            accessor: (data) => data.metadata?.clientOrganizationId,
            Cell: (data) => data.row.original?.metadata?.clientOrganization?.metadata?.organizationName || '',
            toText: (data) => data.row.original?.metadata?.clientOrganization?.metadata?.organizationName || '',
            filterOptions: {
              type: TableFilterTypes.organizationSelect,
              hidden: false,
              typeOfOrganizationToFind: clientValueForFilter,
            },
          },
        ]
      : []),
    {
      id: 'metadata.providerId',
      Header: 'Provider',
      accessor: (data) => data.metadata?.providerOrganizationId,
      Cell: (data) => data.row.original?.metadata?.providerOrganization?.metadata?.organizationName || '',
      toText: (data) => data.row.original?.metadata?.providerOrganization?.metadata?.organizationName || '',
      filterOptions: {
        type: TableFilterTypes.organizationSelect,
        hidden: false,
        typeOfOrganizationToFind: providerValueForFilter,
      },
    },
    {
      id: 'metadata.externalInvoiceNummber',
      Header: 'External invoice number',
      accessor: (data) => data.metadata?.externalInvoiceNumber,
    },
    {
      id: 'metadata.externalServiceNumber',
      Header: 'External service number',
      accessor: (data) => data.metadata?.externalServiceNumber,
    },
    {
      id: 'metadata.serviceRequestNumber',
      Header: 'Service request number',
      accessor: (data) => data.metadata?.serviceRequestNumber,
      Cell: (data) => {
        return (
          <div className="flex justify-between">
            <Link
              className="active font-bold"
              data-testid="equipmentEditButton"
              to={`${Urls.SERVICE_REQUESTS}/${data.row.original?.metadata?.serviceRequests?.[0]?.metadata?.serviceRequestId}`}
            >
              {data?.value}
            </Link>
          </div>
        );
      },
    },
    {
      id: 'metadata.externalInvoiceDate',
      Header: 'External invoice date',
      accessor: (data) => data.metadata?.externalInvoiceDate,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data.value),
    },
    {
      id: 'metadata.externalServiceDate',
      Header: 'External service date',
      accessor: (data) => data.metadata?.externalServiceDate,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data.value),
    },
    {
      id: 'metadata.invoiceDisplayStatus',
      Header: 'Payment Status',
      accessor: (data) => data.metadata?.invoiceDisplayStatus,
      Cell: (data) => <InvoiceDisplayStatusTag status={data?.value} />,
      disableSortBy: true,
      width: 175,
    },
    {
      id: 'equipment.equipmentName',
      Header: 'Equipment name',
      accessor: (data) => data.equipment?.[0]?.metadata?.equipmentName,
    },
    {
      id: 'equipment.shortId',
      Header: 'Equipment',
      accessor: (data) => data.equipment?.[0]?.metadata?.shortId,
      Cell: (data) => (
        <div className="flex justify-between">
          <Link
            className="active font-bold"
            data-testid="equipmentEditButton"
            to={`${Urls.EQUIPMENT}/${data.row.original?.equipment?.[0]?.equipmentId}`}
          >
            {data?.value}
          </Link>
        </div>
      ),
    },
    {
      id: 'specMetadata.categoryId',
      Header: 'Category',
      accessor: (data) => data.equipment?.[0]?.specMetadata?.category?.metadata?.name || 'N/A',
      width: defaultColumnWidths.normalText,
    },
    {
      id: 'equipment.location.address.line1',
      Header: 'Equipment address',
      accessor: (data) => data.equipment?.[0]?.metadata?.location?.address?.line1,
    },
    {
      id: 'equipment.location.address.city',
      Header: 'City',
      accessor: (data) => data.equipment?.[0]?.metadata?.location?.address?.city,
      disableSortBy: true,
    },
    {
      id: 'equipment.location.address.stateUnabbreviated',
      Header: 'State',
      accessor: (data) => data.equipment?.[0]?.metadata?.location?.address?.stateUnabbreviated,
      disableSortBy: true,
      Cell: (data) => (data?.value && statesAsObject[data?.value]) || 'NA',
    },
    {
      id: 'equipment.location.address.zip',
      Header: 'Zip',
      accessor: (data) => data.equipment?.[0]?.metadata?.location?.address?.zip,
      disableSortBy: true,
    },
    {
      id: 'invoice.notificationSentAt',
      Header: 'Notification sent at',
      accessor: (data) => data?.metadata?.legacyPaymentNotificationSentAt,
      disableSortBy: true,
    },
  ];
}
