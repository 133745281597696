import React from 'react';
import { API } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import Image from '@mui/icons-material/Image';

type ThumbnailProps = {
  /** Location of thumbnail in S3 */
  location: string;
  /** Image type */
  type: string;
  /** Name of the image for alt text */
  name: string;
  /** Width and height, defaults to 50 */
  w?: number;
  h?: number;
};

const Thumbnail = (props: ThumbnailProps) => {
  const { location, type, name, w = 50, h = 50 } = props;

  // Format location that contain white spaces. Can't find file with white spaces in name
  const formattedLocation = encodeURIComponent(location?.trim());

  const { data: thumbnailSrc } = useQuery(
    ['thumbnail', location, w, h],
    () =>
      API.get('equips-app', `/thumb?location=${formattedLocation}&type=${type}&w=${w}}&h=${h}`, {
        response: true,
      }),
    {
      enabled: !!location && !!type,
      select: (res) => {
        return `data:image/${type};base64,` + res?.data;
      },
    },
  );

  if (!location) return <Image className="text-blue-700" />;

  return <img src={thumbnailSrc} alt={`${name}-thumbnail`} />;
};

export default Thumbnail;
