import React from 'react';
import NumberFormat from 'react-number-format';
import { moneyToCents } from '../../functions/moneyFunctions';
import ReactSelectRequiredInput from '../Selects/ReactSelectRequiredInput';
import FormGroup from './FormGroup';
import Label from './Label';
import Input from './Input';
import InputLoader from './InputLoader';

interface CurrencyInputProps {
  required?: boolean;
  labelHelper?: string;
  cents: number;
  id: string;
  handleCents: (cents: number) => any;
  label?: string | React.ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
  isWhite?: boolean;
  discardFormGroup?: boolean;
  discardLabel?: boolean;
}

export default function CurrencyInput({
  required = false,
  discardFormGroup = false,
  discardLabel = false,
  cents,
  handleCents,
  label = 'Amount',
  loading = false,
  fullWidth = false,
  labelHelper = '',
  id,
  isWhite = false,
  ...restOfProps
}: CurrencyInputProps) {
  const convertInputToCents = (event) => {
    const dirtyInput = event.target.value;

    handleCents(moneyToCents(dirtyInput) || 0);
  };

  if (loading) return <InputLoader />;

  function InputInners() {
    return (
      <div className="relative">
        {!discardLabel && <Label label={label} id={id} helper={labelHelper} required={required} />}
        <NumberFormat
          customInput={Input}
          isWhite={isWhite}
          id={id}
          data-testid={id}
          value={cents / 100}
          onChange={convertInputToCents}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={true}
          prefix={'$'}
          {...restOfProps}
        />
        {required && <ReactSelectRequiredInput isValid={cents > 0} />}
      </div>
    );
  }

  if (discardFormGroup) return InputInners();

  return <FormGroup fullWidth={fullWidth}>{InputInners()}</FormGroup>;
}
