import { Category, ElasticsearchSearchTypesEnum, Maybe, queryCategories, SortByElasticSearchOptions } from '@equips/entities-schema';
import { TableFetch } from '../../common/components/DataTable';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';

const categoryFields = `
  categoryId
  metadata {
    organizationId
    categoryId
    shortId
    name
  }
`;

export const categoryGraphQlNode = `category {${categoryFields}}`;

export const getCategoriesForMainTable: TableFetch<Maybe<Category>> = async ({
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}) => {
  const query = `
  metadata {
    categoryId
    name
    symptoms
    shortId
    createdAt
    createdByUser {
      metadata {
        fullName
      }
    }
    modifiedAt
    modifiedByUser {
      metadata {
        fullName
      }
    }
    deactivatedAt
    deactivatedByUser {
      metadata {
        fullName
      }
    }
  }
`;

  const { data } = await queryCategories(
    {
      search: {
        q: globalFilter,
        size: pageSize,
        from: pageSize * pageIndex,
        searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
        sortBy: transformTableSortToGraphQLSort(sortBy),
        includeTotalCount,
        filters: [
          {
            metadata: {
              ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              ...(filterMap['metadata.createdByUserId']
                ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
                : {}),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              ...(filterMap['metadata.modifiedByUserId']
                ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
                : {}),
            },
          },
        ],
      },
    },
    { query },
  );

  return { totalCount: data?.categories?.totalCount, data: data?.categories?.data ?? [] };
};

export const getCategories = async ({
  organizationId,
  showUsedCategoriesOnly = true,
  showUsedSubcategoriesOnly = true,
}: {
  organizationId?: string | null;
  showUsedCategoriesOnly?: boolean;
  showUsedSubcategoriesOnly?: boolean;
}) =>
  queryCategories(
    {
      organizationId,
      search: {
        sortBy: [{ metadata: { name: { keyword: SortByElasticSearchOptions.Asc } } }],
        showUsedCategoriesOnly,
      },
    },
    {
      query: `
      ${categoryFields}
      metadata {
        subcategories(showUsedSubcategoriesOnly:${showUsedSubcategoriesOnly}){
          ${categoryFields}
          metadata {
            subcategories(showUsedSubcategoriesOnly:${showUsedSubcategoriesOnly}){
              ${categoryFields}
            }
          }
        }
      }
    `,
    },
  );

export const getCategory = async ({ categoryId, organizationId }: { categoryId: string; organizationId: string | null }) =>
  queryCategories(
    {
      categoryId,
      organizationId,
    },
    {
      query: `
      metadata {
        organizationId
        categoryId
        name
        symptoms
        shortId
        parentCategoryId
        subcategories {
          ${categoryFields}
        }
        createdAt
        createdByUser {
          metadata {
            fullName
          }
        }
        modifiedAt
        modifiedByUser {
          metadata {
            fullName
          }
        }
        deactivatedAt
        deactivatedByUser {
          metadata {
            fullName
          }
        }
      }
    `,
    },
  );
