import React, { useMemo, useState } from 'react';
import { Equipment, Maybe } from '@equips/entities-schema';
import capitalCase from 'lodash/capitalize';
import Close from '@mui/icons-material/Close';
import Label from '../Form/Label';
import { entityIcons } from '../../../graphql/entityIcons';
import Input from '../Form/Input';
import QRCodeIcon from '../../../images/qrcode.svg';
import EquipmentClassSimpleSelect from './EquipmentClassSimpleSelect';

const Icon = entityIcons.equipment;

type DisplayEquipmentType = {
  item?: Equipment;
  handleSelect: (string) => void;
  text?: string;
};

export function DisplaySelectableEquipment({ item, handleSelect, text }: DisplayEquipmentType) {
  const equipmentId = item?.metadata?.equipmentId;
  const equipmentName = item?.metadata?.equipmentName;
  const shortId = item?.metadata?.shortId;
  const model = item?.specMetadata?.model;
  const manufacturer = item?.specMetadata?.manufacturer;
  const serialNumber = item?.metadata?.serialNumber;
  const equipmentStatus = item?.metadata?.equipmentStatus;
  const tid = item?.metadata?.tid;
  const qrCodeId = item?.metadata?.qrCodeId;

  return (
    <button
      data-testid="serviceRequestEquipmentSelect"
      data-equipmentid={equipmentId}
      className="my-2 flex w-full items-center space-x-2 rounded-lg border border-gray-400 px-4 py-2 text-left text-gray-800 hover:bg-gray-50"
      type="button"
      onClick={() => handleSelect(equipmentId)}
    >
      <div className="pr-2 text-gray-600">
        <Icon color="inherit" />
      </div>
      <div className="flex w-full flex-wrap">
        {text ? (
          text
        ) : (
          <>
            <div className="w-full">
              {equipmentName}
              {shortId ? `- ${shortId}` : ''}
            </div>
            <div className="w-full text-xs">
              {tid && (
                <span className="pr-2">
                  <span className="font-semibold text-gray-700">TID:</span> {tid}
                </span>
              )}
              {model && (
                <span className="pr-2">
                  <span className="font-semibold text-gray-700">Model:</span> {model}
                </span>
              )}
              {manufacturer && (
                <span className="pr-2">
                  <span className="font-semibold text-gray-700">Manufacturer:</span> {manufacturer}
                </span>
              )}
              {serialNumber && (
                <span className="pr-2">
                  <span className="font-semibold text-gray-700">Serial number:</span> {serialNumber}
                </span>
              )}

              <span className="pr-2">
                <span className="font-semibold text-gray-700">Status:</span> {capitalCase(equipmentStatus ?? '')}
              </span>
            </div>
          </>
        )}
      </div>
      {!!qrCodeId && <img src={QRCodeIcon} alt="QR Code Logo" className="text-gray-400" style={{ height: 25, width: 25 }} />}
    </button>
  );
}

interface EquipmentSelectionsProps {
  equipment: Maybe<Equipment>[];
  label: string;
  handleSelect: (equipmentId: string) => void;
  id?: string;
  /** The selected equipmentId. */
  /** Whether the component should output and "any" option. If empty, no option is shown. */
  loading?: boolean;
}

export default function EquipmentSelection({ equipment, label, handleSelect, id, loading }: EquipmentSelectionsProps) {
  const [category, setCategory] = useState<string | undefined>();
  const [filter, setFilter] = useState('');

  const categories = useMemo(() => {
    const classes = equipment.map((eq) => eq?.specMetadata?.category?.metadata?.name || '');
    return [...new Set(classes)].sort((a, b) => a.localeCompare(b));
  }, [equipment]);

  const categoryEquipment = useMemo(() => {
    if (!category && !filter) return equipment;
    return equipment.filter((eq) => {
      const eqCategory = eq?.specMetadata?.category?.metadata?.name || '';
      const searchable = [
        eq?.metadata?.equipmentName,
        eq?.metadata?.serialNumber,
        eq?.specMetadata?.manufacturer,
        eq?.specMetadata?.model,
        eq?.metadata?.tid,
      ].join(' ');

      const matchesCategory = !category || eqCategory === category;
      return matchesCategory && searchable.match(new RegExp(filter, 'gi'));
    });
  }, [equipment, category, filter]);

  return (
    <>
      <Label label={label} id={id || 'selectEquipmentForRequest'} />

      <div className="mb-3 flex">
        <Input id="search" value={filter} onInput={(e) => setFilter(e.target.value)} placeholder="Search equipment" />
        <button
          className="ml-2 text-sm text-gray-700"
          type="button"
          title="Clear search"
          aria-label="Clear search"
          onClick={() => setFilter('')}
        >
          <Close />
        </button>
      </div>

      <EquipmentClassSimpleSelect
        equipmentClass={category}
        equipmentClassesAtTheLocation={categories}
        setEquipmentClass={setCategory}
        loading={loading ?? false}
        label=""
      />

      {categoryEquipment.length === 0 ? (
        <div className="pt-5 text-sm text-gray-800">
          <span>No equipment found.</span>
        </div>
      ) : (
        <div className="py-2">
          <div
            aria-labelledby={id || 'selectEquipmentForRequest'}
            className="scrolling-touch max-h-md w-full overflow-y-auto border-b px-2"
          >
            {categoryEquipment.map((eq) => (
              <DisplaySelectableEquipment key={eq?.metadata?.equipmentId || ''} item={eq || {}} handleSelect={handleSelect} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
