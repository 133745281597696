import { useState } from 'react';
import { getAllAASProviders } from '../../screens/aas/form/graphQLQueries';

async function checkDuplicateProvider(field: string, value: string, exists?: boolean) {
  const { data } = await getAllAASProviders({
    size: 2,
    field,
    value,
  });

  const providers = data?.organizations?.data || [];
  return providers.length > (exists ? 1 : 0);
}

export function useDuplicateProviderChecker(exists?: boolean) {
  const [isDuplicateProvider, setIsDuplicateProvider] = useState({
    organizationName: false,
    dispatchPhone: false,
    dispatchEmail: false,
  });

  const checkAndSetDuplicateProvider = async (field: string, value: string) => {
    const isDuplicate = await checkDuplicateProvider(field, value, exists);
    setIsDuplicateProvider((currentState) => ({
      ...currentState,
      [field]: isDuplicate,
    }));
  };

  return {
    isDuplicateProvider,
    checkAndSetDuplicateProvider,
  };
}
