import {
  ServiceRequestStatusType,
  ServiceTypeEnum,
  RequestOriginationType,
  ServiceRequestsSearchInput,
  ElasticsearchSearchTypesEnum,
  BooleanSearchFilterInputType,
  CoverageStatusTypeSearchFilterInputType,
  EquipmentFiltersInput,
  FloatSearchFilterInputType,
  generateStringTermFilters,
  Maybe,
  queryContracts,
  StringSearchFilterInputType,
} from '@equips/entities-schema';
import { TableState } from 'react-table';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import { toTerms } from '../../common/functions/elasticSearchHelpers';
import { toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST } from '../../common/functions/expensiveDateFunctions';
import { agreementFilterTypeOptions } from '../../screens/equipment/equipmentScreenQuery';
import { getSeverityGraphQLFilter } from '../queries/serviceRequestGraphQLQueries';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';

const convertAnnualizedPriceToCents = (price) => price / 365 / 100;

export const serviceRequestSearch = (rawTableState: TableState) => {
  if (!rawTableState) return null;

  const tableState = rawTableState;

  const { filters: tableStateFilters = [], globalFilter = '', pageIndex = 1, pageSize = 15, sortBy = [] } = tableState || {};

  const filterMap = tableStateFilters.reduce((map, filter) => ({ ...map, [filter.id]: filter.value }), {});

  let referredByOrgFilter;
  if (filterMap['referredByOrg']?.substring(0, 5) === 'false') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ notTerm: filterMap['referredByOrg'].substring(5) }] }] };
  } else if (filterMap['referredByOrg']?.substring(0, 4) === 'true') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ term: filterMap['referredByOrg'].substring(4) }] }] };
  }

  const filters = [
    {
      metadata: {
        ...getSeverityGraphQLFilter(filterMap['metadata.severity']),
        ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
        requestStatus: toTerms<ServiceRequestStatusType>(filterMap['metadata.requestStatus']),
        serviceType: toTerms<ServiceTypeEnum>(filterMap['metadata.serviceType']),
        requestOrigination: toTerms<RequestOriginationType>(filterMap['metadata.requestOrigination']),
        ...(filterMap['metadata.locationId'] ? { locationId: [{ keyword: [{ terms: filterMap['metadata.locationId'] }] }] } : {}),
        ...(filterMap['metadata.equipmentId'] ? { equipmentId: [{ keyword: [{ term: filterMap['metadata.equipmentId'] }] }] } : {}),
        ...(filterMap['metadata.size'] ? { size: [{ keyword: [{ term: filterMap['metadata.size'] }] }] } : {}),
        ...(filterMap['metadata.priority'] ? { priority: [{ keyword: [{ term: filterMap['metadata.priority'] }] }] } : {}),
        ...((filterMap['metadata.organizationId']?.length || 0) > 0
          ? { organizationId: [{ keyword: [{ terms: filterMap['metadata.organizationId'] }] }] }
          : {}),
        ...(filterMap['metadata.providerId'] ? { providerId: [{ keyword: [{ term: filterMap['metadata.providerId'] }] }] } : {}),
        ...(filterMap['metadata.tags'] && filterMap['metadata.tags'].split(',').length > 0
          ? { tags: [{ keyword: [{ terms: filterMap['metadata.tags'].split(',') }] }] }
          : {}),
        ...(filterMap['metadata.potentiallyChronic']
          ? { potentiallyChronic: [{ term: filterMap['metadata.potentiallyChronic'] === 'true' }] }
          : {}),
        createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
        dueDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.dueDate']),
        completedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.completedAt']),
        closedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.closedAt']),
        ...(filterMap['metadata.assignedInternalUserId']
          ? filterMap['metadata.assignedInternalUserId'] === 'unassigned'
            ? { assignedInternalUserId: [{ keyword: [{ term: null }] }] }
            : { assignedInternalUserId: [{ keyword: [{ term: filterMap['metadata.assignedInternalUserId'] }] }] }
          : {}),
        ...(filterMap['metadata.createdByUserId']
          ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
          : {}),
        scheduledDate:
          filterMap['metadata.scheduledDate'] !== 'none'
            ? transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.scheduledDate'])
            : [{ exists: false }],
        /** For some reason filtering with terms does not work */
        ...(filterMap['metadata.recurrenceInterval'] === 'all'
          ? { recurrenceInterval: [{ notTerm: 0 }] }
          : filterMap['metadata.recurrenceInterval']
          ? { recurrenceInterval: [{ term: parseInt(filterMap['metadata.recurrenceInterval']) }] }
          : {}),
        modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
        ...(filterMap['metadata.modifiedByUserId']
          ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
          : {}),
        followUpDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.followUpDate']),
        ...(filterMap['metadata.billable'] ? { billable: [{ term: filterMap['metadata.billable'] === 'true' }] } : {}),
        ...(filterMap['metadata.additionalPmCompleted']
          ? { additionalPmCompleted: [{ term: filterMap['metadata.additionalPmCompleted'] === 'true' }] }
          : {}),
        ...(filterMap['metadata.nonCoverageInvoiceNumber']
          ? { nonCoverageInvoiceNumber: [{ keyword: [{ term: filterMap['metadata.nonCoverageInvoiceNumber'] }] }] }
          : {}),
        ...(filterMap['metadata.nonCoverageInvoiceTotal'] && filterMap['metadata.nonCoverageInvoiceTotal'] !== 'all'
          ? { nonCoverageInvoiceTotal: [{ exists: filterMap['metadata.nonCoverageInvoiceTotal'] === 'blank' ? false : true }] }
          : {}),
        ...(filterMap['closed'] && filterMap['closed'] !== 'all'
          ? { closedAt: [{ exists: filterMap['closed'] === 'closed' ? true : false }] }
          : {}),
      },
      specMetadata: {
        categoryId: toTerms<string>(filterMap['specMetadata.categoryId']),
      },
      organizationMetadata: {
        ...(filterMap['referredByOrg'] ? referredByOrgFilter : {}),
      },
      equipmentMetadata: {
        ...(filterMap['equipmentMetadata.hasChronicIssues']
          ? { hasChronicIssues: [{ term: filterMap['equipmentMetadata.hasChronicIssues'] === 'true' }] }
          : {}),
        subclasses: toTerms<string>(filterMap['equipmentMetadata.subclasses']),
      },
      tagMetadata: {
        tagId: toTerms<string>(filterMap['tagMetadata.tagId']),
      },
    },
  ];

  const search: ServiceRequestsSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    filters: filterMap['sublocationIds']
      ? [...filters, { metadata: { locationId: [{ keyword: [{ terms: filterMap['sublocationIds'] }] }] } }]
      : filters,
  };

  return search;
};

export const equipmentSearch = async (rawTableState: TableState) => {
  if (!rawTableState) return null;

  const tableState = rawTableState;

  const { filters: tableStateFilters = [], globalFilter = '', pageIndex = 1, pageSize = 15, sortBy = [] } = tableState || {};

  const filterMap = tableStateFilters.reduce((map, filter) => ({ ...map, [filter.id]: filter.value }), {});

  const customDateFunctionParams = {
    customDatestringToTimestampFunction: toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST,
  };

  const includeDateFilterDueToCoverageMetadataSorting = Boolean(
    sortBy?.length > 0 && sortBy?.find((sorting) => sorting?.id?.includes('coveragesMetadata')),
  );

  let onDateFilter = transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['coveragesMetadata.onDate'], customDateFunctionParams);

  if (includeDateFilterDueToCoverageMetadataSorting && !onDateFilter) {
    onDateFilter = [{ range: { lte: Date.now() } }];
  }
  let offDateFilter = transformDateRangeSelectAndCustomDateIntoGraphQLQuery(
    filterMap['coveragesMetadata.offDate'],
    customDateFunctionParams,
  );

  if (includeDateFilterDueToCoverageMetadataSorting && !offDateFilter) {
    offDateFilter = [{ range: { gte: Date.now() } }];
  }

  let hasChronicIssues: Maybe<BooleanSearchFilterInputType>[] | null | undefined;

  if (filterMap['metadata.hasChronicIssues'] === 'true') {
    hasChronicIssues = [{ term: true }];
  } else if (filterMap['metadata.hasChronicIssues'] === 'false') {
    hasChronicIssues = [{ notTerm: true }];
  }

  let deactivatedAt: Maybe<FloatSearchFilterInputType>[] | null | undefined = filterMap['metadata.deactivatedAt']
    ? [{ exists: true }]
    : undefined;
  let createdAt: Maybe<FloatSearchFilterInputType>[] | null | undefined = transformDateRangeSelectAndCustomDateIntoGraphQLQuery(
    filterMap['metadata.createdAt'],
  );

  let contractIdFilter: Maybe<StringSearchFilterInputType>[] | null | undefined;
  let coverageStatus: Maybe<CoverageStatusTypeSearchFilterInputType>[] | null | undefined;
  let organizationId = filterMap['metadata.organizationId'];

  let referredByOrgFilter;
  if (filterMap['referredByOrg']?.substring(0, 5) === 'false') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ notTerm: filterMap['referredByOrg'].substring(5) }] }] };
  } else if (filterMap['referredByOrg']?.substring(0, 4) === 'true') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ term: filterMap['referredByOrg'].substring(4) }] }] };
  }

  const filter: EquipmentFiltersInput = {
    metadata: {
      ...generateStringTermFilters({
        providerId: filterMap['metadata.providerId'],
        locationId: filterMap['metadata.locationId'],
        organizationId,
      }),
      deactivatedAt,
      createdAt,
      ...(filterMap['metadata.equipmentStatus']
        ? { equipmentStatus: [{ keyword: [{ term: filterMap['metadata.equipmentStatus'] }] }] }
        : {}),
      ...(filterMap['metadata.equipmentHealth']
        ? { equipmentHealth: [{ keyword: [{ term: filterMap['metadata.equipmentHealth'] }] }] }
        : {}),
      modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
      ...(filterMap['metadata.createdByUserId']
        ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
        : {}),
      ...(filterMap['metadata.modifiedByUserId']
        ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
        : {}),
      serviceEndDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.serviceEndDate'], customDateFunctionParams),
      usefulLifeEndDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.usefulLifeEndDate']),
      installationDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.installationDate']),
      subclasses: toTerms<string>(filterMap['metadata.subclasses']),
      hasChronicIssues,
    },
    organizationMetadata: {
      ...(filterMap['referredByOrg'] ? referredByOrgFilter : {}),
    },
    coveragesMetadata: {
      ...(filterMap['coveragesMetadata.annualizedCoveragePrice']
        ? {
            dailyCoveragePrice: [{ range: { gte: convertAnnualizedPriceToCents(filterMap['coveragesMetadata.annualizedCoveragePrice']) } }],
          }
        : {}),
      onDate: onDateFilter,
      offDate: offDateFilter,
      contractId: contractIdFilter,
      coverageStatus,
    },
    specMetadata: {
      categoryId: toTerms<string>(filterMap['specMetadata.categoryId']),
    },
  };

  const orStatements: EquipmentFiltersInput[] = [];

  if (filterMap['metadata.contractId']) {
    const response = await queryContracts({ contractId: filterMap['metadata.contractId'] });
    const contractMetadata = response?.data?.contracts?.data?.[0]?.metadata;
    organizationId = contractMetadata?.organizationId || '';
    const effectiveDate = contractMetadata?.effectiveDate || Infinity;
    const expirationDate = contractMetadata?.expirationDate || 0;

    const createdBeforeContractEnds = { createdAt: [{ range: { lte: expirationDate } }] };
    const wasDeactivatedDuringTheContract = { deactivatedAt: [{ range: { lte: expirationDate, gte: effectiveDate } }] };
    const equipmentIsActive = { deactivatedAt: [{ notExists: true }] };
    const equipmentHasNoServiceEndDate = { serviceEndDate: [{ notExists: true }] };
    const serviceEndDateIsAfterContractEffectiveDate = { serviceEndDate: [{ range: { gte: effectiveDate } }] };

    orStatements.push({
      metadata: { ...createdBeforeContractEnds, ...wasDeactivatedDuringTheContract, ...equipmentHasNoServiceEndDate },
    });
    orStatements.push({
      metadata: { ...createdBeforeContractEnds, ...wasDeactivatedDuringTheContract, ...serviceEndDateIsAfterContractEffectiveDate },
    });
    orStatements.push({
      metadata: { ...createdBeforeContractEnds, ...equipmentIsActive, ...equipmentHasNoServiceEndDate },
    });
    orStatements.push({
      metadata: { ...createdBeforeContractEnds, ...equipmentIsActive, ...serviceEndDateIsAfterContractEffectiveDate },
    });

    if (filterMap['coverageFilter'] === agreementFilterTypeOptions.equipmentWithCoverage && filter.coveragesMetadata) {
      filter.coveragesMetadata.onDate = [{ range: { gte: effectiveDate, lte: expirationDate } }];
    }
  }

  if (filterMap['sublocationIds']) {
    orStatements.push({ metadata: { locationId: [{ keyword: [{ terms: filterMap['sublocationIds'] }] }] } });
  }

  const filters: EquipmentFiltersInput[] = [];

  if (orStatements.length === 0) {
    filters.push(filter);
  }

  for (const statement of orStatements) {
    filters.push({ ...filter, ...statement, metadata: { ...filter.metadata, ...statement.metadata } });
  }

  const search = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    filters,
    sortBy: transformTableSortToGraphQLSort(sortBy),
  };

  return search;
};

const tableIdSearchFactory = {
  serviceRequestTable: serviceRequestSearch,
  equipmentTable: equipmentSearch,
};

export const getSearchFactory = (tableId: string) =>
  tableId in tableIdSearchFactory ? tableIdSearchFactory[tableId] : tableIdSearchFactory.serviceRequestTable;
