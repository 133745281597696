import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const SimpleTabs = (props: Parameters<typeof Tabs>[0]) => (
  <Tabs
    classes={{
      root: 'gap-3 overflow-visible text-center fontFamily-sans min-h-0',
      indicator: 'bg-equipsLightBlue bottom-5',
    }}
    {...props}
  />
);

export const SimpleTab = (props: Parameters<typeof Tab>[0]) => (
  <Tab
    disableRipple
    classes={{
      root: 'text-gray-600 outline-none font-semibold min-w-0 overflow-visible capitalize hover:opacity-100',
      selected: 'text-equipsLightBlue ',
      wrapper: 'block',
    }}
    {...props}
  />
);
