import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export enum UnitOfTime {
  'Millisecond' = 'ms',
  'Second' = 's',
  'Minute' = 'm',
  'Hour' = 'h',
  'Day' = 'd',
  'Week' = 'w',
  'Month' = 'M',
  'Year' = 'y',
}

export function getUnitOfTimeKeyByValue(value: UnitOfTime) {
  const index = Object.values(UnitOfTime).indexOf(value);
  const key = Object.keys(UnitOfTime)[index];

  return key;
}

export function currentStartOfUnitOfTimeInMillseconds(unitOfTime: UnitOfTime) {
  return dayjs().startOf(unitOfTime).valueOf();
}

export function currentEndOfUnitOfTimeInMillseconds(unitOfTime: UnitOfTime) {
  return dayjs().endOf(unitOfTime).valueOf();
}

export const startOfUnitOfTimeForSuppliedTimestampInMilliseconds = (timestamp: number, unitOfTime: UnitOfTime): number => {
  return dayjs(timestamp).startOf(unitOfTime).valueOf();
};

export const endOfUnitOfTimeForSuppliedTimestampInMilliseconds = (timestamp: number, unitOfTime: UnitOfTime): number => {
  return dayjs(timestamp).endOf(unitOfTime).valueOf();
};

export const previousUnitOfTimeForSuppliedTimestampInMillseconds = (timestamp: number, unitOfTime: UnitOfTime) => {
  return dayjs(timestamp).subtract(1, unitOfTime).valueOf();
};

export const nextUnitOfTimeForSuppliedTimestampInMillseconds = (timestamp: number, unitOfTime: UnitOfTime) => {
  return dayjs(timestamp).add(1, unitOfTime).valueOf();
};

export type AmbigiousData = Date | number | string | unknown;

export const isValidDateOrTimeStamp = (data: AmbigiousData) =>
  (typeof data === 'string' || typeof data === 'number' || data instanceof Date) && data !== 0 && dayjs(data).isValid();

export const htmlDateStringFormat = 'YYYY-MM-DD';
export const htmlDateTimeStringFormat = 'YYYY-MM-DDTHH:mm';
export const currentTimestamp = (): number => Date.now();
export const currentYear = (): number => new Date().getFullYear();

export const relativeTimeToTimestamp = (timestampInMilliseconds, noSuffix = false) => dayjs().to(timestampInMilliseconds, noSuffix);

export function isValidHtmlDate(dateString: string | null | undefined): boolean {
  if (!dateString) return false;

  const [yyyy, mm, dd] = dateString.split('-');
  if (!yyyy || !mm || !dd) return false;

  const yearIsValid = yyyy.length === 4 && (yyyy.startsWith('2') || yyyy.startsWith('1'));
  if (!yearIsValid) return false;

  return mm.length === 2 || dd.length === 2;
}

export const dateStringFormattedForHtmlInput = (timestampInMilliseconds) => {
  return dayjs(timestampInMilliseconds).format(htmlDateStringFormat);
};

export const dateTimeStringFormattedForHtmlInput = (timestampInMilliseconds) => {
  return dayjs(timestampInMilliseconds).format(htmlDateTimeStringFormat);
};

export const dateIsAfter = (timestampInMilliseconds, referenceTimestamp) =>
  dayjs(timestampInMilliseconds).isAfter(dayjs(referenceTimestamp));

export const dateIsBefore = (timestampInMilliseconds, referenceTimestamp) =>
  dayjs(timestampInMilliseconds).isBefore(dayjs(referenceTimestamp));

export const dateIsSameOrAfter = (timestampInMilliseconds, referenceTimestamp) =>
  dayjs(timestampInMilliseconds).isSame(dayjs(referenceTimestamp), 'day') ||
  dayjs(timestampInMilliseconds).isAfter(dayjs(referenceTimestamp));

export const dateIsSameOrBefore = (timestampInMilliseconds, referenceTimestamp) =>
  dayjs(timestampInMilliseconds).isSame(dayjs(referenceTimestamp), 'day') ||
  dayjs(timestampInMilliseconds).isBefore(dayjs(referenceTimestamp));

export const getCurrentDateAndMonth = (): { month: number; date: number } => {
  let today = new Date();
  let month = today.getMonth() + 1;
  let date = today.getDate();

  return {
    month,
    date,
  };
};

export const toDateStringFromUnixMillisecondTimestamp = (
  unixTimestamp: number | null | undefined,
  { customFormatString = '', toUTC = false } = {},
): string => {
  if (!unixTimestamp) return '';

  const formatString = !customFormatString ? `MM/DD/YYYY` : customFormatString;
  const timestampAsInt = typeof unixTimestamp !== 'number' ? parseInt(unixTimestamp, 10) : unixTimestamp;
  const formattedDate = toUTC ? dayjs(timestampAsInt).utc().format(formatString) : dayjs(timestampAsInt).format(formatString);

  if (!formattedDate || formattedDate === 'Invalid Date') {
    return '';
  }

  return formattedDate;
};

export const getDatesBetweenTwoTimestamps = (startDate, endDate) => {
  let dates = {};

  let theDate = startDate;

  while (theDate <= endDate) {
    const date = toDateStringFromUnixMillisecondTimestamp(theDate);

    if (date) {
      dates[date] = 0;
    }

    theDate = dayjs(theDate).add(1, 'day').valueOf();
  }

  return dates;
};

export const toUTCUnixMillisecondFromDayjsInstance = (
  dateAsDayjsInstanceInUsersTimezone: null | undefined | dayjs.Dayjs,
): null | number => {
  if (!dateAsDayjsInstanceInUsersTimezone) return null;

  const dateAsUTCDayjsInstance = dateAsDayjsInstanceInUsersTimezone.utc();
  const utcUnixMilliseconds = dateAsUTCDayjsInstance.valueOf();

  return utcUnixMilliseconds;
};

export const toUTCUnixMillisecondFromCstString = (dateAsStringInCst: string | undefined | null): null | number => {
  if (!dateAsStringInCst) return null;

  const dateAsDayjsInstanceInUsersTimezone = dayjs(dateAsStringInCst);

  const usersUTCOffset = dateAsDayjsInstanceInUsersTimezone.utcOffset();
  const cstUTCOffset = -360;
  const minutesDifferentThanCst = usersUTCOffset - cstUTCOffset;
  const dateAsDayjsInstanceInCst = dateAsDayjsInstanceInUsersTimezone.add(minutesDifferentThanCst, 'm');
  const dateAsUTCUnixMillisecond = toUTCUnixMillisecondFromDayjsInstance(dateAsDayjsInstanceInCst);

  return dateAsUTCUnixMillisecond;
};

export const toUnixMillisecondsFromDate = (date?: Date): number => {
  if (!date) return 0;

  const timeInSeconds = dayjs(date).unix();
  const timeInMilliseconds = timeInSeconds * 1000;

  return timeInMilliseconds;
};

export const toUnixMillisecondsFromString = (string?: string): number => {
  if (!string) return 0;
  const regex = new RegExp(/([0-9])-([A-Z])\w+/);
  if (regex.test(string)) return toUnixMillisecondsFromString(`${string}-${currentYear()}`);

  const timeInSeconds = dayjs(string).unix();
  const timeInMilliseconds = timeInSeconds * 1000;

  return timeInMilliseconds;
};

export const toFormattedStringDirtyString = (string?: string, { customFormatString = '' } = {}): string => {
  const unixTimestamp = toUnixMillisecondsFromString(string);

  return toDateStringFromUnixMillisecondTimestamp(unixTimestamp, { customFormatString });
};

export function todaysDateFormattedForWindowsFileSystem(): string {
  return toDateStringFromUnixMillisecondTimestamp(currentTimestamp(), {
    customFormatString: 'MM.DD.YYYY',
  });
}

export const todayIsInBetween = (startTimestampInMilliseconds: number, endTimestampInMilliseconds: number) => {
  const today = dayjs().utc();
  const startTime = dayjs(startTimestampInMilliseconds).utc();
  const endTime = dayjs(endTimestampInMilliseconds).utc();
  const todayOrAfter = today.isSame(startTime, 'day') || today.isAfter(startTime, 'day');
  const todayOrBefore = today.isSame(endTime, 'day') || today.isBefore(endTime, 'day');

  return todayOrAfter && todayOrBefore;
};
export const daysBetweenTwoTimestamps = (startingTimestamp: number | null | undefined, endingTimestamp: number | null | undefined) =>
  Math.abs(dayjs(startingTimestamp || 0).diff(dayjs(endingTimestamp || 0), 'day'));

export const getJavascriptDateXDaysAgo = (daysAgo: number): Date => dayjs().subtract(daysAgo, 'day').toDate();
export const getUnixMillisecondTimestampXDaysAgo = (daysAgo: number): number => dayjs().subtract(daysAgo, 'day').valueOf();

export const getUnixMillisecondTimestampXDaysInFutureFromSuppliedTimeStamp = (suppliedDate: number, daysInFuture: number): number =>
  dayjs(suppliedDate).add(daysInFuture, 'day').valueOf();

export const getJavascriptDateXMonthsAgo = (monthsAgo: number): Date => dayjs().subtract(monthsAgo, 'month').toDate();
export const getUnixMillisecondTimestampXMonthsAgo = (monthsAgo: number): number => dayjs().subtract(monthsAgo, 'month').valueOf();

export const toStringFromUnixMilliseconds = (unixTimestampInMilliseconds: number | null | undefined): string => {
  if (!unixTimestampInMilliseconds) return '';

  return dayjs(unixTimestampInMilliseconds).format('MM/DD/YYYY');
};

export const parseDirtyDateString = (probablyADate: any): string => {
  return dayjs(probablyADate).format('MM/DD/YYYY');
};

export const startOfTodayUnixTimestampInMilliseconds = (): number => {
  return dayjs().startOf('day').valueOf();
};

export const startOfYearUnixTimestampInMilliseconds = (): number => {
  return dayjs().startOf('year').valueOf();
};

export const startOfYearForSuppliedTimestampInMilliseconds = (timestamp: number): number => {
  return dayjs(timestamp).startOf('year').valueOf();
};

export const endOfYearForSuppliedTimestampInMilliseconds = (timestamp: number): number => {
  return dayjs(timestamp).endOf('year').valueOf();
};

export function convertExcelDateStringToString(string: string, customFormatString = 'MM/DD/YYYY'): string {
  if (!string) {
    return '';
  }

  if (new RegExp(/([0-9])-([A-Z])\w+/).test(string)) {
    return dayjs(`${string}-${currentYear()}`).format(customFormatString);
  }

  return dayjs(string).format(customFormatString);
}

export const msToDayHourMinuteSecond = (duration: number) => {
  if (duration <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const totalSeconds = duration / 1000;
  const seconds = Math.round(totalSeconds % 60);
  const minutes = Math.floor((totalSeconds / 60) % 60);
  const hours = Math.floor((totalSeconds / (60 * 60)) % 24);
  const days = Math.floor(totalSeconds / (60 * 60 * 24));

  return { days, hours, minutes, seconds };
};

export const msToTime = (duration: number) => {
  const { days, hours, minutes, seconds } = msToDayHourMinuteSecond(duration);

  const formattedSeconds = seconds.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedDays = days > 0 ? `${days}:` : '';

  return `${formattedDays}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const timeStampToCustomFormatDateString = (timeStamp: number, format = 'YYYY-MM-DDTHH:mm:ssZ[Z]') => {
  return dayjs(timeStamp).isValid() ? dayjs(timeStamp).format(format) : dayjs(null);
};

// need to convert timestamp to match service power time zone
export const timeStampToDateStringServicePower = (timeStamp: number | string) => {
  return dayjs(timeStamp).isValid() ? dayjs(timeStamp).utc().add(1, 'hour').format('hh:mm A') : dayjs(null);
};
// dayjs needs an extension to do this and our bundle is big enough
export const isLeapYear = (year: number) => {
  if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
    return true;
  } else {
    return false;
  }
};

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

export const today = dayjs();

export const getStartAndEndOfMonthInUnixTimestamp = (month: string) => {
  const currentYear = dayjs().year();
  const startOfMonth = dayjs(`${currentYear}-${month}-01`).startOf('month').valueOf();
  const endOfMonth = dayjs(`${currentYear}-${month}-01`).endOf('month').valueOf();

  return { startOfMonth, endOfMonth };
};
