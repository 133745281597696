export const parseContact = (contacts) => {
  if (!contacts) return [];

  try {
    const parsedContacts = JSON.parse(contacts);

    return (parsedContacts && Array.isArray(parsedContacts) && parsedContacts) || [];
  } catch (error) {
    console.error(error);

    return [];
  }
};
