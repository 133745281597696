const ATM_PRIMARY_CLASS_ID = 'f002';
const ITM_PRIMARY_CLASS_ID = 'f039';

type EquipmentOptionsConfigType = {
  [optionName: string]: { type: any; label: string; default?: any; canHaveCoverage: boolean; includeUnlimited?: boolean };
};

type CoverageOptionsConfigType = {
  [optionName: string]: { type: any; label: string; default?: any; includeUnlimited?: boolean };
};

const itmEquipmentOptionsConfig: EquipmentOptionsConfigType = {
  antiSkimmingDevice: {
    type: Boolean,
    label: 'Anti-skimming device',
    default: false,
    canHaveCoverage: true,
  },
  activeEdgeCardReader: {
    type: Boolean,
    label: 'ActiveEdge™ card reader',
    default: false,
    canHaveCoverage: true,
  },
};

const atmEquipmentOptionsConfig: EquipmentOptionsConfigType = {
  antiSkimmingDevice: {
    type: Boolean,
    label: 'Anti-skimming device',
    default: false,
    canHaveCoverage: true,
  },
  activeEdgeCardReader: {
    type: Boolean,
    label: 'ActiveEdge™ card reader',
    default: false,
    canHaveCoverage: true,
  },
  atmOperatingSystem: {
    type: String,
    label: 'ATM Operating System',
    default: '',
    canHaveCoverage: false,
  },
  atmCore: {
    type: String,
    label: 'ATM Core',
    default: '',
    canHaveCoverage: false,
  },
};

const atmItmCoverageOptionsConfig: CoverageOptionsConfigType = {
  firstLineMaintenance: {
    type: Boolean,
    label: 'First-Line Maintenance',
    default: false,
  },
  secondLineMaintenance: {
    type: Boolean,
    label: 'Second-Line Maintenance',
    default: false,
  },
  maxCoveredNoProblemFounds: {
    type: Number,
    label: 'Max covered no problem founds',
    default: 0,
    includeUnlimited: true,
  },
};

export function legacyGetEquipmentOptionsConfig(search: string | null | undefined) {
  if (search === ATM_PRIMARY_CLASS_ID || search === 'ATM') {
    return atmEquipmentOptionsConfig;
  }
  if (search === ITM_PRIMARY_CLASS_ID || search === 'ITM') {
    return itmEquipmentOptionsConfig;
  }

  return {};
}

export function legacyGetCoverageOptionsConfig(search: string | null | undefined) {
  if (search === ATM_PRIMARY_CLASS_ID || search === 'ATM' || search === ITM_PRIMARY_CLASS_ID || search === 'ITM') {
    return atmItmCoverageOptionsConfig;
  }

  return {};
}
