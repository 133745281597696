import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import RadioCard from '../Form/RadioCards';
import Input from '../Form/Input';
import StyledSelect from '../Selects/StyledSelect';
import Checkbox from '../Form/Checkbox';

const PREVIEW_FORMAT = 'M/DD/YYYY';

enum RelativeDateTense {
  Last = -1,
  Current = 0,
  Next = 1,
}

enum Units {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export type RelativeDateRange = {
  tense: RelativeDateTense;
  count: number;
  units: Units;
  includeCurrent?: boolean;
};

type RelativeDateSelectProps = {
  value?: RelativeDateRange;
  onChange: (value: RelativeDateRange) => void;
};

export const getDatesFromRelative = ({ tense, count, units, includeCurrent }: RelativeDateRange) => {
  let from: dayjs.Dayjs;
  let to: dayjs.Dayjs;

  switch (tense) {
    case RelativeDateTense.Last:
      from = dayjs().subtract(count, units).startOf(units);
      to = from.add(count - 1, units).endOf(units);
      break;
    case RelativeDateTense.Next:
      from = dayjs().add(count, units).startOf(units);
      to = from.add(count - 1, units).endOf(units);
      break;
    default:
      from = dayjs().startOf(units);
      to = from.endOf(units);
  }

  if (includeCurrent) {
    if (tense === RelativeDateTense.Next) {
      from = dayjs().startOf(units);
    } else if (tense === RelativeDateTense.Last) {
      to = dayjs().endOf(units);
    }
  }

  return { from, to };
};

function parseTense(tenseValue?: string | null): RelativeDateTense {
  if (tenseValue) {
    const tense = Number(tenseValue);

    if (tense > 0) {
      return RelativeDateTense.Next;
    } else if (tense < 0) {
      return RelativeDateTense.Last;
    }
  }

  return RelativeDateTense.Current;
}

function parseUnits(unitsValue?: string | null): Units {
  if (Object.values(Units).includes(unitsValue as Units)) {
    return unitsValue as Units;
  }

  return Units.Week;
}

export function parseRelativeFilterArgs(args: string[]): RelativeDateRange {
  const [tense, count, units, includeCurrent] = args;

  return {
    tense: parseTense(tense),
    count: Number(count) || 1,
    units: parseUnits(units),
    includeCurrent: includeCurrent === 'true',
  };
}

export function toRelativeFilterArgs(value: RelativeDateRange) {
  const { tense, count, units, includeCurrent } = value;

  return [tense.toString(), count.toString(), units.toString(), includeCurrent ? 'true' : 'false'];
}

export default function RelativeDateSelect({ value, onChange }: RelativeDateSelectProps) {
  const [preview, setPreview] = useState('');
  const [state, setState] = useState<RelativeDateRange>(
    value ?? {
      tense: RelativeDateTense.Current,
      count: 1,
      units: Units.Week,
      includeCurrent: true,
    },
  );

  useEffect(() => {
    const { from, to } = getDatesFromRelative(state);
    setPreview(`${from.format(PREVIEW_FORMAT)} to ${to.format(PREVIEW_FORMAT)}`);

    if (JSON.stringify(state) !== JSON.stringify(value)) {
      onChange?.(state);
    }
  }, [state, onChange]);

  return (
    <div>
      <div className="text-center text-sm font-semibold text-gray-700">Selected range: {preview}</div>
      <div className="pb-4">
        <RadioCard
          id="tense"
          label=""
          options={[
            { label: 'Last', value: RelativeDateTense.Last },
            { label: 'Current', value: RelativeDateTense.Current },
            { label: 'Next', value: RelativeDateTense.Next },
          ]}
          value={state.tense}
          onChange={(value) => setState((state) => ({ ...state, tense: value }))}
          textSize="text-xs"
          cardSize="w-fit"
        />
      </div>
      <div className="flex justify-between gap-2 pb-4">
        {state.tense !== RelativeDateTense.Current && (
          <div className="w-1/3">
            <Input
              id="count"
              type="number"
              value={state.count}
              onChange={(event) => setState((state) => ({ ...state, count: Number(event.target.value) }))}
              min="1"
            />
          </div>
        )}
        <div className={`${state.tense !== RelativeDateTense.Current ? 'w-1/3' : 'w-full'}`}>
          <StyledSelect
            id="units"
            value={state.units}
            onChange={(event) => setState((state) => ({ ...state, units: event.target.value as Units }))}
          >
            {Object.values(Units).map((unit) => (
              <option key={unit} value={unit}>
                {unit}
              </option>
            ))}
          </StyledSelect>
        </div>
        {state.tense !== RelativeDateTense.Current && (
          <div className="">
            <Checkbox
              id="includeCurrent"
              label="Include current?"
              size="small"
              checked={state.includeCurrent}
              onChange={(event) => setState((state) => ({ ...state, includeCurrent: event.target.checked }))}
            />
          </div>
        )}
      </div>
    </div>
  );
}
