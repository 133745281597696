import React from 'react';
import { OrganizationFeatureFlagEnum, queryServiceRequests } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import Close from '@mui/icons-material/Close';
import { useAuth } from '../auth/AuthContext';
import { supportEmailAddress } from '../constants/contact';
import IconButton from './Buttons/IconButton';
import Button from './Buttons/Button';

export function TrialMessage(props: { onClose(): void }) {
  const { auth } = useAuth();
  const isVerifiedTrial = auth?.organization?.metadata?.featureFlags?.includes(OrganizationFeatureFlagEnum.VerifiedTrial);
  const { data: trialServiceRequestCount } = useQuery(
    ['ServiceRequestFormTrialCount'],
    async () => {
      const { data } = await queryServiceRequests({
        search: {
          includeTotalCount: true,
          filters: [{ metadata: { organizationId: [{ keyword: [{ term: auth?.organizationId }] }] } }],
        },
      });
      return data?.serviceRequests?.totalCount ?? 0;
    },
    { enabled: isVerifiedTrial },
  );

  return (
    <div className="relative flex h-screen max-h-md items-center justify-center p-5">
      <div className="absolute right-10 top-10">
        <IconButton text="Close" Icon={Close} onClick={() => props.onClose()} size="large" />
      </div>
      <div className="leading-loose">
        <h2 className="text-3xl text-blue-800">Trial Mode</h2>
        <p>Your account is in trial mode.</p>
        <p>Trial accounts are limited to 1 location and 10 pieces of equipment.</p>
        {isVerifiedTrial ? (
          <p className="font-semibold">
            Your trial account has used {trialServiceRequestCount} of 10 service requests. Please contact us to keep submitting service
            requests.
          </p>
        ) : (
          <p className="font-semibold">
            To start submitting service requests, you must provide billing information by contacting{' '}
            <a href={`mailto:${supportEmailAddress}`}>{supportEmailAddress}</a>.
          </p>
        )}
        <div className="mt-5 text-center">
          <Button href={`mailto:${supportEmailAddress}`}>Contact Us</Button>
        </div>
      </div>
    </div>
  );
}
